<template>
  <div>
    <a-card>
      <a-typography-title :level="4" class="text-gray-8 mb-0 left-aligned">
        {{ title }}
      </a-typography-title>

      <a-table
        class="mt-5"
        :dataSource="table_data"
        :columns="table_columns"
        :loading="loading"
        :pagination="pagination"
        @change="changeCurrentPage"
      >
        <template #idRender="{ text: id }">
          <span>#{{ id }}</span>
        </template>

        <template #stateRender="{ text }">
          <a-tag :color="collectionBillingStateColor(text)">
            {{ getTagText(text)?.toUpperCase() }}
          </a-tag>
        </template>

        <template #multipleRender="{ text }">
          <div v-if="text.length > 1">
            <span>
              {{ text[0] }}
              <b>
                <a-tooltip>
                  <template #title>{{
                    text.slice(1, text.length).join(', ')
                  }}</template>
                  +{{ text.length - 1 }}
                </a-tooltip>
              </b>
            </span>
          </div>
          <div v-else>
            <span>{{ text[0] }}</span>
          </div>
        </template>

        <template #filterDropdown="{ column, confirm, clearFilters }">
          <div style="padding: 8px">
            <a-form
              name="form"
              layout="vertical"
              ref="formRef"
              :model="filter_values"
              :rules="rules"
            >
              <a-form-item :name="column.dataIndex">
                <a-input
                  v-model:value="filter_values[column.key]"
                  @keyup="formatRuts"
                  :placeholder="`Buscar por ${column.title}`"
                  :maxlength="25"
                  @pressEnter="
                    handleSearch(
                      column.dataIndex,
                      filter_values[column.key],
                      confirm
                    )
                  "
                  style="width: 188px; margin-bottom: 8px; display: block"
                />

                <a-button
                  type="primary"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="
                    handleSearch(
                      column.dataIndex,
                      filter_values[column.key],
                      confirm
                    )
                  "
                >
                  <template #icon><search-outlined /></template>
                  Buscar
                </a-button>

                <a-button
                  size="small"
                  style="width: 90px"
                  @click="handleColumnReset(column.dataIndex, clearFilters)"
                >
                  Limpiar
                </a-button>
              </a-form-item>
            </a-form>
          </div>
        </template>

        <template #periodFilter="{ column, confirm, clearFilters }">
          <div style="padding: 8px">
            <a-month-picker
              v-model:value="filter_values[column.key]"
              :placeholder="`Buscar por ${column.title}`"
              @pressEnter="
                handleSearch(
                  column.dataIndex,
                  filter_values[column.key],
                  confirm
                )
              "
              style="width: 188px; margin-bottom: 8px; display: block"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  filter_values[column.key],
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </template>

        <template #filterStates="{ confirm, column, clearFilters }">
          <div style="padding: 8px; width: 300px">
            <a-checkbox-group v-model:value="filter_values[column.key]">
              <a-row>
                <a-col
                  :span="24"
                  v-for="(state, index) in states_list"
                  :key="index"
                >
                  <a-checkbox :value="state.value">{{
                    state.label
                  }}</a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>

            <div
              class="mt-4 p-2"
              style="
                border-top: 1px solid #f0f0f0;
                display: flex;
                justify-content: space-between;
              "
            >
              <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="
                  handleSearch(
                    column.dataIndex,
                    filter_values[column.key].join(','),
                    confirm
                  )
                "
              >
                <template #icon><search-outlined /></template>
                Buscar
              </a-button>

              <a-button
                size="small"
                style="width: 90px"
                @click="handleColumnReset(column.dataIndex, clearFilters)"
              >
                Limpiar
              </a-button>
            </div>
          </div>
        </template>

        <template #filterCompanies="{ column, confirm, clearFilters }">
          <div style="padding: 8px; width: 300px">
            <a-select
              v-model:value="selected_companies"
              :options="company_list"
              mode="multiple"
              show-search
              :filterOption="true"
              optionFilterProp="label"
              placeholder="Buscar por Razon social"
              style="width: 100%"
              size="middle"
            >
              <template #dropdownRender="{ menuNode: menu }">
                <v-nodes :vnodes="menu" />
                <div
                  style="
                    border-top: 1px solid #f0f0f0;
                    display: flex;
                    justify-content: space-between;
                  "
                  class="p-2"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="
                      handleSearch(
                        column.dataIndex,
                        selected_companies.join(','),
                        confirm
                      )
                    "
                  >
                    Buscar
                  </a-button>

                  <a-button
                    size="small"
                    style="width: 90px"
                    @click="handleColumnReset(column.dataIndex, clearFilters)"
                  >
                    Limpiar
                  </a-button>
                </div>
              </template>
            </a-select>
            <div
              class="p-2"
              style="
                border-top: 1px solid #f0f0f0;
                display: flex;
                justify-content: space-between;
              "
            >
              <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="
                  handleSearch(
                    column.dataIndex,
                    selected_companies.join(','),
                    confirm
                  )
                "
              >
                Buscar
              </a-button>

              <a-button
                size="small"
                style="width: 90px"
                @click="handleColumnReset(column.dataIndex, clearFilters)"
              >
                Limpiar
              </a-button>
            </div>
          </div>
        </template>

        <!-- <a-month-picker v-model:value="stock_form.date" placeholder="Seleccionar fecha" style="width:100% !important;"/> -->

        <template #filterIcon="{ column }">
          <search-outlined
            :style="{
              color:
                search_value[column.dataIndex] === undefined ||
                search_value[column.dataIndex] === ''
                  ? undefined
                  : '#2521E5'
            }"
          />
        </template>

        <template #filterIconState="{ column }">
          <filter-outlined
            :style="{
              color:
                search_value[column.dataIndex] === undefined ||
                search_value[column.dataIndex] === ''
                  ? undefined
                  : '#2521E5'
            }"
          />
        </template>

        <template #actionDetails="{ record }">
          <slot :record="record" name="actionDetails"> </slot>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { SearchOutlined, FilterOutlined } from '@ant-design/icons-vue';
import { onMounted, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import { useStore } from 'vuex';
import rutHelper from '@/utils/Ruts';
import getTagText from '@/utils/getTagText';
import Ruts from '@/utils/Ruts';
import collectionBillingStateColor from '@/utils/collectionBillingStateColor';
import { GET_ALL_COMPANIES, FETCH_ALL_COMPANIES } from '@/store/types';
import notification from '@/utils/notifications';

export default {
  name: 'CollectionAndBillingStockTable',
  components: {
    'search-outlined': SearchOutlined,
    'filter-outlined': FilterOutlined,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: []
    },
    pagination: {
      type: Object,
      default: {}
    },
    routeName: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const formRef = ref();
    const table_data = computed(() => {
      return props.data.map((obj) => {
        return {
          id: obj.id,
          key: obj.id,
          states: obj.state,
          holdingName: obj.holding,
          subHoldingName: obj?.subHoldings,
          companyId: obj?.companiesName,
          companyRut: obj?.companiesRut?.map((rut) => Ruts.formatRut(rut)),
          numberPolicy: obj?.policies,
          insuranceCompanyName: obj.insuranceCompany,
          period: obj.period,
          action: obj.id
        };
      });
    });
    const table_columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'idRender'
          /* filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon', */
        }
      },
      {
        title: 'Estado reproceso',
        dataIndex: 'states',
        key: 'states',
        slots: {
          customRender: 'stateRender',
          filterDropdown: 'filterStates',
          filterIcon: 'filterIconState'
        }
      },
      {
        title: 'Holding',
        dataIndex: 'holdingName',
        key: 'holdingName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Subholding',
        dataIndex: 'subHoldingName',
        key: 'subHoldingName',
        slots: {
          customRender: 'multipleRender',
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Razón social',
        dataIndex: 'companyId',
        key: 'companyId',
        slots: {
          customRender: 'multipleRender',
          filterDropdown: 'filterCompanies',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'RUT empresa',
        dataIndex: 'companyRut',
        key: 'companyRut',
        slots: {
          customRender: 'multipleRender',
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Póliza',
        dataIndex: 'numberPolicy',
        key: 'numberPolicy',
        slots: {
          customRender: 'multipleRender',
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Aseguradora',
        dataIndex: 'insuranceCompanyName',
        key: 'insuranceCompanyName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Periodo',
        dataIndex: 'period',
        key: 'period',
        slots: {
          filterDropdown: 'periodFilter',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Acción',
        dataIndex: 'action',
        key: 'action',
        slots: {
          customRender: 'actionDetails'
        }
      }
    ];

    const checkRut = async (rule, value) => {
      if (!value) {
        return;
      }
      if (!rutHelper.validateRut(value)) {
        return Promise.reject('RUT no válido');
      }
      return Promise.resolve();
    };

    const rules = {
      companyRut: [
        {
          required: false,
          validator: checkRut,
          trigger: 'change'
        }
      ]
    };

    const states_list = [
      {
        label: 'Aprobada',
        value: 'APPROVED'
      },
      {
        label: 'En aseguradora',
        value: 'IN_INSURANCE'
      },
      {
        label: 'Rechazado',
        value: 'REJECTED'
      },
      {
        label: 'Cancelado',
        value: 'CANCELED'
      }
    ];

    const changeCurrentPage = (page) => {
      filterRedirectionNewParam('page', page.current);
    };

    const formatRuts = () => {
      if (filter_values.value['companyRut']) {
        filter_values.value['companyRut'] = rutHelper.formatRut(
          filter_values.value['companyRut']
        );
      }
    };

    // * Redireccion
    const filter_values = ref({}); // Objeto que guarda los valores de los filtros
    const search_value = ref({}); // Objeto que guarda los valores de los cuales se hace la busqueda por filtro

    const filterRedirectionNewParam = (key, param) => {
      search_value.value[key] = param;

      if (key != 'page') {
        search_value.value['page'] = 1;
      }

      router.replace({
        name: props.routeName,
        query: search_value.value
      });
    };

    const filterRedirectionResetColumn = (key) => {
      delete search_value.value[key];
      delete filter_values.value[key];

      router.replace({
        name: props.routeName,
        query: search_value.value
      });
    };

    const handleSearch = (column, value, confirm) => {
      if (value != '' && value != undefined) {
        formRef.value.validate().then(() => {
          let search_value = value;

          if (column == 'period') {
            search_value = value.format('YYYY-MM');
          }

          if (column == 'companyRut') {
            search_value = Ruts.normalizeRut(value);
          }

          filterRedirectionNewParam(column, search_value);
          confirm();
        });
      } else {
        formRef.value.clearValidate();
      }
    };

    const handleColumnReset = (column, resetConfirm) => {
      filterRedirectionResetColumn(column);
      resetConfirm();

      if (column === 'companyId') {
        selected_companies.value = [];
      }

      delete filter_values.value[column];
    };

    // * Lista de compañias
    const selected_companies = ref([]);
    const company_list = computed(() => {
      return store.getters[GET_ALL_COMPANIES].map((obj) => {
        return {
          value: obj.id,
          label: obj.businessName
        };
      });
    });

    const getAllCompanies = () => {
      try {
        store.dispatch(FETCH_ALL_COMPANIES);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    // * Data de filtros
    onMounted(() => {
      const keys = Object.keys(route.query);

      keys.forEach((element) => {
        search_value.value[element] = route.query[element]; // Le paso valor al los inputs para que muestre que es lo que busco anteriormente
        if (element === 'period') {
          filter_values.value[element] = moment(
            route.query[element],
            'YYYY-DD'
          );
        } else if (element === 'states') {
          filter_values.value[element] = [...route.query[element].split(',')];
        } else if (element === 'companyId') {
          filter_values.value[element] = [...route.query[element].split(',')];
          selected_companies.value.push(
            ...route.query[element].split(',').map((e) => parseInt(e))
          );
        } else {
          filter_values.value[element] = route.query[element];
        }
      });

      getAllCompanies();
    });

    return {
      table_columns,
      table_data,
      collectionBillingStateColor,
      getTagText,
      filter_values,
      search_value,
      states_list,
      rules,
      formRef,
      formatRuts,
      changeCurrentPage,
      handleSearch,
      handleColumnReset,
      selected_companies,
      company_list
    };
  }
};
</script>
