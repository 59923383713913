// EventBus.js

import mitt from 'mitt';

const emitter = mitt();

export default {
  emit: (event, ...args) => {
    emitter.emit(event, ...args);
  },
  on: (event, callback) => {
    emitter.on(event, callback);
  },
  off: (event, callback) => {
    emitter.off(event, callback);
  }
};
