<template>
  <div class="company-avatar">
    <a-avatar :size="100" :src="image_url">
      <template #icon>
        <a-spin v-if="loading" />
        <user-outlined v-else />
      </template>
    </a-avatar>
    <a-upload
      id="company-avatar"
      name="avatar_upload_ref"
      ref="avatar_upload_ref"
      :file-list="avatar_upload_file"
      :multiple="false"
      accept="image/png, image/jpeg, image/svg"
      :customRequest="uploadFile"
    >
      <a-button
        shape="circle"
        size="small"
        style="border-radius: 15px !important"
      >
        <template #icon>
          <EditOutlined />
        </template>
      </a-button>
    </a-upload>
  </div>
</template>

<script setup>
import { UserOutlined, EditOutlined } from '@ant-design/icons-vue';
import { onMounted, ref, defineProps } from 'vue';
import notification from '../utils/notifications';
import { useStore } from 'vuex';
import { FETCH_COMPANY_LOGO, POST_COMPANY_LOGO } from '../store/types';

const props = defineProps({
  companyId: {
    default: null,
    type: [String, Number]
  }
});

const avatar_upload_ref = ref();
const avatar_upload_file = ref([]);
const image_url = ref();
const loading = ref(false);
const store = useStore();

const uploadFile = async ({ file }) => {
  loading.value = true;
  image_url.value = null;

  try {
    loading.value = true;
    const formData = new FormData();
    formData.append('logo', file);
    await store.dispatch(POST_COMPANY_LOGO, {
      payload: formData,
      id: props.companyId
    });
    notification('success', 'Correcto', 'Logo subido correctamente');
    getLogo();
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
};

const getLogo = () => {
  loading.value = true;
  store
    .dispatch(FETCH_COMPANY_LOGO, props.companyId)
    .then((resp) => {
      const { logoObjectUrl } = resp;
      if (logoObjectUrl != null && logoObjectUrl != undefined) {
        image_url.value = logoObjectUrl;
      }
    })
    .catch((error) => {
      notification('error', 'Error', error.response.data.message);
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  getLogo();
});
</script>

<style>
.company-avatar {
  width: 100px;
  height: 100px;
}

.company-avatar .ant-upload.ant-upload-select.ant-upload-select-text {
  display: block !important;
  margin-top: -25px;
  margin-left: 35px;
}
</style>
