<template>
  <a-row type="flex" justify="center" align="middle" style="min-height: 100vh">
    <a-spin v-if="state.loading" size="large" />
    <a-col v-if="!state.loading" :sm="20" :md="14" :lg="10">
      <a-row type="flex" justify="center" align="middle">
        <img src="@/assets/images/insured_form/ilustracion.svg" style="height: 300px" />
      </a-row>
      <a-row type="flex" justify="center" align="middle">
        <a-typography-title :level="2" style="margin-top: 16px">Formulario de incorporación de
          cargas</a-typography-title>
      </a-row>
      <a-row type="flex" justify="center" align="middle">
        <a-typography-title :level="4">Este formulario esta compuesto por
          {{ steps.length }} pasos:</a-typography-title>
      </a-row>
      <a-row type="flex" justify="center" align="middle">
        <div style="margin-top: 16px">
          <a-steps direction="vertical">
            <a-step v-for="(step, stepIndex) in steps" :key="stepIndex" :title="step.title"
              :description="step.description" />
          </a-steps>
        </div>
      </a-row>
      <a-row type="flex" justify="center" align="middle">
        <router-link :to="{
          name: 'DependentsIncorporationFormStep1',
          params: { token: route.params.token }
        }">
          <a-button style="margin-top: 16px" type="primary" size="large">Comenzar</a-button>
        </router-link>
      </a-row>
    </a-col>
  </a-row>
</template>

<script setup>
import { onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { FETCH_DEPENDENTS_INCORPORATION_FORM } from '@/store/types';

const route = useRoute();
const router = useRouter();
const store = useStore();

const state = reactive({
  loading: true
});

const steps = [
  {
    title: 'Ingresa tus cargas',
    description: 'Ingresa el rut, nombres y fecha de nacimiento de tus cargas.'
  },
  {
    title: 'Carga tus documentos',
    description:
      'Una vez que hayas completado el o los formularios, cárgalos desde tu computador.'
  }
];

onMounted(async () => {
  try {
    const form = await store.dispatch(
      FETCH_DEPENDENTS_INCORPORATION_FORM,
      route.params.token
    );
    if (form.state !== 'Creado') {
      router.push({ name: 'DependentsIncorporationFormError' });
    } else if (form.isTokenExpired) {
      router.push({ name: 'DependentsIncorporationFormExpired' });
    }
    state.loading = false;
  } catch (error) {
    router.push({ name: 'DependentsIncorporationFormError' });
  }
});
</script>
