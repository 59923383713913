<template>
  <div>
    <page-header :breadcrumRoutes="routes" title="Dashboard" :displayIcon="false">
      <template #buttons>
        <div>
          <a-radio-group v-model:value="dashboard_type" @change="onChangeTabs">
            <a-radio-button value="movimientos">Movimientos</a-radio-button>
            <a-radio-button value="cobranzas">Cobranzas</a-radio-button>
            <a-radio-button value="facturacion">Facturación y mora</a-radio-button>
          </a-radio-group>
        </div>
      </template>
    </page-header>

    <a-card>
      <div style="display: flex; flex-direction: row; gap: 16px; flex-wrap: wrap">
        <a-range-picker v-if="dashboard_type === 'movimientos'" format="DD-MM-YYYY" v-model:value="period_date_filter"
          style="flex-grow: 1" />
        <a-month-picker v-else v-model:value="date_filter" placeholder="Seleccionar periodo" />
        <select-filter />
      </div>
    </a-card>

    <movements v-if="dashboard_type === 'movimientos'" />
    <collections v-else-if="dashboard_type === 'cobranzas'" />
    <billing-and-deliquency v-else />
  </div>
</template>

<script>
import { ref, computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import PageHeader from '@/components/PageHeader.vue';
import SelectFilter from '@/components/dashboard/SelectFilter.vue';
import {
  GET_PERIOD_DATE_FILTER_DASHBOARD,
  SAVE_PERIOD_DATE_FILTER_DASHBOARD,
  GET_DATE_FILTER_DASHBOARD,
  SAVE_DATE_FILTER_DASHBOARD
} from '@/store/types';

const Movements = defineAsyncComponent(() =>
  import('@/components/dashboard/sections/MovementsSection.vue')
);
const Collectons = defineAsyncComponent(() =>
  import('@/components/dashboard/sections/CollectionsSection.vue')
);
const BillingAndDelinquency = defineAsyncComponent(() =>
  import('@/components/dashboard/sections/BillingAndDelinquencySection.vue')
);

export default {
  name: 'Dashboard',
  components: {
    'page-header': PageHeader,
    'select-filter': SelectFilter,
    movements: Movements,
    collections: Collectons,
    'billing-and-deliquency': BillingAndDelinquency
  },
  setup() {
    // * Router
    const router = useRouter();
    const route = useRoute();

    // * Store
    const store = useStore();

    // * Page Header
    const routes = [
      {
        breadcrumbName: 'Dashboard'
      }
    ];

    // * Tipo de dashboard
    const dashboard_type = ref('');
    dashboard_type.value =
      route.hash === '' ? 'movimientos' : route.hash.replace('#', '');
    const onChangeTabs = async (value) => {
      const redirection_obj = {
        name: 'Dashboard'
      };

      if (value.target.value === 'cobranzas') {
        redirection_obj.hash = '#cobranzas';
      } else if (value.target.value === 'facturacion') {
        redirection_obj.hash = '#facturacion';
      }

      await router.replace(redirection_obj);
    };

    // * Filtro de fechas
    const period_date_filter = computed({
      get() {
        return store.getters[GET_PERIOD_DATE_FILTER_DASHBOARD];
      },
      set(value) {
        store.dispatch(SAVE_PERIOD_DATE_FILTER_DASHBOARD, value);
      }
    });

    const date_filter = computed({
      get() {
        return store.getters[GET_DATE_FILTER_DASHBOARD];
      },
      set(value) {
        store.dispatch(SAVE_DATE_FILTER_DASHBOARD, value);
      }
    });

    return {
      dashboard_type,
      routes,
      period_date_filter,
      date_filter,
      onChangeTabs
    };
  }
};
</script>
