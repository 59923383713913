<template>
  <collapsable-table title="Inconsistencias" activeKey="1">
    <template #extra v-if="squaringCollectionInsurance.status != 'PROCESSING'">
      <a-button v-show="squaringCollectionInsurance.status !== 'NO_PROCESSED'" @click.stop="downloadInconsistency"
        type="link" style="margin-top: -5px">
        <download-outlined /> Descargar inconsistencias
      </a-button>
    </template>

    <slot>
      <a-typography-title class="text-gray-8" :level="5">
        Resumen de inconsistencias
      </a-typography-title>

      <a-typography-text v-if="squaringCollectionInsurance.status == 'NO_MATCH'">
        Para más detalle descargar documento excel.
      </a-typography-text>
      <a-typography-text v-else-if="squaringCollectionInsurance.status == 'MATCH'">
        No se han registrado inconsistencias en este proceso de cobranza.
      </a-typography-text>

      <a-descriptions :bordered="true" :column="1" class="mt-3">
        <a-descriptions-item label="Estado nómina">
          <a-row justify="space-between" align="middle">
            <a-col>
              <a-tag :color="getColorState(squaringCollectionInsurance.status)">
                {{ getTagText(squaringCollectionInsurance.status)?.toUpperCase() }}
                <close-circle-outlined v-if="squaringCollectionInsurance.status == 'NO_MATCH'" />
                <check-circle-outlined v-else />
              </a-tag>
            </a-col>
            <a-col v-if="squaringCollectionInsurance.status !== 'NO_PROCESSED' &&
              squaringCollectionInsurance.status !== 'PROCESSING' &&
              (lastState == 'BEWELL_VALIDATION' ||
                lastState == 'HR_VALIDATION') &&
              hideAdjustPayrollButton != true
              ">
              <a-button type="primary" class="edit-aseguradora-text" @click="openValidateModal">
                Recuadrar
              </a-button>
            </a-col>
          </a-row>
        </a-descriptions-item>
        <a-descriptions-item v-if="squaringCollectionInsurance && squaringCollectionInsurance.totalDiscrepancies"
          label="Total de inconsistencias">{{
            squaringCollectionInsurance.totalDiscrepancies
          }}</a-descriptions-item>
      </a-descriptions>

      <a-table v-if="table_props.dataSource.length > 0" :dataSource="table_props.dataSource" :columns="columns"
        class="mt-3" :pagination="false">
      </a-table>
    </slot>
  </collapsable-table>
  <re-esquering :loadingsquaringValidate="loading" @createSquaringValidate="createSquaringValidate"
    ref="modal_resquering" />
</template>

<script>
import { reactive, ref, watchEffect, computed } from 'vue';
import {
  DownloadOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined
} from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import CollectionResquering from '../../../../components/collection and billing/collectionTicket/CollectionTicketReSquaring.vue';
import CollapsableTable from '@/components/CollapsableTable.vue';
import stateColor from '@/utils/collectionBillingStateColor';
import getTagText from '@/utils/getTagText';
import {
  DOWNLOAD_SQUARING_INCONSISTENCY,
  GET_DOWNLOAD_SQUARING_INCOSISTENCY_LINK,
  GET_COLLECTION_INCONSISTENCIES
} from '@/store/types';

export default {
  name: 'AssociateInconsistency',
  components: {
    'download-outlined': DownloadOutlined,
    'check-circle-outlined': CheckCircleOutlined,
    'close-circle-outlined': CloseCircleOutlined,
    'collapsable-table': CollapsableTable,
    're-esquering': CollectionResquering
  },
  props: {
    states: {
      type: Object,
      default: {}
    },
    loadingsquaringValidate: {
      type: Boolean,
      default: false
    },
    squaringCollectionInsurance: {
      type: Object,
      default: {}
    },
    hideAdjustPayrollButton: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const collapse_open = ref();
    const store = useStore();
    const route = useRoute();
    const lastState = computed(() =>
      props.states.states.map((e) => e.state).pop()
    );
    const loading = computed(() => props.loadingsquaringValidate);
    const status = ref('');

    const downloadFile = () => {
      const a = document.createElement('a');
      a.href = props.inconsistencyFileUrl;
      a.download = props.inconsistencyFileUrl;
      a.setAttribute('target', '_blank');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    const table_props = reactive({
      dataSource: [],
      columns: [],
      pagination: false
    });
    const getInconsistency = computed(
      () => store.getters[GET_COLLECTION_INCONSISTENCIES]
    );

    watchEffect(() => {
      if (
        props.squaringCollectionInsurance.discrepancies != undefined &&
        props.squaringCollectionInsurance.discrepancies.length > 0
      ) {
        table_props.dataSource =
          props.squaringCollectionInsurance.discrepancies[0].table_data;
      }
    });
    const getColorState = (value) => {
      return stateColor(value);
    };
    const columns = [
      {
        title: 'Cuadratura',
        key: 'types',
        dataIndex: 'types',
        slots: {
          customRender: 'cuadratura'
        }
      },
      {
        title: 'Plataforma',
        dataIndex: 'stockCount',
        key: 'payrollCount',
        slots: {
          customRender: 'plataforma'
        }
      },
      {
        title: 'Cobranza',
        dataIndex: 'payrollCount',
        key: 'payrollCount',
        slots: {
          customRender: 'cobranza'
        }
      }
    ];
    const getUrlSquaringInconsistency = store.dispatch(
      DOWNLOAD_SQUARING_INCONSISTENCY,
      { collectionId: route.params.id, type: '?type=xlsx' }
    );
    const downloadSquaringCollectionInconsistency = computed(
      () => store.getters[GET_DOWNLOAD_SQUARING_INCOSISTENCY_LINK]
    );

    const downloadInconsistency = () => {
      window.location.href = downloadSquaringCollectionInconsistency.value;
    };

    const squaring_value = ref();
    const modal_resquering = ref();
    const openValidateModal = () => {
      modal_resquering.value.openModal();
    };

    const createSquaringValidate = async () => {
      // emitir para crear
      emit('createSquaringValidate', {
        ...modal_resquering.value,
        force: true
      });

      // emitir al padre para validar el cerrar modal de collectionTicketReSquaring
      // ya que en el ticket padre tengo el fetch de los datos
      emit('updateInconsistency', { ...modal_resquering.value, force: true });
    };

    return {
      collapse_open,
      table_props,
      downloadFile,
      getColorState,
      getTagText,
      columns,
      getUrlSquaringInconsistency,
      downloadSquaringCollectionInconsistency,
      downloadInconsistency,
      // openModal,
      // closeModal,
      squaring_value,
      modal_resquering,
      // visible,
      openValidateModal,
      lastState,
      createSquaringValidate,
      getInconsistency,
      loading,
      status
    };
  }
};
</script>
