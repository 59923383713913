export const SET_DEPENDENTS_INCORPORATION_FORM =
  'SET_DEPENDENTS_INCORPORATION_FORM';
export const SET_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM =
  'SET_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM';

export const GET_DEPENDENTS_INCORPORATION_FORM =
  'GET_DEPENDENTS_INCORPORATION_FORM';
export const GET_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM =
  'GET_DEPENDENTSOF_DEPENDENTS_INCORPORATION_FORM';

export const FETCH_DEPENDENTS_INCORPORATION_FORM =
  'FETCH_DEPENDENTS_INCORPORATION_FORM';
export const FETCH_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM =
  'FETCH_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM';
export const FETCH_POLICIES_FOR_DEPENDENTS_INCORPORATION_FORM =
  'FETCH_POLICIES_FOR_DEPENDENTS_INCORPORATION_FORM';
export const FETCH_COMPANIES_FOR_DEPENDENTS_INCORPORATION_FORM =
  'FETCH_COMPANIES_FOR_DEPENDENTS_INCORPORATION_FORM';

export const POST_EMPLOYEE_DEPENDENTS_INCORPORATION =
  'POST_EMPLOYEE_DEPENDENTS_INCORPORATION';
export const POST_INSURANCE_DEPENDENTS_INCORPORATION =
  'POST_INSURANCE_DEPENDENTS_INCORPORATION';
export const POST_RESEND_DEPENDENTS_INCORPORATION_FORM =
  'POST_RESEND_DEPENDENTS_INCORPORATION_FORM';
export const POST_SEND_TO_BROKER_DEPENDENTS_INCORPORATION_FORM =
  'POST_SEND_TO_BROKER_DEPENDENTS_INCORPORATION_FORM';
export const POST_CANCEL_DEPENDENTS_INCORPORATION =
  'POST_CANCEL_DEPENDENTS_INCORPORATION';
export const POST_FINISH_DEPENDENTS_INCORPORATION =
  'POST_FINISH_DEPENDENTS_INCORPORATION';
export const POST_SEND_TO_INSURANCE_DEPENDENTS_INCORPORATION =
  'POST_SEND_TO_INSURANCE_DEPENDENTS_INCORPORATION';
export const PUT_DEPENDENTS_OF_DEPENDENTS_INCORPORATION =
  'PUT_DEPENDENTS_OF_DEPENDENTS_INCORPORATION';
export const PUT_DOCUMENT_OF_DEPENDENTS_INCORPORATION =
  'PUT_DOCUMENT_OF_DEPENDENTS_INCORPORATION';

export const FETCH_TICKET_DEPENDANTS_INCORPORATIONS =
  'FETCH_TICKET_DEPENDANTS_INCORPORATIONS';
export const SET_TICKET_DEPENDANTS_INCORPORATIONS_LOADING =
  'SET_TICKET_DEPENDANTS_INCORPORATIONS_LOADING';
export const SET_TICKET_DEPENDANTS_INCORPORATIONS =
  'SET_TICKET_DEPENDANTS_INCORPORATIONS';
export const GET_TICKET_DEPENDANTS_INCORPORATIONS =
  'GET_TICKET_DEPENDANTS_INCORPORATIONS';
export const DELETE_TICKET_DEPENDANT_INCORPORATION =
  'DELETE_TICKET_DEPENDANT_INCORPORATION';
