<template>
  <a-modal
    :closable="!loading"
    :maskClosable="!loading"
    title="Baja al stock"
    v-model:visible="visible"
    @cancel="onCloseModal"
  >
    <a-typography-text>
      Baja al stock se utiliza para desvincular a uno o varios colaboradores de
      su seguro.
    </a-typography-text>

    <a-typography-title class="text-gray-8 mt-4" :level="5">
      Completa los siguientes datos
    </a-typography-title>

    <a-form
      class="mt-2"
      name="form"
      layout="vertical"
      ref="form_ref"
      :model="form_state"
      :rules="rules"
    >
      <a-form-item
        has-feedback
        name="insurance_company"
        ref="insurance_company"
        label="Aseguradora"
      >
        <a-select
          ref="select"
          style="witdh: 100%"
          :options="props.insuranceCompanies"
          v-model:value="form_state.insurance_company"
          placeholder="Selecciona una aseguradora"
        />
      </a-form-item>

      <a-row type="flex" justify="space-between">
        <a-col :span="8">
          <a-form-item name="file_list">
            <a-upload
              name="avatar"
              :file-list="form_state.file_list"
              :remove="handleRemoveFile"
              :before-upload="beforeUploadFile"
            >
              <a-button>
                <UploadOutlined />
                Adjuntar documentos
              </a-button>
            </a-upload>
          </a-form-item>
        </a-col>
        <a-col :span="8" :offset="8">
          <a-button type="link" @click="downloadFormat"
            >Descargar formato</a-button
          >
        </a-col>
      </a-row>
    </a-form>

    <template #footer>
      <a-button :disabled="loading" @click="closeModal">Cancelar</a-button>
      <a-button type="primary" @click="uploadFile" :loading="loading"
        >Confirmar</a-button
      >
    </template>
  </a-modal>
</template>

<script setup>
import { ref, reactive, defineProps, defineExpose, defineEmits } from 'vue';
import { message } from 'ant-design-vue';
import { UploadOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  FETCH_BAJA_STOCK_FORMAT,
  GET_BROKER_ID,
  POST_EXCEL_BAJA_STOCK
} from '../../store/types';
import notification from '../../utils/notifications';

const props = defineProps({
  insuranceCompanies: {
    type: Array,
    default: []
  }
});

const emit = defineEmits(['uploaded']);

const store = useStore();
const route = useRoute();
const company_id = route.params.id;
const broker_id = store.getters[GET_BROKER_ID];

const checkFiles = async () => {
  if (
    form_state.file_list.length < 1 &&
    form_validation_type.value === 'upload'
  ) {
    return Promise.reject('Ingrese un archivo');
  }
  return Promise.resolve();
};

const handleRemoveFile = (file) => {
  if (file.id) {
    form_state.file_list = [...form_state.file_list, file.id];
  }
  const index = form_state.file_list.indexOf(file);
  const newFileList = form_state.file_list.slice();
  newFileList.splice(index, 1);
  form_state.file_list = newFileList;
};

const beforeUploadFile = (file) => {
  const fileSize = file.size / 1024 / 1024;
  if (fileSize > 5) {
    message.error('El archivo excede el peso máximo 5MB');
  } else {
    form_state.file_list = [file];
  }
  return false;
};

const openModal = () => {
  visible.value = true;
};

const onCloseModal = () => {
  form_ref.value.resetFields();
};

const form_validation_type = ref('upload'); // upload - download

const loading = ref(false);
const uploadFile = () => {
  form_validation_type.value = 'upload';
  form_ref.value.validate().then(() => {
    loading.value = true;
    const formData = new FormData();
    formData.append('insuranceCompanyId', form_state.insurance_company);
    formData.append('companyId', company_id);
    formData.append('brokerId', broker_id);
    formData.append('file', form_state.file_list[0]);
    store
      .dispatch(POST_EXCEL_BAJA_STOCK, {
        companyId: company_id,
        payload: formData
      })
      .then(() => {
        notification(
          'success',
          'Correcto',
          'Baja al stock realizada con éxito'
        );
        emit('uploaded', true);
        closeModal();
      })
      .catch((error) => {
        notification('error', 'Error', error.response.data.message);
      })
      .finally(() => {
        loading.value = false;
      });
  });
};

const closeModal = () => {
  visible.value = false;
  onCloseModal();
};

const downloadFormat = () => {
  form_validation_type.value = 'download';
  form_ref.value.validate().then(() => {
    store
      .dispatch(FETCH_BAJA_STOCK_FORMAT, {
        companyId: company_id,
        insuranceCompanyId: form_state.insurance_company
      })
      .then((resp) => {
        const link = document.createElement('a');
        link.href = resp;
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  });
};

defineExpose({
  openModal
});

const visible = ref(false);
const form_ref = ref();
const form_state = reactive({
  insurance_company: '',
  file_list: []
});
const rules = {
  insurance_company: [
    {
      required: true,
      message: 'Seleccione una aseguradora',
      trigger: 'change',
      type: 'number'
    }
  ],
  file_list: [
    {
      required: true,
      message: 'Ingrese un archivo',
      validator: checkFiles
    }
  ]
};
</script>
