<template>
  <page-header title="Ticket baja masiva" :breadcrumRoutes="routes">
    <template #tags>
      <tags color="processing">
        ID: {{ ticketId }}
      </tags>
    </template>

    <template #buttons v-if="stateTicketButtonEjecutar || stateTicketButtonCancelar">
      <a-button @click="handleCancel" :loading="loading" :disabled="loading">Cancelar</a-button>
      <a-button class="ml-2" @click="handlePreSuccess" :loading="loading" :disabled="loading"
        type="primary">Ejecutar</a-button>
    </template>
  </page-header>

  <lista-tickets-masivos title="Listado de bajas" defaultRedirect="detalle_baja" :data="altasData"
    :loading="loadingTicketMasivo" />

  <a-card class="mt-4">
    <a-row :gutter="16">
      <a-col :span="12">
        <datos-ticket :data="tableData" title="Datos ticket" :loadTicket="loadTicket" />
        <mensaje-aseguradora :message="insuranceMessage" />
      </a-col>

      <a-col :span="12">
        <documentos-adjuntos title="Documentos adjuntos" :data="attachedFiles" :loading="loadingDocument" />
        <comments v-if="user_role != 'rrhh'" class="mt-2" :comments="comments" ticketType="masive"
          :idToTicket="ticketId" @new_comment="getNewCommment" />
      </a-col>
    </a-row>
  </a-card>

  <a-modal v-model:visible="showModalCancel" @ok="handleOk" centered title="Cancelación de ticket de baja masiva"
    :maskClosable="false">
    <a-typography class="mb-4">
      Para cancelar el ticket de baja masiva debe enviar un email a la
      compañía aseguradora.
    </a-typography>

    <a-form name="form" layout="vertical" ref="formRef" :model="formState" :rules="rules">
      <a-typography-title class="text-gray-8 fw-regular mb-1" strong :level="4">
        Email de cancelación para compañía aseguradora
      </a-typography-title>
      <a-typography-title class="text-gray-8 fw-regular mb-1" strong :level="5">
      </a-typography-title>
      <a-col :span="24" class="mt-4">
        <a-form-item has-feedback name="message">
          <a-textarea :rows="4" v-model:value="formState.message" />
        </a-form-item>
      </a-col>
      <a-col :span="24" class="mt-4">
        <a-form-item has-feedback name="fileList">
          <a-upload name="avatar" v-model:fileList="formState.fileList" :remove="handleRemove"
            :before-upload="beforeUpload">
            <a-button>
              <upload-outlined></upload-outlined>
              Subir archivo
            </a-button>
          </a-upload>
        </a-form-item>
      </a-col>
    </a-form>
    <template #footer>
      <a-row type="flex" justify="end">
        <a-col>
          <a-space>
            <a-button key="submit" type="default" @click="handleCancelBajaButton">
              Volver atrás
            </a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleCancelBaja">
              Confirmar
            </a-button>
          </a-space>
        </a-col>
      </a-row>
    </template>
  </a-modal>

  <a-modal v-model:visible="visible" :title="modalStyle.title" @ok="handleOk" centered :maskClosable="false">
    <a-typography-title class="text-gray-4 fw-regular mb-4" :level="4">
      {{ modalStyle.text }}
    </a-typography-title>
    <a-row :gutter="16" align="middle">
      <a-col :span="3" class="mr-2">
        <info-circle-outlined v-if="modalStyle.contentText !== 'Sin casos pendientes'"
          :style="{ fontSize: '21px', color: '#FAAD14' }" />
        <check-circle-outlined v-if="modalStyle.contentText === 'Sin casos pendientes'"
          :style="{ fontSize: '21px', color: '#00DC92' }" />
      </a-col>
      <a-col :span="20">
        <a-typography-title class="text-gray-4 fw-regular ml-2" :level="5">
          {{ modalStyle.contentText }}
        </a-typography-title>
      </a-col>
    </a-row>

    <template #footer>
      <a-row type="flex" justify="end">
        <a-col>
          <a-space>
            <a-button @click="handleOk">Volver atrás</a-button>
            <a-button v-if="modalStyle.mode === 'successTicket'" key="submit" type="primary" :loading="loading"
              @click="handleSuccess">
              Confirmar
            </a-button>
          </a-space>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import { reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons-vue';
// Store
import { message } from 'ant-design-vue';
import { UploadOutlined } from '@ant-design/icons-vue';
import {
  GET_AM_DETAIL,
  FETCH_AM_DETAIL,
  GET_AM_DETAIL_FILE,
  FETCH_AM_DETAIL_FILE,
  // PRECANCEL_ALTA_MASIVA,
  PRESUCCESS_ALTA_MASIVA,
  SUCCESS_BAJA_MASIVA,
  CANCEL_BAJA_MASIVA,
  FETCH_MASIVE_COMMENTS,
  GET_USER_ROLE,
  FETCH_USER_BY_ID
} from '@/store/types';
import parseDate from '@/utils/dateFormatter';
// Components
import PageHeader from '@/components/PageHeader.vue';
import Tags from '@/components/Tags.vue';
import DatosTicket from '@/components/Tickets/TicketData.vue';
import Comments from '@/components/Tickets/Comments.vue';
import MensajeAseguradora from '@/components/Tickets/MensajeAseguradora.vue';
import DocumentosAdjuntos from '@/components/Tickets/DocumentosAdjuntos.vue';
import ListaTicketsMasivos from '@/components/Tickets/ListaTicketsMasivos.vue';
// Utils
import openNotificationWithIcon from '@/utils/notifications';
import rutHelper from '@/utils/Ruts';

export default {
  name: 'BajaMasivaDetail',
  components: {
    'upload-outlined': UploadOutlined,
    comments: Comments,
    'datos-ticket': DatosTicket,
    'mensaje-aseguradora': MensajeAseguradora,
    'documentos-adjuntos': DocumentosAdjuntos,
    'lista-tickets-masivos': ListaTicketsMasivos,
    'info-circle-outlined': InfoCircleOutlined,
    'check-circle-outlined': CheckCircleOutlined,
    'page-header': PageHeader,
    tags: Tags
  },
  setup() {
    const rules = {
      message: [
        {
          required: true,
          message: 'Ingrese un mensaje',
          trigger: 'change',
          type: 'string'
        }
      ],
      fileList: [
        {
          type: 'array',
          required: true,
          message: 'Adjunte un archivo',
          trigger: 'change'
        }
      ]
    };
    const header_state_data = reactive({
      text: '',
      color: '',
      showCancelAndExecuted: false,
      revisado_disabled: false,
      show_buttons: true
    });
    const route = useRoute();
    const formRef = ref();
    const nameFile = ref('');
    const store = useStore();
    const ticketId = ref(route.params.id);

    // * Rutas
    const routes = [
      {
        path: '/bajas',
        breadcrumbName: 'Bajas'
      },
      {
        path: '#',
        breadcrumbName: 'Masiva'
      },
      {
        breadcrumbName: `#${ticketId.value}`
      }
    ];

    /* const records = reactive([]); */
    const formState = reactive({
      message:
        'A través del presente correo solicitamos cancelar la información contenida en este correo, debido a que existe un error en el documento adjunto. Se generará otra solicitud con el archivo correcto.',
      fileList: []
    });
    const handleRemove = () => {
      formState.fileList = [];
      formRef.value.validate('fileList');
    };
    const beforeUpload = (file) => {
      nameFile.value = file.name;
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        message.error('El archivo excede el peso máximo 5MB');
      } else {
        formState.fileList[0] = file;
      }
      return false;
    };
    //* Get Data
    const ticketData = ref({});
    const router = useRouter();
    const stateTicketButtonEjecutar = ref(false);
    const stateTicketButtonCancelar = ref(false);
    const loadingTicketMasivo = ref(false);
    const loadTicket = ref(false);
    const loadingDocument = ref(false);
    const getTicketData = async () => {
      loadTicket.value = true;
      loadingTicketMasivo.value = true;
      await store
        .dispatch(FETCH_AM_DETAIL, ticketId.value)
        .catch(() => router.push({ name: 'Missing' }));
      ticketData.value = await store.getters[GET_AM_DETAIL];
      if (ticketData.value.typeTicketGroupId != 2) {
        router.push({ name: 'Missing' });
      }
      const stateMasiveTicketButton = ticketData.value.ticketGroups.map((e) =>
        e.states.map((f) => f.state).pop()
      );
      console.log('stateMasiveTicketButton', stateMasiveTicketButton.length);
      if (stateMasiveTicketButton.length) {
        const d = stateMasiveTicketButton.filter(
          (f) => f.tag == 'Cancelado' || f.tag == 'Ejecutado'
        );
        if (d.length < stateMasiveTicketButton.length) {
          stateTicketButtonEjecutar.value = true;
          stateTicketButtonCancelar.value = true;
        }
      }
      loadTicket.value = false;
      loadingTicketMasivo.value = false;
      // if(stateMasiveTicketButton==true){
      //   stateTicketButtonEjecutar.value=false;
      //   stateTicketButtonCancelar.value=false;
      // }else{
      //   stateTicketButtonEjecutar.value=true;
      //   stateTicketButtonCancelar.value=true;
      // }
    };
    getTicketData().then(() => {
      fillTableData();
      getBroker();
      fillIndividualTable();
    });

    const attachedFiles = ref([]);
    const getAttachedFiles = async () => {
      loadingDocument.value = true;
      try {
        await store
          .dispatch(FETCH_AM_DETAIL_FILE, ticketId.value)
          .catch((err) =>
            openNotificationWithIcon(
              'error',
              'Error',
              `Error al cargar archivo del Ticket. ${err.response.data ? err.response.data.message : 'error'
              }`
            )
          );
        attachedFiles.value = await store.getters[GET_AM_DETAIL_FILE];
        loadingDocument.value = false;
      } catch (error) {
        console.log('error', error);
        openNotificationWithIcon(
          'error',
          'Error',
          error.response.data ? error.response.data.message : 'error'
        );
        loadingDocument.value = false;
      }
    };
    getAttachedFiles();

    const tableData = reactive([
      { key: 'Broker', value: '' },
      { key: 'Aseguradora', value: '' },
      { key: 'Holding', value: '' },
      { key: 'Subholding', value: '' },
      { key: 'Empresa', value: '' },
      { key: 'RUT Empresa', value: '' }
    ]);
    const insuranceMessage = ref('');

    const fillTableData = () => {
      tableData[1].value = ticketData.value.object.insuranceCompany;
      tableData[2].value = ticketData.value.object.holding;
      tableData[3].value = ticketData.value.object.subHolding;
      tableData[4].value = ticketData.value.object.company;
      tableData[5].value = rutHelper.formatRut(
        ticketData.value.object.rutCompany
      );

      insuranceMessage.value = ticketData.value.message;
    };

    const getBroker = async () => {
      const response = await store.dispatch(
        FETCH_USER_BY_ID,
        ticketData.value.object.brokerId
      );
      tableData[0].value = response.data.body.name;
    };

    const altasData = ref([]);
    const fillIndividualTable = () => {
      console.log('tickeDataaaa', ticketData.value);
      altasData.value = ticketData.value.ticketGroups.map((a) => {
        return {
          key: a.id,
          id: a.id,
          group: a.employee.employeeGroups,
          employeeName: `${a.employee.firstName} ${a.employee.firstSurname}`,
          employeeRut: rutHelper.formatRut(a.employee.rut),
          ticketState: a.states[a.states.length - 1].state.id,
          pendingCases: a.cases === 0 ? 'No' : 'Sí'
        };
      });
      const last = altasData.value.map((e) => e.ticketState)[0].toString();

      switch (last) {
        case 'CREADO':
          header_state_data.showCancelAndExecuted = true;
          header_state_data.text = 'Creado';
          header_state_data.color = 'blue';
          break;
        case 'EN REVISION ASEGURADORA':
          header_state_data.showCancelAndExecuted = true;
          header_state_data.text = 'En revisión aseguradora';
          header_state_data.color = 'gold';
          header_state_data.show_buttons = true;
          break;
        case 'EJECUTADO':
          header_state_data.showCancelAndExecuted = false;
          header_state_data.text = 'Ejecutado';
          header_state_data.color = 'green';
          break;
        case 'CANCELADO':
          header_state_data.showCancelAndExecuted = false;
          header_state_data.text = 'Cancelado';
          header_state_data.color = 'red';
          break;
        default:
          // TODO: En caso de que reciba cualquier otro estado que no deba
          header_state_data.text = last;
          header_state_data.color = 'default';
          break;
      }
    };
    // const showcancelAndEjecutar = computed(()=>altasData.value.some(d=>{
    //   if(d.ticketState == 'CANCELADO'|| d.ticketState=='EJECUTADO'){
    //     return false;
    //   }
    //   return true;
    // }));
    const showcancelAndEjecutar = () => {
      // switch (last) {
      // case 'Creado':
      //   header_state_data.showCancelAndExecuted = true;
      //   header_state_data.text = 'Creado';
      //   header_state_data.color = 'blue';
      //   break;
      // case 'En revision Aseguradora':
      //   header_state_data.showCancelAndExecuted = true;
      //   header_state_data.text = 'En revisión aseguradora';
      //   header_state_data.color = 'gold';
      //   header_state_data.show_buttons = true;
      //   break;
      // case 'Ejecutado':
      //   header_state_data.showCancelAndExecuted = true;
      //   header_state_data.text = 'Ejecutado';
      //   header_state_data.color = 'green';
      //   break;
      // case 'Cancelado':
      //   header_state_data.showCancelAndExecuted = false;
      //   header_state_data.text = 'Cancelado';
      //   header_state_data.color = 'red';
      //   break;
      // default:
      //   // TODO: En caso de que reciba cualquier otro estado que no deba
      //   header_state_data.text = 'holaaaa';
      //   header_state_data.color = 'default';
      //   break;
      // }
    };
    showcancelAndEjecutar();

    const payloadObject = ref({});
    const loading = ref(false);
    const visible = ref(false);
    const showModalCancel = ref(false);
    const modalStyle = reactive({
      title: '',
      text: '',
      contentText: '',
      mode: ''
    });

    const showModal = () => {
      visible.value = true;
      loading.value = false;
    };

    const handleOk = () => {
      visible.value = false;
      loading.value = false;
    };
    const handleCancelBajaButton = () => {
      showModalCancel.value = false;
    };
    const orderDataTicketMasive = ref();
    const handleCancel = async () => {
      showModalCancel.value = true;
      orderDataTicketMasive.value = ticketData.value.ticketGroups
        .map((e) => e.states.pop())
        .filter((f) => f.state.tag !== 'Ejecutado');
    };
    const handleCancelBaja = async () => {
      formRef.value.validate().then(async () => {
        loading.value = true;
        try {
          await store
            .dispatch(CANCEL_BAJA_MASIVA, {
              idAlta: ticketId.value,
              payload: formState
            })
            .then(() => {
              openNotificationWithIcon(
                'success',
                'Correcto',
                `Ticket ${ticketId.value} cancelado exitosamente`
              );
              getTicketData().then(() => {
                fillTableData();
                getBroker();
                fillIndividualTable();
              });
              stateTicketButtonEjecutar.value = false;
              stateTicketButtonCancelar.value = false;
              formState.message =
                'A través del presente correo solicitamos cancelar la información contenida en este correo, debido a que existe un error en el documento adjunto. Se generará otra solicitud con el archivo correcto.';
              formState.fileList[0] = [];
              showModalCancel.value = false;
              loading.value = false;
            });
        } catch (error) {
          loading.value = false;
          openNotificationWithIcon(
            'error',
            'Error',
            `No se ha podido cancelar el ticket ${ticketId.value}. ${error.response.data ? error.response.data.message : 'error'
            }`
          );
        }
      });
    };

    const handlePreSuccess = async () => {
      let cantTickets = null;
      let pendingCount = 0;
      await store
        .dispatch(PRESUCCESS_ALTA_MASIVA, ticketId.value)
        .then((res) => {
          cantTickets = res.ticketSuccess.length;
          if (cantTickets === 0) {
            openNotificationWithIcon(
              'error',
              'Error',
              'No se ha podido cargar la información para ejecutar el ticket. No existe ningún ticket para ejecutar.'
            );
          } else {
            res.ticketPending.map(() => {
              pendingCount++;
            });
            modalStyle.mode = 'successTicket';
            modalStyle.title = 'Ejecutar ticket de baja masiva';
            modalStyle.text = `¿Estás seguro de que deseas ejecutar ${cantTickets} tickets de baja masiva?`;
            if (cantTickets === 1) {
              modalStyle.text = `¿Estás seguro de que deseas ejecutar ${cantTickets} ticket de baja masiva?`;
            }
            modalStyle.contentText =
              pendingCount > 0
                ? `No se están considerando ${pendingCount} tickets pendientes con casos asociados.`
                : 'Sin casos pendientes';
            if (pendingCount === 1) {
              modalStyle.contentText = `No se está considerando ${pendingCount} ticket pendiente con casos asociados.`;
            }
            payloadObject.value = { result: res };
            showModal();
          }
        })
        .catch((err) =>
          openNotificationWithIcon(
            'error',
            'Error',
            `No se ha podido cargar la información para ejecutar el ticket. ${err.response.data ? err.response.data.message : 'error'
            }.`
          )
        );
    };

    // Llenar el historial con Comentarios
    /* const fillRecordsWithComents = (data) =>{
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        records.push({
          title:'Nuevo comentario',
          description:element.createdAt
        });
      }
    }; */

    const handleSuccess = async () => {
      loading.value = true;
      try {
        await store
          .dispatch(SUCCESS_BAJA_MASIVA, {
            idAlta: ticketId.value,
            payload: payloadObject.value
          })
          .then(() => {
            openNotificationWithIcon(
              'success',
              'Correcto',
              `Ticket ${ticketId.value} ejecutado exitosamente`
            );
            getTicketData().then(() => {
              fillTableData();
              getBroker();
              fillIndividualTable();
            });
            loading.value = false;
            stateTicketButtonEjecutar.value = false;
            stateTicketButtonCancelar.value = false;
            visible.value = false;
          });
      } catch (error) {
        loading.value = false;
        openNotificationWithIcon(
          'error',
          'Error',
          `No se ha podido ejecutar el ticket ${ticketId.value}. ${error.response.data ? error.response.data.message : 'error'
          }`
        );
      }
    };

    // * Comentarios
    // rol de  usuario
    const user_role = store.getters[GET_USER_ROLE];

    // Obtencion de comentarios
    const comments = ref([]);
    const getComments = async () => {
      comments.value = [];
      const resp = await store.dispatch(FETCH_MASIVE_COMMENTS, ticketId.value);
      for (let i = 0; i < resp.length; i++) {
        const element = resp[i];
        const broker_name = await getBrokerName(element.brokerId);
        comments.value.push({
          description: parseDate(element.createdAt),
          title: element.message,
          author: broker_name
        });
      }
    };

    const getBrokerName = async (id) => {
      const response = await store.dispatch(FETCH_USER_BY_ID, id);
      return response.data.body.name;
    };

    const getNewCommment = async (value) => {
      const { description } = value;
      const { brokerId } = value;
      const brokerName = await getBrokerName(brokerId);

      comments.value.push({
        title: value.title,
        description: parseDate(description),
        author: brokerName
      });
    };

    getComments();

    return {
      // Variables
      rules,
      handleCancelBaja,
      header_state_data,
      formRef,
      beforeUpload,
      handleRemove,
      formState,
      comments,
      loading,
      visible,
      showModalCancel,
      modalStyle,
      altasData,
      routes,
      ticketId,
      tableData,
      insuranceMessage,
      attachedFiles,
      handleCancelBajaButton,
      user_role,
      // Methods
      handleCancel,
      handlePreSuccess,
      handleSuccess,
      handleOk,
      getNewCommment,
      stateTicketButtonEjecutar,
      stateTicketButtonCancelar,
      loadingTicketMasivo,
      loadTicket,
      loadingDocument
    };
  }
};
</script>
