<template>
  <div>
    <a-modal
      title="Editar datos de factura"
      :visible="modal_props.visible"
      @cancel="onClose"
      :closable="!modal_props.buttons_loading"
      :maskClosable="!modal_props.buttons_loading"
    >
      <a-skeleton
        :loading="modal_props.data_loading"
        :active="modal_props.data_loading"
      >
        <p>Ingresa manualmente los datos reconocidos por la plataforma</p>

        <a-form
          ref="form_ref"
          :model="form_state"
          :rules="rules"
          layout="vertical"
        >
          <a-form-item label="N° de factura" ref="folio" name="folio">
            <a-input-number
              style="width: 100%"
              v-model:value="form_state.folio"
              :maxlength="99999999999999999999"
              placeholder="Ingrese nº de factura"
            />
          </a-form-item>

          <a-form-item
            label="Fecha de emisión"
            ref="issueDate"
            name="issueDate"
          >
            <a-date-picker
              v-model:value="form_state.issueDate"
              format="DD/MM/YYYY"
              style="width: 100%"
            />
          </a-form-item>

          <a-form-item
            label="Razón social"
            ref="companyIdRecipient"
            name="companyIdRecipient"
          >
            <a-select
              :options="companies_options"
              v-model:value="form_state.companyIdRecipient"
              placeholder="Ingresa razón social"
              allowClear
            />
          </a-form-item>
        </a-form>
      </a-skeleton>

      <template #footer>
        <a-button
          @click="onClose"
          :disabled="modal_props.buttons_loading || modal_props.data_loading"
          >Cancelar</a-button
        >
        <a-button
          type="primary"
          @click="formSubmit"
          :disabled="modal_props.data_loading"
          :loading="modal_props.buttons_loading"
          >Guardar</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import {
  FETCH_INVOICE,
  GET_INVOICE,
  PATCH_INVOICE,
  FETCH_COMPANIES_INVOICE,
  GET_COMPANIES_INVOICE
} from '@/store/types';
import notification from '@/utils/notifications';

export default {
  name: 'EditInvoiceDataModal',
  setup(_, { emit }) {
    const store = useStore();

    const modal_props = reactive({
      id: '',
      visible: false,
      buttons_loading: false,
      data_loading: false
    });

    const companies_options = ref([]);

    const resetFields = () => {
      form_ref.value.resetFields();
    };

    const onClose = () => {
      modal_props.visible = false;
      resetFields();
    };

    // * Formulario
    const form_ref = ref();
    const form_state = reactive({
      folio: null,
      issueDate: null,
      companyIdRecipient: null
    });

    const rules = {
      folio: [
        {
          required: true,
          message: 'Ingrese numero de factura',
          trigger: 'change',
          type: 'integer'
        }
      ],
      issueDate: [
        {
          required: true,
          message: 'Ingrese fecha de emisión',
          trigger: 'change',
          type: 'object'
        }
      ],
      companyIdRecipient: [
        {
          required: true,
          message: 'Ingrese razón social',
          trigger: 'change',
          type: 'number'
        }
      ]
    };

    const emitPolicyEdition = () => {
      emit('policyEdition', true);
    };

    const patchInvoice = async (form) => {
      modal_props.buttons_loading = true;

      try {
        await store.dispatch(PATCH_INVOICE, {
          id: modal_props.id,
          payload: form
        });

        emitPolicyEdition();

        notification('success', 'Correcto', 'Factura editada correctamente');
        onClose();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }

      modal_props.buttons_loading = false;
    };

    const formSubmit = async () => {
      try {
        console.log('aaaa');
        const form_resp = await form_ref.value.validate();
        form_resp.issueDate = form_resp.issueDate.format('MM-DD-YYYY'); // cambio el formato de la fecha
        patchInvoice(form_resp);
      } catch (error) {
        console.error('error', error);
      }
    };

    // * funcion principal
    const openModal = async (invoice_id) => {
      // guardo el id
      modal_props.id = invoice_id;

      modal_props.visible = true;
      modal_props.data_loading = true;

      try {
        await store.dispatch(FETCH_COMPANIES_INVOICE, invoice_id);
        companies_options.value = store.getters[GET_COMPANIES_INVOICE].map(
          (obj) => {
            return {
              value: obj.id,
              label: obj.businessName
            };
          }
        );

        // Obtengo los datos de la factura
        await store.dispatch(FETCH_INVOICE, invoice_id);
        const { folio, issueDate, companyIdRecipient } =
          store.getters[GET_INVOICE];

        form_state.folio = Number(folio);
        form_state.issueDate =
          issueDate != null ? moment(issueDate, 'YYYY-MM-DD') : null;
        form_state.companyIdRecipient = companyIdRecipient;
      } catch (error) {
        console.error(error);
      }

      modal_props.data_loading = false;
    };

    return {
      modal_props,
      form_ref,
      form_state,
      rules,
      companies_options,
      onClose,
      formSubmit,
      openModal
    };
  }
};
</script>
