import {
  TicketTableQuery,
  TicketTableResume,
  TicketTableQueryCases,
  TicketTableQueryGroups,
  TicketResendFormIncorporation
} from '../../../network';
import {
  FETCH_TABLE_TICKETS,
  FETCH_TABLE_TICKETS_TEST,
  FETCH_TABLE_RESUME,
  FETCH_TABLE_CASES,
  FETCH_TABLE_MASIVE_TICKETS,
  POST_RESEND_FORM_INCORPORATION_TICKET,
  MUTATE_TABLE_ROW,
  MUTATE_TABLE_TOTAL_PAGES,
  MUTATE_TABLE_ITEMS_PER_PAGE,
  MUTATE_TABLE_CURRENT_PAGE,
  MUTATE_TABLE_FILTERS,
  MUTATE_TABLE_RESUME,
  MUTATE_CASES_TABLE_ROW,
  MUTATE_CASES_TABLE_TOTAL_PAGES,
  MUTATE_CASES_TABLE_ITEMS_PER_PAGE,
  MUTATE_CASES_TABLE_CURRENT_PAGE,
  MUTATE_CASES_TABLE_FILTERS,
  MUTATE_GROUPS_TABLE_ROW,
  MUTATE_GROUPS_TABLE_TOTAL_PAGES,
  MUTATE_GROUPS_TABLE_ITEMS_PER_PAGE,
  MUTATE_GROUPS_TABLE_CURRENT_PAGE,
  MUTATE_GROUPS_TABLE_FILTERS,
  GETTER_TICKET_TABLE_TOTAL_PAGES,
  GETTER_TICKET_TABLE_ITEMS_PER_PAGE,
  GETTER_TICKET_TABLE_CURRENT_PAGE,
  GETTER_TICKET_TABLE_ROWS,
  GETTER_TICKET_TABLE_FILTERS,
  GETTER_TICKET_TABLE_RESUME,
  GETTER_CASES_TICKET_TABLE_ROWS,
  GETTER_CASES_TICKET_TABLE_TOTAL_PAGES,
  GETTER_CASES_TICKET_TABLE_ITEMS_PER_PAGE,
  GETTER_CASES_TICKET_TABLE_CURRENT_PAGE,
  GETTER_CASES_TICKET_TABLE_FILTERS,
  GETTER_GROUPS_TICKET_TABLE_ROWS,
  GETTER_GROUPS_TICKET_TABLE_TOTAL_PAGES,
  GETTER_GROUPS_TICKET_TABLE_ITEMS_PER_PAGE,
  GETTER_GROUPS_TICKET_TABLE_CURRENT_PAGE,
  GETTER_GROUPS_TICKET_TABLE_FILTERS
} from './types';

const store = {};

store.state = {
  resume: {},
  tickets: {
    rows: [],
    total_pages: 0,
    items_per_page: 0,
    current_page: 0,
    filters: {}
  },
  cases: {
    rows: [],
    total_pages: 0,
    items_per_page: 0,
    current_page: 0,
    filters: {}
  },
  groups: {
    rows: [],
    total_pages: 0,
    items_per_page: 0,
    current_page: 0,
    filters: {}
  }
};

store.getters = {
  // Tickets
  [GETTER_TICKET_TABLE_ROWS](state) {
    return state.tickets.rows;
  },
  [GETTER_TICKET_TABLE_TOTAL_PAGES](state) {
    return state.tickets.total_pages;
  },
  [GETTER_TICKET_TABLE_ITEMS_PER_PAGE](state) {
    return state.tickets.items_per_page;
  },
  [GETTER_TICKET_TABLE_CURRENT_PAGE](state) {
    return state.tickets.current_page;
  },
  [GETTER_TICKET_TABLE_FILTERS]: (state) => (type) => {
    return state.tickets.filters[type];
  },
  // Cases
  [GETTER_CASES_TICKET_TABLE_ROWS](state) {
    return state.cases.rows;
  },
  [GETTER_CASES_TICKET_TABLE_TOTAL_PAGES](state) {
    return state.cases.total_pages;
  },
  [GETTER_CASES_TICKET_TABLE_ITEMS_PER_PAGE](state) {
    return state.cases.items_per_page;
  },
  [GETTER_CASES_TICKET_TABLE_CURRENT_PAGE](state) {
    return state.cases.current_page;
  },
  [GETTER_CASES_TICKET_TABLE_FILTERS]: (state) => (type) => {
    return state.cases.filters[type];
  },
  // Groups
  [GETTER_GROUPS_TICKET_TABLE_ROWS](state) {
    return state.groups.rows;
  },
  [GETTER_GROUPS_TICKET_TABLE_TOTAL_PAGES](state) {
    return state.groups.total_pages;
  },
  [GETTER_GROUPS_TICKET_TABLE_ITEMS_PER_PAGE](state) {
    return state.groups.items_per_page;
  },
  [GETTER_GROUPS_TICKET_TABLE_CURRENT_PAGE](state) {
    return state.groups.current_page;
  },
  [GETTER_GROUPS_TICKET_TABLE_FILTERS]: (state) => (type) => {
    return state.groups.filters[type];
  },
  [GETTER_TICKET_TABLE_RESUME](state) {
    return state.resume;
  }
};

store.mutations = {
  // Tickets
  [MUTATE_TABLE_ROW](state, payload) {
    state.tickets.rows = payload;
  },
  [MUTATE_TABLE_TOTAL_PAGES](state, payload) {
    state.tickets.total_pages = payload;
  },
  [MUTATE_TABLE_ITEMS_PER_PAGE](state, payload) {
    state.tickets.items_per_page = payload;
  },
  [MUTATE_TABLE_CURRENT_PAGE](state, payload) {
    state.tickets.current_page = payload;
  },
  [MUTATE_TABLE_FILTERS](state, payload) {
    state.tickets.filters[Object.keys(payload)[0]] =
      payload[Object.keys(payload)[0]];
  },
  // Casos
  [MUTATE_CASES_TABLE_ROW](state, payload) {
    state.cases.rows = payload;
  },
  [MUTATE_CASES_TABLE_TOTAL_PAGES](state, payload) {
    state.cases.total_pages = payload;
  },
  [MUTATE_CASES_TABLE_ITEMS_PER_PAGE](state, payload) {
    state.cases.items_per_page = payload;
  },
  [MUTATE_CASES_TABLE_CURRENT_PAGE](state, payload) {
    state.cases.current_page = payload;
  },
  [MUTATE_CASES_TABLE_FILTERS](state, payload) {
    state.cases.filters[Object.keys(payload)[0]] =
      payload[Object.keys(payload)[0]];
  },
  // Ticket group
  [MUTATE_GROUPS_TABLE_ROW](state, payload) {
    state.groups.rows = payload;
  },
  [MUTATE_GROUPS_TABLE_TOTAL_PAGES](state, payload) {
    state.groups.total_pages = payload;
  },
  [MUTATE_GROUPS_TABLE_ITEMS_PER_PAGE](state, payload) {
    state.groups.items_per_page = payload;
  },
  [MUTATE_GROUPS_TABLE_CURRENT_PAGE](state, payload) {
    state.groups.current_page = payload;
  },
  [MUTATE_GROUPS_TABLE_FILTERS](state, payload) {
    state.groups.filters[Object.keys(payload)[0]] =
      payload[Object.keys(payload)[0]];
  },
  [MUTATE_TABLE_RESUME](state, payload) {
    state.resume = payload;
  }
};

store.actions = {
  [FETCH_TABLE_TICKETS_TEST](_, payload) {
    return new Promise((resolve, reject) => {
      TicketTableQuery(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_TABLE_TICKETS]({ commit }, { payload, signal }) {
    return new Promise((resolve, reject) => {
      TicketTableQuery(payload, signal)
        .then((resp) => {
          commit(MUTATE_TABLE_ROW, resp.data.rows);
          commit(MUTATE_TABLE_TOTAL_PAGES, resp.data.total_items);
          commit(MUTATE_TABLE_ITEMS_PER_PAGE, resp.data.items_per_page);
          commit(MUTATE_TABLE_CURRENT_PAGE, resp.data.page);
          resolve(resp.status);
        })
        .catch((error) => {
          commit(MUTATE_TABLE_ROW, []);
          commit(MUTATE_TABLE_TOTAL_PAGES, 0);
          commit(MUTATE_TABLE_ITEMS_PER_PAGE, 0);
          commit(MUTATE_TABLE_CURRENT_PAGE, 0);
          reject(error);
        });
    });
  },
  [FETCH_TABLE_CASES]({ commit }, { payload, signal }) {
    return new Promise((resolve, reject) => {
      TicketTableQueryCases(payload, signal)
        .then((resp) => {
          commit(MUTATE_CASES_TABLE_ROW, resp.data.rows);
          commit(MUTATE_CASES_TABLE_TOTAL_PAGES, resp.data.total_items);
          commit(MUTATE_CASES_TABLE_ITEMS_PER_PAGE, resp.data.items_per_page);
          commit(MUTATE_CASES_TABLE_CURRENT_PAGE, resp.data.page);
          resolve(resp);
        })
        .catch((error) => {
          commit(MUTATE_CASES_TABLE_ROW, []);
          commit(MUTATE_CASES_TABLE_TOTAL_PAGES, 0);
          commit(MUTATE_CASES_TABLE_ITEMS_PER_PAGE, 0);
          commit(MUTATE_CASES_TABLE_CURRENT_PAGE, 0);
          reject(error);
        });
    });
  },
  [FETCH_TABLE_MASIVE_TICKETS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TicketTableQueryGroups(payload)
        .then((resp) => {
          commit(MUTATE_GROUPS_TABLE_ROW, resp.data.rows);
          commit(MUTATE_GROUPS_TABLE_TOTAL_PAGES, resp.data.total_items);
          commit(MUTATE_GROUPS_TABLE_ITEMS_PER_PAGE, resp.data.items_per_page);
          commit(MUTATE_GROUPS_TABLE_CURRENT_PAGE, resp.data.current_page);
          resolve(resp.status);
        })
        .catch((error) => {
          commit(MUTATE_GROUPS_TABLE_ROW, []);
          commit(MUTATE_GROUPS_TABLE_TOTAL_PAGES, 0);
          commit(MUTATE_GROUPS_TABLE_ITEMS_PER_PAGE, 0);
          commit(MUTATE_GROUPS_TABLE_CURRENT_PAGE, 0);
          reject(error);
        });
    });
  },
  [POST_RESEND_FORM_INCORPORATION_TICKET](_, payload) {
    return new Promise((resolve, reject) => {
      TicketResendFormIncorporation(payload.id, payload.email)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_TABLE_RESUME]({ commit }, { payload, signal }) {
    return new Promise((resolve, reject) => {
      TicketTableResume(payload, signal)
        .then((resp) => {
          commit(MUTATE_TABLE_RESUME, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default store;
