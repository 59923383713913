<template>
  <div>
    <a-layout style="min-height: 100vh">
      <sidebar />

      <a-layout>
        <navbar />

        <a-layout-content :style="{ margin: '3.5rem' }">
          <router-view v-slot="{ Component, route }">
            <transition
              :name="route.meta.transition === false ? '' : 'fade'"
              mode="out-in"
            >
              <div :key="route.name">
                <component :is="Component"></component>
              </div>
            </transition>
          </router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import Sidebar from '../components/dashboard/Sidebar.vue';
import Navbar from '../components/dashboard/Navbar.vue';

export default {
  name: 'DashboardTemplate',
  components: {
    sidebar: Sidebar,
    navbar: Navbar
  },
  setup() {}
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
