<template>
  <div>
    <page-header :breadcrumRoutes="routes_pageheader" title="Stock Global" />

    <a-card class="mt-2">
      <a-row type="flex" justify="space-between" align="middle">
        <a-col>
          <a-typography-title :level="4" class="text-gray-8 mb-0">
            Stock Global
          </a-typography-title>
        </a-col>

        <a-range-picker v-if="tickets_type_selection === 'tickets'" v-model:value="dante_range_individual"
          :value-format="date_format" :format="[date_format, date_format]" />

        <a-range-picker v-else v-model:value="dante_range_masive" :value-format="date_format"
          :format="[date_format, date_format]" />
      </a-row>
      <a-row class="mt-3">
        <a-col :span="24">
          <a-tabs :activeKey="tickets_type_selection" @change="onChangeTabs">
            <a-tab-pane key="tickets" tab="Tickets">
              <table-results :key="render_table_key" v-if="tickets_type_selection === 'tickets'" type="tickets"
                :dates="dante_range_individual" />
            </a-tab-pane>
            <a-tab-pane key="cases" tab="Casos">
              <table-results :key="render_table_key" v-if="tickets_type_selection === 'cases'" type="cases"
                :dates="dante_range_masive" />
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { ref, defineAsyncComponent, watch } from 'vue';
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PageHeader from '@/components/PageHeader.vue';

const TableResults = defineAsyncComponent(() =>
  import('@/components/globalStock/TableResults.vue')
);

export default {
  name: 'Dashboard',
  components: {
    'page-header': PageHeader,
    'table-results': TableResults
  },
  setup() {
    // * Breadcrum
    const routes_pageheader = [
      {
        breadcrumbName: 'Stock Global'
      }
    ];

    // * VUEX
    const route = useRoute();
    const Router = useRouter();

    // * Tabs
    const tickets_type_selection = ref('');
    tickets_type_selection.value = route.hash === '' ? 'tickets' : 'cases';
    const tickets_routes = ref({ keywords: route.query.keywords });
    const cases_routes = ref({ keywords: route.query.keywords });

    const onChangeTabs = async (value) => {
      if (value === 'cases') {
        // Guardo los filtros que hice en individual
        tickets_routes.value = route.query;

        // Redireccion
        await Router.replace({
          name: 'GlobalStock',
          query: cases_routes.value,
          hash: '#cases'
        });
      } else {
        // Guardo los filtros que hice en individual
        cases_routes.value = route.query;
        // Redireccion
        await Router.replace({
          name: 'GlobalStock',
          query: tickets_routes.value
        });
      }

      tickets_type_selection.value = value;
    };

    // * Forzar el re renderizado de los componentes de tablas
    /*
    En caso de que se haga la busqueda especifica de una palabra dentro de la misma pagina de global stock, se actualizaria la data
    pero el componente no, manteniendo la data pasada de la busqueda anterior
    para que esto no suceda, voy a renderizar otra vez el componente asi perdera todo estado, variables y valores de la busqueda pasada y 
    asi se haria desde 0
    */
    const render_table_key = ref(0);
    watch(
      () => route.query['keywords'],
      () => {
        render_table_key.value += 1;

        // Ademas si se actualizza la busqueda, obviamente los parametros que guarde anteriormente ya no van a estar
        tickets_routes.value = { keywords: route.query.keywords };
        cases_routes.value = { keywords: route.query.keywords };
      }
    );

    // * Carga de selector de fechas
    const date_format = 'DD-MM-YYYY';
    const dante_range_individual = ref([]);
    const dante_range_masive = ref([]);
    // Cuando cargue la pagina, obtener la fecha y pasarla en el selector
    onMounted(() => {
      if (
        route.query.startDate != undefined &&
        route.query.endDate != undefined
      ) {
        if (route.hash == '#cases') {
          dante_range_masive.value.push(route.query.startDate);
          dante_range_masive.value.push(route.query.endDate);
        } else {
          dante_range_individual.value.push(route.query.startDate);
          dante_range_individual.value.push(route.query.endDate);
        }
      }
    });

    return {
      routes_pageheader,
      date_format,
      dante_range_individual,
      dante_range_masive,
      onChangeTabs,
      tickets_type_selection,
      render_table_key
    };
  }
};
</script>
