<template>
  <div>
    <a-modal
      title="Eliminar cobranza"
      v-model:visible="modal_props.visibility"
      @cancel="closeModal"
      :closable="!modal_props.loading"
      :maskClosable="!modal_props.loading"
    >
      <p>¿Seguro que deseas eliminar esta cobranza?</p>

      <template #footer>
        <a-button @click="closeModal" :disabled="modal_props.loading"
          >Cancelar</a-button
        >
        <a-button
          type="primary"
          danger
          @click="deletePayroll"
          :loading="modal_props.loading"
          >Eliminar</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { DELETE_COLLECTION_RESOURCE } from '../../../store/types';
import notification from '../../../utils/notifications';

export default {
  setup(_, { emit }) {
    // * Variables
    const store = useStore();
    const modal_props = reactive({
      visibility: false,
      loading: false
    });
    const payroll_id = ref('');

    // * Metodos
    const openModal = (id) => {
      payroll_id.value = id;
      modal_props.visibility = true;
    };

    const closeModal = () => {
      modal_props.visibility = false;
    };

    const deletePayroll = async () => {
      modal_props.loading = true;
      try {
        await store.dispatch(DELETE_COLLECTION_RESOURCE, payroll_id.value);
        notification('success', 'Correcto', 'Nomina Eliminada');
        emit('deletedPayroll', true);
      } catch (error) {
        notification('error', 'Error', 'Ocurrio un error, intentalo mas tarde');
      }
      modal_props.loading = false;
    };

    return {
      modal_props,
      openModal,
      closeModal,
      deletePayroll
    };
  }
};
</script>
