<template>
  <a-modal v-model:visible="visibility" title="Editar cobertura" @cancel="closeModal" :closable="!loading"
    :maskClosable="!loading">
    <a-typography-title :level="5">
      Completa los siguientes datos.
    </a-typography-title>
    <a-form name="form" layout="vertical" ref="formRef" :model="formState" :rules="rules">
      <a-form-item required name="requestStartDate" label="Fecha de solicitud de alta">
        <a-date-picker format="DD/MM/YYYY" placeholder="Seleccione fecha" v-model:value="formState.requestStartDate"
          :style="{ width: '100%' }" />
      </a-form-item>
      <a-form-item required name="startDate" label="Fecha de inicio de vigencia">
        <a-date-picker format="DD/MM/YYYY" placeholder="Seleccione fecha" v-model:value="formState.startDate"
          :style="{ width: '100%' }" />
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button @click="closeModal" :loading="loading">Cancelar</a-button>
      <a-button type="primary" @click="validateForm" :loading="loading" :disabled="saveChangesButtonDisabled">Guardar
        cambios</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, reactive, defineExpose, defineEmits, computed } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import {
  PATCH_EMPLOYEE_COVERAGE_REQUEST_DATE,
  PATCH_EMPLOYEE_DEPENDENT
} from '@/store/types';
import notification from '@/utils/notifications';

const emit = defineEmits([
  'patchedEmployecoverage',
  'patchedEmployeeDependent'
]);
const store = useStore();
const loading = ref(false);
const recordData = ref();
const visibility = ref(false);
const formRef = ref();
const rules = {
  startDate: [
    {
      required: true,
      type: 'object',
      trigger: 'change',
      message: 'Seleccione fecha',
      validator: async (rule, value) => {
        console.log('rule', rule);
        console.log('value', value);
        Promise.resolve();
      },
    }
  ],
  requestStartDate: [
    {
      required: true,
      type: 'object',
      trigger: 'change',
      message: 'Seleccione fecha'
    }
  ]
};

const formState = reactive({
  startDate: null,
  requestStartDate: null
});

const saveChangesButtonDisabled = computed(
  () =>
    JSON.stringify({
      startDate: formState.startDate || null,
      requestStartDate: formState.requestStartDate || null
    }) ===
    JSON.stringify({
      startDate: recordData.value.startDate || null,
      requestStartDate: recordData.value.requestStartDate || null
    })
);

const openModal = (record) => {
  recordData.value = record;
  visibility.value = true;
  formState.requestStartDate =
    record.requestStartDate && moment(record.requestStartDate).startOf('day');
  formState.startDate = record.startDate && moment(record.startDate).startOf('day');
};

const closeModal = () => {
  visibility.value = false;
  formRef.value.resetFields();
};

const validateForm = () => {
  formRef.value.validate().then((form) => {
    if (recordData.value.maintainerType === 'cobertura') {
      patchEmployeeCoverageRequestDate(form);
    } else if (recordData.value.maintainerType === 'carga') {
      patchEmployeeDependent(form);
    }
  });
};

const patchEmployeeCoverageRequestDate = async (data) => {
  loading.value = true;
  try {
    await store.dispatch(PATCH_EMPLOYEE_COVERAGE_REQUEST_DATE, {
      id: recordData.value.employeeCoverageId,
      payload: data
    });
    loading.value = true;
    notification('success', 'Correcto', 'Fecha actualizada en la cobertura');
    closeModal();
    emit('patchedEmployecoverage');
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
  loading.value = false;
};

const patchEmployeeDependent = async (data) => {
  loading.value = true;
  try {
    await store.dispatch(PATCH_EMPLOYEE_DEPENDENT, {
      employeeId: recordData.value.employeeId,
      dependentId: recordData.value.id,
      payload: data
    });
    loading.value = true;
    notification('success', 'Correcto', 'Fecha actualizada de la carga');
    closeModal();
    emit('patchedEmployeeDependent');
  } catch (error) {
    notification('error', 'Error', error?.response?.data?.message);
  }
  loading.value = false;
};

defineExpose({ openModal });
</script>
