<template>
  <div>
    <a-modal width="auto" v-model:visible="visible" title="Reabrir Cobranza" centered>
      <a-typography-text :level="5">
        Para reabrir este ticket de cobranza debes cargar la nueva nómina
        enviada por la compañia aseguradora asociada a la(s) póliza(s)
        {{ policyNumber }}.
      </a-typography-text>
      <a-row class="mt-2 mb-4">
        <a-col :span="1">
          <exclamation-circle-outlined :style="{ fontSize: '28px', color: '#FFBF01' }" />
        </a-col>
        <a-col>
          <a-typography-text class="text-gray-8">
            En el caso de tener observaciones cierre la ventana, resuelva las
            observaciones y vuelva a reabrir la cobranza reprocesando el
            documento.
          </a-typography-text>
        </a-col>
      </a-row>
      <a-row class="mb-3" type="flex" justify="space-between">
        <a-col>
          <a-typography-title :level="4" class="mb-0 left-aligned payroll-title">
            Consorcio
          </a-typography-title>
        </a-col>
        <a-col class="ml-4" style="display: flex">
          <payload-upload @payrollUploading="payrollUploading" @payrollUploaded="payrollUploaded" :ticketId="ticketId" />
          <div style="display: inline-block" class="ml-3">
            <a-tooltip @click="getCollectionTicketPayroll">
              <template #title>Recargar Tabla</template>
              <a-button>
                <template #icon>
                  <reload-files-upload />
                </template>
              </a-button>
            </a-tooltip>
          </div>
          <div style="clear: both"></div>
        </a-col>
      </a-row>

      <a-table maxWidth="auto" :loading="loading" :data-source="data_table" :columns="columns" :scroll="{ x: 1100 }">
        <template #custom_id="{ text }">
          <p># {{ text }}</p>
        </template>

        <template #tags="{ text: tags }">
          <a-tag :color="getColorState(tags)">
            {{ getTagText(tags)?.toUpperCase() }}
          </a-tag>
        </template>
        <template #details="{ text: collection_resource_id }">
          <a-row>
            <a-col :span="5">
              <a-tooltip color="#05045D" title="Ver observaciones" @click="
                openObservationModal(
                  collection_ticket_id,
                  collection_resource_id
                )
                ">
                <a-button type="link">
                  <eye-outlined class="blue-icon" />
                  <observation-modal ref="observacion_modal" />
                </a-button>
              </a-tooltip>
            </a-col>
            <a-col :span="5">
              <a-tooltip color="#05045D" title="Reprocesar nómina" @click="
                payrollReprocessing(
                  collection_ticket_id,
                  collection_resource_id
                )
                ">
                <a-button type="link">
                  <file-sync-outlined class="blue-icon" />
                </a-button>
              </a-tooltip>
            </a-col>
            <a-col :span="5">
              <a-tooltip color="#05045D" title="Adjuntar nómina original" @click="
                openModalPayrollOriginal(
                  collection_ticket_id,
                  collection_resource_id
                )
                ">
                <a-button type="link">
                  <paper-clip-outlined class="blue-icon" />
                  <original-payroll ref="original_payroll" @uploadedOriginal="getCollectionTicketPayroll" />
                </a-button>
              </a-tooltip>
            </a-col>
            <a-col :span="5">
              <a-tooltip color="#05045D" title="Eliminar" @click="openDeleteModal(collection_resource_id)">
                <a-button type="link">
                  <delete-outlined class="red-icon" />
                  <delete-modal ref="delete_payroll_modal" @deletedPayroll="deletedPayroll" />
                </a-button>
              </a-tooltip>
            </a-col>
          </a-row>
        </template>
      </a-table>

      <template #footer>
        <a-button @click="closeModal">Cancelar</a-button>
        <a-button type="primary" @click="reopenCollectionFinish" :disabled="enableReopenButton">Reabrir
          cobranza</a-button>
      </template>
    </a-modal>

    <!-- Ticket Abierto -->
    <a-modal v-model:visible="success_modal_visible" title="Reabrir Cobranza">
      <a-result status="success" title="Ticket Abierto" sub-title="Tu ticket de cobranza haa sido reabierto con éxito.">
      </a-result>
      <template #footer>
        <a-button type="primary" @click="closeSuccessModal">Ok</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref, reactive, onMounted, computed, onBeforeMount, watch } from 'vue';
import {
  ExclamationCircleOutlined,
  EyeOutlined,
  ReloadOutlined,
  FileSyncOutlined,
  PaperClipOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import CollectionTickeUploadPayload from './CollectionTickeUploadPayload.vue';
import originalPayroll from './CollectionResourceOriginalPayroll.vue';
import ObservationsModal from './CollectionResourceObservations.vue';
import PayrollDeleteModal from './CollectionResourceDelete.vue';
import getTagText from '@/utils/getTagText';
import notification from '@/utils/notifications';
import {
  FETCH_COLLECTION_TICKET_PAYROLL,
  GET_COLLECTION_TICKET_PAYROLL,
  POST_COLLECTION_CONFIRM_REOPEN,
  POST_COLLECTION_RESOURCE_REPROCESS
} from '@/store/types';
import stateColor from '@/utils/collectionBillingStateColor';

export default {
  components: {
    'exclamation-circle-outlined': ExclamationCircleOutlined,
    'eye-outlined': EyeOutlined,
    'payload-upload': CollectionTickeUploadPayload,
    'reload-files-upload': ReloadOutlined,
    'observation-modal': ObservationsModal,
    'file-sync-outlined': FileSyncOutlined,
    'original-payroll': originalPayroll,
    'paper-clip-outlined': PaperClipOutlined,
    'delete-modal': PayrollDeleteModal,
    'delete-outlined': DeleteOutlined
  },
  props: {
    ticketId: {
      type: [String, Number],
      default: 1
    },
    policyNumber: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const visible = ref(false);
    const router = useRoute();
    const collection_ticket_id = router.params.id;

    const loading = ref(false);
    const rules = {
      messageText: [
        {
          required: true,
          message: 'ingrese mensaje',
          trigger: 'change'
        }
      ]
    };
    const formRef = ref();
    const formState = reactive({
      messageText:
        'Estimado cliente:\n\n' +
        'Adjuntamos cobranzas del periodo {fecha periodo} de la(s) empresa(s) {Nombre(s) de la(s) empresa(s)}.\n\n' +
        'En la revisión realizada se encuentran las siguientes inconsistencias que se veran regularizadas en la próxima cobranza.\n\n' +
        '{Texto escrito por broker}\n\n' +
        'Un cordial saludo'
    });

    const columns = [
      {
        title: 'N°',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'custom_id'
        }
      },
      {
        title: 'Archivo',
        dataIndex: 'file',
        key: 'file'
      },
      {
        title: 'Estado',
        dataIndex: 'tags',
        key: 'tags',
        slots: {
          customRender: 'tags'
        }
      },
      {
        title: 'Observaciones',
        dataIndex: 'observations',
        key: 'observations'
      },
      {
        title: 'Opciones',
        dataIndex: 'details',
        key: 'details',
        slots: {
          customRender: 'details'
        }
      }
    ];

    const getColorState = (value) => {
      return stateColor(value);
    };

    const closeModal = () => {
      visible.value = false;
    };

    const openModal = () => {
      visible.value = true;
    };

    const reopenCollectionFinish = async () => {
      payrollUploading(true);
      try {
        const resp = await store.dispatch(
          POST_COLLECTION_CONFIRM_REOPEN,
          props.ticketId
        );
        if (resp === 200) {
          openSuccessModal();
          closeModal();
          emit('uploadedFile', true);
        }
      } catch (error) {
        closeModal();
        notification('error', 'Error', error.response.data.message);
      }
      payrollUploading(false);
    };

    const enableReopenButton = computed(() => !data_table.value.length > 0);

    // * Modal Correcto
    const success_modal_visible = ref(false);

    const openSuccessModal = () => {
      success_modal_visible.value = true;
    };

    const closeSuccessModal = () => {
      success_modal_visible.value = false;
      emit('reopenCollectionSuccess', true);
    };

    // * Observation modal
    const observacion_modal = ref(null);
    const openObservationModal = (
      collection_ticket_id,
      collection_resource_id
    ) => {
      observacion_modal.value.openModal(
        collection_ticket_id,
        collection_resource_id
      );
    };

    // * Reprocesar nomina
    const payrollReprocessing = async (
      collection_ticket_id,
      collection_resource_id
    ) => {
      try {
        const resp = await store.dispatch(POST_COLLECTION_RESOURCE_REPROCESS, {
          collection_ticket_id,
          collection_resource_id
        });

        if (resp.status === 201) {
          getCollectionTicketPayroll();
          notification('success', 'Correcto', 'Reprocesando Cobranza');
        }
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    // * ELiminar nomina
    const delete_payroll_modal = ref();
    const openDeleteModal = (id) => {
      delete_payroll_modal.value.openModal(id);
    };

    const deletedPayroll = () => {
      getCollectionTicketPayroll();
    };

    // Adjuntar nomina original
    const original_payroll = ref(null);
    const openModalPayrollOriginal = (
      collection_ticket_id,
      collection_resource_id
    ) => {
      original_payroll.value.openModal(
        collection_ticket_id,
        collection_resource_id
      );
    };

    // * Obtencion de data
    const data_table = computed(() => {
      return store.getters[GET_COLLECTION_TICKET_PAYROLL].map((obj) => {
        return {
          key: obj.id,
          id: obj.id,
          file: obj.name == undefined ? 'Archivito.xls' : obj.name,
          tags: obj.state == undefined ? 'COMPLETED' : obj.state,
          observations: obj.issueCounter == undefined ? '0' : obj.issueCounter,
          details: obj.id
        };
      });
    });

    const interval = ref(0);
    const reloadPayrollData = () => {
      interval.value = setInterval(async () => {
        getCollectionTicketPayroll();
      }, 20000);
    };

    watch(data_table, (new_val) => {
      const is_different_than_completed = new_val.some(
        (obj) => obj.tags != 'COMPLETED'
      );

      if (is_different_than_completed === true) {
        if (interval.value === 0) {
          reloadPayrollData();
        }
      } else {
        clearInterval(interval.value);
        interval.value = 0;
      }
    });

    onBeforeMount(() => {
      clearInterval(interval.value);
    });

    const payrollUploaded = () => {
      getCollectionTicketPayroll();
    };

    const payrollUploading = (value) => {
      loading.value = value;
    };

    const getCollectionTicketPayroll = () => {
      payrollUploading(true);

      store
        .dispatch(FETCH_COLLECTION_TICKET_PAYROLL, props.ticketId)
        .then(() => payrollUploading(false))
        .catch((err) => {
          notification('error', 'Error', err.response.data.message);
          payrollUploading(false);
        });
    };

    onMounted(() => {
      getCollectionTicketPayroll();
    });

    return {
      visible,
      loading,
      rules,
      formRef,
      formState,
      data_table,
      columns,
      success_modal_visible,
      getTagText,
      enableReopenButton,
      reopenCollectionFinish,
      payrollUploaded,
      payrollUploading,
      openSuccessModal,
      getColorState,
      closeModal,
      openModal,
      closeSuccessModal,
      getCollectionTicketPayroll,
      observacion_modal,
      openObservationModal,
      payrollReprocessing,
      original_payroll,
      openModalPayrollOriginal,
      delete_payroll_modal,
      openDeleteModal,
      deletedPayroll,
      collection_ticket_id
    };
  }
};
</script>
