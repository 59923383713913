// GETTERS
export const GET_COMPANY_POLICIES = 'policies/GET_INSURANCE_POLICIES';
export const GET_COMPANY_GROUPS = 'policies/GET_COMPANY_GROUPS';
export const GET_COMPANY_POLICIES_VIGENTES =
  'policies/GET_COMPANY_POLICIES_VIGENTES';
export const GET_COMPANY_POLICIES_WITHOUT_CONFIGURATION =
  'policies/GET_COMPANY_POLICIES_WITHOUT_CONFIGURATION';

// SETTERS
export const SET_COMPANY_POLICIES = 'policies/SET_INSURANCE_POLICIES';
export const SET_COMPANY_GROUPS = 'policies/SET_COMPANY_GROUPS';
export const SET_COMPANY_POLICIES_WITHOUT_CONFIGURATION =
  'policies/SET_COMPANY_POLICIES_WITHOUT_CONFIGURATION';

// FETCH
export const FETCH_COMPANY_POLICIES = 'policies/FETCH_INSURANCE_POLICIES';
export const FETCH_COMPANY_POLICIES_WITHOUT_CONFIGURATION =
  'policies/FETCH_COMPANY_POLICIES_WITHOUT_CONFIGURATION';
export const FETCH_COMPANY_GROUPS = 'policies/FETCH_COMPANY_GROUPS';
export const FETCH_POLICIES_COVERAGES = 'policies/FETCH_POLICIES_COVERAGES';
export const FETCH_POLICY_COVERAGES = 'policies/FETCH_POLICY_COVERAGES';
export const FETCH_POLICY = 'policies/FETCH_POLICY';
export const FETCH_COVERAGE = 'policies/FETCH_COVERAGE';
export const FETCH_POLICY_GROUP_COMPANY = 'policies/FETCH_POLICY_GROUP_COMPANY';

// ACTIONS
export const POST_COMPANY_POLICY = 'policies/POST_COMPANY_POLICY';
export const POST_COMPANY_POLICY_COVERAGES =
  'policies/POST_COMPANY_POLICY_COVERAGES';
export const PUT_COMPANY_POLICY_COVERAGES =
  'policies/PUT_COMPANY_POLICY_COVERAGES';
export const DELETE_COMPANY_POLICY_COVERAGE =
  'policies/DELETE_COMPANY_POLICY_COVERAGE';
export const PUT_COMPANY_POLICY = 'policies/PUT_COMPANY_POLICY';
export const DELETE_COMPANY_POLICY = 'policies/DELETE_COMPANY_POLICY';
// config
export const POST_CONFIG_POLICY = 'policies/POST_CONFIG_POLICY';
export const FETCH_INVOICE_PAYMENT_POLICY =
  'policies/FETCH_INVOICE_PAYMENT_POLICY';
export const FETCH_CUT_OFFS_POLICY = 'policies/FETCH_CUT_OFFS_POLICY';
export const FETCH_ALL_CONFIG_POLICY = 'policies/FETCH_ALL_CONFIG_POLICY';
export const SET_INVOICE_PAYMENT_POLICY = 'policies/SET_INVOICE_PAYMENT_POLICY';
export const SET_CUT_OFFS_POLICY = 'policies/SET_CUT_OFFS_POLICY';
export const SET_ALL_COMPANIES_POLICIES = 'policies/SET_ALL_COMPANIES_POLICIES';
export const GET_INVOICE_PAYMENT_POLICY = 'policies/GET_INVOICE_PAYMENT_POLICY';
export const GET_CUT_OFFS_POLICY = 'policies/GET_CUT_OFFS_POLICY';
export const GET_ALL_CONFIG = 'policies/GET_ALL_CONFIG';
export const PUT_CONFIG_POLIZA = 'policies/PUT_CONFIG_POLIZA';
