import {
  Company,
  CompanyCreate,
  CompanyById,
  companyGetExecutives,
  companyGetExecutive,
  createCompanyExecutive,
  editExecutive,
  EditCompany,
  deleteCompany,
  getInsurances,
  massiveRegistrationInitialLoad,
  CompanyNotificationsById,
  InsuranceCompanyNotificationsById,
  CompanyPutNotificationsById,
  InsuranceCompanyPutNotificationsById,
  companyByUserId,
  CompanyFetchTableData,
  UserByCompanyId,
  companiesStockDeregistration,
  companyPoliciesConfiguration,
  companiesByEmployee,
  getCompanyLogo,
  saveCompanyLogo,
  getCompanyDigitalFormConsentTexts,
  putCompanyDigitalFormConsentTexts,
  getCompanyEmailConfigurations,
  patchCompanyEmailConfiguration,
  postCompanyEmailConfiguration
} from '../../../network';
import { companyService } from '@/network';

import {
  SET_TOTAL_PAGES_TABLE,
  SET_ITEMS_PER_PAGE_TABLE,
  SET_CURRENT_PAGE_TABLE,
  SET_ROWS_TABLE
} from '../../../store/tables/types';

import {
  GET_USERS,
  SET_ALL_COMPANIES,
  SET_USERS,
  SET_USER,
  FETCH_ALL_COMPANIES,
  FETCH_STOCK_EMPLOYEE_COMPANIES,
  FETCH_USERS,
  FETCH_USER,
  GET_USER,
  POST_USER,
  SET_EMPLOYEES_COMPANY,
  PUT_EXECUTIVE,
  GET_ALL_COMPANIES,
  GET_COMPANY,
  SET_COMPANY,
  FETCH_COMPANY,
  POST_COMPANY,
  PATCH_COMPANY,
  GET_DATA_STOCK,
  FETCH_INSURANCE_COMPANY,
  SET_INSURANCE_COMPANY,
  GET_INSURANCE_COMPANY,
  POST_INSURANCE_COMPANY,
  DELETE_COMPANY,
  FETCH_COMPANY_NOTIFICATION,
  GET_COMPANY_NOTIFICATION,
  SET_COMPANY_NOTIFICATION,
  SET_INSURANCE_COMPANY_NOTIFICATION,
  PUT_NOTIFICATION,
  FETCH_INSURANCE_COMPANY_NOTIFICATION,
  GET_INSURANCE_COMPANY_NOTIFICATION,
  PUT_INSURANCE_NOTIFICATION,
  SET_TABLE_TOTAL_PAGES,
  SET_TABLE_ITEMS_PER_PAGE,
  SET_TABLE_CURRENT_PAGE,
  GETTER_STOCK_TABLE_FILTERS,
  SET_TABLE_FILTERS,
  GETTER_STOCK_TABLE_ITEMS_PER_PAGE,
  GETTER_STOCK_TABLE_CURRENT_PAGE,
  GETTER_STOCK_TABLE_TOTAL_PAGES,
  GET_COMPANY_BY_USER_ID,
  SET_COMPANY_BY_USER_ID,
  FETCH_COMPANY_BY_USER_ID,
  FETCH_COMPANY_DATA_TABLE,
  MUTATE_COMPANY_DATA_TABLE,
  GET_COMPANY_DATA_TABLE,
  FETCH_USER_BY_COMPANY,
  SET_USER_BY_COMPANY,
  GET_USER_BY_COMPANY,
  POST_STOCK_DEREGISTRATION,
  GET_COMPANY_POLICIES_WITHOUT_CONFIGURATION,
  SET_COMPANY_POLICIES_CONFIGURATION,
  FETCH_COMPANY_POLICIES_CONFIGURATION,
  FETCH_EMPLOYEE_COMPANIES,
  FETCH_COMPANY_LOGO,
  POST_COMPANY_LOGO,
  FETCH_COMPANY_DIGITAL_FORM_CONSENT_TEXT,
  PUT_COMPANY_DIGITAL_FORM_CONSENT_TEXT,
  GET_COMPANY_EMAIL_CONFIGURATIONS,
  FETCH_COMPANY_EMAIL_CONFIGURATIONS,
  SET_COMPANY_EMAIL_CONFIGURATIONS,
  UPDATE_COMPANY_EMAIL_CONFIGURATION,
  PATCH_COMPANY_EMAIL_CONFIGURATION,
  POST_COMPANY_EMAIL_CONFIGURATION
} from './types';

const store = {};
const getDefaultState = () => ({
  companies: [],
  users: [],
  user: {},
  company: {},
  stockCompany: {
    rows: [],
    total_pages: 0,
    items_per_page: 0,
    current_page: 0,
    filters: {}
  },
  insuranceCompany: [],
  notifications: {},
  insurance_notifications: {},
  companyUserId: [],
  companies_data_table: [],
  company_user: {},
  policies_configuration: []
});

store.state = getDefaultState();

store.getters = {
  [GET_ALL_COMPANIES](state) {
    // state.companies.sort((a, b) => {
    //   return a.id - b.id;
    // });
    return state.companies
      .map((a) => {
        let holding = '';
        let subholding = '';
        let rutHolding = a
          ? a.subHolding
            ? a.subHolding.holding.rut
            : ''
          : '';

        // Tiene holding pero no subholding
        if (a.holdingId != null && a.subHoldingId === null) {
          holding = a.holding.businessName;
          rutHolding = a ? a.holding.rut : null;
          subholding = '';
        } else if (a.holdingId === null && a.subHoldingId === null) {
          // no tiene holding ni subholding
          holding = '';
          subholding = '';
          rutHolding = a ? (a.subHolding ? a.subHolding.holding.rut : '') : '';
        } else {
          holding = a
            ? a.subHolding
              ? a.subHolding.holding.businessName
              : ''
            : '';
          subholding = a ? (a.subHolding ? a.subHolding.name : '') : '';
          // eslint-disable-next-line no-unused-vars
          rutHolding = a ? (a.subHolding ? a.subHolding.holding.rut : '') : '';
        }

        return {
          key: a.id,
          id: a.id,
          businessName: a.businessName,
          rut: a.rut,
          holding,
          subholding,
          rut_holding: a ? (a.subHolding ? a.subHolding.holding.rut : '') : ''
        };
      })
      .sort((a, b) => {
        return (
          a.holding.localeCompare(b.holding) ||
          a.subholding.localeCompare(b.subholding) ||
          a.businessName.localeCompare(b.businessName)
        );
      });
  },
  [GET_USERS](state) {
    return state.users;
  },
  [GET_USER](state) {
    return state.user;
  },
  [GET_COMPANY](state) {
    return state.company;
  },
  [GET_DATA_STOCK]: (state) => {
    return state.stockCompany.rows;
  },
  [GETTER_STOCK_TABLE_FILTERS]: (state) => {
    return state.stockCompany.filters;
  },
  [GETTER_STOCK_TABLE_ITEMS_PER_PAGE](state) {
    return state.stockCompany.items_per_page;
  },
  [GETTER_STOCK_TABLE_CURRENT_PAGE](state) {
    return state.stockCompany.current_page;
  },
  [GETTER_STOCK_TABLE_TOTAL_PAGES](state) {
    return state.stockCompany.total_pages;
  },

  [GET_INSURANCE_COMPANY]: (state) =>
    state.insuranceCompany.map((e) => ({
      value: e.id,
      label: e.businessName
    })),
  [GET_COMPANY_NOTIFICATION](state) {
    return state.notifications;
  },
  [GET_INSURANCE_COMPANY_NOTIFICATION](state) {
    return state.insurance_notifications;
  },
  [GET_COMPANY_BY_USER_ID](state) {
    return state.companyUserId;
  },
  [GET_COMPANY_DATA_TABLE](state) {
    return state.companies_data_table;
  },
  [GET_USER_BY_COMPANY](state) {
    return state.company_user;
  },
  [GET_COMPANY_POLICIES_WITHOUT_CONFIGURATION](state) {
    return state.policies_configuration;
  },
  [GET_COMPANY_EMAIL_CONFIGURATIONS](state) {
    return state.companyEmailConfigurations;
  }
};

// * Mutations
store.mutations = {
  [SET_ALL_COMPANIES](state, payload) {
    state.companies = payload;
  },
  [SET_USERS](state, payload) {
    state.users = payload;
  },
  [SET_USER](state, payload) {
    state.user = payload;
  },
  [SET_COMPANY](state, payload) {
    state.company = payload;
  },
  [SET_EMPLOYEES_COMPANY](state, payload) {
    state.stockCompany.rows = payload;
  },
  [SET_TABLE_TOTAL_PAGES](state, payload) {
    state.stockCompany.total_pages = payload;
  },
  [SET_TABLE_ITEMS_PER_PAGE](state, payload) {
    state.stockCompany.items_per_page = payload;
  },
  [SET_TABLE_CURRENT_PAGE](state, payload) {
    state.stockCompany.current_page = payload;
  },
  [SET_TABLE_FILTERS](state, payload) {
    state.stockCompany.filters = payload;
  },
  [SET_INSURANCE_COMPANY](state, payload) {
    state.insuranceCompany = payload;
  },
  [SET_COMPANY_NOTIFICATION](state, payload) {
    state.notifications = payload;
  },
  [SET_INSURANCE_COMPANY_NOTIFICATION](state, payload) {
    state.insurance_notifications = payload;
  },
  [SET_COMPANY_BY_USER_ID](state, payload) {
    state.CompanyById = payload;
  },
  [MUTATE_COMPANY_DATA_TABLE](state, payload) {
    state.companies_data_table = payload;
  },
  [SET_USER_BY_COMPANY](state, payload) {
    state.company_user = payload;
  },
  [SET_COMPANY_POLICIES_CONFIGURATION](state, payload) {
    state.policies_configuration = payload;
  },
  [SET_COMPANY_EMAIL_CONFIGURATIONS](state, payload) {
    state.companyEmailConfigurations = payload;
  },
  [UPDATE_COMPANY_EMAIL_CONFIGURATION](state, payload) {
    state.companyEmailConfigurations = state.companyEmailConfigurations.map(
      (c) => {
        if (c.id != payload.id) return c;
        return payload;
      }
    );
  }
};

// * Actions
store.actions = {
  async [FETCH_ALL_COMPANIES]({ commit }) {
    return new Promise((resolve, reject) => {
      Company().then(
        (resp) => {
          commit(SET_ALL_COMPANIES, resp.data);
          resolve(resp);
        },
        (err) => {
          commit(SET_ALL_COMPANIES, []);
          reject(err);
        }
      );
    });
  },
  [FETCH_USERS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyGetExecutives(payload).then(
        (resp) => {
          commit(SET_USERS, resp.data);
          resolve(resp);
        },
        (err) => {
          commit(SET_USERS, []);
          reject(err);
        }
      );
    });
  },
  [FETCH_USER]({ commit }, { companyId, executiveId, params }) {
    return new Promise((resolve, reject) => {
      companyGetExecutive({ companyId, executiveId, params }).then(
        (resp) => {
          commit(SET_USER, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [POST_COMPANY]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      CompanyCreate(payload).then(
        (resp) => {
          dispatch(FETCH_ALL_COMPANIES);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [POST_USER]({ dispatch }, { payload, id }) {
    return new Promise((resolve, reject) => {
      createCompanyExecutive(payload, id).then(
        (resp) => {
          dispatch(FETCH_USERS, id);
          dispatch(FETCH_COMPANY, id);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [FETCH_COMPANY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CompanyById(payload).then(
        (resp) => {
          commit(SET_COMPANY, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [PUT_EXECUTIVE]({ dispatch }, { payload, idCompany, idExecutive }) {
    return new Promise((resolve, reject) => {
      editExecutive(payload, idCompany, idExecutive).then(
        (resp) => {
          dispatch(FETCH_USERS, idCompany);
          dispatch(FETCH_COMPANY, idCompany);
          resolve(resp.status);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [PATCH_COMPANY]({ dispatch }, { payload, id }) {
    return new Promise((resolve, reject) => {
      EditCompany(payload, id).then(
        (resp) => {
          dispatch(FETCH_ALL_COMPANIES);
          dispatch(FETCH_COMPANY, id);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [DELETE_COMPANY](_, payload) {
    let error = '';
    const response = await deleteCompany(payload).catch((err) => (error = err));
    if (response && response.status === 200) {
      return response.data;
    }
    throw error;
  },
  async [FETCH_STOCK_EMPLOYEE_COMPANIES]({ commit }, { companyId, query }) {
    return new Promise((resolve, reject) => {
      companyService
        .getEmployees(companyId, query)
        .then((resp) => {
          commit(SET_EMPLOYEES_COMPANY, resp.data.rows);
          commit(SET_TABLE_TOTAL_PAGES, resp.data.total_items);
          commit(SET_TABLE_ITEMS_PER_PAGE, resp.data.items_per_page);
          commit(SET_TABLE_CURRENT_PAGE, resp.data.page);
          resolve(resp);
        })
        .catch((error) => {
          commit(SET_EMPLOYEES_COMPANY, []);
          commit(SET_TABLE_TOTAL_PAGES, 0);
          commit(SET_TABLE_ITEMS_PER_PAGE, 0);
          commit(SET_TABLE_CURRENT_PAGE, 0);
          reject(error);
        });
    });
  },
  async [FETCH_INSURANCE_COMPANY]({ commit }) {
    return new Promise((resolve, reject) => {
      getInsurances().then(
        (resp) => {
          commit(SET_INSURANCE_COMPANY, resp.data);
          resolve(resp);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  [POST_INSURANCE_COMPANY](_, payload) {
    return new Promise((resolve, reject) => {
      massiveRegistrationInitialLoad(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COMPANY_NOTIFICATION]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CompanyNotificationsById(payload)
        .then((resp) => {
          commit(SET_COMPANY_NOTIFICATION, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_INSURANCE_COMPANY_NOTIFICATION]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      InsuranceCompanyNotificationsById(payload)
        .then((resp) => {
          commit(SET_INSURANCE_COMPANY_NOTIFICATION, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          commit(SET_INSURANCE_COMPANY_NOTIFICATION, {});
          reject(err);
        });
    });
  },
  [PUT_NOTIFICATION]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      CompanyPutNotificationsById(
        {
          repeatsPerDay: payload.repetition,
          days: payload.days
        },
        payload.id_company,
        payload.id_notification
      )
        .then((resp) => {
          dispatch(FETCH_COMPANY_NOTIFICATION, payload.id_company);
          resolve(resp);
        })
        .catch((error) => {
          dispatch(FETCH_COMPANY_NOTIFICATION, {});
          reject(error);
        });
    });
  },
  [PUT_INSURANCE_NOTIFICATION]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      InsuranceCompanyPutNotificationsById(
        {
          repeatsPerDay: payload.repetition,
          days: payload.days
        },
        payload.id_company,
        payload.id_notification
      )
        .then((resp) => {
          dispatch(FETCH_INSURANCE_COMPANY_NOTIFICATION, payload.id_company);
          resolve(resp);
        })
        .catch((error) => {
          dispatch(FETCH_INSURANCE_COMPANY_NOTIFICATION, {});
          reject(error);
        });
    });
  },
  [FETCH_COMPANY_BY_USER_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyByUserId(payload)
        .then((resp) => {
          commit(SET_COMPANY_BY_USER_ID, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COMPANY_DATA_TABLE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CompanyFetchTableData(payload)
        .then((resp) => {
          resolve(resp);
          commit(MUTATE_COMPANY_DATA_TABLE, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_USER_BY_COMPANY]({ commit }, { company_id, employee_id }) {
    return new Promise((resolve, reject) => {
      UserByCompanyId(company_id, employee_id)
        .then((resp) => {
          commit(SET_USER_BY_COMPANY, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_STOCK_DEREGISTRATION](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      companiesStockDeregistration(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COMPANY_POLICIES_CONFIGURATION]({ commit }, { id, params }) {
    return new Promise((resolve, reject) => {
      companyPoliciesConfiguration(id, params)
        .then((resp) => {
          commit(SET_COMPANY_POLICIES_CONFIGURATION, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_EMPLOYEE_COMPANIES]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      companiesByEmployee(payload)
        .then((resp) => {
          const { rows, current_page, items_per_page, total_items } = resp.data;
          dispatch(SET_ROWS_TABLE, rows);
          dispatch(SET_ITEMS_PER_PAGE_TABLE, items_per_page);
          dispatch(SET_CURRENT_PAGE_TABLE, current_page);
          dispatch(SET_TOTAL_PAGES_TABLE, total_items);
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_COMPANY_LOGO](_, payload) {
    return new Promise((resolve, reject) => {
      getCompanyLogo(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [POST_COMPANY_LOGO](_, { payload, id }) {
    return new Promise((resolve, reject) => {
      saveCompanyLogo(payload, id)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_COMPANY_DIGITAL_FORM_CONSENT_TEXT](_, payload) {
    return new Promise((resolve, reject) => {
      getCompanyDigitalFormConsentTexts(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_COMPANY_DIGITAL_FORM_CONSENT_TEXT](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      putCompanyDigitalFormConsentTexts(id, payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_COMPANY_EMAIL_CONFIGURATIONS]({ commit }, { companyId }) {
    return new Promise((resolve, reject) =>
      getCompanyEmailConfigurations(companyId)
        .then((res) => {
          commit(SET_COMPANY_EMAIL_CONFIGURATIONS, res.data);
          resolve(res.data);
        })
        .catch((err) => reject(err))
    );
  },
  [PATCH_COMPANY_EMAIL_CONFIGURATION](
    { commit },
    { companyId, emailConfigurationId, data }
  ) {
    return new Promise((resolve, reject) =>
      patchCompanyEmailConfiguration(companyId, emailConfigurationId, data)
        .then((res) => {
          commit(UPDATE_COMPANY_EMAIL_CONFIGURATION, res.data);
          resolve(res.data);
        })
        .catch((err) => reject(err))
    );
  },
  [POST_COMPANY_EMAIL_CONFIGURATION]({ dispatch }, { companyId, data }) {
    return new Promise((resolve, reject) =>
      postCompanyEmailConfiguration(companyId, data)
        .then((res) => {
          dispatch(FETCH_COMPANY_EMAIL_CONFIGURATIONS, { companyId });
          resolve(res.data);
        })
        .catch((err) => reject(err))
    );
  }
};

export default store;
