<template>
  <a-modal
    :title="modal_prop.title"
    v-model:visible="modal_prop.visible"
    @cancel="closeModal"
    :closable="!modal_prop.loading"
    :maskClosable="!modal_prop.loading"
  >
    <a-form
      name="form"
      layout="vertical"
      ref="form_ref"
      :model="form_state"
      :rules="rules"
    >
      <a-form-item name="numberPolicy" label="Número de póliza">
        <a-input
          v-model:value="form_state.numberPolicy"
          placeholder="Ingrese número de póliza"
        />
      </a-form-item>
      <a-form-item
        name="insuranceCompanyId"
        label="Seleccionar compañía aseguradora"
      >
        <a-select
          :options="insurance_companies"
          v-model:value="form_state.insuranceCompanyId"
          placeholder="Selecciona una aseguradora"
        />
      </a-form-item>
      <a-form-item name="groupId" label="Nombre grupo">
        <a-select
          :options="company_groups"
          v-model:value="form_state.groupId"
          mode="multiple"
          allowClear
          placeholder="Seleccionar grupo"
        />
      </a-form-item>
      <a-form-item name="startDate" label="Fecha de vigencia">
        <a-date-picker
          format="DD/MM/YYYY"
          placeholder="Seleccione fecha inicio"
          v-model:value="form_state.startDate"
          :style="{ width: '100%' }"
        />
      </a-form-item>
      <a-form-item name="endDate">
        <a-date-picker
          format="DD/MM/YYYY"
          placeholder="Seleccione fecha término"
          v-model:value="form_state.endDate"
          :style="{ width: '100%' }"
        />
      </a-form-item>
      <br />
      <a-form-item has-feedback name="fileList">
        <a-upload
          name="fileList"
          :file-list="form_state.fileList"
          :multiple="true"
          :remove="handleRemove"
          :before-upload="beforeUpload"
        >
          <a-button>
            <upload-outlined></upload-outlined>
            Adjuntar documentos
          </a-button>
        </a-upload>
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button @click="closeModal" :disabled="modal_prop.loading"
        >Cancelar</a-button
      >
      <a-button
        @click="submitForm"
        type="primary"
        :loading="modal_prop.loading"
        >{{ button_confirm_text }}</a-button
      >
    </template>
  </a-modal>
</template>

<script setup>
import { reactive, defineExpose, ref, computed, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { UploadOutlined } from '@ant-design/icons-vue';
import {
  FETCH_INSURANCE_COMPANY,
  GET_INSURANCE_COMPANY,
  FETCH_COMPANY_GROUPS,
  GET_COMPANY_GROUPS,
  POST_COMPANY_POLICY
} from '../../store/types';
import notification from '../../utils/notifications';
import EventBus from '@/utils/events';

const store = useStore();
const route = useRoute();
const modal_prop = reactive({
  title: '',
  visible: false,
  loading: false,
  mode: 'create'
});
const form_ref = ref();
const form_state = reactive({
  companyId: null,
  numberPolicy: null,
  insuranceCompanyId: null,
  groupId: [],
  startDate: null,
  endDate: null,
  fileList: []
});

form_state.companyId = parseInt(route.params.id);

const checkDate = async (prop) => {
  const { field } = prop;
  if (form_state[field]) {
    const startDate = new Date(form_state.startDate);
    const endDate = new Date(form_state.endDate);

    if (form_state.startDate && form_state.endDate) {
      if (endDate < startDate) {
        return Promise.reject(
          'La fecha de término vigencia debe ser mayor a la de inicio'
        );
      }
    }

    return Promise.resolve();
  }
  return Promise.reject('Seleccione una fecha');
};
const rules = {
  numberPolicy: [
    {
      required: true,
      message: 'Ingrese un valor',
      type: 'string',
      trigger: 'change'
    }
  ],
  insuranceCompanyId: [
    {
      required: true,
      message: 'Seleccione una compañía aseguradora',
      type: 'number',
      trigger: 'change'
    }
  ],
  groupId: [
    {
      required: true,
      message: 'Seleccione un grupo',
      type: 'array',
      trigger: 'change'
    }
  ],
  startDate: [
    {
      required: true,
      type: 'object',
      trigger: 'change',
      validator: checkDate
    }
  ],
  endDate: [
    {
      required: true,
      type: 'object',
      trigger: 'change',
      validator: checkDate
    }
  ]
};
const button_confirm_text = computed(() => {
  const texts = {
    create: 'Crear',
    update: 'Guardar cambios'
  };
  return texts[modal_prop.mode];
});

const openModalOnCreate = () => {
  modal_prop.mode = 'create';
  modal_prop.title = 'Crear nueva póliza';
  modal_prop.visible = true;
};

const closeModal = () => {
  modal_prop.visible = false;
  form_ref.value.resetFields();
};

const beforeUpload = (file) => {
  const fileSize = file.size / 1024 / 1024;
  if (fileSize > 5) {
    message.error('El archivo excede el peso máximo 5MB');
  } else {
    form_state.fileList = [...form_state.fileList, file];
  }
  return false;
};

const handleRemove = (file) => {
  const index = form_state.fileList.indexOf(file);
  const newFileList = form_state.fileList.slice();
  newFileList.splice(index, 1);
  form_state.fileList = newFileList;
};

const submitForm = () => {
  form_ref.value.validate().then(() => {
    if (modal_prop.mode === 'create') {
      createPolicy();
    }
  });
};

const createPolicy = () => {
  modal_prop.loading = true;
  store
    .dispatch(POST_COMPANY_POLICY, { payload: form_state })
    .then(() => {
      notification('success', 'Correcto', 'Póliza creada con éxito');
      closeModal();
      EventBus.emit('policyCreated');
    })
    .catch((error) => {
      notification(
        'error',
        'Error',
        `No se ha podido crear la Póliza. ${error.response.data.message}`
      );
    })
    .finally(() => {
      modal_prop.loading = false;
    });
};

// Obtencion de data
const insurance_companies = computed(
  () => store.getters[GET_INSURANCE_COMPANY]
);
const company_groups = computed(() => store.getters[GET_COMPANY_GROUPS]);

onMounted(() => {
  try {
    store.dispatch(FETCH_INSURANCE_COMPANY);
    store.dispatch(FETCH_COMPANY_GROUPS, route.params.id);
  } catch (error) {
    notification(
      'error',
      'Error',
      `Error al cargar compañías aseguradoras. ${error.response.data.message}`
    );
  }
});

defineExpose({
  openModalOnCreate,
  beforeUpload,
  handleRemove
});
</script>
