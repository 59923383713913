<template>
  <div>
    <a-radio-group :value="policy_type" @change="onChangeTabs" :options="options" />

    <PoliciesTable class="mt-3" ref="policies_table_ref" :data="table_policies_data" :loading="loading_data"
      @deletedPolicy="getPolicies" />
  </div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import EventBus from '@/utils/events';
import notification from '@/utils/notifications';
import PoliciesTable from '@/components/policy/PoliciesTable.vue';
import { FETCH_COMPANY_POLICIES, GET_COMPANY_POLICIES } from '@/store/types';

const router = useRouter();
const route = useRoute();
const store = useStore();
const loading_data = ref(false);
const policies_table_ref = ref();

const policies_list = computed(() => {
  return store.getters[GET_COMPANY_POLICIES];
});

const table_policies_data = computed(() => {
  return policies_list.value.map((policy) => ({
    key: policy.id,
    policyNumber: policy.policyNumber,
    companyName: policy.insuranceCompanyName,
    coverages: policy.covers.map((c) => c.name).join(', '),
    action: policy.id
  }));
});

// * Radio button
const policy_type = ref('');
const options = [
  {
    label: 'Vigentes',
    value: 'Vigente'
  },
  {
    label: 'No vigentes',
    value: 'No vigente'
  }
];

// * Metodos
const onChangeTabs = (val) => {
  policy_type.value = val.target.value;
  router.push({
    name: 'Movimientos',
    query: { companyId: route.query.companyId, status: val.target.value }
  });
};

const getPolicies = async () => {
  loading_data.value = true;
  try {
    const { companyId, status } = route.query;
    await store.dispatch(
      FETCH_COMPANY_POLICIES,
      `?companyId=${companyId}&status=${status}`
    );
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
  loading_data.value = false;
};

EventBus.on('policyCreated', async () => {
  await getPolicies();
  if (policies_table_ref.value != undefined) {
    const total_pages = Math.ceil(table_policies_data.value.length / 5);
    policies_table_ref.value.changeCurrentPage({ current: total_pages });
  }
});

watchEffect(() => {
  if (route.name === 'Movimientos') {
    const { status } = route.query;
    policy_type.value = status;
    getPolicies();
  }
});
</script>
