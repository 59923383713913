import {
  cancelDependentsExclusion,
  createDependentsExclusion,
  finishDependentsExclusion,
  getDependentsExclusionsOfTicket
} from '../../../network';

import {
  POST_DEPENDENTS_EXCLUSION,
  FETCH_DEPENDENTS_EXCLUSION_OF_TICKET,
  POST_CANCEL_DEPENDENTS_EXCLUSION,
  POST_FINISH_DEPENDENTS_EXCLUSION
} from './types';

const store = {};
const getDefaultState = () => ({});

store.state = getDefaultState();

store.getters = {};

store.mutations = {};

store.actions = {
  async [POST_DEPENDENTS_EXCLUSION](_, payload) {
    const response = await createDependentsExclusion(payload);
    return response.data;
  },
  async [FETCH_DEPENDENTS_EXCLUSION_OF_TICKET](_, ticketId) {
    const response = await getDependentsExclusionsOfTicket(ticketId);
    return response.data;
  },
  async [POST_CANCEL_DEPENDENTS_EXCLUSION](_, ticketId) {
    const response = await cancelDependentsExclusion(ticketId);
    return response.data;
  },
  async [POST_FINISH_DEPENDENTS_EXCLUSION](_, ticketId) {
    const response = await finishDependentsExclusion(ticketId);
    return response.data;
  }
};

export default store;
