<template>
  <a-card
    bodyStyle="padding: 0px;"
    :title="title"
    :bordered="false"
    class="ticket_details mb-2"
  >
    <template #extra>
      <slot name="action"> </slot>
    </template>
    <a-descriptions class="" bordered>
      <template v-for="(data, index) in table_data" :key="index">
        <a-descriptions-item :label="data.key" :span="3">
          <a-list
            :loading="loadTicket"
            size="small"
            :data-source="[data].map((e) => e)"
          >
            <template #renderItem="{ item }">
              <a-list-item>
                {{ data.value !== undefined ? item.value : ' - ' }}
              </a-list-item>
            </template>
          </a-list>
        </a-descriptions-item>
      </template>
    </a-descriptions>
  </a-card>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'DatosTicket',
  props: {
    data: {
      type: Object,
      default: {}
    },
    title: {
      type: String,
      default: ''
    },
    loadTicket: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const table_data = computed(() => {
      return props.data;
    });

    const title = computed(() => {
      return props.title ? props.title : '';
    });

    return {
      table_data,
      // TODO: ARREGLAR VARIABLES DUPLICADAS
      // eslint-disable-next-line vue/no-dupe-keys
      title
    };
  }
};
</script>
