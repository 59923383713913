<template>
  <page-header title="Ticket de modificación masiva" :breadcrumRoutes="routes">
    <template #tags>
      <tags :color="header_state_data.color">
        {{ header_state_data.text }}
      </tags>
      <tags color="processing"> ID: {{ ticketIdMasiva }} </tags>
    </template>

    <template #buttons>
      <a-button
        v-if="
          header_state_data.text == 'EN ASEGURADORA' ||
          header_state_data.text == 'Creado'
        "
        @click="handleCancel"
        :loading="loading"
        :disabled="loading"
        >Cancelar</a-button
      >
      <a-button
        class="ml-2"
        v-if="header_state_data.text == 'EN ASEGURADORA'"
        @click="handleExecuted"
        type="primary"
        :loading="loading"
        :disabled="loading"
        >Ejecutar</a-button
      >
    </template>
  </page-header>

  <stepper
    :state="stepper_props.state"
    :stateDates="stepper_props.dates"
    ticketType="baja"
    ref="stepper_ref"
  />
  <a-card>
    <a-row :gutter="16">
      <a-col :span="12">
        <datos-ticket
          :loadTicket="loadTicket"
          :data="tableData"
          title="Datos ticket"
        />
        <mensaje-aseguradora :message="insuranceMessage" />
      </a-col>

      <a-col :span="12">
        <documentos-adjuntos
          title="Documentos adjuntos"
          :data="attachedFiles"
          :loading="loadingDocument"
        />

        <comments
          v-if="user_role != 'rrhh'"
          class="mt-2"
          :comments="comments"
          ticketType="masive"
          :idToTicket="ticketIdMasiva"
          @new_comment="getNewCommment"
        />
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import { createVNode } from 'vue';
import {
  GET_AM_DETAIL,
  FETCH_AM_DETAIL,
  GET_AM_DETAIL_FILE,
  FETCH_AM_DETAIL_FILE,
  SUCCESS_BAJA_MASIVA,
  CANCEL_MODIFICACION_MASIVA,
  EJECUTAR_MODIFICACION_MASIVA,
  FETCH_MASIVE_COMMENTS,
  GET_USER_ROLE,
  FETCH_USER_BY_ID,
  FETCH_TICKET_ID
} from '@/store/types';
import parseDate from '@/utils/dateFormatter';

// Components
import PageHeader from '@/components/PageHeader.vue';
import DatosTicket from '@/components/Tickets/TicketData.vue';
import Comments from '@/components/Tickets/Comments.vue';
import MensajeAseguradora from '@/components/Tickets/MensajeAseguradora.vue';
import DocumentosAdjuntos from '@/components/Tickets/DocumentosAdjuntos.vue';
// Utils
import openNotificationWithIcon from '@/utils/notifications';
import Tags from '@/components/Tags.vue';
import rutHelper from '@/utils/Ruts';
import Stepper from '@/components/Tickets/Stepper.vue';

export default {
  name: 'ModificacionMasivaDetalle',
  components: {
    Stepper,
    'page-header': PageHeader,
    comments: Comments,
    'datos-ticket': DatosTicket,
    'mensaje-aseguradora': MensajeAseguradora,
    'documentos-adjuntos': DocumentosAdjuntos,
    tags: Tags
    // 'info-circle-outlined': InfoCircleOutlined,
    // 'check-circle-outlined': CheckCircleOutlined
  },
  setup() {
    const stepper_props = reactive({
      state: '',
      dates: []
    });
    const rules = {
      message: [
        {
          required: true,
          message: 'Ingrese un mensaje',
          trigger: 'change',
          type: 'string'
        }
      ],
      fileList: [
        {
          type: 'array',
          required: true,
          message: 'Adjunte un archivo',
          trigger: 'change'
        }
      ]
    };
    const route = useRoute();
    const finishAlta = ref(false);
    const formRef = ref();
    const nameFile = ref('');
    const store = useStore();
    const ticketIdMasiva = route.params.id;
    const loadingDocument = ref(false);
    const loadTicket = ref(false);

    // * Rutas
    const routes = [
      {
        path: '/modificaciones',
        breadcrumbName: 'Modificaciones'
      },
      {
        path: '/modificaciones',
        breadcrumbName: 'Masiva'
      },
      {
        breadcrumbName: `#${ticketIdMasiva}`
      }
    ];

    const records = reactive([]);
    const formState = reactive({
      message:
        'A través del presente correo solicitamos cancelar la información contenida en este correo, debido a que existe un error en el documento adjunto. Se generará otra solicitud con el archivo correcto.',
      fileList: []
    });
    const handleRemove = () => {
      formState.fileList = [];
      formRef.value.validate('fileList');
    };
    const beforeUpload = (file) => {
      nameFile.value = file.name;
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        message.error('El archivo excede el peso máximo 5MB');
      } else {
        formState.fileList[0] = file;
      }
      return false;
    };
    //* Get Data
    const ticketData = ref({});
    const router = useRouter();
    const getTicketData = async () => {
      loadTicket.value = true;
      try {
        await store
          .dispatch(FETCH_AM_DETAIL, ticketIdMasiva)
          .catch(() => router.push({ name: 'Missing' }));
        ticketData.value = await store.getters[GET_AM_DETAIL];
        if (ticketData.value.typeTicketGroupId != 3) {
          router.push({ name: 'Missing' });
        }

        fillTableData();
        getBroker();
        fillIndividualTable();
        stepperData(ticketIdMasiva);
        loadTicket.value = true;
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          error.response?.data
            ? error.response?.data?.message
            : 'Ocurrió un error. Favor intentar más tarde.'
        );
        console.log('error', error);
      }

      // fillRecordsWithStates(value_to_records);
    };
    getTicketData();

    const attachedFiles = ref([]);
    const getAttachedFiles = async () => {
      loadingDocument.value = true;
      try {
        await store
          .dispatch(FETCH_AM_DETAIL_FILE, ticketIdMasiva)
          .catch((err) =>
            openNotificationWithIcon(
              'error',
              'Error',
              `Error al cargar archivo del Ticket. ${
                err.response.data ? err.response.data.message : 'error'
              }`
            )
          );
        attachedFiles.value = await store.getters[GET_AM_DETAIL_FILE];
        loadingDocument.value = false;
      } catch (error) {
        loadingDocument.value = false;
        console.log('error', error);
        openNotificationWithIcon(
          'error',
          'Error',
          error.response.data ? error.response.data.message : 'error'
        );
      }
    };
    getAttachedFiles();

    const tableData = reactive([
      { key: 'Broker', value: '' },
      { key: 'Aseguradora', value: '' },
      { key: 'Holding', value: '' },
      { key: 'Subholding', value: '' },
      { key: 'Empresa', value: '' },
      { key: 'RUT Empresa', value: '' }
    ]);
    const insuranceMessage = ref('');

    const fillTableData = () => {
      tableData[1].value = ticketData.value.object.insuranceCompany;
      tableData[2].value = ticketData.value.object.holding;
      tableData[3].value = ticketData.value.object.subHolding;
      tableData[4].value = ticketData.value.object.company;
      tableData[5].value = rutHelper.formatRut(
        ticketData.value.object.rutCompany
      );

      insuranceMessage.value = ticketData.value.message;
    };

    const getBroker = async () => {
      const response = await store.dispatch(
        FETCH_USER_BY_ID,
        ticketData.value.object?.brokerId
      );
      if (response) {
        tableData[0].value = response.data.body.name;
      } else {
        tableData[0].value = '';
      }
    };

    const altasData = ref([]);
    const fillIndividualTable = () => {
      altasData.value = ticketData.value.ticketGroupStates.map((a) => {
        return {
          key: a.id,
          id: a.id,
          employeeName: `${a.employee?.firstName} ${a.employee?.firstSurname}`,
          employeeRut: a.employee?.rut,
          ticketState: a.state,
          pendingCases: a.cases === 0 ? 'No' : 'Sí'
        };
      });
    };

    const payloadObject = ref({});
    const loading = ref(false);
    const visible = ref(false);
    const showModalCancel = ref(false);
    const modalStyle = reactive({
      title: '',
      text: '',
      contentText: '',
      mode: ''
    });
    // * Stepper
    const final_state = ref('');
    const final_dates = ref({});
    const stepper_ref = ref('null');
    const cancel_ticket = ref(false);
    const header_state_data = reactive({
      text: '',
      color: '',
      cancel_disabled: false,
      revisado_disabled: false,
      show_buttons: true
    });

    const handleOk = () => {
      visible.value = false;
      loading.value = false;
    };
    const getStateType = (state) => {
      // Lo transformo en un texto que el stepper lo sepa interpretar, ya que los nombres de los estados
      // Estan en cambio aun, los estados en el stepper siempre van a estar igual, pero no aca
      // la regla es la siguiente

      switch (state) {
        case 'Creado': // Asi viene desde el backend
          return 'creado'; // Asi es como lo toma el stepper y lo marca en su lugar
        case 'En revision Bewell':
          return 'revision_bewell';
        case 'En revision Aseguradora':
          return 'revision_aseguradora';
        case 'Ejecutado':
          return 'ejecutado';
        case 'Cancelado':
          return 'cancelado';
      }
    };
    const cancelStep = () => {
      // Metodo para cancelar el stepper en un cierto paso
      stepper_ref.value.cancelStep();
    };
    const finishStep = () => {
      // Metodo para cancelar el stepper en un cierto paso
      stepper_ref.value.finishStep();
    };
    const stepperData = async (id) => {
      try {
        // Data para el stepper de manera reactiva

        // 1.- Obtengo data para el ticket
        await store.dispatch(FETCH_TICKET_ID, id).catch((e) => e.response);

        // 2.- Primero obtengo el ultimo estado del cual esta el ticket, obviamente este puede cambiar
        let last_ticket_state = '';
        last_ticket_state = ticketData.value?.ticketGroupStates.sort((a, b) => {
          return b.id - a.id;
        })[0]?.state?.tag;

        // 3.- Transformo el ultimo estado en un texto que pueda interpretar el stepper
        let step = getStateType(last_ticket_state);
        console.log('estadooooooooo', step);
        // 4.- En caso de que el paso quede en cancelado, tengo que obtener el penultimo estado
        // que estubo correcto para pasarle a esta propiedad el cancelado para que se muestre
        // con una X en el paso, asi es como lo toma Ant Desing
        if (step === 'cancelado') {
          // Obtengo entonces el penultimo estado
          const last_before = ticketData.value.ticketGroupStates?.sort(
            (a, b) => {
              return b.id - a.id;
            }
          )[1].state.tag;
          step = getStateType(last_before);
        }

        const sorted_dates = ticketData.value.ticketGroupStates.sort(function (
          a,
          b
        ) {
          return a.id - b.id;
        });

        const final_obj = {};
        for (let i = 0; i < sorted_dates.length; i++) {
          const element = sorted_dates[i];
          const state_name = getStateType(element.state.tag);
          final_obj[state_name] = element.createdAt;
        }

        // 6.- Elimino estados que quedaron por delante del ultimo estado como explique arriba
        // Con el ultimo estado del ticket, valido si hay estados mas adelantes los cuales deba eliminar

        switch (final_state.value) {
          case 'creado':
            delete final_obj['revision_bewell'];
            delete final_obj['revision_aseguradora'];
            delete final_obj['ejecutado'];
            break;
          case 'revision_bewell':
            delete final_obj['revision_aseguradora'];
            delete final_obj['ejecutado'];
            break;
          case 'revision_aseguradora':
            delete final_obj['ejecutado'];
            break;
        }

        // 7.- En caso de que el ultimo estado este en cancelado, voy a dejar el penultimo estado en marcado
        // Para que se marque la x ahi
        // Voy a preguntar cual es el ultimo estado nuevamente
        const last = ticketData.value.ticketGroupStates.sort((a, b) => {
          return b.id - a.id;
        })[0].state.tag;

        stepper_props.dates = final_obj;
        // 8.- Paso data para el page-header
        // Si el ultimo estado esta en 'En revision bewell' osea 'revision_bewell', podemos darle al boton de revisado y de cancelar
        // el unico estado que puedo cancelar y revisar el ticket es en 'Revision aseguradora'

        header_state_data.show_buttons = false;

        switch (last) {
          case 'Creado':
            finishAlta.value = true;
            header_state_data.text = 'CREADO';
            header_state_data.color = 'blue';
            break;
          case 'En revision Aseguradora':
            header_state_data.text = 'EN ASEGURADORA';
            header_state_data.color = 'gold';
            finishAlta.value = true;
            header_state_data.show_buttons = true;
            break;
          case 'Ejecutado':
            finishAlta.value = false;
            header_state_data.text = 'EJECUTADO';
            header_state_data.color = 'green';
            break;
          case 'Cancelado':
            finishAlta.value = false;
            header_state_data.text = 'CANCELADO';
            header_state_data.color = 'red';
        }

        if (last === 'Cancelado') {
          // Llamo al metodo que cancela el estado
          cancelStep();
        }
        if (last === 'Ejecutado') {
          // Llamo al metodo que cancela el estado
          finishStep();
        }

        stepper_props.state = step;
        // Si el ultimo estado del ticket fue cancelado, la fecha de este cancelado, se la paso al penultimo estado
        // asi para que se muestre en el penultimo estado que se cancelo con su fecha correspondiente

        const keys_states = Object.keys(final_obj);
        if (last === 'Cancelado') {
          final_obj[keys_states[keys_states.length - 2]] =
            final_obj[keys_states[keys_states.length - 1]];
          delete final_obj['cancelado'];
        }

        // Formateo las fechas
        keys_states.forEach((element) => {
          final_obj[element] = parseDate(final_obj[element]);
        });

        final_dates.value = final_obj;
        if (header_state_data.text == 'cancelado') {
          cancel_ticket.value = false;
        }
        if (header_state_data.text == 'Creado') {
          cancel_ticket.value = true;
        }
        loadTicket.value = false;
      } catch (error) {
        console.log('error', error);
      }
    };
    const handleCancelBajaButton = () => {
      showModalCancel.value = false;
    };
    const handleCancelBaja = async () => {
      loading.value = true;
      try {
        // Hago la request hacia la base de datos
        await store.dispatch(CANCEL_MODIFICACION_MASIVA, ticketIdMasiva);
        await getTicketData();
        // Actualizo nuevamente el stepper para que se coloque automaticamente
        await stepperData(ticketIdMasiva);
        // records.push({
        //   title: 'Cancelado',
        //   description: response.data.createdAt
        // });
        loading.value = false;
      } catch (error) {
        loading.value = false;
        console.log(error);
      }
      //   loading.value = true;
      //   try {
      //     await store
      //       .dispatch(CANCEL_BAJA_MASIVA,{idAlta:ticketId.value,payload:formState})
      //       .then(() => {
      //         openNotificationWithIcon(
      //           'success',
      //           'Correcto',
      //           `Ticket ${ticketId.value} cancelado exitosamente`
      //         );
      //         getTicketData().then(() => {
      //           fillTableData();
      //           getBroker();
      //           fillIndividualTable();
      //         });
      //         formState.message='A través del presente correo solicitamos cancelar la información contenida en este correo, debido a que existe un error en el documento adjunto. Se generará otra solicitud con el archivo correcto.';
      //         formState.fileList[0]=[];
      //         showModalCancel.value=false;
      //         loading.value = false;
      //       });
      //   } catch (error) {
      //     loading.value = false;
      //     openNotificationWithIcon(
      //       'error',
      //       'Error',
      //       `No se ha podido cancelar el ticket ${ticketId.value}. ${error.response.data.message}`
      //     );
      //   }
    };
    const ejecutar = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
          EJECUTAR_MODIFICACION_MASIVA,
          ticketIdMasiva
        );
        // Actualizo nuevamente el stepper para que se coloque automaticamente
        await getTicketData();
        await stepperData(ticketIdMasiva);

        // Agrego una row mas al historial
        records.push({
          title: 'Ejecutado',
          description: response.data.createdAt
        });
        loading.value = false;
      } catch (error) {
        loading.value = false;
        console.log(error);
      }
    };
    const handleExecuted = () => {
      Modal.confirm({
        title: () =>
          '¿Estás seguro que deseas ejecutar el ticket de modificación masiva?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => 'Sí',
        cancelText: () => 'No',
        onOk() {
          ejecutar();
        },
        onCancel() {}
      });
    };
    const handleCancel = async () => {
      Modal.confirm({
        title: () =>
          '¿Estás seguro que deseas cancelar el ticket de modificación masiva?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => 'Sí',
        cancelText: () => 'No',
        onOk() {
          handleCancelBaja();
        },
        onCancel() {}
      });
      // showModalCancel.value=true;
    };

    const handleSuccess = async () => {
      loading.value = true;
      try {
        await store
          .dispatch(SUCCESS_BAJA_MASIVA, {
            idAlta: ticketIdMasiva,
            payload: payloadObject.value
          })
          .then(() => {
            openNotificationWithIcon(
              'success',
              'Correcto',
              `Ticket ${ticketIdMasiva} ejecutado exitosamente`
            );
            getTicketData().then(() => {
              fillTableData();
              getBroker();
              fillIndividualTable();
            });
            loading.value = false;
            visible.value = false;
          });
      } catch (error) {
        loading.value = false;
        openNotificationWithIcon(
          'error',
          'Error',
          `No se ha podido ejecutar el ticket ${ticketIdMasiva}. ${error.response.data.message}`
        );
      }
    };

    // * Comentarios
    // rol de  usuario
    const user_role = store.getters[GET_USER_ROLE];

    // Obtencion de comentarios
    const comments = ref([]);
    const getComments = async () => {
      comments.value = [];
      const resp = await store.dispatch(FETCH_MASIVE_COMMENTS, ticketIdMasiva);
      for (let i = 0; i < resp.length; i++) {
        const element = resp[i];
        const broker_name = await getBrokerName(element.brokerId);
        comments.value.push({
          description: parseDate(element.createdAt),
          title: element.message,
          author: broker_name
        });
      }
    };

    const getBrokerName = async (id) => {
      const response = await store.dispatch(FETCH_USER_BY_ID, id);
      return response.data.body.name;
    };

    const getNewCommment = async (value) => {
      const { description } = value;
      const { brokerId } = value;
      const brokerName = await getBrokerName(brokerId);

      comments.value.push({
        title: value.title,
        description: parseDate(description),
        author: brokerName
      });
    };

    getComments();

    return {
      // Variables
      stepper_props,
      rules,
      finishAlta,
      final_state,
      final_dates,
      stepper_ref,
      cancel_ticket,
      header_state_data,
      handleCancelBaja,
      formRef,
      beforeUpload,
      handleRemove,
      formState,
      comments,
      loading,
      visible,
      showModalCancel,
      modalStyle,
      altasData,
      routes,
      ticketIdMasiva,
      tableData,
      insuranceMessage,
      attachedFiles,
      user_role,
      handleCancelBajaButton,
      // Methods
      handleCancel,
      handleExecuted,
      ejecutar,
      handleSuccess,
      handleOk,
      getNewCommment,
      finishStep,
      loadingDocument,
      loadTicket
    };
  }
};
</script>
