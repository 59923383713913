<template>
  <div>
    <a-table :columns="columns" :data-source="table_data" :pagination="pagination" :loading="loading"
      @change="changeCurrentPage">
      <template #idRender="{ text }"> # {{ text }} </template>

      <template #observationsRender="{ text }">
        <p v-if="text > 1">{{ text }} Observaciones</p>
        <p v-else-if="text === 1">{{ text }} Observacion</p>
        <p v-else>Sin observaciones</p>
      </template>

      <template #stateRender="{ text: tag }">
        <a-tag :color="collectionBillingStateColor(tag)">
          {{ getStateName(tag) }}
        </a-tag>
      </template>

      <template #optionsRender="{ text, record }">
        <a-space size="middle" align="center">
          <a-tooltip color="#05045D" title="Ver observaciones" @click="openObservationModal(text)">
            <a-button type="link" style="padding: 0;">
              <eye-outlined class="blue-icon" />
              <observation-modal ref="observacion_modal" />
            </a-button>
          </a-tooltip>
          <a-tooltip color="#05045D" title="Reprocesar nómina" @click="payrollReprocessing(text)"
            v-if="!(record.state == 'CREATED' || record.state == 'EN SUBIDA')">
            <a-button type="link" style="padding: 0;">
              <file-sync-outlined class="blue-icon" />
            </a-button>
          </a-tooltip>
          <a-tooltip color="#05045D" :title="'Adjuntar nómina original'" @click="openModalPayrollOriginal(text)"
            v-if="!(record.state == 'CREATED' || record.state == 'EN SUBIDA')">
            <a-button type="link" style="padding: 0;">
              <paper-clip-outlined class="blue-icon" />
              <original-payroll :collectionId="text" ref="OriginalPayroll" />
            </a-button>
          </a-tooltip>
          <a-tooltip color="#05045D" :title="`Eliminar`" @click="openDeleteModal(text)">
            <a-button type="link" style="padding: 0;">
              <delete-outlined class="red-icon" />
              <delete-modal ref="delete_payroll_modal" @deletedPayroll="deletedPayroll" />
            </a-button>
          </a-tooltip>
        </a-space>
      </template>

      <template #filterDropdown="{
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column
      }">
        <div style="padding: 8px">
          <a-input ref="searchInput" :placeholder="`Buscar por ${column.dataIndex}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block" @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              " @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />
          <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
            <template #icon> <search-outlined /></template>
            Buscar
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            Limpiar
          </a-button>
        </div>
      </template>

      <template #filterIcon="filtered">
        <search-outlined :style="{ color: filtered.filtered ? '#108ee9' : undefined }" />
      </template>
    </a-table>
  </div>
</template>

<script>
import {
  SearchOutlined,
  EyeOutlined,
  FileSyncOutlined,
  PaperClipOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue';

import {
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  reactive,
  toRefs,
  ref
} from 'vue';
import { useStore } from 'vuex';
import collectionBillingStateColor from '../../../utils/collectionBillingStateColor';
import PayrollDeleteModal from './PayrollDeleteModal.vue';
import {
  FETCH_COLLECTION_FORM_PAYROLL_BY_INSURANCE_COMPANY,
  GET_COLLECTION_FORM_INSURANCE_COMPANY_PAYROLL,
  POST_PAYROLL_REPROCESSING,
  FETCH_FILES_ORIGINAL_PAYROLL
} from '@/store/types';
import ObservationsModal from '@/components/collection and billing/payroll/PayrollObservationsModal.vue';
import originalPayroll from '@/components/collection and billing/payroll/PayrollOriginal.vue';
import notification from '@/utils/notifications';

export default {
  props: {
    insuranceCompanyId: {
      type: [String, Number],
      default: ''
    },
    collectionFormId: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    'search-outlined': SearchOutlined,
    'eye-outlined': EyeOutlined,
    'file-sync-outlined': FileSyncOutlined,
    'paper-clip-outlined': PaperClipOutlined,
    'delete-outlined': DeleteOutlined,
    'observation-modal': ObservationsModal,
    'delete-modal': PayrollDeleteModal,
    'original-payroll': originalPayroll
  },
  setup(props, { emit }) {
    // * Vuex
    const store = useStore();

    // * Props
    const col_form_id = computed(() => props.collectionFormId);
    const ins_comp_id = computed(() => props.insuranceCompanyId);
    // * Tabla
    const loading = ref(false);
    const table_data = computed(() => {
      const data = store.getters[GET_COLLECTION_FORM_INSURANCE_COMPANY_PAYROLL](
        ins_comp_id.value
      )?.map((obj) => {
        return {
          id: obj.id,
          key: obj.id,
          fileName: obj.objectFilename,
          state: obj.state != undefined ? obj.state : '',
          observations: obj.issueCounter,
          options: obj.id
        };
      });
      return data;
    });

    const state = reactive({
      searchText: '',
      searchedColumn: ''
    });
    const searchInput = ref();
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
    };
    const handleReset = (clearFilters) => {
      clearFilters();
      state.searchText = '';
    };

    const columns = [
      {
        title: 'N°',
        width: 80,
        dataIndex: 'id',
        slots: {
          customRender: 'idRender',
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        },
        onFilter: (value, record) =>
          record.id.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Archivo',
        width: 250,
        dataIndex: 'fileName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'customRender'
        },
        onFilter: (value, record) =>
          record.fileName
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Estado',
        width: 100,
        dataIndex: 'state',
        slots: {
          customRender: 'stateRender'
        },
        filters: [
          {
            text: 'Completado',
            value: 'COMPLETED'
          }
        ],
        onFilter: (value, record) => {
          return record.state.toString().includes(value);
        }
      },
      {
        title: 'Observaciones',
        width: 200,
        dataIndex: 'observations',
        slots: {
          customRender: 'observationsRender'
        }
      },
      {
        title: 'Opciones',
        width: 100,
        dataIndex: 'options',
        slots: {
          customRender: 'optionsRender'
        }
      }
    ];

    watch(
      () => table_data.value,
      (new_val) => {
        let validation;
        if (new_val.length == 0) {
          validation = true;
        } else {
          validation = new_val.some((obj) => obj.state != 'COMPLETED');
        }

        emit('allValidPayrolls', !validation);
      }
    );

    const total_items = computed(() => table_data.value?.length);
    const pagination = reactive({
      total: total_items.value,
      pageSize: 10,
      current: 1
    });
    const changeCurrentPage = (page) => {
      pagination.current = page.current;
    };

    // * Cambio de palabra en el stado
    const getStateName = (name) => {
      switch ((name && name.toUpperCase()) || name.toLowerCase()) {
        case 'FAILED':
          return 'Error';
        case 'COMPLETED':
          return 'Válido';
        case 'CREATED':
          return 'Procesando';
        case 'OBSERVATIONS':
          return 'Observaciones';
        case 'COMPLETED_OBSERVATIONS':
          return 'Con Observaciones';
        case 'NO APLICA':
          return 'Subiendo';
        default:
          return 'Subiendo';
      }
    };

    // * Observation modal
    const observacion_modal = ref(null);
    const openObservationModal = (id) => {
      observacion_modal.value.openModal(id);
    };

    // * Reprocesar nomina
    const payrollReprocessing = async (id) => {
      try {
        const resp = await store.dispatch(POST_PAYROLL_REPROCESSING, id);

        if (resp.status === 201) {
          getPayrollsByInsuranceCompanies();
        }
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    // * ELiminar nomina
    const delete_payroll_modal = ref();
    const openDeleteModal = (id) => {
      delete_payroll_modal.value.openModal(id);
    };

    const deletedPayroll = () => {
      getPayrollsByInsuranceCompanies();
    };

    // * Obtencion de data principal
    const getPayrollsByInsuranceCompanies = async () => {
      loading.value = true;
      try {
        const payload = {
          collection_form_id: col_form_id.value,
          insurance_company_id: ins_comp_id.value
        };

        await store.dispatch(
          FETCH_COLLECTION_FORM_PAYROLL_BY_INSURANCE_COMPANY,
          payload
        );
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    const interval = ref(0);
    const reloadPayrollData = () => {
      interval.value = setInterval(async () => {
        await getPayrollsByInsuranceCompanies();
      }, 10000);
    };

    watch(table_data, (new_val) => {
      const is_different_than_completed = new_val.some(
        (obj) => obj.state == 'CREATED'
      );
      if (is_different_than_completed === true) {
        if (interval.value === 0) {
          reloadPayrollData();
        }
      } else {
        clearInterval(interval.value);
        interval.value = 0;
      }
    });

    onMounted(async () => {
      loading.value = true;
      try {
        getPayrollsByInsuranceCompanies();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    });

    onBeforeUnmount(() => {
      clearInterval(interval.value);
    });

    const OriginalPayroll = ref(null);
    const openModalPayrollOriginal = async (id) => {
      await store.dispatch(FETCH_FILES_ORIGINAL_PAYROLL, id);
      OriginalPayroll.value.openModal(id);
    };

    return {
      table_data,
      columns,
      ...toRefs(state),
      searchInput,
      handleSearch,
      handleReset,
      pagination,
      changeCurrentPage,
      getStateName,
      collectionBillingStateColor,
      openObservationModal,
      observacion_modal,
      loading,
      payrollReprocessing,
      getPayrollsByInsuranceCompanies,
      delete_payroll_modal,
      openDeleteModal,
      deletedPayroll,
      interval,
      openModalPayrollOriginal,
      OriginalPayroll
    };
  }
};
</script>

<style scoped>
.blue-icon {
  color: #2521e5;
}

.red-icon {
  color: #f5222d;
}
</style>
