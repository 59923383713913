<template>
  <div>
    <a-modal
      v-model:visible="visible"
      title="Visualizando documento"
      @ok="closeModal"
      width="100%"
      wrapClassName="full-modal"
    >
      <a-skeleton :loading="loading" :active="loading">
        <embed :src="url_route" width="100%" height="100%" />
      </a-skeleton>
    </a-modal>
  </div>
</template>

<script>
import { ref } from 'vue';
import notification from '../utils/notifications';

export default {
  setup() {
    const visible = ref(false);
    const loading = ref(false);
    const url_route = ref('');

    const openModal = (url) => {
      try {
        loading.value = true;
        visible.value = true;

        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';

        xhr.onload = function () {
          if (this.status == 200) {
            const blob = new Blob([this.response], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            url_route.value = url;
            loading.value = false;
          }
        };

        xhr.send();
      } catch (error) {
        loading.value = false;
        notification('error', 'Error', 'Ocurrio un error, intentalo mas tarde');
      }
    };

    const closeModal = () => {
      visible.value = false;
    };

    return {
      visible,
      url_route,
      loading,
      openModal,
      closeModal
    };
  }
};
</script>

<style lang="less">
.full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
  }
}
</style>
