<template>
  <page-header title="Caso de antecedentes" :breadcrumRoutes="routes">
    <template #tags>
      <tags :stateId="ticketState.id" v-if="ticketState.id !== ''" />
      <tags :stateId="1" v-else />
      <tags color="processing">
        ID Caso: <b>{{ id }}</b>
      </tags>
    </template>

    <template #buttons>
      <a-button :loading="loadingButton" v-if="ticketState.id == 2 || ticketState.id == 1"
        @click="openCancelModal">Cancelar</a-button>
    </template>
  </page-header>

  <a-card>
    <a-row>
      <a-col span="12">
        <a-row class="mb-4">
          <a-col :span="24">
            <datos-ticket title="Datos caso" :data="record_data_table" :loadTicket="loadTicket" />
          </a-col>
        </a-row>
      </a-col>
      <a-col span="12">
        <a-row class="mb-4">
          <a-col :span="22" :offset="1">
            <records title="Historial del caso" :data="recordData" v-if="user_role != 'rrhh'"
              :loadingHistory="loadTicket" />

            <documentos-adjuntos v-if="user_role != 'rrhh'" :title="'Carta de solicitud de antecedentes'"
              :data="filesTicket" :loadingDocument="loadTicket" />
          </a-col>
        </a-row>

        <a-row class="mb-4">
          <a-col :span="22" :offset="1">
            <documentos-adjuntos v-if="user_role != 'rrhh'" :title="'Documentos adjuntos'" :data="solicitud_ant"
              :loadingDocument="loadTicket" />
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { ref, reactive, createVNode } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { computed } from 'vue';
import { Modal } from 'ant-design-vue';
import DatosTicket from '@/components/Tickets/TicketData.vue';
import DocumentosAdjuntos from '@/components/Tickets/DocumentosAdjuntos.vue';
import Records from '@/components/Tickets/Records.vue';
import parseDate from '@/utils/dateFormatter';
import getTagColor from '@/utils/getTagColor';
import {
  FETCH_CASE_ID_DATA,
  FETCH_FILES_TICKET_ID,
  GET_USER_ROLE,
  POST_CREATE_STATE_CASE
} from '@/store/types';
import PageHeader from '@/components/PageHeader.vue';
import Tags from '@/components/Tags.vue';
import rutHelper from '@/utils/Ruts';

export default {
  name: 'DetalleCaso',
  components: {
    'documentos-adjuntos': DocumentosAdjuntos,
    records: Records,
    'datos-ticket': DatosTicket,
    'page-header': PageHeader,
    tags: Tags
  },
  setup() {
    // * Vuex
    const store = useStore();
    const user_role = store.getters[GET_USER_ROLE];

    // Informacion para 'Datos caso'

    const case_data = ref([]);
    const selected_files = ref([]);
    const final_state = ref('');
    const files = ref([]);
    const loadTicket = ref(false);
    // const currentState = ref('');
    const ticketState = reactive({ id: '', tag: '', color: '' });
    // const records_files = reactive([]);
    const filesTicket = ref([]);
    const solicitud_ant = ref([]);
    const recordData = ref([]);
    const loadingButton = ref(false);
    // Id del caso
    const route = useRoute();
    const { id } = route.params;

    // * Rutas
    const routes = [
      {
        path: '/antecedentes',
        breadcrumbName: 'Antecedentes'
      },
      {
        breadcrumbName: `#${id}`
      }
    ];
    const router = useRouter();
    // Obtener informacion
    const getCaseData = async () => {
      loadTicket.value = true;
      try {
        const res_case = await store.dispatch(FETCH_CASE_ID_DATA, id);
        await getDocumentsByTicketId(id);

        recordData.value = res_case.data.states.map((a) => ({
          description: parseDate(a.createdAt),
          title: a.state.tag
        }));
        // Records Data
        recordData.value = res_case.data.states.map((a) => {
          if (a.state.tag == 'Cancelado') {
            return {
              description: parseDate(a.createdAt),
              title: a.state.tag,
              status: 'error'
            };
          } else if (a.state.tag == 'Finalizado') {
            return {
              description: parseDate(a.createdAt),
              title: a.state.tag,
              status: 'finish'
            };
          }
          return {
            description: parseDate(a.createdAt),
            title: a.state.tag
          };
        });

        if (res_case.data.typeCaseId != 1) {
          router.push({ name: 'Missing' });
        }
        const finalState =
          res_case.data.states[res_case.data.states.length - 1].state;

        ticketState.id = finalState.id.toString();
        ticketState.tag = finalState.tag;
        ticketState.color = getTagColor(ticketState.tag);
        loadTicket.value = false;
        return (case_data.value = res_case.data);
      } catch (error) {
        loadTicket.value = false;
        console.log('error', error);
      }
    };
    getCaseData(id);

    const getFiles = async () => {
      const resp = await store.dispatch(FETCH_FILES_TICKET_ID, id);
      // const data = resp.filter(f => f.name !== 'Caso por antecedentes');
      const data = resp.data
        .map((e, i) => ({
          key: i,
          name: e.name,
          company: case_data.value.ticket?.insuranceCompany.businessName,
          ver: 'Ver más',
          route: e.caseFileUrl
        }))
        .filter((f) => f.name == 'Caso por antecedentes');
      return (filesTicket.value = data);

      // console.log('respuesta data', resp);
      // const data = resp
      //   .map((e, i) => ({
      //     key: i,
      //     name: e.name,
      //     company: case_data.value.ticket?.insuranceCompany.businessName,
      //     ver: 'Ver más',
      //     route: e.caseFileUrl
      //   }))
      //   .filter((f) => f.name == 'Caso por antecedentes');
      // console.log('fileeeeees',data);
      // return (filesTicket.value = data);
    };
    getFiles(id);

    const rowSelectionForm = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          'selectedRows: ',
          selectedRows
        );

        selected_files.value.length = 0;
        for (let i = 0; i < selectedRows.length; i++) {
          const element = selectedRows[i];
          selected_files.value.push(element);
        }
      },
      getCheckboxProps: () => ({
        disabled:
          final_state.value === 'ejecutado' ||
          final_state.value === 'revision_aseguradora'
      })
    };

    const openCancelModal = () => {
      Modal.confirm({
        title: () =>
          '¿Estás seguro que deseas cancelar el caso de antecedente?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => 'Sí',
        cancelText: () => 'No',
        onOk() {
          cancelCase();
        },
        onCancel() { }
      });
    };
    // const changeStepperData = async (id) =>{
    //   console.log('CANGE STEPPER DATA LLAMADO');
    //   // Este sirve de manera reactica para cambiar cada data del stepper, ya que tiene que ser reactivo
    //   // A cualquier cmabio que quiera hacer el usuario con los estados del ticket

    //   const ticket_response = await ticketId(id).catch((e) => e.response);
    //   const ticket_data = ticket_response.data;

    //   // Paso los estados que estubo el ticket al historial
    //   recordData.value.length = 0;
    //   for (let i = 0; i < ticket_data.states.length; i++) {
    //     const element = ticket_data.states[i];
    //     recordData.value.push(
    //       {
    //         title: element.state.tag,
    //         description:element.createdAt
    //       }
    //     );
    //   }
    // };
    const cancelCase = async () => {
      loadingButton.value = true;
      try {
        console.log('caseeee', case_data.value);
        // Hago la request hacia la base de datos
        await store.dispatch(POST_CREATE_STATE_CASE, {
          id,
          payload: {
            stateId: 4
          }
        });

        // Actualizo nuevamente el stepper para que se coloque automaticamente
        await getCaseData(id);
        loadingButton.value = false;
      } catch (error) {
        loadingButton.value = false;
        console.log(error);
      }
    };

    const getDocumentsByTicketId = async (id) => {
      try {
        // Lo inicializo en 0, ya que cuando cambie de estado el ticket tengo que obtener los archivos otra vez
        // asi que en caso de llamar denuevo a este metodo, obtengo la lista de archivos nuevamente para mostrarlo

        files.value.length = 0;

        const response_files = await store.dispatch(FETCH_FILES_TICKET_ID, id);

        // Obtengo la data
        const data = response_files.data
          .map((e, i) => ({
            key: i,
            name: e.name,
            company: case_data.value.ticket?.insuranceCompany.businessName,
            ver: 'Ver más',
            route: e.caseFileUrl
          }))
          .filter((f) => f.name !== 'Caso por antecedentes');
        return (solicitud_ant.value = data);
      } catch (error) {
        console.log('No existen archivos');
      }
    };
    const record_data_table = computed(() => {
      const date = case_data.value.states?.reduce((acc, item) => {
        return [
          ...acc,
          {
            inicio_vigencia: item?.createdAt
          }
        ];
      }, []);

      const group = case_data.value.ticket?.employee.employeeGroups
        .map((e) => e.groupCompanies)
        .map((e) => e.name)[0];
      console.log('grupooo', group);

      // case_data.value.push(d[0]);
      return [
        {
          key: 'Inicio',
          value: date
            ? parseDate(date ? date[0].inicio_vigencia : null).substring(0, 10)
            : '' // formatDate(new Date(ticket.created_at))
        },
        {
          key: 'Nombre',
          value: `${case_data.value
              ? case_data.value?.ticket
                ? case_data.value.ticket.employee.firstName
                : ''
              : ''
            } ${case_data.value
              ? case_data.value.ticket
                ? case_data.value.ticket.employee.firstSurname
                : ''
              : ''
            }`
        },
        {
          key: 'RUT',
          value: rutHelper.formatRut(case_data.value.ticket?.employee.rut)
        },
        {
          key: 'Correo',
          value: case_data.value.ticket?.employee.email
        },
        {
          key: 'Empresa',
          value: case_data.value.ticket?.company.businessName
        },
        {
          key: 'Grupo',
          value: group
        },
        {
          key: 'Aseguradora',
          value: case_data.value.ticket?.insuranceCompany.businessName
        }
      ];
    });
    const columnsForm = [
      {
        dataIndex: 'name',
        slots: { customRender: 'name' }
      },
      {
        dataIndex: 'path',
        slots: { customRender: 'path' }
      }
    ];

    // const insurancesNames = computed(() => {
    //   if (case_data.value.insuranceCompany != undefined) {
    //     let result = '';

    //     for (let i = 0; i < case_data.value.insuranceCompany.length; i++) {
    //       const element = case_data.value.insuranceCompany[i];
    //       let character = '';
    //       if (i != case_data.value.insuranceCompany.length - 1) {
    //         character = ', ';
    //       }
    //       result = result + element.name + character;
    //     }

    //     return result;
    //   }
    //   return '';
    // });

    return {
      routes,
      record_data_table,
      id,
      rowSelectionForm,
      recordData,
      ticketState,
      openCancelModal,
      user_role,
      // valuePicker: ref(),
      // valueCheckbox: ref([]),
      columnsForm,
      case_data,
      filesTicket,
      solicitud_ant,
      loadTicket,
      loadingButton,
      // doc_adj,
      cancelCase
      // insurancesNames
    };
  }
};
</script>
