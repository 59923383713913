<template>
  <div>
    <a-modal
      v-model:visible="modal_props.visibility"
      :title="modal_props.title"
      @ok="handleOk"
      @cancel="handleClose"
      centered
      :closable="!modal_props.loading"
      :maskClosable="!modal_props.loading"
    >
      <a-form ref="formRef" :model="formState" :rules="rules" layout="vertical">
        <div v-if="!deletingCompany">
          <!-- Rut -->
          <a-form-item ref="rut" label="Rut empresa" name="rut" has-feedback>
            <a-input
              v-model:value="formState.rut"
              placeholder="Ingresa rut"
              @keyup="formatRuts"
              :maxlength="12"
            />
          </a-form-item>
          <!-- Nombre -->
          <a-form-item
            ref="name"
            label="Nombre o razón social empresa"
            name="name"
          >
            <a-input
              v-model:value="formState.name"
              placeholder="Ingresa razón social"
            />
          </a-form-item>

          <!-- Holding -->
          <a-form-item
            label="¿Asociar holding?"
            ref="holding_name"
            name="holding_name"
          >
            <a-select
              v-model:value="selectHolding"
              :options="filterHolding"
              placeholder="Selecciona un holding"
              :disabled="modal_props.holding_disabled"
            >
            </a-select>
          </a-form-item>

          <!-- Holding check -->
          <a-form-item ref="holding_checked" name="holding_checked">
            <a-checkbox v-model:checked="formState.holding_checked"
              >No asociar holding</a-checkbox
            >
          </a-form-item>

          <!-- SubHolding -->
          <a-form-item
            label="¿Asociar subholding?"
            ref="subholding_name"
            name="subholding_name"
          >
            <a-select
              v-model:value="selectSubholding"
              :options="subHoldingFilter"
              placeholder="Selecciona un subholding"
              :disabled="
                modal_props.subholding_disabled || !formState.holding_name
              "
            >
            </a-select>
          </a-form-item>

          <!-- SubHolding check -->
          <a-form-item ref="subholding_checked" name="subholding_checked">
            <a-checkbox v-model:checked="formState.subholding_checked"
              >No asociar subholding</a-checkbox
            >
          </a-form-item>
        </div>
        <div v-if="deletingCompany">
          <p>
            Para eliminar la compañía
            <strong>{{ deletingObject.name }}</strong> escribe en el recuadro
            “eliminar” y presiona continuar.
          </p>
          <a-form-item ref="eliminar" name="eliminar">
            <a-input
              v-model:value="formState.eliminar"
              placeholder="eliminar"
            />
          </a-form-item>
        </div>
      </a-form>

      <template #footer>
        <a-button
          key="back"
          @click="handleClose"
          :disabled="modal_props.loading"
          >Cancelar</a-button
        >

        <a-button
          type="primary"
          :loading="modal_props.loading"
          v-if="deletingCompany === false"
          @click="handleOk"
        >
          {{ modal_props.button_text }}
        </a-button>
        <a-button
          v-else
          type="primary"
          @click="handleDelete"
          :loading="modal_props.loading"
          :disabled="disableDelete()"
          danger
        >
          Eliminar
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import rutHelper from '../../utils/Ruts';
import {
  POST_COMPANY,
  FETCH_ALL_HOLDINGS,
  FETCH_COMPANY,
  GET_HOLDINGS,
  // GET_ALL_COMPANIES,
  PATCH_COMPANY,
  DELETE_COMPANY,
  FETCH_ALL_COMPANIES,
  HOLDING_FILTER,
  SUBHOLDINGS_FILTER,
  SELECT_HOLDING,
  HOLDINGS_SELECTS,
  SELECTED_SUBHOLDINGS,
  ACTION_SELECTED_ALL_SUBHOLDING,
  GET_COMPANY
} from '../../store/types';
import openNotificationWithIcon from '../../utils/notifications';
import delay from '../../utils/delay';

export default {
  name: 'CompanyModal',
  props: {
    modelValue: Boolean
  },
  setup(props, { emit }) {
    // * Modal
    const modal_props = reactive({
      visibility: false,
      title: '',
      holding_disabled: false,
      subholding_disabled: true,
      loading: false,
      disabled: false,
      button_text: ''
    });

    // * Formulario
    const formRef = ref();
    const formState = reactive({
      rut: '',
      name: '',
      holding_name: null,
      holding_checked: true,
      subholding_name: null,
      subholding_checked: true,
      ambos: false,
      eliminar: ''
    });

    const formatRuts = () => {
      formState.rut = rutHelper.formatRut(formState.rut);
    };

    const handleChange = () => {
      if (formState.holding_name) {
        store.state.clients.holdings.selectedSubHolding = null;
      }
    };
    const filterHolding = computed(() => store.getters[HOLDING_FILTER]);

    const subHoldingFilter = computed(() => {
      if (formState.holding_name !== null) {
        return store.getters[SUBHOLDINGS_FILTER].filter((f) => {
          if (formState.holding_name !== null) {
            return [formState.holding_name].some((h) => f.idholding == h);
          }
          return f;
        });
      }
      return store.getters[SUBHOLDINGS_FILTER];
    });

    const selectHolding = computed({
      get() {
        return (formState.holding_name = store.getters[SELECT_HOLDING]);
      },
      set(value) {
        store.dispatch(HOLDINGS_SELECTS, value);
      }
    });
    const selectSubholding = computed({
      get() {
        return (formState.subholding_name =
          store.getters[SELECTED_SUBHOLDINGS]);
      },
      set(value) {
        store.dispatch(ACTION_SELECTED_ALL_SUBHOLDING, value);
      }
    });

    // Cargar los valores del prop apenas cargue el componente
    onMounted(() => {
      modal_props.visibility = props.modelValue;
    });

    // Visibilidad
    watch(
      () => props.modelValue,
      (value) => {
        modal_props.visibility = value;
      }
    );

    // Creando compania
    const creatingCompany = ref(false);

    const handleOk = () => {
      formRef.value
        .validate()
        .then(() => {
          if (creatingCompany.value) {
            finishCompanyCreation();
          } else {
            editCompanyFinish();
          }

          // emitResponse();
        })
        .catch((error) => {
          console.log('Error', error);
        });
    };

    const handleClose = () => {
      // Reseteo los fields
      formRef.value.resetFields();

      // Dejo la variable que dice que estoy editando en falso y creando igual
      creatingCompany.value = false;
      editingCompany.value = false;
      deletingCompany.value = false;

      modal_props.visibility = false;
      selectSubholding.value = null;
      selectHolding.value = null;
      formState.subholding_name = null;
      formState.holding_name = null;

      emitResponse();
    };

    // Funcion para emitir el cambio de la prop hacia el padre automaticamente con v-model
    const emitResponse = () => {
      emit('update:modelValue', false);
    };

    const valid_rut = ref(false);
    const checkRut = async (rule, value) => {
      if (!value) {
        const error_message = 'Ingrese RUT';
        return Promise.reject(error_message);
      }
      if (!rutHelper.validateRut(value)) {
        return Promise.reject('RUT no válido');
      }
      return Promise.resolve();
    };

    const checkHolding = async (rule, value) => {
      if (
        formState.holding_checked === false &&
        value === null &&
        formState.holding_name === null &&
        selectHolding.value === null
      ) {
        return Promise.reject('Seleccione un holding');
      }
      return Promise.resolve();
    };

    const checkSubholding = async (rule, value) => {
      if (
        formState.subholding_checked === false &&
        value === null &&
        selectSubholding.value === null
      ) {
        return Promise.reject('Seleccione un subholding');
      }
      return Promise.resolve();
    };

    // Validacion en los checks

    // Si hago click en 'No asociar Holding'
    // # Marco checked a 'No Asociar SubHolding'
    // # Quito la opcion en el selector de Holding
    // # Quito la opcion en el selector de Subholding
    // # Deshabilito el select de holding
    // # Deshabilito el select de subholding
    watch(
      () => formState.holding_checked,
      (value) => {
        if (value) {
          formState.holding_checked = true;
          formState.subholding_checked = true;
          modal_props.holding_disabled = true;
          selectHolding.value = null;
        } else {
          formState.holding_checked = false;
          modal_props.holding_disabled = false;
        }
        if (
          formState.subholding_checked == true &&
          formState.holding_checked == true
        ) {
          modal_props.holding_disabled = true;
          selectHolding.value = null;
          selectSubholding.value = null;
        }
      }
    );

    // Si hago click en 'No asociar Subholding'

    watch(
      () => formState.subholding_checked,
      (value) => {
        if (creatingCompany.value) {
          if (value) {
            formState.subholding_checked = true;
            modal_props.subholding_disabled = true;
            selectSubholding.value = null;
          } else {
            formState.holding_checked = false;
            modal_props.subholding_disabled = false;
            modal_props.holding_disabled = false;
            formState.subholding_checked = false;
          }
          if (
            formState.subholding_checked == true &&
            formState.holding_checked == true
          ) {
            modal_props.subholding_disabled = true;
            modal_props.holding_disabled = true;
          }
        }
        if (editingCompany.value) {
          if (value) {
            formState.subholding_checked = true;
            modal_props.subholding_disabled = true;
            selectSubholding.value = null;
            // selectSubholding.value=null;
          } else {
            formState.holding_checked = false;
            modal_props.subholding_disabled = false;
            modal_props.holding_disabled = false;
            formState.subholding_checked = false;
          }
          if (
            formState.subholding_checked == true &&
            formState.holding_checked == true
          ) {
            modal_props.subholding_disabled = false;
            modal_props.holding_disabled = false;
          }
          if (
            formState.holding_checked == false &&
            formState.subholding_checked == false
          ) {
            if (formState.holding_name) {
              holdings_list.value.filter((e) => {
                if (e.businessName == formState.holding_name) {
                  formState.holding_name = e.id;
                }
              });
            }
          }
        }
      }
    );

    const rules = {
      rut: [
        {
          required: true,
          validator: checkRut,
          trigger: 'change'
        }
      ],
      name: [
        {
          required: true,
          message: 'Ingrese razón social',
          trigger: 'change',
          type: 'string'
        }
      ],
      holding_name: [
        {
          required: true,
          validator: checkHolding,
          trigger: 'change'
        }
      ],
      subholding_name: [
        {
          required: true,
          validator: checkSubholding,
          trigger: 'change'
        }
      ]
    };

    // * Creacion de empresa
    const store = useStore();

    const createCompany = () => {
      if (
        formState.holding_checked == true &&
        formState.subholding_checked == true
      ) {
        modal_props.holding_disabled = true;
      }
      deletingCompany.value = false;
      editingCompany.value = false;

      modal_props.button_text = 'Crear empresa';

      // Variable que lleva el registro de si estoy creando o no la company
      creatingCompany.value = true;

      // Agrego un titulo
      modal_props.title = 'Crear empresa';

      // Abro el modal
      modal_props.visibility = true;
    };

    const finishCompanyCreation = async () => {
      modal_props.loading = true;

      // Objeto que mandare al endpoint
      const post_obj = {
        rut: rutHelper.normalizeRut(formState.rut),
        businessName: formState.name
      };

      try {
        if (
          formState.holding_checked == false &&
          formState.subholding_checked == false
        ) {
          post_obj['subHoldingId'] = formState.subholding_name;
        } else if (!formState.holding_checked && formState.subholding_checked) {
          post_obj['holdingId'] = formState.holding_name;
        }

        const resp = await store.dispatch(POST_COMPANY, post_obj);
        await delay(1500);

        if (resp.status >= 200) {
          openNotificationWithIcon('success', 'Correcto', 'Compañía creada');
          handleClose();
          emit('companyCreated', true);
        }
      } catch (error) {
        const error_message = error.response.data.message;
        let final_error = '';

        if (error_message === 'Company RUT and name already exists') {
          final_error = 'RUT y nombre para la compañía ya existe';
        } else if (error_message === 'Company name already exists') {
          final_error = 'Nombre para la compañía ya existe';
        } else if (error_message === 'Company RUT already exists') {
          final_error = 'RUT para la compañía ya existe';
        }

        openNotificationWithIcon('error', 'Error', final_error);
      }
      modal_props.loading = false;
    };

    // * Holdings
    const holdings_list = computed(() => store.getters[GET_HOLDINGS]);

    // * Subholdings

    // * Apenas cargue el componente obtengo la lista de holdings y subholdings
    onMounted(() => {
      store.dispatch(FETCH_ALL_HOLDINGS);
    });
    const suhboldings_list = computed(() =>
      holdings_list.value
        .map((e) => ({
          ...{
            d: e.subHolding.map((f) => ({
              ...f,
              holding: e.businessName,
              rut_holding: e.rut
            }))
          }
        }))
        .reduce((acc, item) => {
          return [...acc, ...item.d];
        }, [])
        .map((sh) => ({
          id: sh.id,
          label: sh.name
        }))
        .sort((a, b) => a.id - b.id)
    );

    // * Eliminar empresa
    const deletingCompany = ref(false);
    const deletingObject = ref({ id: null, name: null, style: null });

    const deleteCompany = (values) => {
      deletingObject.value.id = null;
      deletingObject.value.name = null;
      deletingCompany.value = false;

      deletingObject.value.id = values.id;
      deletingObject.value.name = values.companyName;
      deletingCompany.value = true;

      deletingObject.value.style = 'danger';
      modal_props.title = 'Eliminar empresa';
      modal_props.visibility = true;
    };

    const handleDelete = async () => {
      modal_props.loading = true;
      try {
        await store.dispatch(DELETE_COMPANY, deletingObject.value.id);

        await delay(1500); // ! Eliminar en produccion, es solo para probar

        openNotificationWithIcon(
          'success',
          'Correcto',
          'Compañía eliminada con éxito'
        );
        await store.dispatch(FETCH_ALL_COMPANIES);
        handleClose();
        emit('deletedCompany', false);
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          `No se ha podido eliminar la Compañía. ${error.response.data.message}`
        );
      }

      modal_props.loading = false;
      handleClose();
    };

    // * Editar empresa

    const editingCompany = ref(false);
    const company_edit = ref({});
    const company_id = ref('');

    const editCompany = async (id_company) => {
      deletingCompany.value = false;
      creatingCompany.value = false;

      modal_props.subholding_disabled = false;
      modal_props.holding_disabled = false;

      modal_props.button_text = 'Editar empresa';

      // agrego el id
      company_id.value = id_company;

      // Le digo al modal que estoy editando
      editingCompany.value = true;

      // Agrego un titulo
      modal_props.title = 'Editar empresa';

      // Abro el modal
      modal_props.visibility = true;

      // Obtengo la company
      await store.dispatch(FETCH_COMPANY, id_company);

      // Obtengo la data de company
      const company_data = store.getters[GET_COMPANY];
      formState.rut = rutHelper.normalizeRut(company_data.rut);
      formState.name = company_data.businessName;

      // Si holding viene null y subholding viene con valor
      // let hold_value;
      // let subhold_value;

      if (
        company_data?.holdingId == null &&
        company_data.subHoldingId !== null
      ) {
        selectHolding.value = company_data?.subHolding?.holdingId;
        selectSubholding.value = company_data.subHoldingId;
      }
      if (company_data.subHoldingId == null) {
        formState.subholding_name = null;
        selectHolding.value = company_data?.holding?.businessName;
      }
      if (modal_props.visibility == false) {
        selectHolding.value = null;
        selectSubholding.value = null;
      }
      if (selectHolding.value) {
        modal_props.holding_disabled = false;
        formState.holding_checked = false;
      } else {
        formState.holding_checked = true;
        modal_props.holding_disabled = true;
      }
      if (selectSubholding.value) {
        modal_props.subholding_disabled = false;
        formState.subholding_checked = false;
      } else {
        formState.subholding_checked = true;
        modal_props.subholding_disabled = true;
      }

      company_edit.value = {
        rut: rutHelper.normalizeRut(formState.rut),
        name: formState.name,
        holding_name: formState.holding_name,
        holding_checked: formState.holding_checked,
        subholding_name: formState.subholding_name,
        subholding_checked: formState.subholding_checked
      };
    };
    const editCompanyFinish = async () => {
      modal_props.loading = true;

      try {
        // Obtengo los valores que son distintos al momento de editar
        const object_keys = Object.keys(formState);
        const obj = {};

        object_keys.forEach((element) => {
          if (company_edit.value[element] != formState[element]) {
            obj[element] = formState[element];
          }
        });

        // Creo y le paso las propiedades a un objeto que es el que se va a mandar como post
        const post_obj = {};
        if (
          formState.holding_checked == false &&
          formState.subholding_checked == false
        ) {
          post_obj['subHoldingId'] = formState.subholding_name;
        } else if (!formState.holding_checked && formState.subholding_checked) {
          post_obj['holdingId'] = formState.holding_name;
        }
        if (
          formState.holding_checked == false &&
          formState.subholding_checked == true
        ) {
          holdings_list.value.filter((e) => {
            if (e.businessName == formState.holding_name) {
              formState.holding_name = e.id;
              post_obj['holdingId'] = formState.holding_name;
            }
          });
          // post_obj['holdingId'] = ;
        }

        if ('rut' in obj) {
          post_obj['rut'] = rutHelper.normalizeRut(formState.rut);
        }

        if ('name' in obj) {
          post_obj['businessName'] = formState.name;
        }

        const resp = await store.dispatch(PATCH_COMPANY, {
          payload: post_obj,
          id: company_id.value
        });
        await delay(1500);

        emit('updateCompany', true);

        if (resp.status >= 200) {
          handleClose();
          openNotificationWithIcon('success', 'Correcto', 'Compañía Editada');
        }
      } catch (error) {
        openNotificationWithIcon('error', 'Error', 'Ocurrio un error');
      }

      modal_props.loading = false;
    };

    // * habilitar boton para enviar la data
    watch(
      () => formState,
      (value) => {
        if (editingCompany.value) {
          if (
            company_edit.value.rut != value.rut ||
            company_edit.value.name != value.name ||
            company_edit.value.holding_name != value.holding_name ||
            company_edit.value.holding_checked != value.holding_checked ||
            company_edit.value.subholding_name != value.subholding_name ||
            company_edit.value.subholding_checked != value.subholding_checked
          ) {
            modal_props.disabled = false;
          } else {
            modal_props.disabled = true;
          }
        } else {
          modal_props.disabled = false;
        }
      },
      { deep: true }
    );

    const disableDelete = () => {
      let blocked = true;
      if (formState.eliminar === 'eliminar') {
        blocked = false;
      }
      return blocked;
    };

    return {
      // subholdingfiltro,
      handleChange,
      filterHolding,
      subHoldingFilter,
      selectHolding,
      selectSubholding,
      handleClose,
      rules,
      formRef,
      formState,
      modal_props,
      handleOk,
      emitResponse,
      holdings_list,
      suhboldings_list,
      editCompany,
      createCompany,
      deleteCompany,
      deletingCompany,
      deletingObject,
      company_edit,
      disableDelete,
      handleDelete,
      formatRuts,
      valid_rut
    };
  }
};
</script>
