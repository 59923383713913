<template>
  <div v-if="$route.name === 'Cliente'">
    <page-header :title="titleCase(activeKey)" :breadcrumRoutes="routes">
      <template #buttons>
        <a-button v-if="show_holding" @click="createHolding" type="primary">Crear holding</a-button>
        <a-button v-if="show_subholding" @click="createSubHolding" type="primary">Crear Subholding</a-button>
        <a-button v-if="show_empresas" @click="createEmpresa" type="primary">Crear empresa</a-button>
      </template>
    </page-header>

    <a-card>
      <a-tabs :style="{ zIndex: 0 }" v-model:activeKey="activeKey" @tabClick="tabClick">
        <a-tab-pane tab="Holdings" key="Holdings">
          <div v-if="activeKey === 'Holdings' && enable_tab_content">
            <holdings ref="holding" />
          </div>
        </a-tab-pane>
        <a-tab-pane tab="Subholdings" key="Subholdings">
          <div v-if="activeKey === 'Subholdings' && enable_tab_content">
            <subholdings ref="subHolding" />
          </div>
        </a-tab-pane>
        <a-tab-pane tab="Empresas" key="Empresas">
          <div v-if="activeKey === 'Empresas' && enable_tab_content">
            <empresas ref="empresa" />
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
  <div v-else>
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import PageHeader from '@/components/PageHeader.vue';
import Holdings from './cliente_components/Holdings.vue';
import Subholdings from './cliente_components/Subholdings.vue';
import Empresas from './cliente_components/Empresas.vue';
import { GET_USER_ROLE, SAVE_CLIENT_CURRENT_TAB } from '@/store/types';

export default {
  name: 'ClientDashboard',
  components: {
    'page-header': PageHeader,
    holdings: Holdings,
    subholdings: Subholdings,
    empresas: Empresas
  },
  setup() {
    // * Vuex
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    // Rol del usuario que esta autenticado
    const user_role = store.getters[GET_USER_ROLE];

    // * Tabs
    const activeKey = ref('');
    onBeforeMount(() => {
      switch (route.hash) {
        case '#subholdings':
          activeKey.value = 'Subholdings';
          break;
        case '#empresas':
          activeKey.value = 'Empresas';
          break;
        default:
          activeKey.value = 'Holdings';
          break;
      }
    });

    const holding_params = ref({});
    const subholding_params = ref({});
    const company_params = ref({});

    const saveParams = (hash) => {
      switch (hash) {
        case '#subholdings':
          subholding_params.value = route.query;
          break;
        case '#empresas':
          company_params.value = route.query;
          break;
        default:
          holding_params.value = route.query;
          break;
      }
    };

    const enable_tab_content = ref(true);
    const tabClick = async (e) => {
      enable_tab_content.value = false;

      const hash_path = route.hash;
      saveParams(hash_path);

      if (e === 'Holdings') {
        await router.replace({
          query: holding_params.value,
          name: 'Cliente'
        });
      } else if (e === 'Subholdings') {
        await router.replace({
          name: 'Cliente',
          query: subholding_params.value,
          hash: '#subholdings'
        });
      } else {
        await router.replace({
          name: 'Cliente',
          query: company_params.value,
          hash: '#empresas'
        });
      }

      enable_tab_content.value = true;
    };

    // * Poner el titulo del tab en mayuscula
    const titleCase = (string) => {
      return string[0].toUpperCase() + string.slice(1).toLowerCase();
    };

    // * Page header
    const show_holding = computed(() => {
      return activeKey.value === 'Holdings' && user_role === 'superadmin';
    });

    const show_subholding = computed(() => {
      return activeKey.value === 'Subholdings' && user_role === 'superadmin';
    });

    const show_empresas = computed(() => {
      return activeKey.value === 'Empresas' && user_role === 'superadmin';
    });
    // * Holding
    const holding = ref(null);

    const createHolding = async () => {
      holding.value.holdingCreation();
    };

    // * Subholding
    const subHolding = ref(null);
    const createSubHolding = () => {
      subHolding.value.subHoldingCreation();
    };

    // * Crear Empresas
    // * Empresas
    const empresa = ref(null);
    const createEmpresa = () => {
      empresa.value.createCompany();
    };

    // * Cambio del breadcrums en caso de que se cambie el tab
    const routes = ref([
      {
        path: '/clientes',
        breadcrumbName: 'Cliente'
      },
      {
        breadcrumbName: 'Holdings'
      }
    ]);

    watch(activeKey, (value) => {
      // Aca cambio los breadcrums y ademas el boton que se muestre o no
      routes.value[1].breadcrumbName = value;

      // Guardo la ultima tab que tengo activa, asi en caso de irme a otra pagina, se mantiene esta tab y no vuelve a empezar desde holdings
      store.dispatch(SAVE_CLIENT_CURRENT_TAB, value);
    });

    return {
      activeKey,
      titleCase,
      routes,
      show_holding,
      show_subholding,
      show_empresas,
      useStore,
      user_role,
      createHolding,
      holding,
      subHolding,
      createSubHolding,
      empresa,
      createEmpresa,
      tabClick,
      enable_tab_content
    };
  }
};
</script>
