<template>
  <div class="mb-3">
    <a-card>
      <a-typography-title :level="3">Listado de cobranzas</a-typography-title>
      <p>Aquí encontraras el detalle de tus tickets de cobranza</p>

      <a-table
        :columns="columns"
        :dataSource="data"
        :pagination="pagination"
        :loading="loading"
      >
        <template #idRender="{ text: id }">
          <span>#{{ id }}</span>
        </template>

        <template #tagRender="{ text }">
          <a-tag :color="getTagColor(text)">
            {{ getTagText(text)?.toUpperCase() }}
          </a-tag>
        </template>

        <!-- <template #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column
        }">
          <div style="padding: 8px">
            <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block" @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              " @pressEnter="
  handleSearch(selectedKeys, confirm, column.dataIndex)
" />
            <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
              <template #icon>
                <SearchOutlined />
              </template>
              Search
            </a-button>
            <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
              Reset
            </a-button>
          </div>
        </template> -->
        <!-- <template #filterIcon="{ filtered }">
          <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template> -->
        <template #customRender="{ text }">
          <div v-if="text.length > 1">
            <a-tooltip>
              <template #title>
                <span v-for="(t, index) in text" :key="index">
                  <p v-if="index != 0">{{ t }}</p>
                </span>
              </template>
              <span
                >{{ text[0] }} <b>+{{ text.length - 1 }}</b></span
              >
            </a-tooltip>
          </div>
          <div v-else>
            <a-tooltip>
              <span>{{ text[0] }}</span>
            </a-tooltip>
          </div>
        </template>

        <template #actionRender="{ text: id }">
          <a-tooltip color="#05045D" title="Ver">
            <router-link
              :to="{
                name: 'detalleTicketCobranza',
                params: { id }
              }"
            >
              <eye-outlined class="blue-icon" />
            </router-link>
          </a-tooltip>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import {
  onMounted,
  reactive,
  ref,
  toRefs,
  watchEffect,
  computed,
  watch,
  onBeforeUnmount
} from 'vue';
import { EyeOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import {
  FETCH_COLLECTION_GROUP_COLLECTIONS,
  GET_COLLECTION_GROUP_COLLECTIONS
} from '../../../store/types';
import getTagText from '../../../utils/getTagText';
import getTagColor from '../../../utils/getTagColor';
import EventBus from '../../../utils/events';
import delay from '@/utils/delay';

export default {
  name: 'CollectionListTable',
  components: {
    'eye-outlined': EyeOutlined
  },
  props: {
    collectionFormId: {
      type: [String, Number],
      default: 0
    }
  },
  setup(props) {
    // * Variables
    const store = useStore();
    const loading = ref(false);

    // * Table
    const searchInput = ref();
    const state = reactive({
      searchText: '',
      searchedColumn: ''
    });

    const columns = ref([
      {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'idRender'
        },
        sorter: (a, b) => a.id - b.id,
        sortDirections: ['descend']
      },
      {
        title: 'Cobranza',
        dataIndex: 'collectionStatus',
        key: 'collectionStatus',
        filters: [
          {
            text: 'Iniciada',
            value: 'CREATED'
          },
          {
            text: 'Validación corredora',
            value: 'BEWELL_VALIDATION'
          },
          {
            text: 'Validación RRHH.',
            value: 'HR_VALIDATION'
          },
          {
            text: 'Aprobada',
            value: 'APPROVED'
          }
        ],
        slots: {
          customRender: 'tagRender'
        },
        onFilter: (value, record) =>
          record.collectionStatus.indexOf(value) === 0,
        sorter: (a, b) => a.collectionStatus.length - b.collectionStatus.length,
        sortDirections: ['descend']
      },
      {
        title: 'Factura',
        dataIndex: 'invoiceStatus',
        key: 'invoiceStatus',
        filters: [
          {
            text: 'Emitida',
            value: 'ISSUED'
          },
          {
            text: 'Sin emitir',
            value: 'NOT_ISSUED'
          }
        ],
        slots: {
          customRender: 'tagRender'
        },
        onFilter: (value, record) => record.invoiceStatus.indexOf(value) === 0,
        sorter: (a, b) => a.invoiceStatus.length - b.invoiceStatus.length,
        sortDirections: ['descend']
      },
      {
        title: 'Nómina',
        dataIndex: 'payrollStatus',
        key: 'payrollStatus',
        filters: [
          {
            text: 'Cuadra',
            value: 'NOT_IMPLEMENTED'
          },
          {
            text: 'No cuadra',
            value: 'NOT_IMPLEMENTED'
          }
        ],
        slots: {
          customRender: 'tagRender'
        },
        onFilter: (value, record) => record.payrollStatus.indexOf(value) === 0,
        sorter: (a, b) => a.payrollStatus.length - b.payrollStatus.length,
        sortDirections: ['descend']
      },
      {
        title: 'Subholding',
        dataIndex: 'subholding',
        key: 'subholding',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'customRender'
        },
        onFilter: (value, record) => record.subholding.indexOf(value) === 0,
        filters: []
        /* onFilter: (value, record) =>
            record.subholding
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                searchInput.value.focus();
              }, 100);
            }
          } */
      },
      {
        title: 'Razón social',
        dataIndex: 'bussinessName',
        key: 'bussinessName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'customRender'
        },
        onFilter: (value, record) =>
          record.bussinessName
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Póliza',
        dataIndex: 'policy',
        key: 'policy',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'customRender'
        },
        onFilter: (value, record) =>
          record.policy.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Detalle',
        dataIndex: 'detail',
        key: 'detail',
        slots: {
          customRender: 'actionRender'
        }
      }
    ]);

    watchEffect(() => {
      const value = store.getters[GET_COLLECTION_GROUP_COLLECTIONS];
      const subholding_options = [
        ...new Set(value?.map((item) => item.subHolding))
      ][0];
      const bussiness_options = [
        ...new Set(value?.map((item) => item.companies))
      ][0];
      const policies_options = [
        ...new Set(value?.map((item) => item.policies))
      ][0];
      columns.value[4].filters = subholding_options?.map((item) => {
        return {
          text: item,
          value: item
        };
      });
      columns.value[5].filters = bussiness_options?.map((item) => {
        return {
          text: item,
          value: item
        };
      });
      columns.value[6].filters = policies_options?.map((item) => {
        return {
          text: item,
          value: item
        };
      });
    });

    const pagination = {
      pageSize: 5
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
    };

    const handleReset = (clearFilters) => {
      clearFilters();
      state.searchText = '';
    };

    // * data principal
    const data = computed(() => {
      return store.getters[GET_COLLECTION_GROUP_COLLECTIONS].map((obj) => {
        return {
          id: obj.id,
          key: obj.id,
          collectionStatus: obj.status,
          invoiceStatus: obj.invoiceStatus,
          payrollStatus: obj.squaringStatus,
          subholding: obj.subHolding, // ['subholding','subholding 2','subholding 3'],
          bussinessName: obj.companies, // ['Razon social','Razon social 2','Razon social 3','Razon social 4'],
          policy: obj.policies, // ['Poliza','Poliza 2'],
          detail: obj.id
        };
      });
    });

    // primero el evento de eventBus.emit esta en collectionValidModal dentro del post de  validar llamo a la peticion de UpdateTableByModalValidation
    // lo que hago es llamar a una peticion para que me lo deje en processing y estando en processing
    // me tome el getcollection group el cual me toma en processing y valida y recarga hasta salir de processing
    //
    const UpdateTableByModalValidation = async () => {
      try {
        if (data.value.payrollStatus == 'PROCESSING') {
          EventBus.emit('loadingValidate', true);
          await getCollectionGroupCollections();
        }
      } catch (error) {
        console.log('error', error);
      }
    };
    EventBus.on('UpdateData', UpdateTableByModalValidation);

    const getCollectionGroupCollections = async () => {
      loading.value = true;
      try {
        await store.dispatch(
          FETCH_COLLECTION_GROUP_COLLECTIONS,
          props.collectionFormId
        );
        await delay(1000);
      } catch (error) {
        loading.value = false;
        console.log('error', error);
      }
      loading.value = false;
    };
    const interval = ref(0);

    const reloadCollectionGroup = () => {
      interval.value = setInterval(async () => {
        getCollectionGroupCollections();
      }, 20000);
    };

    watch(data, (new_val) => {
      const is_different_than_completed = new_val.some(
        (obj) => obj.payrollStatus == 'PROCESSING'
      );
      if (is_different_than_completed === true) {
        if (interval.value === 0) {
          reloadCollectionGroup();
        }
      } else {
        clearInterval(interval.value);
        interval.value = 0;
      }
    });

    onMounted(() => {
      getCollectionGroupCollections();
    });
    onBeforeUnmount(() => {
      clearInterval(interval.value);
    });

    return {
      searchInput,
      data,
      columns,
      pagination,
      ...toRefs(state),
      handleSearch,
      handleReset,
      getTagText,
      getTagColor,
      loading,
      reloadCollectionGroup,
      interval
    };
  }
};
</script>
