<template>
  <a-config-provider :locale="es_ES">
    <router-view />
  </a-config-provider>
</template>

<script>
import es_ES from 'ant-design-vue/lib/locale-provider/es_ES';
import 'moment/locale/zh-cn';

export default {
  data() {
    return {
      es_ES
    };
  }
};
</script>
