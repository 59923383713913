<template>
  <div>
    <a-row type="flex">
      <ArrowLeftOutlined class="back-arrow mr-3" @click="goBack" />
      <a-typography-title :level="4">Volver a pólizas</a-typography-title>
    </a-row>

    <a-skeleton active :loading="policy_info_loading">
      <a-row type="flex" justify="space-between" class="mt-3">
        <a-typography-title :level="4">Póliza Nº {{ policy_data.numberPolicy }}</a-typography-title>
        <a-button @click="openEditPolicy">Editar info</a-button>
      </a-row>
      <a-descriptions bordered>
        <a-descriptions-item label="Número póliza" :span="3">
          {{ policy_data.numberPolicy }}
        </a-descriptions-item>
        <a-descriptions-item label="Compañía aseguradora" :span="3">
          {{ policy_data.insuranceCompany }}
        </a-descriptions-item>
        <a-descriptions-item label="Grupo" :span="3">
          {{ policy_data.groupCompanies }}
        </a-descriptions-item>
        <a-descriptions-item label="Fecha vigencia" :span="3">
          {{ policy_data.initCurrency }}
        </a-descriptions-item>
        <a-descriptions-item label="Fecha término" :span="3">
          {{ policy_data.endCurrency }}
        </a-descriptions-item>
        <a-descriptions-item label="Documentos" :span="3">
          <ul v-if="policy_data?.docs?.length > 0">
            <li v-for="document in policy_data.docs" :key="document.id">
              <a v-if="/\.pdf$/i.test(document.fileName)" @click="openPdfViewerModal(document.filePolicyURL)">{{
                document.fileName }}</a>
              <a v-else :href="document.filePolicyURL" target="_blank" rel="noreferrer noopener">{{ document.fileName
                }}</a>
            </li>
          </ul>
          <p v-else>Sin documentos</p>
        </a-descriptions-item>
      </a-descriptions>

      <a-row type="flex" justify="space-between" class="mt-4">
        <a-typography-title :level="4">Coberturas</a-typography-title>
        <a-button @click="openAddCoverageModal">Agregar coberturas</a-button>
      </a-row>
    </a-skeleton>

    <PolicyCoverages class="mb-3" :data="policy_coverages" @openEditCoverage="openEditCoverage"
      @openDeleteCoverage="openDeleteCoverage" />

    <div class="coverage-box" v-if="
      company_groups_coverages ? company_groups_coverages.length === 0 : true
    ">
      <p>No hay coberturas</p>
    </div>
    <div v-else>
      <div v-for="comp_group in company_groups_coverages" :key="comp_group.id">
        <CompanyGroupCoverages class="mb-3" :data="comp_group" :groupName="comp_group.name"
          @primeConfiguration="openPrimeConfiguration" />
      </div>
    </div>

    <PDFViewer ref="pdf_viewer" />
    <PolicyEditModal ref="edit_policy_ref" @editedPolicy="getMainData" />
    <AddCoverage ref="add_coverage_modal" :groups="company_group" @coverageCreated="coverageCreated" />
    <EditCoverage ref="edit_coverage_ref" :groups="company_group" />
    <DeleteCoverage ref="delete_coverage_ref" :groups="company_group" />
    <PrimeConfiguration ref="prime_configuration" @completed="completedPrimeConfiguration" />
  </div>
</template>

<script setup>
import { ArrowLeftOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  FETCH_POLICY,
  FETCH_POLICY_COVERAGES,
  FETCH_POLICY_GROUP_COMPANY,
  FETCH_GROUP_COMPANY_PREMIUM_CALC
} from '@/store/types';
import PDFViewer from '@/components/PDFViewer';
import PolicyEditModal from '@/components/clients/policy/PolicyEditModal';
import AddCoverage from '@/components/policy/AddCoverage';
import CompanyGroupCoverages from '@/components/policy/CompanyGroupCoverages';
import EditCoverage from '@/components/policy/EditCoverage';
import DeleteCoverage from '@/components/policy/DeleteCoverage';
import PolicyCoverages from '@/components/clients/policy/PolicyCoverages';
import PrimeConfiguration from '@/components/clients/policy/PrimeConfiguration.vue';
import EventBus from '@/utils/events';

const store = useStore();
const router = useRouter();
const policy_data = ref({});
const route = useRoute();
const {
  // id:company_id,
  policyId
} = route.params;
const policy_object = ref({});
const policy_info_loading = ref(false);
const pdf_viewer = ref();
const edit_policy_ref = ref();
const policy_coverages = ref([]);
const company_groups_coverages = ref([]);
const company_group = ref([]);
const add_coverage_modal = ref();
const edit_coverage_ref = ref();
const delete_coverage_ref = ref();
const prime_configuration = ref();
const policy_coverages_loading = ref(false);

const openEditPolicy = () => {
  edit_policy_ref.value.openModal(policy_object.value);
};

const openPdfViewerModal = (url) => {
  pdf_viewer.value.openModal(url);
};

const getPolicy = async () => {
  policy_info_loading.value = true;
  try {
    const resp = await store.dispatch(FETCH_POLICY, policyId);
    policy_object.value = resp;
    const {
      numberPolicy,
      insuranceCompany,
      groupCompanies,
      startDate,
      endDate,
      filePolicies
    } = resp;

    policy_data.value = {
      numberPolicy,
      insuranceCompany: insuranceCompany.businessName,
      groupCompanies:
        groupCompanies?.length > 0
          ? groupCompanies.map((group) => group.name).join(', ')
          : ' - ',
      initCurrency: startDate,
      endCurrency: endDate,
      docs: filePolicies
    };

    company_group.value = groupCompanies.map((group) => ({
      id: group.id,
      name: group.name
    }));
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
    router.push({ name: 'Missing' });
  }
  policy_info_loading.value = false;
};

const getPolicyCoverages = async () => {
  policy_coverages_loading.value = true;
  try {
    const resp = await store.dispatch(FETCH_POLICY_COVERAGES, policyId);
    policy_coverages.value = resp;
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
  policy_coverages_loading.value = false;
};

const getPolicyCompanyGroups = async () => {
  try {
    const resp = await store.dispatch(FETCH_POLICY_GROUP_COMPANY, policyId);
    company_groups_coverages.value = [];

    resp.forEach(async (group) => {
      const coverages = await getCompanyGroupCoverages(group.id);

      if (coverages != null) {
        company_groups_coverages.value.push({
          groupCompany: group.id,
          ...coverages
        });
      }
    });
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
};

const getCompanyGroupCoverages = async (groupId) => {
  try {
    const resp = await store.dispatch(FETCH_GROUP_COMPANY_PREMIUM_CALC, {
      policyId,
      groupId
    });

    return resp.data;
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
};

const openAddCoverageModal = () => {
  add_coverage_modal.value.openModal();
};

const coverageCreated = () => {
  getPolicyCoverages();
  getPolicyCompanyGroups();
};

// const getCompanyGroupName = (id) => {
//   const name = company_group.value.find(obj => obj.id === id).name;
//   console.log('name',name);
//   return name;
// };

const openEditCoverage = (coverageId) => {
  edit_coverage_ref.value.openModal(coverageId);
};

const openDeleteCoverage = (coverageId) => {
  delete_coverage_ref.value.openModal(coverageId);
};

const openPrimeConfiguration = (value) => {
  prime_configuration.value.openModal(value);
};

const completedPrimeConfiguration = () => {
  getMainData();
};

const goBack = () => {
  router.go(-1);
};

const getMainData = () => {
  getPolicy();
  getPolicyCoverages();
  getPolicyCompanyGroups();
};

onMounted(() => {
  getMainData();
});

EventBus.on('coverageEdited', () => {
  getPolicyCoverages();
  getPolicyCompanyGroups();
});

EventBus.on('coverageDeleted', () => {
  getPolicyCoverages();
  getPolicyCompanyGroups();
});
</script>

<style>
.back-arrow {
  font-size: 30px;
  color: black !important;
}
</style>
