<template>
  <div>
    <a-row type="flex" justify="space-between">
      <a-typography-title :level="4">{{ form_type_text }}</a-typography-title>
      <a-button type="primary" class="ml-3" @click="openIncorporationModal">Configurar formulario</a-button>
    </a-row>

    <a-skeleton active :loading="loading_data">
      <div v-if="form_type === 2">
        <a-row type="flex" justify="space-between" class="my-4">
          <a-typography-title :level="5">Consentimientos</a-typography-title>
          <a-button @click="addParagraph" v-if="editting">
            <template #icon>
              <PlusOutlined />
            </template>
            Agregar consentimiento
          </a-button>
        </a-row>

        <a-empty v-if="form_state.consents.length === 0" />
        <div v-else>
          <a-typography-text>
            Puedes utilizar las siguientes variables en el título y contenido:
            {PRIMER_NOMBRE}, {SEGUNDO_NOMBRE}, {PRIMER_APELLIDO},
            {SEGUNDO_APELLIDO}, {NOMBRE_COMPLETO}
          </a-typography-text>

          <a-form ref="form_ref" :model="form_state" layout="vertical" class="mt-5">
            <div v-for="(consent, consentIndex) in form_state.consents" :key="consentIndex" class="mb-5">
              <a-row type="flex" justify="space-between">
                <a-typography-title :level="4">Párrafo {{ consentIndex + 1 }}</a-typography-title>
                <div>
                  <a-button size="small" v-if="
                    consentIndex != form_state.consents.length - 1 && editting
                  " @click="moveParagraphDown(consentIndex)">
                    <template #icon>
                      <DownOutlined />
                    </template>
                    Bajar
                  </a-button>
                  <a-button size="small" v-if="consentIndex > 0 && editting" @click="moveParagraphUp(consentIndex)"
                    class="ml-2">
                    <template #icon>
                      <UpOutlined />
                    </template>
                    Subir
                  </a-button>
                  <a-button v-if="editting" size="small" @click="deleteParagraph(consentIndex)" class="ml-2">
                    Eliminar
                  </a-button>
                </div>
              </a-row>

              <a-form-item label="Título" :rules="{
                required: true,
                message: 'Ingrese el título',
                trigger: 'change',
                type: 'string'
              }" :name="['consents', consentIndex, 'title']">
                <a-input :disabled="!editting" ref="title" v-model:value="consent.title" />
              </a-form-item>
              <a-form-item label="Contenido" :rules="{
                required: true,
                message: 'Ingrese el contenido',
                trigger: 'change',
                type: 'string'
              }" :name="['consents', consentIndex, 'content']">
                <a-textarea :disabled="!editting" ref="content" v-model:value="consent.content" />
              </a-form-item>

              <a-divider v-if="
                form_state.consents.length > 1 &&
                consentIndex + 1 !== form_state.consents.length
              " />
            </div>
          </a-form>
        </div>
        <a-row type="flex" justify="end">
          <a-button v-if="editting && form_ref" type="primary" @click="formSubmit" :loading="loading">Guardar</a-button>
          <a-button v-if="!editting" type="primary" @click="toggleEdit" :loading="loading">Editar</a-button>
        </a-row>
      </div>
      <div v-else-if="form_type === 1">
        <TypeForms />
      </div>
    </a-skeleton>

    <IncorporationFormConfModal :formType="form_type" ref="incorporation_modal_ref"
      @selectedFormType="selectedFormType" />
  </div>
</template>

<script setup>
import { ref, watch, reactive, onMounted, computed } from 'vue';
import IncorporationFormConfModal from './IncorporationFormConfModal.vue';
import { useStore } from 'vuex';
import {
  FETCH_COMPANY_DIGITAL_FORM_CONSENT_TEXT,
  FETCH_INSURANCE_COMPANY_INCORPORATION_TYPE,
  PUT_COMPANY_DIGITAL_FORM_CONSENT_TEXT,
  PUT_INSURANCE_COMPANY_INCORPORATION_TYPE
} from '@/store/types';
import notification from '@/utils/notifications';
import { useRoute } from 'vue-router';
import { DownOutlined, UpOutlined, PlusOutlined } from '@ant-design/icons-vue';
import TypeForms from './TypeForms.vue';

const incorporation_modal_ref = ref();
const form_type = ref(null);
const store = useStore();
const route = useRoute();
const insurance_id = route.params.id;
const form_state = reactive({
  consents: []
});
const editting = ref(false);
const form_ref = ref();
const loading = ref(false);
const loading_data = ref(false);

const toggleEdit = () => (editting.value = !editting.value);

const form_type_text = computed(() => {
  return form_type.value === null
    ? 'Sin configurar'
    : form_type.value === 1
      ? 'Formulario tipo'
      : 'Formulario digital';
});

const openIncorporationModal = () => {
  incorporation_modal_ref.value.openModal();
};

const selectedFormType = (type) => {
  form_type.value = type;
  store
    .dispatch(PUT_INSURANCE_COMPANY_INCORPORATION_TYPE, {
      id: insurance_id,
      payload: {
        type: type === 2 ? 'DIGITAL_FORM' : 'PDF_FORM'
      }
    })
    .then((resp) => {
      console.log(resp);
    })
    .catch((error) => {
      notification('error', 'Error', error.response.data.message);
    });
};

const getConsentTexts = () => {
  store
    .dispatch(FETCH_COMPANY_DIGITAL_FORM_CONSENT_TEXT, insurance_id)
    .then((resp) => {
      form_state.consents = resp.paragraphs;
    })
    .catch((error) => {
      notification('error', 'Error', error.response.data.message);
    });
};

const addParagraph = () => {
  form_state.consents.push({
    title: null,
    content: null
  });
};

const moveParagraphUp = (index) => {
  const elemento = form_state.consents[index];
  form_state.consents.splice(index, 1);
  form_state.consents.splice(index - 1, 0, elemento);
};

const moveParagraphDown = (index) => {
  const elemento = form_state.consents[index];
  form_state.consents.splice(index, 1);
  form_state.consents.splice(index + 1, 0, elemento);
};

const deleteParagraph = (index) => {
  form_state.consents.splice(index, 1);
};

const formSubmit = () => {
  form_ref.value
    .validate()
    .then(() => {
      saveConsentTexts();
    })
    .catch((error) => {
      console.error(error);
    });
};

const saveConsentTexts = () => {
  loading.value = true;

  const payload = {
    paragraphs: form_state.consents.map((consent) => ({
      title: consent.title,
      content: consent.content
    }))
  };

  store
    .dispatch(PUT_COMPANY_DIGITAL_FORM_CONSENT_TEXT, {
      id: insurance_id,
      payload
    })
    .then(() => {
      notification('success', 'Correcto', 'Guardado correctamente');
    })
    .catch((error) => {
      console.error(error);
      notification('error', 'Error', error.response.data.message);
    })
    .finally(() => {
      editting.value = false;
      loading.value = false;
    });
};

watch(
  () => form_type.value,
  (new_val) => {
    if (new_val === 2) {
      getConsentTexts();
    }
  }
);

const getFormType = () => {
  loading_data.value = true;
  store
    .dispatch(FETCH_INSURANCE_COMPANY_INCORPORATION_TYPE, insurance_id)
    .then((resp) => {
      const { type } = resp;
      form_type.value = type === 'DIGITAL_FORM' ? 2 : 1;
    })
    .finally(() => {
      loading_data.value = false;
    });
};

onMounted(() => {
  getFormType();
});
</script>
