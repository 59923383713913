<template>
  <div>
    <a-modal
      v-model:visible="modal_props.visibility"
      title="Validación cobranza"
      @cancel="closeModal"
    >
      <a-typography-text class="fs-xl text-gray-8">
        La plataforma validará los últimos movimientos del mes registrados para
        el periodo {{ dataInfo.period }} con los moviemientos adjuntos en la
        nómina de cobranza.
      </a-typography-text>

      <a-row class="mt-3 mb-4">
        <a-col :span="3">
          <exclamation-circle-outlined
            :style="{ fontSize: '40px', color: '#FFBF01' }"
          />
        </a-col>
        <a-col :span="20">
          <a-typography-text class="text-gray-8">
            No se esta considerando ningún movimiento registrado antes o después
            de dicho periodo
          </a-typography-text>
        </a-col>
      </a-row>

      <template #footer>
        <a-button :loading="modal_props.loading" @click="closeModal"
          >Volver atrás</a-button
        >
        <a-button :loading="modal_props.loading" type="primary" @click="onOk"
          >Validar</a-button
        >
      </template>
    </a-modal>
    <a-modal
      v-model:visible="show_sucess"
      centered
      footer=""
      :maskClosable="false"
    >
      <a-result
        centered
        status="success"
        title="Cobranza Validada"
        sub-title="La cobranza ah sido validada con éxito."
      >
        <!-- <template #extra>
          <a-button type="link"
          >
            <a-button key="console" type="primary">cerrar</a-button>
          </a-button>
        </template> -->
      </a-result>
    </a-modal>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  POST_COLLECTION_VALDATE_PAYROLL_FORM,
  FETCH_COLLECTION_DATA_SEND_RRHH,
  FETCH_COLLECTION_GROUP_COLLECTIONS
  // FETCH_COLLECTION_GROUP_COLLECTIONS
} from '../../../store/types';
import EventBus from '../../../utils/events';
import notification from '@/utils/notifications';

export default {
  name: 'CollectionStartModal',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    idModal: {
      type: Number,
      default: 1
    },
    dataInfo: {
      type: Object,
      default: {}
    }
  },
  components: {
    'exclamation-circle-outlined': ExclamationCircleOutlined
  },
  setup(props, { emit }) {
    // * Props
    const insurance_data = computed(() => props.value);
    const show_sucess = ref(false);
    const store = useStore();
    const route = useRoute();

    // * Modal
    const modal_props = reactive({
      visibility: false,
      title: 'Resumen formulario inicio de cobranza'
    });

    const closeModal = () => {
      modal_props.visibility = false;
      emitResponse();
    };

    const emitResponse = () => {
      emit('update:modelValue', false);
    };

    // * Funciones
    const setTimeIntervalValid = 20000;
    const onOk = async () => {
      modal_props.loading = true;
      try {
        const resp = await store.dispatch(
          POST_COLLECTION_VALDATE_PAYROLL_FORM,
          route.params.id
        );
        if (resp.status == 200) {
          notification(
            'success',
            'Completado',
            'Se han validado correctamente el listado de cobranza'
          );
          modal_props.visibility = false;
          modal_props.loading = false;
          await store.dispatch(
            FETCH_COLLECTION_GROUP_COLLECTIONS,
            route.params.id
          );
          EventBus.emit('UpdateData');
          await store.dispatch(
            FETCH_COLLECTION_DATA_SEND_RRHH,
            route.params.id
          );
        }
      } catch (error) {
        modal_props.visibility = false;
        // show_sucess.value=false;
        modal_props.loading = false;
        notification('error', 'Error', error.response.data.message);
        console.log('error', error);
      }

      // modal_props.visibility=false;
      // emit('onOk',{keyModal:2});
    };

    watch(
      () => props.modelValue,
      (newVal) => {
        modal_props.visibility = newVal;
      }
    );

    return {
      closeModal,
      modal_props,
      insurance_data,
      onOk,
      show_sucess,
      setTimeIntervalValid
    };
  }
};
</script>
