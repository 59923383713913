<template>
  <a-card class="mt-2">
    <a-row type="flex" justify="space-between" align="middle">
      <a-typography-title :level="4" class="text-gray-8 mb-0">
        {{ title }}
      </a-typography-title>
    </a-row>
    <a-row class="mt-3">
      <a-col :span="24">
        <a-table :data-source="data" :columns="columns" :scroll="{ x: 1000 }" :loading="loadingTicketMasivo">
          <template #tag="{ text: tag }">
            <tags :stateId="tag" />
          </template>

          <template #action="{ text }">
            <a-button type="link" @click="redirectType(text)">Ver</a-button>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { GET_USER_ROLE } from '../../store/types';
import getTagColor from '../../utils/getTagColor';
import Tags from '../Tags.vue';

export default {
  name: 'ListaTicketsMasivos',
  components: {
    tags: Tags
  },
  props: {
    data: {
      type: Array
    },
    title: '',
    defaultRedirect: '',
    loadingTicketMasivo: false
  },
  setup() {
    const store = useStore();
    // rol de  usuario
    const user_role = store.getters[GET_USER_ROLE];

    const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'customRender'
        },
        onFilter: (value, record) =>
          record.id.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Grupo',
        dataIndex: 'group',
        key: 'group',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'customRender'
        },
        onFilter: (value, record) =>
          record.group.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Nombre',
        dataIndex: 'employeeName',
        key: 'employeeName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'customRender'
        },
        onFilter: (value, record) =>
          record.employeeName
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'RUT',
        dataIndex: 'employeeRut',
        key: 'employeeRut',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'customRender'
        },
        onFilter: (value, record) =>
          record.employeeRut
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Estado',
        dataIndex: 'ticketState',
        key: 'ticketState',
        slots: {
          customRender: 'tag'
        }
      },
      {
        title: 'Casos pendientes',
        dataIndex: 'pendingCases',
        key: 'pendingCases'
      }
    ];

    if (user_role != 'rrhh') {
      columns.push({
        title: 'Detalle',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'action'
        }
      });
    }

    const state = reactive({
      searchText: '',
      searchedColumn: ''
    });
    const searchInput = ref();

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
    };

    const handleReset = (clearFilters) => {
      clearFilters();
      state.searchText = '';
    };
    const router = useRouter();
    const name = router.currentRoute.value.name.toLowerCase();
    const redirectType = (c) => {
      switch (name) {
        case 'bajamasivadetail':
          router.push({ name: 'BajaDetail', params: { id: c } });
          break;
        case 'altamasivadetail':
          router.push({ name: 'AltasDetalle', params: { id: c } });
          break;
      }
    };
    return {
      ...toRefs(state),
      redirectType,
      columns,
      searchInput,
      handleSearch,
      handleReset,
      getTagColor
    };
  }
};
</script>
