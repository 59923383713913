<template>
  <a-layout>
    <a-layout-content>
      <a-row type="flex" justify="center">
        <div style="height: 32px;" />
      </a-row>

      <a-row type="flex" justify="center">
        <a-col>
          <img src="@/assets/images/new-identity/carga-welcome.svg" alt="" width="145" class="pb-1" />
        </a-col>
      </a-row>

      <a-row type="flex" justify="center">
        <a-col class="text-center" :xs="20" :sm="16" :md="12" :lg="8">
          <a-typography-paragraph strong class="text-gray-8 mb-2">
            Solicitud por falta de antecedentes
          </a-typography-paragraph>
        </a-col>
      </a-row>

      <a-row type="flex" justify="center">
        <a-col class="text-center" :xs="20" :sm="16" :md="12" :lg="8">
          <a-typography-title class="ff-urbanist mb-1" :level="1">{{
            data.companyName
            }}</a-typography-title>
          <a-typography-title class="text-blue-10 ff-urbanist" :level="3">¡Hola {{ data.name }}!</a-typography-title>
        </a-col>
      </a-row>

      <br />
      <div v-if="error">
        <a-col :offset="6" :span="11">
          <a-typography-title class="text-gray-8" :level="3">
            Link invalido
          </a-typography-title>
        </a-col>
      </div>
      <div v-else>
        <a-row v-if="last_date !== 'Cancelado' && last_date !== 'Finalizado'" class="" type="flex" justify="center">
          <a-col :xs="20" :sm="16" :md="12" :lg="10">
            <!--         <a-upload name="avatar" list-type="text" class="avatar-uploader w-100" :file-list="fileList"
                    :remove="handleRemove" :before-upload="beforeUpload">
                    <div>
                      <img src="@/assets/images/icons/drag-drop-icon.svg" alt="">
                      <div class="ant-upload-text text-gray-9 my-2">
                        Clickea aquí o arrastra el archivo
                      </div>
                      <div class="ant-upload-text text-gray-7 my-2">
                        JPG, PNG y PDF, peso máximo 5mb
                      </div>
                    </div>
                  </a-upload> -->

            <a-upload-dragger :file-list="fileList" name="avatar" :multiple="true" :remove="handleRemove"
              :before-upload="beforeUpload">
              <p class="ant-upload-drag-icon mb-3">
                <inbox-outlined></inbox-outlined>
              </p>
              <p class="ant-upload-text text-gray-9 mb-1">
                Clickea aquí o arrastra el archivo
              </p>
              <p class="ant-upload-hint text-gray-7">
                JPG, PNG y PDF, peso máximo 5mb
              </p>
            </a-upload-dragger>
          </a-col>
        </a-row>

        <br />
        <br />

        <a-row type="flex" justify="center" v-if="last_date !== 'Cancelado' && last_date !== 'Finalizado'">
          <a-col :xs="20" :sm="16" :md="12" :lg="10">
            <a-row type="flex" :gutter="8">
              <a-col>
                <exclamation-circle-outlined class="danger-icon" />
              </a-col>
              <a-col :span="20">
                <a-typography-text class="text-gray-9 small">
                  <a-typography-text strong>Importante:</a-typography-text>
                  Recuerda que puedes volver aquí desde el link en el correo
                  electrónico que te enviamos.
                </a-typography-text>
              </a-col>
            </a-row>
            <br />
            <br />
          </a-col>
        </a-row>

        <a-row type="flex" justify="center">
          <a-col class="text-center">
            <a-button v-if="last_date !== 'Cancelado' && last_date !== 'Finalizado'" type="primary" size="large" block
              :disabled="fileList.length === 0" :loading="uploading" @click="handleUpload">
              {{ uploading ? 'Sube tus documentos' : 'Enviar documentos' }}
            </a-button>
            <a-button v-else size="large" :disabled="true">
              El Estado de Antecedentes se encuentra
              {{ last_date.toUpperCase() }}
            </a-button>
          </a-col>
        </a-row>

        <br /><br />
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import {
  ExclamationCircleOutlined,
  InboxOutlined
} from '@ant-design/icons-vue';
import { reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  FETCH_VERIFY_CASE_ANTECEDENTES,
  FETCH_CASE_ID_DATA,
  POST_CASE_FILE_UPLOAD
} from '@/store/types';

export default {
  name: 'CargaAntecedentesInicio',
  components: {
    'exclamation-circle-outlined': ExclamationCircleOutlined,
    InboxOutlined
  },
  setup() {
    // * Obtengo el id y el token desde la ruta
    const route = useRoute();
    const { case_id, token } = route.params;
    const store = useStore();

    // * En caso de que no tenga ninguna de las rutas doy un mensaje de error
    if (case_id === undefined || token === undefined) {
      alert('Invalido');
    }

    // * Valido de que el token que tiene el link este valido, en caso contrario mando un mensaje de error

    // Para abrir un mensaje de error de link invalido
    const error = ref(false);

    // Objeto que muestra data en la pantalla
    const data = reactive({
      name: '',
      message: '',
      companyName: ''
    });

    // Veo si el link es valido
    store
      .dispatch(FETCH_VERIFY_CASE_ANTECEDENTES, { id: case_id, payload: token })
      .then((resp) => {
        console.log(resp.data);
        data.name = resp.employee_name;
        data.message = resp.message;
      })
      .catch((err) => {
        error.value = true;
        console.log(err);
      });

    // * Obtengo el ultimo estado del ticket para mostrar si ya esta cancelado o ejecutado, mostrando un mensaje al final

    // ultima fecha
    const last_date = ref('');

    store
      .dispatch(FETCH_CASE_ID_DATA, case_id)
      .then((resp) => {
        const sorted_items = resp.data.states.sort(function (a, b) {
          return a.id - b.id;
        });

        last_date.value = sorted_items[sorted_items.length - 1].state.tag;

        data.companyName = resp.data.ticket.company.businessName;
      })
      .catch((err) => {
        console.log(err);
      });

    // * Logica para los archivos
    // Lista de archivos
    const fileList = ref([]);

    // Eliminar los archivos de la lista
    const handleRemove = (file) => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
    };

    // Validacion cuando se suben los archivos
    const beforeUpload = (file) => {
      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 5) {
        alert('archivo pesado');
      } else {
        fileList.value = [...fileList.value, file];
      }

      return false;
    };

    // * Subida de archivos
    const uploading = ref(false); // Mostrar el boton cargando
    const router = useRouter();

    // Subir los archivos a la base de datos
    const handleUpload = async () => {
      // Dejo el boton cargando
      uploading.value = true;

      // Envio la data como form adata
      const formData = new FormData();

      // Paso por cada uno de los archivos adjuntos y los agrego al formData
      fileList.value.forEach((file) => {
        formData.append('file', file);
      });

      try {
        const resp = await store.dispatch(POST_CASE_FILE_UPLOAD, {
          id: case_id,
          payload: formData
        });

        if (resp.status === 200) {
          setTimeout(function () {
            uploading.value = false;
            router.push({
              name: 'CargaAntecedentesListo',
              params: { caseId: case_id }
            });
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    };

    return {
      error, // Mostrar 'link invalido'
      data, // Muestra el nombre y el mensaje por pantalla
      last_date, // Fecha para mostar
      fileList, // Lista de archivos
      handleRemove, // Eliominar los archivos de la lista
      beforeUpload, // subir los archivos
      uploading, // Mostrar cargando el boton
      handleUpload, // Subir archivos
      ExclamationCircleOutlined
    };
  }
};
</script>

<style>
.ant-upload.ant-upload-select.ant-upload-select-text {
  width: 100% !important;
  text-align: center !important;
  /* border: 1px solid #bdd7dd !important;
  border-style: dashed !important; */
  background-color: #f8f9fb !important;
  cursor: pointer !important;
}
</style>
