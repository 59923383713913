<template>
  <div style="margin-top: 65px">
    <a-layout-header
      :style="{
        background: '#FDFEFF',
        display: 'flex',
        width: '100%',
        position: 'fixed',
        top: 0,
        zIndex: 1
      }"
    >
      <a-row
        :gutter="[16, 16]"
        :style="{
          display: 'flex',
          position: 'fixed',
          top: 0,
          left: '0',
          right: 0,
          width: '100%',
          transition: '0.6s'
        }"
        type="flex"
        align="middle"
        justify="end"
      >
        <a-col class="gutter-row avatar-pointer">
          <a-button type="link" @click="search_visible = true">
            <template #icon>
              <SearchOutlined />
            </template>
          </a-button>
        </a-col>
        <a-col class="gutter-row avatar-pointer">
          <a-popover
            v-model:visible="visible"
            placement="bottomRight"
            trigger="click"
            style="position: fixed; z-index: 1"
          >
            <template #content>
              <p>
                <b>{{ user_name }}</b>
              </p>
              <p @click="showModal">Información de contacto</p>
              <p v-if="tagVersion">Version: {{ tagVersion }}</p>
              <a @click="closeSesion">Cerrar sesión</a>
            </template>
            <a-avatar
              class="mr-5"
              :size="24"
              style="background-color: #2521e5; font-size: 12px"
            >
              {{ user_name_initial }}
            </a-avatar>
          </a-popover>
        </a-col>
      </a-row>
    </a-layout-header>

    <searcher v-model:visible="search_visible" />
  </div>
  <a-modal
    v-model:visible="visibleModal"
    title="Información de contacto"
    centered
    footer=""
  >
    <div>
      <a-typography-title class="text-gray-8" :level="5">
        Claudio Cáceres Osorio
      </a-typography-title>
      <a-typography class="text-gray-8 fw-regular" :level="5">
        Responsable comercial
      </a-typography>
      <a-descriptions class="mt-2" bordered>
        <a-descriptions-item label="Teléfono" :span="3">
          +569 9242 2514
        </a-descriptions-item>
        <a-descriptions-item label="Correo" :span="3">
          <a href="mailto:ccaceres@somosbewell.cl"> ccaceres@somosbewell.cl </a>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <div class="mt-4">
      <a-typography-title class="text-gray-8" :level="5">
        María José Aros
      </a-typography-title>
      <a-typography class="text-gray-8 fw-regular" :level="5">
        Ejecutiva de servicio
      </a-typography>
      <a-descriptions class="mt-2" bordered>
        <a-descriptions-item label="Teléfono" :span="3">
          +569 8508 1125
        </a-descriptions-item>
        <a-descriptions-item label="Correo" :span="3">
          <a href="mjaros@somosbewell.cl"> mjaros@somosbewell.cl </a>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <div class="mt-4">
      <a-typography-title class="text-gray-8" :level="5">
        Cobranza y facturación
      </a-typography-title>
      <a-descriptions class="mt-2" bordered>
        <a-descriptions-item label="Correo" :span="3">
          <a href="cobranzas@b-well.cl"> cobranzas@b-well.cl </a>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <div class="mt-4">
      <a-typography-title class="text-gray-8" :level="5">
        Siniestros
      </a-typography-title>
      <a-descriptions class="mt-2" bordered>
        <a-descriptions-item label="Correo" :span="3">
          <a href="gastosmedicos@b-well.cl"> gastosmedicos@b-well.cl </a>
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </a-modal>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import Searcher from '../../components/Searcher.vue';
import config from '../../config';
import { LOG_OUT, GET_USER_NAME } from '../../store/types';
import { SearchOutlined } from '@ant-design/icons-vue';

export default {
  name: 'Navbar',
  components: {
    searcher: Searcher,
    SearchOutlined
  },
  setup() {
    const tagVersion = config().gitTag;
    // * Vue router
    const router = useRouter();

    // * Vue Store
    const store = useStore();
    // Nombre del usuario
    const user_name = store.getters[GET_USER_NAME];
    const user_name_initial = store.getters[GET_USER_NAME].substring(
      0,
      1
    ).toUpperCase();

    // * Buscador
    const search_visible = ref(false);

    // * Icono Persona
    // Para que se abra o se cierre el submenu
    const visible = ref(false);

    // Cerrar sesion
    const closeSesion = async () => {
      try {
        const resp_logout = await store.dispatch(LOG_OUT);
        if (resp_logout.status === 200) {
          router.push({ name: 'Login' });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const visibleModal = ref(false);
    const showModal = () => {
      visible.value = false;
      visibleModal.value = true;
    };

    return {
      visible,
      closeSesion,
      user_name,
      user_name_initial,
      showModal,
      visibleModal,
      search_visible,
      tagVersion
    };
  }
};
</script>

<style scoped>
.avatar-pointer {
  cursor: pointer;
}
</style>
