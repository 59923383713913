<template>
  <div class="centered">
    <img src="@/assets/images/token-expired.svg" class="img-margin-ready centered-image" />
    <a-typography-title style="bottom: 0px; font-family: 'Urbanist'; text-align: center">
      Token inválido
    </a-typography-title>
  </div>
</template>

<style scoped>
.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centered-image {
  height: 400px;
}
</style>
