<template>
  <div>
    <a-modal
      v-model:visible="modal_props.visibility"
      title="Cancelar formulario de cobranza"
      @cancel="closeModal"
      :closable="!modal_props.loading"
      :maskClosable="!modal_props.loading"
    >
      <a-typography-title :level="4" class="text-black"
        >¿Estás seguro que deseas cancelar el formulario de
        cobranza?</a-typography-title
      >

      <a-row class="mt-3 mb-4">
        <a-col :span="3">
          <exclamation-circle-outlined
            :style="{ fontSize: '40px', color: '#FFBF01' }"
          />
        </a-col>
        <a-col :span="20">
          <a-typography-text class="text-gray-8">
            Tienes el formulario con la cobranza cargada y los tickets de
            cobranza creados, toda la información procesada hasta el momento se
            perderá.
          </a-typography-text>
        </a-col>
      </a-row>

      <template #footer>
        <a-button @click="closeModal" :disabled="modal_props.loading"
          >Volver atrás</a-button
        >
        <a-button
          danger
          type="primary"
          @click="onOk"
          :loading="modal_props.loading"
          >Sí, cancelar</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, reactive } from 'vue';
import { watch } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { PATCH_COLLECTION_FORM_STATE } from '@/store/types';
import notification from '@/utils/notifications';

export default {
  name: 'CollectionStartModal',
  props: {
    modelValue: Boolean,
    collectionFormId: {
      type: [String, Number],
      default: 0
    }
  },
  components: {
    'exclamation-circle-outlined': ExclamationCircleOutlined
  },
  setup(props, { emit }) {
    // * Vuex
    const store = useStore();

    // * Props
    const col_form_id = computed(() => props.collectionFormId);

    // * Modal
    const modal_props = reactive({
      visibility: false,
      title: 'Resumen formulario inicio de cobranza',
      loading: false
    });

    watch(
      () => props.modelValue,
      (newVal) => {
        modal_props.visibility = newVal;
      }
    );

    const closeModal = () => {
      modal_props.visibility = false;
      emitResponse();
    };

    const emitResponse = () => {
      emit('update:modelValue', false);
    };

    // * Funciones
    const onOk = async (e) => {
      modal_props.loading = true;

      try {
        await store.dispatch(PATCH_COLLECTION_FORM_STATE, {
          id: col_form_id.value,
          payload: {
            state: 'CANCELED'
          }
        });

        notification('success', 'Correcto', 'Formulario de cobranza cancelado');
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }

      modal_props.loading = false;
      closeModal();
      emit('onOk', e);
    };

    return {
      closeModal,
      modal_props,
      onOk
    };
  }
};
</script>
