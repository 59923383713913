export const FETCH_REQUESTS_BY_COLLECTION_ID =
  'requests/FETCH_REQUESTS_BY_COLLECTION_ID';
export const FETCH_REQUESTS_BY_ID = 'requests/FETCH_REQUESTS_BY_ID';
export const FETCH_REQUESTS_ID_COLLECTION =
  'requests/FETCH_REQUESTS_ID_COLLECTION';
export const FETCH_REQUESTS_COMMENTS = 'requests/FETCH_REQUESTS_COMMENTS';
export const FETCH_REQUESTS_TABLE_DATA_BY_TYPE =
  'requests/FETCH_REQUESTS_TABLE_DATA_BY_TYPE';

export const GET_REQUEST_BY_ID = 'requests/GET_REQUEST_BY_ID';
export const GET_REQUESTS_BY_COLLECTION_ID =
  'requests/GET_REQUESTS_BY_COLLECTION_ID';
export const GET_REQUESTS_ID_COLLECTION = 'requests/GET_REQUESTS_ID_COLLECTION';
export const GET_REQUESTS_COMMENTS = 'requests/GET_REQUESTS_COMMENTS';
export const GET_INVOICES = 'requests/GET_INVOICES';

export const MUTATE_REQUESTS_BY_COLLECTION_ID =
  'requests/MUTATE_REQUESTS_BY_COLLECTION_ID';
export const MUTATE_REQUESTS_BY_ID = 'requests/MUTATE_REQUESTS_BY_ID';
export const MUTATE_REQUESTS_ID_COLLECTION =
  'requests/MUTATE_REQUESTS_ID_COLLECTION';
export const MUTATE_REQUESTS_COMMENTS = 'requests/MUTATE_REQUESTS_COMMENTS';

export const POST_REQUESTS = 'requests/POST_REQUESTS';
export const POST_REQUESTS_COMMENTS = 'requests/POST_REQUESTS_COMMENTS';
export const POST_CHANGE_REQUEST_STATE = 'requests/POST_CHANGE_REQUEST_STATE';

// teeest api files
export const GET_FILES_INVOICES = 'requests/GET_FILES_INVOICES';
export const GET_SELECT_FILTER = 'requests/GET_SELECT_FILTER';

export const MUTATE_FILES_INVOICES = 'requests/MUTATE_FILES_INVOICES';
export const SET_SELECT_INVOICES = 'requests/SET_SELECT_INVOICES';

export const FETCH_FILES_INVOICES = 'requests/FETCH_FILES_INVOICES';
export const SELECT_INVOICES_DATA = 'requests/SELECT_INVOICES_DATA';

export const POST_FILES_INVOICES = 'requests/POST_FILES_IMVOICES';
export const DELETE_FILE_INVOICE = 'requests/DELETE_FILE_INVOICE';
export const PATCH_FILE_INVOICE = 'requests/PATCH_FILE_INVOICE';

export const FETCH_REQUESTS_SUMMARY = 'requests/FETCH_REQUESTS_SUMMARY';
export const MUTATE_REQUESTS_SUMMARY = 'requests/MUTATE_REQUESTS_SUMMARY';
export const GET_REQUESTS_SUMMARY = 'requests/GET_REQUESTS_SUMMARY';
