import { ticketService } from '@/network';

import {
  SET_TICKET_DEPENDANTS_LOADING,
  FETCH_TICKET_DEPENDANTS,
  GET_TICKET_DEPENDANTS,
  SET_TICKET_DEPENDANTS,
  DELETE_TICKET_DEPENDANT
} from '@/store/types';

const store = {};
const getDefaultState = () => ({
  dependants: {
    data: [],
    loading: false
  }
});

store.state = getDefaultState();

store.getters = {
  [GET_TICKET_DEPENDANTS]: (state) => {
    return state.dependants;
  }
};

store.mutations = {
  [SET_TICKET_DEPENDANTS_LOADING](state, loading) {
    state.dependants.loading = loading;
  },
  [SET_TICKET_DEPENDANTS](state, dependants) {
    state.dependants.data = dependants;
  }
};

store.actions = {
  async [FETCH_TICKET_DEPENDANTS]({ commit }, id) {
    commit(SET_TICKET_DEPENDANTS_LOADING, true);
    commit(SET_TICKET_DEPENDANTS, []);
    try {
      const response = await ticketService.getDependants(id);
      commit(SET_TICKET_DEPENDANTS, response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching ticket dependants', error);
      console.error('Params:', id);
      throw error;
    } finally {
      commit(SET_TICKET_DEPENDANTS_LOADING, false);
    }
  },
  async [DELETE_TICKET_DEPENDANT](
    { commit, dispatch },
    { ticketId, employeeDependantId }
  ) {
    commit(SET_TICKET_DEPENDANTS_LOADING, true);
    try {
      const response = await ticketService.deleteEmployeeDependant(
        ticketId,
        employeeDependantId
      );
      return response.data;
    } catch (error) {
      console.error('Error deleting ticket dependant', error);
      console.error('Params:', ticketId, employeeDependantId);
      throw error;
    } finally {
      commit(SET_TICKET_DEPENDANTS_LOADING, false);
      dispatch(FETCH_TICKET_DEPENDANTS, ticketId);
    }
  }
};

export default store;
