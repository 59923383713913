<template>
  <div>
    <a-modal v-model:visible="visible" title="Validación cobranza" centered>
      <a-typography-text :level="5">
        La plataforma validará los ultimos movimientos del mes registrados para
        el {periodo 2022-08} con los movimientos adjuntos en la nomina de
        cobranza.
      </a-typography-text>
      <a-row class="mt-2 mb-4">
        <a-col :span="2">
          <exclamation-circle-outlined
            :style="{ fontSize: '28px', color: '#FFBF01' }"
          />
        </a-col>
        <a-col :span="20">
          <a-typography-text class="text-gray-8">
            No se esta considerando ningun movimiento registrado antes o después
            de dicho periodo.
          </a-typography-text>
        </a-col>
      </a-row>
      <template #footer>
        <a-button key="back" @click="closeModal">Volver atrás</a-button>
        <a-button key="submit" type="primary" @click="submitModal">
          Validar
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    'exclamation-circle-outlined': ExclamationCircleOutlined
  },
  setup() {
    const visible = ref(false);

    const closeModal = () => {
      visible.value = false;
    };

    const openModal = () => {
      visible.value = true;
    };

    const submitModal = () => {
      closeModal();
    };

    return {
      visible,
      closeModal,
      submitModal,
      openModal
    };
  }
};
</script>
