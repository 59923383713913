import {
  TOKEN_VALIDATION,
  SET_PROFILE,
  REFRESH_TOKEN
} from '../../store/types';
import store from '@/store';

// Este metodo es para validar si mis tokens estan correctos de la siguiente manera
// Hago un llamado hacia el backend preguntando si mis cookies que contienen los
// tokens estan validas y no expiradas.
// 1.- Si esta expirada el access token pero no el refresh, actualizo mis cookies y devuelvo un true
// 2.- Si esta expirada el access y el refresh no actualizo nada y devuevlo un false
// 3.- y si no tengo nada tambien devuelvo un false
// 4.- Si tengo ambos buenos retorno true y no actualizo nada, ademas creo el usuario vuex

export const validateToken = async () => {
  try {
    const resp = await store.dispatch(TOKEN_VALIDATION);

    if (resp.validation) {
      const user = {
        id: resp.resp.user.id,
        email: resp.resp.user.email,
        name: resp.resp.user.name,
        phone: resp.resp.user.phone,
        role: {
          tag: resp.resp.user.role.tag,
          id: resp.resp.user.role.id
        },
        exp_token_date: resp.resp.exp
      };
      await store.commit(SET_PROFILE, user);
      return true;
    }

    if (resp.resp === 'access expired') {
      const resp_refresh = await store.dispatch(REFRESH_TOKEN);
      const user_body = resp_refresh.data.body;

      const user = {
        id: user_body.id,
        email: user_body.email,
        name: user_body.name,
        phone: user_body.phone,
        role: {
          tag: user_body.role.tag,
          id: user_body.role.id
        },
        exp_token_date: user_body.exp_token_date
      };

      await store.commit(SET_PROFILE, user);
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
