<template>
  <a-row type="flex" justify="center" align="middle" style="min-height: 100vh">
    <a-col :sm="20" :md="14" :lg="10">
      <div style="margin-top: 16px">
        <a-steps direction="horizontal">
          <a-step v-for="(step, stepIndex) in steps" :key="stepIndex" :title="step.title" />
        </a-steps>
      </div>
      <a-typography-title :level="4" style="margin-top: 16px">Ingresa tus cargas</a-typography-title>
      <a-typography-paragraph>Ingresa los datos de cada una de tus cargas.</a-typography-paragraph>
      <a-form layout="vertical" ref="formRef" :model="state">
        <a-row v-if="state.dependentsLoading" type="flex" justify="center" align="middle">
          <a-spin />
        </a-row>
        <a-empty v-if="!state.dependentsLoading && state.dependents.length === 0"
          description="No has agregado tus cargas" />
        <template v-for="(dependent, dependentIndex) in state.dependents" :key="dependentIndex">
          <a-row type="flex" justify="space-between" align="middle">
            <a-col>
              <a-typography-title :level="5" style="margin: 0">Carga {{ dependentIndex + 1 }}</a-typography-title>
            </a-col>
            <a-col>
              <a-button danger size="small" @click="() => handleDeleteDependent(dependentIndex)">Eliminar</a-button>
            </a-col>
          </a-row>
          <a-row type="flex" :gutter="16" style="margin-top: 16px">
            <a-col :span="12">
              <a-form-item :name="['dependents', dependentIndex, 'rut']" label="Rut" :rules="[
                { validator: rutValidator },
                { required: true, message: 'El rut es obligatorio.' }
              ]">
                <a-input :value="dependent.rut" @change="(e) => (dependent.rut = rutHelper.formatRut(e.target.value))
                  " />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :name="['dependents', dependentIndex, 'dateOfBirth']" label="Fecha de nacimiento" :rules="{
                required: true,
                message: 'La fecha de nacimiento es obligatoria.'
              }">
                <a-date-picker style="width: 100%" format="DD/MM/YYYY" v-model:value="dependent.dateOfBirth" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" :gutter="16">
            <a-col :span="12">
              <a-form-item :name="['dependents', dependentIndex, 'firstName']" label="Primer nombre" :rules="{
                required: true,
                message: 'El primer nombre es obligatorio.'
              }">
                <a-input v-model:value="dependent.firstName" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :name="['dependents', dependentIndex, 'secondName']" label="Segundo nombre">
                <a-input v-model:value="dependent.secondName" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" :gutter="16">
            <a-col :span="12">
              <a-form-item :name="['dependents', dependentIndex, 'firstSurname']" label="Apellido paterno" :rules="{
                required: true,
                message: 'El primer apellido es obligatorio.'
              }">
                <a-input v-model:value="dependent.firstSurname" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :name="['dependents', dependentIndex, 'secondSurname']" label="Apellido materno">
                <a-input v-model:value="dependent.secondSurname" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :name="['dependents', dependentIndex, 'relationship']" label="Relación" :rules="{
                required: true,
                message: 'La relación es obligatoria.'
              }">
                <a-select v-model:value="dependent.relationship" :options="relationOptions"
                  placeholder="Ingrese relación">
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </template>
      </a-form>
      <div v-if="!state.dependentsLoading" style="display: flex; flex-direction: column">
        <a-button style="align-self: flex-end" @click="handleAddDependent">Agregar carga</a-button>
      </div>
      <a-row style="margin-top: 18px; margin-bottom: 18px" :gutter="16" type="flex" justify="space-between">
        <a-col :span="8">
          <a-button :loading="state.dependentsLoading || state.putLoading" block size="large">
            <router-link :to="{
              name: 'DependentsIncorporationForm',
              params: { token: route.params.token }
            }">
              Atrás
            </router-link>
          </a-button>
        </a-col>
        <a-col :span="8">
          <a-button :loading="state.dependentsLoading || state.putLoading" block type="primary" size="large"
            @click="submitForm">Siguiente</a-button>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { reactive, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import rutHelper from '@/utils/Ruts';
import {
  FETCH_DEPENDENTS_INCORPORATION_FORM,
  FETCH_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM,
  PUT_DEPENDENTS_OF_DEPENDENTS_INCORPORATION
} from '@/store/types';
import notification from '@/utils/notifications';

const route = useRoute();
const router = useRouter();
const store = useStore();

const steps = [
  {
    title: 'Ingresa tus cargas'
  },
  {
    title: 'Carga tus documentos'
  }
];

const formRef = ref();
const state = reactive({
  dependents: [],
  loading: true,
  dependentsLoading: true,
  putLoading: false
});

const handleAddDependent = () => {
  state.dependents.push({
    rut: '',
    firstName: '',
    secondName: '',
    firstSurname: '',
    secondSurname: '',
    dateOfBirth: null,
    relationship: null
  });
};

const handleDeleteDependent = (dependentIndex) => {
  state.dependents = state.dependents.filter(
    (_, idx) => idx !== dependentIndex
  );
};

const submitForm = async () => {
  const isValid = await formRef.value.validate();
  if (!isValid) return;
  if (state.dependents.length == 0) {
    notification('error', 'Error', 'Debe incorporar al menos una carga.');
    return;
  }
  try {
    state.putLoading = true;
    await store.dispatch(PUT_DEPENDENTS_OF_DEPENDENTS_INCORPORATION, {
      token: route.params.token,
      dependents: state.dependents.map((d) => ({
        rut: rutHelper.normalizeRut(d.rut),
        firstName: d.firstName,
        secondName: d.secondName,
        firstSurname: d.firstSurname,
        secondSurname: d.secondSurname,
        dateOfBirth: d.dateOfBirth,
        relationship: d.relationship
      }))
    });
    router.push({
      name: 'DependentsIncorporationFormStep2',
      params: { token: route.params.token }
    });
  } catch (error) {
    notification(
      'error',
      'Error',
      'Ha ocurrido un error al guardar las cargas'
    );
  } finally {
    state.putLoading = false;
  }
};

const rutValidator = (_, value) => {
  const isValid = rutHelper.validateRut(value);
  if (value && !isValid) return Promise.reject('RUT inválido.');

  if (state.dependents.filter((d) => d.rut === value).length > 1)
    return Promise.reject('RUT repetido.');

  return Promise.resolve();
};

const relationOptions = [
  {
    label: 'Cónyuge',
    value: 'SPOUSE'
  },
  {
    label: 'Conviviente',
    value: 'COHABITANT'
  },
  {
    label: 'Hijo(a)',
    value: 'CHILD'
  },
  {
    label: 'Padre/Madre',
    value: 'PARENT'
  },
  {
    label: 'Nieto(a)',
    value: 'GRANDCHILD'
  },
  {
    label: 'Hermano(a)',
    value: 'SIBLING'
  }
];

onMounted(async () => {
  try {
    const form = await store.dispatch(
      FETCH_DEPENDENTS_INCORPORATION_FORM,
      route.params.token
    );
    if (form.state !== 'Creado') {
      router.push({ name: 'DependentsIncorporationFormError' });
    } else if (form.isTokenExpired) {
      router.push({ name: 'DependentsIncorporationFormExpired' });
    }
    state.loading = false;
  } catch (error) {
    router.push({ name: 'DependentsIncorporationFormError' });
  }

  try {
    state.dependentsLoading = true;
    const dependents = await store.dispatch(
      FETCH_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM,
      route.params.token
    );
    state.dependents = dependents.map((d) => ({
      rut: rutHelper.formatRut(d.rut),
      firstName: d.firstName,
      secondName: d.secondName,
      firstSurname: d.firstSurname,
      secondSurname: d.secondSurname,
      dateOfBirth: d.dateOfBirth ? moment(d.dateOfBirth) : null,
      relationship: d.relationship
    }));
  } catch (error) {
    notification(
      'error',
      'Error',
      'Ha ocurrido un error al obtener las cargas'
    );
  } finally {
    state.dependentsLoading = false;
  }
});
</script>
