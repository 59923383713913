<template>
  <a-modal v-model:visible="visibility" title="Historial resolicitudes">
    <a-typography>
      Revisa aquí el historial de tus resolicitudes para el formulario de
      incorporación
    </a-typography>

    <a-skeleton active :loading="loading">
      <a-collapse
        v-model:activeKey="activeKey"
        class="mt-3"
        v-if="history_data.length > 0"
      >
        <a-collapse-panel
          v-for="(k, index) in history_data"
          :key="k.id"
          :header="`#${index + 1}° Resolicitud (${dateFormatter(k.createdAt)})`"
        >
          <a-button @click="() => redirectToFile(k.url)">
            Ver documento
          </a-button>
          <div class="mt-3">
            <a-typography-paragraph v-if="k.requestedMessage != null">
              {{ k.requestedMessage }}
            </a-typography-paragraph>
            <a-typography-paragraph v-else>
              Sin mensaje
            </a-typography-paragraph>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <div v-else>
        <a-empty />
      </div>
    </a-skeleton>
    <template #footer>
      <a-button @click="closeModal">Cancelar</a-button>
      <a-button type="primary" @click="closeModal">Ok</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, defineExpose, computed } from 'vue';
import { useStore } from 'vuex';
import { FETCH_FILES_HISTORY, GET_FILES_HISTORY } from '../store/types';
import dateFormatter from '../utils/dateFormatter';
import notification from '@/utils/notifications';

const visibility = ref(false);
const activeKey = ref(0);
const store = useStore();
const loading = ref(false);

const openModal = (file_id) => {
  visibility.value = true;
  getHistoryData(file_id);
};

const closeModal = () => {
  visibility.value = false;
};

const history_data = computed(() => {
  return store.getters[GET_FILES_HISTORY]?.sort((a, b) => a.id - b.id).filter(
    (obj) => obj.requestedMessage != null
  );
});

const getHistoryData = (id) => {
  loading.value = true;
  store
    .dispatch(FETCH_FILES_HISTORY, id)
    .then((resp) => {
      console.log('getHistoryData', resp);
    })
    .catch((err) => {
      notification('error', 'Error', err.response.data.message);
    })
    .finally(() => {
      loading.value = false;
    });
};

const redirectToFile = (url) => {
  window.open(url, '_blank');
};

defineExpose({ openModal });
</script>
