// * SET
export const SET_PROFILE = 'user/SET_PROFILE';
export const UNSET_PROFILE = 'user/UNSET_PROFILE';
export const SET_PROFILE_BY_ID = 'user/SET_PROFILE_BY_ID';

// * FETCH
export const FETCH_AUTH = 'user/FETCH_AUTH';

// * GET
export const GET_BROKER_ID = 'user/GET_BROKER_ID';
export const GET_LOGGED_USER = 'user/GET_LOGGED_USER';
export const GET_USER_TOKEN_EXP = 'user/GET_USER_TOKEN_EXP';
export const GET_USER_ROLE = 'user/GET_USER_ROLE';
export const GET_USER_NAME = 'user/GET_USER_NAME';
export const GET_PROFILE_BY_ID = 'user/GET_PROFILE_BY_ID';

// * OTROS
export const LOG_OUT = 'user/LOG_OUT';
export const LOG_IN = 'user/LOG_IN';
export const TOKEN_VALIDATION = 'user/TOKEN_VALIDATION';
export const REFRESH_TOKEN = 'user/REFRESH_TOKEN';
export const RESET_PASS = 'user/RESET_PASS';
export const FETCH_USER_BY_ID = 'user/FETCH_USER_BY_ID';
