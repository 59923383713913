import { createApp } from 'vue';
import axios from 'axios';
import Antd from 'ant-design-vue';
import VueApexCharts from 'vue3-apexcharts';
import dotenv from 'dotenv';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import VueHotjar from 'vue-hotjar-next';
import App from './App.vue';
import router from './router';
import store from './store';
import './less/app.less';
import config from './config';

const configs = config();

const app = createApp(App);

Sentry.init({
  app,
  dsn: configs.dsn,
  environment: configs.appEnv,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: configs.baseURL
    })
  ],
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  // or specify specific names
  trackComponents: true,
  hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
  // or use default hooks ['activate', 'mount', 'update']
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});
dotenv.config();

router.beforeEach((to, from, next) => {
  const { name } = to;
  if (
    name == 'insuredform' ||
    name == 'InsuredForm1' ||
    name == 'InsuredForm2' ||
    name == 'InsuredForm3' ||
    name == 'Auth' ||
    name == 'Resume' ||
    name == 'ready' ||
    name == 'CargaDeAntecedentes' ||
    name == 'ResolicitudDocumento'
  ) {
    app.use(VueHotjar, {
      id: configs.hotjarId,
      isProduction: true,
      snippetVersion: 6
    });
  }
  next();
});

// axios config
axios.defaults.withCredentials = true;
app.config.globalProperties.axios = axios;

app.use(Antd).use(VueApexCharts).use(store).use(router).mount('#app');
