<template>
  <a-row type="flex" justify="center" align="middle" style="min-height: 100vh">
    <a-spin v-if="state.loading" size="large" />
    <a-col v-if="!state.loading" :sm="20" :md="14" :lg="10">
      <div style="margin-top: 16px">
        <a-steps direction="horizontal" :current="1">
          <a-step
            v-for="(step, stepIndex) in steps"
            :key="stepIndex"
            :title="step.title"
          />
        </a-steps>
      </div>
      <a-typography-title :level="4" style="margin-top: 16px"
        >Carga tus documentos</a-typography-title
      >
      <a-typography-paragraph
        >Descarga los formularios, rellénalos y
        cárgalos.</a-typography-paragraph
      >
      <a-collapse v-if="state.form.coverages.some((c) => c.required)">
        <a-collapse-panel header="Coberturas financiadas por tu empleador">
          <a-row
            v-for="(coverage, coverageIndex) in state.form.coverages.filter(
              (c) => c.required
            )"
            :key="`req-${coverageIndex}`"
          >
            <a-col :span="12">
              <a-typography-text>{{ coverage.name }}</a-typography-text>
            </a-col>
            <a-col :span="12">
              <a-typography-text
                >Póliza #{{ coverage.policies.numberPolicy }}</a-typography-text
              >
            </a-col>
          </a-row>
        </a-collapse-panel>
      </a-collapse>
      <a-collapse
        style="margin-top: 16px"
        v-if="state.form.coverages.some((c) => !c.required)"
      >
        <a-collapse-panel header="Coberturas voluntarias">
          <a-row
            v-for="(coverage, coverageIndex) in state.form.coverages.filter(
              (c) => !c.required
            )"
            :key="`opt-${coverageIndex}`"
          >
            <a-col :span="12">
              <a-typography-text>{{ coverage.name }}</a-typography-text>
            </a-col>
            <a-col :span="12">
              <a-typography-text
                >Póliza #{{ coverage.policies.numberPolicy }}</a-typography-text
              >
            </a-col>
          </a-row>
        </a-collapse-panel>
      </a-collapse>
      <a-form layout="vertical" ref="formRef" :model="state">
        <div v-for="insurance in state.form.insurances" :key="insurance.id">
          <a-card
            :title="insurance.businessName"
            style="margin-top: 16px"
            v-for="file in insurance.files"
            :key="file.id"
          >
            <template #extra>
              <ExclamationCircleOutlined
                v-if="!state.fileLists[file.id]"
                style="color: red"
              />
              <CheckCircleOutlined
                v-if="state.fileLists[file.id]"
                style="color: green"
              />
            </template>
            <a-row justify="center">
              <a-typography-text>{{ file.name }}</a-typography-text>
            </a-row>
            <a-row justify="center" style="margin-top: 8px">
              <a-col :span="16">
                <a-upload
                  list-type="text"
                  :multiple="false"
                  :customRequest="
                    ({ file: uploadedFile }) => handleUpload(uploadedFile, file)
                  "
                  :file-list="state.fileLists[file.id]"
                  accept=".pdf, .doc, .docx, .xls, .xlsx"
                  :remove="(removedFile) => handleUploadRemove(file)"
                >
                  <a-button
                    :loading="state.fileLists[file.id]?.[0]?.loading"
                    type="primary"
                    block
                    >Subir documento</a-button
                  >
                </a-upload>
              </a-col>
            </a-row>
            <a-row justify="center" style="margin-top: 8px">
              <a-typography-text type="secondary"
                >Puedes descargar el documento a completar en el siguiente
                link</a-typography-text
              >
            </a-row>
            <a-row justify="center">
              <a-button type="link" size="small" :href="file.url"
                >Descargar documento</a-button
              >
            </a-row>
          </a-card>
        </div>
      </a-form>
      <a-row
        style="margin-top: 18px; margin-bottom: 18px"
        :gutter="16"
        type="flex"
        justify="space-between"
      >
        <a-col :span="8">
          <a-button
            :loading="
              Object.values(state.fileLists).some((fl) =>
                fl.some((fl) => fl.loading)
              )
            "
            block
            size="large"
          >
            <router-link
              :loading="
                state.loadingSendToBroker ||
                Object.values(state.fileLists).some((fl) =>
                  fl.some((fl) => fl.loading)
                )
              "
              :to="{
                name: 'DependentsIncorporationFormStep1',
                params: { token: route.params.token }
              }"
            >
              Atrás
            </router-link>
          </a-button>
        </a-col>
        <a-col :span="8">
          <a-button
            :loading="
              state.loadingSendToBroker ||
              Object.values(state.fileLists).some((fl) =>
                fl.some((fl) => fl.loading)
              )
            "
            block
            type="primary"
            size="large"
            @click="submitForm"
            >Finalizar</a-button
          >
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { reactive, ref, onMounted } from 'vue';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue';
import {
  FETCH_DEPENDENTS_INCORPORATION_FORM,
  POST_SEND_TO_BROKER_DEPENDENTS_INCORPORATION_FORM,
  PUT_DOCUMENT_OF_DEPENDENTS_INCORPORATION
} from '@/store/types';
import notification from '@/utils/notifications';

const route = useRoute();
const router = useRouter();
const store = useStore();

const steps = [
  {
    title: 'Ingresa tus cargas'
  },
  {
    title: 'Carga tus documentos'
  }
];

const formRef = ref();
const state = reactive({
  loading: true,
  form: {},
  fileLists: {},
  loadingSendToBroker: false
});

const submitForm = async () => {
  state.loadingSendToBroker = true;
  const isValid = await formRef.value.validate();
  if (!isValid) return;
  if (
    !state.form.insurances.every((i) =>
      i.files.every((f) => state.fileLists[f.id])
    )
  ) {
    notification('error', 'Error', 'Debes subir todos los documentos');
    state.loadingSendToBroker = false;
    return;
  }
  try {
    await store.dispatch(
      POST_SEND_TO_BROKER_DEPENDENTS_INCORPORATION_FORM,
      route.params.token
    );
    router.push({
      name: 'DependentsIncorporationFormSuccess',
      params: { token: route.params.token }
    });
  } catch (error) {
    notification(
      'error',
      'Error',
      'Ocurrió un error al finalizar el formulario de incorporación de cargas. Intentalo más tarde.'
    );
  }
  state.loadingSendToBroker = false;
};

const handleUpload = async (uploadedFile, insuranceFile) => {
  const currentFile = state.fileLists[insuranceFile.id];
  const fileSize = uploadedFile.size / 1024 / 1024;
  if (fileSize > 5) {
    state.fileLists[insuranceFile.id] = currentFile;
    notification(
      'error',
      'Error',
      'El archivo del documento excede el peso máximo 5MB.'
    );
    return;
  } else if (
    uploadedFile.type != 'image/png' &&
    uploadedFile.type != 'image/jpg' &&
    uploadedFile.type != 'application/pdf'
  ) {
    state.fileLists[insuranceFile.id] = currentFile;
    notification('error', 'Error', 'Sólo se permiten archivos pdf e imágenes.');
    return;
  }
  state.fileLists[insuranceFile.id] = currentFile
    ? currentFile.map((f) => ({ ...f, loading: true }))
    : [{ ...uploadedFile, name: uploadedFile.name, loading: true }];
  const formData = new FormData();
  formData.append('file', uploadedFile);
  formData.append('fileInsuranceCompanyId', insuranceFile.id);
  try {
    await store.dispatch(PUT_DOCUMENT_OF_DEPENDENTS_INCORPORATION, {
      token: route.params.token,
      payload: formData
    });
    state.fileLists[insuranceFile.id] = [uploadedFile];
    notification('success', 'Carga exitosa', 'Documento cargado con éxito');
  } catch (error) {
    state.fileLists[insuranceFile.id] = currentFile;
    if (error.response.status === 400 && error?.response?.data?.message) {
      notification('error', 'Error', error.response.data.message);
    } else {
      notification(
        'error',
        'Error',
        'Ocurrió un error al subir el documento. Intentalo más tarde.'
      );
    }
  }
};

const handleUploadRemove = (insuranceFile) => {
  delete state.fileLists[insuranceFile.id];
};

onMounted(async () => {
  try {
    const form = await store.dispatch(
      FETCH_DEPENDENTS_INCORPORATION_FORM,
      route.params.token
    );
    state.form = form;
    for (const insurance of form.insurances) {
      for (const file of insurance.files) {
        if (file.uploadedFile) {
          state.fileLists[file.id] = [
            {
              uid: String(file.uploadedFile.id),
              name: file.uploadedFile.name
            }
          ];
        }
      }
    }
    if (form.state !== 'Creado') {
      router.push({ name: 'DependentsIncorporationFormError' });
    } else if (form.isTokenExpired) {
      router.push({ name: 'DependentsIncorporationFormExpired' });
    }
    state.loading = false;
  } catch (error) {
    router.push({ name: 'DependentsIncorporationFormError' });
  }
});
</script>
