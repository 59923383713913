// * GETTERS
export const GET_COMPANY_MAILBOX = 'companies/GET_COMPANY_MAILBOX';

// * FETCH
export const FETCH_COMPANY_MAILBOX = 'companies/FETCH_COMPANY_MAILBOX';

// * MUTATE
export const MUTATE_COMPANY_MAILBOX = 'companies/MUTATE_COMPANY_MAILBOX';

// * POST
export const POST_COMPANY_MAILBOX = 'companies/POST_COMPANY_MAILBOX';

// * PUT
export const PUT_COMPANY_MAILBOX = 'companies/PUT_COMPANY_MAILBOX';
