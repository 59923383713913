<template>
  <a-row type="flex" justify="center" align="middle" style="min-height: 100vh">
    <a-col :sm="20" :md="14" :lg="10">
      <a-row type="flex" justify="center" align="middle">
        <img src="@/assets/images/token-expired.svg" style="height: 300px" />
      </a-row>
      <a-typography-title :level="2" style="bottom: 0px; font-family: 'Urbanist'; text-align: center">
        Lo sentimos, el formulario de incorporación al que estás intentando
        acceder es inválido
      </a-typography-title>
    </a-col>
  </a-row>
</template>
