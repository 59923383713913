<template>
  <div>
    <a-table
      :dataSource="table_data"
      :columns="table_columns"
      :loading="table_loading"
      @change="changeCurrentPage"
      :pagination="pagination_props"
    >
      <template #filterIcon="{ column }">
        <search-outlined
          :style="{
            color:
              search_value[column.dataIndex] === undefined ||
              search_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>

      <template #filterDropdown="{ column, confirm, clearFilters }">
        <div style="padding: 8px">
          <a-form
            name="form"
            layout="vertical"
            ref="formRef"
            :model="filter_values"
            :rules="rules"
          >
            <a-form-item has-feedback :name="column.dataIndex">
              <a-input
                v-model:value="filter_values[column.key]"
                :placeholder="`Buscar por ${column.title}`"
                :maxlength="
                  column.dataIndex == 'employeeRut' ||
                  column.dataIndex == 'companyRut'
                    ? 12
                    : 40
                "
                @pressEnter="
                  handleSearch(
                    column.dataIndex,
                    filter_values[column.key],
                    confirm
                  )
                "
                @keyup="formatRuts"
                style="width: 188px; margin-bottom: 8px; display: block"
              />
            </a-form-item>
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  filter_values[column.key],
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>
            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </a-form>
        </div>
      </template>

      <!-- Filtros para estados -->
      <template #filterStates="{ confirm, column, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-checkbox-group v-model:value="states_selection">
            <a-row>
              <a-col
                :span="24"
                v-for="(state, index) in states_options"
                :key="index"
              >
                <a-checkbox :value="state.value">{{ state.label }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>

          <div
            class="mt-4 p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  states_selection.join(','),
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <template #filterCompanies="{ column, confirm, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-select
            v-model:value="selected_companies"
            :options="companies"
            mode="multiple"
            show-search
            :filterOption="true"
            optionFilterProp="label"
            placeholder="Buscar por Razon social"
            style="width: 100%"
            size="middle"
          >
            <template #dropdownRender="{ menuNode: menu }">
              <v-nodes :vnodes="menu" />
              <div
                style="
                  border-top: 1px solid #f0f0f0;
                  display: flex;
                  justify-content: space-between;
                "
                class="p-2"
                @mousedown="(e) => e.preventDefault()"
              >
                <a-button
                  type="primary"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="
                    handleSearch(
                      column.dataIndex,
                      selected_companies.join(','),
                      confirm
                    )
                  "
                >
                  Buscar
                </a-button>

                <a-button
                  size="small"
                  style="width: 90px"
                  @click="handleColumnReset(column.dataIndex, clearFilters)"
                >
                  Limpiar
                </a-button>
              </div>
            </template>
          </a-select>
          <div
            class="mt-4 p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  selected_companies.join(','),
                  confirm
                )
              "
            >
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <template #filterIconState="{ column }">
        <filter-outlined
          :style="{
            color:
              search_value[column.dataIndex] === undefined ||
              search_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>

      <template #tag="{ text: tag }">
        <tag :stateId="tag" />
      </template>

      <template #details="{ text }">
        <a-tooltip :color="'#05045D'">
          <template #title>Ver más</template>
          <router-link :to="{ name: 'TransferDetail', params: { id: text } }">
            <eye-outlined />
          </router-link>
        </a-tooltip>
      </template>
    </a-table>
  </div>
</template>

<script>
import { computed, watchEffect } from 'vue';
import { onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  SearchOutlined,
  EyeOutlined,
  FilterOutlined
} from '@ant-design/icons-vue';
import {
  FETCH_TABLE_TICKETS,
  FETCH_ALL_COMPANIES,
  GETTER_TICKET_TABLE_ROWS,
  GETTER_TICKET_TABLE_TOTAL_PAGES,
  GETTER_TICKET_TABLE_ITEMS_PER_PAGE,
  GETTER_TICKET_TABLE_CURRENT_PAGE,
  GET_ALL_COMPANIES
} from '@/store/types';
import rutHelper from '@/utils/Ruts';
import tag from '@/components/Tags.vue';

export default {
  components: {
    'search-outlined': SearchOutlined,
    'eye-outlined': EyeOutlined,
    'filter-outlined': FilterOutlined,
    tag,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    }
  },
  props: {
    dates: Object
  },
  setup(props) {
    const table_columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Holding',
        dataIndex: 'holding',
        key: 'holding',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Subholding',
        dataIndex: 'subHolding',
        key: 'subHolding',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Razón social',
        dataIndex: 'companyId',
        key: 'companyId',
        slots: {
          filterDropdown: 'filterCompanies',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'RUT empresa',
        dataIndex: 'companyRut',
        key: 'companyRut',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Nombre',
        dataIndex: 'employeeName',
        key: 'employeeName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'RUT',
        dataIndex: 'employeeRut',
        key: 'employeeRut',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Estado',
        dataIndex: 'state',
        key: 'state',
        slots: {
          customRender: 'tag',
          filterDropdown: 'filterStates',
          filterIcon: 'filterIconState'
        }
      },
      {
        title: 'Opciones',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'details'
        }
      }
    ];
    const store = useStore();
    const table_data = computed(() => {
      return store.getters[GETTER_TICKET_TABLE_ROWS].map((obj) => ({
        id: obj.id,
        key: obj.id,
        holding: obj.holding,
        subHolding: obj.subHolding,
        companyId: obj.company.name,
        companyRut: rutHelper.formatRut(obj.company.rut),
        employeeName: obj.employee.name,
        employeeRut: rutHelper.formatRut(obj.employee.rut),
        state: obj.state.id
      }));
    });

    const total = computed(() => {
      return store.getters[GETTER_TICKET_TABLE_TOTAL_PAGES];
    });
    const items_per_page = computed(() => {
      return store.getters[GETTER_TICKET_TABLE_ITEMS_PER_PAGE];
    });
    const current_page = computed(() => {
      return store.getters[GETTER_TICKET_TABLE_CURRENT_PAGE];
    });
    const pagination_props = reactive({
      total,
      pageSize: items_per_page,
      current: current_page
    });

    const table_loading = ref(false);
    const route = useRoute();
    const router = useRouter();
    const formRef = ref();
    const filter_values = reactive({});
    const search_value = reactive({});
    const validateEmail = (mail) => {
      return String(mail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const checkEmail = async (rule, value) => {
      if (!value || value == '') {
        return Promise.resolve();
      }
      if (validateEmail(value)) {
        return Promise.resolve();
      }
      return Promise.reject('Correo inválido');
    };
    const checkRut = async (rule, value) => {
      if (!value) {
        return;
      }
      if (!rutHelper.validateRut(value)) {
        return Promise.reject('RUT no válido');
      }
      return Promise.resolve();
    };
    const rules = {
      email: [
        {
          required: true,
          validator: checkEmail,
          trigger: 'change'
        }
      ],
      employeeRut: [
        {
          required: false,
          validator: checkRut,
          trigger: 'change'
        }
      ],
      companyRut: [
        {
          required: false,
          validator: checkRut,
          trigger: 'change'
        }
      ]
    };

    // * Metodos

    const selected_companies = ref([]);
    const companies = computed(() => {
      return store.getters[GET_ALL_COMPANIES].map((obj) => {
        return {
          value: obj.id,
          label: obj.businessName
        };
      });
    });

    const handleSearch = (column, value, confirm) => {
      addParamForRedirection(column, value);
      filterRedirectionNewParam();
      confirm();
      if (column === 'companyId') {
        selected_companies.value = [];
      }
    };

    const states_selection = ref([]);
    const states_options = ref([
      {
        label: 'Creado',
        value: 1
      },
      {
        label: 'En revision Aseguradora',
        value: 8
      },
      {
        label: 'Ejecutado',
        value: 3
      },
      {
        label: 'Cancelado',
        value: 4
      }
    ]);
    const handleColumnReset = (column, resetConfirm) => {
      console.log('handleColumnReset');
      filterRedirectionResetColumn(column);
      delete filter_values[column];
      resetConfirm();

      if (column === 'state') {
        states_selection.value = [];
      }

      if (column === 'companyId') {
        selected_companies.value = [];
      }
    };

    const filterRedirectionResetColumn = (key) => {
      delete search_value[key];
      router.replace({
        name: 'Transfers',
        query: search_value
      });
    };

    const addParamForRedirection = (key, param) => {
      search_value[key] = param;
      filter_values[key] = param;

      if (key != 'page') {
        search_value['page'] = 1;
        filter_values['page'] = 1;
      }
    };

    const getCompaniesList = async () => {
      try {
        store.dispatch(FETCH_ALL_COMPANIES);
      } catch (error) {
        console.log(error);
      }
    };

    const deleteParamForRedirection = (key) => {
      delete filter_values[key];
      delete search_value[key];
    };

    const filterRedirectionNewParam = () => {
      formRef.value.validate().then(() => {
        router.replace({
          name: 'Transfers',
          query: search_value
        });
      });
    };

    const changeCurrentPage = (page) => {
      addParamForRedirection('page', page.current);
      filterRedirectionNewParam();
    };

    const formatRuts = () => {
      if (filter_values['employeeRut']) {
        filter_values['employeeRut'] = rutHelper.formatRut(
          filter_values['employeeRut']
        );
      }
      if (filter_values['companyRut']) {
        filter_values['companyRut'] = rutHelper.formatRut(
          filter_values['companyRut']
        );
      }
    };

    // * Interaccion
    watch(
      () => props.dates,
      (new_val) => {
        if (new_val[0] != undefined && new_val[1] != undefined) {
          addParamForRedirection('startDate', new_val[0]);
          addParamForRedirection('endDate', new_val[1]);
        } else {
          deleteParamForRedirection('startDate');
          deleteParamForRedirection('endDate');
        }

        filterRedirectionNewParam();
      }
    );

    const ticket_type = computed(() => {
      switch (route.name) {
        case 'Altas':
          return 'Alta';
        case 'AltasCargas':
          return 'Altas de cargas';
        case 'Bajas':
          return 'Baja';
        case 'BajasCargas':
          return 'Bajas de cargas';
        case 'Modificaciones':
          return 'Modificacion';
        case 'Transfers':
          return 'Traspaso';
        default:
          break;
      }
      return '';
    });

    watchEffect(async (onCleanUp) => {
      try {
        table_loading.value = true;
        const ac = new AbortController();
        const signal = ac.signal;
        onCleanUp(() => ac.abort());
        const queryParams = route.fullPath.split('?')[1] || '';
        await store.dispatch(FETCH_TABLE_TICKETS, {
          payload: `type=${ticket_type.value}&${queryParams}`,
          signal
        });
        table_loading.value = false;
      } catch (error) {
        table_loading.value = false;
      }
    });

    // * Obtencion de data principal
    onMounted(() => {
      getCompaniesList();

      const keys = Object.keys(route.query);
      keys.forEach((element) => {
        const param_value = route.query[element];
        filter_values[element] = param_value;
        search_value[element] = param_value;

        // en caso de que el filtro que venga sea 'State', voy a dejar selecciadas las casillas checkbox
        if (element === 'state') {
          states_selection.value.push(
            ...route.query[element].split(',').map((e) => parseInt(e))
          );
        }

        // en caso de que el filtro que venga sea 'companyId', voy a dejar selecciadas las casillas checkbox
        if (element === 'companyId') {
          selected_companies.value.push(
            ...route.query[element].split(',').map((e) => parseInt(e))
          );
        }
      });
    });

    return {
      changeCurrentPage,
      table_columns,
      table_data,
      pagination_props,
      table_loading,
      formRef,
      filter_values,
      search_value,
      rules,
      selected_companies,
      companies,
      handleSearch,
      handleColumnReset,
      formatRuts,
      states_selection,
      states_options
    };
  }
};
</script>
