import {
  typeCases,
  caseTicketId,
  caseAntecedentes,
  getCaseData,
  getCaseFiles,
  createStateCase
} from '../../../network';
import {
  SET_CASES,
  SET_FILE,
  SET_TYPE_CASES,
  SET_SELECTED,
  FETCH_CASES,
  FETCH_TYPE_CASES,
  POST_FILE_ANTECEDENTES,
  SELECTED_TYPE,
  FETCH_CASE_ID_DATA,
  FETCH_FILES_TICKET_ID,
  POST_CREATE_STATE_CASE,
  GET_CASE_TICKET_ID,
  SET_CASE_TICKET_ID
} from './types';

const store = {};
const getDefaultState = () => ({
  cases: [],
  typeCases: [],
  tipos: {
    type_id: '',
    tag: ''
  },
  visible: false,
  caseFile: [],
  caseTicketById: []
});

store.state = getDefaultState();

store.getters = {
  cases: (state) => state.cases,
  getTypeCases: (state) => state.typeCases.splice(0, 2),
  tipos: (state) => state.tipos,
  [GET_CASE_TICKET_ID]: (state) => state.caseTicketById
};

store.mutations = {
  [SET_FILE](state, payload) {
    state.caseFile = payload;
  },
  [SET_CASES](state, payload) {
    state.cases = payload;
  },
  [SET_TYPE_CASES](state, payload) {
    state.typeCases = payload;
  },
  [SET_SELECTED](state, payload) {
    state.tipos = payload;
  },
  [SET_CASE_TICKET_ID](state, payload) {
    state.caseTicketById = payload;
  }
};

store.actions = {
  async [FETCH_TYPE_CASES]({ commit }) {
    const response = await typeCases();
    if (response && response.status === 200) {
      commit(SET_TYPE_CASES, response.data);
    } else {
      throw { mensaje: 'error en la solicitud' };
    }
  },
  async [FETCH_CASES]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      caseTicketId(payload).then(
        (response) => {
          commit(SET_CASE_TICKET_ID, response);
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [FETCH_CASE_ID_DATA]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getCaseData(payload).then(
        (response) => {
          commit(SET_CASES, response);
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [FETCH_FILES_TICKET_ID](_, payload) {
    return new Promise((resolve, reject) => {
      getCaseFiles(payload).then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [POST_FILE_ANTECEDENTES](_, payload) {
    return new Promise((resolve, reject) => {
      caseAntecedentes(payload).then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [POST_CREATE_STATE_CASE](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      createStateCase(id, payload).then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [SELECTED_TYPE]({ commit }, payload) {
    commit(SET_SELECTED, payload);
  }
};

export default store;
