<template>
  <div>
    <page-header v-bind="page_header_props" />

    <a-row :gutter="16">
      <a-col :span="6">
        <a-card>
          <!-- informacion de la empresa -->
          <div>
            <div style="text-align: center" class="mb-3">
              <a-row type="flex" justify="center">
                <a-avatar :size="100">
                  <template #icon><user-outlined /></template>
                </a-avatar>
              </a-row>
              <strong style="font-size: 20px">{{ data.name }}</strong>
            </div>

            <a-row align="middle" justify="space-between">
              <ShopOutlined />
              <a href="#" @click.prevent="editCompany(data.id)" v-if="user_role === 'superadmin'">
                <EditOutlined />
                Editar
              </a>
            </a-row>

            <a-divider style="margin: 12px 0px" />

            <div>
              <b>RUT</b>
              <p>{{ data.rut }}</p>
            </div>
            <div>
              <b>Razón social</b>
              <p>{{ data.name }}</p>
            </div>
            <div class="mb-3">
              <b>Holding</b>
              <div>
                <a href="#">{{ data.holding }}</a>
              </div>
            </div>
            <div class="mb-3">
              <b>Subholding</b>
              <div>
                <a href="#">{{ data.subholding }}</a>
              </div>
            </div>

            <UserOutlined />
            <a-divider style="margin: 12px 0px" />

            <div class="mb-3">
              <b>Contraparte principal</b>
              <p>
                {{
                  data.executive.name != ''
                    ? data.executive.name
                    : 'Sin contraparte principal'
                }}
              </p>
            </div>
            <div class="mb-3">
              <b>Correo electrónico</b>
              <p>
                {{
                  data.executive.email != ''
                    ? data.executive.email
                    : 'Sin contraparte principal'
                }}
              </p>
            </div>
            <div class="mb-3">
              <b>Teléfono</b>
              <p>
                {{
                  data.executive.phone != ''
                    ? data.executive.phone
                    : 'Sin contraparte principal'
                }}
              </p>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :span="18">
        <a-card class="card-extended">
          <container-tabs />
        </a-card>
      </a-col>
    </a-row>

    <company-modal ref="modalRef" />
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  UserOutlined,
  ShopOutlined,
  EditOutlined
} from '@ant-design/icons-vue';
import PageHeader from '@/components/PageHeader.vue';
import {
  GET_USER_ROLE,
  FETCH_COMPANY,
  GET_COMPANY,
  GET_CLIENT_CURRENT_TAB,
  SAVE_CLIENT_CURRENT_TAB
} from '@/store/types';

import rutHelper from '@/utils/Ruts';
import CompanyModal from '@/components/clients/CompanyModal.vue';
import ContainerTabs from './company-tabs-container.vue';

export default {
  name: 'EmpresaDashboard',
  components: {
    'company-modal': CompanyModal,
    'page-header': PageHeader,
    ContainerTabs,
    UserOutlined,
    ShopOutlined,
    EditOutlined
  },
  setup() {
    // * Vuex
    const store = useStore();
    const router = useRouter();

    // Obtencion del rol del usuario
    const user_role = store.getters[GET_USER_ROLE];

    // * Page header
    const page_header_props = reactive({
      title: '',
      breadcrumRoutes: [
        {
          path: '/clientes',
          breadcrumbName: 'Cliente'
        },
        {
          path: '/clientes#empresas',
          breadcrumbName: 'Empresas'
        }
      ]
    });

    // * Modal de edicion de compañia
    const modalRef = ref();

    const editCompany = (value) => {
      modalRef.value.editCompany(value);
    };

    // * Logica principal de del componente
    // Router
    const route = useRoute();

    // Id de la compañia que estamos viendo desde la url
    const company_id = route.params.id;

    // Funcion para obtener informacion de la compañia
    const getCompanyData = async (id) => {
      try {
        await store.dispatch(FETCH_COMPANY, id);
      } catch (error) {
        router.push({ name: 'Missing' });
      }
    };

    // Data ordenada que coloco en el componente
    const data = computed(() => {
      const store_data = store.getters[GET_COMPANY];

      page_header_props.title = store_data.businessName;

      const { breadcrumRoutes } = page_header_props;

      if (breadcrumRoutes.length === 3) {
        breadcrumRoutes.pop();
      }

      breadcrumRoutes.push({
        breadcrumbName: store_data.businessName
      });

      page_header_props.breadcrumRoutes = breadcrumRoutes;

      return {
        id: store_data.id,
        rut:
          store_data.rut != undefined
            ? rutHelper.formatRut(store_data.rut)
            : '',
        name: store_data.businessName,
        executive: {
          name:
            store_data.principalExecutive != null
              ? store_data.principalExecutive.name
              : '',
          phone:
            store_data.principalExecutive != null
              ? store_data.principalExecutive.phone
              : '',
          email:
            store_data.principalExecutive != null
              ? store_data.principalExecutive.email
              : ''
        },
        holding:
          store_data.subHolding === null && store_data.holding != null
            ? store_data.holding.businessName
            : store_data.subHolding != null
              ? store_data.subHolding.holding.businessName
              : '',
        subholding:
          store_data.subHolding != null ? store_data.subHolding.name : ''
      };
    });

    const activeKey = ref('');

    const tabs = [
      {
        title: 'Grupos',
        name: 'Grupos',
        key: '1'
      },
      {
        title: 'Póliza',
        name: 'Poliza',
        key: '2'
      },
      {
        title: 'Stock',
        name: 'Stock',
        key: '3'
      },
      {
        title: 'Usuarios',
        name: 'Usuarios',
        key: '4'
      },
      {
        title: 'Recepción',
        name: 'Recepcion',
        key: '5'
      },
      {
        title: 'Notificaciones',
        name: 'Notificaciones',
        key: '6'
      }
    ];

    // const tabKey = computed({
    //   get(){
    //     return activeKey.value=store.getters[SELECTED_ACTIVE_TABS];
    //   },
    //   set(value){
    //     store.dispatch(ACTION_ACTIVE_KEY_TABS,value);
    //     router.push({name:value});
    //   }
    // });

    const gettabCliente = computed(() => store.getters[GET_CLIENT_CURRENT_TAB]);

    watch(activeKey, () => {
      store.dispatch(SAVE_CLIENT_CURRENT_TAB, gettabCliente.value);
      // store.dispatch(ACTION_ACTIVE_KEY_TABS,value);
    });

    // Coloco un un path mas a las rutas dependiendo del nombre de la empresa que estoy viendo

    /*
      page_header_props.breadcrumRoutes.push({
        breadcrumbName: store_data.businessName
      });

    /* const {breadcrumRoutes} = page_header_props;

      if(breadcrumRoutes.length === 4){
        breadcrumRoutes.pop();
      }

      breadcrumRoutes.push({
        breadcrumbName:newValue
      });

      page_header_props.breadcrumRoutes = breadcrumRoutes; */

    // * Carga principal
    getCompanyData(company_id);

    return {
      gettabCliente,
      page_header_props,
      user_role,
      data,
      rutHelper,
      modalRef,
      editCompany,
      tabs,
      // tabKey,
      activeKey,
      company_id
    };
  }
};
</script>

<style>
.card-extended {
  height: 100%;
}
</style>
