import VueCookies from 'vue-cookies';
import {
  deleteTokens,
  tokenValidation,
  tokenRefresh,
  authLogin,
  resetMail,
  getOneUserById
} from '../../network';
import {
  FETCH_AUTH,
  SET_PROFILE,
  UNSET_PROFILE,
  LOG_OUT,
  GET_BROKER_ID,
  LOG_IN,
  GET_LOGGED_USER,
  GET_USER_TOKEN_EXP,
  GET_USER_ROLE,
  TOKEN_VALIDATION,
  REFRESH_TOKEN,
  RESET_PASS,
  GET_USER_NAME,
  SELECTS,
  ACTION_SELECTED_SUBHOLDING,
  SET_PROFILE_BY_ID,
  GET_PROFILE_BY_ID,
  FETCH_USER_BY_ID
} from '../types';

const store = {};
const getDefaultState = () => ({
  auth: {
    id: '',
    email: '',
    name: '',
    phone: '',
    role_name: '',
    role_id: '',
    token_expiration_date: ''
  },
  profile: []
});

store.state = getDefaultState();

store.getters = {
  [GET_BROKER_ID](state) {
    return state.auth.id;
  },
  [GET_LOGGED_USER](state) {
    return state.auth;
  },
  [GET_USER_TOKEN_EXP](state) {
    return state.auth.token_expiration_date;
  },
  [GET_USER_ROLE](state) {
    return state.auth.role_name;
  },
  [GET_USER_NAME](state) {
    return state.auth.name;
  },
  [GET_PROFILE_BY_ID](state) {
    return state.profile;
  }
};

store.mutations = {
  [SET_PROFILE](state, payload) {
    state.auth.id = payload.id;
    state.auth.email = payload.email;
    state.auth.name = payload.name;
    state.auth.phone = payload.phone;
    state.auth.role_name = payload.role.tag;
    state.auth.role_id = payload.role.id;
    state.auth.token_expiration_date = payload.exp_token_date;
  },
  [UNSET_PROFILE](state) {
    state.auth.id = '';
    state.auth.email = '';
    state.auth.name = '';
    state.auth.phone = '';
    state.auth.role_name = '';
    state.auth.role_id = '';
    state.auth.token_expiration_date = '';
  },
  [SET_PROFILE_BY_ID](state, payload) {
    return (state.profile = payload);
  }
};

store.actions = {
  async [FETCH_AUTH]({ commit }, payload) {
    commit(SET_PROFILE, payload);
  },
  [LOG_OUT]({ commit }) {
    return new Promise((resolve, reject) => {
      deleteTokens()
        .then((resp) => {
          commit(UNSET_PROFILE);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [LOG_IN]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      authLogin(payload).then(
        (resp) => {
          commit(SET_PROFILE, resp.data.body);
          resolve(resp);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  [REFRESH_TOKEN]() {
    return new Promise((resolve, reject) => {
      tokenRefresh()
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TOKEN_VALIDATION]() {
    return new Promise((resolve, reject) => {
      tokenValidation()
        .then((resp) => {
          resolve(resp.data.body);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [LOG_OUT]({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      deleteTokens()
        .then((resp) => {
          commit(UNSET_PROFILE);
          VueCookies.remove('busqueda');
          setTimeout(() => {
            dispatch(SELECTS, []);
            dispatch(ACTION_SELECTED_SUBHOLDING, []);
          }, 3000);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [RESET_PASS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      resetMail(payload).then(
        (resp) => {
          // Dejo el usuario como vacio porsiacaso
          commit(UNSET_PROFILE);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [FETCH_USER_BY_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getOneUserById(payload).then(
        (resp) => {
          commit(SET_PROFILE_BY_ID, resp);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
};

export default store;
