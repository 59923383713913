import altaIndividual from './altas/altaIndividual/altaIndividual';
import altaMasiva from './altas/altaMasivas/altaMasiva';
import bajas from './bajas/index';
import cases from './cases/cases';
import antecedentes from './cases/antecedentes';
import outOfTime from './cases/outOfTime';
import rechazo from './cases/rechazo';
import comments from './comments/comments';
import datatable from './datatable/index';
import history from './historyFileTicket';
import insured from './insured/insured';
import modificaciones from './modificaciones';
import reapplication from './reapplication/reapplication';
import transfer from './transfer';
import dependentsIncorporation from './dependants-incorporation/store';
import dependentsExclusion from './dependents-exclusion';
import incorporation from './incorporation/store';
import common from './common/store';

const store = {};

store.modules = {
  common,
  incorporation,
  comments,
  cases,
  antecedentes,
  outOfTime,
  rechazo,
  insured,
  altaMasiva,
  modificaciones,
  reapplication,
  altaIndividual,
  bajas,
  datatable,
  history,
  transfer,
  dependentsIncorporation,
  dependentsExclusion
};

export default store;
