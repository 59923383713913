import {
  getRequestsByCollectionId,
  getRequestsById,
  postRequestByCollectionId,
  getRequestsIdCollection,
  createRequestsComment,
  getRequestsComments,
  changeRequestState,
  getRequestsSummary,

  // test network api files
  getAllFileInvoice,
  postFileInvoice,
  patchFileInvoice,
  deleteFileInvoice
} from '../../../network';
import notaCredito from './creditNote';
import notaDebito from './debitNote';
import general from './general';
import ajusteRetroactivo from './retroActiveAdjustment';
import {
  FETCH_REQUESTS_BY_COLLECTION_ID,
  MUTATE_REQUESTS_BY_COLLECTION_ID,
  GET_REQUESTS_BY_COLLECTION_ID,
  FETCH_REQUESTS_BY_ID,
  MUTATE_REQUESTS_BY_ID,
  GET_REQUEST_BY_ID,
  POST_REQUESTS,
  FETCH_REQUESTS_ID_COLLECTION,
  MUTATE_REQUESTS_ID_COLLECTION,
  GET_REQUESTS_ID_COLLECTION,
  POST_REQUESTS_COMMENTS,
  MUTATE_REQUESTS_COMMENTS,
  GET_REQUESTS_COMMENTS,
  FETCH_REQUESTS_COMMENTS,
  POST_CHANGE_REQUEST_STATE,
  FETCH_REQUESTS_SUMMARY,
  MUTATE_REQUESTS_SUMMARY,
  GET_REQUESTS_SUMMARY,

  // test files invoices
  FETCH_FILES_INVOICES,
  GET_FILES_INVOICES,
  MUTATE_FILES_INVOICES,
  POST_FILES_INVOICES,
  GET_SELECT_FILTER,
  SET_SELECT_INVOICES,
  SELECT_INVOICES_DATA,
  GET_INVOICES,
  DELETE_FILE_INVOICE,
  PATCH_FILE_INVOICE
} from './types';

const store = {};
store.modules = {
  notaCredito,
  notaDebito,
  ajusteRetroactivo,
  general
  // test files
};

store.state = {
  FilesInvoice: [],
  collectionRequests: [],
  requests: [],
  requestIdCollection: [],
  comments: [],
  selectedInvoices: [],
  requests_summary: {}
};

store.getters = {
  [GET_REQUESTS_BY_COLLECTION_ID]: (state) => state.collectionRequests,
  [GET_REQUEST_BY_ID]: (state) => state.requests,
  [GET_REQUESTS_ID_COLLECTION]: (state) => state.requestIdCollection,
  [GET_REQUESTS_COMMENTS]: (state) => state.comments,
  [GET_REQUESTS_SUMMARY]: (state) => (type) => state.requests_summary[type],
  // TEST GET FILES invoice
  [GET_FILES_INVOICES]: (state) =>
    state.FilesInvoice.map((e, i) => ({
      key: i,
      id: e.id,
      name: e.objectFilename,
      value: e.id,
      invoices: e.requestInvoices.map((e) => ({
        label: e.invoice.folio,
        value: e.invoice.id,
        key: e.invoice.id
      }))
    })),
  [GET_INVOICES]: (state, getters) =>
    state.requests.requestInvoices
      .map((e) => ({
        label: e.invoice.folio,
        value: e.invoice.id,
        key: e.invoice.id
      }))
      .filter(
        (fl) =>
          !getters[GET_FILES_INVOICES].reduce((acc, item) => {
            return [...acc, ...item.invoices.map((e) => e.value)];
          }, []).includes(fl.key)
      )
      .filter(
        (f) =>
          !getters[GET_SELECT_FILTER].flat().some((s) => s.value == f.value)
      ),
  [GET_SELECT_FILTER]: (state) => state.selectedInvoices
};

store.mutations = {
  [MUTATE_REQUESTS_BY_COLLECTION_ID](state, payload) {
    state.collectionRequests = payload;
  },
  [MUTATE_REQUESTS_BY_ID](state, payload) {
    state.requests = payload;
  },
  [MUTATE_REQUESTS_ID_COLLECTION](state, payload) {
    state.requestIdCollection = payload;
  },
  [MUTATE_REQUESTS_COMMENTS](state, payload) {
    state.comments = payload;
  },

  // test mutate files invoice
  [MUTATE_FILES_INVOICES](state, payload) {
    state.FilesInvoice = payload;
  },
  [MUTATE_REQUESTS_SUMMARY](state, { type, data }) {
    state.requests_summary[type] = data;
  },
  [SET_SELECT_INVOICES](state, payload) {
    console.log('payloaaaad', payload);
    state.selectedInvoices = payload;
  }
};

store.actions = {
  [FETCH_REQUESTS_BY_COLLECTION_ID](
    { commit },
    { collection_id, query_params }
  ) {
    return new Promise((resolve, reject) => {
      getRequestsByCollectionId(collection_id, query_params)
        .then((resp) => {
          commit(MUTATE_REQUESTS_BY_COLLECTION_ID, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_REQUESTS_BY_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getRequestsById(payload)
        .then((resp) => {
          commit(MUTATE_REQUESTS_BY_ID, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_REQUESTS](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      postRequestByCollectionId(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_REQUESTS_ID_COLLECTION]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getRequestsIdCollection(payload)
        .then((resp) => {
          commit(MUTATE_REQUESTS_ID_COLLECTION, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_REQUESTS_COMMENTS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getRequestsComments(payload)
        .then((resp) => {
          commit(MUTATE_REQUESTS_COMMENTS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_REQUESTS_SUMMARY]({ commit }, { type, payload }) {
    return new Promise((resolve, reject) => {
      getRequestsSummary(type, payload)
        .then((resp) => {
          commit(MUTATE_REQUESTS_SUMMARY, { type, data: resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_REQUESTS_COMMENTS](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      createRequestsComment(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_CHANGE_REQUEST_STATE](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      changeRequestState(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // teeeest aprovar modal con archivos

  [FETCH_FILES_INVOICES]({ commit, getters }, requestId) {
    return new Promise((resolve, reject) => {
      getAllFileInvoice(requestId)
        .then((resp) => {
          commit(MUTATE_FILES_INVOICES, resp.data);
          commit(
            SET_SELECT_INVOICES,
            getters[GET_FILES_INVOICES].map((e) => e.invoices)
          );
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_FILES_INVOICES](_, { requestId, payload }) {
    const formData = new FormData();
    formData.append('file', payload.file);
    return new Promise((resolve, reject) => {
      postFileInvoice(requestId, formData)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_FILE_INVOICE](_, { requestId, requestInvoiceFileId }) {
    return new Promise((resolve, reject) => {
      deleteFileInvoice(requestId, requestInvoiceFileId)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_FILE_INVOICE](_, { requestId, requestInvoiceFileId, payload }) {
    return new Promise((resolve, reject) => {
      patchFileInvoice(requestId, requestInvoiceFileId, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SELECT_INVOICES_DATA]({ commit }) {
    commit(SET_SELECT_INVOICES, []);
  }
};

export default store;
