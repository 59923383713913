<template>
  <a-row class="mt-4" type="flex" justify="center">
    <a-col>
      <img src="@/assets/images/insured_form/ilustracion.svg" alt="" width="150" class="mb-2" />
    </a-col>
  </a-row>
  <br />
  <a-row class="mb-4 pb-1" type="flex" justify="center">
    <a-col class="text-center" :xs="20" :sm="16" :md="12" :lg="8">
      <a-typography-title class="ff-urbanist" :level="1">{{
        companyName
        }}</a-typography-title>

      <a-typography-title class="text-gray-8" :level="3">Formulario de incorporación</a-typography-title>
      <a-typography-text class="text-gray-8 ml-3">Este formulario está compuesto por 3 pasos</a-typography-text>
    </a-col>
  </a-row>
  <a-row class="mb-4" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <a-steps direction="vertical" :current="0" class="stepper-client">
        <a-step title="Verifica tus datos" description="Nombre, Rut y correo electrónico." />
        <a-step title="Ingresa tus cargas" description="Ingresa el Rut de cada una de tus cargas." />
        <a-step title="Carga tus documentos"
          description="Una vez que hayas completado el o los formularios, cárgalos desde tu computador." />
      </a-steps>
    </a-col>
  </a-row>
  <a-row class="mb-4" type="flex" justify="center">
    <a-col>
      <router-link class="justify-content-center d-flex"
        :to="{ name: 'insuredform1', params: { token: $route.params.token } }">
        <a-button class="btn-client" size="large" :loading="loading" block :disabled="ticketInfo !== 1">{{ typeButton
          }}</a-button>
      </router-link>
    </a-col>
  </a-row>
</template>

<script>
import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { FETCH_DATA_TICKET, FETCH_TICKET_ID } from '@/store/types';

export default {
  name: 'InsuredForm',
  setup() {
    const info = reactive({
      id: '',
      ticketId: '',
      token: ''
    });
    const loading = ref(false);

    // Vue router
    const route = useRoute();
    // Vuex
    const store = useStore();
    const companyName = ref(null);
    const typeButton = ref('');

    try {
      info.token = route.params.token;
      const decodeToken = JSON.parse(atob(info.token.split('.')[1]));
      info.id = decodeToken.employeeId;
      info.ticketId = decodeToken.ticketId;
    } catch (e) {
      message.error('Hubo un problema con el token');
    }

    const getData = async () => {
      try {
        const response = await store.dispatch(FETCH_DATA_TICKET, {
          payload: info.id,
          token: info.token
        });
        console.log('responsee', response);
        if (response.data) {
          store.commit('setIncorporationToken', info);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();

    const ticketInfo = ref({});

    const getTicketInfo = async () => {
      store
        .dispatch(FETCH_TICKET_ID, info.ticketId)
        .then((response) => {
          companyName.value = response.data.company.businessName;
          ticketInfo.value = response.data.states.sort((a, b) => {
            return b.id - a.id;
          })[0].state.id;
          if (response.status == 200 && ticketInfo.value == 1) {
            loading.value = true;
            typeButton.value = 'Comenzar';
            loading.value = false;
          }
          if (ticketInfo.value !== 1) {
            loading.value = true;
            typeButton.value = 'Este formulario ya no está disponible';
            loading.value = false;
          }
        })
        .catch((err) => {
          loading.value = true;
          if (err) {
            typeButton.value = 'Este formulario ya no está disponible';
            loading.value = false;
          }
        });
    };

    getTicketInfo();
    return {
      ticketInfo,
      companyName,
      loading,
      typeButton
    };
  }
};
</script>
