<template>
  <a-table
    rowKey="id"
    :columns="[
      {
        title: 'RUT',
        dataIndex: 'rut',
        key: 'rut'
      },
      {
        title: 'Nombre',
        dataIndex: 'fullName',
        key: 'fullName',
        slot: { customRender: 'fullName' }
      },
      {
        title: 'Acción',
        key: 'action',
        slots: { customRender: 'action' }
      }
    ]"
    :data-source="dependants"
    :showHeader="false"
    :pagination="false"
    :loading="loading"
  >
    <template #title>Cargas</template>
    <template
      v-if="
        !ticketLoading &&
        ![TICKET.STATES.CANCELED, TICKET.STATES.EXECUTED].includes(
          ticket.state?.id
        )
      "
      #action="{ record }"
    >
      <a-tooltip placement="topLeft" title="Eliminar">
        <a-popconfirm
          title="¿Estás seguro de eliminar esta carga?"
          ok-text="Sí"
          cancel-text="No"
          @confirm="
            () =>
              deleteDependant(
                record.employeeDependant?.ticketId,
                record.employeeDependant?.id
              )
          "
        >
          <a-button type="link">
            <delete-outlined class="red-icon" />
          </a-button>
        </a-popconfirm>
      </a-tooltip>
    </template>
  </a-table>
</template>

<script setup>
import {
  GET_TICKET_DEPENDANTS,
  DELETE_TICKET_DEPENDANT,
  GET_TICKET
} from '@/store/types';
import TICKET from '@/constants/ticket';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { DeleteOutlined } from '@ant-design/icons-vue';
import notification from '@/utils/notifications';
const store = useStore();

const ticket = computed(() => store.getters[GET_TICKET].data);
const ticketLoading = computed(() => store.getters[GET_TICKET].loading);
const dependants = computed(() => store.getters[GET_TICKET_DEPENDANTS].data);
const loading = computed(() => store.getters[GET_TICKET_DEPENDANTS].loading);

const deleteDependant = async (ticketId, employeeDependantId) => {
  try {
    await store.dispatch(DELETE_TICKET_DEPENDANT, {
      ticketId,
      employeeDependantId
    });
  } catch (error) {
    console.error('Error deleting dependant', error);
    console.error('Params', ticketId, employeeDependantId);
    notification(
      'error',
      'Error',
      'No se pudo eliminar la carga. Intente de nuevo.'
    );
  }
};
</script>
