<template>
  <div>
    <a-card>
      <a-typography-title :level="4"
        >Beneficiarios seguro de vida</a-typography-title
      >

      <a-skeleton :loading="loading_data" active>
        <div v-if="form_state.beneficiaries.length === 0">
          <a-empty>
            <template #description>
              <span> Aún no has agregado beneficiarios </span>
            </template>
          </a-empty>

          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <a-button @click="addDependant" type="primary">
              <template #icon>
                <PlusOutlined />
              </template>
              Agregar mis beneficiarios
            </a-button>
          </div>
        </div>

        <a-form ref="form_ref" :model="form_state" layout="vertical">
          <div
            v-for="(dependant, index) in form_state.beneficiaries"
            :key="dependant.key"
          >
            <a-row type="flex" justify="space-between">
              <a-typography-title :level="5"
                >Beneficiario {{ index + 1 }}</a-typography-title
              >
              <div>
                <a-button
                  size="small"
                  v-if="index != form_state.beneficiaries.length - 1"
                  @click="moveBeneficiaryDown(index)"
                >
                  <template #icon>
                    <DownOutlined />
                  </template>
                  Bajar
                </a-button>
                <a-button
                  size="small"
                  v-if="index > 0"
                  @click="moveBeneficiaryUp(index)"
                  class="ml-2"
                >
                  <template #icon>
                    <UpOutlined />
                  </template>
                  Subir
                </a-button>
                <a-button
                  size="small"
                  @click="deleteDependant(dependant.key)"
                  class="ml-2"
                >
                  <template #icon>
                    <MinusOutlined />
                  </template>
                  Eliminar
                </a-button>
              </div>
            </a-row>

            <a-row :gutter="16" type="flex">
              <a-col :span="8">
                <a-form-item
                  :name="['beneficiaries', index, 'rut']"
                  :rules="{
                    required: true,
                    validator: checkRut,
                    trigger: 'change'
                  }"
                  label="RUT"
                  required
                >
                  <a-input
                    v-model:value="dependant.rut"
                    placeholder="Ingrese el rut"
                    @keyup="formatRuts(index)"
                    style="width: 100%"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  :name="['beneficiaries', index, 'firstName']"
                  :rules="{
                    required: true,
                    message: 'Ingrese el primer nombre',
                    trigger: 'change'
                  }"
                  label="Primer nombre"
                  required
                >
                  <a-input
                    v-model:value="dependant.firstName"
                    placeholder="Ingrese el primer nombre"
                    style="width: 100%"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  :name="['beneficiaries', index, 'secondName']"
                  label="Segundo  nombre"
                >
                  <a-input
                    v-model:value="dependant.secondName"
                    placeholder="Segundo nombre"
                    style="width: 100%"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  :name="['beneficiaries', index, 'firstSurname']"
                  :rules="{
                    required: true,
                    message: 'Ingrese el Apellido paterno',
                    trigger: 'change'
                  }"
                  label="Apellido paterno"
                  required
                >
                  <a-input
                    v-model:value="dependant.firstSurname"
                    placeholder="Ingrese el Apellido paterno"
                    style="width: 100%"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  :name="['beneficiaries', index, 'secondSurname']"
                  label="Apellido materno"
                >
                  <a-input
                    v-model:value="dependant.secondSurname"
                    placeholder="Apellido materno"
                    style="width: 100%"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  :name="['beneficiaries', index, 'relationship']"
                  :rules="{
                    required: true,
                    message: 'Ingrese Relación / Parentesco',
                    trigger: 'change',
                    type: 'string'
                  }"
                  required
                >
                  <template #label>
                    <a-typography>Relación / Parentesco </a-typography>
                    <a-tooltip>
                      <template #title>
                        Se sugiere dejar como beneficiarios del seguro de vida a
                        personas mayores de 18 años, dado que los menores de 18
                        años necesariamente deben actuar representados por
                        personas mayores de edad.
                      </template>
                      <QuestionCircleOutlined class="ml-2" />
                    </a-tooltip>
                  </template>
                  <a-input
                    v-model:value="dependant.relationship"
                    placeholder="Ingrese Relación / Parentesco"
                    style="width: 100%"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  :name="['beneficiaries', index, 'dateOfBirth']"
                  :rules="{
                    required: true,
                    message: 'Ingrese Fecha de nacimiento',
                    trigger: 'change',
                    type: 'object'
                  }"
                  label="Fecha de nacimiento"
                  required
                >
                  <a-date-picker
                    :style="{ width: '100%' }"
                    format="DD/MM/YYYY"
                    v-model:value="dependant.dateOfBirth"
                    placeholder="Ingrese Fecha de nacimiento"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  :name="['beneficiaries', index, 'email']"
                  label="Correo electrónico"
                  :rules="{
                    required: true,
                    message: 'Ingrese Correo electrónico',
                    trigger: 'change',
                    type: 'email'
                  }"
                >
                  <a-input
                    v-model:value="dependant.email"
                    placeholder="email@ejemplo.cl"
                    style="width: 100%"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  :name="['beneficiaries', index, 'benefitPercent']"
                  :rules="{
                    required: true,
                    validator: percentageValidator,
                    trigger: 'change'
                  }"
                  label="Porcentaje Beneficio"
                  required
                >
                  <a-input-number
                    :min="0"
                    :max="100"
                    v-model:value="dependant.benefitPercent"
                    placeholder="ingrese el porcentaje beneficio"
                    style="width: 100%"
                    :formatter="(value) => `${value}%`"
                    :parser="(value) => value.replace('%', '')"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row type="flex" justify="end">
              <a-button
                @click="addDependant"
                type="primary"
                v-if="form_state.beneficiaries.length - 1 === index"
                class="ml-2"
              >
                <template #icon>
                  <PlusOutlined />
                </template>
                Agregar
              </a-button>
            </a-row>

            <a-divider />
          </div>
        </a-form>
      </a-skeleton>
    </a-card>

    <a-row type="flex" justify="space-between" class="mt-3">
      <a-button size="large" @click="formSubmit(3)" :loading="loading">
        <template #icon>
          <ArrowLeftOutlined />
        </template>
        Atrás
      </a-button>
      <a-button
        size="large"
        type="primary"
        @click="formSubmit(5)"
        :loading="loading"
        >Siguiente</a-button
      >
    </a-row>
  </div>
</template>

<script setup>
import { computed, reactive, ref, onMounted, defineExpose } from 'vue';
import rutHelper from '@/utils/Ruts';
import {
  MinusOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
  DownOutlined,
  UpOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons-vue';
import notification from '@/utils/notifications';
import {
  ACTION_CHANGE_STEP,
  FETCH_BENEFICIARIES,
  FETCH_DEPENDANTS,
  PUT_BENEFICIARIES
} from '../../store/types';
import { useStore } from 'vuex';
import moment from 'moment';

const loading_data = ref(false);
const loading = ref(false);
const store = useStore();
const form_ref = ref();
const form_state = reactive({
  beneficiaries: []
});

const totalPercentage = computed(() => {
  const total_percentage = form_state.beneficiaries
    .map((dependant) => parseFloat(dependant.benefitPercent))
    .reduce((acc, value) => acc + value, 0);
  return total_percentage;
});

const isRutRepeated = computed(() => {
  return form_state.beneficiaries
    .map((obj) => obj.rut)
    .some((rut, index, array) => array.indexOf(rut) !== index);
});

const addDependant = () => {
  form_state.beneficiaries.push({
    key: Date.now(),
    rut: null,
    firstName: null,
    secondName: null,
    firstSurname: null,
    secondSurname: null,
    relationship: null,
    dateOfBirth: null,
    benefitPercent: null,
    email: null
  });
};

const deleteDependant = (key) => {
  form_state.beneficiaries = form_state.beneficiaries.filter(
    (dependant) => dependant.key !== key
  );
};

const formatRuts = (key) => {
  const rut = form_state.beneficiaries[key].rut;
  form_state.beneficiaries[key].rut = rutHelper.formatRut(rut);
  searchDependant(key, rutHelper.normalizeRut(rut));
};

const moveBeneficiaryDown = (index) => {
  const element = form_state.beneficiaries[index];
  form_state.beneficiaries.splice(index, 1);
  form_state.beneficiaries.splice(index + 1, 0, element);
};

const moveBeneficiaryUp = (index) => {
  const element = form_state.beneficiaries[index];
  form_state.beneficiaries.splice(index, 1);
  form_state.beneficiaries.splice(index - 1, 0, element);
};

const checkRut = async (rule, value) => {
  if (!value) {
    const error_message = 'Ingrese RUT';
    return Promise.reject(error_message);
  }
  if (!rutHelper.validateRut(value)) {
    return Promise.reject('RUT no válido');
  }
  return Promise.resolve();
};

const percentageValidator = async (rule, value) => {
  if (typeof value !== 'number') {
    return Promise.reject('Ingrese un valor numerico');
  }

  if (value === 0) {
    return Promise.reject('Porcentaje debe ser mayor a 0%');
  }

  return Promise.resolve();
};

const formSubmit = (step) => {
  form_ref.value
    .validate()
    .then(() => {
      let error = false;
      if (form_state.beneficiaries.length > 0) {
        if (totalPercentage.value < 100) {
          notification(
            'error',
            'Error',
            'La sumatoria del porcentaje ingresado para los beneficiarios es menor al 100%'
          );
          error = true;
        }

        if (totalPercentage.value > 100) {
          notification(
            'error',
            'Error',
            'La sumatoria del porcentaje ingresado para los beneficiarios es mayor al 100%'
          );
          error = true;
        }

        if (isRutRepeated.value === true) {
          notification(
            'error',
            'Error',
            'Ya existe un beneficiario con el mismo rut'
          );
          error = true;
        }
      }

      if (error === false) {
        putBeneficiaries(step);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const putBeneficiaries = async (step) => {
  loading.value = true;
  try {
    const beneficiaries = form_state.beneficiaries.map((beneficiary) => ({
      rut: rutHelper.normalizeRut(beneficiary.rut),
      firstName: beneficiary.firstName,
      secondName: beneficiary.secondName,
      firstSurname: beneficiary.firstSurname,
      secondSurname: beneficiary.secondSurname,
      relationship: beneficiary.relationship,
      dateOfBirth: moment(beneficiary.dateOfBirth, 'YYYY-MM-DD'),
      benefitPercent: beneficiary.benefitPercent,
      email: beneficiary.email
    }));

    await store.dispatch(PUT_BENEFICIARIES, beneficiaries);
    store.dispatch(ACTION_CHANGE_STEP, step);
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
  loading.value = false;
};

const searchDependant = (index, rut) => {
  const parseDependentRelationship = {
    SPOUSE: 'Cónyuge',
    COHABITANT: 'Conviviente',
    CHILD: 'Hijo(a)',
    PARENT: 'Padre/Madre',
    GRANDCHILD: 'Nieto(a)',
    SIBLING: 'Hermano(a)'
  };
  const dependant = dependants.find((d) => d.rut === rut);
  const formBeneficiary = form_state.beneficiaries[index];
  formBeneficiary.firstName = dependant?.firstName;
  formBeneficiary.relationship =
    parseDependentRelationship[dependant?.relationship];
  formBeneficiary.secondName = dependant?.secondName;
  formBeneficiary.firstSurname = dependant?.firstSurname;
  formBeneficiary.secondSurname = dependant?.secondSurname;
  formBeneficiary.dateOfBirth = dependant?.dateOfBirth
    ? moment(dependant.dateOfBirth)
    : undefined;
};

const dependants = reactive([]);

const getDependants = async () => {
  loading.value = true;
  try {
    const resp = await store.dispatch(FETCH_DEPENDANTS);
    resp.forEach((dependant) => {
      dependants.push(dependant);
    });
  } catch (error) {
    console.error(error.response.data.message);
  }
  loading.value = false;
};

const getBeneficiaries = async () => {
  loading_data.value = true;
  form_state.beneficiaries = [];
  try {
    const resp = await store.dispatch(FETCH_BENEFICIARIES);
    form_state.beneficiaries = resp.map((beneficiary) => ({
      key: beneficiary.id,
      rut: rutHelper.formatRut(beneficiary.rut),
      firstName: beneficiary.firstName,
      secondName: beneficiary.secondName,
      firstSurname: beneficiary.firstSurname,
      secondSurname: beneficiary.secondSurname,
      relationship: beneficiary.relationship,
      dateOfBirth: moment(beneficiary.dateOfBirth, 'YYYY-MM-DD'),
      benefitPercent: beneficiary.benefitPercent,
      email: beneficiary.email
    }));
  } catch (error) {
    console.error(error.response.data.message);
  }
  loading_data.value = false;
};

defineExpose({ formSubmit });

onMounted(async () => {
  await getBeneficiaries();
  await getDependants();
});
</script>
