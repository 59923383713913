<template>
  <a-card title="Lista de coberturas" :bodyStyle="'padding:0;'">
    <a-table :dataSource="table_data" :columns="columns" :pagination="false">
      <template #action="{ record }">
        <span style="display: flex">
          <a-tooltip color="#05045D" title="Editar">
            <a-button type="link" @click="editCoverage(record.key)">
              <EditOutlined class="blue-icon" />
            </a-button>
          </a-tooltip>
          <a-tooltip color="#05045D" title="Eliminar">
            <a-button type="link" @click="deleteCoverage(record.key)">
              <DeleteOutlined class="delete-holding-text" />
            </a-button>
          </a-tooltip>
        </span>
      </template>
    </a-table>
  </a-card>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons-vue';

const props = defineProps({
  data: {
    type: Array,
    default: []
  },
  groupName: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['openEditCoverage', 'openDeleteCoverage']);

const table_data = computed(() => {
  return props.data.map((coverage) => ({
    key: coverage.id,
    id: coverage.id,
    name: coverage.name,
    action: coverage.id
  }));
});

const columns = [
  {
    title: 'Cobertura',
    width: 100,
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Acciones',
    width: 100,
    dataIndex: 'action',
    key: 'action',
    slots: {
      customRender: 'action'
    }
  }
];

const editCoverage = (coverageId) => {
  emit('openEditCoverage', coverageId);
};

const deleteCoverage = (coverageId) => {
  emit('openDeleteCoverage', coverageId);
};
</script>
