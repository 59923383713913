<template>
  <div>
    <a-modal v-model:visible="visible" title="Nuevo holding" :closable="!loading" :maskClosable="!loading">
      <a-form ref="formRef" :model="formState" :rules="rules" layout="vertical">
        <a-form-item ref="name" label="Nombre o razón social holding" name="name" has-feedback>
          <a-input v-model:value="formState.name" placeholder="Ingresa un nombre" />
        </a-form-item>
        <a-form-item ref="rut" label="RUT Holding" name="rut" has-feedback>
          <a-input v-model:value="formState.rut" placeholder="Ingresa el RUT" />
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button :disabled="loading">Cancelar</a-button>
        <a-button type="primary" @click="onOk" :loading="loading">Agregar</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import modulo11 from '@/utils/rut';
import notification from '@/utils/notifications';
import { POST_CREATE_HOLDING } from '@/store/types';

export default {
  name: 'HoldingCreationModal',
  setup(_, { emit }) {
    // * Variables
    const visible = ref(false);
    const loading = ref(false);
    const store = useStore();

    // * Funciones Modal
    const openModal = () => {
      console.log('openModal');
      visible.value = true;
    };

    const checkRut = async (rule, value) => {
      if (!value) {
        return Promise.reject('Ingrese RUT');
      }
      if (modulo11(value)) {
        return Promise.resolve();
      }
      return Promise.reject('RUT no válido');
    };

    const onOk = () => {
      validateHoldingForm();
    };

    // * Form
    const formRef = ref();
    const formState = reactive({
      name: '',
      rut: ''
    });
    const rules = {
      name: [
        {
          required: true,
          message: 'Ingrese nombre',
          trigger: 'change'
        }
      ],
      rut: [
        {
          required: true,
          validator: checkRut,
          trigger: 'change'
        }
      ]
    };

    const createHolding = async (values) => {
      loading.value = true;
      try {
        const hold_obj = {
          rut: values.rut,
          businessName: values.name
        };

        await store.dispatch(POST_CREATE_HOLDING, hold_obj);

        notification('success', 'Correcto', 'Holding Creado.');
        visible.value = false;
        emit('holdingCreated', true);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }

      loading.value = false;
    };

    const validateHoldingForm = async () => {
      try {
        const validation = await formRef.value.validate();
        createHolding(validation);
      } catch (error) {
        console.error(error);
      }
    };

    return {
      visible,
      formRef,
      formState,
      rules,
      loading,
      onOk,
      openModal
    };
  }
};
</script>
