<template>
  <div>
    <div class="mt-5 mb-3">
      <a-typography-title
        :level="4"
        class="text-gray-8 mb-0 left-aligned payroll-title"
      >
        {{ insurance_company_name }}
      </a-typography-title>

      <a-tag class="ml-3 mt-1" :color="insurance_company_type.color">
        {{ insurance_company_type?.text?.toUpperCase() }}
      </a-tag>
    </div>

    <div>
      <!-- Validar que si se pueda subir al precobranza -->
      <a-radio-group v-model:value="radio_selection" size="large">
        <a-radio-button value="cobranza">Cobranza</a-radio-button>
        <a-radio-button
          value="facturacion"
          :disabled="
            can_upload_invoices === false &&
            insurance_company_type.text === 'Pre-Cobranza'
          "
          >Facturación</a-radio-button
        >
      </a-radio-group>

      <div class="right-aligned">
        <div
          style="display: inline-block"
          v-if="radio_selection === 'facturacion'"
        >
          <bill-file-upload
            :collection-type="type"
            :insurance-id-to-upload="insurance_company_id"
            :collection-id-to-upload="coll_form_id"
            @postCollectionFormInsuranceCompanyInvoices="reloadTable"
            @uploadDummyFile="uploadDummyFile"
          />
          <modal-send-invoices
            ref="human_send_ref"
            v-if="
              insurance_company_type.text == 'Pre-Cobranza' &&
              validateApprovedSendHumanInsurance
            "
            :insurance-id="insurance_company_id"
            :collection-id="coll_form_id"
          />
        </div>
        <div style="display: inline-block" class="ml-4">
          <a-tooltip @click="reloadTable">
            <template #title>Recargar Tabla</template>
            <a-button>
              <template #icon>
                <reload-files-upload />
              </template>
            </a-button>
          </a-tooltip>
        </div>
      </div>

      <div style="clear: both"></div>
    </div>

    <div class="mt-3">
      <div v-if="radio_selection === 'cobranza'">
        <collection-table :data-source="policy_list" />
      </div>
      <div v-else>
        <bill-table
          ref="bill_table_ref"
          :collection-form-id="coll_form_id"
          :collection-type="type"
          :insurance-company-id="insurance_company_id"
          :collection-start-form-state="collectionStartFormState"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ReloadOutlined } from '@ant-design/icons-vue';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import {
  GET_INSURANCE_COMPANY_INVOICE_UPLOAD_VALIDATION,
  FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_POLICIES
} from '../../../store/types';
import CollectionDataTable from '../detail/collection/CollectionDataTable.vue';
import BillDataTable from './bill/BillDataTable.vue';
import BillFilesUpload from './bill/BillFilesUpload.vue';
import ModalSendInvoices from './bill/ModalSendInvoices.vue';
import notification from '@/utils/notifications';

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    id: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    collectionFormId: {
      type: [String, Number],
      default: ''
    },
    validateApproved: {
      type: Boolean,
      default: false
    },
    collectionStartFormState: {
      type: String,
      default: ''
    }
  },
  components: {
    'collection-table': CollectionDataTable,
    'bill-table': BillDataTable,
    'bill-file-upload': BillFilesUpload,
    'reload-files-upload': ReloadOutlined,
    'modal-send-invoices': ModalSendInvoices
  },
  setup(props) {
    // * Variables
    const store = useStore();
    const radio_selection = ref('cobranza');
    const statusMessageError = ref('');
    const human_send_ref = ref();

    // * Facturacion
    const bill_table_ref = ref();
    const reloadTable = async () => {
      bill_table_ref.value.getInsuranceCompanyInvoices();
    };

    const uploadDummyFile = (dummy_file) => {
      bill_table_ref.value.uploadDummyFile(dummy_file);
    };

    // * Data
    const insurance_company_name = computed(() => props.name);
    const insurance_company_id = computed(() => props.id);
    const coll_form_id = computed(() => props.collectionFormId);
    const insurance_company_type = computed(() => {
      return {
        color: props.type === 'COLLECTION' ? 'red' : 'blue',
        text: props.type === 'COLLECTION' ? 'Cobranza' : 'Pre-Cobranza'
      };
    });
    const validateApprovedSendHumanInsurance = computed(
      () => props.validateApproved
    );
    const can_upload_invoices = ref(false);

    // * Obtencion de data principal
    const policy_list = ref([]);
    const getPolicies = async () => {
      try {
        const { data } = await store.dispatch(
          FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_POLICIES,
          {
            collection_id: props.collectionFormId,
            insurance_id: insurance_company_id.value,
            type: props.type
          }
        );

        policy_list.value = data;
      } catch (error) {
        notification('error', 'Error', error.data.response.message);
      }
    };

    const insuranceCompanyValidation = async () => {
      try {
        const resp = await store.dispatch(
          GET_INSURANCE_COMPANY_INVOICE_UPLOAD_VALIDATION,
          {
            collection_group_id: props.collectionFormId,
            insurance_id: insurance_company_id.value
          }
        );

        can_upload_invoices.value = resp.data.canUploadInvoices;
      } catch (error) {
        notification('error', 'Error', error.data.response.message);
      }
    };

    // * Obtencion de data principal
    onMounted(() => {
      getPolicies();
      insuranceCompanyValidation();
    });

    return {
      insurance_company_name,
      insurance_company_id,
      insurance_company_type,
      coll_form_id,
      radio_selection,
      policy_list,
      bill_table_ref,
      reloadTable,
      can_upload_invoices,
      validateApprovedSendHumanInsurance,
      human_send_ref,
      statusMessageError,
      uploadDummyFile
    };
  }
};
</script>
