<template>
  <a-modal v-model:visible="success_modal" centered :footer="false" title="Resumen de traspaso de póliza">
    <a-result status="success" title="Ticket creado"
      sub-title="Tu ticket de traspaso de póliza ha sido creada con éxito.">
      <template #extra>
        <div class="justify-content-center d-flex">
          <a-button class="justify-content-center d-flex" type="primary" @click="redirectToDetail">
            Ver Ticket
          </a-button>
        </div>
      </template>
    </a-result>
  </a-modal>
</template>

<script setup>
//TODO: remove this file and make a component
import { ref, defineExpose } from 'vue';
import { useRouter } from 'vue-router';

const success_modal = ref(false);
const router = useRouter();
const id_ticket = ref(0);

const openSuccessModal = (id) => {
  id_ticket.value = id;
  success_modal.value = true;
};

const closeSuccessModal = () => {
  success_modal.value = false;
};

const redirectToDetail = () => {
  router.push({ name: 'TransferDetail', params: { id: id_ticket.value } });
  closeSuccessModal();
};

defineExpose({ openSuccessModal });
</script>
