import { getDependents } from '../../network';
import { FETCH_DEPENDENTS_WITH_PARAMS } from './types';

const store = {};
const getDefaultState = () => ({});

store.state = getDefaultState();

store.getters = {};

store.mutations = {};

store.actions = {
  async [FETCH_DEPENDENTS_WITH_PARAMS](_, query) {
    const response = await getDependents(query);
    return response.data;
  }
};

export default store;
