<template>
  <div>
    <a-typography-title :level="4">Notificaciones</a-typography-title>

    <div v-if="loading">
      <a-skeleton active />
    </div>
    <div v-else>
      <div v-for="(d, index) in Object.keys(data)" :key="index">
        <div style="padding-top: 30px">
          <a-table :data-source="data[d]" :columns="columns" :scroll="{ x: 1000 }" :pagination="false">
            <template #customName>
              <b>{{ d }}</b>
            </template>
            <template #customSecuencia>
              <b>Secuencia</b>
            </template>
            <template #customAction>
              <b>Accion</b>
            </template>
            <template #secuency="{ text }">
              <p>
                Se repite {{ text.repetition }} veces cada {{ text.days }} dias
              </p>
            </template>
            <template #action="{ text }">
              <a @click="openConfigurationModal(text.id, data[d])">Configurar</a>
            </template>
          </a-table>
        </div>
      </div>
    </div>

    <!-- Modal de configuracion -->
    <a-modal v-model:visible="visible_modal" centered @cancel="cancelModal" @ok="okModal"
      title="Configuracion de notificaciones" :closable="!loading" :maskClosable="!loading">
      <p>
        Configura la secuencia de repeticiones y días en los que se enviaran las
        notificaciones.
      </p>
      <b>Documentos completos</b>

      <div class="mt-4">
        <a-row>
          <a-col :span="6">
            <p>Repeticiones</p>
            <a-input-number id="repetition" v-model:value="repetition" :min="0" :max="30" />
          </a-col>
          <a-col :span="12">
            <p>Días</p>
            <a-input-number id="days" v-model:value="days" :min="0" :max="30" />
          </a-col>
        </a-row>
      </div>

      <p class="mt-3">Se repite {{ repetition }} veces cada {{ days }} dias</p>

      <template #footer>
        <a-button key="back" @click="cancelModal" :disabled="loading">Cancelar</a-button>
        <a-button key="submit" @click="okModal" type="primary" :disabled="button_disabled"
          :loading="loading">Guardar</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  FETCH_INSURANCE_COMPANY_NOTIFICATION,
  GET_INSURANCE_COMPANY_NOTIFICATION,
  PUT_INSURANCE_NOTIFICATION
} from '@/store/types';
import notifications from '@/utils/notifications';
import delay from '@/utils/delay';

export default {
  name: 'Notificaciones',
  setup() {
    // * Obtencion de data principal
    const route = useRoute();
    const store = useStore();

    const company_id = route.params.id;

    store
      .dispatch(FETCH_INSURANCE_COMPANY_NOTIFICATION, company_id)
      .then((resp) => {
        console.log(resp);
      });

    const data = computed(() => {
      const final_data = {};
      const resp = store.getters[GET_INSURANCE_COMPANY_NOTIFICATION];

      for (let i = 0; i < resp.length; i++) {
        const element = resp[i];

        const new_object = {
          key: i + 1,
          name: element.name,
          secuency: {
            repetition:
              element.schedule != null ? element.schedule.repeatsPerDay : 0,
            days: element.schedule != null ? element.schedule.days : 0
          },
          action: {
            id: element.id
          }
        };

        if (!(element.group_name in final_data)) {
          final_data[element.group_name] = [new_object];
        } else {
          final_data[element.group_name].push(new_object);
        }
      }

      return final_data;
    });

    // * Tablas
    const columns = [
      {
        dataIndex: 'name',
        key: 'name',
        slots: {
          title: 'customName'
        }
      },
      {
        dataIndex: 'secuency',
        key: 'secuency',
        slots: {
          title: 'customSecuencia',
          customRender: 'secuency'
        }
      },
      {
        dataIndex: 'action',
        key: 'action',
        slots: {
          title: 'customAction',
          customRender: 'action'
        }
      }
    ];

    // * Configuracion de notificaciones
    const visible_modal = ref(false);
    const loading = ref(false);
    const repetition = ref(0);
    const days = ref(0);
    const id_configuration = ref('');

    const openConfigurationModal = (id, value) => {
      // Paso el id del que estoy editando
      id_configuration.value = id;

      // Abrir el modal
      visible_modal.value = true;

      // Paso los dias y la repetition

      const result = value.find((obj) => obj.key == id);
      repetition.value = result.secuency.repetition;
      days.value = result.secuency.days;
    };

    const cancelModal = () => {
      // Cierro el modal
      visible_modal.value = false;

      // Dejo los la cantidad de repeticiones y botones en 0
      repetition.value = 0;
      days.value = 0;
    };

    const okModal = async () => {
      loading.value = true;

      const post_data = {
        id_company: company_id,
        id_notification: id_configuration.value,
        repetition: repetition.value,
        days: days.value
      };

      try {
        await store.dispatch(PUT_INSURANCE_NOTIFICATION, post_data);

        await delay(1000);

        notifications('success', 'Correcto', 'Secuencia guardada con éxito');
      } catch (error) {
        notifications(
          'error',
          'Error',
          'Ocurrio un error, vuelve a intentarlo mas tarde'
        );
      }

      loading.value = false;
      cancelModal();
    };

    const button_disabled = computed(() => {
      return !(repetition.value > 0 || days.value > 0);
    });

    return {
      columns,
      visible_modal,
      cancelModal,
      okModal,
      openConfigurationModal,
      repetition,
      days,
      button_disabled,
      loading,
      data
    };
  }
};
</script>
