import { newConfig } from '../config';
import config from '../config';

const appConfig = config();
const { v2 } = appConfig.apiVersion;

export default class CompanyService {
  constructor(client) {
    this.client = client;
  }

  async getEmployees(companyId, query) {
    return this.client.get(
      `${newConfig.services.proxy.url}${newConfig.services.proxy.core}/${v2}/companies/${companyId}/employees`,
      { params: query }
    );
  }
}
