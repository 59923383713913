export const FETCH_CONSENT_TEXT = 'insured/FETCH_CONSENT_TEXT';
export const FETCH_CARNET = 'insured/FETCH_CARNET';
export const FETCH_TICKET_ID = 'insured/FETCH_TICKET_ID';
export const FETCH_USER_LOADS = 'insured/FETCH_USER_LOADS';
export const FETCH_DATA_TICKET = 'insured/FETCH_DATA_TICKET';
export const FETCH_INSURANCE_DOCUMENTS = 'insured/FETCH_INSURANCE_DOCUMENTS';

export const POST_CARNET = 'insured/POST_CARNET';
export const POST_USER_LOADS = 'insured/POST_USER_LOADS';
export const SAVE_CONSENT_DATA = 'insured/SAVE_CONSENT_DATA';
export const POST_FILE_INSURANCE_COMPANY =
  'insured/POST_FILE_INSURANCE_COMPANY';

export const PATCH_CONSENT_TEXT = 'insured/PATCH_CONSENT_TEXT';
export const PATCH_INSURED_EMPLOYEE = 'insured/PATCH_INSURED_EMPLOYEE';

export const DELETE_FILE_ANV = 'insured/DELETE_FILE_ANV';
export const DELETE_USER_LOADS = 'insured/DELETE_USER_LOADS';

export const RESET_STATE = 'insured/RESET_STATE';

export const SET_TICKET_ID = 'insured/SET_TICKET_ID';
export const SET_CONSENT_TEXT = 'insured/SET_CONSENT_TEXT';
export const SET_REV = 'insured/SET_REV';
export const SET_ANV = 'insured/SET_ANV';
export const SET_DATA_TICKET = 'insured/SET_DATA_TICKET';
export const SET_INSURANCE_DOCUMENT = 'insured/SET_INSURANCE_DOCUMENT';

export const GET_CONSENT_TEXT = 'insured/GET_CONSENT_TEXT';
export const GET_POLIZAS = 'insured/GET_POLIZAS';
export const GET_DATA_TICKET = 'insured/GET_DATA_TICKET';
export const GET_INSURANCE_DOCUMENTS = 'insured/GET_INSURANCE_DOCUMENTS';
