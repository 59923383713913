<template>
  <a-tag :color="attributes.color">
    {{ attributes.text }}
    <slot v-if="stateId == undefined || stateId == ''"> </slot>
  </a-tag>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'Tags',
  props: {
    stateId: {
      type: [String, Number],
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const id_map = {
      1: {
        name: 'CREADO',
        color: 'processing'
      },
      2: {
        name: 'PENDIENTE',
        color: 'warning'
      },
      3: {
        name: 'EJECUTADO',
        color: 'success'
      },
      4: {
        name: 'CANCELADO',
        color: 'error'
      },
      5: {
        name: 'FINALIZADO',
        color: 'success'
      },
      6: {
        name: 'EN APELACIÓN',
        color: 'warning'
      },
      7: {
        name: 'EN CORREDORA',
        color: 'warning'
      },
      8: {
        name: 'EN ASEGURADORA',
        color: 'warning'
      },
      9: {
        name: 'EN RESOLICITUD',
        color: 'warning'
      },
      10: {
        name: 'APROBADO',
        color: 'success'
      },
      11: {
        name: 'RECHAZADO',
        color: 'error'
      },
      12: {
        name: 'CANCELAR',
        color: 'error'
      },
      13: {
        name: 'VALIDACIÓN CORREDORA',
        color: 'warning'
      },
      14: {
        name: 'NO CUADRA',
        color: 'error'
      },
      15: {
        name: 'CUADRA',
        color: 'success'
      },
      16: {
        name: 'ERROR',
        color: 'success'
      },
      17: {
        name: 'VALIDO',
        color: 'success'
      }
    };

    // * Props
    const attributes = computed(() => {
      // Busco si el se agrego la propiedad state-id, de ser asi busco el objeto
      const data = id_map[props.stateId];

      let color = '';
      let text = '';

      if (data == undefined) {
        color = props.color == '' ? 'default' : props.color;
      } else {
        color = data.color;
        text = data.name;
      }

      return {
        text,
        color
      };
    });

    return {
      attributes
    };
  }
};
</script>
