<template>
  <div>
    <a-table v-bind="table_props" v-if="table_props.dataSource.length > 0">
      <template #tags="{ text: tags }">
        <a-tag :color="getColorState(tags)">{{ getTagText(tags)?.toUpperCase() }}</a-tag>
      </template>
      <template #details="{ text: id }">
        <a-tooltip placement="topLeft" title="Ir a la solicitud">
          <router-link
            :to="{
              name: 'RetroActiveAdjustmentDetail',
              params: { id }
            }"
          >
            Ver solicitud
          </router-link>
        </a-tooltip>
      </template>
    </a-table>
    <a-typography-text v-else>
      No hay solicitudes de ajuste retroactivo para ese periodo
    </a-typography-text>
  </div>
</template>

<script>
import { reactive, watch } from 'vue';
import stateColor from '@/utils/collectionBillingStateColor';
import getTagText from '@/utils/getTagText';

export default {
  name: 'RetroactiveAdjustmentTable',
  props: {
    data: {
      type: Array,
      default: []
    }
  },
  setup(props) {
    const table_props = reactive({
      dataSource: props.data,
      columns: [
        {
          dataIndex: 'name',
          slots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'tags',
          slots: {
            customRender: 'tags'
          }
        },
        {
          dataIndex: 'details',
          slots: {
            customRender: 'details'
          }
        }
      ],
      showHeader: false,
      pagination: false
    });

    watch(table_props.dataSource, (data) => {
      if (data.length > 5) {
        table_props.pagination = {
          pageSize: 5
        };
      } else {
        table_props.pagination = false;
      }
    });

    const getColorState = (value) => {
      return stateColor(value);
    };

    return {
      table_props,
      getColorState,
      getTagText
    };
  }
};
</script>
