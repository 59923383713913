<template>
  <a-modal
    title="Añadir coberturas"
    v-model:visible="visible"
    @cancel="onCloseModal"
    :closable="!loading_add_coverages"
    :maskClosable="!loading_add_coverages"
  >
    <a-form
      name="form"
      ref="form_ref"
      :model="form_state"
      layout="vertical"
      :rules="rules"
    >
      <a-form-item name="name" ref="name" label="Nombre">
        <a-input
          v-model:value="form_state.name"
          placeholder="Ingresa nombre cobertura"
          :maxlength="250"
        />
      </a-form-item>
      <a-form-item
        name="collectionName"
        ref="collectionName"
        label="Nombre cobranza"
      >
        <a-input
          v-model:value="form_state.collectionName"
          placeholder="Ingresa nombre cobertura para cobranza"
          :maxlength="250"
        />
      </a-form-item>
      <a-form-item name="group" ref="group" label="Seleccionar grupo">
        <a-select
          ref="group"
          :options="groups_options"
          v-model:value="form_state.group"
          mode="multiple"
          placeholder="Selecciona los grupos"
        />
      </a-form-item>

      <a-row type="flex" justify="space-between">
        <a-form-item ref="required" name="required">
          <a-checkbox v-model:checked="form_state.required">
            Cobertura obligatoria
          </a-checkbox>
        </a-form-item>
        <a-button
          type="primary"
          :disabled="disableAddButton"
          @click="addCoverage"
        >
          Añadir
        </a-button>
      </a-row>
    </a-form>

    <div v-for="c in coverages_list" :key="c.id" class="mt-3">
      <div style="display: flex; justify-content: space-between">
        <div style="width: 50%">
          <p>
            <b>{{ c.name }}</b> ({{
              c.group
                .map((g) => groups_options.find((gs) => gs.value === g).label)
                .join(', ')
            }}) {{ c.required ? '(requerido)' : '' }}
          </p>
        </div>
        <div>
          <a-button @click="deleteCoverFromList(c)" type="link" danger>
            <template #icon>
              <DeleteOutlined />
            </template>
            Eliminar
          </a-button>
        </div>
      </div>
    </div>

    <template #footer>
      <a-button @click="closeModal" :disabled="loading_add_coverages"
        >Cancelar</a-button
      >
      <a-button
        type="primary"
        @click="formSubmit"
        :disabled="disableSavebutton"
        :loading="loading_add_coverages"
        >Guardar</a-button
      >
    </template>
  </a-modal>
</template>

<script setup>
import {
  defineExpose,
  defineEmits,
  defineProps,
  ref,
  reactive,
  computed
} from 'vue';
import { DeleteOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import notification from '@/utils/notifications';
import { POST_COMPANY_POLICY_COVERAGES } from '@/store/types';

const props = defineProps({
  groups: {
    type: Array,
    default: []
  }
});

const emit = defineEmits(['coverageCreated']);

const store = useStore();
const route = useRoute();
const groups_options = computed(() => {
  return props.groups.map((group) => ({
    label: group.name,
    value: group.id
  }));
});
const visible = ref(false);
const form_ref = ref();
const form_state = reactive({
  name: null,
  collectionName: null,
  group: [],
  required: false
});
const coverages_list = ref([]);
const rules = {
  name: [
    {
      required: true,
      message: 'Ingrese nombre de la cobertura',
      trigger: 'change',
      type: 'string'
    }
  ],
  collectionName: [
    {
      required: false,
      message: 'Ingrese nombre de la cobertura para la cobranza',
      trigger: 'change',
      type: 'string'
    }
  ],
  group: [
    {
      required: true,
      message: 'Seleccionar grupo',
      trigger: 'change',
      type: 'array'
    }
  ]
};

const openModal = () => {
  visible.value = true;
};

const closeModal = () => {
  visible.value = false;
  onCloseModal();
};

const loading_add_coverages = ref();
const formSubmit = async () => {
  if (coverages_list.value.length > 0) {
    loading_add_coverages.value = true;
    try {
      const payload = {
        coverages: coverages_list.value.map((coverage) => {
          return {
            policyId: route.params.policyId,
            name: coverage.name,
            collectionName: coverage.collectionName || null,
            groupCompanyIds: coverage.group,
            required: coverage.required
          };
        })
      };
      await store.dispatch(POST_COMPANY_POLICY_COVERAGES, payload);
      closeModal();
      notification(
        'success',
        'Correcto',
        'Coberturas agregadas correctamente  '
      );
      emit('coverageCreated', coverages_list.value);
    } catch (error) {
      notification('error', 'Error', error.response.data.message);
    }

    loading_add_coverages.value = false;
  } else {
    notification('error', 'Error', 'Agregue coberturas');
  }
};

const onCloseModal = () => {
  form_ref.value.resetFields();
  coverages_list.value = [];
};

const deleteCoverFromList = (coverage) => {
  coverages_list.value = coverages_list.value.filter((x) => x !== coverage);
};

const disableAddButton = computed(() => {
  return (
    form_state.name === '' ||
    form_state.name === null ||
    form_state.group.length === 0
  );
});

const addCoverage = () => {
  form_ref.value.validate().then((resp) => {
    const found_coverage = coverages_list.value.find(
      (coverage) => coverage.name === resp.name
    );
    if (!found_coverage) {
      coverages_list.value.push(resp);
      form_ref.value.resetFields();
    } else {
      notification('error', 'Error', 'La cobertura ya esta agregada');
    }
  });
};

const disableSavebutton = computed(() => {
  return coverages_list.value.length === 0;
});

defineExpose({ openModal });
</script>

<style scoped>
.delete-cover {
  color: red;
}
</style>
