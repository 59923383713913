<template>
  <page-header :title="dataCompany?.businessName" :breadcrumRoutes="routes" />

  <a-row :gutter="16">
    <a-col :lg="6">
      <a-card>
        <div style="text-align: center" class="mb-3">
          <a-row type="flex" justify="center">
            <CompanyAvatar :company-id="insurerId" />
          </a-row>
          <b style="font-size: 20px">
            {{ dataCompany?.businessName ? dataCompany?.businessName : '-' }}
          </b>
        </div>

        <a-row justify="space-between" align="middle">
          <ShopOutlined />
          <a-button type="link" @click="showModal">
            <EditOutlined />
            Editar
          </a-button>
        </a-row>

        <a-divider style="margin: 12px 0px" />

        <div>
          <b>Rut empresa</b>
          <p>
            {{ rutHelper.formatRut(dataCompany?.rut ? dataCompany?.rut : '-') }}
          </p>
        </div>
        <div>
          <b>Razón social empresa</b>
          <p>
            {{ dataCompany?.businessName ? dataCompany?.businessName : '-' }}
          </p>
        </div>
        <div>
          <b>ID</b>
          <p># {{ dataCompany?.id ? dataCompany?.id : '-' }}</p>
        </div>

        <UserOutlined />

        <a-divider style="margin: 12px 0px" />

        <div>
          <b>Ejecutivo comercial</b>
          <p>
            {{
              dataCompany.insuranceExecutive.name !== ''
                ? dataCompany.insuranceExecutive.name
                : 'Sin Asignar'
            }}
          </p>
        </div>
        <div>
          <b>Correo electrónico</b>
          <p>
            {{
              dataCompany.insuranceExecutive.email !== ''
                ? dataCompany.insuranceExecutive.email
                : 'Sin Asignar'
            }}
          </p>
        </div>
        <div>
          <b>Teléfono</b>
          <p>
            {{
              dataCompany.insuranceExecutive.phone !== ''
                ? dataCompany.insuranceExecutive.phone
                : 'Sin Asignar'
            }}
          </p>
        </div>

        <MailOutlined />

        <a-divider style="margin: 12px 0px" />

        <b>Tipo de notificación</b>
        <p>Correo electrónico</p>
      </a-card>
    </a-col>
    <a-col :lg="18">
      <a-card class="card-extended">
        <a-tabs :style="{ zIndex: 0 }" v-model:activeKey="active_key">
          <a-tab-pane tab="Formularios incorporación" key="1">
            <incorporation-form />
          </a-tab-pane>
          <a-tab-pane tab="Ejecutivos" key="2">
            <ejecutivos @refreshExecutive="updateExecutive" />
          </a-tab-pane>
          <a-tab-pane tab="Recepción de solicitudes" key="3">
            <mail-reception />
          </a-tab-pane>
          <a-tab-pane tab="Manual de uso" key="4">
            <user-manual />
          </a-tab-pane>
          <a-tab-pane tab="Notificaciones" key="5">
            <notificaciones />
          </a-tab-pane>
        </a-tabs>
      </a-card>
    </a-col>
  </a-row>
  <a-modal v-model:visible="visible" title="Editar aseguradora" @ok="handleOk" centered :closable="!loading"
    :maskClosable="!loading">
    <a-form layout="vertical" name="form" ref="formRef" :model="formState" :rules="rules">
      <span class="text-gray-8 fw-regular"> RUT Aseguradora </span>
      <a-form-item name="rut">
        <a-input class="mt-2" placeholder="Ingresa RUT Aseguradora" v-model:value="formState.rut" :maxlength="13" />
      </a-form-item>
      <span class="text-gray-8 fw-regular"> Nombre / Razón social </span>
      <a-form-item name="name      </div>
">
        <a-input class="mt-2" placeholder="Ingresa Nombre o Razón social" v-model:value="formState.name"
          :maxlength="50" />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button key="back" @click="handleOk" :disabled="loading">Cancelar</a-button>
      <a-button key="submit" @click="aseguradoraUpdate" :loading="loading" :disabled="disableUpdate()">
        Editar
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PageHeader from '@/components/PageHeader.vue';
import {
  ShopOutlined,
  EditOutlined,
  UserOutlined,
  MailOutlined
} from '@ant-design/icons-vue';
import {
  FETCH_INSURANCES_COMPANY_ID,
  GET_COMPANY_ID,
  PUT_INSURANCECOMPANY
} from '@/store/types';
import rutHelper from '@/utils/Ruts';
import openNotificationWithIcon from '@/utils/notifications';
import MailReception from './components/MailReception.vue';
import Ejecutivos from './components/Ejecutivos.vue';
import UserManual from './components/UserManual.vue';
import IncorporationForm from './components/IncorporationForm.vue';
import Notificaciones from './components/Notificaciones.vue';
import CompanyAvatar from '@/components/CompanyAvatar.vue';

export default {
  name: 'AseguradorasDetail',
  components: {
    MailOutlined,
    UserOutlined,
    ShopOutlined,
    EditOutlined,
    'page-header': PageHeader,
    'mail-reception': MailReception,
    'incorporation-form': IncorporationForm,
    ejecutivos: Ejecutivos,
    'user-manual': UserManual,
    notificaciones: Notificaciones,
    CompanyAvatar
  },
  setup() {
    // * VUEX
    const store = useStore();

    // * Router
    const route = useRoute();
    const router = useRouter();
    const insurerId = route.params.id; // Id de la aseguradora que obtengo por url

    // * Data de la aseguradora
    onMounted(() => {
      store.dispatch(FETCH_INSURANCES_COMPANY_ID, insurerId).catch(() => {
        router.push({ name: 'Missing' });
      });
    });

    const dataCompany = computed(() => {
      return store.getters[GET_COMPANY_ID];
    });

    const nameCompany = computed(
      () => store.getters[GET_COMPANY_ID].businessName
    );

    const updateExecutive = async () => {
      await store.dispatch(FETCH_INSURANCES_COMPANY_ID, insurerId);
    };

    // * Page header
    const routes = ref([
      {
        path: '/aseguradora',
        breadcrumbName: 'Aseguradora'
      },
      {
        breadcrumbName: nameCompany
      }
    ]);

    // * Tabs
    const active_key = ref('1');

    // * Editar Compañía Aseguradora
    const formRef = ref();
    const formState = reactive({
      id: null,
      rut: '',
      name: ''
    });
    const formBackup = reactive({
      rut: '',
      name: ''
    });

    const visible = ref(false);
    const loading = ref(false);
    const showModal = () => {
      formState.id = null;
      formState.rut = '';
      formState.name = '';

      formBackup.rut = dataCompany.value.rut;
      formBackup.name = dataCompany.value.businessName;
      formState.id = insurerId;
      formState.rut = dataCompany.value.rut;
      formState.name = dataCompany.value.businessName;

      visible.value = true;
    };

    const handleOk = () => {
      visible.value = false;
    };

    const disableUpdate = () => {
      let disabled = true;
      if (
        formState.rut.length >= 8 &&
        formState.name.length >= 3 &&
        (formState.name !== formBackup.name || formState.rut !== formBackup.rut)
      ) {
        disabled = false;
      }
      return disabled;
    };

    const checkRut = async (rule, value) => {
      let error_message = '';
      if (!value) {
        error_message = 'Ingrese RUT';
        return Promise.reject(error_message);
      }
      if (rutHelper.validateRut(value)) {
        return Promise.resolve();
      }
      error_message = 'RUT no válido';
      return Promise.reject(error_message);
    };

    const checkName = async (rule, value) => {
      let error_message = '';
      if (!value) {
        error_message = 'Ingrese un nombre o razón social';
        return Promise.reject(error_message);
      }
      if (value.length < 3) {
        const error_message = 'Largo mínimo de 3 caracteres';
        return Promise.reject(error_message);
      }
      return Promise.resolve();
    };

    const rules = {
      rut: [
        {
          required: true,
          type: 'string',
          validator: checkRut,
          trigger: 'change'
        }
      ],
      name: [
        {
          required: true,
          type: 'string',
          validator: checkName,
          trigger: 'change'
        }
      ]
    };

    const cleanForm = () => {
      formState.rut = '';
      formState.name = '';
    };

    const getErrorMessage = (error) => {
      let message = '';
      switch (error) {
        case 'The Insurance name already exists':
          message =
            'Nombre o Razón social de compañía aseguradora ya registrado';
          break;
        case 'The Insurance RUT already exists':
          message = 'El RUT ingresado ya se encuentra registrado';
          break;
        case 'The Insurance RUT and name already exists':
          message =
            'El RUT y la Razón social ingresados ya se encuentran registrados';
          break;
        case 'El nombre para esta aseguradora ya existe':
          message = 'El nombre para esta aseguradora ya existe';
          break;
        case 'El Rut para esta aseguradora ya existe':
          message = 'El Rut para esta aseguradora ya existe';
          break;
        default:
          message = 'No se ha podido generar la operación, intente más tarde';
          break;
      }
      return message;
    };

    const aseguradoraUpdate = async () => {
      loading.value = true;
      const payload = { rut: formState.rut, businessName: formState.name };
      await store
        .dispatch(PUT_INSURANCECOMPANY, {
          insuranceCompanyId: formState.id,
          payload
        })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            store.dispatch(FETCH_INSURANCES_COMPANY_ID, insurerId);
            loading.value = false;
            handleOk();
            openNotificationWithIcon(
              'success',
              'Correcto',
              'Aseguradora Actualizada'
            );
            cleanForm();
          }
        })
        .catch((err) => {
          const responseError = getErrorMessage(err.response.data.message);
          openNotificationWithIcon('error', 'Error', responseError);
          loading.value = false;
        });
    };

    return {
      dataCompany,
      routes,
      active_key,
      visible,
      loading,
      formRef,
      formState,
      rules,
      rutHelper,
      insurerId,
      updateExecutive,
      showModal,
      handleOk,
      disableUpdate,
      aseguradoraUpdate
    };
  }
};
</script>
