<template>
  <div v-if="$route.name === 'BajasCargas'">
    <page-header :breadcrumRoutes="routes_header" title="Bajas cargas">
      <template #buttons>
        <a-button type="primary" @click="showModal">Crear baja</a-button>
      </template>
    </page-header>

    <!-- Resumen de Bajas -->
    <table-resume type="Bajas de cargas" />

    <a-card class="mt-2">
      <a-row type="flex" justify="space-between" align="middle">
        <a-col>
          <a-row class="mt-2">
            <a-typography-title :level="4" class="text-gray-8 mr-5">
              Stock bajas cargas
            </a-typography-title>
          </a-row>
        </a-col>

        <a-range-picker v-model:value="dante_range_individual" :value-format="date_format"
          :format="[date_format, date_format]" />
      </a-row>
      <a-row class="mt-3">
        <a-col :span="24">
          <!-- Baja individual -->
          <tickets-table type="Bajas de cargas" :dates="dante_range_individual" />
        </a-col>
      </a-row>
    </a-card>

    <!-- Modal -->
    <a-modal v-model:visible="modal_visible" title="Nueva solicitud de baja de cargas" centered :maskClosable="false">
      <template #footer>
        <a-row type="flex" justify="end">
          <a-col>
            <a-space>
              <router-link class="justify-content-center d-flex" :to="{ name: 'BajaCargasIndividual' }">
                <a-button key="1" type="primary">Individual </a-button>
              </router-link>
              <router-link class="justify-content-center d-flex" :to="{ name: 'BajaMasiva' }">
                <a-button key="2" type="primary" disabled>Masiva </a-button>
              </router-link>
            </a-space>
          </a-col>
        </a-row>
      </template>
      <a-typography-title class="text-gray-9 fw-regular" :level="3">¿Que tipo de solicitud de baja de cargas deseas
        crear?</a-typography-title>
    </a-modal>
  </div>
  <router-view :key="$route.path"></router-view>
</template>

<script>
import { useRoute } from 'vue-router';
import { ref, defineAsyncComponent, onMounted } from 'vue';
import PageHeader from '@/components/PageHeader.vue';
const TicketsTable = defineAsyncComponent(() =>
  import('@/components/Tables/TicketsTableTest.vue')
);
import TableResume from '@/components/TableResume.vue';

export default {
  name: 'Bajas',
  components: {
    TableResume,
    'page-header': PageHeader,
    'tickets-table': TicketsTable
  },

  setup() {
    // * Route y router
    const route = useRoute();

    // * Page Header
    const routes_header = [
      {
        // path: "first",
        breadcrumbName: 'Bajas cargas'
      }
    ];

    // * Modal
    const modal_visible = ref(false);

    const showModal = () => {
      modal_visible.value = true;
    };

    // * Selector de fechas
    const date_format = 'YYYY-MM-DD';

    const dante_range_individual = ref([]);

    // Cuando cargue la pagina, obtener la fecha y pasarla en el selector
    onMounted(() => {
      if (
        route.query.startDate != undefined &&
        route.query.endDate != undefined
      ) {
        dante_range_individual.value.push(route.query.startDate);
        dante_range_individual.value.push(route.query.endDate);
      }
    });

    return {
      routes_header,
      modal_visible,
      showModal,
      date_format,
      dante_range_individual
    };
  }
};
</script>
