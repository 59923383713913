<template>
  <a-card :loading="loading">
    <a-col>
      <a-row type="flex" align="top">
        <a-col
          ><i style="font-weight: 300"></i>
          <a-typography-text class="text-gray-8 fw-semibold">
            {{ title }}
          </a-typography-text></a-col
        >
      </a-row>
      <a-row>
        <a-col>
          <a-typography-title class="ff-urbanist text-gray-9 mb-0">
            {{ total }}
            <a-typography-text class="fs-xl text-gray-8">{{
              description
            }}</a-typography-text>
          </a-typography-title>
        </a-col>
      </a-row>
    </a-col>
  </a-card>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
    total: [String, Number],
    description: String,
    loading: Boolean
  }
};
</script>
