<template>
  <div v-if="pass_confirm">
    <password-confirm />
  </div>
  <div v-else>
    <div v-if="incorrect_link">
      <a-typography-title
        :level="3"
        class="fw-regular title-underline text-gray-9"
        >Link no válido</a-typography-title
      >
      <router-link class="justify-content-center d-flex" to="/login"
        >Volver al inicio</router-link
      >
    </div>
    <div v-else>
      <a-typography-title
        :level="3"
        class="fw-regular title-underline text-gray-9"
        >Ingresa tu nueva contraseña</a-typography-title
      >

      <a-form
        ref="formRef"
        layout="vertical"
        name="pass-validation"
        :model="formState"
        :rules="rules"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item has-feedback label="Nueva contraseña" name="pass">
          <a-input-password
            v-model:value="formState.pass"
            class="ant_input"
            placeholder="Contraseña"
            :maxlength="15"
          />
        </a-form-item>

        <a-form-item
          has-feedback
          label="Repite tu nueva contraseña"
          name="checkPass"
        >
          <a-input-password
            v-model:value="formState.checkPass"
            class="ant_input"
            placeholder="Contraseña"
            :maxlength="15"
          />
        </a-form-item>

        <a-alert
          v-if="show_error"
          message="Error"
          description="Ocurrio un error, vuelve a intentarlo mas tarde"
          type="error"
        />

        <br />

        <a-form-item>
          <a-button
            type="primary"
            size="large"
            html-type="submit"
            block
            :loading="loading"
            >Guardar</a-button
          >
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { FETCH_USER_RESET, POST_USER_RESET } from '../../store/types';
import PasswordConfirm from '@/components/PasswordConfirm.vue';

export default {
  name: 'NewPassword',
  components: {
    PasswordConfirm
  },
  setup() {
    // * Validacion de tokens
    /*
    Cuando carga la pagina, obtengo el id y el token que vienen desde el link que el usuario hizo click
    validando esto es que puedo ver si el link sigue activo o si no, no puede realizar la accion
    */
    const route = useRoute();

    const { id } = route.params;
    const { token } = route.params;

    const incorrect_link = ref(false);

    const store = useStore();

    onMounted(async () => {
      try {
        const resp = await store.dispatch(FETCH_USER_RESET, { id, token });
        if (resp.status == 200) {
          incorrect_link.value = false;
        }
      } catch (error) {
        incorrect_link.value = true;
      }
    });

    // * Form
    const formRef = ref();
    const formState = reactive({
      pass: '',
      checkPass: ''
    });

    const validatePass = async (rule, value) => {
      if (!value) {
        return Promise.reject('Porfavor ingrese la contraseña');
      }
      if (value.length < 4) {
        return Promise.reject('La contraseña debe de ser mayor a 4 caracteres');
      }
      return Promise.resolve();
    };

    const validatePass2 = async (rule, value) => {
      if (value === '') {
        return Promise.reject('Porfavor ingrese la contraseña nuevamente');
      } else if (value != formState.pass) {
        return Promise.reject('Las contraseñas no son iguales');
      }
      return Promise.resolve();
    };

    const rules = {
      pass: [
        {
          validator: validatePass,
          trigger: 'change'
        }
      ],
      checkPass: [
        {
          validator: validatePass2,
          trigger: 'change'
        }
      ]
    };

    // * Cambio de contraseña
    // Loading button
    const loading = ref(false);
    // Error
    const show_error = ref(false);
    // Password change message
    const pass_confirm = ref(false);

    const handleFinish = async (values) => {
      loading.value = true;

      try {
        const resp = await store.dispatch(POST_USER_RESET, {
          id,
          token,
          payload: { password: values.pass }
        });

        const status_code = resp.data.status;
        if (status_code === 200) {
          setTimeout(function () {
            loading.value = false;
            pass_confirm.value = true;
          }, 1000);
        }

        console.log(resp);
      } catch (error) {
        setTimeout(function () {
          loading.value = false;
          show_error.value = 'true';
        }, 1000);
      }
    };

    const handleFinishFailed = (errors) => {
      console.log(errors);
    };

    return {
      incorrect_link,
      formRef,
      formState,
      rules,
      show_error,
      loading,
      handleFinish,
      handleFinishFailed,
      pass_confirm
    };
  }
};
</script>
