import { FETCH_REQUESTS_TABLE_DATA_BY_TYPE } from '../types';

import { getAllRequests } from '../../../../network';

// Tabla
import {
  SET_ROWS_TABLE,
  SET_TOTAL_PAGES_TABLE,
  SET_CURRENT_PAGE_TABLE,
  SET_ITEMS_PER_PAGE_TABLE
} from '../../../tables/types';

const store = {};

const getDefaultState = () => ({});

store.state = getDefaultState();

store.getters = {};

store.mutations = {};

store.actions = {
  [FETCH_REQUESTS_TABLE_DATA_BY_TYPE]({ dispatch }, { payload, signal }) {
    return new Promise((resolve, reject) => {
      getAllRequests(payload, signal)
        .then((resp) => {
          const { rows, total_pages, page } = resp.data;

          dispatch(SET_ROWS_TABLE, rows);
          dispatch(SET_TOTAL_PAGES_TABLE, total_pages);
          dispatch(SET_CURRENT_PAGE_TABLE, page);
          dispatch(SET_ITEMS_PER_PAGE_TABLE, 10);

          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default store;
