<template>
  <div>
    <a-upload
      :multiple="true"
      :file-list="file_list"
      :customRequest="uploadRequest"
    >
      <a-button
        type="default"
        :loading="loading"
        :disabled="loading"
        style="border: 1px solid #2521e5; color: #2521e5"
      >
        <plus-circle-outline />
        Cargar facturas
      </a-button>
    </a-upload>
  </div>
</template>

<script>
import { PlusCircleOutlined } from '@ant-design/icons-vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import notification from '@/utils/notifications';
import {
  FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES,
  POST_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES
} from '@/store/types';

export default {
  props: {
    insuranceIdToUpload: {
      type: [String, Number],
      default: 1
    },
    collectionIdToUpload: {
      type: [String, Number],
      default: 1
    },
    collectionType: {
      type: String,
      default: 'COLLECTION'
    }
  },
  components: {
    'plus-circle-outline': PlusCircleOutlined
  },
  setup(props, { emit }) {
    // * store
    const store = useStore();

    // * Props
    const type = computed(() => props.buttonType);
    const style = computed(() => props.customStyle);
    const insurance_id = computed(() => props.insuranceIdToUpload);
    const collection_id = computed(() => props.collectionIdToUpload);
    const coll_type = computed(() => props.collectionType);

    // * Variables
    const file_list = ref([]);
    const loading = ref(false);

    // * Metodos
    const uploadRequest = async ({ file }) => {
      loading.value = true;
      file_list.value = [];

      const file_type = file.type;
      const { data: uploaded_files } = await store.dispatch(
        FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES,
        {
          collection_id: collection_id.value,
          insurance_id: insurance_id.value,
          type: coll_type.value
        }
      );
      const uploaded_file_found = uploaded_files.some(
        (obj) => obj.objectName === file.name
      );

      if (uploaded_file_found) {
        notification('error', 'Error', 'Archivo ya existe en el registro');
      } else if (file_type != 'application/pdf') {
        notification(
          'error',
          'Error',
          'Archivo no puede ser distinto a formato pdf'
        );
      } else {
        try {
          const uploaded_files_id = uploaded_files.map((obj) => obj.id);
          const max_file_id =
            uploaded_files_id.length > 0 ? Math.max(...uploaded_files_id) : 0;

          const dummy_file = {
            id: max_file_id + 1,
            key: max_file_id + 1,
            bill: file.name,
            billNumber: null,
            issueDate: null,
            bussinessName: null,
            state: 'En Subida',
            observations: 0,
            policyNumber: [],
            options: max_file_id + 1
          };

          emit('uploadDummyFile', dummy_file);

          // await store.dispatch(ADD_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICE,
          //   {
          //     insurance_comp_id: insurance_id.value,
          //     payload:
          //     {
          //       'id': max_file_id + 1, // Como es un objeto dummy solamente, le doy un id que no existe para que la tabla no choque con otro
          //       'collectionType': coll_type.value,
          //       'isCompleted': false,
          //       'objectName': file.name,
          //       'objectPath': '',
          //       'insuranceCompanyIdSender': null,
          //       'companyIdRecipient': null,
          //       'folio': null,
          //       'issueDate': null,
          //       'firstItemDetail': null,
          //       'issuesCount': null,
          //       'createdAt': null,
          //       'updatedAt': null,
          //       'state': 'En Subida'
          //     }
          //   }
          // );

          const formData = new FormData();
          formData.append('file', file);
          formData.append('collectionType', coll_type.value);

          await store.dispatch(
            POST_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES,
            {
              collection_id: collection_id.value,
              insurance_id: insurance_id.value,
              payload: formData
            }
          );

          emit('postCollectionFormInsuranceCompanyInvoices', true);
        } catch (error) {
          notification('error', 'Error', error.response.data.message);
        }
      }

      loading.value = false;
    };

    return {
      file_list,
      type,
      style,
      uploadRequest,
      loading
    };
  }
};
</script>
1
