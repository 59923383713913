<template>
  <div>
    <a-modal v-model:visible="visible" title="Volver a validar nómina" centered>
      <a-typography-text :level="5">
        ¿Estás seguro de volver a validar los últimos movimientos del mes
        registrados para el periodo {{ collection_ticket_data.period }} con los
        movimientos adjuntos en la nómina de cobranza?
      </a-typography-text>
      <a-row class="mt-2 mb-4">
        <a-col :span="2">
          <exclamation-circle-outlined :style="{ fontSize: '28px', color: '#FFBF01' }" />
        </a-col>
        <a-col :span="20">
          <a-typography-text class="text-gray-8">
            No se esta considerando ningun movimiento registrado antes o después
            de dicho periodo. Considerar que las inconsistencias actuales se
            reemplazaran.
          </a-typography-text>
        </a-col>
      </a-row>
      <template #footer>
        <a-button :loading="loadingsquaringValidate" key="back" @click="closeModal">Volver atrás</a-button>
        <a-button :loading="loadingsquaringValidate" key="submit" type="primary" @click="createReSquaringValidate">
          Confirmar
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import {
  GET_COLLECTIONS,
  GET_COLLECTION_INCONSISTENCIES
} from '../../../store/types';

export default {
  components: {
    'exclamation-circle-outlined': ExclamationCircleOutlined
  },
  props: {
    data: {
      type: Object,
      default: {}
    },
    ticketId: {
      type: Number,
      default: 1
    },
    loadingsquaringValidate: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const store = useStore(); // * Obtencion de data principal
    const visible = ref(false);
    const loading = computed(() => props.loadingsquaringValidate);
    const collection_ticket_data = computed(
      () => store.getters[GET_COLLECTIONS]
    );

    const closeModal = () => {
      visible.value = false;
    };
    const squaring_value = ref();

    const openModal = () => {
      visible.value = true;
    };
    const status = ref('');
    const collectionInconsistency = computed(
      () => store.getters[GET_COLLECTION_INCONSISTENCIES]
    );
    const createReSquaringValidate = async () => {
      visible.value = false;
      emit('createSquaringValidate');
    };

    return {
      visible,
      closeModal,
      createReSquaringValidate,
      openModal,
      squaring_value,
      collection_ticket_data,
      loading,
      collectionInconsistency,
      status
    };
  }
};
</script>
