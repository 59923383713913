<template>
  <div>
    <a-modal
      v-model:visible="modal_props.visibility"
      title="Resumen formulario inicio de cobranza"
      @cancel="closeModal"
      :closable="!loading_state"
      :maskClosable="!loading_state"
    >
      <div>
        <p>Revisa que los datos ingresados esten correctos.</p>

        <a-descriptions
          class="mb-3"
          v-for="(ins, index) in insurance_data"
          :key="index"
          :title="ins.insurance_name"
          bordered
        >
          <a-descriptions-item label="Nº de nóminas">{{
            ins.payroll_amount
          }}</a-descriptions-item>
        </a-descriptions>
      </div>

      <template #footer>
        <a-button :disabled="loading_state" type="primary" @click="onOk"
          >Ok</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, reactive } from 'vue';
import { watch } from 'vue';

export default {
  name: 'CollectionStartModal',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: []
    }
  },
  setup(props, { emit }) {
    // * Props
    const insurance_data = computed(() => props.value);
    const loading_state = computed(() => props.loading);

    // * Modal
    const modal_props = reactive({
      visibility: false,
      title: 'Resumen formulario inicio de cobranza'
    });

    watch(
      () => props.modelValue,
      (newVal) => {
        modal_props.visibility = newVal;
      }
    );

    const closeModal = () => {
      modal_props.visibility = false;
      emitResponse();
    };

    const emitResponse = () => {
      emit('update:modelValue', false);
    };

    // * Funciones
    const onOk = (e) => {
      emit('onOk', e);
    };

    return {
      closeModal,
      modal_props,
      loading_state,
      insurance_data,
      onOk
    };
  }
};
</script>
