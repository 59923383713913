// * FETCH
export const FETCH_TABLE_TICKETS = 'datatable/FETCH_TABLE_TICKETS';
export const FETCH_TABLE_CASES = 'datatable/FETCH_TABLE_CASES';
export const FETCH_TABLE_MASIVE_TICKETS =
  'datatable/FETCH_TABLE_MASIVE_TICKETS';
export const FETCH_TABLE_RESUME = 'datatable/FETCH_TABLE_RESUME';

export const FETCH_TABLE_TICKETS_TEST = 'datatable/FETCH_TABLE_TICKETS_TEST';

// * MUTATE
export const MUTATE_TABLE_ROW = 'datatable/MUTATE_TABLE_ROW';
export const MUTATE_TABLE_TOTAL_PAGES = 'datatable/MUTATE_TABLE_TOTAL_PAGES';
export const MUTATE_TABLE_ITEMS_PER_PAGE =
  'datatable/MUTATE_TABLE_ITEMS_PER_PAGE';
export const MUTATE_TABLE_CURRENT_PAGE = 'datatable/MUTATE_TABLE_CURRENT_PAGE';
export const MUTATE_TABLE_FILTERS = 'datatable/MUTATE_TABLE_FILTERS';
export const MUTATE_TABLE_RESUME = 'datatable/MUTATE_TABLE_RESUME';

export const MUTATE_CASES_TABLE_ROW = 'datatable/MUTATE_CASES_TABLE_ROW';
export const MUTATE_CASES_TABLE_TOTAL_PAGES =
  'datatable/MUTATE_CASES_TABLE_TOTAL_PAGES';
export const MUTATE_CASES_TABLE_ITEMS_PER_PAGE =
  'datatable/MUTATE_CASES_TABLE_ITEMS_PER_PAGE';
export const MUTATE_CASES_TABLE_CURRENT_PAGE =
  'datatable/MUTATE_CASES_TABLE_CURRENT_PAGE';
export const MUTATE_CASES_TABLE_FILTERS =
  'datatable/MUTATE_CASES_TABLE_FILTERS';

export const MUTATE_GROUPS_TABLE_ROW = 'datatable/MUTATE_GROUPS_TABLE_ROW';
export const MUTATE_GROUPS_TABLE_TOTAL_PAGES =
  'datatable/MUTATE_GROUPS_TABLE_TOTAL_PAGES';
export const MUTATE_GROUPS_TABLE_ITEMS_PER_PAGE =
  'datatable/MUTATE_GROUPS_TABLE_ITEMS_PER_PAGE';
export const MUTATE_GROUPS_TABLE_CURRENT_PAGE =
  'datatable/MUTATE_GROUPS_TABLE_CURRENT_PAGE';
export const MUTATE_GROUPS_TABLE_FILTERS =
  'datatable/MUTATE_GROUPS_TABLE_FILTERS';

// * GETTERS
export const GETTER_TICKET_TABLE_ROWS = 'datatable/GETTER_TICKET_TABLE_ROWS';
export const GETTER_TICKET_TABLE_TOTAL_PAGES =
  'datatable/GETTER_TICKET_TABLE_TOTAL_PAGES';
export const GETTER_TICKET_TABLE_ITEMS_PER_PAGE =
  'datatable/GETTER_TICKET_TABLE_ITEMS_PER_PAGE';
export const GETTER_TICKET_TABLE_CURRENT_PAGE =
  'datatable/GETTER_TICKET_TABLE_CURRENT_PAGE';
export const GETTER_TICKET_TABLE_FILTERS =
  'datatable/GETTER_TICKET_TABLE_FILTERS';
export const GETTER_TICKET_TABLE_RESUME =
  'datatable/GETTER_TICKET_TABLE_RESUME';

export const GETTER_CASES_TICKET_TABLE_ROWS =
  'datatable/GETTER_CASES_TICKET_TABLE_ROWS';
export const GETTER_CASES_TICKET_TABLE_TOTAL_PAGES =
  'datatable/GETTER_CASES_TICKET_TABLE_TOTAL_PAGES';
export const GETTER_CASES_TICKET_TABLE_ITEMS_PER_PAGE =
  'datatable/GETTER_CASES_TICKET_TABLE_ITEMS_PER_PAGE';
export const GETTER_CASES_TICKET_TABLE_CURRENT_PAGE =
  'datatable/GETTER_CASES_TICKET_TABLE_CURRENT_PAGE';
export const GETTER_CASES_TICKET_TABLE_FILTERS =
  'datatable/GETTER_CASES_TICKET_TABLE_FILTERS';

export const GETTER_GROUPS_TICKET_TABLE_ROWS =
  'datatable/GETTER_GROUPS_TICKET_TABLE_ROWS';
export const GETTER_GROUPS_TICKET_TABLE_TOTAL_PAGES =
  'datatable/GETTER_GROUPS_TICKET_TABLE_TOTAL_PAGES';
export const GETTER_GROUPS_TICKET_TABLE_ITEMS_PER_PAGE =
  'datatable/GETTER_GROUPS_TICKET_TABLE_ITEMS_PER_PAGE';
export const GETTER_GROUPS_TICKET_TABLE_CURRENT_PAGE =
  'datatable/GETTER_GROUPS_TICKET_TABLE_CURRENT_PAGE';
export const GETTER_GROUPS_TICKET_TABLE_FILTERS =
  'datatable/GETTER_GROUPS_TICKET_TABLE_FILTERS';

// * POST
export const POST_RESEND_FORM_INCORPORATION_TICKET =
  'datatable/POST_RESEND_FORM_INCORPORATION_TICKET';
