<template>
  <page-header :breadcrumRoutes="routes" title="Modificación individual">
    <template #tags>
      <tags :stateId="ticket_state_id" />
      <tags color="processing">ID: {{ id_modificacion }}</tags>
    </template>

    <template #buttons v-if="user_role != 'rrhh'">
      <a-button @click="openCancelModal" :loading="loading" :disabled="loading" v-if="ticket_state_id == 2 || ticket_state_id == 8 || ticket_state_id == 1
      ">Cancelar</a-button>
      <a-button class="ml-2" @click="openEjecutarModal" v-if="ticket_state_id == 8" :loading="loading"
        :disabled="loading" type="primary">Ejecutar</a-button>
    </template>
  </page-header>

  <stepper ticketType="modificacion" :state="final_state" :stateDates="final_dates" ref="stepper_ref" />

  <a-card class="mb-3">
    <a-row>
      <a-col span="12">
        <a-row class="mb-4">
          <a-col :span="24">
            <div>
              <datos-ticket :data="data_to_table" title="Datos corporativos" :loadTicket="loadTicket" />

              <mensaje-aseguradora :message="insuranceMessage" v-if="user_role != 'rrhh'" />
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col span="12">
        <a-row class="mb-4">
          <a-col :span="23" :offset="1">
            <div>
              <records title="Historial" :data="ordered_records" v-if="user_role != 'rrhh'"
                :loadingHistory="loadingHistory" />

              <comments :comments="comments_ordered" :idToTicket="id_modificacion" @new_comment="getNewCommment"
                v-if="user_role != 'rrhh'" />
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Stepper from '@/components/Tickets/Stepper.vue';
import DatosTicket from '@/components/Tickets/TicketData.vue';
import Comments from '@/components/Tickets/Comments.vue';
import Records from '@/components/Tickets/Records.vue';
import MensajeAseguradora from '@/components/Tickets/MensajeAseguradora.vue';
import parseDate from '@/utils/dateFormatter';
import {
  CANCEL_MODIFICACION_INDIVIDUAL,
  GET_USER_ROLE,
  FETCH_TICKET_ID,
  FETCH_TICKET_COMMENTS_ID,
  FETCH_RECORD_FILE_TICKET,
  FETCH_COMPANY_BY_USER_ID,
  FETCH_USER_BY_ID,
  FETCH_INSURANCE_BY_EMPLOYEE_GROUP_COMPANY,
  POST_EXECUTE_MODIFICATION_INDIVIDUAL
} from '@/store/types';
import PageHeader from '@/components/PageHeader.vue';
import Tags from '@/components/Tags.vue';
import rutHelper from '@/utils/Ruts';

export default {
  name: 'DetalleModificacion',
  components: {
    stepper: Stepper,
    'datos-ticket': DatosTicket,
    Comments,
    records: Records,
    'mensaje-aseguradora': MensajeAseguradora,
    'page-header': PageHeader,
    tags: Tags
  },
  setup() {
    // * VUEX
    const store = useStore();
    const router = useRouter();
    const user_role = store.getters[GET_USER_ROLE];
    const finishAlta = ref(false);
    const loadTicket = ref(false);
    const loadingHistory = ref(false);

    // *  Vue router
    const route = useRoute();
    const id_modificacion = route.params.id;

    const routes = [
      {
        path: '/modificaciones',
        breadcrumbName: 'Modificaciones'
      },
      {
        path: '/modificaciones',
        breadcrumbName: 'Individual'
      },
      {
        breadcrumbName: `#${id_modificacion}`
      }
    ];

    // // * Ordenar data
    // const parseArrayToString = (values) => {
    //   let final_string = '';

    //   for (let i = 0; i < values.length; i++) {
    //     const element = values[i];
    //     let digit = '';

    //     if (i != values.length - 1) {
    //       digit = ',';
    //     }

    //     final_string = final_string + element + digit;
    //   }

    //   return final_string;
    // };

    const getBroker = async (id) => {
      console.log('aquii', id);
      const response = await store.dispatch(FETCH_USER_BY_ID, id);
      console.log('response', response);
      return response.data.body.name;
    };

    // * Ticket
    const ticket = reactive({
      employee: '',
      company: '',
      insurance: '',
      policies: '',
      endDate: '',
      messageInsurance: ''
    });
    // const router = useRouter();
    const getTicketData = async (id) => {
      loadTicket.value = true;
      try {
        // Metodo para obtener data y llenar el ticket
        const ticket_response = await store
          .dispatch(FETCH_TICKET_ID, id)
          .catch(() => router.push({ name: 'Missing' }));
        const ticket_data = ticket_response.data;

        // if(ticket.type.id !=3 ){
        //   return router.push({name:'Missing'});
        // }
        ticket.employee = ticket_data.employee;
        ticket.emailTicket = ticket_data.emailTicket;
        ticket.company = ticket_data.company;
        ticket.insurance = ticket_data.insuranceCompany.businessName;
        ticket.endDate = parseDate(ticket_data.endDate).substring(0, 10);
        loadTicket.value = false;

        const value_to_records = ticket_data.states;
        fillRecordsWithStates(value_to_records);
        loadTicket.value = false;
      } catch (error) {
        loadTicket.value = false;
        console.log('error', error);
      }
    };

    const ticket_last_state_id = computed(() => {
      return ticket.value.states != undefined
        ? ticket.value.states[
          ticket.value.states.length - 1
        ].state.id.toString()
        : '1'; //
    });

    // * Comentarios
    const comments = ref([]);
    const getCommentsFromTicket = async (id) => {
      // Metodo para obtener data y llenar los comentarios

      comments.value.length = 0;
      const comments_response = await store.dispatch(
        FETCH_TICKET_COMMENTS_ID,
        id
      );

      for (let i = 0; i < comments_response.data.length; i++) {
        const element = comments_response.data[i];
        const brokerName = await getBroker(element.brokerId);
        element.brokerName = brokerName;
        comments.value.push(element);
      }

      fillRecordsWithComents(comments.value);
    };

    const getNewCommment = async (value) => {
      const { brokerId } = value;
      const brokerName = await getBroker(brokerId);

      comments.value.push({
        message: value.title,
        createdAt: value.description,
        brokerName
      });

      records.push({
        title: 'Nuevo comentario',
        description: value.description
      });
    };

    const comments_ordered = computed(() => {
      const rows = [];

      for (let i = 0; i < comments.value.length; i++) {
        const element = comments.value[i];
        const finishDate = new Date(element.createdAt);
        rows.push({
          description: finishDate,
          title: element.message,
          author: element.brokerName
        });
      }

      rows.sort(function (a, b) {
        return a.description - b.description;
      });

      for (let i = 0; i < rows.length; i++) {
        const element = rows[i];
        const fecha = parseDate(element.description);
        rows[i].description = fecha;
      }

      return rows;
    });

    // * Archivos
    const file_records = ref([]);
    const getFilesRecordsFromTicket = async (id) => {
      // Metodo para obtener data del registro de los archivos enviados por ticket

      file_records.value.length = 0;
      const file_records_response = await store.dispatch(
        FETCH_RECORD_FILE_TICKET,
        id
      );
      for (let i = 0; i < file_records_response.length; i++) {
        const element = file_records_response[i];
        file_records.value.push(element);
      }

      fillRecordsWithFilesHistory(file_records.value);
    };

    // * Grupos
    const groups = ref({});
    const getGroupByEmployee = async (id) => {
      // Metodo para obtener los grupos que pertenece el asegurado
      groups.value = {};
      const group_company_response = await store.dispatch(
        FETCH_COMPANY_BY_USER_ID,
        id
      );
      groups.value = group_company_response.data;
    };

    // * Compania a seguradora y polizas
    const policies = ref([]);
    const insurance_companies = ref([]);
    const getPoliciesAndCompanies = async (id) => {
      policies.value.length = 0;
      insurance_companies.value.length = 0;
      const response = await store.dispatch(
        FETCH_INSURANCE_BY_EMPLOYEE_GROUP_COMPANY,
        id
      );
      const { data } = response;

      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        policies.value.push(element.policies.numberPolicy);
        insurance_companies.value.push(
          element.policies.insuranceCompany.businessName
        );
      }
      ticket.policies = policies.value
        .reduce((acc, item) => {
          return [...acc, item];
        }, '')
        .toString();
    };

    // * Stepper
    const final_state = ref('');
    const final_dates = ref({});
    const stepper_ref = ref('null');
    const cancel_ticket = ref(false);
    const header_state_data = reactive({
      text: '',
      color: '',
      cancel_disabled: false,
      revisado_disabled: false,
      show_buttons: true
    });

    const getStateType = (state) => {
      // Lo transformo en un texto que el stepper lo sepa interpretar, ya que los nombres de los estados
      // Estan en cambio aun, los estados en el stepper siempre van a estar igual, pero no aca
      // la regla es la siguiente

      switch (state) {
        case 'Creado': // Asi viene desde el backend
          return 'creado'; // Asi es como lo toma el stepper y lo marca en su lugar
        case 'En revision Bewell':
          return 'revision_bewell';
        case 'En revision Aseguradora':
          return 'revision_aseguradora';
        case 'Ejecutado':
          return 'ejecutado';
        case 'Cancelado':
          return 'cancelado';
      }
    };

    const cancelStep = () => {
      // Metodo para cancelar el stepper en un cierto paso
      stepper_ref.value.cancelStep();
    };
    const finishStep = () => {
      stepper_ref.value.finishStep();
    };
    const ticket_state_id = ref('');
    const records_steps = reactive([]);

    const stepperData = async (id) => {
      // Data para el stepper de manera reactiva

      // 1.- Obtengo data para el ticket
      const ticket_response = await store
        .dispatch(FETCH_TICKET_ID, id)
        .catch((e) => e.response);
      const ticket_data = ticket_response.data;

      records_steps.length = 0;
      for (let i = 0; i < ticket_data.states.length; i++) {
        const element = ticket_data.states[i];
        console.log('elemeeent', element);
        records_steps.push({
          title: element.state.tag,
          description: element.createdAt
        });
      }
      ticket_state_id.value =
        ticket_data.states[ticket_data.states.length - 1].state.id;
      // 2.- Primero obtengo el ultimo estado del cual esta el ticket, obviamente este puede cambiar
      const last_ticket_state = {};
      last_ticket_state.value = ticket_data.states.sort((a, b) => {
        return b.id - a.id;
      })[0].state.tag;
      console.log('finaaal', last_ticket_state);

      // 3.- Transformo el ultimo estado en un texto que pueda interpretar el stepper
      let step = getStateType(last_ticket_state.value);

      // 4.- En caso de que el paso quede en cancelado, tengo que obtener el penultimo estado
      // que estubo correcto para pasarle a esta propiedad el cancelado para que se muestre
      // con una X en el paso, asi es como lo toma Ant Desing
      if (step === 'cancelado') {
        // Obtengo entonces el penultimo estado
        const last_before = ticket_data.states.sort((a, b) => {
          return b.id - a.id;
        })[1].state.tag;
        step = getStateType(last_before);
        cancelStep();
      }
      if (step == 'ejecutado') {
        finishStep();
      }

      final_state.value = step;

      // 5.- Tomo los estados ordenados por fecha, despues eliminare los siguientes si es que un estado
      // se devolvio al anterior y hay mas estados delante del ultimo estado donde quedo
      /*
            (X)            (((X)))                       (X)               ()
                      [este es el ultimo
                        estado ya que
                        se devolvio
                        por alguna razon]
                                              [Este deberia de
                                              eliminarse de la
                                              lista de estados
                                              para no mostrarlo
                                              en el stepper]
          CREADO   EN REVISION BEWELL   EN REVISION ASEGURADORA   EJECUTADO
      */

      const sorted_dates = ticket_data.states.sort(function (a, b) {
        return a.id - b.id;
      });

      const final_obj = {};
      for (let i = 0; i < sorted_dates.length; i++) {
        const element = sorted_dates[i];
        const state_name = getStateType(element.state.tag);
        final_obj[state_name] = element.createdAt;
      }

      // 6.- Elimino estados que quedaron por delante del ultimo estado como explique arriba
      // Con el ultimo estado del ticket, valido si hay estados mas adelantes los cuales deba eliminar

      switch (final_state.value) {
        case 'creado':
          delete final_obj['revision_bewell'];
          delete final_obj['revision_aseguradora'];
          delete final_obj['ejecutado'];
          break;
        case 'revision_bewell':
          delete final_obj['revision_aseguradora'];
          delete final_obj['ejecutado'];
          break;
        case 'revision_aseguradora':
          delete final_obj['ejecutado'];
          break;
      }

      // 7.- En caso de que el ultimo estado este en cancelado, voy a dejar el penultimo estado en marcado
      // Para que se marque la x ahi
      // Voy a preguntar cual es el ultimo estado nuevamente
      const last = ticket_data.states.sort((a, b) => {
        return b.id - a.id;
      })[0].state.tag;

      // 8.- Paso data para el page-header
      // Si el ultimo estado esta en 'En revision bewell' osea 'revision_bewell', podemos darle al boton de revisado y de cancelar
      // el unico estado que puedo cancelar y revisar el ticket es en 'Revision aseguradora'

      header_state_data.cancel_disabled = true;
      header_state_data.revisado_disabled = true;
      header_state_data.show_buttons = false;

      switch (last) {
        case 'Creado':
          finishAlta.value = true;
          header_state_data.text = 'Creado';
          header_state_data.color = 'blue';
          break;
        case 'En revision Aseguradora':
          header_state_data.text = 'En revisión aseguradora';
          header_state_data.color = 'gold';
          finishAlta.value = true;
          header_state_data.show_buttons = true;
          break;
        case 'Ejecutado':
          finishAlta.value = false;
          header_state_data.text = 'Ejecutado';
          header_state_data.color = 'green';
          break;
        case 'Cancelado':
          finishAlta.value = false;
          header_state_data.text = 'Cancelado';
          header_state_data.color = 'red';
      }

      if (last === 'Cancelado') {
        // Llamo al metodo que cancela el estado
        cancelStep();
      }

      if (last === 'Ejecutado') {
        finishStep();
        // Llamo al metodo que cancela el estado
      }

      // Si el ultimo estado del ticket fue cancelado, la fecha de este cancelado, se la paso al penultimo estado
      // asi para que se muestre en el penultimo estado que se cancelo con su fecha correspondiente

      const keys_states = Object.keys(final_obj);
      if (last === 'Cancelado') {
        final_obj[keys_states[keys_states.length - 2]] =
          final_obj[keys_states[keys_states.length - 1]];
        delete final_obj['cancelado'];
      }

      // Formateo las fechas
      keys_states.forEach((element) => {
        final_obj[element] = parseDate(final_obj[element]);
      });

      final_dates.value = final_obj;
      if (header_state_data.text == 'cancelado') {
        cancel_ticket.value = false;
      }
      if (header_state_data.text == 'Creado') {
        cancel_ticket.value = true;
      }
    };

    // * Historial
    const records = reactive([]);

    // Llenar el historial con estados del ticket
    const fillRecordsWithStates = (data) => {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        records.push({
          title: element.state.tag,
          description: element.createdAt
        });
      }
    };

    // Llenar el historial con Comentarios
    const fillRecordsWithComents = (data) => {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        records.push({
          title: 'Nuevo comentario',
          description: element.createdAt
        });
      }
    };

    // Llenar el historial con el historial de archivos enviados
    const fillRecordsWithFilesHistory = (data) => {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        records.push({
          title: 'Documento enviado',
          description: element.createdAt
        });
      }
    };
    // const formatDate = (date) => {
    //   const year    = date.getFullYear();
    //   let month   = date.getMonth()+1;
    //   let day     = date.getDate();
    //   let hour    = date.getHours();
    //   let minute  = date.getMinutes();
    //   let second  = date.getSeconds();
    //   if(month.toString().length == 1) {
    //     month = `0${month}`;
    //   }
    //   if(day.toString().length == 1) {
    //     day = `0${day}`;
    //   }
    //   if(hour.toString().length == 1) {
    //     hour = `0${hour}`;
    //   }
    //   if(minute.toString().length == 1) {
    //     minute = `0${minute}`;
    //   }
    //   if(second.toString().length == 1) {
    //     second = `0${second}`;
    //   }
    //   const dateTime = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
    //   return dateTime;
    // };

    const ordered_records = computed(() => {
      loadingHistory.value = true;
      const rows = [];
      const records = [records_steps];

      records.forEach((element) => {
        for (let i = 0; i < element.length; i++) {
          const el = element[i];
          if (el.title == 'Ejecutado') {
            rows.push({
              description: new Date(el.description),
              title: el.title,
              status: 'finish'
            });
          } else if (el.title == 'Cancelado') {
            rows.push({
              description: new Date(el.description),
              title: el.title,
              status: 'error'
            });
          } else {
            rows.push({
              description: new Date(el.description),
              title: el.title
            });
          }
          loadingHistory.value = false;
        }
      });

      rows.sort(function (a, b) {
        return a.description - b.description;
      });

      for (let i = 0; i < rows.length; i++) {
        const element = rows[i];
        const fecha = parseDate(element.description); // formatDate(element.description);
        rows[i].description = fecha;
      }
      return rows;
    });

    // * Mensaje aseguradora
    const insuranceMessage = ref('');
    const getInsuranceMessage = (value) => {
      if (value.emailTicket != null) {
        insuranceMessage.value = value.emailTicket.message;
      }
    };

    // * Cancelar ticket
    const openCancelModal = () => {
      Modal.confirm({
        title: () => '¿Estás seguro que deseas cancelar el ticket de modificación?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => 'Sí',
        cancelText: () => 'No',
        onOk() {
          cancelTicket();
        },
        onCancel() { }
      });
    };

    const loading = ref(false);
    const cancelTicket = async () => {
      loading.value = true;
      try {
        // Hago la request hacia la base de datos
        const response = await store.dispatch(
          CANCEL_MODIFICACION_INDIVIDUAL,
          id_modificacion
        );

        // Actualizo nuevamente el stepper para que se coloque automaticamente
        await stepperData(id_modificacion);
        // Actualizo la data
        getData(id_modificacion);

        // Agrego una row mas al historial
        records.push({
          title: 'Cancelado',
          description: response.data.createdAt
        });
        if (header_state_data.text == 'cancelado') {
          cancel_ticket.value = false;
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
        console.log(error);
      }
    };

    // * Ejecutar ticket
    const openEjecutarModal = () => {
      Modal.confirm({
        title: () => '¿Estás seguro que deseas ejecutar el ticket de modificación?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => 'Sí',
        cancelText: () => 'No',
        onOk() {
          ejecutar();
        },
        onCancel() { }
      });
    };

    const ejecutar = async () => {
      loading.value = true;
      try {
        await store.dispatch(
          POST_EXECUTE_MODIFICATION_INDIVIDUAL,
          id_modificacion
        );

        // Actualizo nuevamente el stepper para que se coloque automaticamente
        await stepperData(id_modificacion);

        // Actualizo la data
        getData(id_modificacion);
      } catch (error) {
        console.log(error);
      }
      loading.value = false;
    };

    // * Obtener la data principal al cargar la pagina
    const getData = async (id) => {
      try {
        // Dejo el historila vacio para no tener ningun problema futuro
        records.length = 0;

        // Obtengo data del ticket
        await getTicketData(id);

        // OBtengo mensaje de aseguradora
        await getInsuranceMessage(ticket);

        // Obtengo los comentarios asociados al ticket
        await getCommentsFromTicket(id);

        // Obtengo el registro de archivos enviados por el ticket
        await getFilesRecordsFromTicket(id);

        // Obtengo los grupos que pertenece el asegurado
        await getGroupByEmployee(ticket.employee.id);

        // Obtengo las las polizas junto con su compania aseguradora
        await getPoliciesAndCompanies(groups.value.groupCompanies.id);

        // // Creo la data para pasarsela a la tabla principal descriptiva
        // const data_to_table = {
        //   name: ticket.value.employee.firstName,
        //   last_name: ticket.value.employee.firstSurname,
        //   rut: ticket.value.employee.rut,
        //   company: ticket.value.company.businessName,
        //   insurance: insurance_companies.value,
        //   policies: policies.value
        // };

        // crearDataToTable(data_to_table);

        // Obtengo data para el stepper
        await stepperData(id);
      } catch (error) {
        console.log('error', error);
      }
    };
    getData(id_modificacion);
    console.log('detallee', ticket);
    // * Crear data para la tabla principal
    const data_to_table = computed(() => {
      return [
        {
          key: 'Nombre',
          value: `${ticket?.employee?.firstName || ''} ${ticket?.employee?.firstSurname || ''}`
        },
        {
          key: 'RUT',
          value: ticket.employee
            ? rutHelper.formatRut(ticket.employee.rut)
            : 'No hay datos'
        },
        {
          key: 'Empresa',
          value: ticket.company ? ticket.company.businessName : 'No hay datos'
        },
        {
          key: 'Aseguradora',
          value: ticket.insurance ? ticket.insurance : 'No hay datos'
        },
        {
          key: 'Pólizas',
          value: ticket.policies ? ticket.policies : 'no hay datos'
        }
      ];
    });

    return {
      ticket,
      finishAlta,
      comments,
      file_records,
      groups,
      policies,
      insurance_companies,
      data_to_table,
      final_state,
      final_dates,
      stepper_ref,
      getNewCommment,
      comments_ordered,
      id_modificacion,
      insuranceMessage,
      routes,
      header_state_data,
      cancelTicket,
      ejecutar,
      ordered_records,
      openCancelModal,
      openEjecutarModal,
      cancel_ticket,
      user_role,
      ticket_last_state_id,
      loading,
      finishStep,
      loadTicket,
      ticket_state_id,
      loadingHistory
    };
  }
};
</script>
