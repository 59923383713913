<template>
  <div v-if="$route.name === 'FueraTiempo'">
    <page-header :breadcrumRoutes="routes" title="Fuera de Tiempo" />

    <a-card class="mt-2">
      <a-row type="flex" justify="space-between" align="middle">
        <a-col>
          <a-typography-title :level="4" class="text-gray-8 mb-0">
            Stock Fuera de Tiempo
          </a-typography-title>
        </a-col>

        <a-range-picker v-model:value="dante_range" :value-format="date_format" :format="[date_format, date_format]" />
      </a-row>
      <a-row class="mt-3">
        <a-col :span="24">
          <cases-table type="Out of Time" :dates="dante_range" />
        </a-col>
      </a-row>
    </a-card>
  </div>
  <router-view :key="$route.path"></router-view>
</template>

<script>
// Components
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import PageHeader from '@/components/PageHeader.vue';
import CasesTable from '@/components/Tables/CasesTable.vue';

const routes = [
  {
    breadcrumbName: 'Fuera de tiempo'
  }
];

export default {
  name: 'FueraTiempo',
  components: {
    'page-header': PageHeader,
    'cases-table': CasesTable
  },
  setup() {
    // * Route y router
    const route = useRoute();

    // * Filtro de fechas
    const dante_range = ref([]);
    const date_format = 'YYYY-MM-DD';

    onMounted(() => {
      if (
        route.query.startDate != undefined &&
        route.query.endDate != undefined
      ) {
        dante_range.value.push(route.query.startDate);
        dante_range.value.push(route.query.endDate);
      }
    });

    return {
      // Variables
      routes,
      date_format,
      dante_range
      // Methods
    };
  }
};
</script>
