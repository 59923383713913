// * MUTATE
export const MUTATE_REPROCESS = 'reprocesos/MUTATE_REPROCESS';
export const MUTATE_PROCESS_BY_REPROCESS =
  'reprocesos/MUTATE_PROCESS_BY_REPROCESS';
export const MUTATE_REPROCESS_COMMENTS = 'reprocesos/MUTATE_REPROCESS_COMMENTS';
export const MUTATE_REPROCESS_BY_COLLECTION_ID =
  'reprocesos/MUTATE_REPROCESS_BY_COLLECTION_ID';
export const MUTATE_REPROCESS_SUMMARY = 'reprocesos/MUTATE_REPROCESS_SUMMARY';

// * FETCH
export const FETCH_REPROCESS = 'reprocesos/FETCH_REPROCESS';
export const FETCH_PROCESS_BY_REPROCESS =
  'reprocesos/FETCH_PROCESS_BY_REPROCESS';
export const FETCH_REPROCESS_COMMENTS = 'reprocesos/FETCH_REPROCESS_COMMENTS';
export const FETCH_REPROCESS_BY_COLLECTION_ID =
  'reprocesos/FETCH_REPROCESS_BY_COLLECTION_ID';
export const FETCH_REPROCESS_SUMMARY = 'reprocesos/FETCH_REPROCESS_SUMMARY';
export const FETCH_REPROCESS_TABLE_DATA_BY_TYPE =
  'reprocesos/FETCH_REPROCESS_TABLE_DATA_BY_TYPE';

// * GET
export const GET_REPROCESS = 'reprocesos/GET_REPROCESS';
export const GET_PROCESS_BY_REPROCESS = 'reprocesos/GET_PROCESS_BY_REPROCESS';
export const GET_REPROCESS_COMMENTS = 'reprocesos/GET_REPROCESS_COMMENTS';
export const GET_REPROCESS_BY_COLLECTION_ID =
  'reprocesos/GET_REPROCESS_BY_COLLECTION_ID';
export const GET_REPROCESS_SUMMARY = 'reprocesos/GET_REPROCESS_SUMMARY';

// * OTHERS
export const POST_CHANGE_REPROCESS_STATE =
  'reprocesos/POST_CHANGE_REPROCESS_STATE';
export const POST_REPROCESS_COMMENTS = 'reprocesos/POST_REPROCESS_COMMENTS';
export const POST_REPROCESS = 'reprocesos/POST_REPROCESS';
