<template>
  <div>
    <a-card class="mt-3">
      <a-typography-title :level="3">
        Detalle de cobranza y facturación
      </a-typography-title>
      <p>Aquí encontraras el detalle de tus cobranzas y facturas</p>

      <div v-for="insurance in insurance_companies" :key="insurance.id">
        <insurance-company-info
          :name="insurance.name"
          :id="insurance.id"
          :type="insurance.type"
          :collection-form-id="collection_form_id"
          :validateApproved="validateApproved"
          :collection-start-form-state="collectionStartFormState"
        />
      </div>
    </a-card>

    <a-row
      v-if="current_state === 'PROCCESING'"
      type="flex"
      class="justify-content-end mt-3"
    >
      <a-col>
        <a-button
          size="large"
          class="px-4"
          type="primary"
          block
          @click="openSummaryModal(collection_form_id)"
          >Cargar cobranza
        </a-button>
      </a-col>
    </a-row>

    <!-- Modal de resumen -->
    <summary-modal ref="summary_ref" />
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import notification from '../../../utils/notifications';
import {
  FETCH_COLLECTION_FORM_INSURANCE_COMPANIES,
  GET_COLLECTION_FORM_INSURANCE_COMPANIES
} from '../../../store/types';
import CollectionChargeSummaryModal from '../../collection and billing/collection form/CollectionChargeSummaryModal.vue';
import DetailInsuranceInformation from './DetailInsuranceInformation.vue';

export default {
  components: {
    'insurance-company-info': DetailInsuranceInformation,
    'summary-modal': CollectionChargeSummaryModal
  },
  props: {
    currentCollectionFormState: {
      type: String,
      default: 'PROCCESING'
    },
    validateApproved: {
      type: Boolean,
      default: false
    },
    collectionStartFormState: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    // * Variables globales
    const route = useRoute();
    const collection_form_id = route.params.id;
    const store = useStore();
    const current_state = computed(() => props.currentCollectionFormState);
    // * Obtencion de data principal
    const getInsurancesCompanies = () => {
      try {
        store.dispatch(
          FETCH_COLLECTION_FORM_INSURANCE_COMPANIES,
          collection_form_id
        );
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const insurance_companies = computed(
      () => store.getters[GET_COLLECTION_FORM_INSURANCE_COMPANIES]
    );

    // * modal de resumen
    const summary_ref = ref();

    const openSummaryModal = (collection_form_id) => {
      summary_ref.value.openModal(collection_form_id);
    };

    onMounted(() => {
      getInsurancesCompanies();
    });

    return {
      insurance_companies,
      summary_ref,
      collection_form_id,
      current_state,
      openSummaryModal
    };
  }
};
</script>
