<template>
  <div>
    <a-card>
      <a-typography-title :level="3">
        Procesamiento de nóminas
      </a-typography-title>
      <p>
        Carga aquí las nóminas de cobranza asociadas a la aseguradora
        seleccionada
      </p>

      <div
        v-for="insurance_comp in insurance_comp_list"
        :key="insurance_comp.id"
      >
        <payroll-list
          class="mt-5"
          :insurance-company-name="insurance_comp.name"
          :insurance-company-id="insurance_comp.id"
          :payroll-format-id="insurance_comp.payroll_format_id"
          :collection-form-id="col_form_id"
          @allValidPayrolls="payrollListValidation"
        />
      </div>
    </a-card>

    <a-row type="flex" class="justify-content-end mt-3">
      <a-col>
        <a-button
          size="large"
          class="px-4"
          type="primary"
          :disabled="!payroll_list_validation"
          @click="PayrollProcesingFinish"
          block
          >Siguiente
        </a-button>
      </a-col>
    </a-row>

    <collection-start
      v-model="start_collection_modal.visible"
      :value="start_collection_modal.values"
      :loading="start_collection_modal.loading"
      @onOk="collectionStartOnOk"
    />
  </div>
</template>

<script>
import { computed, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import CollectionStartModal from '../../collection and billing/collection form/CollectionsStartModal.vue';
import scrollToTopScreen from '../../../utils/scrollToTopScreen';
import notification from '../../../utils/notifications';
import {
  GET_COLLECTION_FORM,
  GET_COLLECTION_FORM_INSURANCE_COMPANY_PAYROLL,
  PATCH_COLLECTION_FORM_STATE
} from '../../../store/types';
import PayrollList from './PayrollList.vue';

export default {
  props: {
    collectionFormId: {
      type: [String, Number],
      default: 0
    }
  },
  components: {
    'payroll-list': PayrollList,
    'collection-start': CollectionStartModal
  },
  setup(props) {
    // * Vuex
    const store = useStore();

    // * Props
    const col_form_id = computed(() => props.collectionFormId);

    // * Obtencion de data principal
    const insurance_comp_list = computed(() => {
      return store.getters[GET_COLLECTION_FORM].insuranceCompanies.map(
        (insurance) => {
          return {
            name: insurance.businessName,
            id: insurance.id,
            payroll_format_id: insurance.payrollFormatId
          };
        }
      );
    });

    // * Funciones
    const valid_insurance_companies = ref({});

    const payrollListValidation = (validation, ins_comp_id) => {
      valid_insurance_companies.value[ins_comp_id] = validation;
    };

    const payroll_list_validation = computed(() => {
      const keys = Object.keys(valid_insurance_companies.value);
      for (const key of keys) {
        if (valid_insurance_companies.value[key] === false) {
          return false;
        }
      }
      return true;
    });

    // * Modal collection start
    const start_collection_modal = reactive({
      visible: false,
      loading: false,
      values: []
    });

    const closeStartCollectionModal = () => {
      start_collection_modal.visible = false;
    };

    const collectionStartOnOk = async () => {
      start_collection_modal.loading = true;

      try {
        // Paso a nuevo estado en el formulario
        await store.dispatch(PATCH_COLLECTION_FORM_STATE, {
          id: col_form_id.value,
          payload: {
            state: 'PROCESSED'
          }
        });

        closeStartCollectionModal();
        scrollToTopScreen();
        notification(
          'success',
          'Correcto',
          'Las nominas fueron cargadas, validadas y procesadas'
        );
      } catch (error) {
        notification(
          'error',
          'Error',
          'Ocurrio un error inesperado, vuelve a intentarlo mas tarde'
        );
      }

      start_collection_modal.loading = false;
    };

    const PayrollProcesingFinish = () => {
      const values_resume = [];
      insurance_comp_list.value.forEach((insurance) => {
        const payrolls = store.getters[
          GET_COLLECTION_FORM_INSURANCE_COMPANY_PAYROLL
        ](insurance.id);

        const insurance_total = {
          insurance_name: insurance.name,
          payroll_amount: payrolls.length
        };

        values_resume.push(insurance_total);
      });

      start_collection_modal.values = values_resume;
      start_collection_modal.visible = true;
    };

    return {
      col_form_id,
      insurance_comp_list,
      valid_insurance_companies,
      payrollListValidation,
      collectionStartOnOk,
      payroll_list_validation,
      start_collection_modal,
      PayrollProcesingFinish
    };
  }
};
</script>
