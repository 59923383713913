export const GET_INSURANCECOMPANY_EXECUTIVES =
  'index/GET_INSURANCECOMPANY_EXECUTIVES';
export const SET_INSURANCECOMPANY_EXECUTIVES =
  'index/SET_INSURANCECOMPANY_EXECUTIVES';
export const FETCH_INSURANCECOMPANY_EXECUTIVES =
  'index/FETCH_INSURANCECOMPANY_EXECUTIVES';
export const POST_INSURANCECOMPANY_EXECUTIVE =
  'index/POST_INSURANCECOMPANY_EXECUTIVE';
export const PUT_INSURANCECOMPANY_EXECUTIVE =
  'index/PUT_INSURANCECOMPANY_EXECUTIVE';
export const DELETE_INSURANCECOMPANY_EXECUTIVE =
  'index/DELETE_INSURANCECOMPANY_EXECUTIVE';
export const DELETE_CLIENT_EXECUTIVE_INSURANCE_COMPANY =
  'index/DELETE_CLIENT_EXECUTIVE_INSURANCE_COMPANY';
