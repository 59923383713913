<template>

  <a-row class="mb-4 mt-4 pb-1" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <a-typography-title class="text-gray-8" :level="5">Casi terminamos</a-typography-title>
      <a-typography-text class="text-gray-8">Lee y confirma la siguiente declaración de autorización
        personal.</a-typography-text>
    </a-col>
  </a-row>

  <a-row class="pb-1" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <a-card>
        <a-typography-title class="text-gray-8" :level="5">Declaración de autorización personal</a-typography-title>
        <div class="d-flex align-items-start">
          <a-checkbox v-model:checked="checked" class="d-flex mr-2"></a-checkbox>
          <a-typography-text class="text-gray-8">{{
            textConsent
            }}</a-typography-text>
        </div>
      </a-card>
      <br />
    </a-col>
  </a-row>

  <a-row :gutter="16" type="flex" style="width: 100%" justify="center">
    <a-col :xs="10" :sm="6" :lg="4">
      <a-button class="" size="large" block @click="onPrevStep()">Volver atrás</a-button>
    </a-col>
    <a-col :xs="10" :sm="6" :lg="4">
      <a-button class="btn-client" type="primary" size="large" block @click="onNextStep()" :disabled="!checked">
        Siguiente
      </a-button>
    </a-col>
  </a-row>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
// import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import {
  FETCH_CONSENT_TEXT,
  FETCH_TICKET_ID,
  FETCH_EMPLOYEE,
  SAVE_CONSENT_DATA
} from '@/store/types';

export default {
  name: 'Auth',
  setup() {
    const store = useStore();
    const textConsent = ref('');
    const ticket = ref([]);
    const checked = ref(false);
    const route = useRoute();
    const router = useRouter();
    const info = reactive({
      id: '',
      ticketId: '',
      token: ''
    });
    try {
      info.token = route.params.token;
      const decodeToken = JSON.parse(atob(info.token.split('.')[1]));
      info.id = decodeToken.employeeId;
      info.ticketId = decodeToken.ticketId;
    } catch (e) {
      message.error('Hubo un problema con el token');
    }
    const person = reactive({
      name: '',
      surname: ''
    });
    onMounted(async () => {
      const resp = await store.dispatch(FETCH_TICKET_ID, info.ticketId);
      const response = await store.dispatch(FETCH_CONSENT_TEXT, info.ticketId);
      if (resp && response && response.status === 200 && resp.status === 200) {
        textConsent.value = response.data;
        ticket.value = resp.data;
      }
      if (ticket.value.consentText == null) {
        checked.value = false;
      } else {
        checked.value = true;
      }
    });
    // const patchConsentimiento =async () =>{
    //   const response = await store.dispatch(PATCH_CONSENT_TEXT,info.ticketId);
    //   checked.value = true;
    //   textConsent.value=response.data;
    // };
    const getData = async () => {
      store
        .dispatch(FETCH_EMPLOYEE, info.id)
        .then((response) => {
          console.log('responseeeeeeeee', response);
          person.name = response.data.firstName;
          person.surname = response.data.firstSurname;
        })
        .catch((err) => {
          console.log(err.response);
        });
    };
    getData();
    const onPrevStep = () => {
      router.go(-1);
    };

    const onNextStep = () => {
      store
        .dispatch(SAVE_CONSENT_DATA, info.ticketId)
        .then(() => {
          router.push({
            name: 'Resume',
            params: { token: route.params.token }
          });
        })
        .catch((err) => {
          console.log(err.response);
        });
    };

    return {
      checked,
      person,
      onNextStep,
      onPrevStep,
      textConsent,
      ticket
      // patchConsentimiento
    };
  }
};
</script>
