import { dashboard } from '../../../network';
import {
  GET_ALL_DATA_DASHBOARD,
  GET_PERIOD_DATE_FILTER_DASHBOARD,
  SET_ALL_DATA_DASHBOARD,
  SET_PERIOD_DATE_FILTER_DASHBOARD,
  FETCH_ALL_DATA_DASHBOARD,
  SAVE_PERIOD_DATE_FILTER_DASHBOARD,
  SAVE_DATE_FILTER_DASHBOARD,
  SET_DATE_FILTER_DASHBOARD,
  GET_DATE_FILTER_DASHBOARD
} from './types';

const store = {};

const getDefaultState = () => ({
  allDataDashboard: [],
  date_range_filter: [],
  date_filter: ''
});

store.state = getDefaultState();

store.getters = {
  [GET_ALL_DATA_DASHBOARD]: (state) => state.allDataDashboard,
  [GET_PERIOD_DATE_FILTER_DASHBOARD]: (state) => state.date_range_filter,
  [GET_DATE_FILTER_DASHBOARD]: (state) => state.date_filter
};

store.mutations = {
  [SET_ALL_DATA_DASHBOARD](state, payload) {
    state.allDataDashboard = payload;
  },
  [SET_PERIOD_DATE_FILTER_DASHBOARD](state, payload) {
    state.date_range_filter = payload;
  },
  [SET_DATE_FILTER_DASHBOARD](state, payload) {
    state.date_filter = payload;
  }
};

store.actions = {
  [FETCH_ALL_DATA_DASHBOARD]({ commit }, payload) {
    console.log('FETCH_ALL_DATA_DASHBOARD', payload);
    return new Promise((resolve, reject) => {
      dashboard([payload]).then(
        (resp) => {
          commit(SET_ALL_DATA_DASHBOARD, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [SAVE_PERIOD_DATE_FILTER_DASHBOARD]({ commit }, payload) {
    commit(SET_PERIOD_DATE_FILTER_DASHBOARD, payload);
  },
  [SAVE_DATE_FILTER_DASHBOARD]({ commit }, payload) {
    commit(SET_DATE_FILTER_DASHBOARD, payload);
  }
};

export default store;
