<template>
  <div style="background-color: #fff">
    <a-row justify="center">
      <a-col :lg="14" :xl="14">
        <img class="img-fluid" src="@/assets/images/404.png" alt="" />
      </a-col>
    </a-row>
    <a-row justify="center">
      <a-col>
        <a-typography-title
          style="bottom: 0px; font-family: 'Urbanist'; text-align: center"
        >
          Esta página ya no esta disponible
        </a-typography-title>
        <p>
          Es posible que el enlace que has seguido este roto o que se haya
          eliminado la página
        </p>
      </a-col>
    </a-row>
    <a-row justify="center">
      <a-col>
        <a-button @click="home()" type="primary">Back Home</a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const home = () => {
      router.push({ name: 'Dashboard' });
    };

    return {
      home
    };
  }
};
</script>

<style></style>
