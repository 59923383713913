import { employeeStockSearch } from '../../network';
import {
  FETCH_GLOBAL_STOCK_QUERY,
  ACTION_DELETE_GLOBAL_STOCK_FILTERS,
  MUTATE_GLOBAL_STOCK_ROWS,
  MUTATE_GLOBAL_STOCK_TOTAL_PAGES,
  MUTATE_GLOBAL_STOCK_CURRENT_PAGE,
  MUTATE_GLOBAL_STOCK_ITEM_PER_PAGE,
  MUTATE_GLOBAL_STOCK_FILTERS,
  MUTATE_GLOBAL_STOCK_TAB,
  MUTATE_EMPTY_GLOBAL_STOCK_FILTERS,
  GET_GLOBAL_STOCK_ROWS_TYPE,
  GET_GLOBAL_STOCK_TOTAL_PAGES,
  GET_GLOBAL_STOCK_CURRENT_PAGE,
  GET_GLOBAL_STOCK_ITEM_PER_PAGE,
  GET_GLOBAL_STOCK_FILTERS,
  GET_GLOBAL_STOCK_DATE_RANGE_FILTERS,
  GET_GLOBAL_STOCK_TAB
} from './types';

const store = {};

// * State
store.state = {
  data: {},
  current_tab: ''
};

// * GETTERS
store.getters = {
  [GET_GLOBAL_STOCK_ROWS_TYPE]: (state) => (type) => {
    return state.data[type]?.rows;
  },
  [GET_GLOBAL_STOCK_TOTAL_PAGES]: (state) => (type) => {
    return state.data[type]?.total_pages;
  },
  [GET_GLOBAL_STOCK_CURRENT_PAGE]: (state) => (type) => {
    return state.data[type]?.current_page;
  },
  [GET_GLOBAL_STOCK_ITEM_PER_PAGE]: (state) => (type) => {
    return state.data[type]?.items_per_page;
  },
  [GET_GLOBAL_STOCK_FILTERS]: (state) => (type) => {
    return state.data[type]?.filters;
  },
  [GET_GLOBAL_STOCK_DATE_RANGE_FILTERS]: (state) => (type) => {
    return [
      state.data[type]?.filters?.startDate,
      state.data[type]?.filters?.endDate
    ];
  },
  [GET_GLOBAL_STOCK_TAB](state) {
    return state.current_tab;
  }
};

// * MUTATIONS
store.mutations = {
  [MUTATE_GLOBAL_STOCK_ROWS](state, payload) {
    if (state.data[payload.type] === undefined) {
      state.data[payload.type] = {};
    }

    state.data[payload.type]['rows'] = payload.data;
  },
  [MUTATE_GLOBAL_STOCK_TOTAL_PAGES](state, payload) {
    state.data[payload.type]['total_pages'] = payload.data;
  },
  [MUTATE_GLOBAL_STOCK_CURRENT_PAGE](state, payload) {
    state.data[payload.type]['current_page'] = payload.data;
  },
  [MUTATE_GLOBAL_STOCK_ITEM_PER_PAGE](state, payload) {
    state.data[payload.type]['items_per_page'] = payload.data;
  },
  [MUTATE_GLOBAL_STOCK_FILTERS](state, payload) {
    // En caso de que los filtros no existan, dejo el objeto vacio para
    // su creacion
    if (state.data[payload.type]['filters'] === undefined) {
      state.data[payload.type]['filters'] = {};
    }

    // Obtengo los filtros ya existentes
    const new_object = state.data[payload.type]['filters'];

    // Dejo los filtros en vacio para que no fallen en caso de
    state.data[payload.type]['filters'] = {};

    // Paso el nuevo valor
    new_object[Object.keys(payload.data)[0]] =
      payload.data[Object.keys(payload.data)[0]];

    // En caso de que venga la opcion de resolicitud en false, la voy a eliminar
    if (new_object.isRequesting === false) {
      delete new_object.isRequesting;
    }

    // Siempre que se haga un nuevo filtro, se tiene que dejar la paginacion en 1
    if (Object.keys(payload.data)[0] != 'page') {
      new_object['page'] = 1;
    }

    state.data[payload.type]['filters'] = new_object;
  },
  [MUTATE_GLOBAL_STOCK_TAB](state, payload) {
    state.current_tab = payload;
  },
  [MUTATE_EMPTY_GLOBAL_STOCK_FILTERS](state) {
    state.data = {};
  }
};

// * ACTIONS
store.actions = {
  [FETCH_GLOBAL_STOCK_QUERY]({ commit }, payload) {
    // depende del tipo de busuqeda que seleccione voy a cambiar la url
    const type_search = payload.type === 'tickets' ? 'search' : 'search_cases';
    return new Promise((resolve, reject) => {
      employeeStockSearch(type_search, payload.query)
        .then((resp) => {
          // Rows para la tabla
          commit(MUTATE_GLOBAL_STOCK_ROWS, {
            type: payload.type,
            data: resp.data.rows
          });
          // Total de paginas
          commit(MUTATE_GLOBAL_STOCK_TOTAL_PAGES, {
            type: payload.type,
            data: resp.data.total_items
          });
          // Pagina actual
          commit(MUTATE_GLOBAL_STOCK_CURRENT_PAGE, {
            type: payload.type,
            data: resp.data.current_page
          });
          // rows por pagina
          commit(MUTATE_GLOBAL_STOCK_ITEM_PER_PAGE, {
            type: payload.type,
            data: resp.data.items_per_page
          });

          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ACTION_DELETE_GLOBAL_STOCK_FILTERS]({ commit }) {
    commit(MUTATE_EMPTY_GLOBAL_STOCK_FILTERS);
  }
};

export default store;
