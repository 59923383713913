<template>
  <page-header :title="pageTitle" :breadcrumRoutes="routes">
    <template #tags>
      <tags v-if="casesData.length" :stateId="firstTag" />
      <tags :stateId="secondTag" />
      <tags :color="thirdTag.tagColor">
        {{ thirdTag.tagText }}
      </tags>
      <tags color="processing">
        <span>ID Caso <b>{{ caseId }}</b></span>
      </tags>
    </template>
  </page-header>
  <a-card class="mt-2">
    <a-row :gutter="16">
      <a-col :span="12">
        <datos-ticket :data="tableData" title="Datos caso" :loadTicket="loadTicket" />

        <solicitudes-asociadas :data="solicitudData" :stateId="solicitudData.currentState" v-if="user_role != 'rrhh'" />

        <casos-asociados :data="casosAsoc" />
      </a-col>

      <a-col :span="12">
        <solicitudes-asociadas :data="solicitudData" :stateId="solicitudData.currentState" v-if="user_role == 'rrhh'" />

        <records title="Historial del caso" :data="recordData" v-if="user_role != 'rrhh'"
          :loadingHistory="loadTicket" />
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
// Utils
import parseDate from '@/utils/dateFormatter';
import getTagColor from '@/utils/getTagColor';
// Components
import PageHeader from '@/components/PageHeader.vue';
import Tags from '@/components/Tags.vue';
import DatosTicket from '@/components/Tickets/TicketData.vue';
import Records from '@/components/Tickets/Records.vue';
import SolicitudesAsociadas from '@/components/Tickets/SolicitudesAsociadas.vue';
import CasosAsociados from '@/components/Tickets/CasosAsociados.vue';
import rutHelper from '@/utils/Ruts';
import {
  GET_USER_ROLE,
  FETCH_CASE_ID_DATA,
  FETCH_USER_BY_ID
} from '@/store/types';

export default {
  name: 'DetalleFueraTiempo',
  components: {
    'datos-ticket': DatosTicket,
    records: Records,
    'solicitudes-asociadas': SolicitudesAsociadas,
    'casos-asociados': CasosAsociados,
    'page-header': PageHeader,
    tags: Tags
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const caseId = ref(route.params.id);
    const pageTitle = ref('');
    const loadTicket = ref(false);

    // * Rutas
    const routes = [
      {
        path: '/fuera_de_tiempo',
        breadcrumbName: 'Fuera de tiempo'
      },
      {
        breadcrumbName: `#${caseId.value}`
      }
    ];

    // * VUEX
    const store = useStore();
    const user_role = store.getters[GET_USER_ROLE];

    // * Data
    const tableData = reactive([
      { key: 'Nombre', value: '' },
      { key: 'RUT', value: '' },
      { key: 'Correo', value: '' },
      { key: 'Empresa', value: '' },
      { key: 'Broker', value: '' }
    ]);

    const recordData = ref([]);
    const casesData = ref([]);
    const casosAsoc = ref([]);
    const solicitudData = ref({});
    const firstTag = ref('');
    const secondTag = ref('');
    const thirdTag = reactive({ tagColor: 'default', tagText: '' });
    const getData = async () => {
      loadTicket.value = true;
      try {
        const getCase = await store
          .dispatch(FETCH_CASE_ID_DATA, caseId.value)
          .catch(() => router.push({ name: 'Missing' }));

        if (![4, 5, 6, 7, 8, 9, 10, 11, 12].includes(getCase.data.typeCaseId)) {
          router.push({ name: 'Missing' });
        }

        const getUser = await store
          .dispatch(FETCH_USER_BY_ID, getCase.data.ticket.brokerId)
          .catch((err) => err.response);
        // const getAllCases = await caseTicketId(caseId.value).catch(
        //   (err) => err.response
        // );

        const { ticket } = getCase.data;
        const { states } = getCase.data;
        const { employee } = ticket;

        // const titleText =
        //   ticket.type.tag === 'Out of Time'
        //     ? 'Fuera de Tiempo'
        //     : ticket.type.tag;
        pageTitle.value = getCase.data.typeCase.tag;

        // * Fill Table Data
        tableData[0].value = `${employee.firstName} ${employee.firstSurname}`;
        tableData[1].value = rutHelper.formatRut(employee.rut);
        tableData[2].value = employee.email;
        tableData[3].value = ticket.company.businessName;
        tableData[4].value = getUser?.data?.body?.name
          ? getUser.data.body.name
          : 'Pendiente';

        //* Fill Records
        recordData.value = states.map((a) => {
          if (a.state.tag == 'Cancelado') {
            return {
              description: parseDate(a.createdAt),
              title: a.state.tag,
              status: 'error'
            };
          } else if (a.state.tag == 'Finalizado') {
            return {
              description: parseDate(a.createdAt),
              title: a.state.tag,
              status: 'finish'
            };
          }
          return {
            description: parseDate(a.createdAt),
            title: a.state.tag
          };
        });

        //* Fill Solicitudes Asociadas
        solicitudData.value = {
          ticketId: ticket.id,
          ticketType: ticket.type.tag,
          currentState: ticket.states[ticket.states.length - 1].state.id
        };

        //* Fill Casos Asociados
        const cases = getCase.data;
        casesData.value = [
          {
            casos: cases
          }
        ]
          .reduce((acc, item) => {
            return [...acc, item];
          }, [])
          .map((e) => ({
            ...e.casos
          }))
          .map((f) => {
            if (f.case) {
              const casoss = {
                ...f,
                case: [
                  {
                    ...f.case,
                    caseId: f.case.id,
                    caseType: f.case.typeCase.tag,
                    currentState: f.case.states.map((e) => e.state).pop().tag,
                    tagColor: getTagColor(
                      f.case.states.map((e) => e.state).pop().tag
                    ),
                    redirect: '2'
                  }
                ]
              };
              return casoss;
            }
          })
          .map((c) => c);

        casosAsoc.value = casesData.value
          .map((h) => h?.case[0])
          .filter((t) => t?.caseType === 'Antecedentes');

        // cases.case.redirect=cases.typeCase.tag === 'Out of Time'?'fuera_de_tiempo':cases.typeCase.tag.toLowerCase();
        // cases.case.caseType=cases.case.typeCaseId;
        // cases.case.currentState=getTagColor(cases.states.map(e=>e.state.tag));

        // cases.case.redirect=
        // return {
        //   caseId: a.ticketId,
        //   caseType: a.typeCase.tag,
        //   redirect:
        //     a.typeCase.tag === 'Out of Time'
        //       ? 'fuera_de_tiempo'
        //       : a.typeCase.tag.toLowerCase(),
        //   currentState: a.states[a.states.length - 1].state.tag,
        //   tagColor: getTagColor(a.states[a.states.length - 1].state.tag)
        // };

        //* Fill Tags

        const caseCurrentState = states[states.length - 1]?.state.id;
        firstTag.value = caseCurrentState;

        const ticketStates = ticket?.states;
        const ticketCurrentState =
          ticketStates[ticketStates.length - 1].state.id;
        secondTag.value = ticketCurrentState;

        const today = new Date();
        const created = new Date(ticketStates[0].createdAt);
        const resultDays = Math.round(
          (today.getTime() - created.getTime()) / (1000 * 3600 * 24)
        );
        const text = resultDays > 1 ? 'DÍAS' : 'DÍA';
        thirdTag.tagText = `${resultDays} ${text}`;
        loadTicket.value = false;
      } catch (error) {
        loadTicket.value = false;
        console.log('error', error);
      }
    };
    getData();

    return {
      // Variables
      routes,
      pageTitle,
      caseId,
      tableData,
      recordData,
      solicitudData,
      firstTag,
      secondTag,
      thirdTag,
      casesData,
      user_role,
      casosAsoc,
      loadTicket
    };
  }
};
</script>
