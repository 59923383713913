export const FETCH_TYPE_CASES = 'cases/FETCH_TYPE_CASES';
export const FETCH_CASES = 'cases/FETCH_CASES';
export const FETCH_CASE_ID_DATA = 'cases/FETCH_CASEID_DATA';
export const FETCH_FILES_TICKET_ID = 'cases/FETCH_FILES_TICKET_ID';

export const POST_CREATE_STATE_CASE = 'casos/POST_CREATE_STATE_CASE';

export const SET_FILE = 'cases/SET_FILE';
export const SET_CASES = 'cases/SET_CASES';
export const SET_TYPE_CASES = 'cases/SET_TYPE_CASES';
export const SET_SELECTED = 'cases/SET_SELECTED';
export const SET_CASE_TICKET_ID = 'cases/SET_CASE_TICKET_ID';

export const SELECTED_TYPE = 'cases/SELECTED_TYPE';

export const POST_FILE_ANTECEDENTES = 'cases/POST_FILE_ANTECEDENTES';
export const GET_CASE_TICKET_ID = 'cases/GET_CASE_TICKET_ID';

// * Case Antecedentes
export const FETCH_ANTECEDENTES_CASES = 'antecedentes/FETCH_ANTECEDENTES_CASES';
export const FETCH_VERIFY_CASE_ANTECEDENTES =
  'antecedentes/FETCH_VERIFY_CASE_ANTECEDENTES';

export const POST_CASE_FILE_UPLOAD = 'antecedentes/POST_CASE_FILE_UPLOAD';
export const UPDATE_INSURANCE_STATE = 'antecedentesUPDATE_INSURANCE_STATE';

export const GET_ANTECEDENTES_CASES = 'antecedentes/GET_ANTECEDENTES_CASES';
export const GET_VERIFY_CASE_ANTECEDENTES =
  'antecedentes/GET_VERIFY_CASE_ANTECEDENTES';

export const SET_ANTECEDENTES_CASES = 'antecedentes/SET_ANTECEDENTES_CASES';
export const SET_VERIFY_CASE_ANTECEDENTES =
  'antecedentes/SET_VERIFY_CASE_ANTECEDENTES';

// * Case Out of time
export const GET_OUTOFTIME_CASES = 'outOfTime/GET_OUTOFTIME_CASES';

export const SET_OUTOFTIME_CASES = 'outOfTime/SET_OUTOFTIME_CASES';

export const FETCH_OUTOFTIME_CASES = 'outOfTime/FETCH_OUTOFTIME_CASES';

// * Case Rechazo
export const GET_RECHAZO_CASES = 'rechazo/GET_RECHAZO_CASES';
export const GET_APPEAL_EMAILS = 'rechazo/GET_APPEAL_EMAILS';

export const SET_RECHAZO_CASES = 'rechazo/SET_RECHAZO_CASES';
export const SET_APPEAL_EMAIL = 'rechazo/SET_APPEAL_EMAIL';

export const FETCH_RECHAZO_CASES = 'rechazo/FETCH_RECHAZO_CASES';
export const FETCH_APPEAL_EMAILS = 'rechazo/FETCH_APPEAL_EMAILS';
export const POST_REJECTION_APPEAL = 'rechazo/POST_REJECTION_APPEAL';
export const POST_APROVE_OR_DENY_APPEAL = 'rechazo/POST_APROVE_OR_DENY_APPEAL';
export const POST_CASE_RECHAZO = 'rechazo/POST_CASE_RECHAZO';
