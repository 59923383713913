import config from '../config';

const appConfig = config();
const apiTicket = appConfig.ticket_prefix;
const { v1 } = appConfig.apiVersion;

const digitalform = (client) => ({
  // --> Obtener informacion de el formulario digital
  getDigitalForm: (payload) =>
    client.get(`${apiTicket}${v1}/digital-form`, {
      headers: { Authorization: `Bearer ${payload}` }
    }),
  // --> Obtener informacion de el formulario digital
  getDigitalFormResetedToken: (payload) =>
    client.post(
      `${apiTicket}${v1}/digital-form/reset-token`,
      {},
      {
        headers: { Authorization: `Bearer ${payload}` }
      }
    ),
  // --> Obtener compania
  getDigitalFormCompany: (payload) =>
    client.get(`${apiTicket}${v1}/digital-form/companies`, {
      headers: { Authorization: `Bearer ${payload}` }
    }),
  // --> Obtener polizas
  getDigitalFormPolicies: (payload) =>
    client.get(`${apiTicket}${v1}/digital-form/policies`, {
      headers: { Authorization: `Bearer ${payload}` }
    }),
  // --> Obtener polizas
  saveDigitalFormPolicies: (payload, token) =>
    client.put(`${apiTicket}${v1}/digital-form/policies`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Obtener sistema de salud previsional
  getPrevisionalHealthSystem: (payload) =>
    client.get(`${apiTicket}${v1}/digital-form/healthcare-systems`, {
      headers: { Authorization: `Bearer ${payload}` }
    }),
  // --> Obtener municipalidades
  getMunicipalities: (payload) =>
    client.get(`${apiTicket}${v1}/digital-form/municipalities`, {
      headers: { Authorization: `Bearer ${payload}` }
    }),
  // --> Obtener estaodocivil
  getDigitalFormMaritalStatus: (payload) =>
    client.get(`${apiTicket}${v1}/digital-form/marital-statuses`, {
      headers: { Authorization: `Bearer ${payload}` }
    }),
  // --> Obtener cargas
  getDependants: (payload) =>
    client.get(`${apiTicket}${v1}/digital-form/dependents`, {
      headers: { Authorization: `Bearer ${payload}` }
    }),
  // --> Guardar cargas
  putDependants: (payload, token) =>
    client.put(`${apiTicket}${v1}/digital-form/dependents`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Obtener beneficiarios
  getBeneficiares: (payload) =>
    client.get(`${apiTicket}${v1}/digital-form/beneficiaries`, {
      headers: { Authorization: `Bearer ${payload}` }
    }),
  // --> Guardar beneficiarios
  putBeneficiares: (payload, token) =>
    client.put(`${apiTicket}${v1}/digital-form/beneficiaries`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Obtener lista de asegurados por formulario
  getInsureds: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/insureds`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Agregar asegurados por formulario
  putInsureds: (payload, token) =>
    client.put(`${apiTicket}${v1}/digital-form/diseases-declaration`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Obtener asegurados por formulario
  getInsuredsWithDiseases: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/diseases-declaration`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Obtener enfermedades
  getDiseases: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/diseases`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Obtener bancos
  getBanks: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/banks`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Obtener deportes
  getSports: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/sports`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Guardar empleado
  putDigitalFormEmployee: (payload, token) =>
    client.put(`${apiTicket}${v1}/digital-form/employee`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Obtener empleado
  getDigitalFormEmployee: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/employee`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Guardar forma de pago reembolso salud
  putPaymentMethod: (payload, token) =>
    client.put(`${apiTicket}${v1}/digital-form/payment-method`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Obtener forma de pago reembolso salud
  getPaymentMethod: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/payment-method`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // Guardar Anverso de el empleado
  putObverse: (payload, token) =>
    client.put(
      `${apiTicket}${v1}/digital-form/employee-photos/obverse`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  // Obtener Anverso de el empleado
  getObverse: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/employee-photos/obverse`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // Guardar Reverso de el empleado
  putReverse: (payload, token) =>
    client.put(
      `${apiTicket}${v1}/digital-form/employee-photos/reverse`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  // Obtener Reverso de el empleado
  getReverse: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/employee-photos/reverse`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Guardar deportes
  putSports: (payload, token) =>
    client.put(`${apiTicket}${v1}/digital-form/sports-declaration`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Guardar asegurados con deportes
  getInsuredWithSports: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/sports-declaration`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Document previsualization
  getPrevisualizationFile: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/preview-form`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob'
    }),
  // --> Obtener opciones de mascotas
  getPets: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/pets`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Obtener mascotas declaradas
  getDeclaredPets: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/pets-declaration`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Guardar mascotas declaradas
  putDeclaredPets: (payload, token) =>
    client.put(`${apiTicket}${v1}/digital-form/pets-declaration`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Guardar concentimiento
  putConsent: (payload, token) =>
    client.put(`${apiTicket}${v1}/digital-form/consent`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Obtener concentimiento
  getConsent: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/consent`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Obtener codigo para firmar documento
  getCodeToSign: (token) =>
    client.post(
      `${apiTicket}${v1}/digital-form/sign/request`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  // --> Firmar documento con el codigo
  signDocument: (token, payload) =>
    client.post(`${apiTicket}${v1}/digital-form/sign`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Firmar documento con el codigo
  firmState: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/sign`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  // --> Obtener logo para el formulario
  digitalFormLogo: (token) =>
    client.get(`${apiTicket}${v1}/digital-form/insurance-logo`, {
      headers: { Authorization: `Bearer ${token}` }
    })
});
export default digitalform;
