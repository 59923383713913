// * FETCH
export const FETCH_COVERAGE_POLICY = 'altaIndividual/FETCH_COVERAGE_POLICY';
export const FETCH_COMPANIES = 'altaIndividual/FETCH_COMPANIES';
export const FETCH_GROUP_COMPANIES = 'altaIndividual/FETCH_GROUP_COMPANIES';
export const FETCH_INSURANCE_COMPANIES =
  'altaIndividual/FETCH_INSURANCE_COMPANIES';
export const FETCH_INSURANCE_COMPANIES_DOCUMENTS =
  'altaIndividual/FETCH_INSURANCE_COMPANIES_DOCUMENTS';
export const FETCH_INSURANCE_COMPANY_POLICY =
  'altaIndividual/FETCH_INSURANCE_COMPANY_POLICY';
export const FETCH_EMPLOYEE_DATA_BY_RUT =
  'altaIndividual/FETCH_EMPLOYEE_DATA_BY_RUT';
export const FETCH_FILE_BY_TICKET = 'altaIndividual/FETCH_FILE_BY_TICKET';
export const FETCH_FILES_HISTORY = 'altaIndividual/FETCH_FILES_HISTORY';
export const FETCH_TRANSFER_INFORMATION =
  'altaIndividual/FETCH_TRANSFER_INFORMATION';
export const FETCH_TRANSFER_GROUPS = 'altaIndividual/FETCH_TRANSFER_GROUPS';
export const FETCH_TICKET_INCOME = 'altaIndividual/FETCH_TICKET_INCOME';

// * GETTERS
export const GET_COVERAGE_POLICY = 'altaIndividual/GET_COVERAGE_POLICY';
export const GET_COMPANIES = 'altaIndividual/GET_COMPANIES';
export const GET_GROUP_COMPANIES = 'altaIndividual/GET_GROUP_COMPANIES';
export const GET_INSURANCE_COMPANIES = 'altaIndividual/GET_INSURANCE_COMPANIES';
export const GET_INSURANCE_COMPANY_POLICY =
  'altaIndividual/GET_INSURANCE_COMPANY_POLICY';
export const GET_INSURANCE_COMPANIES_DOCUMENTS =
  'altaIndividual/GET_INSURANCE_COMPANIES_DOCUMENTS';
export const GET_EMPLOYEE_DATA = 'altaIndividual/GET_EMPLOYEE_DATA';
export const GET_DASHBOARD_OPTION_SELECTION =
  'altaIndividual/GET_DASHBOARD_OPTION_SELECTION';
export const GET_FILE_TICKET = 'altaIndividual/GET_FILE_TICKET';
export const GET_FILES_HISTORY = 'altaIndividual/GET_FILES_HISTORY';

// * SETTERS
export const SET_COVERAGE_POLICY = 'altaIndividual/SET_COVERAGE_POLICY';
export const SET_COMPANIES = 'altaIndividual/SET_COMPANIES';
export const SET_GROUP_COMPANIES = 'altaIndividual/SET_GROUP_COMPANIES';
export const SET_INSURANCE_COMPANIES = 'altaIndividual/SET_INSURANCE_COMPANIES';
export const SET_INSURANCE_COMPANY_POLICY =
  'altaIndividual/SET_INSURANCE_COMPANY_POLICY';
export const SET_INSURANCE_COMPANIES_DOCUMENTS =
  'altaIndividual/SET_INSURANCE_COMPANIES_DOCUMENTS';
export const SET_EMPLOYEE_DATA = 'altaIndividual/SET_EMPLOYEE_DATA';
export const SET_DASHBOARD_OPTION_SELECTION =
  'altaIndividual/SET_DASHBOARD_OPTION_SELECTION';
export const SET_FILE_TICKET = 'altaIndividual/SET_FILE_TICKET';
export const SET_FILES_HISTORY = 'altaIndividual/SET_FILES_HISTORY';

// * POST
export const POST_ALTA_INDIVIDUAL_TICKET_TO_COMPANY =
  'altaIndividual/POST_ALTA_INDIVIDUAL_TICKET_TO_COMPANY';
export const POST_ALTA_INDIVIDUAL_TICKET_TO_COLLABORATOR =
  'altaIndividual/POST_ALTA_INDIVIDUAL_TICKET_TO_COLLABORATOR';
export const SAVE_TICKET_DISPLAY_SELECTION =
  'altaIndividual/SAVE_TICKET_DISPLAY_SELECTION';
export const TICKET_RESEND_FORM_INCORPORATION =
  'altaIndividual/TICKET_RESEND_FORM_INCORPORATION';
export const PUT_REAPPLY_FILE = 'altaIndividual/PUT_REAPPLY_FILE';
export const POST_FINISH_ALTA_INDIVIDUAL =
  'altaIndividual/POST_FINISH_ALTA_INDIVIDUAL';
export const POST_SEND_INFO_TO_INSURANCE_COMPANY =
  'altaIndividual/POST_SEND_INFO_TO_INSURANCE_COMPANY';
export const POST_CANCEL_TICKET_ALTA = 'altaIndividual/POST_CANCEL_TICKET_ALTA';
export const EDIT_INDIVIDUAL_ALTA_COLABORATOR_TICKET =
  'altaIndividual/EDIT_INDIVIDUAL_ALTA_COLABORATOR_TICKET';
