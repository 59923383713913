<template>
  <div>
    <a-modal
      v-model:visible="visible"
      width="1000px"
      title="Historial de Reprocesos"
    >
      <a-table
        :loading="loading"
        :data-source="data_table"
        :columns="columns"
        :pagination="false"
      >
        <template #actionRender="{ text: route }">
          <a-tooltip color="#05045D" title="Ver" @click="closeModal">
            <router-link :to="route">
              <eye-outlined class="blue-icon" />
            </router-link>
          </a-tooltip>
        </template>
      </a-table>

      <template #footer>
        <a-button type="primary" @click="closeModal">Ok</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { EyeOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';

export default {
  components: {
    'eye-outlined': EyeOutlined
  },
  props: {
    ticketId: {
      type: [String, Number],
      default: 1
    },
    data: {
      type: Array,
      default: []
    }
  },
  setup(props) {
    const loading = ref(false);
    const visible = ref(false);
    const route = useRoute();
    const params_id = parseInt(route.params.id);
    const data_table = computed(() => {
      return props.data.map((obj, index) => {
        const route_obj = {
          ...obj,
          key: obj.id,
          detail: {
            name: 'detalleTicketCobranza',
            params: { id: params_id }
          }
        };

        if (index != props.data.length - 1) {
          route_obj.detail.query = { collectionResourceId: obj.id };
        }

        return route_obj;
      });
    });
    const columns = [
      {
        title: 'Reprocesos',
        dataIndex: 'reprocess',
        key: 'reprocess'
      },
      {
        title: 'Archivo',
        dataIndex: 'fileName',
        key: 'fileName'
      },
      {
        title: 'Fecha',
        dataIndex: 'date',
        key: 'date'
      },
      {
        title: 'Opciones',
        dataIndex: 'detail',
        key: 'detail',
        slots: {
          customRender: 'actionRender'
        }
      }
    ];

    const openModal = () => {
      visible.value = true;
    };

    const closeModal = () => {
      visible.value = false;
    };

    return {
      visible,
      loading,
      columns,
      openModal,
      closeModal,
      data_table
    };
  }
};
</script>
