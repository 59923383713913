<template>
  <a-collapse class="mb-2" v-model:activeKey="activeKey">
    <a-collapse-panel key="1" header="Mensaje a compañía aseguradora">
      <a-typography-text
        v-html="message ? message : 'No hay información.'"
        class="text-gray-8"
      ></a-typography-text>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  name: 'MensajeAseguradora',
  props: {
    message: ''
  },
  setup() {
    const activeKey = ref(['1']);
    watch(activeKey, () => {});

    return {
      activeKey
    };
  }
};
</script>
