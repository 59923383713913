// GETTERS
export const GET_AM_COMPANIES = 'altaMasivas/GET_AM_COMPANIES';
export const GET_AM_INSURANCES = 'altaMasivas/GET_AM_INSURANCES';
export const GET_AM_DOCS = 'altaMasivas/GET_AM_DOCS';
export const GET_AM_FORM = 'altaMasivas/GET_AM_FORM';
export const GET_AM_URL = 'altaMasivas/GET_AM_URL';
export const GET_AM_TICKETS = 'altaMasivas/GET_AM_TICKETS';
export const GET_AI_TICKETS = 'altaMasivas/GET_AI_TICKETS';
export const GET_AM_DETAIL = 'altaMasivas/GET_AM_DETAIL';
export const GET_AM_DETAIL_FILE = 'altaMasivas/GET_AM_DETAIL_FILE';

// SETTERS
export const SET_AM_COMPANIES = 'altaMasivas/SET_AM_COMPANIES';
export const SET_AM_INSURANCES = 'altaMasivas/SET_AM_INSURANCES';
export const SET_AM_DOCS = 'altaMasivas/SET_AM_DOCS';
export const SET_AM_FORM = 'altaMasivas/SET_AM_FORM';
export const SET_AM_URL = 'altaMasivas/SET_AM_URL';
export const SET_AM_TICKETS = 'altaMasivas/SET_AM_TICKETS';
export const SET_AM_DETAIL = 'altaMasivas/SET_AM_DETAIL';
export const SET_AM_DETAIL_FILE = 'altaMasivas/SET_AM_DETAIL_FILE';
export const SET_AI_TICKETS = 'altaMasivas/SET_AI_TICKETS';

// ACTIONS
export const FETCH_AM_COMPANIES = 'altaMasivas/FETCH_AM_COMPANIES';
export const FETCH_AM_INSURANCES = 'altaMasivas/FETCH_AM_INSURANCES';
export const FETCH_AM_DOCS = 'altaMasivas/FETCH_AM_DOCS';
export const FETCH_AM_FORM = 'altaMasivas/FETCH_AM_FORM';
export const FETCH_AM_URL = 'altaMasivas/FETCH_AM_URL';
export const FETCH_AM_TICKETS = 'altaMasivas/FETCH_AM_TICKETS';
export const FETCH_AM_DETAIL = 'altaMasivas/FETCH_AM_DETAIL';
export const FETCH_AM_DETAIL_FILE = 'altaMasivas/FETCH_AM_DETAIL_FILE';
export const FETCH_AI_TICKETS = 'altaMasivas/FETCH_AI_TICKETS';
export const FETCH_BAJA_STOCK_FORMAT = 'altaMasivas/FETCH_BAJA_STOCK_FORMAT';
export const URL_MASSIVE_INCORPORATION_TO_EMPLOYEE_TEMPLATE =
  'altaMasivas/URL_MASSIVE_INCORPORATION_TO_EMPLOYEE_TEMPLATE';

export const POST_ALTA_MASIVA = 'altaMasivas/POST_ALTA_MASIVA';
export const POST_RESEEND_MAIL = 'altaMasivas/POST_RESEEND_MAIL';
export const PRECANCEL_ALTA_MASIVA = 'altaMasivas/PRECANCEL_ALTA_MASIVA';
export const CANCEL_ALTA_MASIVA = 'altaMasivas/CANCEL_ALTA_MASIVA';
export const PRESUCCESS_ALTA_MASIVA = 'altaMasivas/PRESUCCESS_ALTA_MASIVA';
export const SUCCESS_ALTA_MASIVA = 'altaMasivas/SUCCESS_ALTA_MASIVA';
export const POST_EXCEL_BAJA_STOCK = 'altaMasivas/POST_EXCEL_BAJA_STOCK';
