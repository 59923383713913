<template>
  <div>
    <a-typography-title :level="4" class="mt-3 text-gray">Configuración</a-typography-title>
    <a-typography-text class="text-gray-8">
      Rellena los siguientes campos para configurar las cobranzas asociadas a
      los números de póliza
      {{
        polizasConfightml.map((e) => `#${e.policyNumber}`).toString()
      }}</a-typography-text>
    <a-form :model="formState" name="form" ref="formRef" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }"
      :rules="rules">
      <!-- <a-card class="mt-4" style="height: 80px;">
        <a-row type="flex" justify="space-between">
          <a-col :span="8">
          <a-typography-title :level="5" class="fw-regular mr-5">
            Selecciona cobranza y facturación:
          </a-typography-title>
          </a-col>
          <a-col :span="16">
            <a-col :span="14">
          <a-form-item
            required
            has-feedback
            class="fw-regular"
            name="typeselect"
            ref="typeselect"
            label=""
          >
            <a-radio-group
              v-model:value="formState.typeSelected"
              @change="handleChange"
              :options="optionSelected"
              />
          </a-form-item>
            </a-col>
          </a-col>
        </a-row>
      </a-card> -->
      <a-row class="mt-4">
        <a-col :span="12">
          <a-card style="width: 490px">
            <a-typography-title :level="5" class="text-gray mr-5">
              Modalidad de cobranza
            </a-typography-title>
            <a-form-item required has-feedback class="text-gray mr-5" name="typeselect" ref="typeselect">
              <a-row>
                <a-radio-group class="mt-2" v-model:value="formState.typeSelected" @change="handleChange"
                  :options="optionSelected" />
              </a-row>
            </a-form-item>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card>
            <a-typography-title :level="5" class="text-gray mr-5">
              Tipo de cobranza
            </a-typography-title>
            <a-form-item required has-feedback class="text-gray mr-5" name="selectTypeCobranza"
              ref="selectTypeCobranza">
              <a-row>
                <a-radio-group class="mt-2" v-model:value="formState.SelectTypeCobranza" @change="handleChangeCobranza"
                  :options="Cobranza" />
              </a-row>
            </a-form-item>
          </a-card>
        </a-col>
      </a-row>
      <!-- <a-card class="mt-4">
        <a-row type="flex" justify="space-between" align="middle">
           <a-col>
            <a-typography-title :level="5" class="text-gray mr-5">
              Emisión de cobranza
                <a-tooltip placement="topLeft" title="Días entre el mes en cobro y el mes siguiente">
                  <info-circle-outlined :style="{fontSize: '18px'}"/>
                </a-tooltip>
            </a-typography-title>
            <a-typography-text class="text-gray-8">
              Selecciona un rango de fecha de emisión de cobranza
            </a-typography-text>
            <a-row>
              <a-col :span="11">
                <a-form-item required has-feedback name="startDate" ref="startDate" class="mt-3 mr-5" label="Entre día:">
                  <a-input
                    v-model:value="formState.startDate"
                    placeholder="0"
                    type="number"
                    @change="handleDateStart"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="11">
                <a-form-item
                required has-feedback
                  class="mt-3 ml-4"
                  label="Hasta el día:"
                  name="finishDate"
                  ref="finishDate"
                >
                  <a-input

                    class="mr-5"
                    v-model:value="formState.finishDate"
                    @change="handleDateFinish"
                    placeholder="0"
                    type="number"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col> </a-col>
        </a-row>
      </a-card> -->
      <!--
      <a-row class="mt-4">
        <a-col :span="12">
          <a-card style="width: 490px;">
            <a-typography-title :level="5" class="text-gray mr-5">
              Periodicidad
            </a-typography-title>
            <a-radio-group
              class="mt-2"
              v-model:value="SelectPeriodicidad"
              @change="handleChange"
              :options="periodicidad"
            />
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card style="width: 490px;">
            <a-typography-title :level="5" class="text-gray mr-5">
              Tipo de Cobranza
            </a-typography-title>
            <a-form-item
            required has-feedback
            class="text-gray mr-5"
            name="selectTypeCobranza"
            ref="selectTypeCobranza"
          >
          <a-row>
            <a-radio-group
              class="mt-2"
              v-model:value="formState.SelectTypeCobranza"
              @change="handleChangeCobranza"
              :options="Cobranza"
            />
          </a-row>
            </a-form-item>
          </a-card>
        </a-col>
      </a-row> -->

      <a-row class="mt-4">
        <a-col :span="12">
          <a-card style="width: 490px">
            <a-typography-title :level="5" class="text-gray mr-5">
              Fecha de corte
              <a-tooltip placement="topLeft"
                title="Fecha corte para registro de movimientos (Altas, bajas y modificaciones">
                <info-circle-outlined :style="{ fontSize: '18px' }" />
              </a-tooltip>
            </a-typography-title>
            <a-typography-text class="text-gray-8">
              Día corte de cada mes</a-typography-text>
            <!-- <a-radio-group
            class="mt-2"
                v-model:value="typeCobranza"
                @change="handleChange"
                :options="options"
              /> -->
            <a-row>
              <a-col :span="19">
                <a-form-item required has-feedback name="cutDate" ref="cutDate" class="mt-2 mr-5" label="">
                  <a-input v-model:value="formState.cutDate" placeholder="0" type="number" style="width: 200px"
                    @change="handleFechaCorte" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card>
            <a-typography-title :level="5" class="text-gray mr-5">
              Pago de factura
              <a-tooltip placement="topLeft" title="Plazo máximo de pago desde la emisión de la factura">
                <info-circle-outlined :style="{ fontSize: '18px' }" />
              </a-tooltip>
            </a-typography-title>
            <a-typography-text class="text-gray-8">
              Plazo máximo de pago</a-typography-text>
            <!-- <a-radio-group
            class="mt-2"
                v-model:value="typeCobranza"
                @change="handleChange"
                :options="options"
              /> -->
            <a-row>
              <a-col :span="18">
                <a-form-item required has-feedback name="invoicePayment" ref="invoicePayment" class="mt-2 mr-5"
                  label="">
                  <a-select v-model:value="formState.invoicePayment" placeholder="Selecciona pago factura"
                    :options="dataInvoicePayment" @change="handleFactura" style="width: 205px"></a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
      <a-row class="mt-4">
        <a-col :span="12">
          <a-card style="width: 490px">
            <a-typography-title :level="5" class="text-gray mr-5">
              Corte de Beneficios
            </a-typography-title>
            <a-typography-text class="text-gray-8">
              Días mora desde atraso en pago de factura</a-typography-text>
            <!-- <a-radio-group
            class="mt-2"
                v-model:value="typeCobranza"
                @change="handleChange"
                :options="options"
              /> -->
            <a-row>
              <a-col :span="21">
                <a-form-item required has-feedback name="benefitCut" ref="benefitCut" class="mt-2 mr-5" label="">
                  <a-select v-model:value="formState.benefitCut" placeholder="Selecciona pago factura"
                    :options="dataCutOffsPolicy" @change="handleCorteBeneficio" style="width: 225px"></a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col :span="12"> </a-col>
      </a-row>
      <a-row justify="end">
        <a-col>
          <a-button class="mt-4" @click="handleConfiguration" type="primary">Guardar configuración</a-button>
        </a-col>
      </a-row>
    </a-form>

    <a-modal centered v-model:visible="visible" title="Resumen Configuración" @ok="handleOk">
      <template #footer>
        <a-row type="flex" justify="end">
          <a-col>
            <a-space>
              <a-button @click="handleOk" :disabled="loading">Cancelar</a-button>
              <a-button key="submit" type="primary" @click="onSubmit" :loading="loading">Guardar</a-button>
            </a-space>
          </a-col>
        </a-row>
      </template>
      <a-typography-title class="text-gray-8 fw-regular" :level="4">
        Cobranza y facturación
      </a-typography-title>
      <a-typography-title class="text-gray-8 fw-regular mb-4" :level="5">Revisa que los datos ingresados esten
        correctos.
      </a-typography-title>
      <a-descriptions class="mb-4" bordered>
        <a-descriptions-item label="cobranza y facturación" :span="3">
          {{ typeSelectedCobranza }}
        </a-descriptions-item>
        <!-- <a-descriptions-item label="Emisión cobranza" :span="3">
        Entre el {{formState.startDate}} y el {{formState.finishDate}}
      </a-descriptions-item> -->
        <a-descriptions-item label="Tipo cobranza" :span="3">
          {{ typeCobranzaHtml }}
        </a-descriptions-item>
        <a-descriptions-item label="Fecha de corte" :span="3">
          Día {{ formState.cutDate }}
        </a-descriptions-item>
        <a-descriptions-item label="Pago de factura" :span="3">
          {{ dayInvoicePayment }}
        </a-descriptions-item>
        <a-descriptions-item label="Corte de beneficio" :span="3">
          {{ dayCutBenefict }}
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>

    <template v-if="show_sucess">
      <a-modal v-model:visible="show_sucess" centered footer="" @cancel="show_sucess = false">
        <a-result status="success" title="Configuración Creada" sub-title="Tu configuración ha sido creada con éxito.">
          <template #extra>
            <router-link class="justify-content-center d-flex" :to="{
              name: 'cobranzaFacturacionTabs',
              query: { status: status_redirection }
            }">
              <a-button key="console" type="primary">Ver Configuración</a-button>
            </router-link>
          </template>
        </a-result>
      </a-modal>
    </template>
  </div>
</template>

<script>
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { computed, reactive, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import {
  POST_CONFIG_POLICY,
  GET_COMPANY_POLICIES,
  FETCH_INVOICE_PAYMENT_POLICY,
  GET_INVOICE_PAYMENT_POLICY,
  FETCH_CUT_OFFS_POLICY,
  GET_CUT_OFFS_POLICY
} from '@/store/types';

export default {
  name: 'CobranzaFacturacionTabs',
  components: {
    'info-circle-outlined': InfoCircleOutlined
  },
  props: ['polizaconfig'],
  emits: ['show'],
  setup(props) {
    // * Notificaciones
    const loading = ref(false);
    const store = useStore();
    const showError = ref(false);
    /* modal */
    const visible = ref(false);
    const route = useRoute();
    const router = useRouter();
    const show_sucess = ref(false);
    // onMounted(() => {
    //   router.replace({
    //     name: 'Empresas',
    //     query: {}
    //   });
    // });
    const formState = reactive({
      startDate: null,
      finishDate: null,
      typeSelected: '',
      SelectTypeCobranza: '',
      cutDate: '',
      invoicePayment: 'Seleccione plazo',
      benefitCut: 'Seleccione atraso pago'
    });

    const polizas = route.params.selected;
    const showLista = computed(() => props.listPolizaState);
    const status_redirection = route.params.status;
    const polizasConfightml = computed(() =>
      store.getters[GET_COMPANY_POLICIES].filter((f) =>
        polizas?.some((h) => f.id == h)
      )
    );
    const typeCobranzaHtml = computed(() =>
      Cobranza.filter((f) => f.value == formState.SelectTypeCobranza)
        .map((e) => e.label)
        .toString()
    );
    const typeSelectedCobranza = computed(() =>
      optionSelected
        .filter((f) => f.value == formState.typeSelected)
        .map((e) => e.label)
        .toString()
    );
    const dataInvoicePayment = computed(
      () => store.getters[GET_INVOICE_PAYMENT_POLICY]
    );
    const dayInvoicePayment = computed(() =>
      dataInvoicePayment.value
        .filter((f) => f.value == formState.invoicePayment)
        .map((e) => e.label)
        .toString()
    );
    const dataCutOffsPolicy = computed(
      () => store.getters[GET_CUT_OFFS_POLICY]
    );
    const dayCutBenefict = computed(() =>
      dataCutOffsPolicy.value
        .filter((f) => f.value == formState.benefitCut)
        .map((e) => e.label)
        .toString()
    );

    // selecion de cobranza y factura
    const optionSelected = [
      {
        label: 'Vencida',
        value: 'expired'
      },
      {
        label: 'Anticipada',
        value: 'anticipated'
      }
    ];

    // periodicidad
    // const SelectPeriodicidad = ref('');
    const periodicidad = [
      {
        label: 'Anual',
        value: 'Anual'
      },
      {
        label: 'Mensual',
        value: 'Mensual'
      }
    ];

    // tipo de cobranza
    const Cobranza = [
      {
        label: 'Cobranza',
        value: 'collection'
      },
      {
        label: 'Pre-cobranza',
        value: 'precollection'
      }
    ];

    const handleChange = (value) => {
      formState.typeSelected = value.target.value;
    };
    const handleDateStart = () => {
      if (formState.startDate > 0 && formState.startDate <= 31) {
        return formState.startDate;
      }
      return (formState.startDate = '');
    };
    const handleDateFinish = () => {
      if (formState.finishDate > 0 && formState.finishDate <= 31) {
        return formState.finishDate;
      }
      return (formState.finishDate = '');
    };
    const handleChangeCobranza = (value) => {
      formState.SelectTypeCobranza = value.target.value;
    };
    const handleFactura = (value) => {
      formState.invoicePayment = value;
    };
    const handleFechaCorte = () => {
      if (formState.cutDate > 0 && formState.cutDate <= 31) {
        return formState.cutDate;
      }
      return (formState.cutDate = '');
    };
    const handleCorteBeneficio = (value) => {
      formState.benefitCut = value;
    };

    const formRef = ref();
    const handleConfiguration = async () => {
      formRef.value
        .validate()
        .then(() => {
          visible.value = true;
        })
        .catch((error) => {
          console.log('errr', error);
        });
      // if(formState.typeSelected!==''){
      //   formRef.value.resetFields('typeselect');
      // }
      // if(formState.startDate!==''){
      //   formRef.value.resetFields('startDate');
      // }
    };
    const checkType = async (rule, value) => {
      if (formState.typeSelected === '' && value === undefined) {
        return Promise.reject('Seleccione tipo Cobranza y facturación');
      }
      return Promise.resolve();
    };
    const checkSelectTypeCobranza = async (rule, value) => {
      if (formState.SelectTypeCobranza === '' && value === undefined) {
        return Promise.reject('Seleccione tipo Cobranza');
      }
      return Promise.resolve();
    };
    const checkBenefitCut = async (rule, value) => {
      if (value === 'Seleccione atraso pago') {
        return Promise.reject('Seleccione Corte beneficio');
      }
      return Promise.resolve();
    };

    const rules = {
      typeselect: [
        {
          required: true,
          validator: checkType,
          trigger: 'change'
        }
      ],
      startDate: [
        {
          required: true,
          message: 'Seleccione un día',
          type: 'string',
          trigger: 'change'
        }
      ],
      finishDate: [
        {
          required: true,
          message: 'Seleccione un día',
          type: 'string',
          trigger: 'change'
        }
      ],
      selectTypeCobranza: [
        {
          required: true,
          validator: checkSelectTypeCobranza,
          trigger: 'change'
        }
      ],
      cutDate: [
        {
          required: true,
          message: 'Selecciona un día',
          type: 'string',
          trigger: 'change'
        }
      ],
      invoicePayment: [
        {
          required: true,
          message: 'Seleccionar plazo',
          type: 'number',
          trigger: 'change'
        }
      ],
      benefitCut: [
        {
          required: true,
          validator: checkBenefitCut,
          trigger: 'change'
        }
      ]
    };

    watchEffect(async () => {
      try {
        await store.dispatch(FETCH_INVOICE_PAYMENT_POLICY);
        await store.dispatch(FETCH_CUT_OFFS_POLICY);
      } catch (error) {
        console.log(error);
      }
      // router.replace({name:'Empresas',query:{}});
    });

    const handleOk = () => {
      visible.value = false;
      showError.value = false;
    };

    const onSubmit = async () => {
      const payload = {
        policies: polizas,
        configurationType: formState.typeSelected,
        // EMISION COBRANZA
        // collectionInsuranceStart: formState.startDate,
        // collectionInsuranceEnd: formState.finishDate,
        collectionType: formState.SelectTypeCobranza,
        cutoffDay: formState.cutDate,
        invoicePaymentId: formState.invoicePayment,
        invoiceBenefictCutoffId: formState.benefitCut
      };
      try {
        loading.value = true;
        await store.dispatch(POST_CONFIG_POLICY, {
          id: route.params.id,
          payload
        });
        loading.value = false;
        visible.value = false;
        show_sucess.value = true;
      } catch (error) {
        loading.value = false;
        console.log(error);
      }
    };

    const closeModalClean = () => {
      formRef.value.resetFields();
      formState.typeSelected = '';
      formState.startDate = '';
      formState.finishDate = '';
      formState.SelectTypeCobranza = '';
      formState.cutDate = '';
      formState.invoicePayment = ' seleccione plazo';
      formState.benefitCut = 'Seleccione atraso pago';
    };

    const verConfig = () => {
      router.push({ name: 'cobranzaFacturacionTabs' });
    };

    return {
      verConfig,
      loading,
      typeCobranzaHtml,
      closeModalClean,
      show_sucess,
      dayInvoicePayment,
      typeSelectedCobranza,
      dayCutBenefict,
      handleOk,
      onSubmit,
      visible,
      showError,
      rules,
      dataInvoicePayment,
      dataCutOffsPolicy,
      polizasConfightml,
      showLista,
      handleConfiguration,
      handleCorteBeneficio,
      handleFactura,
      handleFechaCorte,
      handleDateStart,
      handleChangeCobranza,
      handleDateFinish,
      polizas,
      periodicidad,
      Cobranza,
      optionSelected,
      handleChange,
      formState,
      formRef,
      status_redirection
    };
  }
};
</script>

<style></style>
