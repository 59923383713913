<template>
  <div>
    <div>
      <a-table :data-source="data" :columns="columns" :scroll="{ x: 1000 }">
        <template #custom_id="{ text }">
          <p># {{ text }}</p>
        </template>
        <template #action="{ record }">
          <a-row style="float: right">
            <a-col v-if="record.maintainerType == 'cobertura'">
              <a-tooltip
                @click="linkPolizas()"
                color="#05045D"
                title="Ver cobertura"
              >
                <a-button type="link">
                  <eye-outlined class="blue-icon" />
                </a-button>
              </a-tooltip>
            </a-col>
            <a-col v-if="record.canBeDeleted">
              <a-tooltip
                @click="openEditModal(record)"
                color="#05045D"
                title="Editar vigencia"
              >
                <a-button type="link">
                  <user-delete-outlined class="blue-icon" />
                </a-button>
              </a-tooltip>
            </a-col>
            <a-col
              v-if="
                (record.maintainerType == 'cobertura' &&
                  record.canBeDeleted == true) ||
                (record.maintainerType == 'carga' &&
                  record.canBeDeleted == true)
              "
            >
              <a-tooltip
                color="#05045D"
                title="Dar de baja cobertura"
                @click="typeUnsubscribe(record)"
              >
                <a-button type="link">
                  <download-outlined />
                </a-button>
              </a-tooltip>
            </a-col>
            <a-col
              v-if="
                (record.maintainerType == 'cobertura' &&
                  record.canBeDeleted == true) ||
                (record.maintainerType == 'carga' &&
                  record.canBeDeleted == true)
              "
            >
              <a-tooltip
                title="Eliminar"
                color="#05045D"
                @click="typeDelete(record)"
              >
                <a-button type="link">
                  <template #icon>
                    <delete-outlined class="red-icon" />
                  </template>
                </a-button>
              </a-tooltip>
            </a-col>
          </a-row>
        </template>
      </a-table>
    </div>

    <modal-unsubscribe
      :type-selection="typeSelection"
      ref="modal_Unsubscribe_ref"
    />
    <modal-delete ref="modal_delete_ref" :type-selection="typeSelection" />
    <modal-edit
      ref="modal_edit_ref"
      @patchedEmployecoverage="patchedEmployecoverage"
      @patchedEmployeeDependent="patchedEmployeeDependent"
    />
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref } from 'vue';
import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
  UserDeleteOutlined
} from '@ant-design/icons-vue';
import { SAVE_COMPANY_CLIENT_TAB } from '../../../store/types';
import ModalUnsubscribe from './ModalUnsubscribeCyC.vue';
import ModalDelete from './ModalDeleteCyC.vue';
import ModalEditCyC from './ModalEditCyC.vue';

export default {
  name: 'CollaboratorProfileTable',
  props: {
    data: {
      type: Array,
      default: []
    },
    typeSelection: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: []
    }
  },
  components: {
    'eye-outlined': EyeOutlined,
    'download-outlined': DownloadOutlined,
    'delete-outlined': DeleteOutlined,
    'modal-unsubscribe': ModalUnsubscribe,
    'modal-delete': ModalDelete,
    'modal-edit': ModalEditCyC,
    'user-delete-outlined': UserDeleteOutlined
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const linkPolizas = () => {
      store.dispatch(SAVE_COMPANY_CLIENT_TAB, 'Movimientos');
      router.push({
        name: 'Movimientos',
        query: { companyId: route.params.id, status: props.typeSelection }
      });
    };
    const modal_Unsubscribe_ref = ref();
    const typeUnsubscribe = (type) => {
      modal_Unsubscribe_ref.value.openModal(type);
    };
    const modal_delete_ref = ref();
    const typeDelete = (type) => {
      modal_delete_ref.value.openModal(type);
    };

    const modal_edit_ref = ref();
    const openEditModal = (id) => {
      modal_edit_ref.value.openModal(id);
    };

    const patchedEmployecoverage = () => {
      emit('patchedEmployecoverage');
    };

    const patchedEmployeeDependent = () => {
      emit('patchedEmployeeDependent');
    };

    return {
      linkPolizas,
      typeUnsubscribe,
      modal_Unsubscribe_ref,
      typeDelete,
      modal_delete_ref,
      modal_edit_ref,
      openEditModal,
      patchedEmployecoverage,
      patchedEmployeeDependent
    };
  }
};
</script>
