<template>
  <a-typography-title :level="4">Emails</a-typography-title>
  <div style="display: flex; flex-direction: column; gap: 16px">
    <a-table :loading="loading" :dataSource="dataSource" :columns="columns" :pagination="false">
      <template #state="{ record }">
        <a-checkbox :disabled="!editing" :checked="record.status" @change="(checkedValue) =>
            updateCompanyEmailConfiguration(checkedValue, record)
          " />
      </template>
    </a-table>
    <div style="align-self: flex-end">
      <a-button v-if="!editing" type="primary" @click="toggleEditing">Editar</a-button>
      <a-button v-if="editing" type="primary" @click="toggleEditing">Terminar</a-button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  FETCH_COMPANY_EMAIL_CONFIGURATIONS,
  GET_COMPANY_EMAIL_CONFIGURATIONS,
  PATCH_COMPANY_EMAIL_CONFIGURATION,
  POST_COMPANY_EMAIL_CONFIGURATION
} from '@/store/types';
import notification from '@/utils/notifications';

const store = useStore();
const route = useRoute();

const editing = ref(false);
const loading = ref(true);
const dataSource = computed(() => {
  const emailConfigurations =
    store.getters[GET_COMPANY_EMAIL_CONFIGURATIONS] || [];
  return emailConfigurations.map((i) => ({ ...i, key: i.id }));
});
const columns = [
  {
    title: 'Nombre',
    dataIndex: 'emailLabel'
  },
  {
    title: 'Estado',
    key: 'state',
    slots: { customRender: 'state' }
  }
];

const toggleEditing = () => (editing.value = !editing.value);
const updateCompanyEmailConfiguration = async (checkedValue, record) => {
  loading.value = true;
  try {
    if (record.id) {
      await store.dispatch(PATCH_COMPANY_EMAIL_CONFIGURATION, {
        companyId: route.params.id,
        emailConfigurationId: record.id,
        data: {
          status: checkedValue.target.checked
        }
      });
    } else {
      await store.dispatch(POST_COMPANY_EMAIL_CONFIGURATION, {
        companyId: route.params.id,
        data: {
          emailId: record.emailId,
          emailLabel: record.emailLabel,
          status: checkedValue.target.checked
        }
      });
    }
    notification(
      'success',
      'Actualización exitosa',
      'Se actualizó correctamente la configuración de email'
    );
  } catch {
    notification(
      'error',
      'Error',
      'Ocurrio un error al actualizar la configuración de email'
    );
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  try {
    await store.dispatch(FETCH_COMPANY_EMAIL_CONFIGURATIONS, {
      companyId: route.params.id
    });
  } catch {
    notification(
      'error',
      'Error',
      'Ocurrio un error al obtener las configuraciones de email'
    );
  } finally {
    loading.value = false;
  }
});
</script>
