<template>
  <a-modal
    v-model:visible="modal_visible"
    :title="title"
    :closable="!loading"
    :maskClosable="!loading"
    centered
  >
    <a-typography-text strong>Resolución compañia</a-typography-text>
    <a-typography-title class="text-gray-8 fw-regular mb-4" :level="5">
      Escribe aquí los motivos por los cuales la compañía aseguradora está
      rechazando el reproceso de la cobranza
    </a-typography-title>
    <a-form ref="form_ref" :model="form_state" :rules="rules">
      <a-form-item name="message_text">
        <a-textarea
          placeholder="Resolución"
          v-model:value="form_state.message_text"
          :autoSize="{ minRows: 4, maxRows: 8 }"
        />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button @click="closeModal" :disabled="loading" key="back"
        >Cancelar</a-button
      >
      <a-button
        @click="formValidation"
        :loading="loading"
        key="submit"
        type="primary"
        >Confirmar</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { GET_BROKER_ID, POST_CHANGE_REPROCESS_STATE } from '@/store/types';
import notificiation from '@/utils/notifications';

export default {
  props: {
    reprocessType: {
      type: Number,
      default: 1
    },
    reprocessId: {
      type: Number,
      default: 1
    }
  },
  setup(props, { emit }) {
    const modal_visible = ref(false);
    const loading = ref(false);
    const store = useStore();
    const title = computed(() => {
      const types = {
        1: 'Rechazar reproceso por inconsistencia',
        2: 'Rechazar reproceso por anulación de factura',
        3: 'Rechazar Reproceso por Cambio y/o adicionar'
      };

      return types[props.reprocessType];
    });

    const form_ref = ref();
    const form_state = reactive({
      message_text: ''
    });

    const rules = {
      message_text: [
        {
          required: true,
          message: 'Ingrese un mensaje',
          trigger: 'change',
          type: 'string'
        }
      ]
    };

    const openModal = () => {
      modal_visible.value = true;
    };

    const closeModal = () => {
      modal_visible.value = false;
    };

    const formValidation = () => {
      form_ref.value
        .validate()
        .then(() => {
          rejectReprocess();
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const rejectReprocess = async () => {
      loading.value = true;
      try {
        const payload = {
          insurance_resolution: form_state.message_text,
          state: 3,
          brokerId: store.getters[GET_BROKER_ID]
        };

        await store.dispatch(POST_CHANGE_REPROCESS_STATE, {
          id: props.reprocessId,
          payload
        });

        notificiation('success', 'Correcto', 'Reproceso Rechazado.');
        emit('rejectReprocess', true);
        closeModal();
      } catch (error) {
        notificiation('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    return {
      modal_visible,
      title,
      form_state,
      rules,
      form_ref,
      loading,
      formValidation,
      openModal,
      closeModal
    };
  }
};
</script>
