import {
  createTransferTicket,
  executeTransferTicket,
  cancelTransferTicket,
  getInsuranceCompaniesByEmployeeRut
} from '../../../network';

import {
  POST_TICKET_TRANSFER,
  EXECUTE_TICKET_TRANSFER,
  CANCEL_TICKET_TRANSFER,
  FETCH_EMPLOYEE_INSURANCE_COMPANIES_BY_RUT
} from './types';

const store = {};
const getDefaultState = () => ({});

store.state = getDefaultState();

store.getters = {};

store.mutations = {};

store.actions = {
  [POST_TICKET_TRANSFER](_, payload) {
    return new Promise((resolve, reject) => {
      createTransferTicket(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [EXECUTE_TICKET_TRANSFER](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      executeTransferTicket(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CANCEL_TICKET_TRANSFER](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      cancelTransferTicket(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_EMPLOYEE_INSURANCE_COMPANIES_BY_RUT](_, payload) {
    return new Promise((resolve, reject) => {
      getInsuranceCompaniesByEmployeeRut(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default store;
