import config from '../config';

const appConfig = config();

const apiCollectionTicket = appConfig.company_client_prefix;
const { v2 } = appConfig.apiVersion;

const network = (client) => ({
  // * Formulario de cobranza
  // --> Creacion de formulario de cobranza
  createCollectionForm: (payload) =>
    client.post(`${apiCollectionTicket}${v2}/collection-groups`, payload),
  // --> Obtener todos los formularios de cobranza
  collectionForms: (payload) =>
    client.get(`${apiCollectionTicket}${v2}/collection-groups?${payload}`),
  // --> Obtener todos los tickets de cobranza
  collectionTickets: (payload) =>
    client.get(`${apiCollectionTicket}${v2}/collections?${payload}`),
  // --> Validar si se puede subir facturas en precobranza
  collectionInsuranceCompanyInvoiceUploadValidation: (
    collection_group_id,
    insurance_id
  ) =>
    client.get(
      `${apiCollectionTicket}${v2}/collection-groups/${collection_group_id}/insurance-companies/${insurance_id}/can-upload-invoices`
    ),
  // --> Obtener un formulario de cobranza por id
  collectionFormById: (payload) =>
    client.get(`${apiCollectionTicket}${v2}/collection-groups/${payload}`),
  // --> Actualizar el estado del formulario de cobranza
  updateStatecollectionForm: (id, payload) =>
    client.patch(
      `${apiCollectionTicket}${v2}/collection-groups/${id}`,
      payload
    ),
  // --> Obtener las compañias aseguradoras segun id de formulario de cobranza
  collectionFormInsuranceCompanies: (id) =>
    client.get(
      `${apiCollectionTicket}${v2}/collection-groups/${id}/insurance-companies`
    ),
  // --> Obtener las polizas de un formulario de cobraza y compañia aseguradora
  collectionFormInsuranceCompaniesPolicies: (
    collection_id,
    insurance_id,
    type
  ) =>
    client.get(
      `${apiCollectionTicket}${v2}/collection-groups/${collection_id}/insurance-companies/${insurance_id}/policies?collectionType=${type}`
    ),
  // --> Obtener las facturas de un formulario de cobraza y compañia aseguradora
  collectionFormInsuranceCompaniesInvoices: (
    collection_id,
    insurance_id,
    type
  ) =>
    client.get(
      `${apiCollectionTicket}${v2}/collection-groups/${collection_id}/insurance-companies/${insurance_id}/invoices?collectionType=${type}`
    ),
  // --> Obtener resumen de el formulario de cobranza
  collectionFormSummary: (payload) =>
    client.get(
      `${apiCollectionTicket}${v2}/collection-groups/${payload}/summary`
    ),
  // --> Obtener resumen general de todos los formularios de cobranza
  allCollectionFormSummary: (payload = '') =>
    client.get(
      `${apiCollectionTicket}${v2}/collection-groups/summary${payload}`
    ),

  // * Nominas
  // --> Creacion de nominas para formulario de cobranza
  createCollectionFormPayroll: (id, payload) =>
    client.post(
      `${apiCollectionTicket}${v2}/collection-groups/${id}/collections`,
      payload
    ),
  // --> Listado de cobranza por id de formulario de inicio de cobranza
  getCollectionListByCollectionGroup: (payload) =>
    client.get(
      `${apiCollectionTicket}${v2}/collection-groups/${payload}/collections`
    ),
  // --> Obtener las nominas de un formulario de cobranza y conpañia aseguradora
  collectionFormPayrollByInsuranceCompany: (formId, insuranceCompanyId) =>
    client.get(
      `${apiCollectionTicket}${v2}/collection-groups/${formId}/insurance-companies/${insuranceCompanyId}/collections`
    ),
  // --> Obtener las observaciones segun id de la nomina subida
  payrollObservations: (payload) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${payload}/processes/validator/issues`
    ),
  // --> Descarga del stock de cobranzas
  collectionStockDownload: (payload) => {
    return `${appConfig.baseURL}${apiCollectionTicket}${v2}/collections/sheet${payload}`;
  },
  collectionFormDownload: (payload) => {
    return `${appConfig.baseURL}${apiCollectionTicket}${v2}/collection-groups/sheet${payload}`;
  },
  // --> Reprocesar nómina
  payrollReprocessing: (payload) =>
    client.post(
      `${apiCollectionTicket}${v2}/collections/${payload}/processes/validator`
    ),
  // --> Eliminar nómina
  deletePayroll: (payload) =>
    client.delete(`${apiCollectionTicket}${v2}/collections/${payload}`),
  // --> Observaciones de reabrir de cobranza
  collectionResourceObservations: (collection_id, collection_resource_id) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${collection_id}/processes/validator/issues?collectionResourceId=${collection_resource_id}`
    ),
  // --> Eliminar la cobranza reabierta
  collectionResourceDelete: (collection_resource_id) =>
    client.delete(
      `${apiCollectionTicket}${v2}/collection-resources/${collection_resource_id}`
    ),
  // --> Reprocesar cobranza reabierta
  collectionResourceReprocess: (collection_id, collection_resource_id) =>
    client.post(
      `${apiCollectionTicket}${v2}/collections/${collection_id}/processes/validator?collectionResourceId=${collection_resource_id}`
    ),
  // --> Adjuntar nomina original en reapertura de cobranza
  collectionResourceAttachOriginalPayroll: (
    collection_id,
    collection_resource_id,
    payload
  ) =>
    client.patch(
      `${apiCollectionTicket}${v2}/collections/${collection_id}/original-payroll?collectionResourceId=${collection_resource_id}`,
      payload
    ),

  getDataSendToRRHH: (payload) =>
    client.get(
      `${apiCollectionTicket}${v2}/collection-groups/${payload}/human-resources/send`
    ),
  postDataSendRRHH: (id, payload) =>
    client.post(
      `${apiCollectionTicket}${v2}/collection-groups/${id}/human-resources/send`,
      payload
    ),
  getFileCollectionTicket: (collection_id, query_params) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${collection_id}/attachments${query_params}`
    ),
  // --> Cargar una nómina en el ticket de cobranza
  addCollectionTicketPayroll: (id, payload) =>
    client.post(
      `${apiCollectionTicket}${v2}/collections/${id}/resources`,
      payload
    ),
  // --> Confirmar reabrir cobranza
  confirmCollectionReopen: (payload) =>
    client.post(`${apiCollectionTicket}${v2}/collections/${payload}/reopen`),
  // --> Obtener nóminas en el ticket de cobranza
  getCollectionTicketPayrolls: (id) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${id}/resources?isTemporary=true`
    ),
  // --> Obtener historial de reprocesos
  getCollectionReprocessRecords: (id) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${id}/resources?isTemporary=false`
    ),
  // --> Obtener resumen general de todos los clientes por periodo
  collectionCompaniesSummary: (payload) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/companies-summary${payload}`
    ),
  // --> Obtener historial de reprocesos
  allCollectionSummary: (payload = '') =>
    client.get(`${apiCollectionTicket}${v2}/collections/summary${payload}`),

  // * Facturas
  // --> Obtener una factura por su id
  invoiceById: (payload) =>
    client.get(`${apiCollectionTicket}${v2}/invoices/${payload}`),
  // --> Editar una factura
  editInvoice: (id, payload) =>
    client.patch(`${apiCollectionTicket}${v2}/invoices/${id}`, payload),
  // --> Editar una factura
  deleteInvoice: (id) =>
    client.delete(`${apiCollectionTicket}${v2}/invoices/${id}`),
  // --> Editar una factura
  reprocessInvoice: (id) =>
    client.post(`${apiCollectionTicket}${v2}/invoices/${id}/processes`),
  // --> Crear una factura de un formulario de cobranza y compañia aseguradora
  createCollectionFormInsuranceCompaniesInvoices: (
    collection_id,
    insurance_id,
    payload
  ) =>
    client.post(
      `${apiCollectionTicket}${v2}/collection-groups/${collection_id}/insurance-companies/${insurance_id}/invoices`,
      payload
    ),
  getCollectionGroupComments: (payload) =>
    client.get(
      `${apiCollectionTicket}${v2}/collection-groups/${payload}/comments`
    ),
  postCollectionGroupComments: (id, payload) =>
    client.post(
      `${apiCollectionTicket}${v2}/collection-groups/${id}/comments`,
      payload
    ),

  // --> Obtener las compañias que tiene una factura
  companiesByInvoiceId: (payload) =>
    client.get(
      `${apiCollectionTicket}${v2}/invoices/${payload}/companies?selectable=true`
    ),
  // --> Obtener las polizas de una factura
  invoicePoliciesById: (payload) =>
    client.get(
      `${apiCollectionTicket}${v2}/invoices/${payload}/policies?selectable=true`
    ),
  // --> Seleccionar poliza de una factura
  selectInvoicePoliciesById: (id, payload) =>
    client.post(`${apiCollectionTicket}${v2}/invoices/${id}/policies`, payload),
  getCollectionComments: (collection_id, param) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${collection_id}/comments${param}`
    ),
  postCollectionComments: (id, payload) =>
    client.post(
      `${apiCollectionTicket}${v2}/collections/${id}/comments`,
      payload
    ),
  getBillingDetailList: (collection_id, query) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${collection_id}/invoices${query}`
    ),
  getCollections: (collection_id, query_params) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${collection_id}${query_params}`
    ),
  postReprocesses: (payload) =>
    client.post(
      `${apiCollectionTicket}${v2}/collections/4/reprocesses`,
      payload
    ),
  // --> Observaciones de una factura
  invoiceObservations: (payload) =>
    client.get(`${apiCollectionTicket}${v2}/invoices/${payload}/issues`),
  // --> Resumen de factura
  invoiceSummary: (payload) =>
    client.get(`${apiCollectionTicket}${v2}/invoices/summary${payload}`),
  // --> Resumen de factura morisidad
  invoiceDefaultersSummary: (payload) =>
    client.get(
      `${apiCollectionTicket}${v2}/invoices/defaulters-summary${payload}`
    ),
  // --> Resumen de factura morisidad lista de tablas
  invoiceDefaultersSummaryList: (payload) =>
    client.get(
      `${apiCollectionTicket}${v2}/invoices/defaulters-list${payload}`
    ),
  patchMarkInvoicePaid: (invoiceId, date) =>
    client.patch(`${apiCollectionTicket}${v2}/invoices/${invoiceId}/pay`, date),
  // * Reproceso
  // --> Creacion de reproceso
  createReprocess: (id, payload) =>
    client.post(
      `${apiCollectionTicket}${v2}/collections/${id}/reprocesses`,
      payload
    ),
  // --> Obtener todos los reprocesos con filtros
  getAllReprocess: (params, signal) =>
    client.get(`${apiCollectionTicket}${v2}/reprocesses?${params}`, { signal }),
  // --> Obtener los reprocesos por id de ticket de cobranza
  getReprocessByCollectionId: (collection_id, query_params) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${collection_id}/reprocesses${query_params}`
    ),
  // --> Obtener las solicitudes por id de ticket de cobranza
  getRequestsByCollectionId: (collection_id, query_params) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${collection_id}/requests${query_params}`
    ),
  getRequestsIdCollection: (payload) =>
    client.get(`${apiCollectionTicket}${v2}/requests/${payload}/collections`),
  postRequestByCollectionId: (id, payload) =>
    client.post(
      `${apiCollectionTicket}${v2}/collections/${id}/requests`,
      payload
    ),
  // --> Obtener todos las solicitudes con filtros
  getAllRequests: (payload, signal) =>
    client.get(`${apiCollectionTicket}${v2}/requests?${payload}`, { signal }),
  getRequestsById: (payload) =>
    client.get(`${apiCollectionTicket}${v2}/requests/${payload}`),
  // --> Obtener los archivos adjuntos de un ticket de cobranza
  getAttachmentsByCollectionId: (payload) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${payload}/attachments`
    ),

  // --> Aprobar cobranza
  aproveCollectionTicket: (id, payload) =>
    client.patch(
      `${apiCollectionTicket}${v2}/collections/${id}/approve`,
      payload
    ),

  // --> Obtener un reproceso por id
  getReprocessById: (id) =>
    client.get(`${apiCollectionTicket}${v2}/reprocesses/${id}`),
  // --> Cancelar un reproceso
  changeReprocessState: (id, payload) =>
    client.post(`${apiCollectionTicket}${v2}/reprocesses/${id}/state`, payload),
  changeRequestState: (id, payload) =>
    client.post(`${apiCollectionTicket}${v2}/requests/${id}/states`, payload),
  // --> Obtener los procesos por reproceso por id
  getProcessByReprocessId: (id) =>
    client.get(`${apiCollectionTicket}${v2}/reprocesses/${id}/collections`),
  // --> Obtener los comentarios de los reprocesos
  getReprocessesComments: (id) =>
    client.get(`${apiCollectionTicket}${v2}/reprocesses/${id}/comments`),
  // --> Crear comentario para reproceso
  createReprocessesComment: (id, payload) =>
    client.post(
      `${apiCollectionTicket}${v2}/reprocesses/${id}/comments`,
      payload
    ),
  // --> Obtener resumen de reproceso por tipo
  getReprocessSummary: (type, payload) =>
    client.get(
      `${apiCollectionTicket}${v2}/reprocesses/summary?type=${type}${payload}`
    ),
  createRequestsComment: (id, payload) =>
    client.post(`${apiCollectionTicket}${v2}/requests/${id}/comments`, payload),
  getRequestsComments: (id) =>
    client.get(`${apiCollectionTicket}${v2}/requests/${id}/comments`),
  // --> Obtener resumen de solicitudes por tipo
  getRequestsSummary: (type, params) =>
    client.get(
      `${apiCollectionTicket}${v2}/requests/summary?type=${type}${params}`
    ),

  getAllFileInvoice: (requestId) =>
    client.get(
      `${apiCollectionTicket}${v2}/requests/${requestId}/invoice-files`
    ),
  postFileInvoice: (requestId, payload) =>
    client.post(
      `${apiCollectionTicket}${v2}/requests/${requestId}/invoice-files`,
      payload
    ),
  patchFileInvoice: (requestId, requestInvoiceFileId, payload) =>
    client.patch(
      `${apiCollectionTicket}${v2}/requests/${requestId}/invoice-files/${requestInvoiceFileId}`,
      payload
    ),
  deleteFileInvoice: (requestId, requestInvoiceFileId) =>
    client.delete(
      `${apiCollectionTicket}${v2}/requests/${requestId}/invoice-files/${requestInvoiceFileId}`
    ),
  patchOriginalPayroll: (collectionId, payload) =>
    client.patch(
      `${apiCollectionTicket}${v2}/collections/${collectionId}/original-payroll`,
      payload
    ),
  getFileOriginalPayroll: (collectionId) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${collectionId}/original-payroll`
    ),
  postValidatePayroll: (CollectionGroupId) =>
    client.post(
      `${apiCollectionTicket}${v2}/collection-groups/${CollectionGroupId}/conciliate`
    ),

  // Ajuste Retroactivo
  getRetroactiveAdjustmentByCollection: (collection_id, query_params) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${collection_id}/retroactives${query_params}`
    ),

  // cuadratura
  getCollectionInconsistencies: (collection_id, query_params) =>
    client.get(
      `${apiCollectionTicket}${v2}/collections/${collection_id}/conciliate${query_params}`
    ),

  downloadFileInconsistencies: (collectionId, type) =>
    `${appConfig.baseURL}${apiCollectionTicket}${v2}/collections/${collectionId}/conciliate${type}`,

  squaringCollection: (collectionId, payload) =>
    client.post(
      `${apiCollectionTicket}${v2}/collections/${collectionId}/conciliate${
        payload ? payload : ''
      }`
    ),

  postCollectionTicketHumanSend: (collectionId, payload) =>
    client.patch(
      `${apiCollectionTicket}${v2}/collections/${collectionId}/send-to-hr`,
      payload
    ),

  // ENVIAR FACTURAS SI ES PRECOBRANZA O COBRANZA
  getInvoicesHumanSend: (collectionId, insuranceCompanyId) =>
    client.get(
      `${apiCollectionTicket}${v2}/collection-groups/${collectionId}/insurance-companies/${insuranceCompanyId}/invoices/send-to-human-resources`
    ),
  postSendPrecollectionInvoices: (collectionId, insuranceCompanyId, payload) =>
    client.post(
      `${apiCollectionTicket}${v2}/collection-groups/${collectionId}/insurance-companies/${insuranceCompanyId}/invoices/send-to-human-resources`,
      payload
    )
});

export default network;
