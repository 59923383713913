<template>
  <div>
    <a-modal v-model:visible="visible" :title="title" :closable="!loading" :maskClosable="!loading" centered>
      <a-typography-title :level="5" style="margin-top: -15px">
        Completa los siguientes datos.
      </a-typography-title>
      <a-form name="form" layout="vertical" ref="formRef" :model="formState" :rules="rules" class="mt-3">
        <a-form-item required name="requestEndDate" label="Fecha de solicitud de baja">
          <a-date-picker format="DD/MM/YYYY" placeholder="Seleccione Fecha" v-model:value="formState.requestEndDate"
            :style="{ width: '100%' }" />
        </a-form-item>
        <a-form-item required name="endDate" label="Fecha de término de vigencia">
          <a-date-picker format="DD/MM/YYYY" placeholder="Seleccione Fecha" v-model:value="formState.endDate"
            :style="{ width: '100%' }" />
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button :loading="loading" key="back" @click="closeModal">Volver atrás</a-button>
        <a-button :loading="loading" key="submit" type="primary" @click="submitModal"
          :disabled="saveChangesButtonDisabled">
          Confirmar
          {{ datatype }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { useRoute } from 'vue-router';
import notification from '../../../utils/notifications';
import {
  PATCH_EMPLOYEE_COVERAGE_COLABORATOR,
  FETCH_COVERAGE_EMPLOYEE,
  FETCH_DEPENDENTS,
  PATCH_EMPLOYEE_DEPENDENT
} from '@/store/types';

export default {
  name: 'ModalUNsubscribeCyC',
  props: {
    typeSelection: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    const dataRecord = ref();
    const loading = ref(false);
    const store = useStore();
    const route = useRoute();
    const { id, colaborador } = route.params;
    const type = computed(() => props.typeSelection);
    const title = ref('');

    const visible = ref(false);

    const closeModal = () => {
      visible.value = false;
    };

    const formState = reactive({
      requestEndDate: null,
      endDate: null
    });

    const saveChangesButtonDisabled = computed(
      () =>
        JSON.stringify({
          endDate: formState.endDate || null,
          requestEndDate: formState.requestEndDate || null
        }) ===
        JSON.stringify({
          endDate: dataRecord.value.endDate || null,
          requestEndDate: dataRecord.value.requestEndDate || null
        })
    );

    const openModal = (record) => {
      dataRecord.value = record;
      visible.value = true;
      formState.requestEndDate =
        record.requestEndDate && moment(record.requestEndDate).startOf('day');
      formState.endDate = record.endDate && moment(record.endDate).startOf('day');
      if (record.maintainerType === 'cobertura') {
        title.value = `Dar de baja cobertura ${record.tipo}`;
      } else if (record.maintainerType === 'carga') {
        title.value = `Dar de baja ${record.fullName}`;
      }
    };

    const formRef = ref();

    const rules = {
      endDate: [
        {
          required: true,
          type: 'object',
          trigger: 'change',
          message: 'Seleccione fecha'
        }
      ],
      requestEndDate: [
        {
          required: true,
          type: 'object',
          trigger: 'change',
          message: 'Seleccione fecha'
        }
      ]
    };
    const submitModal = async () => {
      try {
        loading.value = true;
        const validate = await formRef.value.validate();
        // validaar el datePicker
        if (validate) {
          // VER SI es de tipo cobertuas o cargas para mandar el dato
          if (dataRecord.value.maintainerType == 'cobertura') {
            const resp = await store.dispatch(
              PATCH_EMPLOYEE_COVERAGE_COLABORATOR,
              {
                idEmployeeCoverage: dataRecord.value.employeeCoverageId,
                payload: validate
              }
            );
            if (resp.status == 200) {
              loading.value = false;
              await store.dispatch(FETCH_COVERAGE_EMPLOYEE, {
                companyId: id,
                employeeId: colaborador,
                queryParam: `?status=${type.value == 'Vigente'
                  ? 'ACTIVE'
                  : '' || type.value == 'No vigente'
                    ? 'INACTIVE'
                    : ''
                }`
              });
              closeModal();
              formState.endDate = null;
              formState.requestEndDate = null;
              notification(
                'success',
                'Correcto',
                'Fecha de término actualizada'
              );
            }
          }
          if (dataRecord.value.maintainerType == 'carga') {
            const resp = await store.dispatch(PATCH_EMPLOYEE_DEPENDENT, {
              employeeId: route.params.colaborador,
              dependentId: dataRecord.value.id,
              payload: validate
            });
            if (resp.status == 200) {
              loading.value = false;
              await store.dispatch(FETCH_DEPENDENTS, route.params.colaborador);
              closeModal();
              formState.endDate = null;
              formState.requestEndDate = null;
              notification(
                'success',
                'Correcto',
                'Fecha de término actualizada'
              );
            }
          }
          loading.value = false;
        }
      } catch (error) {
        loading.value = false;
        formState.endDate = null;
        formState.requestEndDate = null;
        notification('error', 'Error', error.response.data.message);
        closeModal();
        console.log('error', error);
      }
    };

    return {
      dataType: dataRecord,
      visible,
      closeModal,
      submitModal,
      openModal,
      formState,
      formRef,
      rules,
      loading,
      saveChangesButtonDisabled,
      title,
      type
    };
  }
};
</script>
