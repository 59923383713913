export const SET_COMMENTS = 'comments/SET_COMMENTS';
export const SET_POST_COMMENT_DATA = 'comments/SET_POST_COMMENT_DATA';
export const SET_MASIVE_COMMENTS = 'comments/SET_MASIVE_COMMENTS';
export const SET_TICKET_COMMENT_ID = 'comments/SET_TICKET_COMMENT_ID';

// * FETCH
export const FETCH_COMMENTS = 'comments/FETCH_COMMENTS';
export const FETCH_MASIVE_COMMENTS = 'comments/FETCH_MASIVE_COMMENTS';
export const FETCH_TICKET_COMMENTS_ID = 'comments/FETCH_TICKET_COMMENTS_ID';

// * POST
export const POST_COMMENTS = 'comments/POST_COMMENTS';
export const POST_MASIVE_COMMENTS = 'comments/POST_MASIVE_COMMENTS';

// * GET
export const GET_MASIVE_COMMENTS = 'comments/GET_MASIVE_COMMENTS';
export const GET_COMMENT_TICKET_ID = 'comments/GET_COMMENT_TICKET_ID';
