```jsx
<template>
  <div>
    <a-row :gutter="24">
      <a-col :span="8">
        <detail-card
          title="Cobranzas"
          :disable-redirection="true"
          :quantity="collection_data.total"
          quantity-description="Procesos"
          :percentage="collection_data.progress"
          :details="collection_data.details"
        />
      </a-col>
      <a-col :span="8">
        <detail-card
          title="Formularios"
          :disable-redirection="true"
          :quantity="collection_form_data.total"
          quantity-description="Procesos"
          :percentage="collection_form_data.progress"
          :details="collection_form_data.details"
        />
      </a-col>
      <a-col :span="8">
        <a-card style="height: 100%">
          <a-row type="flex" justify="space-between">
            <a-col>
              <a-typography-text class="fw-semibold text-gray-8 fs-6">
                Resumen de cobranzas
              </a-typography-text>
            </a-col>
          </a-row>
          <a-typography-text class="text-gray-8 d-block mt-3">
            Total de clientes por periodo
          </a-typography-text>
          <a-row align="middle">
            <ShopOutlined class="mr-2" :style="{ fontSize: '36px' }" />
            <a-typography-text class="fw-semibold display-3 text-gray-9">
              {{ collection_sumary.total }}
            </a-typography-text>
          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col>
              <a-tag color="processing"> Total Empresas </a-tag>
            </a-col>
            <a-col>
              <span class="text-blue-6"
                >{{ collection_sumary.percentageChange }}%</span
              >
            </a-col>
          </a-row>
          <a-typography-text class="text-gray-9 d-block mt-5">
            Empresas con proceso
          </a-typography-text>
          <a-progress
            :percent="collection_sumary.percentageWithCollection"
            strokeColor="green"
          />
        </a-card>
      </a-col>
    </a-row>

    <div class="mt-3">
      <a-card>
        <a-row type="flex" justify="space-between">
          <a-col>
            <a-radio-group v-model:value="reprocess_requests_tab">
              <a-radio-button value="reprocess">Reprocesos</a-radio-button>
              <a-radio-button value="requests">Solicitudes</a-radio-button>
            </a-radio-group>

            <br />
            <br />

            <a-typography-text class="mt-5">
              Cantidad total de reprocesos por inconsistencia, cambio y/o
              adicional y anulación de factura.
            </a-typography-text>
          </a-col>
          <a-col>
            <a-row :gutter="24">
              <a-col>
                <mini-details
                  :title="
                    reprocess_requests_tab === 'reprocess'
                      ? 'Inconsistencia'
                      : 'Nota de Crédito'
                  "
                  icon="fa-xmark-to-slot"
                  :total="
                    reprocess_requests_tab === 'reprocess'
                      ? inconsistencyReprocess?.total
                      : creditRequests?.total
                  "
                  :description="
                    reprocess_requests_tab === 'reprocess'
                      ? 'Reprocesos'
                      : 'Solicitudes'
                  "
                  :loading="collection_summary_loading"
                />
              </a-col>
              <a-col>
                <mini-details
                  :title="
                    reprocess_requests_tab === 'reprocess'
                      ? 'Anulación de factura'
                      : 'Nota de débito'
                  "
                  icon="fa-file"
                  :total="
                    reprocess_requests_tab === 'reprocess'
                      ? anullmentReprocess?.total
                      : debitRequests?.total
                  "
                  :description="
                    reprocess_requests_tab === 'reprocess'
                      ? 'Reprocesos'
                      : 'Solicitudes'
                  "
                  :loading="collection_summary_loading"
                />
              </a-col>
              <a-col>
                <mini-details
                  :title="
                    reprocess_requests_tab === 'reprocess'
                      ? 'Cambio y/o adicional'
                      : 'Ajuste retroactivo'
                  "
                  icon="fa-stopwatch"
                  :total="
                    reprocess_requests_tab === 'reprocess'
                      ? modificationReprocess?.total
                      : adjustmentRequests?.total
                  "
                  :description="
                    reprocess_requests_tab === 'reprocess'
                      ? 'Reprocesos'
                      : 'Solicitudes'
                  "
                  :loading="collection_summary_loading"
                />
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-card>

      <a-card class="mt-3">
        <tickets />
      </a-card>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { ShopOutlined } from '@ant-design/icons-vue';
import CollectionDetailTable from '../../../dashboard/CollectionDetailTable.vue';
import {
  GET_DATE_FILTER_DASHBOARD,
  GET_REQUESTS_SUMMARY,
  GET_ALL_COLLECTION_FORM_SUMMARY,
  GET_ALL_COLLECTION_SUMMARY,
  GET_COLLECTION_COMPANY_SUMMARY,
  GET_REPROCESS_SUMMARY,
  FETCH_ALL_COLLECTION_SUMMARY,
  FETCH_ALL_COLLECTION_FORM_SUMMARY,
  FETCH_COLLECTION_COMPANY_SUMMARY,
  FETCH_REPROCESS_SUMMARY,
  FETCH_REQUESTS_SUMMARY,
  SELECT_FILTER,
  SELECTED_COMPANY,
  ALL_COMPANIES
} from '@/store/types';
import GeneralDetailsCard from '@/components/dashboard/CollectionAndBillingDetailsCard.vue';
import RrhhCollectionAndBillingSection from '@/components/dashboard/card details/ReworkAndRequestsMiniCard.vue';
import nofitication from '@/utils/notifications';

export default {
  components: {
    'detail-card': GeneralDetailsCard,
    'mini-details': RrhhCollectionAndBillingSection,
    tickets: CollectionDetailTable,
    ShopOutlined
  },
  setup() {
    const store = useStore();

    // * Periodo seleccionado en el dashboard
    const period = computed(() => {
      return store.getters[GET_DATE_FILTER_DASHBOARD];
    });

    watch(
      () => period.value,
      () => {
        getMainData();
      }
    );

    // * Companias seleccionadas en el dashboard
    const selected_companies = computed(() => {
      const selected_holding = store.getters[SELECT_FILTER].map((value) =>
        parseInt(value)
      );
      const selected_comp = store.getters[SELECTED_COMPANY].map((value) =>
        parseInt(value)
      );
      const all_companies = store.getters[ALL_COMPANIES].map(({ value }) =>
        parseInt(value)
      );

      if (selected_holding.length > 0) {
        if (selected_comp.length > 0) {
          return selected_comp;
        }
        return all_companies;
      }

      if (
        selected_comp.length > 0 &&
        selected_comp.length < all_companies.length
      ) {
        return selected_comp;
      }

      return [];
    });

    watch(
      () => selected_companies.value,
      () => {
        getMainData();
      }
    );

    // * Cobranzas
    const percentageCalculation = (total, number) => {
      if (total != 0 && number != 0) {
        return Math.round((number * 100) / total);
      }
      return 0;
    };

    const collection_data = computed(() => {
      const {
        progress,
        total,
        created,
        bewellValidation,
        hrValidation,
        approved
      } = store.getters[GET_ALL_COLLECTION_SUMMARY];

      return {
        progress,
        total,
        details: [
          {
            id: 1,
            description: 'Creadas',
            total: percentageCalculation(total, created)
          },
          {
            id: 2,
            description: 'Validación corredora',
            total: percentageCalculation(total, bewellValidation)
          },
          {
            id: 3,
            description: 'Validación RRHH',
            total: percentageCalculation(total, hrValidation)
          },
          {
            id: 4,
            description: 'Aprobado',
            total: percentageCalculation(total, approved)
          }
        ]
      };
    });

    const getCollectionData = async (params = '') => {
      try {
        store.dispatch(FETCH_ALL_COLLECTION_SUMMARY, params);
      } catch (error) {
        nofitication('error', 'Error', error.response.data.message);
      }
    };

    // * Formularios de cobranza
    const collection_form_data = computed(() => {
      const { progress, total, created, proccesing, loaded, cancelled } =
        store.getters[GET_ALL_COLLECTION_FORM_SUMMARY];

      return {
        progress,
        total,
        details: [
          {
            id: 1,
            description: 'Creadas',
            total: percentageCalculation(total, created)
          },
          {
            id: 2,
            description: 'En proceso',
            total: percentageCalculation(total, proccesing)
          },
          {
            id: 3,
            description: 'Cargadas',
            total: percentageCalculation(total, loaded)
          },
          {
            id: 4,
            description: 'Canceladas',
            total: percentageCalculation(total, cancelled)
          }
        ]
      };
    });

    const getCollectionFormData = (params = '') => {
      try {
        store.dispatch(FETCH_ALL_COLLECTION_FORM_SUMMARY, params);
      } catch (error) {
        nofitication('error', 'Error', error.response.data.message);
      }
    };

    // * Resumen de cobranzas
    const collection_sumary = computed(
      () => store.getters[GET_COLLECTION_COMPANY_SUMMARY]
    );
    const collection_summary_loading = ref(false);

    const getCollectionSummary = (params = '') => {
      try {
        store.dispatch(FETCH_COLLECTION_COMPANY_SUMMARY, params);
      } catch (error) {
        nofitication('error', 'Error', error.response.data.message);
      }
    };

    // * Resumen de reproceso
    const reprocess_requests_tab = ref('reprocess');
    const reprocessSummary = async (type) => {
      collection_summary_loading.value = true;

      const payload = {};
      // tomo los ids de las compañias seleccionadas
      if (selected_companies.value.length > 0) {
        payload.companies = selected_companies.value.join(',');
      }

      // Tomo el periodo seleccionado
      if (period.value != undefined && period.value != '') {
        payload.period = moment(period.value, 'YYYY-MM').format('YYYY-MM');
      }

      // lo transformo en parametros para la consulta
      let params = '';
      Object.keys(payload).forEach((key) => {
        params += `&${key}=${payload[key]}`;
      });

      try {
        await store.dispatch(FETCH_REPROCESS_SUMMARY, {
          type,
          payload: params
        });
      } catch (error) {
        nofitication('error', 'Error', error.response.data.message);
      }
      collection_summary_loading.value = false;
    };

    const requestsSummary = async (type) => {
      collection_summary_loading.value = true;

      const payload = {};
      // tomo los ids de las compañias seleccionadas
      if (selected_companies.value.length > 0) {
        payload.companies = selected_companies.value.join(',');
      }

      // Tomo el periodo seleccionado
      if (period.value != undefined && period.value != '') {
        payload.period = moment(period.value, 'YYYY-MM').format('YYYY-MM');
      }

      // lo transformo en parametros para la consulta
      let params = '';
      Object.keys(payload).forEach((key) => {
        params += `&${key}=${payload[key]}`;
      });

      try {
        await store.dispatch(FETCH_REQUESTS_SUMMARY, {
          type,
          payload: params
        });
      } catch (error) {
        nofitication('error', 'Error', error.response.data.message);
      }
      collection_summary_loading.value = false;
    };

    const inconsistencyReprocess = computed(() =>
      store.getters[GET_REPROCESS_SUMMARY]('REPROCESS_FOR_INCONSISTENCY')
    );
    const anullmentReprocess = computed(() =>
      store.getters[GET_REPROCESS_SUMMARY]('REPROCESS_FOR_INVOICE_CANCEL')
    );
    const modificationReprocess = computed(() =>
      store.getters[GET_REPROCESS_SUMMARY](
        'REPROCESS_FOR_MODIFICATION_OR_ADDITIONAL'
      )
    );
    const adjustmentRequests = computed(() =>
      store.getters[GET_REQUESTS_SUMMARY]('RETROACTIVE_ADJUSTMENT_REQUEST')
    );
    const creditRequests = computed(() =>
      store.getters[GET_REQUESTS_SUMMARY]('CREDIT_NOTE_REQUEST')
    );
    const debitRequests = computed(() =>
      store.getters[GET_REQUESTS_SUMMARY]('DEBIT_NOTE_REQUEST')
    );

    watch(reprocess_requests_tab, (val) => {
      if (val === 'reprocess') {
        reprocessSummary('REPROCESS_FOR_INCONSISTENCY');
        reprocessSummary('REPROCESS_FOR_INVOICE_CANCEL');
        reprocessSummary('REPROCESS_FOR_MODIFICATION_OR_ADDITIONAL');
      } else {
        requestsSummary('RETROACTIVE_ADJUSTMENT_REQUEST');
        requestsSummary('CREDIT_NOTE_REQUEST');
        requestsSummary('DEBIT_NOTE_REQUEST');
      }
    });

    // * Objeto de parametros segun filtros por dashboard
    const getObjectParams = computed(() => {
      const obj = {};

      if (selected_companies.value.length != 0) {
        obj.companyId = selected_companies.value.join(',');
      }

      if (period.value) {
        obj.period = moment(period.value, 'YYYY-MM').format('YYYY-MM');
      }

      let params = '';
      Object.keys(obj).forEach((key, index) => {
        if (index === 0) {
          params += `?${key}=${obj[key]}`;
        } else {
          params += `&${key}=${obj[key]}`;
        }
      });

      return params;
    });

    const getMainData = () => {
      const params = getObjectParams.value;
      getCollectionData(params);
      getCollectionFormData(params);
      getCollectionSummary(params);
      reprocessSummary('REPROCESS_FOR_INCONSISTENCY');
      reprocessSummary('REPROCESS_FOR_INVOICE_CANCEL');
      reprocessSummary('REPROCESS_FOR_MODIFICATION_OR_ADDITIONAL');
    };

    onMounted(() => {
      getMainData();
    });

    return {
      period,
      collection_data,
      collection_form_data,
      collection_sumary,
      reprocess_requests_tab,
      inconsistencyReprocess,
      anullmentReprocess,
      modificationReprocess,
      adjustmentRequests,
      creditRequests,
      debitRequests,
      collection_summary_loading
    };
  }
};
</script>
```
