<template>
  <div>
    <div class="mb-3">
      <a-typography-title
        :level="4"
        class="text-gray-8 mb-0 left-aligned payroll-title"
      >
        {{ ins_comp_name }}
      </a-typography-title>

      <div class="right-aligned">
        <div style="display: inline-block">
          <payroll-upload
            :insurance-company-id="ins_comp_id"
            :collection-form-id="col_form_id"
            :payroll-format-id="payrollFormatId"
          />
        </div>
        <div style="display: inline-block" class="ml-4">
          <a-tooltip @click="reloadTable">
            <template #title>Recargar Tabla</template>
            <a-button>
              <template #icon>
                <reload-files-upload />
              </template>
            </a-button>
          </a-tooltip>
        </div>
      </div>

      <div style="clear: both"></div>
    </div>

    <payroll-table
      ref="table_ref"
      :insurance-company-id="ins_comp_id"
      :collection-form-id="col_form_id"
      @allValidPayrolls="payrollsValid"
    />
  </div>
</template>

<script>
import { ReloadOutlined } from '@ant-design/icons-vue';
import { computed } from 'vue';
import { ref } from 'vue';
import PayrollTable from './PayrollTable.vue';
import PayrollFilesUpload from './PayrollFilesUpload.vue';

export default {
  name: 'PayrollList',
  props: {
    insuranceCompanyName: {
      type: String,
      default: ''
    },
    insuranceCompanyId: {
      type: [String, Number],
      default: ''
    },
    payrollFormatId: {
      type: [String, Number],
      default: ''
    },
    collectionFormId: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    'payroll-table': PayrollTable,
    'payroll-upload': PayrollFilesUpload,
    'reload-files-upload': ReloadOutlined
  },
  setup(props, { emit }) {
    // * Props
    const ins_comp_name = computed(() => props.insuranceCompanyName);
    const ins_comp_id = computed(() => props.insuranceCompanyId);
    const col_form_id = computed(() => props.collectionFormId);

    // * Payroll table
    const table_ref = ref();

    // * Funciones
    const reloadTable = async () => {
      table_ref.value.getPayrollsByInsuranceCompanies();
    };

    const payrollsValid = (value) => {
      emit('allValidPayrolls', value, ins_comp_id.value);
    };

    return {
      ins_comp_name,
      ins_comp_id,
      col_form_id,
      table_ref,
      reloadTable,
      payrollsValid
    };
  }
};
</script>

<style scoped>
.payroll-title {
  color: #2521e5 !important;
}
</style>
