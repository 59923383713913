<template>
  <a-modal
    v-model:visible="visibility"
    title="Ejecutar ticket de traspaso de póliza"
  >
    <a-typography-title :level="4"
      >¿Estás seguro que deseas ejecutar la solicitud de traspaso de
      póliza?</a-typography-title
    >

    <div style="display: flex; justify-content: space-between">
      <exclamation-circle-outlined
        :style="{ fontSize: '40px', color: '#FFBF01' }"
      />
      <a-typography class="ml-2"
        >Al ejecutar el ticket se enviará notificación al colaborador de la
        vigencia de su nueva póliza y beneficios</a-typography
      >
    </div>

    <template #footer>
      <a-button @click="closeModal">Volver atrás</a-button>
      <a-button type="primary" @click="executeTransfer">Sí, ejecutar</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, defineExpose, defineEmits } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

const emit = defineEmits(['onExecuteTransfer']);

const visibility = ref(false);

const openModal = () => {
  visibility.value = true;
};

const closeModal = () => {
  visibility.value = false;
};

const executeTransfer = () => {
  emit('onExecuteTransfer');
  closeModal();
};

defineExpose({ openModal });
</script>
