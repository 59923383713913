import {
  SET_TOTAL_PAGES_TABLE,
  SET_ITEMS_PER_PAGE_TABLE,
  SET_CURRENT_PAGE_TABLE
} from '../../../tables/types';

import {
  // inicio inconsistencia
  FETCH_DATA_INCONSISTENCIA,
  GET_DATA_ROWS_INCONSISTENCIA,
  SET_DATA_ROWS_INCONSISTENCIA,

  // detail
  GET_DATA_INCONSISTENCIA_DETAIL,
  SET_DATA_INCONSISTENCIA_DETAIL,
  GET_RECHAZAR_INCONSISTENCIA,
  SET_RECHAZAR_INCONSISTENCIA
} from './types';

const store = {};

const getDefaultState = () => ({
  rows_data: [],
  inconsistencia: [],
  rechazar: []
});

store.state = getDefaultState();

store.getters = {
  [GET_DATA_ROWS_INCONSISTENCIA](state) {
    return state.rows_data;
  },
  [GET_DATA_INCONSISTENCIA_DETAIL](state) {
    return state.inconsistencia;
  },
  [GET_RECHAZAR_INCONSISTENCIA](state) {
    return state.rechazar;
  }
};

store.mutations = {
  [SET_DATA_ROWS_INCONSISTENCIA](state, payload) {
    state.rows_data = payload;
  },
  [SET_DATA_INCONSISTENCIA_DETAIL](state, payload) {
    state.inconsistencia = payload;
  },
  [SET_RECHAZAR_INCONSISTENCIA](state, payload) {
    state.rechazar = payload;
  }
};

store.actions = {
  [FETCH_DATA_INCONSISTENCIA]({ dispatch, commit }) {
    // hice esto para cuando haga el fetch de la tabla, aca voy a obtener la cantidad de rows
    // la pagina actual, cuantos quedan y todo con respecto a la tabla
    // con esto se los voy a pasar al vuex que contiene la data de las tablas
    dispatch(SET_TOTAL_PAGES_TABLE, 30);
    dispatch(SET_ITEMS_PER_PAGE_TABLE, 15);
    dispatch(SET_CURRENT_PAGE_TABLE, 1);
    commit(SET_DATA_ROWS_INCONSISTENCIA, [
      {
        id: 1,
        key: 1,
        stateReproceso: 'En aseguradora',
        holding: 'Sable',
        subHolding: 'Grupo sable',
        businessName: 'Jumpitt',
        insuranceCompany: 'consorcio',
        period: '2022-08',
        action: ''
      },
      {
        id: 2,
        key: 2,
        stateReproceso: 'Cancelado',
        holding: 'Sable',
        subHolding: 'Grupo sable',
        businessName: 'Jumpitt',
        insuranceCompany: 'bci seguros',
        period: '2022-08',
        action: ''
      }
    ]);
  }
};

export default store;
