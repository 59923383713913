import {
  getInsuranceCompanyExecutive,
  postInsuranceCompanyExecutive,
  putInsuranceCompanyExecutive,
  deleteInsuranceCompanyExecutive,
  deleteClientExecutiveInsuranceCompany
} from '../../network';
import {
  GET_INSURANCECOMPANY_EXECUTIVES,
  SET_INSURANCECOMPANY_EXECUTIVES,
  FETCH_INSURANCECOMPANY_EXECUTIVES,
  POST_INSURANCECOMPANY_EXECUTIVE,
  PUT_INSURANCECOMPANY_EXECUTIVE,
  DELETE_INSURANCECOMPANY_EXECUTIVE,
  DELETE_CLIENT_EXECUTIVE_INSURANCE_COMPANY
} from './types';

const store = {};

const getDefaultState = () => ({
  executives: []
});

store.state = getDefaultState();

store.getters = {
  [GET_INSURANCECOMPANY_EXECUTIVES]: (state) => state.executives
};

store.mutations = {
  [SET_INSURANCECOMPANY_EXECUTIVES](state, payload) {
    payload.sort((a, b) => b.isPrincipal - a.isPrincipal);
    state.executives = payload;
  }
};

store.actions = {
  async [FETCH_INSURANCECOMPANY_EXECUTIVES]({ commit }, payload) {
    let error = '';
    const response = await getInsuranceCompanyExecutive(payload).catch(
      (err) => (error = err)
    );
    if (response && response.status === 200) {
      commit(SET_INSURANCECOMPANY_EXECUTIVES, response.data);
    } else {
      throw error;
    }
  },
  async [POST_INSURANCECOMPANY_EXECUTIVE](_, { idInsurance, payload }) {
    let error = '';
    const response = await postInsuranceCompanyExecutive(
      idInsurance,
      payload
    ).catch((err) => (error = err));
    if (response && response.status === 201) {
      return response.data;
    }
    throw error;
  },
  async [PUT_INSURANCECOMPANY_EXECUTIVE](
    _,
    { idInsurance, idExecutive, payload }
  ) {
    let error = '';
    const response = await putInsuranceCompanyExecutive(
      idInsurance,
      idExecutive,
      payload
    ).catch((err) => (error = err));
    if ((response && response.status === 200) || response.status === 201) {
      return response.data;
    }
    throw error;
  },
  async [DELETE_INSURANCECOMPANY_EXECUTIVE](_, payload) {
    return new Promise((resolve, reject) => {
      deleteInsuranceCompanyExecutive(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [DELETE_CLIENT_EXECUTIVE_INSURANCE_COMPANY](_, payload) {
    return new Promise((resolve, reject) => {
      deleteClientExecutiveInsuranceCompany(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default store;
