// import router from '../../router';

import {
  GET_CLIENT_CURRENT_TAB,
  GET_COMPANY_CLIENT_TAB,
  SET_CLIENT_CURRENT_TAB,
  SET_COMPANY_CLIENT_TAB,
  SAVE_CLIENT_CURRENT_TAB,
  SAVE_COMPANY_CLIENT_TAB,
  SELECTED_ACTIVE_TABS,
  SET_ACTIVE_KEY_TABS,
  ACTION_ACTIVE_KEY_TABS
} from './types';

const store = {};

// * State
store.state = {
  client_current_tab: '',
  company_poliza_tab: 'Movimientos',
  active_tabs_company: ''
};

// * Getters
store.getters = {
  [GET_CLIENT_CURRENT_TAB](state) {
    return state.client_current_tab;
  },
  [GET_COMPANY_CLIENT_TAB]: (state) => state.company_poliza_tab,
  [SELECTED_ACTIVE_TABS]: (state) => state.active_tabs_company
};

// * Mutations
store.mutations = {
  [SET_CLIENT_CURRENT_TAB](state, payload) {
    state.client_current_tab = payload;
  },
  [SET_COMPANY_CLIENT_TAB](state, payload) {
    state.company_poliza_tab = payload;
  },
  [SET_ACTIVE_KEY_TABS](state, payload) {
    state.active_tabs_company = payload;
  }
};

// * Actions
store.actions = {
  [SAVE_CLIENT_CURRENT_TAB]({ commit }, payload) {
    commit(SET_CLIENT_CURRENT_TAB, payload);
  },
  [SAVE_COMPANY_CLIENT_TAB]({ commit }, payload) {
    commit(SET_COMPANY_CLIENT_TAB, payload);
  },
  [ACTION_ACTIVE_KEY_TABS]({ commit }, payload) {
    commit(SET_ACTIVE_KEY_TABS, payload);
  }
};

export default store;
