<template>
  <div>
    <a-modal
      title="Eliminar factura"
      :closable="!loading"
      :maskClosable="!loading"
      v-model:visible="visible"
    >
      <a-typography-title :level="5" class="text-black">
        ¿Seguro que deseas eliminar esta Factura N° {{ invoice_id }}?
      </a-typography-title>

      <template #footer>
        <a-button @click="closeModal" :disabled="loading">Cancelar</a-button>
        <a-button
          type="primary"
          :danger="true"
          :loading="loading"
          @click="deleteInvoice"
          >Eliminar</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { DELETE_INVOICE } from '@/store/types';
import notification from '@/utils/notifications';

export default {
  name: 'ObservationsModal',
  setup(_, { emit }) {
    const visible = ref();
    const invoice_id = ref(0);
    const store = useStore();
    const loading = ref(false);

    const openModal = (id) => {
      visible.value = true;
      invoice_id.value = id;
    };

    const closeModal = () => {
      visible.value = false;
    };

    const deleteInvoice = async () => {
      loading.value = true;
      try {
        await store.dispatch(DELETE_INVOICE, invoice_id.value);
        closeModal();
        notification('success', 'Success', 'Factura Eliminada');
        emit('deletedInvoice', true);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    return {
      visible,
      invoice_id,
      loading,
      openModal,
      closeModal,
      deleteInvoice
    };
  }
};
</script>
