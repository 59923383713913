const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
};

const parseDate = (pDate) => {
  const date = new Date(pDate);
  const dd = padTo2Digits(date.getDate());
  const mm = padTo2Digits(date.getMonth() + 1);
  const yyyy = date.getFullYear();
  const HH = padTo2Digits(date.getHours());
  const MM = padTo2Digits(date.getMinutes());
  const formatedDate = `${dd}/${mm}/${yyyy} ${HH}:${MM}hrs`;
  return formatedDate;
};

export default parseDate;
