export const GET_DIGITAL_FORM_TOKEN = 'digitalForm/GET_DIGITAL_FORM_TOKEN';
export const GET_DIGITAL_FORM_STEP = 'digitalForm/GET_DIGITAL_FORM_STEP';

export const FETCH_DIGITAL_FORM = 'digitalForm/FETCH_DIGITAL_FORM';
export const FETCH_BENEFICIARIES = 'digitalForm/FETCH_BENEFICIARIES';
export const FETCH_DEPENDANTS = 'digitalForm/FETCH_DEPENDANTS';
export const FETCH_INSUREDS = 'digitalForm/FETCH_INSUREDS';
export const FETCH_DISEASES = 'digitalForm/FETCH_DISEASES';
export const FETCH_INDUREDS_WITH_DESEASES =
  'digitalForm/FETCH_INDUREDS_WITH_DESEASES';
export const FETCH_BANKS = 'digitalForm/FETCH_BANKS';
export const FETCH_SPORTS = 'digitalForm/FETCH_SPORTS';
export const FETCH_INSURED_WITH_SPORTS =
  'digitalForm/FETCH_INSURED_WITH_SPORTS';
export const FETCH_VISUALIZATION_FILE = 'digitalForm/FETCH_VISUALIZATION_FILE';
export const FETCH_DIGITAL_FORM_COMPANY =
  'digitalForm/FETCH_DIGITAL_FORM_COMPANY';
export const FETCH_DIGITAL_FORM_POLICIES =
  'digitalForm/FETCH_DIGITAL_FORM_POLICIES';
export const FETCH_DIGITAL_FORM_MARITAL_STATUS =
  'digitalForm/FETCH_DIGITAL_FORM_MARITAL_STATUS';
export const FETCH_PREVISIONAL_HEALTH = 'digitalForm/FETCH_PREVISIONAL_HEALTH';
export const FETCH_MUNICIPALITIES = 'digitalForm/FETCH_MUNICIPALITIES';
export const FETCH_DIGITAL_FORM_EMPLOYEE =
  'digitalForm/FETCH_DIGITAL_FORM_EMPLOYEE';
export const FETCH_PAYMENT_METHOD = 'digitalForm/FETCH_PAYMENT_METHOD';
export const FETCH_EMPLOYEE_OBVERSE = 'digitalForm/FETCH_EMPLOYEE_OBVERSE';
export const FETCH_EMPLOYEE_REVERSE = 'digitalForm/FETCH_EMPLOYEE_REVERSE';
export const FETCH_PETS = 'digitalForm/FETCH_PETS';
export const FETCH_PETS_DECLARATION = 'digitalForm/FETCH_PETS_DECLARATION';
export const FETCH_CONSENT = 'digitalForm/FETCH_CONSENT';
export const FETCH_DIGITAL_FORM_RESETTED_TOKEN =
  'digitalForm/FETCH_DIGITAL_FORM_RESETTED_TOKEN';
export const FETCH_SIGN_CODE = 'digitalForm/FETCH_SIGN_CODE';
export const FETCH_FIRM_STATE = 'digitalForm/FETCH_FIRM_STATE';
export const FETCH_INCORPORATION_LOGO = 'digitalForm/FETCH_INCORPORATION_LOGO';

export const SET_DIGITAL_FORM_TOKEN = 'digitalForm/SET_DIGITAL_FORM_TOKEN';
export const SET_DIGITAL_FORM_STEP = 'digitalForm/SET_DIGITAL_FORM_STEP';

export const ACTION_CHANGE_STEP = 'digitalForm/ACTION_CHANGE_STEP';
export const SAVE_DIGITAL_FORM_TOKEN = 'digitalForm/SAVE_DIGITAL_FORM_TOKEN';

export const PUT_CHARGES = 'digitalForm/PUT_CHARGES';
export const PUT_INSUREDS = 'digitalForm/PUT_INSUREDS';
export const PUT_BENEFICIARIES = 'digitalForm/PUT_BENEFICIARIES';
export const PUT_SPORTS = 'digitalForm/PUT_SPORTS';
export const PUT_DIGITAL_FORM_EMPLOYEE =
  'digitalForm/PUT_DIGITAL_FORM_EMPLOYEE';
export const PUT_PAYMENT_METHOD = 'digitalForm/PUT_PAYMENT_METHOD';
export const PUT_EMPLOYEE_OBVERSE = 'digitalForm/PUT_EMPLOYEE_OBVERSE';
export const PUT_EMPLOYEE_REVERSE = 'digitalForm/PUT_EMPLOYEE_REVERSE';
export const PUT_PETS = 'digitalForm/PUT_PETS';
export const PUT_CONSENT = 'digitalForm/PUT_CONSENT';
export const PUT_DIGITAL_FORM_POLICIES =
  'digitalForm/PUT_DIGITAL_FORM_POLICIES';
export const PUT_SIGN_CODE = 'digitalForm/PUT_SIGN_CODE';
