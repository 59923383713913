import {
  createCollectionForm,
  collectionForms,
  collectionTickets,
  collectionFormById,
  createCollectionFormPayroll,
  collectionFormPayrollByInsuranceCompany,
  updateStatecollectionForm,
  collectionFormInsuranceCompanies,
  collectionFormInsuranceCompaniesPolicies,
  collectionFormInsuranceCompaniesInvoices,
  createCollectionFormInsuranceCompaniesInvoices,
  payrollObservations,
  collectionStockDownload,
  invoiceById,
  editInvoice,
  invoicePoliciesById,
  selectInvoicePoliciesById,
  getCollectionGroupComments,
  postCollectionGroupComments,
  companiesByInvoiceId,
  collectionFormSummary,
  payrollReprocessing,
  deletePayroll,
  invoiceObservations,
  createReprocess,
  getCollectionListByCollectionGroup,
  getRequestsByCollectionId,
  getAttachmentsByCollectionId,
  addCollectionTicketPayroll,
  getCollectionTicketPayrolls,
  getCollectionReprocessRecords,
  confirmCollectionReopen,
  postDataSendRRHH,
  getFileCollectionTicket,
  getRequestsById,
  collectionCompaniesSummary,
  deleteInvoice,
  patchOriginalPayroll,
  allCollectionFormSummary,
  allCollectionSummary,
  getCollectionInconsistencies,
  getFileOriginalPayroll,
  squaringCollection,
  downloadFileInconsistencies,
  invoiceSummary,
  invoiceDefaultersSummary,
  invoiceDefaultersSummaryList,
  reprocessInvoice,
  postValidatePayroll,
  getDataSendToRRHH,
  collectionInsuranceCompanyInvoiceUploadValidation,
  collectionResourceObservations,
  collectionResourceDelete,
  collectionResourceReprocess,
  collectionResourceAttachOriginalPayroll,
  collectionFormDownload,
  getInvoicesHumanSend,
  postSendPrecollectionInvoices
} from '../../../network';

// Desde el store de tables
import {
  SET_TOTAL_PAGES_TABLE,
  SET_ITEMS_PER_PAGE_TABLE,
  SET_CURRENT_PAGE_TABLE,
  SET_ROWS_TABLE,
  // SET_ROWS_TABLE,
  FETCH_COLLECTION_TICKETS,
  MUTATE_COLLECTION_TICKETS,
  MUTATE_COLLECTION_FORMS,
  GET_COLLECTION_TICKETS,
  GET_COLLECTION_FORMS
} from '../../types';

import {
  POST_COLLECTION_PAYROLL,
  POST_COLLECTION_FORM,
  POST_COLLECTION_GROUP_COMMENTS,
  FETCH_COLLECTION_FORMS,
  FETCH_COLLECTION_FORM_BY_ID,
  FETCH_COLLECTION_FORM_PAYROLL_BY_INSURANCE_COMPANY,
  MUTATE_COLLECTION_FORM,
  GET_COLLECTION_FORM,
  PATCH_COLLECTION_FORM_STATE,
  FETCH_COLLECTION_FORM_INSURANCE_COMPANIES,
  MUTATE_COLLECTION_FORMS_INSURANCE_COMPANIES,
  GET_COLLECTION_FORM_INSURANCE_COMPANIES,
  FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_POLICIES,
  FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES,
  POST_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES,
  FETCH_PAYROLL_OBSERVATIONS,
  MUTATE_PAYROLL_OBSERVATIONS,
  GET_PAYROLL_OBSERVATIONS,
  FETCH_INVOICE,
  GET_INVOICE,
  MUTATE_INVOICE,
  PATCH_INVOICE,
  MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ONE_INVOICE,
  ADD_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICE,
  MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ADD_INVOICE,
  FETCH_INVOICE_POLICIES_BY_ID,
  MUTATE_INVOICE_POLICIES,
  GET_INVOICE_POLICIES,
  POST_INVOICE_POLICIES,
  GET_COLLECTION_GROUP_COMMENTS,
  MUTATE_COLLECTION_GROUP_COMMENTS,
  FETCH_COLLECTION_GROUP_COMMENTS,
  MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_PAYROLLS,
  GET_COLLECTION_FORM_INSURANCE_COMPANY_PAYROLL,
  ADD_COLLECTION_FORM_INSURANCE_COMPANIES_PAYROLL,
  MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ADD_PAYROLL,
  CLEAR_COLLECTION_FORM,
  FETCH_COMPANIES_INVOICE,
  MUTATE_COMPANIES_INVOICE,
  GET_COMPANIES_INVOICE,
  FETCH_COLLECTION_FORM_SUMMARY,
  MUTATE_COLLECTION_FORM_SUMMARY,
  GET_COLLECTION_FORM_SUMMARY,
  POST_PAYROLL_REPROCESSING,
  DELETE_PAYROLL_FROM_COLLECTION_FORM,
  POST_REPROCESS,
  FETCH_COLLECTION_GROUP_COLLECTIONS,
  MUTATE_COLLECTION_GROUP_COLLECTIONS,
  GET_COLLECTION_GROUP_COLLECTIONS,
  FETCH_REQUESTS_BY_COLLECTION_ID,
  MUTATE_REQUESTS_BY_COLLECTION_ID,
  GET_REQUESTS_BY_COLLECTION_ID,
  FETCH_COLLECTION_ATTACHMENTS,
  MUTATE_COLLECTION_ATTACHMENTS,
  GET_COLLECTION_ATTACHMENTS,
  GET_COLLECTION_DATA_RRHH,
  MUTATE_COLLECTION_DATA_RRHH,
  FETCH_COLLECTION_DATA_SEND_RRHH,
  POST_COLLECTION_DATA_SEND_RRHH,
  FETCH_FILES_COLLECTION_TICKETS,
  GET_FILES_COLLECTION,
  MUTATE_FILE_COLLECTION,
  MUTATE_REQUESTS_BY_ID,
  FETCH_REQUESTS_BY_ID,
  POST_COLLECTION_TICKET_PAYROLL,
  FETCH_COLLECTION_TICKET_PAYROLL,
  MUTATE_COLLECTION_TICKET_PAYROLL,
  GET_COLLECTION_TICKET_PAYROLL,
  FETCH_COLLECTION_REPROCESS_RECORDS,
  MUTATE_COLLECTION_REPROCESS_RECORDS,
  GET_COLLECTION_REPROCESS_RECORDS,
  POST_COLLECTION_CONFIRM_REOPEN,
  GET_REQUEST_BY_ID,
  FETCH_INVOICE_OBSERVATIONS,
  MUTATE_INVOICE_OBSERVATIONS,
  GET_INVOICE_OBSERVATIONS,
  FETCH_COLLECTION_COMPANY_SUMMARY,
  MUTATE_COLLECTION_COMPANY_SUMMARY,
  GET_COLLECTION_COMPANY_SUMMARY,
  DELETE_INVOICE,
  PATCH_ORIGINAL_PAYROLL,
  FETCH_ALL_COLLECTION_FORM_SUMMARY,
  MUTATE_ALL_COLLECTION_FORM_SUMMARY,
  GET_ALL_COLLECTION_FORM_SUMMARY,
  FETCH_ALL_COLLECTION_SUMMARY,
  MUTATE_ALL_COLLECTION_SUMMARY,
  GET_ALL_COLLECTION_SUMMARY,
  FETCH_COLLECTION_INCONSISTENCIES,
  GET_COLLECTION_INCONSISTENCIES,
  MUTATE_COLLECTION_INCONSISTENCIES,
  GET_FILLES_ORIGINAL_PAYROLL,
  MUTATE_FILES_ORIGINAL_PAYROLL_EXIST,
  FETCH_FILES_ORIGINAL_PAYROLL,
  POST_SQUARING_COLLECTION_VALIDATE,
  DOWNLOAD_SQUARING_INCONSISTENCY,
  MUTATION_SQUARING_LINK,
  GET_DOWNLOAD_SQUARING_INCOSISTENCY_LINK,
  FETCH_INVOICE_SUMMARY,
  MUTATE_INVOICE_SUMMARY,
  GET_INVOICE_SUMMARY,
  FETCH_INVOICE_DEFAULTERS_SUMMARY,
  MUTATE_INVOICE_DEFAULTERS_SUMMARY,
  GET_INVOICE_DEFAULTERS_SUMMARY,
  GET_INVOICE_DEFAULTERS_SUMMARY_LIST,
  MUTATE_INVOICE_DEFAULTERS_SUMMARY_LIST,
  FETCH_INVOICE_DEFAULTERS_SUMMARY_LIST,
  REPROCESS_INVOICE,
  POST_COLLECTION_VALDATE_PAYROLL_FORM,
  GET_INSURANCE_COMPANY_INVOICE_UPLOAD_VALIDATION,
  FETCH_COLLECTION_RESOURCE_OBSERVATIONS,
  DELETE_COLLECTION_RESOURCE,
  POST_COLLECTION_RESOURCE_REPROCESS,
  PATCH_COLLECTION_RESOURCE,
  FETCH_COLLECTION_STOCK_DOWNLOAD,
  FETCH_COLLECTION_FORM_DOWNLOAD,

  // send invoices precollection and collection
  FETCH_INVOICE_HUMAN_SEND,
  GET_HUMAN_SEND_INVOICES,
  MUTATION_HUMAN_SEND_INVOICES,
  POST_INVOICE_HUMAN_SEND
} from './types';

const store = {};

store.state = {
  formats: [],
  tickets: [],
  collection_forms: [],
  collection_form: {},
  insurance_companies: [],
  insurance_company_payrolls: {},
  invoice: {},
  invoice_policies: [],
  invoice_summary: {},
  collectionGroupComment: [],
  invoice_companies: [],
  collection_form_summary: {},
  reprocess: {},
  collections: [],
  collectionRequests: [],
  requests: [],
  attachments: [],
  collectionDataRRHH: [],
  getFilesCollection: [],
  collection_ticket_payroll: [],
  reprocess_records: [],
  invoiceObservations: [],
  collection_company_summary: {},
  collection_form_resume: {},
  collection_resume: {},
  FIlesOriginalPayroll: [],
  linkSquaringInconsistency: '',
  invoice_defaulter_summary: {},
  invoice_defaulter_summary_list: [],
  collectionInconsistencies: [],
  humandSendInvoices: []
};

store.getters = {
  [GET_COLLECTION_TICKETS](state) {
    return state.tickets;
  },
  [GET_COLLECTION_FORMS](state) {
    return state.collection_forms;
  },
  [GET_COLLECTION_FORM](state) {
    return state.collection_form;
  },
  [GET_COLLECTION_FORM_INSURANCE_COMPANIES](state) {
    return state.insurance_companies;
  },
  [GET_PAYROLL_OBSERVATIONS](state) {
    return state.payroll_observations;
  },
  [GET_INVOICE](state) {
    return state.invoice;
  },
  [GET_COLLECTION_FORM_INSURANCE_COMPANY_PAYROLL]: (state) => (payload) => {
    return state.insurance_company_payrolls[payload];
  },
  [GET_INVOICE_POLICIES](state) {
    return state.invoice_policies;
  },
  [GET_COLLECTION_GROUP_COMMENTS]: (state) => state.collectionGroupComment,
  [GET_COMPANIES_INVOICE]: (state) => state.invoice_companies,
  [GET_COLLECTION_FORM_SUMMARY]: (state) => state.collection_form_summary,
  [GET_COLLECTION_GROUP_COLLECTIONS]: (state) => state.collections,
  [GET_REQUESTS_BY_COLLECTION_ID]: (state) => state.collectionRequests,
  [GET_COLLECTION_ATTACHMENTS]: (state) => state.attachments,
  [GET_COLLECTION_DATA_RRHH]: (state) => state.collectionDataRRHH,
  [GET_FILES_COLLECTION]: (state) => state.getFilesCollection,
  [GET_REQUEST_BY_ID]: (state) => state.requests,
  [GET_COLLECTION_TICKET_PAYROLL]: (state) => state.collection_ticket_payroll,
  [GET_INVOICE_OBSERVATIONS]: (state) => state.invoiceObservations,
  [GET_COLLECTION_REPROCESS_RECORDS]: (state) => state.reprocess_records,
  [GET_COLLECTION_COMPANY_SUMMARY]: (state) => state.collection_company_summary,
  [GET_ALL_COLLECTION_FORM_SUMMARY]: (state) => state.collection_form_resume,
  [GET_ALL_COLLECTION_SUMMARY]: (state) => state.collection_resume,
  [GET_COLLECTION_INCONSISTENCIES]: (state) => state.collectionInconsistencies,
  [GET_FILLES_ORIGINAL_PAYROLL]: (state) =>
    state.FIlesOriginalPayroll == null
      ? []
      : [state.FIlesOriginalPayroll].map((e) => ({
        uid: e.id,
        lastModified: e.updateAt,
        url: e.objectPath,
        lastModifiedDate: e.createAt,
        name: e.objectFilename,
        type: 'application/xlsx'
      })),
  [GET_DOWNLOAD_SQUARING_INCOSISTENCY_LINK]: (state) =>
    state.linkSquaringInconsistency,
  [GET_INVOICE_SUMMARY]: (state) => state.invoice_summary,
  [GET_INVOICE_DEFAULTERS_SUMMARY]: (state) => state.invoice_defaulter_summary,
  [GET_INVOICE_DEFAULTERS_SUMMARY_LIST]: (state) =>
    state.invoice_defaulter_summary_list,
  [GET_HUMAN_SEND_INVOICES]: (state) => state.humandSendInvoices
};

store.mutations = {
  [MUTATE_COLLECTION_TICKETS](state, payload) {
    state.tickets = payload;
  },
  [MUTATE_COLLECTION_FORMS](state, payload) {
    state.collection_forms = payload;
  },
  [MUTATE_COLLECTION_FORM](state, payload) {
    state.collection_form = payload;
  },
  [MUTATE_COLLECTION_FORMS_INSURANCE_COMPANIES](state, payload) {
    state.insurance_companies = payload;
  },
  [MUTATE_PAYROLL_OBSERVATIONS](state, payload) {
    state.payroll_observations = payload;
  },
  [MUTATE_INVOICE](state, payload) {
    state.invoice = payload;
  },
  [MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_PAYROLLS](
    state,
    { id, payload }
  ) {
    state.insurance_company_payrolls[id] = payload;
  },
  [MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ONE_INVOICE](
    state,
    { key, value, payload }
  ) {
    for (const insurance of Object.keys(state.insurance_company_invoices)) {
      const found_index = state.insurance_company_invoices[insurance].findIndex(
        (obj) => obj[key] === value
      );
      console.log(found_index);
      if (found_index > -1) {
        state.insurance_company_invoices[insurance][found_index] = {
          ...state.insurance_company_invoices[insurance][found_index],
          ...payload
        };
      }
    }
  },
  [MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ADD_INVOICE](
    state,
    { insurance_comp_id, payload }
  ) {
    state.insurance_company_invoices[insurance_comp_id].push(payload);
  },
  [MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ADD_PAYROLL](
    state,
    { insurance_comp_id, payload }
  ) {
    state.insurance_company_payrolls[insurance_comp_id].push(payload);
  },
  [MUTATE_INVOICE_POLICIES](state, payload) {
    state.invoice_policies = payload;
  },
  [MUTATE_COLLECTION_GROUP_COMMENTS](state, payload) {
    state.collectionGroupComment = payload;
  },
  [MUTATE_COMPANIES_INVOICE](state, payload) {
    state.invoice_companies = payload;
  },
  [MUTATE_COLLECTION_FORM_SUMMARY](state, payload) {
    state.collection_form_summary = payload;
  },
  [MUTATE_COLLECTION_GROUP_COLLECTIONS](state, payload) {
    state.collections = payload;
  },
  [MUTATE_REQUESTS_BY_COLLECTION_ID](state, payload) {
    state.collectionRequests = payload;
  },
  [MUTATE_COLLECTION_ATTACHMENTS](state, payload) {
    state.attachments = payload;
  },
  [MUTATE_COLLECTION_DATA_RRHH](state, payload) {
    state.collectionDataRRHH = payload;
  },
  [MUTATE_FILE_COLLECTION](state, payload) {
    state.getFilesCollection = payload;
  },
  [MUTATE_REQUESTS_BY_ID](state, payload) {
    state.requests = payload;
  },
  [MUTATE_COLLECTION_TICKET_PAYROLL](state, payload) {
    state.collection_ticket_payroll = payload;
  },
  [MUTATE_COLLECTION_REPROCESS_RECORDS](state, payload) {
    state.reprocess_records = payload;
  },
  [MUTATE_INVOICE_OBSERVATIONS](state, payload) {
    state.invoiceObservations = payload;
  },
  [MUTATE_REQUESTS_BY_ID](state, payload) {
    state.requests = payload;
  },
  [MUTATE_ALL_COLLECTION_FORM_SUMMARY](state, payload) {
    state.collection_form_resume = payload;
  },
  [MUTATE_ALL_COLLECTION_SUMMARY](state, payload) {
    state.collection_resume = payload;
  },
  [MUTATE_COLLECTION_COMPANY_SUMMARY](state, payload) {
    state.collection_company_summary = payload;
  },
  [MUTATE_INVOICE_SUMMARY](state, payload) {
    state.invoice_summary = payload;
  },
  [MUTATE_INVOICE_DEFAULTERS_SUMMARY](state, payload) {
    state.invoice_defaulter_summary = payload;
  },
  [MUTATE_INVOICE_DEFAULTERS_SUMMARY_LIST](state, payload) {
    state.invoice_defaulter_summary_list = payload;
  },
  [MUTATE_COLLECTION_INCONSISTENCIES](state, payload) {
    state.collectionInconsistencies = payload;
  },
  [MUTATE_FILES_ORIGINAL_PAYROLL_EXIST](state, payload) {
    state.FIlesOriginalPayroll = payload;
  },
  [MUTATION_SQUARING_LINK](state, payload) {
    state.linkSquaringInconsistency = payload;
  },
  [MUTATION_HUMAN_SEND_INVOICES](state, payload) {
    state.humandSendInvoices = payload;
  }
};

store.actions = {
  [POST_COLLECTION_FORM](_, payload) {
    return new Promise((resolve, reject) => {
      createCollectionForm(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_FORMS]({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      collectionForms(payload)
        .then((resp) => {
          commit(MUTATE_COLLECTION_FORMS, resp.data.rows);
          dispatch(SET_ROWS_TABLE, resp.data.rows);
          dispatch(SET_ITEMS_PER_PAGE_TABLE, resp.data.items_per_page);
          dispatch(SET_CURRENT_PAGE_TABLE, resp.data.page);
          dispatch(SET_TOTAL_PAGES_TABLE, resp.data.total_items);

          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_TICKETS]({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      collectionTickets(payload)
        .then((resp) => {
          console.log('responseee', resp);
          commit(MUTATE_COLLECTION_TICKETS, resp.data.rows);
          dispatch(SET_ROWS_TABLE, resp.data.rows);
          dispatch(SET_ITEMS_PER_PAGE_TABLE, resp.data.items_per_page);
          dispatch(SET_CURRENT_PAGE_TABLE, resp.data.page);
          dispatch(SET_TOTAL_PAGES_TABLE, resp.data.total_items);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_FORM_BY_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      collectionFormById(payload)
        .then((resp) => {
          commit(MUTATE_COLLECTION_FORM, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CLEAR_COLLECTION_FORM]({ commit }) {
    commit(MUTATE_COLLECTION_FORM, []);
  },
  [FETCH_COLLECTION_FORM_PAYROLL_BY_INSURANCE_COMPANY](
    { getters, commit },
    { collection_form_id, insurance_company_id }
  ) {
    return new Promise((resolve, reject) => {
      collectionFormPayrollByInsuranceCompany(
        collection_form_id,
        insurance_company_id
      )
        .then((resp) => {
          const payload = resp.data;

          console.log('payload', payload);

          const payrolls =
            getters[GET_COLLECTION_FORM_INSURANCE_COMPANY_PAYROLL](
              insurance_company_id
            );

          const in_upload = payrolls?.filter(
            (obj) => obj.state === 'EN SUBIDA'
          );

          if (in_upload != undefined) {
            in_upload.forEach((uploaded) => {
              const search = resp.data.find(
                (data) => data.name === uploaded.name
              );
              if (search == undefined) {
                payload.push(uploaded);
              }
            });
          }

          commit(MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_PAYROLLS, {
            id: insurance_company_id,
            payload
          });

          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_COLLECTION_PAYROLL]({ dispatch }, { id, payload }) {
    return new Promise((resolve, reject) => {
      createCollectionFormPayroll(id, payload)
        .then((resp) => {
          dispatch(FETCH_COLLECTION_FORM_PAYROLL_BY_INSURANCE_COMPANY, {
            collection_form_id: id,
            insurance_company_id: payload.get('insuranceCompanyId')
          });
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_FORM_INSURANCE_COMPANIES]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      collectionFormInsuranceCompanies(payload)
        .then((resp) => {
          commit(MUTATE_COLLECTION_FORMS_INSURANCE_COMPANIES, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_POLICIES](
    _,
    { collection_id, insurance_id, type }
  ) {
    return new Promise((resolve, reject) => {
      collectionFormInsuranceCompaniesPolicies(
        collection_id,
        insurance_id,
        type
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES](
    _,
    { collection_id, insurance_id, type }
  ) {
    return new Promise((resolve, reject) => {
      collectionFormInsuranceCompaniesInvoices(
        collection_id,
        insurance_id,
        type
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  [POST_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES](
    _,
    { collection_id, insurance_id, payload }
  ) {
    return new Promise((resolve, reject) => {
      createCollectionFormInsuranceCompaniesInvoices(
        collection_id,
        insurance_id,
        payload
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ADD_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICE](
    { commit },
    { insurance_comp_id, payload }
  ) {
    commit(MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ADD_INVOICE, {
      insurance_comp_id,
      payload
    });
  },
  [ADD_COLLECTION_FORM_INSURANCE_COMPANIES_PAYROLL](
    { commit },
    { insurance_comp_id, payload }
  ) {
    // Esta funcion es para agregar un archivo en la tabla de manera dummy para hacer el efecto de carga en las tablas
    // de collection form
    commit(MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ADD_PAYROLL, {
      insurance_comp_id,
      payload
    });
  },
  [FETCH_PAYROLL_OBSERVATIONS]({ commit }, id) {
    return new Promise((resolve, reject) => {
      payrollObservations(id)
        .then((resp) => {
          commit(MUTATE_PAYROLL_OBSERVATIONS, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          commit(MUTATE_PAYROLL_OBSERVATIONS, []);
          reject(err);
        });
    });
  },
  [FETCH_INVOICE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      invoiceById(payload)
        .then((resp) => {
          commit(MUTATE_INVOICE, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_INVOICE](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      editInvoice(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_INVOICE](_, payload) {
    return new Promise((resolve, reject) => {
      deleteInvoice(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REPROCESS_INVOICE](_, payload) {
    return new Promise((resolve, reject) => {
      reprocessInvoice(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_INVOICE_POLICIES_BY_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      invoicePoliciesById(payload)
        .then((resp) => {
          commit(MUTATE_INVOICE_POLICIES, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_COLLECTION_FORM_STATE]({ dispatch }, { id, payload }) {
    return new Promise((resolve, reject) => {
      updateStatecollectionForm(id, payload)
        .then((resp) => {
          // Obtengo denuevo la data del collection form
          dispatch(FETCH_COLLECTION_FORM_BY_ID, id);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_GROUP_COMMENTS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getCollectionGroupComments(payload)
        .then((resp) => {
          commit(MUTATE_COLLECTION_GROUP_COMMENTS, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_COLLECTION_GROUP_COMMENTS](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      postCollectionGroupComments(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_INVOICE_POLICIES](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      selectInvoicePoliciesById(id, payload)
        .then((resp) => {
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COMPANIES_INVOICE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companiesByInvoiceId(payload)
        .then((resp) => {
          commit(MUTATE_COMPANIES_INVOICE, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_FORM_SUMMARY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      collectionFormSummary(payload)
        .then((resp) => {
          const summary = resp.data.map((obj) => {
            return {
              category: obj.insuranceCompany.businessName,
              values: [
                {
                  name: 'N° de nóminas',
                  quantity: obj.collections.length
                },
                {
                  name: 'N° de empresas',
                  quantity: obj.companies.length
                },
                {
                  name: 'N° de facturas',
                  quantity: obj.invoices.length
                }
              ]
            };
          });

          commit(MUTATE_COLLECTION_FORM_SUMMARY, summary);

          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_ALL_COLLECTION_SUMMARY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      allCollectionSummary(payload)
        .then((resp) => {
          commit(MUTATE_ALL_COLLECTION_SUMMARY, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_PAYROLL_REPROCESSING](_, payload) {
    return new Promise((resolve, reject) => {
      payrollReprocessing(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_PAYROLL_FROM_COLLECTION_FORM](_, payload) {
    return new Promise((resolve, reject) => {
      deletePayroll(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_INVOICE_OBSERVATIONS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      invoiceObservations(payload)
        .then((resp) => {
          commit(MUTATE_INVOICE_OBSERVATIONS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_REPROCESS](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      createReprocess(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_DATA_SEND_RRHH]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getDataSendToRRHH(payload)
        .then((resp) => {
          commit(MUTATE_COLLECTION_DATA_RRHH, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_GROUP_COLLECTIONS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getCollectionListByCollectionGroup(payload)
        .then((resp) => {
          commit(MUTATE_COLLECTION_GROUP_COLLECTIONS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_REQUESTS_BY_COLLECTION_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getRequestsByCollectionId(payload)
        .then((resp) => {
          commit(MUTATE_REQUESTS_BY_COLLECTION_ID, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_ATTACHMENTS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getAttachmentsByCollectionId(payload)
        .then((resp) => {
          commit(MUTATE_COLLECTION_ATTACHMENTS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_COLLECTION_DATA_SEND_RRHH](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      postDataSendRRHH(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_FILES_COLLECTION_TICKETS](
    { commit },
    { collection_id, query_params }
  ) {
    return new Promise((resolve, reject) => {
      getFileCollectionTicket(collection_id, query_params)
        .then((resp) => {
          commit(MUTATE_FILE_COLLECTION, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_REQUESTS_BY_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getRequestsById(payload)
        .then((resp) => {
          commit(MUTATE_REQUESTS_BY_ID, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_COLLECTION_TICKET_PAYROLL](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      addCollectionTicketPayroll(id, payload)
        .then((resp) => {
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_TICKET_PAYROLL]({ commit }, id) {
    return new Promise((resolve, reject) => {
      getCollectionTicketPayrolls(id)
        .then((resp) => {
          commit(MUTATE_COLLECTION_TICKET_PAYROLL, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_REPROCESS_RECORDS]({ commit }, id) {
    return new Promise((resolve, reject) => {
      getCollectionReprocessRecords(id)
        .then((resp) => {
          commit(MUTATE_COLLECTION_REPROCESS_RECORDS, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_COLLECTION_CONFIRM_REOPEN](_, payload) {
    console.log('POST_COLLECTION_CONFIRM_REOPEN');
    return new Promise((resolve, reject) => {
      confirmCollectionReopen(payload)
        .then((resp) => {
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_COLLECTION_VALDATE_PAYROLL_FORM](_, payload) {
    return new Promise((resolve, reject) => {
      postValidatePayroll(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_ALL_COLLECTION_FORM_SUMMARY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      allCollectionFormSummary(payload)
        .then((resp) => {
          commit(MUTATE_ALL_COLLECTION_FORM_SUMMARY, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_ALL_COLLECTION_SUMMARY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      allCollectionSummary(payload)
        .then((resp) => {
          commit(MUTATE_ALL_COLLECTION_SUMMARY, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_COMPANY_SUMMARY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      collectionCompaniesSummary(payload)
        .then((resp) => {
          commit(MUTATE_COLLECTION_COMPANY_SUMMARY, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_ORIGINAL_PAYROLL](_, { collectionId, payload }) {
    const formData = new FormData();
    formData.append('file', payload.file[0]);
    return new Promise((resolve, reject) => {
      patchOriginalPayroll(collectionId, formData)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [FETCH_FILES_ORIGINAL_PAYROLL]({ commit }, collectionId) {
    return new Promise((resolve, reject) => {
      getFileOriginalPayroll(collectionId)
        .then((resp) => {
          commit(MUTATE_FILES_ORIGINAL_PAYROLL_EXIST, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_INVOICE_SUMMARY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      invoiceSummary(payload)
        .then((resp) => {
          commit(MUTATE_INVOICE_SUMMARY, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_SQUARING_COLLECTION_VALIDATE](_, { collectionId, payload }) {
    return new Promise((resolve, reject) => {
      squaringCollection(collectionId, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_INVOICE_DEFAULTERS_SUMMARY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      invoiceDefaultersSummary(payload)
        .then((resp) => {
          commit(MUTATE_INVOICE_DEFAULTERS_SUMMARY, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTION_INCONSISTENCIES](
    { commit },
    { collection_id, query_params }
  ) {
    return new Promise((resolve, reject) => {
      getCollectionInconsistencies(collection_id, query_params)
        .then((resp) => {
          commit(MUTATE_COLLECTION_INCONSISTENCIES, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_INVOICE_DEFAULTERS_SUMMARY_LIST]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      invoiceDefaultersSummaryList(payload)
        .then((resp) => {
          commit(MUTATE_INVOICE_DEFAULTERS_SUMMARY_LIST, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_INSURANCE_COMPANY_INVOICE_UPLOAD_VALIDATION](
    _,
    { collection_group_id, insurance_id }
  ) {
    return new Promise((resolve, reject) => {
      collectionInsuranceCompanyInvoiceUploadValidation(
        collection_group_id,
        insurance_id
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [DOWNLOAD_SQUARING_INCONSISTENCY]({ commit }, { collectionId, type }) {
    const data = downloadFileInconsistencies(collectionId, type);
    commit(MUTATION_SQUARING_LINK, data);
  },
  [FETCH_COLLECTION_RESOURCE_OBSERVATIONS](
    _,
    { collection_id, collection_resource_id }
  ) {
    return new Promise((resolve, reject) => {
      collectionResourceObservations(collection_id, collection_resource_id)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  },
  [DELETE_COLLECTION_RESOURCE](_, payload) {
    return new Promise((resolve, reject) => {
      collectionResourceDelete(payload)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  },
  [POST_COLLECTION_RESOURCE_REPROCESS](
    _,
    { collection_ticket_id, collection_resource_id }
  ) {
    return new Promise((resolve, reject) => {
      collectionResourceReprocess(collection_ticket_id, collection_resource_id)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  },
  [PATCH_COLLECTION_RESOURCE](
    _,
    { collection_id, collection_resource_id, payload }
  ) {
    return new Promise((resolve, reject) => {
      collectionResourceAttachOriginalPayroll(
        collection_id,
        collection_resource_id,
        payload
      )
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  },
  [FETCH_COLLECTION_STOCK_DOWNLOAD](_, payload) {
    return new Promise((resolve, reject) => {
      try {
        const resp = collectionStockDownload(payload);
        resolve(resp);
      } catch (error) {
        reject(error);
      }
    });
  },
  [FETCH_COLLECTION_FORM_DOWNLOAD](_, payload) {
    return new Promise((resolve, reject) => {
      try {
        const resp = collectionFormDownload(payload);
        resolve(resp);
      } catch (error) {
        reject(error);
      }
    });
  },
  [FETCH_INVOICE_HUMAN_SEND]({ commit }, { collectionId, insuranceCompanyId }) {
    return new Promise((resolve, reject) => {
      getInvoicesHumanSend(collectionId, insuranceCompanyId)
        .then((resp) => {
          commit(MUTATION_HUMAN_SEND_INVOICES, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          commit(MUTATION_HUMAN_SEND_INVOICES, []);
          reject(err);
        });
    });
  },
  [POST_INVOICE_HUMAN_SEND](_, { collectionId, insuranceCompanyId, payload }) {
    return new Promise((resolve, reject) => {
      postSendPrecollectionInvoices(collectionId, insuranceCompanyId, payload)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  }
};

export default store;
