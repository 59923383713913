import {
  getPolicies,
  postPolicy,
  putPolicy,
  deletePolicy,
  companyGroupCompany,
  postCoverage,
  putCoverage,
  deleteCoverage,
  postConfigPolicy,
  getPolicyInvoicePayment,
  getCutOffs,
  getAllConfigPolicy,
  putConfigPolicy,
  getCoverages,
  getPolicy,
  getPolicyCoverages,
  getCoverage,
  policyGroupCompany
} from '../../../network';
import {
  GET_COMPANY_POLICIES,
  SET_COMPANY_POLICIES,
  FETCH_COMPANY_POLICIES,
  GET_COMPANY_GROUPS,
  SET_COMPANY_GROUPS,
  FETCH_COMPANY_GROUPS,
  POST_COMPANY_POLICY,
  PUT_COMPANY_POLICY,
  DELETE_COMPANY_POLICY,
  POST_COMPANY_POLICY_COVERAGES,
  PUT_COMPANY_POLICY_COVERAGES,
  DELETE_COMPANY_POLICY_COVERAGE,
  FETCH_INVOICE_PAYMENT_POLICY,
  SET_INVOICE_PAYMENT_POLICY,
  GET_INVOICE_PAYMENT_POLICY,
  POST_CONFIG_POLICY,
  GET_CUT_OFFS_POLICY,
  FETCH_CUT_OFFS_POLICY,
  SET_CUT_OFFS_POLICY,
  GET_ALL_CONFIG,
  GET_COMPANY_POLICIES_VIGENTES,
  SET_ALL_COMPANIES_POLICIES,
  FETCH_ALL_CONFIG_POLICY,
  PUT_CONFIG_POLIZA,
  FETCH_POLICIES_COVERAGES,
  FETCH_POLICY,
  FETCH_POLICY_COVERAGES,
  FETCH_COVERAGE,
  FETCH_POLICY_GROUP_COMPANY
} from './types';

const store = {};

const getDefaultState = () => ({
  policies: [],
  groups: [],
  invoicePayment: [],
  cuttOffsPolicy: [],
  allConfigPolicy: []
});

store.state = getDefaultState();

store.getters = {
  [GET_COMPANY_POLICIES]: (state) => state.policies,
  [GET_COMPANY_GROUPS]: (state) => state.groups,
  [GET_INVOICE_PAYMENT_POLICY]: (state) =>
    state.invoicePayment.map((e) => ({
      label: `${e.days} Días`,
      value: e.id
    })),
  [GET_CUT_OFFS_POLICY]: (state) =>
    state.cuttOffsPolicy.map((e) => ({
      label: `${e.days} Días`,
      value: e.id
    })),
  [GET_ALL_CONFIG]: (state) =>
    state.allConfigPolicy.map((e) => {
      if (e.collectionType == 'collection') {
        e.collectionType = 'Cobranza';
      } else if (e.collectionType == 'precollection') {
        e.collectionType = 'Pre-cobranza';
      }
      if (e.configurationType == 'expired') {
        e.configurationType = 'Vencida';
      } else if (e.configurationType == 'anticipated') {
        e.configurationType = 'Anticipada';
      }
      return e;
    }),
  [GET_COMPANY_POLICIES_VIGENTES]: (state) =>
    state.policies
      .filter((f) => f.status == 'Vigente')
      .map((e) => ({
        label: e.policyNumber,
        value: e.id,
        status: e.status,
        data: e
      }))
};

store.mutations = {
  [SET_COMPANY_POLICIES](state, payload) {
    console.log('Llamado a SET_COMPANY_POLICIES');
    state.policies = payload.map((a) => {
      if (a.insuranceCompany) {
        return {
          id: a.id,
          key: a.id,
          policyNumber: a.numberPolicy,
          groups: a.groupCompanies.map((g) => {
            return { id: g.id, name: g.name };
          }),
          group: 1,
          groupName: 'Sin información',
          insuranceCompany: a.insuranceCompanyId,
          insuranceCompanyName: a.insuranceCompany.businessName,
          initCurrency: a.startDate,
          endCurrency: a.endDate,
          docs: a.filePolicies,
          status: a.status,
          covers: a.coverages.map((obj) => {
            return {
              id: obj.id,
              name: obj.name,
              required: obj.required
            };
          })
        };
      }
    });
  },
  [SET_COMPANY_GROUPS](state, payload) {
    state.groups = payload.map((a) => {
      return { value: a.id, label: a.name };
    });
  },
  [SET_INVOICE_PAYMENT_POLICY](state, payload) {
    state.invoicePayment = payload;
  },
  [SET_CUT_OFFS_POLICY](state, payload) {
    state.cuttOffsPolicy = payload;
  },
  [SET_ALL_COMPANIES_POLICIES](state, payload) {
    state.allConfigPolicy = payload;
  }
};

store.actions = {
  [FETCH_COMPANY_POLICIES]({ commit }, payload) {
    console.log('Llamado a FETCH_COMPANY_POLICIES');
    return new Promise((resolve, reject) => {
      getPolicies(payload)
        .then((resp) => {
          commit(SET_COMPANY_POLICIES, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async [FETCH_COMPANY_GROUPS]({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      companyGroupCompany(companyId)
        .then((resp) => {
          commit(SET_COMPANY_GROUPS, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [POST_COMPANY_POLICY](_, { payload }) {
    console.log('Llamado a POST_COMPANY_POLICY');
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('companyId', payload.companyId);
      formData.append('numberPolicy', payload.numberPolicy);
      formData.append('insuranceCompanyId', payload.insuranceCompanyId);
      if (payload.groupCheck) {
        formData.append('groupCompanies', []);
      } else {
        payload.groupId.forEach((group) => {
          formData.append('groupCompanies', group);
        });
      }
      formData.append('startDate', new Date(payload.startDate).toISOString());
      formData.append('endDate', new Date(payload.endDate).toISOString());
      payload.fileList.forEach((files) => {
        formData.append('files', files);
      });
      postPolicy(formData)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_COMPANY_POLICY](_, payload) {
    const formData = new FormData();
    const policyId = payload.id;
    formData.append('companyId', payload.companyId);
    formData.append('numberPolicy', payload.numberPolicy);
    formData.append('insuranceCompanyId', payload.insuranceCompanyId);
    payload.groupId.forEach((group) => {
      formData.append('groupCompanies', group);
    });
    formData.append('startDate', new Date(payload.startDate).toISOString());
    formData.append('endDate', new Date(payload.endDate).toISOString());
    payload.fileList.forEach((file) => {
      if (file.id === undefined && file.fileName === undefined) {
        formData.append('files', file);
      }
    });
    payload.removeFiles.forEach((rf) => {
      formData.append('removeFiles', rf);
    });

    return new Promise((resolve, reject) => {
      putPolicy(policyId, formData)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async [DELETE_COMPANY_POLICY](_, payload) {
    let error = '';
    const response = await deletePolicy(payload).catch((err) => (error = err));
    if (response && response.status === 200) {
      return response.data;
    }
    throw error;
  },
  [POST_COMPANY_POLICY_COVERAGES](_, payload) {
    return new Promise((resolve, reject) => {
      postCoverage(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_COMPANY_POLICY_COVERAGES](_, { id_coverage, payload }) {
    return new Promise((resolve, reject) => {
      putCoverage(id_coverage, payload)
        .then((resp) => {
          resolve(resp.status);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_COMPANY_POLICY_COVERAGE](_, id_coverage) {
    return new Promise((resolve, reject) => {
      deleteCoverage(id_coverage)
        .then((resp) => {
          resolve(resp.status);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async [POST_CONFIG_POLICY](_, { payload, id }) {
    return new Promise((resolve, reject) => {
      postConfigPolicy(id, payload)
        .then((resp) => {
          resolve(resp.status);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_INVOICE_PAYMENT_POLICY]({ commit }) {
    return new Promise((resolve, reject) => {
      getPolicyInvoicePayment().then(
        (resp) => {
          commit(SET_INVOICE_PAYMENT_POLICY, resp.data);
          resolve(resp);
        },
        (err) => {
          commit(SET_INVOICE_PAYMENT_POLICY, []);
          reject(err);
        }
      );
    });
  },
  [FETCH_CUT_OFFS_POLICY]({ commit }) {
    return new Promise((resolve, reject) => {
      getCutOffs().then(
        (resp) => {
          commit(SET_CUT_OFFS_POLICY, resp.data);
          resolve(resp);
        },
        (err) => {
          commit(SET_CUT_OFFS_POLICY, []);
          reject(err);
        }
      );
    });
  },
  [FETCH_ALL_CONFIG_POLICY]({ commit }, { company_id, params }) {
    return new Promise((resolve, reject) => {
      getAllConfigPolicy(company_id, params).then(
        (resp) => {
          commit(SET_ALL_COMPANIES_POLICIES, resp.data);
          resolve(resp);
        },
        (err) => {
          commit(SET_ALL_COMPANIES_POLICIES, []);
          reject(err);
        }
      );
    });
  },
  [PUT_CONFIG_POLIZA](_, payload) {
    return new Promise((resolve, reject) => {
      putConfigPolicy(payload)
        .then((resp) => {
          // dispatch(FETCH_COMPANY_POLICIES,companyId);
          resolve(resp.status);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_POLICIES_COVERAGES](_, payload) {
    return new Promise((resolve, reject) => {
      getCoverages(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_POLICY](_, payload) {
    return new Promise((resolve, reject) => {
      getPolicy(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_POLICY_COVERAGES](_, payload) {
    return new Promise((resolve, reject) => {
      getPolicyCoverages(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_COVERAGE](_, payload) {
    return new Promise((resolve, reject) => {
      getCoverage(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_POLICY_GROUP_COMPANY](_, payload) {
    return new Promise((resolve, reject) => {
      policyGroupCompany(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default store;
