// Retorna el color de los tags según estado
const getTagColor = (state) => {
  switch (state?.toUpperCase() || state?.toLowerCase()) {
    case 'CREADO':
      return 'processing';
    case 'CREATED':
      return 'processing';
    case 'INICIADA':
      return 'processing';
    case 'BEWELL_VALIDATION':
      return 'warning';
    case 'VALIDACIÓN CORREDORA':
      return 'warning';
    case 'HR_VALIDATION':
      return 'warning';
    case 'VALIDACIÓN RRHH':
      return 'warning';
    case 'APPROVED':
      return 'success';
    case 'APROBADA':
      return 'success';
    case 'EN REVISIÓN CORREDORA':
      return 'warning';
    case 'EN REPROCESO':
      return 'error';
    case 'EN ASEGURADORA':
      return 'warning';
    case 'RECHAZADO':
      return 'error';
    case 'EN REVISION ASEGURADORA':
      return 'warning';
    case 'PENDIENTE':
      return 'warning';
    case 'EN APELACION':
      return 'warning';
    case 'APELACION':
      return 'warning';
    case 'EJECUTADO':
      return 'success';
    case 'EJECUTADA':
      return 'success';
    case 'FINALIZADO':
      return 'success';
    case 'CANCELADO':
      return 'error';
    case 'VIGENTE':
      return 'success';
    case 'BAJA':
      return 'gray';
    case 'PENDING':
      return 'warning';
    case 'INACTIVE':
      return 'error';
    case 'ACTIVE':
      return 'success';
    case 'ISSUED':
      return 'processing';
    case 'PROCCESING':
      return 'warning';
    case 'NOT_ISSUED':
      return 'warning';
    case 'LOADED':
      return 'success';
    case 'CANCELLED':
      return 'error';
    case 'WAITING':
      return 'default';
    case 'PROCESSING':
      return 'processing';
    case 'PROCESSED':
      return 'success';
    case 'NO_PROCESSED':
      return 'warning';
    case 'NO_MATCH':
      return 'error';
    case 'MATCH':
      return 'success';
    case 'ERROR':
      return 'error';
    case 'PROCESSED_ISSUES':
      return 'warning';
    default:
      return 'default';
  }
};

export default getTagColor;
