<template>
  <div>
    <a-typography-text class="text-gray-8 fs-xl d-block">
      {{ total }}
    </a-typography-text>
    <a-typography-text class="text-gray-8 fs-sm d-block">
      {{ title }}
    </a-typography-text>
  </div>
  <a-divider
    v-if="showSeparator"
    type="vertical"
    style="height: 53.28px"
    class="mx-4"
  />
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Ingrese un titulo'
    },
    total: {
      type: [String, Number],
      default: 0
    },
    showSeparator: {
      type: Boolean,
      default: true
    }
  },
  setup() {}
};
</script>
