<template>
  <div v-if="$route.name === 'Bajas'">
    <page-header :breadcrumRoutes="routes_header" title="Bajas">
      <template #buttons>
        <a-button type="primary" @click="showModal">Crear baja</a-button>
      </template>
    </page-header>

    <!-- Resumen de Bajas -->
    <table-resume type="Baja" />

    <a-card class="mt-2">
      <a-row type="flex" justify="space-between" align="middle">
        <a-col>
          <a-row class="mt-2">
            <a-typography-title :level="4" class="text-gray-8 mr-5">
              Stock bajas
            </a-typography-title>
            <a-radio-group :value="tickets_type_selection" @change="onChangeTabs">
              <a-radio-button style="margin: 1px; z-index: 0" value="individual">Individuales</a-radio-button>
              <a-radio-button value="masiva">Masivas</a-radio-button>
            </a-radio-group>
          </a-row>
        </a-col>

        <a-range-picker v-if="tickets_type_selection === 'individual'" v-model:value="dante_range_individual"
          :value-format="date_format" :format="[date_format, date_format]" />

        <a-range-picker v-if="tickets_type_selection === 'masiva'" v-model:value="dante_range_masive"
          :value-format="date_format" :format="[date_format, date_format]" />
      </a-row>
      <a-row class="mt-3">
        <a-col :span="24">
          <!-- Baja individual -->
          <tickets-table v-if="tickets_type_selection === 'individual'" type="Baja" :dates="dante_range_individual" />

          <!-- Baja masiva -->
          <masive-table v-else :dates="dante_range_masive" type="Baja" />
        </a-col>
      </a-row>
    </a-card>

    <!-- Modal -->
    <a-modal v-model:visible="modal_visible" title="Nueva solicitud de baja" centered :maskClosable="false">
      <template #footer>
        <a-row type="flex" justify="end">
          <a-col>
            <a-space>
              <router-link class="justify-content-center d-flex" :to="{ name: 'BajaIndividual' }">
                <a-button key="1" type="primary">Individual </a-button>
              </router-link>
              <router-link class="justify-content-center d-flex" :to="{ name: 'BajaMasiva' }">
                <a-button key="2" type="primary">Masiva </a-button>
              </router-link>
            </a-space>
          </a-col>
        </a-row>
      </template>
      <a-typography-title class="text-gray-9 fw-regular" :level="3">¿Que tipo de solicitud de baja deseas
        crear?</a-typography-title>
    </a-modal>
  </div>
  <router-view :key="$route.path"></router-view>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, defineAsyncComponent, onMounted } from 'vue';
import PageHeader from '@/components/PageHeader.vue';
// import DataTable from '../../../components/DataTableTickets.vue';

// const DataTableMasive = defineAsyncComponent(()=>import('../../../components/DataTableMasive.vue'));
const MasiveTable = defineAsyncComponent(() =>
  import('@/components/Tables/MasiveTableTest.vue')
);
const TicketsTable = defineAsyncComponent(() =>
  import('@/components/Tables/TicketsTableTest.vue')
);

import TableResume from '@/components/TableResume.vue';

export default {
  name: 'Bajas',
  components: {
    TableResume,
    'page-header': PageHeader,
    'tickets-table': TicketsTable,
    'masive-table': MasiveTable
  },

  setup() {
    // * Route y router
    const route = useRoute();
    const routes = useRouter();

    // * Page Header
    const routes_header = [
      {
        // path: "first",
        breadcrumbName: 'Bajas'
      }
    ];

    // * Modal
    const modal_visible = ref(false);

    const showModal = () => {
      modal_visible.value = true;
    };

    // * Radio group
    const tickets_type_selection = ref('');
    tickets_type_selection.value = route.hash === '' ? 'individual' : 'masiva';

    const individual_routes = ref({});
    const masive_routes = ref({});

    const onChangeTabs = async (value) => {
      const tab_value = value.target.value;

      if (tab_value === 'masiva') {
        // Guardo los filtros que hice en individual
        individual_routes.value = route.query;
        // Redireccion
        await routes.replace({
          name: 'Bajas',
          query: masive_routes.value,
          hash: '#masive'
        });
      } else {
        // Guardo los filtros que hice en individual
        masive_routes.value = route.query;
        // Redireccion
        await routes.replace({
          name: 'Bajas',
          query: individual_routes.value
        });
      }

      tickets_type_selection.value = tab_value;
    };

    // * Selector de fechas
    const date_format = 'YYYY-MM-DD';

    const dante_range_individual = ref([]);
    const dante_range_masive = ref([]);
    // Cuando cargue la pagina, obtener la fecha y pasarla en el selector
    onMounted(() => {
      if (
        route.query.startDate != undefined &&
        route.query.endDate != undefined
      ) {
        if (route.hash == '#masive') {
          dante_range_masive.value.push(route.query.startDate);
          dante_range_masive.value.push(route.query.endDate);
        } else {
          dante_range_individual.value.push(route.query.startDate);
          dante_range_individual.value.push(route.query.endDate);
        }
      }
    });

    return {
      routes_header,
      modal_visible,
      tickets_type_selection,
      onChangeTabs,
      showModal,
      date_format,
      dante_range_individual,
      dante_range_masive
    };
  }
};
</script>
