<template>
  <div>
    <collapsable-table :data="data" :title="title">
      <template #no-data>
        <a-typography-text>
          No se han registrado procesos asociados
        </a-typography-text>
      </template>
    </collapsable-table>
  </div>
</template>

<script>
import CollapsableTable from '@/components/CollapsableTable.vue';

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: []
    }
  },
  components: {
    'collapsable-table': CollapsableTable
  },
  setup() {
    return {};
  }
};
</script>
