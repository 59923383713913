<template>
  <div>
    <reprocess-detail
      :breadcrumRoutes="breadcrumRoutes"
      header-title="Reproceso por Inconsistencia"
      :reprocess-id="reprocess_id"
    />
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import ReprocessDetailTemplate from '@/components/collection and billing/reprocesses and requests/reprocess/ReprocessDetailTemplate.vue';

export default {
  name: 'InconsistencyDetail',
  components: {
    'reprocess-detail': ReprocessDetailTemplate
  },
  setup() {
    const breadcrumRoutes = [
      {
        breadcrumbName: 'Reprocesos / Inconsistencia'
      }
    ];
    const route = useRoute();
    const reprocess_id = route.params.id;

    return {
      breadcrumRoutes,
      reprocess_id
    };
  }
};
</script>

<style></style>
