<template>
  <a-modal v-model:visible="visibility" title="Pólizas y coberturas">
    <a-radio-group v-model:value="value">
      <a-radio-button value="vigente">Vigentes</a-radio-button>
      <a-radio-button value="no_vigente">No vigentes</a-radio-button>
    </a-radio-group>
    <a-table
      :columns="policy_columns"
      :data-source="
        value === 'vigente' ? currentCoverages : notCurrentCoverages
      "
      :loading="loading"
      class="mt-3"
    >
    </a-table>
    <template #footer>
      <a-button type="primary" @click="closeModal">Ok</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, computed, defineExpose, defineProps, defineEmits } from 'vue';

const props = defineProps([
  'modelValue',
  'currentCoverages',
  'notCurrentCoverages',
  'loading'
]);
const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

const visibility = ref(false);

const policy_columns = [
  {
    title: 'Id póliza',
    dataIndex: 'id'
  },
  {
    title: 'N° póliza',
    dataIndex: 'policy_number'
  },
  {
    title: 'Cobertura',
    dataIndex: 'coverage'
  }
];

const openModal = () => {
  visibility.value = true;
};

const closeModal = () => {
  visibility.value = false;
};

defineExpose({ openModal });
</script>
