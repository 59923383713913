<template>
  <a-row justify="center">
    <a-col :span="12" class="bg-gray-2 img-column">
      <a-row class="p-5" type="flex" justify="center" align="middle">
        <a-col class="flex-column d-flex" :span="18">
          <a-col :span="24">
            <a-row type="flex" justify="center" align="middle">
              <a-col>
                <img
                  class="my-5"
                  src="@/assets/images/new-identity/logoBewell.svg"
                  height="40"
                  alt=""
                />
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="24">
            <a-typography-title class="text-center text-blue-10"
              >Experiencia digital <br />
              en
              <a-typography-text class="text-color-secondary"
                >salud y beneficios</a-typography-text
              >
            </a-typography-title>
          </a-col>
          <a-col :span="24">
            <a-row justify="center" class="mt-5">
              <a-col :lg="20" :xl="16">
                <img
                  class="img-fluid"
                  src="@/assets/images/new-identity/reset-image.png"
                  alt=""
                />
              </a-col>
            </a-row>
          </a-col>
        </a-col>
      </a-row>
    </a-col>
    <a-col :xs="24" :lg="12" class="bg-gray-1">
      <a-row class="" type="flex" justify="center" align="middle">
        <a-col class="flex-column d-flex" :span="12">
          <!-- Load reset views -->
          <a-row class="vh-100 flex-column" type="flex">
            <a-col :span="20" class="d-flex flex-column justify-content-center">
              <router-view />
            </a-col>
            <a-col>
              <a-typography-paragraph class="fs-xs text-center my-5">
                <a-typography-text strong>© 2024, bewell+.</a-typography-text>
                Todos los derechos reservados
              </a-typography-paragraph>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'ResetStructure'
};
</script>
