<template>
  <div v-if="$route.name == 'Inconsistencia'">
    <page-header
      :breadcrumRoutes="[
        {
          breadcrumbName: 'Inconsistencia'
        }
      ]"
      title="Reprocesos por inconsistencia"
    >
    </page-header>

    <inconsistency-table
      title="Stock Inconsistencia"
      :data="table_data"
      :pagination="pagination_props"
      :loading="loading"
      route-name="Inconsistencia"
    >
      <template #actionDetails="slotProps">
        <a-tooltip color="#05045D">
          <template #title>Ver más</template>
          <router-link
            :to="{
              name: 'InconsistencyDetail',
              params: { id: slotProps.record.id }
            }"
          >
            <eye-outlined />
          </router-link>
        </a-tooltip>
      </template>
    </inconsistency-table>
  </div>
  <router-view :key="$route.path"></router-view>
</template>

<script>
import { EyeOutlined } from '@ant-design/icons-vue';
import { ref, watchEffect, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import PageHeader from '@/components/PageHeader.vue';
import CollectionAndBillingStockTable from '@/components/Tables/CollectionAndBillingStockTable.vue';
import {
  FETCH_REPROCESS_TABLE_DATA_BY_TYPE,
  GET_ROWS_TABLE,
  GET_TOTAL_PAGES_TABLE,
  GET_ITEMS_PER_PAGE_TABLE,
  GET_CURRENT_PAGE_TABLE
} from '@/store/types';

export default {
  name: 'Inconsistencia',
  components: {
    'page-header': PageHeader,
    'inconsistency-table': CollectionAndBillingStockTable,
    'eye-outlined': EyeOutlined
  },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const route = useRoute();

    // * Tabla
    const table_data = computed(() => store.getters[GET_ROWS_TABLE]);
    const pagination_props = computed(() => {
      return {
        total: store.getters[GET_TOTAL_PAGES_TABLE],
        pageSize: store.getters[GET_ITEMS_PER_PAGE_TABLE],
        current: store.getters[GET_CURRENT_PAGE_TABLE]
      };
    });

    watchEffect(async (onCleanUp) => {
      let plain_params = route.fullPath.split('?')[1];

      if (plain_params != undefined) {
        const params = plain_params.split('&');
        const page_param_found = params.some((param) => param.includes('page'));
        if (page_param_found === false) {
          params.push('page=1');
        }
        plain_params = `&${params.join('&')}`;
      } else {
        plain_params = '&page=1';
      }

      try {
        loading.value = true;
        const ac = new AbortController();
        const signal = ac.signal;
        onCleanUp(() => ac.abort());
        await store.dispatch(FETCH_REPROCESS_TABLE_DATA_BY_TYPE, {
          payload: `type=REPROCESS_FOR_INCONSISTENCY${plain_params}`,
          signal
        });
      } catch (error) {
        console.error(error);
      }
      loading.value = false;
    });

    return {
      table_data,
      pagination_props,
      loading
    };
  }
};
</script>
