<template>
  <a-card class="mb-2">
    <a-row>
      <a-col :span="24">
        <a-steps
          :current="propStepperData.current"
          :status="propStepperData.state"
        >
          <template v-for="(step, index) in propStepperData.steps" :key="index">
            <a-step :title="step.title" :description="step.date" />
          </template>
        </a-steps>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    stepperData: {
      type: Object,
      default: {}
    }
  },
  setup(props) {
    const propStepperData = computed(() => props.stepperData);
    return {
      propStepperData
    };
  }
};
</script>

<style></style>
