<template>
  <div>
    <page-header :breadcrumRoutes="[
      {
        breadcrumbName: 'Cobranza y facturación'
      }
    ]" title="Formulario de inicio de cobranza">
      <template #tags>
        <a-tag color="purple">{{ monthString }}</a-tag>
      </template>
    </page-header>

    <a-card style="height: 82px">
      <a-row class="py-1 my-0.9">
        <a-typography-text class="fw-semibold text-gray-8 mr-3">
          Selecciona el tipo formulario de cobranza:
        </a-typography-text>
        <a-radio-group name="collection_form_type_group" v-model:value="collection_form_type">
          <a-radio value="holding" class="mr-5">Holding</a-radio>
          <a-radio value="subholding" class="mr-5">Subholding</a-radio>
          <a-radio value="company">Empresa</a-radio>
        </a-radio-group>
      </a-row>
    </a-card>

    <a-form layout="vertical" :model="form_state" ref="form_ref">
      <a-card class="mt-3">
        <a-row type="flex">
          <a-col flex="auto">
            <a-typography-title :level="3">
              Datos de cobranza
            </a-typography-title>
            <p>Completa los siguientes datos y crea el formulario</p>
          </a-col>
          <a-col flex="10px"></a-col>
          <a-col flex="auto">
            <a-row class="justify-content-end">
              <a-button v-if="collection_form_type === 'subholding' ||
                collection_form_type === 'company'
                " size="large" class="button_with_icon" @click="addRow">
                <template #icon>
                  <plus-circle-outlined />
                </template>
                Agregar aseguradora
              </a-button>
            </a-row>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item :label="first_selector_props.label" ref="company" name="company" has-feedback :rules="{
              required: true,
              message: first_selector_props.message,
              trigger: 'change',
              type: 'number'
            }">
              <a-select v-model:value="form_state.company" :options="first_selector_props.options" allow-clear show-search
                :filter-option="filterOption">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Aseguradora" ref="insurer" name="insurer" has-feedback :rules="{
              required: true,
              message: 'Seleccione una aseguradora',
              trigger: 'change',
              type: 'number'
            }">
              <a-select v-model:value="form_state.insurer" :options="insurance_list" allow-clear show-search
                :loading="insurance_selector_props.loading" :disabled="insurance_selector_props.disabled"
                :filter-option="filterOption">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Formato cuadratura" ref="format" name="format" has-feedback :rules="{
              required: true,
              message: 'Seleccione un formato',
              trigger: 'change',
              type: 'number'
            }">
              <a-select v-model:value="form_state.format" :options="format_list" :loading="format_selector_props.loading"
                :disabled="format_selector_props.disabled" allow-clear show-search :filter-option="filterOption">
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <template v-for="(row, index) in form_state.extra" :key="index">
            <a-col :span="8" :offset="8">
              <a-form-item label="Aseguradora" :name="['extra', index, 'insurer']" :rules="{
                required: true,
                message: 'Seleccione una aseguradora',
                trigger: 'change',
                type: 'number'
              }" has-feedback>
                <a-select v-model:value="row.insurer" :options="insurance_list" allow-clear show-search
                  @select="getExtraInsuranceCompanyFormats" :filter-option="filterOption">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="Formato" :name="['extra', index, 'format']" :rules="{
                required: true,
                message: 'Seleccione un formato',
                trigger: 'change',
                type: 'number'
              }" has-feedback>
                <a-select v-model:value="row.format" :options="extra_format_data[row.insurer]" allow-clear show-search
                  :disabled="row.insurer === '' ||
                    row.insurer === undefined ||
                    extra_format_data[row.insurer] === undefined
                    " :filter-option="filterOption">
                </a-select>
              </a-form-item>

              <span style="
                  justify-content: flex-end;
                  list-style: none;
                  display: flex;
                " class="mr-2">
                <p class="delete-holding-text ml-5" @click="deleteRow(index)">
                  <delete-outlined />
                  Eliminar
                </p>
              </span>
            </a-col>
          </template>
        </a-row>
      </a-card>

      <a-form-item class="mt-2">
        <a-row type="flex" class="justify-content-end">
          <a-col>
            <a-button size="large" class="px-4" type="primary" @click="formSubmit" block>Crear formulario
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>

    <a-modal v-model:visible="modal_props.visibility" :title="modal_props.title">
      <div v-if="modal_props.mode === 'success'">
        <a-result status="success" title="Formulario creado"
          sub-title="Tu formulario de cobranza ha sido creado con éxito.">
        </a-result>
        <a-button style="margin: 0 auto; display: block" type="primary" @click="detailsRedirection">
          Ver formulario
        </a-button>
        <a-alert v-if="modal_props.observations && modal_props.observations.length > 0" class="mt-4" message="Importante"
          :description="modal_props.observationsDesc" type="warning" show-icon />
      </div>
      <div v-else-if="modal_props.mode === 'detail'">
        <b>Datos de cobranza</b>
        <p>Revisa que los datos ingresados esten correctos</p>
        <a-descriptions class="mb-4" bordered>
          <a-descriptions-item :label="first_selector_props.label" :span="3">
            {{ table_resume.billing_type }}
          </a-descriptions-item>
          <a-descriptions-item label="Periodo cobranza" :span="3">
            {{ table_resume.period }}
          </a-descriptions-item>
          <a-descriptions-item label="Aseguradoras" :span="3">
            {{ table_resume.insurers.join(' - ') }}
          </a-descriptions-item>
        </a-descriptions>
      </div>

      <template #footer>
        <div v-if="modal_props.mode === 'detail'">
          <a-button :disabled="modal_props.loading" @click="cancelForm">Cancelar</a-button>
          <a-button type="primary" @click="finishForm" :loading="modal_props.loading">Crear</a-button>
        </div>
        <div v-else></div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import {
  FETCH_ALL_HOLDINGS,
  FETCH_SUBHOLDINGS,
  FETCH_COMPANIES,
  FETCH_INSURANCES_COMPANY_BY_HOLDING_ID,
  FETCH_INSURANCES_COMPANY_BY_SUBHOLDING_ID,
  FETCH_INSURANCES_COMPANY_BY_COMPANY_ID,
  FETCH_INSURANCES_COMPANY_PAYROLL_BY_ID,
  GET_HOLDINGS,
  GET_COMPANIES,
  GET_SUBHOLDINGS,
  GET_INSURANCES,
  GET_INSURANCES_COMPANY_PAYROLL_BY_ID,
  ACTION_SET_INSURANCES,
  POST_COLLECTION_FORM
} from '@/store/types';
import notification from '@/utils/notifications';
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'CollectionBillingFormCreation',
  components: {
    'page-header': PageHeader,
    'plus-circle-outlined': PlusCircleOutlined,
    'delete-outlined': DeleteOutlined
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const date_query = route.query.fecha;
    const collection_form_type = ref('holding');
    const form_ref = ref();
    const form_state = reactive({
      company: '',
      insurer: '',
      format: '',
      extra: []
    });
    const insurance_selector_props = reactive({
      loading: false,
      disabled: true
    });
    const format_selector_props = reactive({
      loading: false,
      disabled: true
    });
    const extra_format_data = reactive({});
    const modal_props = reactive({
      visibility: false,
      title: '',
      mode: '',
      loading: false
    });
    const table_resume = reactive({
      billing_type: '',
      period: '',
      insurers: []
    });
    const resp_id = ref('');

    const validateDateParamOnUrl = () => {
      if (date_query === undefined) {
        router.push({ name: 'CobranzaFacturacion' });
      }
    };

    const monthString = computed(() => {
      const date_split = date_query?.split('-');
      const month = date_split[0];

      const month_values = {
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre'
      };

      return month_values[parseInt(month)];
    });

    const first_selector_props = computed(() => {
      switch (collection_form_type.value) {
        case 'holding':
          return {
            label: 'Holding',
            message: 'Seleccione un holding',
            options: holding_list.value
          };
        case 'subholding':
          return {
            label: 'Subholding',
            message: 'Seleccione un subholding',
            options: subholding_list.value
          };
        default:
          return {
            label: 'Empresa',
            message: 'Seleccione una empresa',
            options: company_list.value
          };
      }
    });

    const emptyFormState = () => {
      form_ref.value.resetFields();
    };

    const emptyFormStateSpecificProp = (prop) => {
      form_state[prop] = '';
    };

    const emptySelectors = () => {
      insurance_list.value = [];
      format_list.value = [];
    };

    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const holding_list = computed(() =>
      store.getters[GET_HOLDINGS]?.map((holding) => {
        return { label: holding.businessName, value: parseInt(holding.id) };
      })
    );
    const getHoldingList = () => {
      store
        .dispatch(FETCH_ALL_HOLDINGS)
        .catch((error) =>
          notification('error', 'Error', error.response.data.message)
        );
    };

    const subholding_list = computed(() =>
      store.getters[GET_SUBHOLDINGS]?.map((subholding) => {
        return { label: subholding.name, value: parseInt(subholding.id) };
      })
    );
    const getSubholdingList = () => {
      store
        .dispatch(FETCH_SUBHOLDINGS)
        .catch((error) =>
          notification('error', 'Error', error.response.data.message)
        );
    };

    const company_list = computed(() =>
      store.getters[GET_COMPANIES]?.map((company) => {
        return { label: company.businessName, value: parseInt(company.id) };
      })
    );
    const getCompanyList = () => {
      store
        .dispatch(FETCH_COMPANIES)
        .catch((error) =>
          notification('error', 'Error', error.response.data.message)
        );
    };

    const insurance_list = computed({
      get() {
        return store.getters[GET_INSURANCES].map((insurance) => {
          return {
            label: insurance.name,
            value: insurance.id,
            disabled: false
          };
        });
      },
      set(value) {
        store.dispatch(ACTION_SET_INSURANCES, value);
      }
    });

    const getInsuranceCompaniesByTypeAndId = async (company_id) => {
      insurance_selector_props.loading = true;
      insurance_selector_props.disabled = true;
      const typos = {
        holding: FETCH_INSURANCES_COMPANY_BY_HOLDING_ID,
        subholding: FETCH_INSURANCES_COMPANY_BY_SUBHOLDING_ID,
        company: FETCH_INSURANCES_COMPANY_BY_COMPANY_ID
      };
      try {
        await store.dispatch(typos[collection_form_type.value], company_id);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      insurance_selector_props.loading = false;
      insurance_selector_props.disabled = false;
    };

    const format_list = ref([]);
    const getFormatsByInsuranceId = async (insurance_id) => {
      format_selector_props.loading = true;
      format_selector_props.disabled = true;
      let formats = [];
      try {
        await store.dispatch(
          FETCH_INSURANCES_COMPANY_PAYROLL_BY_ID,
          insurance_id
        );
        formats = store.getters[GET_INSURANCES_COMPANY_PAYROLL_BY_ID].map(
          (format) => {
            return {
              label: format.name,
              value: format.id
            };
          }
        );
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      format_selector_props.loading = false;
      format_selector_props.disabled = false;
      return formats;
    };

    const blockSelectorByCondition = () => {
      if (form_state.company === '' || form_state.company === undefined) {
        insurance_selector_props.disabled = true;
        format_selector_props.disabled = true;
      } else if (
        form_state.insurer === '' ||
        form_state.insurer === undefined
      ) {
        format_selector_props.disabled = true;
      }
    };

    const addRow = () => {
      if (form_state.extra.length < insurance_list.value.length - 1) {
        form_state.extra.push({
          insurer: '',
          format: ''
        });
      }
    };

    const disableOptionInInsuranceList = () => {
      console.log('en disableOptionInInsuranceList');
      insurance_list.value.forEach((insurance) => (insurance.disabled = false));

      if (form_state.insurer != undefined) {
        insurance_list.value.find(
          (insurance) => insurance.value === form_state.insurer
        ).disabled = true;
      }

      form_state.extra.forEach((obj) => {
        if (obj.insurer != undefined) {
          insurance_list.value.find(
            (insurance) => insurance.value === obj.insurer
          ).disabled = true;
        }
      });
    };

    const getExtraInsuranceCompanyFormats = async (id) => {
      form_state.extra.find((ext) => ext.insurer === id).format = '';
      try {
        extra_format_data[id] = await getFormatsByInsuranceId(id);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const deleteFormatValueFromExtraInsurerDiselected = () => {
      form_state.extra.forEach((extra) => {
        if (extra.insurer === undefined || extra.insurer === '') {
          extra.format = '';
        }
      });
    };

    const deleteRow = (id) => {
      form_state.extra.splice(id, 1);
    };

    const openModal = (title, mode, observations) => {
      modal_props.visibility = true;
      modal_props.title = title;
      modal_props.mode = mode;
      if (observations) {
        modal_props.observations = observations;
        modal_props.observationsDesc = observations
          .map((i) => ({
            name: i.holdingName || i.subHoldingName || i.companyName,
            insuranceName: i.insuranceCompanyName,
            period: `${i.periodYear}-${i.periodMonth
              .toString()
              .padStart(2, '0')}`
          }))
          .map(
            (i) =>
              `Ya existe un formulario para ${i.name} con la aseguradora ${i.insuranceName} para el periodo ${i.period}.`
          )
          .join('\n');
      }
    };

    const closeModal = () => {
      modal_props.visibility = false;
    };

    const openSucessModal = (observations) => {
      closeModal();
      setTimeout(() => {
        form_ref.value.resetFields();
        form_state.extra.length = 0;
        openModal('Ticket de formulario', 'success', observations);
      }, 500);
    };

    const cancelForm = () => {
      form_ref.value.resetFields();
      modal_props.visibility = false;
    };

    const detailsRedirection = () => {
      router.push({
        name: 'DetalleCobranzaFacturacion',
        params: { id: resp_id.value }
      });
    };

    const formSubmit = () => {
      form_ref.value.validate().then(() => {
        const { company, extra } = form_state;

        table_resume.billing_type = first_selector_props.value.options.find(
          (comp) => comp.value === company
        ).label;
        table_resume.period = date_query;
        table_resume.insurers = [];
        table_resume.insurers.push(
          insurance_list.value.find((obj) => obj.value === form_state.insurer)
            .label
        );

        if (collection_form_type.value != 'holding') {
          extra.forEach((obj) => {
            const found = insurance_list.value.find(
              (insurance) => insurance.value === obj.insurer
            ).label;
            table_resume.insurers.push(found);
          });
        }

        openModal('Resumen de formulario de cobranza', 'detail');
      });
    };

    const finishForm = async () => {
      const { company, format, insurer, extra } = form_state;
      const date = date_query.split('-');
      const periodMonth = parseInt(date[0]);
      const periodYear = parseInt(date[1]);

      const insuranceCompanies = [
        {
          insuranceCompanyId: insurer,
          payrollFormatId: format
        },
        ...extra.map((insurance) => {
          return {
            insuranceCompanyId: insurance.insurer,
            payrollFormatId: insurance.format
          };
        })
      ];

      const payload = {
        periodMonth,
        periodYear,
        holdingId: collection_form_type.value === 'holding' ? company : null,
        subHoldingId:
          collection_form_type.value === 'subholding' ? company : null,
        companyId: collection_form_type.value === 'company' ? company : null,
        insuranceCompanies
      };

      modal_props.loading = true;

      try {
        const resp = await store.dispatch(POST_COLLECTION_FORM, payload);
        const observations = resp?.data?.observations;
        resp_id.value = resp.data.id;
        openSucessModal(observations);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }

      modal_props.loading = false;
    };

    watch(collection_form_type, () => {
      emptyFormState();
      emptySelectors();
      blockSelectorByCondition();
      form_state.extra.length = 0;
    });

    watch(
      () => form_state.company,
      (company_id) => {
        if (company_id != '' && company_id != undefined) {
          getInsuranceCompaniesByTypeAndId(company_id);
        }
        emptyFormStateSpecificProp('insurer');
        emptyFormStateSpecificProp('format');
      }
    );

    watch(
      () => form_state.insurer,
      async (insurance_id) => {
        if (insurance_id != '' && insurance_id != undefined) {
          format_list.value = await getFormatsByInsuranceId(insurance_id);
        }
        emptyFormStateSpecificProp('format');
        disableOptionInInsuranceList();
      }
    );

    watch(
      () => form_state.extra,
      () => {
        deleteFormatValueFromExtraInsurerDiselected();
        disableOptionInInsuranceList();
      },
      { deep: true }
    );

    watch(form_state, () => {
      blockSelectorByCondition();
    });

    onMounted(() => {
      validateDateParamOnUrl();

      getHoldingList();
      getSubholdingList();
      getCompanyList();
    });

    return {
      subholding_list,
      company_list,
      monthString,
      collection_form_type,
      first_selector_props,
      filterOption,
      form_state,
      form_ref,
      insurance_list,
      format_list,
      formSubmit,
      insurance_selector_props,
      format_selector_props,
      addRow,
      getExtraInsuranceCompanyFormats,
      extra_format_data,
      deleteRow,
      modal_props,
      table_resume,
      cancelForm,
      finishForm,
      detailsRedirection
    };
  }
};
</script>
