<template>
  <div>
    <a-table v-bind="table_props" @change="changeCurrentPage">
      <template #idRender="{ text }">
        <p># {{ text }}</p>
      </template>

      <template #filterDropdown="{ confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-form name="form" layout="vertical" ref="form_ref" :model="filter_values" :rules="rules">
            <a-form-item has-feedback :name="column.dataIndex">
              <a-input v-model:value="filter_values[column.key]" :placeholder="`Buscar ${column.title}`"
                @keyup="formatRuts" @pressEnter="
                  handleSearch(
                    column.dataIndex,
                    filter_values[column.key],
                    confirm
                  )
                  " style="width: 188px; margin-bottom: 8px; display: block" />
            </a-form-item>

            <a-button type="primary" size="small" style="width: 90px; margin-right: 8px" @click="
              handleSearch(
                column.dataIndex,
                filter_values[column.key],
                confirm
              )
              ">
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>
            <a-button size="small" style="width: 90px" @click="handleReset(column.dataIndex, clearFilters)">
              Limpiar
            </a-button>
          </a-form>
        </div>
      </template>

      <template #filterIcon="{ column }">
        <search-outlined :style="{
          color:
            query_values[column.dataIndex] === undefined ||
              query_values[column.dataIndex] === ''
              ? undefined
              : '#2521E5'
        }" />
      </template>

      <template #actionRender="{ text, record }">
        <span style="display: flex">
          <a-tooltip color="#05045D" :title="`Ver más`">
            <a-button type="link" @click="companyRedirect(text)" class="edit-holding-text">
              <eye-outlined class="blue-icon" />
            </a-button>
          </a-tooltip>
          <a-tooltip color="#05045D" :title="`Editar`">
            <a-button type="link" @click="editCompany(record.id)" class="edit-holding-text">
              <edit-outlined class="blue-icon" />
            </a-button>
          </a-tooltip>
          <a-tooltip color="#05045D" :title="`Eliminar`">
            <a-button type="link" @click="companyDelete(record)">
              <delete-outlined class="delete-holding-text" />
            </a-button>
          </a-tooltip>
        </span>
      </template>
    </a-table>

    <company-modal ref="modalRef" @companyCreated="companyCreated" @updateCompany="updateCompany"
      @deletedCompany="deletedCompany" />
  </div>
</template>

<script>
import {
  SearchOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue';
import { onMounted, reactive, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  FETCH_COMPANY_DATA_TABLE,
  GET_COMPANY_DATA_TABLE
} from '@/store/types';
import rutFormatting from '@/utils/formatRut';
import CompanyModal from '@/components/clients/CompanyModal.vue';
import rutHelper from '@/utils/Ruts';

export default {
  name: 'Empresas',
  components: {
    'search-outlined': SearchOutlined,
    'company-modal': CompanyModal,
    'eye-outlined': EyeOutlined,
    'edit-outlined': EditOutlined,
    'delete-outlined': DeleteOutlined
  },
  setup() {
    // * Variables globales
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // * Tabla
    const filter_values = ref({});
    const query_values = ref({});

    const table_props = reactive({
      dataSource: [],
      columns: [
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
          slots: {
            customRender: 'idRender'
          }
        },
        {
          title: 'Holding',
          dataIndex: 'holdingName',
          key: 'holdingName',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon'
          }
        },
        {
          title: 'RUT Holding',
          dataIndex: 'rutHolding',
          key: 'rutHolding',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon'
          }
        },
        {
          title: 'Subholding',
          dataIndex: 'subHoldingName',
          key: 'subHoldingName',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon'
          }
        },
        {
          title: 'Empresa',
          dataIndex: 'companyName',
          key: 'companyName',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon'
          }
        },
        {
          title: 'RUT Empresa',
          dataIndex: 'rutCompany',
          key: 'rutCompany',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon'
          }
        },
        {
          title: 'Acción',
          dataIndex: 'action',
          key: 'action',
          align: 'center',
          width: 150,
          slots: {
            customRender: 'actionRender'
          }
        }
      ],
      loading: false,
      scroll: { x: 700 },
      pagination: {
        total: 0,
        pageSize: 0,
        current: 0
      }
    });

    const filterRedirectionNewParam = (key, param) => {
      query_values.value[key] = param;

      if (key != 'page') {
        query_values.value['page'] = 1;
      }

      router.replace({
        name: 'Cliente',
        hash: '#empresas',
        query: query_values.value
      });
    };

    const filterRedirectionResetColumn = (key) => {
      delete query_values.value[key];

      router.replace({
        name: 'Cliente',
        hash: '#empresas',
        query: query_values.value
      });
    };

    const handleSearch = (column, value, confirm) => {
      if (value != '') {
        form_ref.value.validate().then(() => {
          filterRedirectionNewParam(column, value);
          confirm();
        });
      }
    };

    const handleReset = (column, clearFilters) => {
      filterRedirectionResetColumn(column);
      delete filter_values.value[column];
      clearFilters();
      form_ref.value.clearValidate();
    };

    const changeCurrentPage = (page) => {
      filterRedirectionNewParam('page', page.current);
    };

    // * Form
    const form_ref = ref();
    const checkRut = async (rule, value) => {
      if (!value) {
        return;
      }
      if (!rutHelper.validateRut(value)) {
        return Promise.reject('RUT no válido');
      }
      return Promise.resolve();
    };
    const rules = {
      rutHolding: [
        {
          required: false,
          validator: checkRut,
          trigger: 'change'
        }
      ],
      rutCompany: [
        {
          required: false,
          validator: checkRut,
          trigger: 'change'
        }
      ]
    };

    const formatRuts = () => {
      if (filter_values.value['rutHolding']) {
        filter_values.value['rutHolding'] = rutHelper.formatRut(
          filter_values.value['rutHolding']
        );
      }
      if (filter_values.value['rutCompany']) {
        filter_values.value['rutCompany'] = rutHelper.formatRut(
          filter_values.value['rutCompany']
        );
      }
    };

    // * Funciones
    const getHoldingData = async () => {
      console.log('Empresas ####!!!!!');

      table_props.loading = true;
      let queryParams = route.fullPath.split('?')[1] || 'page=1';
      queryParams = `?${queryParams}`;

      try {
        const resp = await store.dispatch(
          FETCH_COMPANY_DATA_TABLE,
          queryParams
        );
        if (resp.status === 200) {
          const companies = store.getters[GET_COMPANY_DATA_TABLE];

          table_props.dataSource = companies.items?.map((obj) => {
            return {
              key: obj.id,
              id: obj.id,
              holdingName: obj.holding ? obj.holding.businessName : '',
              rutHolding: obj.holding ? rutFormatting(obj.holding.rut) : '',
              subHoldingName: obj.subHolding?.name,
              companyName: obj.businessName,
              rutCompany: rutFormatting(obj.rut),
              action: obj.id
            };
          });

          table_props.pagination.total = companies.totalItems;
          table_props.pagination.pageSize = 10;
          table_props.pagination.current = companies.page;
        }
      } catch (error) {
        console.error(error);
      }
      table_props.loading = false;
    };

    watchEffect(() => {
      getHoldingData();
    });

    onMounted(() => {
      const key_params = Object.keys(route.query);
      key_params.forEach((key) => {
        filter_values.value[key] = route.query[key];
        query_values.value[key] = route.query[key];
      });
    });

    // * Modal
    const modalRef = ref();

    const createCompany = () => {
      modalRef.value.createCompany();
    };

    const editCompany = (value) => {
      modalRef.value.editCompany(value);
    };

    const companyDelete = (value) => {
      modalRef.value.deleteCompany(value);
    };

    const companyRedirect = (id) => {
      router.push({ name: 'Grupos', params: { id } });
    };

    const companyCreated = () => {
      getHoldingData();
    };

    const updateCompany = () => {
      getHoldingData();
    };

    const deletedCompany = () => {
      getHoldingData();
    };

    return {
      table_props,
      query_values,
      filter_values,
      modalRef,
      form_ref,
      rules,
      formatRuts,
      checkRut,
      companyDelete,
      editCompany,
      getHoldingData,
      changeCurrentPage,
      handleSearch,
      handleReset,
      createCompany,
      companyCreated,
      companyRedirect,
      updateCompany,
      deletedCompany
    };
  }
};
</script>
