<template>
  <div>
    <a-tabs v-model:activeKey="active_tab_key">
      <a-tab-pane v-for="tab in tabs" :key="tab.key" :tab="tab.title" />
    </a-tabs>
  </div>

  <router-view :key="$route.path"></router-view>
</template>

<script>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'TabsAll',
  setup() {
    // * route y router
    const route = useRoute();
    const router = useRouter();

    // * Tabs
    const active_tab_key = ref('');
    const tabs = [
      {
        title: 'Grupos',
        name: 'Grupos',
        key: '1'
      },
      {
        title: 'Póliza',
        name: 'Poliza',
        key: '2'
      },
      {
        title: 'Stock',
        name: 'Stock',
        key: '3'
      },
      {
        title: 'Usuarios',
        name: 'Usuarios',
        key: '4'
      },
      {
        title: 'Recepción',
        name: 'Recepcion',
        key: '5'
      },
      {
        title: 'Notificaciones',
        name: 'Notificaciones',
        key: '6'
      },
      {
        title: 'Brokers',
        name: 'Brokers',
        key: '7'
      },
      {
        title: 'Emails',
        name: 'Emails',
        key: '8'
      }
    ];

    // coloco la tab activa segun la pagina en la que estoy
    active_tab_key.value = tabs.find(
      (tab) => tab.name === route.matched[3].name
    ).key;

    watch(active_tab_key, async (value) => {
      const obj = {
        name: tabs.find((obj) => obj.key === value).name
      };
      await router.push(obj);
    });

    return {
      active_tab_key,
      tabs
    };
  }
};
</script>
