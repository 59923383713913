// * Fetch
export const FETCH_CLIENT_COMPANY_GROUPS = 'groups/FETCH_CLIENT_COMPANY_GROUPS';
export const FETCH_CLIENT_COMPANY_GROUPS_COVERAGES =
  'groups/FETCH_CLIENT_COMPANY_GROUPS_COVERAGES';
export const FETCH_GROUP_COMPANY_PREMIUM_CALC =
  'groups/FETCH_GROUP_COMPANY_PREMIUM_CALC';

// * Mutation
export const MUTATE_CLIENT_COMPANY_GROUPS =
  'groups/MUTATE_CLIENT_COMPANY_GROUPS';

// * Getters
export const GET_CLIENT_COMPANY_GROUPS = 'groups/GET_CLIENT_COMPANY_GROUPS';

// * Post
export const POST_CLIENT_COMPANY_GROUP = 'groups/POST_CLIENT_COMPANY_GROUP';

// * Delete
export const DELETE_CLIENT_COMPANY_GROUP = 'groups/DELETE_CLIENT_COMPANY_GROUP';

// * Put
export const PUT_CLIENT_COMPANY_GROUP = 'groups/PUT_CLIENT_COMPANY_GROUP';
export const PUT_GROUP_COMPANY_PREMIUM_CALC =
  'groups/PUT_GROUP_COMPANY_PREMIUM_CALC';
