import { fileTicketRecord } from '../../../network';
import {
  GET_RECORD_FILE_TICkET,
  SET_RECORD_FILE_TICKET,
  FETCH_RECORD_FILE_TICKET
} from './types';

const store = {};

const getDefaultState = () => ({
  records: []
});

store.state = getDefaultState();

store.getters = {
  [GET_RECORD_FILE_TICkET](state) {
    return state.records;
  }
};

store.mutations = {
  [SET_RECORD_FILE_TICKET](state, payload) {
    state.records = payload;
  }
};

store.actions = {
  [FETCH_RECORD_FILE_TICKET]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      fileTicketRecord(payload)
        .then((resp) => {
          commit(SET_RECORD_FILE_TICKET, resp.data);
          resolve(resp.data);
        })
        .catch((error) => {
          commit(SET_RECORD_FILE_TICKET, []);
          reject(error);
        });
    });
  }
};
export default store;
