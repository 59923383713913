<template>
  <div>
    <a-skeleton active :loading="loading_data">
      <a-form
        ref="formRef"
        :model="form_state"
        :rules="rules"
        layout="vertical"
      >
        <a-card>
          <a-typography-title :level="4"
            >Declaración simple de salud</a-typography-title
          >
          <p>
            ¿Usted o alguien de su grupo familiar asegurado padece o ha padecido
            alguna enfermedad, situaciones o condiciones de salud o actualmente
            se encuentra sometido a un diagnóstico en estudio para alguna
            patología?
          </p>
          <p>
            IMPORTANTE: Conforme a la Ley N° 21.656, que consagra el derecho al
            olvido oncológico, para responder esta pregunta no considerar
            patologías oncológicas cuando hayan transcurrido cinco años desde la
            finalización del tratamiento radical sin recaída posterior.
          </p>

          <a-row :gutter="32">
            <a-col
              :span="6"
              v-for="(disease, index) in diseases_displays"
              :key="index"
            >
              <ul>
                <li v-for="d in disease" :key="d.value">
                  <p>{{ d.label }}</p>
                </li>
              </ul>
            </a-col>
          </a-row>

          <a-form-item ref="diseaseSelection" name="diseaseSelection">
            <a-radio-group v-model:value="form_state.diseaseSelection">
              <a-radio :value="1">Sí</a-radio>
              <a-radio :value="2">No</a-radio>
            </a-radio-group>
          </a-form-item>

          <div v-if="form_state.diseaseSelection === 1">
            <a-row type="flex" justify="space-between">
              <a-typography-title :level="5">Asegurados</a-typography-title>
              <a-button type="primary" @click="addInsurer">
                Agregar asegurado
                <template #icon>
                  <PlusOutlined />
                </template>
              </a-button>
            </a-row>
            <a-divider />

            <div
              v-for="(insurer, insurerIndex) in form_state.insuredWithDesease"
              :key="insurerIndex"
              class="mb-5"
            >
              <a-row type="flex" justify="space-between">
                <a-form-item
                  :rules="{
                    required: true,
                    message: 'Seleccione una opción',
                    trigger: 'change',
                    type: 'number'
                  }"
                  :name="['insuredWithDesease', insurerIndex, 'insuredId']"
                  label="Seleccionar Asegurado"
                  required
                >
                  <a-select ref="select" v-model:value="insurer.insuredId">
                    <a-select-option
                      v-for="opt in insured_options"
                      :key="opt.value"
                      :value="opt.value"
                      :disabled="
                        form_state.insuredWithDesease.some(
                          (i) => i.insuredId === opt.value
                        )
                      "
                    >
                      {{ opt.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-button class="mt-3" @click="deleteInsurer(insurerIndex)">
                  <template #icon>
                    <DeleteOutlined />
                  </template>
                  Eliminar
                </a-button>
              </a-row>

              <p>Condición</p>

              <div
                v-for="(disease, insDisease) in insurer.diseases"
                :key="insDisease"
              >
                <a-row :gutter="16" type="flex" justify="space-between">
                  <a-col :span="7">
                    <a-form-item
                      label="Enfermedad / Diagnóstico"
                      :validateStatus="disease.diseaseId.formStatus"
                      :help="disease.diseaseId.errorMessage"
                    >
                      <a-select
                        v-model:value="disease.diseaseId.value"
                        :options="diseases_options"
                        @change="
                          deleteErrorMessagesFromInput(
                            insurerIndex,
                            insDisease,
                            'diseaseId'
                          )
                        "
                        placeholder="Seleccionar"
                      >
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="7">
                    <a-form-item
                      label="Descripción de la enfermedad"
                      :validateStatus="disease.diseaseDetail.formStatus"
                      :help="disease.diseaseDetail.errorMessage"
                    >
                      <a-input
                        v-model:value="disease.diseaseDetail.value"
                        placeholder="Ingrese detalle"
                        @change="
                          deleteErrorMessagesFromInput(
                            insurerIndex,
                            insDisease,
                            'diseaseDetail'
                          )
                        "
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="8">
                    <a-form-item
                      name="diagnosisDate"
                      label="Fecha de diagnóstico"
                      :validateStatus="disease.diagnosisDate.formStatus"
                      :help="disease.diagnosisDate.errorMessage"
                    >
                      <a-date-picker
                        :style="{ width: '100%' }"
                        format="DD/MM/YYYY"
                        v-model:value="disease.diagnosisDate.value"
                        placeholder="Ingrese fecha de diagnóstico"
                        @change="
                          deleteErrorMessagesFromInput(
                            insurerIndex,
                            insDisease,
                            'diagnosisDate'
                          )
                        "
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="2">
                    <a-button
                      style="margin-top: 29px"
                      @click="deleteDisease(insurerIndex, insDisease)"
                    >
                      <template #icon>
                        <DeleteOutlined />
                      </template>
                    </a-button>
                  </a-col>
                </a-row>
              </div>

              <a-button @click="addDisease(insurerIndex)">
                <template #icon>
                  <PlusOutlined />
                </template>
                Agregar Condición
              </a-button>
            </div>
          </div>
        </a-card>

        <a-card class="mt-3">
          <a-typography-title :level="4">Deportes</a-typography-title>
          <p>
            ¿Practica usted o alguien de su grupo asegurado algún deporte o
            actividad?
          </p>

          <a-row :gutter="32">
            <a-col
              :span="6"
              v-for="(sports, index) in sports_displays"
              :key="index"
            >
              <ul v-for="s in sports" :key="s.value">
                <li>
                  <p>{{ s.label }}</p>
                </li>
              </ul>
            </a-col>
          </a-row>

          <a-form-item ref="sportsSelection" name="sportsSelection">
            <a-radio-group v-model:value="form_state.sportsSelection">
              <a-radio :value="1">Sí</a-radio>
              <a-radio :value="2">No</a-radio>
            </a-radio-group>
          </a-form-item>

          <div v-if="form_state.sportsSelection === 1">
            <a-row type="flex" justify="space-between">
              <a-typography-title :level="5">Asegurados</a-typography-title>
              <a-button type="primary" @click="addInsurerInSports">
                Agregar asegurado
                <template #icon>
                  <PlusOutlined />
                </template>
              </a-button>
            </a-row>
            <a-divider />

            <div
              v-for="(insurer, insurerIndex) in form_state.sports"
              :key="insurerIndex"
              class="mb-5"
            >
              <a-row type="flex" justify="space-between">
                <a-form-item
                  :rules="{
                    required: true,
                    message: 'Seleccione una opción',
                    trigger: 'change',
                    type: 'number'
                  }"
                  :name="['sports', insurerIndex, 'insuredId']"
                  label="Seleccionar Asegurado"
                  required
                >
                  <a-select ref="select" v-model:value="insurer.insuredId">
                    <a-select-option
                      v-for="opt in insured_options"
                      :key="opt.value"
                      :value="opt.value"
                      :disabled="
                        form_state.sports.some((i) => i.insuredId === opt.value)
                      "
                    >
                      {{ opt.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-button class="mt-3" @click="deleteInsurerOnSports">
                  <template #icon>
                    <DeleteOutlined />
                  </template>
                  Eliminar
                </a-button>
              </a-row>

              <p>Actividad/Deporte</p>

              <div
                v-for="(sport, sportindex) in insurer.options"
                :key="sportindex"
              >
                <a-row :gutter="16" type="flex" justify="space-between">
                  <a-col :span="22">
                    <a-form-item
                      name="sportId"
                      label="Actividad"
                      :validateStatus="sport.formStatus"
                      :help="sport.errorMessage"
                    >
                      <a-select
                        v-model:value="sport.value"
                        placeholder="Actividad deporte"
                        @change="
                          () => selectOnChangeSport(insurerIndex, sportindex)
                        "
                      >
                        <a-select-option
                          v-for="opt in sports_options"
                          :key="opt.value"
                          :value="opt.value"
                          :disabled="
                            insurer.options.some((s) => s.value === opt.value)
                          "
                        >
                          {{ opt.label }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="2">
                    <a-button
                      style="margin-top: 29px"
                      @click="deleteSportId(insurerIndex, sportindex)"
                    >
                      <template #icon>
                        <DeleteOutlined />
                      </template>
                    </a-button>
                  </a-col>
                </a-row>
              </div>

              <a-button @click="addSportId(insurerIndex)">
                <template #icon>
                  <PlusOutlined />
                </template>
                Agregar Actividad
              </a-button>
            </div>
          </div>
        </a-card>

        <a-card class="mt-3">
          <a-typography-title :level="4">Mascotas</a-typography-title>
          <p>¿Usted posee mascotas?</p>

          <a-form-item ref="petsSelection" name="petsSelection">
            <a-radio-group
              v-model:value="form_state.petsSelection"
              name="petsSelection"
            >
              <a-radio :value="1">Sí</a-radio>
              <a-radio :value="2">No</a-radio>
            </a-radio-group>
          </a-form-item>

          <div v-if="form_state.petsSelection === 1">
            <a-form-item ref="pets" name="pets">
              <a-checkbox-group v-model:value="form_state.pets">
                <a-checkbox
                  v-for="pet in pets_options"
                  :key="pet.value"
                  :value="pet.value"
                  >{{ pet.label }}</a-checkbox
                >
              </a-checkbox-group>
            </a-form-item>

            <div v-if="form_state.pets.includes(3)">
              <a-form-item ref="customPet" name="customPet" label="Otro">
                <a-input
                  v-model:value="form_state.customPet"
                  placeholder="Ingrese nombre"
                  style="width: 250px"
                />
              </a-form-item>
            </div>
          </div>
        </a-card>
      </a-form>

      <a-row type="flex" justify="space-between" class="mt-3">
        <a-button size="large" @click="formValidation(4)" :loading="loading">
          <template #icon>
            <ArrowLeftOutlined />
          </template>
          Atrás
        </a-button>
        <a-button
          size="large"
          type="primary"
          @click="formValidation(6)"
          :loading="loading"
          >Siguiente</a-button
        >
      </a-row>
    </a-skeleton>
  </div>
</template>

<script setup>
import {
  ArrowLeftOutlined,
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue';
import { computed, onMounted, reactive, ref, watch, defineExpose } from 'vue';
import { useStore } from 'vuex';
import {
  FETCH_SPORTS,
  FETCH_PETS,
  FETCH_DISEASES,
  FETCH_INSUREDS,
  FETCH_INDUREDS_WITH_DESEASES,
  FETCH_INSURED_WITH_SPORTS,
  FETCH_PETS_DECLARATION,
  PUT_INSUREDS,
  PUT_SPORTS,
  PUT_PETS,
  ACTION_CHANGE_STEP
} from '@/store/types';
import notification from '../../utils/notifications';
import moment from 'moment';

const formRef = ref();
const form_state = reactive({
  diseaseSelection: null,
  sportsSelection: null,
  petsSelection: null,
  insuredWithDesease: [],
  sports: [],
  pets: [],
  customPet: null
});
const rules = {
  diseaseSelection: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  sportsSelection: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  petsSelection: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  customPet: [
    {
      required: true,
      message: 'Ingrese el nombre',
      trigger: 'change',
      type: 'string'
    }
  ]
};
const store = useStore();
const sports_options = ref([]);
const pets_options = ref([]);
const diseases_options = ref([]);
const insured_options = ref([]);
const loading = ref(false);
const loading_data = ref(false);

const splitInCols = (items, nCols) => {
  const columns = [];
  for (let i = 0; i < nCols; i++) {
    columns.push([]);
  }
  const itemsPerCol = Math.ceil(items.length / nCols);
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    columns[Math.floor(i / itemsPerCol)].push(item);
  }
  return columns;
};

const diseases_displays = computed(() => {
  return splitInCols(diseases_options.value, 4);
});

const sports_displays = computed(() => {
  return splitInCols(sports_options.value, 4);
});

watch(
  () => form_state.diseaseSelection,
  (newVal) => {
    if (newVal === 1 && form_state.insuredWithDesease.length === 0) {
      addInsurer();
    }
  }
);

watch(
  () => form_state.sportsSelection,
  (newVal) => {
    if (newVal === 1 && form_state.sports.length === 0) {
      addInsurerInSports();
    }
  }
);

const addSportId = (index) => {
  form_state.sports[index].options.push({
    value: null,
    errorMessage: null,
    formStatus: ''
  });
};

const selectOnChangeSport = (sportIndex, sportIdIndex) => {
  form_state.sports[sportIndex].options[sportIdIndex].formStatus = null;
  form_state.sports[sportIndex].options[sportIdIndex].errorMessage = null;
};

const deleteSportId = (sportIndex, sportIdIndex) => {
  const condition_length = form_state.sports[sportIndex].options.length;

  if (condition_length === 1) {
    form_state.sports[sportIndex].options[sportIdIndex].value = null;
  } else {
    form_state.sports[sportIndex].options.splice(sportIdIndex, 1);
  }
};

const addDisease = (insurerIndex) => {
  form_state.insuredWithDesease[insurerIndex].diseases.push({
    diseaseId: {
      value: null,
      errorMessage: null,
      formStatus: null
    },
    diseaseDetail: {
      value: null,
      errorMessage: null,
      formStatus: null
    },
    diagnosisDate: {
      value: null,
      errorMessage: null,
      formStatus: null
    }
  });
};

const deleteDisease = (insurerIndex, diseaseIndex) => {
  if (form_state.insuredWithDesease[insurerIndex].diseases.length === 1) {
    form_state.insuredWithDesease[insurerIndex].diseases[0] = {
      diseaseId: {
        value: null,
        errorMessage: null,
        formStatus: null
      },
      diseaseDetail: {
        value: null,
        errorMessage: null,
        formStatus: null
      },
      diagnosisDate: {
        value: null,
        errorMessage: null,
        formStatus: null
      }
    };
  } else {
    form_state.insuredWithDesease[insurerIndex].diseases.splice(
      diseaseIndex,
      1
    );
  }
};

const addInsurerInSports = () => {
  const insurer_total = insured_options.value.length;

  if (form_state.sports.length < insurer_total) {
    form_state.sports.push({
      insuredId: null,
      options: [
        {
          value: null,
          errorMessage: null,
          formStatus: null
        }
      ]
    });
  }
};

const deleteInsurerOnSports = (insurerIndex) => {
  form_state.sports.splice(insurerIndex, 1);

  if (form_state.sports.length === 0) {
    form_state.sportsSelection = 2;
  }
};

const addInsurer = () => {
  const insurer_total = insured_options.value.length;

  if (form_state.insuredWithDesease.length < insurer_total) {
    form_state.insuredWithDesease.push({
      insuredId: null,
      diseases: [
        {
          diseaseId: {
            value: null,
            errorMessage: null,
            formStatus: null
          },
          diseaseDetail: {
            value: null,
            errorMessage: null,
            formStatus: null
          },
          diagnosisDate: {
            value: null,
            errorMessage: null,
            formStatus: null
          }
        }
      ]
    });
  }
};

const deleteInsurer = (insurerIndex) => {
  form_state.insuredWithDesease.splice(insurerIndex, 1);

  if (form_state.insuredWithDesease.length === 0) {
    form_state.diseaseSelection = 2;
  }
};

const getSportsList = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_SPORTS)
      .then((resp) => {
        sports_options.value = resp.map((sport) => ({
          value: sport.id,
          label: sport.label
        }));
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPetsList = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_PETS)
      .then((resp) => {
        pets_options.value = resp.map((pet) => ({
          value: pet.id,
          label: pet.label
        }));
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getDiseasesList = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_DISEASES)
      .then((resp) => {
        diseases_options.value = resp.map((disease) => ({
          value: disease.id,
          label: disease.label
        }));

        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getInsuredList = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_INSUREDS)
      .then((resp) => {
        insured_options.value = resp.map((insured) => ({
          value: insured.id,
          label: `${insured.firstName} ${insured.firstSurname}`,
          disabled: false
        }));
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getInsuredWithDeseasesDeclarations = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_INDUREDS_WITH_DESEASES)
      .then((resp) => {
        console.log('getInsuredWithDeseasesDeclarations', resp);
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const diseaseInputsValidation = () => {
  let errors_found = false;

  if (form_state.diseaseSelection === 1) {
    form_state.insuredWithDesease.forEach((obj) => {
      for (let i = 0; i < obj.diseases.length; i++) {
        if (obj.diseases[i].diseaseId.value === null) {
          errors_found = true;
          obj.diseases[i].diseaseId.errorMessage =
            'Seleccione una enfermedad / diagnóstico';
          obj.diseases[i].diseaseId.formStatus = 'error';
        }

        if (obj.diseases[i].diseaseDetail.value === null) {
          errors_found = true;
          obj.diseases[i].diseaseDetail.errorMessage = 'Ingrese un detalle';
          obj.diseases[i].diseaseDetail.formStatus = 'error';
        }

        if (obj.diseases[i].diagnosisDate.value === null) {
          errors_found = true;
          obj.diseases[i].diagnosisDate.errorMessage =
            'Ingrese una fecha de diagnóstico';
          obj.diseases[i].diagnosisDate.formStatus = 'error';
        }

        if (moment().isBefore(obj.diseases[i].diagnosisDate.value)) {
          errors_found = true;
          obj.diseases[i].diagnosisDate.errorMessage =
            'No se permiten fechas futuras';
          obj.diseases[i].diagnosisDate.formStatus = 'error';
        }
      }
    });
  }

  return errors_found;
};

const sportsInputsValidation = () => {
  let errors_found = false;

  if (form_state.sportsSelection === 1) {
    form_state.sports.forEach((obj) => {
      for (let i = 0; i < obj.options.length; i++) {
        if (obj.options[i].value === null) {
          errors_found = true;
          obj.options[i].errorMessage =
            'Seleccione una enfermedad / diagnóstico';
          obj.options[i].formStatus = 'error';
        }
      }
    });
  }

  return errors_found;
};

const deleteErrorMessagesFromInput = (insurerIndex, diseaseIndex, field) => {
  form_state.insuredWithDesease[insurerIndex].diseases[diseaseIndex][
    field
  ].errorMessage = null;
  form_state.insuredWithDesease[insurerIndex].diseases[diseaseIndex][
    field
  ].formStatus = null;
};

const formValidation = (step) => {
  formRef.value
    .validate()
    .then(() => {
      saveInformation(step);
    })
    .catch((error) => {
      console.error(error);
    });
};

const saveInformation = async (step) => {
  loading.value = true;
  try {
    if (diseaseInputsValidation() || sportsInputsValidation()) {
      notification('error', 'Error', 'Uno o más campos faltan por completar');
    } else {
      await saveInsurerWithDiseases();
      await saveSportsDeclaration();
      await savePetsDecleration();
      store.dispatch(ACTION_CHANGE_STEP, step);
    }
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
  loading.value = false;
};

const saveInsurerWithDiseases = () => {
  return new Promise((resolve, reject) => {
    const payload = form_state.insuredWithDesease.map((obj) => ({
      insuredId: obj.insuredId,
      diseases: obj.diseases.map((disease, index) => {
        return {
          index,
          diseaseId: disease.diseaseId.value,
          details: disease.diseaseDetail.value,
          diagnosisDate: moment(disease.diagnosisDate.value).format(
            'YYYY-MM-DD'
          )
        };
      })
    }));
    store
      .dispatch(PUT_INSUREDS, form_state.diseaseSelection === 1 ? payload : [])
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const saveSportsDeclaration = () => {
  return new Promise((resolve, reject) => {
    const payload = form_state.sports.map((sport) => ({
      insuredId: sport.insuredId,
      sportId: sport.options.map((s) => s.value)
    }));
    store
      .dispatch(PUT_SPORTS, form_state.sportsSelection === 1 ? payload : [])
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const savePetsDecleration = async () => {
  return new Promise((resolve, reject) => {
    const payload = {
      pets: form_state.pets
        .filter((obj) => obj != 3)
        .map((obj) => ({ petId: obj }))
    };

    if (form_state.pets.includes(3)) {
      payload.pets.push({ custom: form_state.customPet });
    }

    store
      .dispatch(
        PUT_PETS,
        form_state.petsSelection === 1 ? payload : { pets: [] }
      )
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchInsuredWithDiseases = () => {
  form_state.diseaseSelection = 2;
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_INDUREDS_WITH_DESEASES)
      .then((resp) => {
        resp.forEach((insured) => {
          if (insured.diseases.length > 0) {
            form_state.diseaseSelection = 1;
            form_state.insuredWithDesease.push({
              insuredId: insured.insured.id,
              diseases: insured.diseases.map((disease) => {
                const date = disease.diagnosisDate.split('-');
                return {
                  diseaseId: {
                    value: disease.disease.id,
                    errorMessage: null,
                    formStatus: null
                  },
                  diseaseDetail: {
                    value: disease?.details ?? null,
                    errorMessage: null,
                    formStatus: null
                  },
                  diagnosisDate: {
                    value: moment(`${date[1]}/${date[2]}/${date[0]}`),
                    errorMessage: null,
                    formStatus: null
                  }
                };
              })
            });
          }
        });
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchSportDeclaration = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_INSURED_WITH_SPORTS)
      .then((resp) => {
        let not_declared_sports = false;

        resp.forEach((sport) => {
          if (sport.sports.length > 0) {
            not_declared_sports = true;
            form_state.sports.push({
              insuredId: sport.insured.id,
              options: sport.sports.map((obj) => ({
                value: obj.id,
                errorMessage: null,
                formStatus: null
              }))
            });
          }
        });

        form_state.sportsSelection = not_declared_sports ? 1 : 2;

        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchPetsDeclaration = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_PETS_DECLARATION)
      .then((resp) => {
        const { pets } = resp;

        if (pets.length > 0) {
          form_state.petsSelection = 1;
          form_state.pets = pets.map((pet) => {
            if (pet?.pet?.id !== undefined) {
              return pet.pet.id;
            }
            return 3;
          });
        } else {
          form_state.petsSelection = 2;
        }
        const custom_found = pets.find((pet) => pet.custom !== undefined);
        if (custom_found != undefined) {
          form_state.customPet = custom_found.custom;
        }
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getData = async () => {
  loading_data.value = true;
  try {
    await getSportsList();
    await getPetsList();
    await getDiseasesList();
    await getInsuredList();
    await getInsuredWithDeseasesDeclarations();
    await fetchInsuredWithDiseases();
    await fetchSportDeclaration();
    await fetchPetsDeclaration();
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
  loading_data.value = false;
};

defineExpose({ formValidation });

onMounted(() => {
  getData();
});
</script>
