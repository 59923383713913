import {
  getAntecedentesCases,
  caseAntecedentesVerify,
  caseFilesUpload,
  updateInsuredState
} from '../../../network';
import {
  GET_ANTECEDENTES_CASES,
  SET_ANTECEDENTES_CASES,
  FETCH_ANTECEDENTES_CASES,
  GET_VERIFY_CASE_ANTECEDENTES,
  SET_VERIFY_CASE_ANTECEDENTES,
  FETCH_VERIFY_CASE_ANTECEDENTES,
  POST_CASE_FILE_UPLOAD,
  UPDATE_INSURANCE_STATE
} from './types';

const store = {};
const getDefaultState = () => ({
  antecedentes: [],
  verifyCaseAntecedentes: []
});

store.state = getDefaultState();

store.getters = {
  [GET_ANTECEDENTES_CASES](state) {
    state.antecedentes.sort((a, b) => {
      return a.id - b.id;
    });
    return state.antecedentes.map((a) => {
      return {
        key: a.id,
        id: a.id,
        holding: a.ticket.company?.holding
          ? a.ticket.company?.holding.businessName
          : a.ticket.company.subHolding?.holding?.businessName,
        subholding: a
          ? a.ticket.company
            ? a.ticket.company.subHolding
              ? a.ticket.company.subHolding.name
              : ''
            : ''
          : '',
        companyName: a?.ticket?.company?.businessName,
        companyRut: a?.ticket?.company?.rut,
        employeeName: `${a.ticket.employee.firstName} ${a.ticket.employee.firstSurname}`,
        employeeRut: a?.ticket?.employee?.rut,
        ticketState: a.states[a.states.length - 1]?.state.id.toString(),
        createdAt: a?.updatedAt
      };
    });
  },
  [GET_VERIFY_CASE_ANTECEDENTES](state) {
    return state.verifyCaseAntecedentes;
  }
};

store.mutations = {
  [SET_ANTECEDENTES_CASES](state, payload) {
    state.antecedentes = payload;
  },
  [SET_VERIFY_CASE_ANTECEDENTES](state, payload) {
    state.verifyCaseAntecedentes = payload;
  }
};

store.actions = {
  async [FETCH_ANTECEDENTES_CASES]({ commit }) {
    const response = await getAntecedentesCases().catch((err) =>
      console.log(err)
    );
    if (response && response.status === 200) {
      commit(SET_ANTECEDENTES_CASES, response.data);
    } else {
      throw { mensaje: 'Error en la solicitud' };
    }
  },
  async [FETCH_VERIFY_CASE_ANTECEDENTES]({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      caseAntecedentesVerify(id, payload).then(
        (response) => {
          commit(SET_VERIFY_CASE_ANTECEDENTES, response.data);
          resolve(response.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [POST_CASE_FILE_UPLOAD](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      caseFilesUpload(id, payload).then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [UPDATE_INSURANCE_STATE](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      updateInsuredState(id, payload).then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
};

export default store;
