import * as Sentry from '@sentry/vue';
import router from '../../router';
import {
  TOKEN_VALIDATION,
  REFRESH_TOKEN,
  FETCH_DIGITAL_FORM,
  GET_DIGITAL_FORM_TOKEN
} from '../../store/types';
import store from '@/store';

// let errors_counter = {};

const authorization = (http) => ({
  tokenResponse: async (error) => {
    if (error.message === 'canceled') return Promise.reject(error);
    const { response, config } = error;
    const { url } = config;

    switch (response.status) {
      case 401: {
        if (url.includes('digital-form')) {
          const url_parts = url.split('/');

          // Si la url final es digital-form y esta invalida, significa que tengo que es el ultimo paso y debo mandar a ticket invalido
          if (url_parts[url_parts.length - 1] === 'digital-form') {
            router.push({ name: 'DigitalFormInvalidToken' });
          } else {
            // Si no lo es, aca tengo que hacer la logica para poder refrescar el token para poder hacer lo demas
            const resp = await store.dispatch(FETCH_DIGITAL_FORM);
            const { isTokenExpired } = resp.data;

            if (isTokenExpired === true) {
              const token = store.getters[GET_DIGITAL_FORM_TOKEN];
              router.push({
                name: 'DigitalFormExpiredToken',
                params: { token: token }
              });
            } else if (isTokenExpired === false) {
              router.push({ name: 'DigitalFormTokenStillWorking' });
            }
          }
        } else {
          // Validar de que los tokens esten bien
          const validation_resp = await store.dispatch(TOKEN_VALIDATION);
          // Como solo expiro el token, puedo volver a  refrescar el token
          if (validation_resp.resp === 'access expired') {
            await store.dispatch(REFRESH_TOKEN);
            return http(config);
          } else if (validation_resp.resp === 'no credentials') {
            router.push({ name: 'Login' });
          }
        }
        break;
      }
      default:
        break;
    }
    // Retornar esto si o si ya que lo necesita el interceptor
    Sentry?.captureException(error);
    return Promise.reject(error);
  }
});

export default authorization;
