<template>
  <div>
    <a-table
      :dataSource="props.data"
      :columns="columns"
      :pagination="pagination_props"
      @change="changeCurrentPage"
      :loading="loading"
    >
      <template #action="{ text: id, record }">
        <span style="display: flex">
          <a-tooltip color="#05045D" title="Ver detalle">
            <a-button type="link" @click="() => redirectPolicyDetail(id)">
              <EyeOutlined class="blue-icon" />
            </a-button>
          </a-tooltip>
          <a-tooltip color="#05045D" title="Eliminar">
            <a-button
              type="link"
              @click="() => openDeletePolicyModal(id, record.policyNumber)"
            >
              <DeleteOutlined class="delete-holding-text" />
            </a-button>
          </a-tooltip>
        </span>
      </template>
    </a-table>
    <PolicyDeleteModal
      ref="policy_delete_modal_ref"
      @deletedPolicy="deletedPolicy"
    />
  </div>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  defineExpose,
  reactive,
  ref,
  watch
} from 'vue';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import PolicyDeleteModal from '../../components/clients/policy/PolicyDeleteModal.vue';

const props = defineProps({
  data: {
    type: Array,
    default: []
  },
  loading: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['deletedPolicy']);

const router = useRouter();

const columns = [
  {
    title: 'Nº Póliza',
    width: 100,
    dataIndex: 'policyNumber',
    key: 'policyNumber'
  },
  {
    title: 'Coberturas',
    width: 100,
    dataIndex: 'coverages',
    key: 'coverages'
  },
  {
    title: 'Compañía',
    width: 100,
    dataIndex: 'companyName',
    key: 'companyName'
  },
  {
    title: 'Acción',
    width: 100,
    dataIndex: 'action',
    key: 'action',
    slots: {
      customRender: 'action'
    }
  }
];

const pagination_props = reactive({
  total: 0,
  pageSize: 5,
  current: 1
});

const changeCurrentPage = (page) => {
  console.log('changeCurrentPage', page);
  pagination_props.current = page.current;
};

const redirectPolicyDetail = (id) => {
  router.push({
    name: 'PolicyDetail',
    params: {
      policyId: id
    }
  });
};

const policy_delete_modal_ref = ref();
const openDeletePolicyModal = (polId, polNum) => {
  policy_delete_modal_ref.value.openModal(polId, polNum);
};

const deletedPolicy = () => {
  emit('deletedPolicy');
};

watch(
  () => props.data,
  (newVal) => {
    pagination_props.total = newVal.length;
  }
);

defineExpose({
  changeCurrentPage
});
</script>
