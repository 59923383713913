import {
  verifyDocumentRequestToken,
  getOldDocument,
  uploadDocumentRequested,
  getBlankDocument
} from '../../../network';
import {
  GET_VALIDATE_REAPPLICATION_DOCUMENT,
  POST_REAPPLICATION_DOCUMENT,
  MUTATE_REAPPLICATION_DOCUMENT,
  GET_REAPPLICATION_DOCUMENT_STATE,
  GET_OLD_DOCUMENT,
  GET_BLANK_DOCUMENT
} from './types';

const store = {};

store.state = {
  valid: false
};

store.getters = {
  [GET_REAPPLICATION_DOCUMENT_STATE](state) {
    return state.valid;
  }
};

store.mutations = {
  [MUTATE_REAPPLICATION_DOCUMENT](state, payload) {
    state.valid = payload;
  }
};

store.actions = {
  [GET_VALIDATE_REAPPLICATION_DOCUMENT](_, payload) {
    return new Promise((resolve, reject) => {
      verifyDocumentRequestToken(payload.id, payload.token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [POST_REAPPLICATION_DOCUMENT]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      uploadDocumentRequested(payload)
        .then((resp) => {
          commit(MUTATE_REAPPLICATION_DOCUMENT, true);
          resolve(resp);
        })
        .catch((error) => {
          commit(MUTATE_REAPPLICATION_DOCUMENT, true);
          reject(error);
        });
    });
  },
  [GET_OLD_DOCUMENT](_, payload) {
    return new Promise((resolve, reject) => {
      getOldDocument(payload)
        .then((resp) => {
          resolve(resp);
        })
        .then((error) => {
          reject(error);
        });
    });
  },
  [GET_BLANK_DOCUMENT](_, payload) {
    return new Promise((resolve, reject) => {
      getBlankDocument(payload)
        .then((resp) => {
          resolve(resp);
        })
        .then((error) => {
          reject(error);
        });
    });
  }
};

export default store;
