<template>
  <div>
    <a-table v-bind="table_props" @change="changeCurrentPage">
      <template #idRender="{ text }">
        <p># {{ text }}</p>
      </template>

      <template #filterDropdown="{ confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-form name="form" layout="vertical" ref="form_ref" :model="filter_values" :rules="rules">
            <a-form-item has-feedback :name="column.dataIndex">
              <a-input v-model:value="filter_values[column.key]" @keyup="formatRuts"
                :placeholder="`Buscar ${column.title}`" @pressEnter="
                  handleSearch(
                    column.dataIndex,
                    filter_values[column.key],
                    confirm
                  )
                  " style="width: 188px; margin-bottom: 8px; display: block" />
            </a-form-item>

            <a-button type="primary" size="small" style="width: 90px; margin-right: 8px" @click="
              handleSearch(
                column.dataIndex,
                filter_values[column.key],
                confirm
              )
              ">
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>
            <a-button size="small" style="width: 90px" @click="handleReset(column.dataIndex, clearFilters)">
              Limpiar
            </a-button>
          </a-form>
        </div>
      </template>

      <template #filterIcon="{ column }">
        <search-outlined :style="{
          color:
            query_values[column.dataIndex] === undefined ||
              query_values[column.dataIndex] === ''
              ? undefined
              : '#2521E5'
        }" />
      </template>

      <template #actionRender="{ text: id }">
        <span style="display: flex">
          <div style="margin-left: auto">
            <a-tooltip color="#05045D" @click="holdingEdition(id)" title="Editar">
              <a-button type="link">
                <delete-outlined class="edit-holding-text" />
              </a-button>
            </a-tooltip>
            <a-tooltip color="#05045D" @click="holdingDelete(id)" :title="`Eliminar`">
              <a-button type="link">
                <edit-outlined class="delete-holding-text" />
              </a-button>
            </a-tooltip>
          </div>
        </span>
      </template>
    </a-table>

    <!-- Creacion de holding -->
    <holding-create-modal ref="holding_creation" @holdingCreated="holdingCreated" />

    <!-- Edicion de holding -->
    <holding-edition-modal ref="holding_edition" @holdingEdited="holdingEdited" />

    <!-- Eliminacion de holding -->
    <holding-deletion-modal ref="holding_delete" @holdingDeleted="holdingDeleted" />
  </div>
</template>

<script>
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons-vue';
import { onMounted, reactive, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  FETCH_HOLDINGS_DATA_TABLE,
  GET_HOLDINGS_DATA_TABLE
} from '@/store/types';
import rutFormatting from '@/utils/formatRut';
import rutHelper from '@/utils/Ruts';
import HoldingCreationModal from './modals/HoldingCreationModal.vue';
import HoldingEditionModal from './modals/HoldingEditionModal.vue';
import HoldingDeletionModal from './modals/HoldingDeletionModal.vue';

export default {
  name: 'Holdings',
  components: {
    'search-outlined': SearchOutlined,
    'edit-outlined': DeleteOutlined,
    'delete-outlined': EditOutlined,
    'holding-create-modal': HoldingCreationModal,
    'holding-edition-modal': HoldingEditionModal,
    'holding-deletion-modal': HoldingDeletionModal
  },
  setup() {
    // * Variables globales
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // * Tabla
    const filter_values = ref({});
    const query_values = ref({});

    const table_props = reactive({
      dataSource: [],
      columns: [
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
          slots: {
            customRender: 'idRender'
          }
        },
        {
          title: 'Holding',
          dataIndex: 'holdingName',
          key: 'holdingName',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon'
          }
        },
        {
          title: 'RUT Holding',
          dataIndex: 'rutHolding',
          key: 'rutHolding',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon'
          }
        },
        {
          title: 'Acción',
          dataIndex: 'action',
          key: 'action',
          align: 'center',
          width: 150,
          slots: {
            customRender: 'actionRender'
          }
        }
      ],
      loading: false,
      scroll: { x: 700 },
      pagination: {
        total: 0,
        pageSize: 0,
        current: 0
      }
    });

    const filterRedirectionNewParam = (key, param) => {
      query_values.value[key] = param;

      if (key != 'page') {
        query_values.value['page'] = 1;
      }

      router.replace({
        name: 'Cliente',
        query: query_values.value
      });
    };

    const filterRedirectionResetColumn = (key) => {
      delete query_values.value[key];

      router.replace({
        name: 'Cliente',
        query: query_values.value
      });
    };

    const handleSearch = (column, value, confirm) => {
      if (value != '') {
        form_ref.value.validate().then(() => {
          filterRedirectionNewParam(column, value);
          confirm();
        });
      }
    };

    const handleReset = (column, clearFilters) => {
      filterRedirectionResetColumn(column);
      delete filter_values.value[column];
      clearFilters();
      form_ref.value.clearValidate();
    };

    const changeCurrentPage = (page) => {
      filterRedirectionNewParam('page', page.current);
    };

    // * Form
    const form_ref = ref();
    const checkRut = async (rule, value) => {
      if (!value) {
        return;
      }
      if (!rutHelper.validateRut(value)) {
        return Promise.reject('RUT no válido');
      }
      return Promise.resolve();
    };
    const rules = {
      rutHolding: [
        {
          required: false,
          validator: checkRut,
          trigger: 'change'
        }
      ]
    };

    const formatRuts = () => {
      if (filter_values.value['rutHolding']) {
        filter_values.value['rutHolding'] = rutHelper.formatRut(
          filter_values.value['rutHolding']
        );
      }
    };

    // * Funciones
    const getHoldingData = async () => {
      table_props.loading = true;
      let queryParams = route.fullPath.split('?')[1] || 'page=1';
      queryParams = `?${queryParams}`;

      try {
        const resp = await store.dispatch(
          FETCH_HOLDINGS_DATA_TABLE,
          queryParams
        );

        if (resp.status === 200) {
          const holdings = store.getters[GET_HOLDINGS_DATA_TABLE];

          table_props.dataSource = holdings.items?.map((obj) => {
            return {
              key: obj.id,
              id: obj.id,
              holdingName: obj.businessName,
              rutHolding: rutFormatting(obj.rut),
              action: obj.id
            };
          });

          table_props.pagination.total = holdings.totalItems;
          table_props.pagination.pageSize = 10;
          table_props.pagination.current = holdings.page;
        }
      } catch (error) {
        console.error(error);
      }
      table_props.loading = false;
    };

    watchEffect(() => {
      getHoldingData();
    });

    onMounted(() => {
      const key_params = Object.keys(route.query);
      key_params.forEach((key) => {
        filter_values.value[key] = route.query[key];
        query_values.value[key] = route.query[key];
      });
    });

    // * Creacion de holding
    const holding_creation = ref();
    const holdingCreation = () => {
      holding_creation.value.openModal();
    };

    const holdingCreated = () => {
      getHoldingData();
    };

    // * Edicion de holding
    const holding_edition = ref();
    const holdingEdition = (id) => {
      const found_holding = table_props.dataSource.find((obj) => obj.id === id);
      holding_edition.value.openModal(found_holding);
    };

    const holdingEdited = () => {
      getHoldingData();
    };

    // * Eliminacion de holding
    const holding_delete = ref();
    const holdingDelete = (id) => {
      const found_holding = table_props.dataSource.find((obj) => obj.id === id);
      holding_delete.value.openModal(found_holding);
    };

    const holdingDeleted = () => {
      getHoldingData();
    };

    return {
      table_props,
      query_values,
      filter_values,
      holding_creation,
      holding_edition,
      holding_delete,
      form_ref,
      rules,
      formatRuts,
      getHoldingData,
      holdingCreated,
      holdingCreation,
      changeCurrentPage,
      handleSearch,
      handleReset,
      holdingEdition,
      holdingEdited,
      holdingDelete,
      holdingDeleted
    };
  }
};
</script>

<style>
.edit-holding-text {
  color: #2383f6;
  cursor: pointer;
}

.delete-holding-text {
  color: #ff4d4f;
  cursor: pointer;
}
</style>
