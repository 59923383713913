<template>
  <div>
    <!-- Tabla -->
    <div v-if="modalProps.loading_button">
      <a-skeleton active />
    </div>
    <div v-else>
      <a-table :dataSource="confSolicitudes" :columns="columns" :pagination="false" :scroll="{ x: 1000 }">
        <template #mailBoxConf="{ text }">
          <a @click="preConfigureMailbox(text)">Configurar</a>
        </template>
        <template #mailsDisplay="{ text }">
          {{ text.join(', ') }}
        </template>
      </a-table>
    </div>

    <!-- Modal -->
    <a-modal v-model:visible="modalProps.visibility" :title="modalProps.title" :loading="loading_table" @ok="handleOk"
      @cancel="handleCancel" :closable="!modalProps.loading_button" :maskClosable="!modalProps.loading_button">
      <p>
        En caso de que sea más de un destinatario por casilla, debes separar por
        comas cada dirección de correo electrónico.
      </p>

      <a-form ref="formRef" :model="formState" :rules="rules" layout="vertical">
        <!-- Altas -->
        <a-form-item ref="emails" :label="modalProps.title_input" name="emails">
          <a-input v-model:value="formState.emails"
            :placeholder="`Correo para solicitud de ${modalProps.title_input}`" />
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button key="back" @click="handleCancel" :disabled="modalProps.loading_button">Cancelar</a-button>
        <a-button key="submit" @click="handleOk" :loading="modalProps.loading_button">Guardar</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  FETCH_MAIL_RECEPTION_REQUEST,
  GET_MAIL_RECEPTION_REQUEST,
  POST_MAIL_RECEPTION_REQUEST,
  PUT_MAIL_RECEPTION_REQUEST
} from '@/store/types';
import notification from '@/utils/notifications';
import delay from '@/utils/delay';

export default {
  name: 'MailReception',
  setup() {
    // * Tabla
    // Columnas
    const columns = [
      {
        title: 'Tipo',
        dataIndex: 'type',
        key: 'type'
      },
      {
        title: 'Clase',
        dataIndex: 'class',
        key: 'class'
      },
      {
        title: 'Correo destinatario',
        dataIndex: 'emails',
        key: 'emails',
        slots: {
          customRender: 'mailsDisplay'
        }
      },
      {
        title: 'Acción',
        dataIndex: 'mailbox_type',
        key: 'mailbox_type',
        slots: {
          customRender: 'mailBoxConf'
        }
      }
    ];

    // * Modal
    const modalProps = reactive({
      visibility: false,
      title: 'Recepción de solicitudes',
      loading_button: false,
      disable_button: false,
      title_input: ''
    });

    const openModal = () => {
      // Abro el modal
      modalProps.visibility = true;
    };

    const closeModal = () => {
      // Abro el modal
      modalProps.visibility = false;
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(() => {
          if (confMode.value === 'create') {
            createMailbox();
          } else {
            editMailbox();
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    };

    const handleCancel = () => {
      formRef.value.resetFields();
      closeModal();
    };

    // * Formulario
    const formRef = ref();
    const formState = reactive({
      emails: ''
    });

    // Reglas
    const validateEmail = (mail) => {
      return String(mail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const checkEmail = async (rule, value) => {
      if (!value) {
        return Promise.reject('Ingrese correo electrónico');
      }

      const emails = value.split(',');
      for (let index = 0; index < emails.length; index++) {
        const element = emails[index].trim();
        const email_validator = validateEmail(element) != null;

        if (email_validator == false) {
          return Promise.reject('Correo inválido');
        }
      }
      return Promise.resolve();
    };

    const rules = {
      emails: [
        {
          required: true,
          validator: checkEmail,
          trigger: 'change'
        }
      ]
    };

    // * Abrir el modal y acciones de configuracion para hacer la accion
    const confMode = ref('');
    const confIdType = ref(0);
    const preConfigureMailbox = (id) => {
      // Busco el objeto al cual hice click de los que aparece en la tabla
      // Asi para poder cambiar el nombre del label del input solicitado
      const mailbox = confSolicitudes.value.find((obj) => obj.key == id);
      modalProps.title_input = mailbox.type;

      formState.emails = mailbox.emails.join(', ');

      // Si no existen correos en el tipo que hice click, significa que tengo que crearlos
      // Asi que paso el modo a creacion
      // En cambio si ya existen correos significa que tengo que editarlo
      // Asi que paso el modo a edicion
      confMode.value = mailbox.emails.length === 0 ? 'create' : 'edit';

      // Obtengo el id que estoy configurando
      confIdType.value = id;

      openModal();
    };

    // * Obtener los correos a un array
    const getEmails = () => {
      return formState.emails.split(/\s*,\s*/);
    };

    // * Crear Mailbox
    const createMailbox = async () => {
      modalProps.loading_button = true;

      try {
        const emails = formState.emails.split(/\s*,\s*/);

        await store.dispatch(POST_MAIL_RECEPTION_REQUEST, {
          payload: {
            mailboxInsuranceTypeId: confIdType.value,
            emails
          },
          id: company_id
        });

        await store.dispatch(FETCH_MAIL_RECEPTION_REQUEST, company_id);

        await delay(1000);

        closeModal();

        notification('success', 'Correcto', 'Casilla de correo creada');
      } catch (error) {
        console.log(error);
        notification(
          'error',
          'Error',
          'Ocurrio un error, vuelve a intentarlo mas tarde'
        );
      }

      modalProps.loading_button = false;
    };

    // * Editar Mailbox
    const editMailbox = async () => {
      modalProps.loading_button = true;

      try {
        // Actualizo
        await store.dispatch(PUT_MAIL_RECEPTION_REQUEST, {
          payload: {
            emails: getEmails()
          },
          id_company: company_id,
          id_mailbox: confIdType.value
        });

        await delay(1000);

        // Envio el mensaje
        notification('success', 'Correcto', 'Casilla de correo editada');

        // Cierro el modal
        closeModal();
      } catch (error) {
        notification(
          'error',
          'Error',
          'Ocurrio un error, vuelve a intentarlo mas tarde'
        );
      }

      modalProps.loading_button = false;
    };

    // * Obtencion de data
    const route = useRoute();
    const company_id = route.params.id;

    // Aca obtengo la data de la configuracion de la aseguradora en la recuperacion de solicitudes

    // Primero obtengo la data por fetch
    const store = useStore();
    onMounted(async () => {
      await store.dispatch(FETCH_MAIL_RECEPTION_REQUEST, company_id);
    });

    // Obtengo la data llamada desde el fetch en un computed para que se actualice automaticamente
    // Cuando cambie la variable del vuex
    const loading_table = ref(false);
    const confSolicitudes = computed(() => {
      // Retorno el valor para la tabla
      loading_table.value = true;
      const mailboxes = store.getters[GET_MAIL_RECEPTION_REQUEST];
      loading_table.value = false;

      return mailboxes;
    });

    return {
      // Variables
      columns,
      modalProps,
      formRef,
      formState,
      rules,
      confSolicitudes,
      loading_table,
      // Funciones
      openModal,
      handleOk,
      handleCancel,
      closeModal,
      preConfigureMailbox
    };
  }
};
</script>
