<template>
  <div>
    <!-- Boton para crear un nuevo formulario -->
    <div class="mt-4">
      <a-button class="new_group" @click="openModal('creating')">
        <template #icon>
          <plus-outlined />
        </template>
        Nuevo formulario
      </a-button>
    </div>

    <div v-if="loading_data">
      <a-skeleton active />
    </div>
    <div v-else>
      <div class="mt-4" v-for="f in files_fetch" :key="f.id">
        <a-descriptions bordered>
          <a-descriptions-item label="Nombre" :span="3" style="width: 50%">
            {{ f.name }}
          </a-descriptions-item>
          <a-descriptions-item label="Archivo" :span="3">
            <a :href="f.fileInsuranceURL" target="_blank">{{ f.name }}</a>
          </a-descriptions-item>
        </a-descriptions>

        <span
          style="justify-content: flex-end; list-style: none; display: flex"
          class="mt-2 mr-2"
        >
          <p class="edit-holding-text" @click="openModal('editing', f.id)">
            Editar
          </p>
          <p class="delete-holding-text ml-5" @click="openDeleteModal(f.id)">
            Eliminar
          </p>
        </span>
      </div>
    </div>

    <a-modal
      v-model:visible="modal_props.visibility"
      :title="modal_props.title"
      @ok="handleOk"
      @cancel="handleCancel"
      :closable="!modal_props.loading"
      :maskClosable="!modal_props.loading"
    >
      <a-form ref="formRef" :model="formState" :rules="rules" layout="vertical">
        <a-form-item ref="name" label="Nombre de formulario" name="name">
          <a-input
            v-model:value="formState.name"
            placeholder="Ingresa nombre de formulario"
          />
        </a-form-item>

        <a-form-item ref="files" :label="modal_props.upload_hint" name="files">
          <a-upload
            :file-list="formState.files"
            :multiple="false"
            :remove="handleRemove"
            :before-upload="beforeUpload"
          >
            <a-button>
              <upload-outlined></upload-outlined>
              Adjuntar archivo
            </a-button>
          </a-upload>
        </a-form-item>

        <p v-if="file_obj_backup.name.length > 0">
          Archivo existente:
          <a :href="file_obj_backup.url_file" target="_blank">{{
            file_obj_backup.file_name
          }}</a>
        </p>
      </a-form>

      <template #footer>
        <a-button
          key="back"
          @click="handleCancel"
          :disabled="modal_props.loading"
          >Cancelar</a-button
        >
        <a-button
          key="submit"
          @click="handleOk"
          :disabled="disable_button"
          :loading="modal_props.loading"
          >{{ modal_props.button_text }}</a-button
        >
      </template>
    </a-modal>

    <a-modal
      v-model:visible="delete_modal_props.visibility"
      title="Eliminar formulario"
      @ok="okDelete"
      @cancel="cancelDelete"
      :closable="!delete_modal_props.loading_button"
      :maskClosable="!delete_modal_props.loading_button"
    >
      <p>
        Para eliminar este Formulario escribe en el recuadro “eliminar” y
        presiona continuar.
      </p>

      <a-form
        ref="formRefDelete"
        :model="formStateDelete"
        :rules="rulesDelete"
        layout="vertical"
      >
        <a-form-item ref="delete_word" name="delete_word">
          <a-input
            v-model:value="formStateDelete.delete_word"
            placeholder="eliminar"
          />
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button key="back" @click="cancelDelete">Cancelar</a-button>
        <a-button
          key="submit"
          @click="deleteAction"
          type="primary"
          danger
          :disabled="
            delete_modal_props.disable_button ||
            delete_modal_props.loading_button
          "
          :loading="delete_modal_props.loading_button"
          >Continuar</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref, computed, onMounted, watch } from 'vue';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  FETCH_FILEINSURANCE_COMPANY,
  GET_FILEINSURANCE_COMPANY,
  POST_FORM_TYPE,
  PUT_FORM_TYPE,
  DELETE_FORM_TYPE
} from '@/store/types';
import delay from '@/utils/delay';
import notification from '@/utils/notifications';

export default {
  name: 'TypeForms',
  components: {
    'upload-outlined': UploadOutlined,
    PlusOutlined
  },
  setup() {
    // * Modal
    const modal_props = reactive({
      visibility: false, // Se abra y se cierre el modal
      title: '', // Titulo del modal
      creating: false, // Si se esta creando, se cambia a true
      editing: false, // Si se esta creando, se cambia a true
      button_text: '', // texto para el boton aceptar del modal
      loading: false,
      upload_hint: ''
    });

    // Click en el boton de ok del modal
    const handleOk = () => {
      formRef.value
        .validate()
        .then(() => {
          if (modal_props.creating) {
            console.log('Creando un form type');

            // Creo el form data
            const formData = new FormData();
            formData.append('file', formState.files[0]);
            formData.append('insuranceCompanyId', company_id);
            formData.append('name', formState.name);

            // Y se lo envio
            createFormType(formData);
          } else {
            // Creo el form data
            const formData = new FormData();

            // Si el usuario no subio ningun archivo significa que cambio solo el nombre
            if (formState.files.length > 0) {
              formData.append('file', formState.files[0]);
            }

            formData.append('name', formState.name);

            editFormType(formData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    // Click en el boton para cancelar
    const handleCancel = () => {
      formRef.value.resetFields();
      // dejo el nombre del archivo que estoy editando en vacio porsiacaso
      file_obj_backup.name = '';

      // Dejo los valores vacios
      formState.name = '';
      formState.files = [];

      modal_props.visibility = false;
    };

    // Abrir el modal
    // # Crear un formulario
    // # Editar un formulario

    const file_obj_backup = reactive({
      name: '',
      file_name: '',
      url_file: ''
    });

    const openModal = (type, id) => {
      if (type === 'creating') {
        modal_props.creating = true;
        modal_props.editing = false;
        modal_props.title = 'Nuevo formulario';
        modal_props.button_text = 'Agregar';
        modal_props.upload_hint = 'Presiona adjuntar archivo para cargar';
      } else {
        const file_found = files_fetch.value.find((file) => {
          return file.id == id;
        });

        // Paso los valores al formulario cuand edito
        formState.name = file_found.name;
        file_obj_backup.file_name = `${file_found.name}`;
        file_obj_backup.name = file_found.name;
        file_obj_backup.url_file = file_found.fileInsuranceURL;

        // Paso el id para llevar un registro de que id estoy editando
        editing_id.value = file_found.id;

        // Agrego propiedades al modal para que muestre diferente informacion
        modal_props.creating = false;
        modal_props.editing = true;
        modal_props.title = 'Editar formulario';
        modal_props.button_text = 'Guardar cambios';
        modal_props.upload_hint =
          'Presiona adjuntar archivo para reemplazar el archivo existente';
      }

      modal_props.visibility = true;
    };

    // habilitar/deshabilitar el boton
    const disable_button = computed(() => {
      if (modal_props.creating == true) {
        if (formState.name === '' || formState.files.length === 0) {
          return true;
        }
      } else {
        if (
          file_obj_backup.name === formState.name &&
          formState.files.length === 0
        ) {
          return true;
        }
      }

      return false;
    });

    // * Formulario
    const formRef = ref();
    const formState = reactive({
      name: '',
      files: []
    });

    const checkFiles = async (rule, value) => {
      if (modal_props.editing == false) {
        if (value.length === 0) {
          return Promise.reject('Adjunta archivo');
        }
      }
      return Promise.resolve();
    };

    const rules = {
      name: [
        {
          required: true,
          type: 'string',
          message: 'Ingresa nombre de formulario',
          trigger: 'change'
        }
      ],
      files: [
        {
          required: true,
          validator: checkFiles,
          trigger: 'change'
        }
      ]
    };

    // * Archivos
    const fileList = ref([]);

    const handleRemove = (file) => {
      const index = formState.files.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      formState.files = newFileList;
    };

    const beforeUpload = (file) => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        alert('El archivo excede el peso máximo 5MB');
      } else {
        formState.files = [file];
      }
      return false;
    };

    // * Crear un formulario tipo
    const loading_data = ref(false); // En caso de que se este trayendo la data nueva dejo cargando el modal

    const createFormType = async (value) => {
      // Dejo el boton de cargando
      modal_props.loading = true;

      // Hago el skeleton loading como cargando la data
      loading_data.value = true;

      try {
        const resp = await store.dispatch(POST_FORM_TYPE, value);

        if (resp.status >= 200) {
          await store.dispatch(FETCH_FILEINSURANCE_COMPANY, company_id);

          openNotificationWithIcon(
            'success',
            'Correcto',
            'Formulario creado con exito'
          );
        }
      } catch (error) {
        console.log({ error });
        if (error.response.status === 400) {
          openNotificationWithIcon(
            'error',
            'Error',
            error.response.data.message
          );
        } else {
          openNotificationWithIcon(
            'error',
            'Error',
            'Ocurrio un error inesperado, vuelve a intentarlo mas tarde'
          );
        }
      }

      // Cierro el modal
      handleCancel();

      modal_props.loading = false;
      loading_data.value = false;
    };

    // * Editar form
    const editing_id = ref('');
    const editFormType = async (value) => {
      modal_props.loading = true;
      loading_data.value = true;

      try {
        const resp = await store.dispatch(PUT_FORM_TYPE, {
          payload: value,
          id: editing_id.value
        });
        if (resp.status >= 200) {
          await store.dispatch(FETCH_FILEINSURANCE_COMPANY, company_id);
        }

        await delay(1000);

        openNotificationWithIcon(
          'success',
          'Correcto',
          'Formulario editado con exito'
        );
      } catch (error) {
        console.log(error);
        openNotificationWithIcon(
          'error',
          'Error',
          'Ocurrio un error inesperado, vuelve a intentarlo mas tarde'
        );
      }

      // Cierro el modal
      handleCancel();

      modal_props.loading = false;
      loading_data.value = false;
    };

    // * Notificaciones
    const openNotificationWithIcon = (type, title, message) => {
      notification(type, title, message);
    };

    // * Modal de eliminacion
    const delete_modal_props = reactive({
      visibility: false,
      disable_button: true,
      loading_button: false
    });

    const okDelete = () => {
      console.log('okDelete');
    };

    const cancelDelete = () => {
      formRefDelete.value.resetFields();
      delete_modal_props.visibility = false;
    };

    const delete_id = ref(0);
    const openDeleteModal = (id) => {
      delete_id.value = id; // Le paso la id que estoy editando
      delete_modal_props.visibility = true;
    };

    const formRefDelete = ref();
    const formStateDelete = reactive({
      delete_word: ''
    });

    const checkDeleteWord = async (rule, value) => {
      if (value === 'eliminar') {
        return Promise.resolve();
      }

      return Promise.reject('Ingresa palabra solicitada');
    };

    const rulesDelete = {
      delete_word: [
        {
          required: true,
          validator: checkDeleteWord,
          trigger: 'change'
        }
      ]
    };

    const deleteAction = () => {
      formRefDelete.value
        .validate()
        .then(() => {
          deleteFormType();
        })
        .catch((error) => {
          console.log('Error', error);
        });
    };

    const deleteFormType = async () => {
      delete_modal_props.loading_button = true;
      loading_data.value = true;
      try {
        const resp = await store.dispatch(DELETE_FORM_TYPE, delete_id.value); // Elimino el form type

        await delay(1000);

        cancelDelete();

        if (resp.status >= 200 && resp.status < 300) {
          openNotificationWithIcon(
            'success',
            'Correcto',
            'Formulario eliminado con exito'
          );
        }

        store.dispatch(FETCH_FILEINSURANCE_COMPANY, company_id);
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Correcto',
          'Ocurrio un error al eliminar el formulario, vuelve a intentarlo mas tarde'
        );
        cancelDelete();
      }
      delete_modal_props.loading_button = false;
      loading_data.value = false;
    };

    watch(
      () => formStateDelete.delete_word,
      (value) => {
        delete_modal_props.disable_button = value !== 'eliminar';
      }
    );

    // * Formularios incorporacion
    const store = useStore();
    const route = useRoute();
    const company_id = route.params.id;

    // Apenas carga la pagina me obtengo desde BD los archivos de la compania aseguradora
    onMounted(() => {
      loading_data.value = true;
      store.dispatch(FETCH_FILEINSURANCE_COMPANY, company_id).finally(() => {
        loading_data.value = false;
      });
    });

    // Me traigo los archivos que hice fetch, este se actualizara siempre si es que cambia
    // el valor del state en el vuex, asi que no hay necesidad de llamar a cada rato este valor
    const files_fetch = computed(() => {
      return store.getters[GET_FILEINSURANCE_COMPANY];
    });

    return {
      // * Variables
      modal_props,
      files_fetch,
      loading_data,
      delete_modal_props,
      formRefDelete,
      formStateDelete,
      rulesDelete,
      file_obj_backup,
      // * Metodos
      handleOk,
      handleCancel,
      openModal,
      formRef,
      formState,
      rules,
      disable_button,
      fileList,
      handleRemove,
      beforeUpload,
      okDelete,
      cancelDelete,
      openDeleteModal,
      deleteAction
    };
  }
};
</script>

<style scoped>
.ant-upload.ant-upload-select.ant-upload-select-text {
  text-align: inherit;
}
</style>
