import {
  getCollectionComments,
  postCollectionComments,
  getBillingDetailList,
  getCollections /* postReprocesses */,
  // postRequestByCollectionId,
  // getDataSendToRRHH,
  // postDataSendRRHH,
  getFileCollectionTicket,
  patchMarkInvoicePaid,
  getRetroactiveAdjustmentByCollection,
  aproveCollectionTicket,
  postCollectionTicketHumanSend
} from '../../../network';
import {
  GET_COLLECTION_COMMENTS,
  MUTATE_COLLECTION_COMMENTS,
  FETCH_COLLECTION_COMMENTS,
  POST_COLLECTION_COMMENTS,
  GET_BILLING_DETAIL_LIST,
  MUTATE_BILLING_DETAIL_LIST,
  FETCH_BILLING_DETAIL_LIST,
  GET_COLLECTIONS,
  MUTATE_COLLECTIONS,
  FETCH_COLLECTIONS /* POST_REPROCESSES */,
  // FETCH_COLLECTION_DATA_SEND_RRHH,
  // POST_COLLECTION_DATA_SEND_RRHH,
  // MUTATE_COLLECTION_DATA_RRHH,
  // GET_COLLECTION_DATA_RRHH,
  // POST_REQUESTS,
  FETCH_FILES_COLLECTION_TICKETS,
  MUTATE_FILES_COLLECTION_TICKET,
  GET_FILES_COLLECTION_TICKET,
  FETCH_RETROACTIVE_ADJUSTMENT_BY_COLLECTION,
  GET_RETROACTIVE_ADJUSTMENT_BY_COLLECTION,
  MUTATE_RETROACTIVE_ADJUSTMENT_BY_COLLECTION,
  PATCH_APROVE_COLLECTION_TICKET,
  PATCH_MARK_INVOICE_PAID,
  POST_COLLECTION_HUMAN_SEND
} from './types';

const store = {};

const getDefaultState = () => ({
  collectionComments: [],
  billingDetailList: [],
  collections: [],
  collectionDataRRHH: [],
  attachments: [],
  filesCollectionTIckets: [],
  retroactiveAdjustment: []
});

store.state = getDefaultState();

store.getters = {
  [GET_COLLECTION_COMMENTS]: (state) => state.collectionComments,
  [GET_BILLING_DETAIL_LIST]: (state) => state.billingDetailList?.data,
  [GET_COLLECTIONS]: (state) => state.collections,
  // [GET_COLLECTION_DATA_RRHH]: (state) => state.collectionDataRRHH,
  [GET_FILES_COLLECTION_TICKET]: (state) => state.filesCollectionTIckets,
  [GET_RETROACTIVE_ADJUSTMENT_BY_COLLECTION]: (state) =>
    state.retroactiveAdjustment
};

store.mutations = {
  [MUTATE_COLLECTION_COMMENTS](state, payload) {
    state.collectionComments = payload;
  },
  [MUTATE_BILLING_DETAIL_LIST](state, payload) {
    state.billingDetailList = payload;
  },
  [MUTATE_COLLECTIONS](state, payload) {
    state.collections = payload;
  },
  // [MUTATE_COLLECTION_DATA_RRHH](state, payload) {
  //   state.collectionDataRRHH = payload;
  // },
  [MUTATE_FILES_COLLECTION_TICKET](state, payload) {
    state.filesCollectionTIckets = payload;
  },
  [MUTATE_RETROACTIVE_ADJUSTMENT_BY_COLLECTION](state, payload) {
    state.retroactiveAdjustment = payload;
  }
};

store.actions = {
  [FETCH_COLLECTION_COMMENTS]({ commit }, { collection_id, param }) {
    return new Promise((resolve, reject) => {
      getCollectionComments(collection_id, param)
        .then((resp) => {
          commit(MUTATE_COLLECTION_COMMENTS, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_COLLECTION_COMMENTS](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      postCollectionComments(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_BILLING_DETAIL_LIST]({ commit }, { collection_id, query }) {
    return new Promise((resolve, reject) => {
      getBillingDetailList(collection_id, query)
        .then((resp) => {
          commit(MUTATE_BILLING_DETAIL_LIST, resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_COLLECTIONS]({ commit }, { collection_id, query_params }) {
    return new Promise((resolve, reject) => {
      getCollections(collection_id, query_params)
        .then((resp) => {
          commit(MUTATE_COLLECTIONS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // [FETCH_COLLECTION_DATA_SEND_RRHH]({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     getDataSendToRRHH(payload)
  //       .then(resp => {
  //         console.log('reeeeespuestaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',resp);
  //         commit(MUTATE_COLLECTION_DATA_RRHH, resp.data);
  //         resolve(resp);
  //       })
  //       .catch(err => {
  //         commit(MUTATE_COLLECTION_DATA_RRHH, []);
  //         reject(err);
  //       });
  //   });
  // },
  // [POST_COLLECTION_DATA_SEND_RRHH](_, { id, payload }) {
  //   return new Promise((resolve, reject) => {
  //     postDataSendRRHH(id, payload)
  //       .then((resp) => {
  //         resolve(resp);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },
  [FETCH_FILES_COLLECTION_TICKETS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getFileCollectionTicket(payload)
        .then((resp) => {
          commit(MUTATE_FILES_COLLECTION_TICKET, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_APROVE_COLLECTION_TICKET](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      aproveCollectionTicket(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_MARK_INVOICE_PAID](_, { invoiceId, date }) {
    return new Promise((resolve, reject) => {
      patchMarkInvoicePaid(invoiceId, date)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_RETROACTIVE_ADJUSTMENT_BY_COLLECTION](
    { commit },
    { collection_id, query_params }
  ) {
    return new Promise((resolve, reject) => {
      getRetroactiveAdjustmentByCollection(collection_id, query_params)
        .then((resp) => {
          commit(MUTATE_RETROACTIVE_ADJUSTMENT_BY_COLLECTION, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_COLLECTION_HUMAN_SEND](_, { collectionId, payload }) {
    console.log(POST_COLLECTION_HUMAN_SEND, { collectionId, payload });

    return new Promise((resolve, reject) => {
      postCollectionTicketHumanSend(collectionId, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  // [POST_REPROCESSES](_, payload) {
  //   return new Promise((resolve, reject) => {
  //     postReprocesses(payload)
  //       .then((resp) => {
  //         resolve(resp);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },
  // [POST_REQUESTS](_,{id,payload}){
  //   return new Promise((resolve, reject) => {
  //     postRequestByCollectionId(id,payload)
  //       .then((resp) => {
  //         resolve(resp);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // }
};

export default store;
