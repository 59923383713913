<template>
  <a-row type="flex" justify="center" align="middle" style="min-height: 100vh">
    <a-col :sm="20" :md="14" :lg="10">
      <a-row type="flex" justify="center" align="middle">
        <img src="@/assets/images/token-expired.svg" style="height: 300px" />
      </a-row>
      <a-typography-title :level="2" style="bottom: 0px; font-family: 'Urbanist'; text-align: center">
        Lo sentimos, el formulario de incorporación al que estás intentando
        acceder está expirado
      </a-typography-title>
      <a-typography-paragraph style="text-align: center">
        Para volver a acceder, presiona el botón reenviar y te enviaremos un
        nuevo enlace a tu correo
      </a-typography-paragraph>
      <a-row type="flex" justify="center">
        <a-button :loading="state.loadingResend" @click="handleResend" type="primary">Reenviar</a-button>
      </a-row>
    </a-col>
  </a-row>
</template>

<script setup>
import { onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  FETCH_DEPENDENTS_INCORPORATION_FORM,
  POST_RESEND_DEPENDENTS_INCORPORATION_FORM
} from '@/store/types';
import notification from '@/utils/notifications';

const route = useRoute();
const router = useRouter();
const store = useStore();

const state = reactive({
  loading: true,
  loadingResend: false
});

const handleResend = async () => {
  try {
    state.loadingResend = true;
    const response = await store.dispatch(
      POST_RESEND_DEPENDENTS_INCORPORATION_FORM,
      route.params.token
    );
    if (!response.status) {
      throw new Error('No se envió el correo');
    }
    notification(
      'success',
      'Enviado',
      'Se ha enviado un nuevo correo con el formulario de incorporación de cargas.'
    );
  } catch (error) {
    notification(
      'error',
      'Error',
      'No se pudo reenviar el correo. Intentalo más tarde.'
    );
  } finally {
    state.loadingResend = false;
  }
};

onMounted(async () => {
  try {
    const form = await store.dispatch(
      FETCH_DEPENDENTS_INCORPORATION_FORM,
      route.params.token
    );
    state.form = form;
    if (form.state !== 'Creado') {
      router.push({ name: 'DependentsIncorporationFormError' });
    }
    state.loading = false;
  } catch (error) {
    router.push({ name: 'DependentsIncorporationFormError' });
  }
});
</script>
