import config from '../config';

const appConfig = config();

const apiBewell = appConfig.auth_prefix;
const { v1 } = appConfig.apiVersion;

const network = (client) => ({
  apiBewellAuth: () => client.get(`${apiBewell}${v1}`),
  allUser: () => client.get(`${apiBewell}${v1}/user`),
  authUser: (payload) => client.get(`${apiBewell}${v1}/user/`, payload),

  updateUser: (payload) => client.patch(`${apiBewell}${v1}/user/`, payload),
  userState: (payload) =>
    client.patch(`${apiBewell}${v1}/user/´${payload}´/active_user`),
  tokenValidation: () => client.get(`${apiBewell}${v1}/auth/token_validation`),
  tokenRefresh: () => client.post(`${apiBewell}${v1}/auth/refresh_token`),
  deleteTokens: () => client.post(`${apiBewell}${v1}/auth/delete_tokens`),
  deleteCookie: () => client.get(`${apiBewell}${v1}/auth/delete_cookie`),

  ValidationCookie: () => client.post(`${apiBewell}${v1}/auth/existing_token`),

  // ---auth---
  authRegister: () => client.post(`${apiBewell}${v1}/auth/register`),
  authLogin: (payload) => client.post(`${apiBewell}${v1}/auth/login`, payload),

  // ---reset---
  resetMail: (payload) => client.post(`${apiBewell}${v1}/reset`, payload),
  resetPassword: (payload, token) =>
    client.get(`${apiBewell}${v1}/reset/${payload}/${token}`),
  CreateResetPassword: (payload, id, token) =>
    client.post(`${apiBewell}${v1}/reset/${id}/${token}`, payload),
  getOneUserById: (id) => client.get(`${apiBewell}${v1}/user/${id}`)
});

export default network;
