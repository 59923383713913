<template>
  <a-modal
    title="Editar cobertura"
    v-model:visible="visible"
    @cancel="closeModal"
    :closable="!loading"
    :maskClosable="!loading"
  >
    <a-form
      name="form"
      ref="form_ref"
      :model="form_state"
      layout="vertical"
      :rules="rules"
    >
      <a-form-item name="name" ref="name" label="Nombre">
        <a-input
          v-model:value="form_state.name"
          placeholder="Ingresa nombre cobertura"
          :maxlength="250"
        />
      </a-form-item>
      <a-form-item
        name="collectionName"
        ref="collectionName"
        label="Nombre cobranza"
      >
        <a-input
          v-model:value="form_state.collectionName"
          placeholder="Ingresa nombre cobertura para la cobranza"
          :maxlength="250"
        />
      </a-form-item>
      <a-form-item
        name="groupCompanyIds"
        ref="groupCompanyIds"
        label="Seleccionar grupo"
      >
        <a-select
          ref="groupCompanyIds"
          :options="groups_options"
          v-model:value="form_state.groupCompanyIds"
          mode="multiple"
          placeholder="Selecciona los grupos"
        />
      </a-form-item>
      <a-form-item ref="required" name="required">
        <a-checkbox v-model:checked="form_state.required">
          Cobertura obligatoria
        </a-checkbox>
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button :disabled="loading" @click="closeModal">Cancelar</a-button>
      <a-button
        type="primary"
        @click="formSubmit"
        :disabled="disableSavebutton"
        :loading="loading"
        >Guardar</a-button
      >
    </template>
  </a-modal>
</template>

<script setup>
import { ref, defineExpose, defineProps, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import notification from '../../utils/notifications';
import EventBus from '../../utils/events';
import { PUT_COMPANY_POLICY_COVERAGES, FETCH_COVERAGE } from '@/store/types';

const props = defineProps({
  groups: {
    type: Array,
    default: []
  }
});

const route = useRoute();
const groups_options = computed(() => {
  return props.groups.map((group) => ({
    label: group.name,
    value: group.id
  }));
});
const store = useStore();
const visible = ref(false);
const form_ref = ref();
const form_state = reactive({
  name: null,
  collectionName: null,
  groupCompanyIds: [],
  required: false
});
const form_state_validator = ref({});
const coverage_id = ref();

const rules = {
  name: [
    {
      required: true,
      message: 'Ingrese nombre de la cobertura',
      trigger: 'change',
      type: 'string'
    }
  ],
  collectionName: [
    {
      required: false,
      message: 'Ingrese nombre de la cobertura para la cobranza',
      trigger: 'change',
      type: 'string'
    }
  ],
  groupCompanyIds: [
    {
      required: true,
      message: 'Seleccionar grupo',
      trigger: 'change',
      type: 'array'
    }
  ]
};

const closeModal = () => {
  form_ref.value.resetFields();
  visible.value = false;
};

const loading = ref(false);
const formSubmit = async () => {
  form_ref.value.validate().then((resp) => {
    editCoverage(resp);
  });
};

const editCoverage = async (resp) => {
  try {
    loading.value = true;

    await store.dispatch(PUT_COMPANY_POLICY_COVERAGES, {
      id_coverage: coverage_id.value,
      payload: {
        ...resp,
        policyId: route.params.policyId
      }
    });

    EventBus.emit('coverageEdited', resp);
    notification('success', 'Correcto', 'Cobertura editada correctamente');
    closeModal();
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
    loading.value = false;
  }
  loading.value = false;
};

const disableSavebutton = computed(() => {
  return (
    JSON.stringify(form_state) === JSON.stringify(form_state_validator.value)
  );
});

const openModal = (coverageId) => {
  coverage_id.value = coverageId;
  getCoverage(coverageId);
};

const get_coverage_loading = ref(false);

const getCoverage = async (coverageId) => {
  get_coverage_loading.value = true;
  try {
    const resp = await store.dispatch(FETCH_COVERAGE, coverageId);
    const { name, groupCompanyIds, required, collectionName } = resp;

    form_state_validator.value = {
      name,
      collectionName,
      groupCompanyIds,
      required
    };

    form_state.name = name;
    form_state.collectionName = collectionName;
    form_state.groupCompanyIds = groupCompanyIds;
    form_state.required = required;
    visible.value = true;
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
    get_coverage_loading.value = false;
  }
  get_coverage_loading.value = false;
};

defineExpose({ openModal });
</script>
