<template>
  <div>
    <a-card>
      <a-typography-title :level="4">Antecedentes Cargas</a-typography-title>

      <a-skeleton :loading="loading_data" active>
        <div v-if="form_state.dependants.length === 0">
          <a-empty>
            <template #description>
              <span> Aún no has agregado cargas </span>
            </template>
          </a-empty>

          <div style="display: flex; flex-direction: column; align-items: center">
            <a-button @click="addDependant" type="primary">
              <template #icon>
                <PlusOutlined />
              </template>
              Agregar mis cargas
            </a-button>
          </div>
        </div>

        <a-form ref="form_ref" :model="form_state" layout="vertical">
          <div v-for="(dependant, index) in form_state.dependants" :key="dependant.key">
            <a-typography-title :level="5">Carga {{ index + 1 }}</a-typography-title>
            <a-row :gutter="16" type="flex">
              <a-col :span="8">
                <a-form-item :name="['dependants', index, 'rut']" :rules="{
                  required: true,
                  validator: checkRut,
                  trigger: 'change'
                }" label="RUT" required>
                  <a-input v-model:value="dependant.rut" placeholder="Ingrese el rut" @keyup="formatRuts(index)"
                    style="width: 100%" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :name="['dependants', index, 'firstName']" :rules="{
                  required: true,
                  message: 'Ingrese el primer nombre',
                  trigger: 'change'
                }" label="Primer nombre" required>
                  <a-input v-model:value="dependant.firstName" placeholder="Ingrese el primer nombre"
                    style="width: 100%" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :name="['dependants', index, 'secondName']" label="Segundo nombre">
                  <a-input v-model:value="dependant.secondName" placeholder="Segundo nombre" style="width: 100%" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :name="['dependants', index, 'firstSurname']" :rules="{
                  required: true,
                  message: 'Ingrese el Apellido paterno',
                  trigger: 'change'
                }" label="Apellido paterno" required>
                  <a-input v-model:value="dependant.firstSurname" placeholder="Ingrese el Apellido paterno"
                    style="width: 100%" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :name="['dependants', index, 'secondSurname']" label="Apellido materno">
                  <a-input v-model:value="dependant.secondSurname" placeholder="Apellido materno" style="width: 100%" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :name="['dependants', index, 'relationship']" :rules="{
                  required: true,
                  message: 'Ingrese Relación / Parentesco',
                  trigger: 'change',
                  type: 'string'
                }" required>
                  <template #label>
                    <a-typography>Relación / Parentesco </a-typography>
                    <a-tooltip>
                      <template #title>
                        Tener en consideración que la poliza considera al grupo
                        familiar directo, tales como cónyuge e hijos. Si Ud
                        ingresa a un beneficiario distinto a los indicados, la
                        compañía evaluará su ingreso de acuerdo con las
                        condiciones negociadas con su empleador y/o establecidas
                        en la poliza de seguros.
                      </template>
                      <QuestionCircleOutlined class="ml-2" />
                    </a-tooltip>
                  </template>

                  <a-select v-model:value="dependant.relationship" :options="relationOptions"
                    placeholder="Ingrese Relación / Parentesco">
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :name="['dependants', index, 'dateOfBirth']" :rules="{
                  required: true,
                  message: 'Ingrese Fecha de nacimiento',
                  trigger: 'change',
                  type: 'object'
                }" label="Fecha de nacimiento" required>
                  <a-date-picker :style="{ width: '100%' }" format="DD/MM/YYYY" v-model:value="dependant.dateOfBirth"
                    placeholder="Ingrese Fecha de nacimiento" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :name="['dependants', index, 'gender']" :rules="{
                  required: true,
                  message: 'Seleccione un genero',
                  trigger: 'change',
                  type: 'string'
                }" label="Sexo" required>
                  <a-select v-model:value="dependant.gender" :options="genderOptions"
                    placeholder="Seleccione una opción">
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :name="['dependants', index, 'weight']" :rules="{
                  required: true,
                  message: 'Ingrese el peso',
                  trigger: 'change',
                  type: 'number'
                }" label="Peso (kg)" required>
                  <a-input-number :min="0" v-model:value="dependant.weight" placeholder="Ingrese el peso"
                    style="width: 100%" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :name="['dependants', index, 'height']" :rules="{
                  required: true,
                  message: 'Ingrese la altura',
                  trigger: 'change',
                  type: 'number'
                }" label="Estatura (mt)" required>
                  <a-input-number :min="0" v-model:value="dependant.height" placeholder="Ingrese la altura"
                    style="width: 100%" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row type="flex" justify="end">
              <a-button @click="deleteDependant(dependant.key)">
                <template #icon>
                  <MinusOutlined />
                </template>
                Eliminar
              </a-button>
              <a-button @click="addDependant" type="primary" v-if="form_state.dependants.length - 1 === index"
                class="ml-2">
                <template #icon>
                  <PlusOutlined />
                </template>
                Agregar Carga
              </a-button>
            </a-row>

            <a-divider />
          </div>
        </a-form>
      </a-skeleton>
    </a-card>

    <a-row type="flex" justify="space-between" class="mt-3">
      <a-button size="large" @click="formSubmit(2)" :loading="loading">
        <template #icon>
          <ArrowLeftOutlined />
        </template>
        Atrás
      </a-button>
      <a-button size="large" type="primary" @click="formSubmit(4)" :loading="loading">Siguiente</a-button>
    </a-row>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted, defineExpose } from 'vue';
import rutHelper from '@/utils/Ruts';
import {
  MinusOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons-vue';
import notification from '@/utils/notifications';
import { useStore } from 'vuex';
import {
  ACTION_CHANGE_STEP,
  FETCH_DEPENDANTS,
  PUT_CHARGES
} from '../../store/types';
import moment from 'moment';

const loading_data = ref(false);
const store = useStore();
const form_ref = ref();
const form_state = reactive({
  dependants: []
});
const genderOptions = [
  {
    label: 'Masculino',
    value: 'M'
  },
  {
    label: 'Femenino',
    value: 'F'
  },
  {
    label: 'Prefiero no decirlo',
    value: '-'
  }
];
const relationOptions = [
  {
    label: 'Cónyuge',
    value: 'SPOUSE'
  },
  {
    label: 'Conviviente',
    value: 'COHABITANT'
  },
  {
    label: 'Hijo(a)',
    value: 'CHILD'
  },
  {
    label: 'Padre/Madre',
    value: 'PARENT'
  },
  {
    label: 'Nieto(a)',
    value: 'GRANDCHILD'
  },
  {
    label: 'Hermano(a)',
    value: 'SIBLING'
  }
];
const loading = ref(false);

const isRutRepeated = computed(() => {
  return form_state.dependants
    .map((obj) => obj.rut)
    .some((rut, index, array) => array.indexOf(rut) !== index);
});

const addDependant = () => {
  form_state.dependants.push({
    key: Date.now(),
    rut: null,
    firstName: null,
    secondName: null,
    firstSurname: null,
    secondSurname: null,
    relationship: null,
    dateOfBirth: null,
    height: null,
    weight: null
  });
};

const deleteDependant = (key) => {
  form_state.dependants = form_state.dependants.filter(
    (dependant) => dependant.key !== key
  );
};

const formatRuts = (key) => {
  form_state.dependants[key].rut = rutHelper.formatRut(
    form_state.dependants[key].rut
  );
};

const checkRut = async (rule, value) => {
  if (!value) {
    const error_message = 'Ingrese RUT';
    return Promise.reject(error_message);
  }
  if (!rutHelper.validateRut(value)) {
    return Promise.reject('RUT no válido');
  }
  return Promise.resolve();
};

const formSubmit = (step) => {
  form_ref.value.validate().then(() => {
    if (isRutRepeated.value === true) {
      notification(
        'error',
        'Error',
        'Ya existe un beneficiario con el mismo rut'
      );
    } else {
      putBeneficiaries(step);
    }
  });
};

const putBeneficiaries = async (step) => {
  loading.value = true;
  try {
    const beneficiaries = form_state.dependants.map((beneficiary) => ({
      rut: rutHelper.normalizeRut(beneficiary.rut),
      firstName: beneficiary.firstName,
      secondName: beneficiary.secondName,
      firstSurname: beneficiary.firstSurname,
      secondSurname: beneficiary.secondSurname,
      relationship: beneficiary.relationship,
      dateOfBirth: moment(beneficiary.dateOfBirth).format('YYYY-MM-DD'),
      gender: beneficiary.gender,
      weight: beneficiary.weight,
      height: beneficiary.height
    }));
    await store.dispatch(PUT_CHARGES, beneficiaries);
    store.dispatch(ACTION_CHANGE_STEP, step);
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
  loading.value = false;
};

const getCharges = async () => {
  loading_data.value = true;
  try {
    form_state.dependants = [];
    const resp = await store.dispatch(FETCH_DEPENDANTS);

    console.log('getCharges', resp);

    resp.forEach((dependant, index) => {
      form_state.dependants.push({
        key: index,
        rut: rutHelper.formatRut(dependant.rut),
        firstName: dependant.firstName,
        secondName: dependant.secondName,
        firstSurname: dependant.firstSurname,
        secondSurname: dependant.secondSurname,
        relationship: dependant.relationship,
        dateOfBirth: moment(dependant.dateOfBirth, 'YYYY-MM-DD'),
        gender: dependant.gender,
        weight: dependant.weight,
        height: dependant.height
      });
    });
  } catch (error) {
    console.error(error.response.data.message);
  }
  loading_data.value = false;
};

defineExpose({ formSubmit });

onMounted(() => {
  getCharges();
});
</script>
