import moment from 'moment';
import {
  dataEmployee,
  getDependent,
  employee,
  employeTicket,
  getCompanyEmployeeCoverage,
  saveEmployeeCompanyRent,
  employeeCompanyRent
} from '../../network';
import rutHelper from '../../utils/Ruts';
import {
  GET_EMPLOYEE,
  FETCH_EMPLOYEE,
  SET_EMPLOYEE,
  GET_FULL_NAME,
  GET_POLIZA,
  FETCH_DEPENDENTS,
  SET_DEPENDENTS,
  GET_DEPENDENTS,
  SET_EMPLOYEE_COMPANY,
  FETCH_EMPLOYEE_COMPANY,
  GET_EMPLOYEE_COMPANY,
  GET_EMPLOYEE_TICKET,
  SET_EMPLOYEE_TICKET,
  FETCH_EMPLOYEE_TICKET,
  FETCH_COVERAGE_EMPLOYEE,
  SET_COVERAGE_EMPLOYEE,
  GET_COVERAGE_EMPLOYEE,
  PUT_EMPLOYEE_COMPANY_RENT,
  FETCH_EMPLOYEE_COMPANY_RENT
} from './types';

const store = {};
const getDefaultState = () => ({
  employees: [],
  dependents: [],
  employeeCompany: [],
  employeeTicket: [],
  coverageEmployee: []
});

store.state = getDefaultState();

store.getters = {
  [GET_EMPLOYEE]: (state) => state.employees,
  [GET_FULL_NAME]: (state) => `${state.employees.firstName}`,
  [GET_POLIZA]: (state) =>
    state.employees.coverages?.map((e) => ({
      id: e?.coverageId,
      key: e.coverageId,
      tipo: e?.coverages.name,
      aseguradora: e.coverages.policies.insuranceCompany.businessName,
      numeroPoliza: e.coverages.policies.numberPolicy,
      empresa: e.coverages.policies.company.businessName,
      companyId: e.coverages.policies.company.id,
      vigencia: `${
        e?.startDate
          ? moment(e.startDate).format('DD-MM-YYYY')
          : 'Sin fecha de inicio'
      } / ${
        e?.endDate
          ? moment(e.endDate).format('DD-MM-YYYY')
          : 'Sin fecha de término'
      }`
    })),
  [GET_DEPENDENTS]: (state) =>
    state.dependents.map((e, i) => ({
      name: `RUT carga ${i + 1}`,
      id: e.id,
      key: e.id,
      rut: rutHelper.formatRut(e.rut),
      fullName: e.fullName,
      birthdayDate:
        e.birthdayDate != null
          ? moment(e.birthdayDate).format('DD-MM-YYYY')
          : ' - ',
      maintainerType: 'carga',
      startDate: e.startDate ? e.startDate : null,
      requestStartDate: e.requestStartDate ? e.requestStartDate : null,
      endDate: e.endDate ? e.endDate : null,
      requestEndDate: e.requestEndDate ? e.requestEndDate : null,
      employeeId: e.employeeId ? e.employeeId : null,
      canBeDeleted: e.canBeDeleted,
      vigencia: `${
        e?.startDate
          ? moment(e.startDate).format('DD-MM-YYYY')
          : 'Sin fecha de inicio'
      } / ${
        e?.endDate
          ? moment(e.endDate).format('DD-MM-YYYY')
          : 'Sin fecha de término'
      }`
    })),
  [GET_EMPLOYEE_COMPANY]: (state) => state.employeeCompany,
  [GET_EMPLOYEE_TICKET]: (state) => state.employeTicket,
  [GET_COVERAGE_EMPLOYEE]: (state) =>
    state.coverageEmployee.map((e) => ({
      id: e?.id,
      employeeCoverageId: e.employeeCoverageId,
      key: e.id,
      tipo: e?.name,
      aseguradora: e.insuranceCompany.businessName,
      numeroPoliza: e.policy.numberPolicy,
      vigencia: `${
        e?.startDate
          ? moment(e.startDate).format('DD-MM-YYYY')
          : 'Sin fecha de inicio'
      } / ${
        e?.endDate
          ? moment(e.endDate).format('DD-MM-YYYY')
          : 'Sin fecha de término'
      }`,
      status: e.status,
      maintainerType: 'cobertura',
      canBeDeleted: e.canBeDeleted,
      requestEndDate: e.requestEndDate ? e.requestEndDate : null,
      endDate: e.endDate,
      startDate: e.startDate ? e.startDate : null,
      requestStartDate: e.requestStartDate ? e.requestStartDate : null
    }))
};

store.mutations = {
  [SET_EMPLOYEE](state, payload) {
    state.employees = payload;
  },
  [SET_DEPENDENTS](state, payload) {
    state.dependents = payload;
  },
  [SET_EMPLOYEE_COMPANY](state, payload) {
    state.employeeCompany = payload;
  },
  [SET_EMPLOYEE_TICKET](state, payload) {
    state.employeTicket = payload;
  },
  [SET_COVERAGE_EMPLOYEE](state, payload) {
    state.coverageEmployee = payload;
  }
};

store.actions = {
  async [FETCH_EMPLOYEE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      dataEmployee(payload).then(
        (response) => {
          commit(SET_EMPLOYEE, response.data);
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [FETCH_COVERAGE_EMPLOYEE](
    { commit },
    { companyId, employeeId, queryParam }
  ) {
    return new Promise((resolve, reject) => {
      getCompanyEmployeeCoverage(companyId, employeeId, queryParam).then(
        (response) => {
          commit(SET_COVERAGE_EMPLOYEE, response.data);
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [FETCH_DEPENDENTS]({ commit }, payload) {
    const response = await getDependent(payload).catch((err) =>
      console.log(err)
    );
    if ((response && response.status === 200) || response.status === 201) {
      commit(SET_DEPENDENTS, response.data);
    } else {
      throw { mensaje: 'Error en la solicitud' };
    }
  },
  [FETCH_EMPLOYEE_COMPANY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      employee([payload]).then(
        (response) => {
          commit(SET_EMPLOYEE_COMPANY, response.data);
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [FETCH_EMPLOYEE_TICKET]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      employeTicket([payload]).then(
        (response) => {
          commit(SET_EMPLOYEE_TICKET, response.data);
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [PUT_EMPLOYEE_COMPANY_RENT](_, { employeeId, payload }) {
    return new Promise((resolve, reject) => {
      saveEmployeeCompanyRent(employeeId, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_EMPLOYEE_COMPANY_RENT](_, { employeeId, companyId }) {
    return new Promise((resolve, reject) => {
      employeeCompanyRent(employeeId, companyId)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default store;
