<template>
  <a-card style="height: 140px">
    <a-row type="flex" justify="space-between" align="middle">
      <a-col class="mb-2 pb-4">
        <a-typography-text class="text-gray-8 fw-semibold">
          Trabajo terminado
          <a-tooltip>
            <template #title>Solicitudes ejecutadas y canceladas</template>
            <InfoCircleOutlined />
          </a-tooltip>
        </a-typography-text>
      </a-col>
    </a-row>
    <a-row class="mb-4" type="flex" justify="space-between" align="bottom">
      <a-col :span="24">
        <div>
          <a-progress stroke-linecap="square" :percent="value" />
        </div>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { InfoCircleOutlined } from '@ant-design/icons-vue';
export default {
  components: {
    InfoCircleOutlined
  },
  props: {
    value: {
      type: Number,
      default: 0
    }
  }
};
</script>
