<template>
  <div>
    <a-modal
      :visible="modal_prop.visible"
      @cancel="closeModal"
      :closable="!modal_prop.loading_buttons"
      :maskClosable="!modal_prop.loading_buttons"
      title="Asociar nº de póliza"
    >
      <a-skeleton
        :loading="modal_prop.loading_data"
        :active="modal_prop.loading_data"
      >
        <p>Revisa que los datos ingresados esten correctos.</p>

        <a-descriptions layout="horizontal" bordered>
          <a-descriptions-item :span="3" label="Nº de factura">
            {{ policy_information.invoice_number }}
          </a-descriptions-item>
          <a-descriptions-item :span="3" label="Glosa">
            {{ policy_information.note }}
          </a-descriptions-item>
          <a-descriptions-item :span="3" label="PDF">
            <b
              class="pdf-viewer"
              @click="openPdfViewer(policy_information.pdf_path)"
              >Ver PDF</b
            >
          </a-descriptions-item>
        </a-descriptions>

        <a-form
          :model="form_state"
          :rules="rules"
          ref="form_ref"
          layout="vertical"
          class="mt-3"
        >
          <a-form-item label="Selecciona póliza" name="selected_policies">
            <a-checkbox-group
              v-model:value="form_state.selected_policies"
              :options="policy_information.policies"
            />
          </a-form-item>
        </a-form>
      </a-skeleton>

      <template #footer>
        <a-button :disabled="modal_prop.loading_buttons" @click="closeModal"
          >Cancelar</a-button
        >
        <a-button
          type="primary"
          :loading="modal_prop.loading_buttons"
          @click="formValidation"
          >Crear</a-button
        >
      </template>
    </a-modal>

    <pdf-viewer ref="pdf_ref" />
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import {
  FETCH_INVOICE_POLICIES_BY_ID,
  GET_INVOICE_POLICIES,
  FETCH_INVOICE,
  GET_INVOICE,
  POST_INVOICE_POLICIES
} from '../../../../store/types';
import notification from '../../../../utils/notifications';
import PDFViewer from '../../../../components/PDFViewer.vue';

export default {
  components: {
    'pdf-viewer': PDFViewer
  },
  setup(_, { emit }) {
    // * Store
    const store = useStore();

    // * Variables
    const modal_prop = reactive({
      visible: false,
      loading_data: false,
      loading_buttons: false
    });
    const policy_information = reactive({
      invoice_number: '',
      note: '',
      pdf_path: '',
      policies: []
    });
    const form_ref = ref();
    const form_state = reactive({
      selected_policies: []
    });
    const rules = {
      selected_policies: [
        {
          required: true,
          message: 'Seleccione una o mas polizas',
          type: 'array'
        }
      ]
    };
    const insurance_id = ref(null);
    const collection_form_data = reactive({
      collection_id: 0,
      insurance_id: 0,
      type: ''
    });

    // * Funciones
    const openModal = (id) => {
      modal_prop.visible = true;
      getPolicyInformation(id);
    };

    const resetForm = () => {
      form_ref.value.resetFields();
    };

    const closeModal = () => {
      modal_prop.visible = false;
      resetForm();
    };

    const emitPolicyAssociation = () => {
      emit('policyAssociation', true);
    };

    const getPolicyInformation = async (id) => {
      insurance_id.value = id;
      form_state.selected_policies = [];

      modal_prop.loading_data = true;
      modal_prop.loading_buttons = true;

      try {
        await store.dispatch(FETCH_INVOICE_POLICIES_BY_ID, id);
        await store.dispatch(FETCH_INVOICE, id);

        const {
          folio,
          objectUrl,
          firstItemDetail,
          collectionGroupId,
          insuranceCompanyIdSender,
          collectionType
        } = store.getters[GET_INVOICE];

        collection_form_data.collection_id = collectionGroupId;
        collection_form_data.insurance_id = insuranceCompanyIdSender;
        collection_form_data.type = collectionType;

        policy_information.invoice_number = folio != null ? `#${folio}` : '';
        policy_information.pdf_path = objectUrl != null ? objectUrl : '#';
        policy_information.note =
          firstItemDetail != null ? firstItemDetail : '';

        policy_information.policies = store.getters[GET_INVOICE_POLICIES].map(
          (obj) => {
            if (obj.selected) {
              form_state.selected_policies.push(obj.id);
            }

            return {
              label: obj.numberPolicy,
              value: obj.id
            };
          }
        );
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }

      modal_prop.loading_data = false;
      modal_prop.loading_buttons = false;
    };

    const formValidation = async () => {
      modal_prop.loading_buttons = true;

      try {
        const form_resp = await form_ref.value.validate();

        await store.dispatch(POST_INVOICE_POLICIES, {
          id: insurance_id.value,
          payload: {
            policiesId: form_resp.selected_policies
          }
        });

        emitPolicyAssociation();

        notification('success', 'Correcto', 'Pólizas asociadas correctamente');
        closeModal();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }

      modal_prop.loading_buttons = false;
    };

    // * PDFViewer
    const pdf_ref = ref();
    const openPdfViewer = (url) => {
      pdf_ref.value.openModal(url);
    };

    return {
      modal_prop,
      policy_information,
      form_state,
      form_ref,
      rules,
      formValidation,
      openModal,
      closeModal,
      pdf_ref,
      openPdfViewer
    };
  }
};
</script>

<style scoped>
.pdf-viewer {
  color: #2521e5;
  cursor: pointer;
}
</style>
