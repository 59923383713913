<template>
  <div>
    <a-table
      :dataSource="dataTable"
      :columns="table_columns"
      :pagination="paginations"
      @change="changeCurrentPage"
      :loading="loadingData"
      :key="dataTable.id"
    >
      <template #idRender="{ text: id }">
        <p># {{ id }}</p>
      </template>

      <template #filterDropdown="{ column, confirm, clearFilters }">
        <div style="padding: 8px">
          <a-input
            v-model:value="filter_values[column.key]"
            :placeholder="`Buscar por ${column.title}`"
            @pressEnter="
              handleSearch(column.dataIndex, filter_values[column.key], confirm)
            "
            style="width: 188px; margin-bottom: 8px; display: block"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="
              handleSearch(column.dataIndex, filter_values[column.key], confirm)
            "
          >
            <template #icon><search-outlined /></template>
            Buscar
          </a-button>

          <a-button
            size="small"
            style="width: 90px"
            @click="handleColumnReset(column.dataIndex, clearFilters)"
          >
            Limpiar
          </a-button>
        </div>
      </template>

      <template #state="{ text }">
        <tag :color="getColorState(text)">{{ text?.toUpperCase() }}</tag>
      </template>

      <template #filterCollectionStates="{ confirm, column, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-checkbox-group v-model:value="collection_states">
            <a-row>
              <a-col
                :span="24"
                v-for="(state, index) in collection_options"
                :key="index"
              >
                <a-checkbox :value="state.value">{{ state.label }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>

          <div
            class="mt-4 p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  collection_states.join(','),
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <template #filterBillingStates="{ confirm, column, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-checkbox-group v-model:value="billing_states">
            <a-row>
              <a-col
                :span="24"
                v-for="(state, index) in billing_options"
                :key="index"
              >
                <a-checkbox :value="state.value">{{ state.label }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>

          <div
            class="mt-4 p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  billing_states.join(','),
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <template #filterIcon="{ column }">
        <search-outlined
          :style="{
            color:
              search_value[column.dataIndex] === undefined ||
              search_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>

      <template #filterIconCollectionState="{ column }">
        <filter-outlined
          :style="{
            color:
              search_value[column.dataIndex] === undefined ||
              search_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>

      <template #filterDate="{ column, confirm, clearFilters }">
        <div style="padding: 8px">
          <a-date-picker
            :placeholder="`${column.title}`"
            v-model:value="filter_values[column.key]"
            @pressEnter="
              handleSearch(column.dataIndex, filter_values[column.key], confirm)
            "
            style="width: 188px; margin-bottom: 8px; display: block"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="
              handleSearch(column.dataIndex, filter_values[column.key], confirm)
            "
          >
            <template #icon><search-outlined /></template>
            Buscar
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleColumnReset(column.dataIndex, clearFilters)"
          >
            Limpiar
          </a-button>
        </div>
      </template>

      <template #filterIconBillingState="{ column }">
        <filter-outlined
          :style="{
            color:
              search_value[column.dataIndex] === undefined ||
              search_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>

      <template #actionDetails="{ record }">
        <slot :record="record" name="details"> </slot>
      </template>
      <template #collectionRender="{ record }">
        <slot :record="record" name="invoiceNumber"> </slot>
      </template>
    </a-table>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { SearchOutlined, FilterOutlined } from '@ant-design/icons-vue';
// import { useStore } from 'vuex';

import Tag from '../Tags.vue';

import stateColor from '../../utils/collectionBillingStateColor';
import parseDate from '@/utils/dateFormatter';

export default {
  name: 'CollectionStockTable',
  components: {
    'search-outlined': SearchOutlined,
    'filter-outlined': FilterOutlined,
    tag: Tag
  },
  props: ['dataTable', 'paginations', 'loadingData', 'routeName'],
  setup(props) {
    const formater = parseDate;
    // * VUEX
    // const store = useStore();

    // * Vue router ### (1) ###
    const route = useRoute();
    const router = useRouter();

    // * Obtener el color del estado
    const getColorState = (value) => {
      return stateColor(value);
    };

    // * Configuracion de la tabla
    const table_columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'idRender',
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'N° factura',
        dataIndex: 'numeroFactura',
        key: 'numeroFactura',
        slots: {
          customRender: 'collectionRender',
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'N° pólizas',
        dataIndex: 'numeroPoliza',
        key: 'numeroPoliza',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Fecha de emisión',
        dataIndex: 'fechaEmision',
        key: 'fechaEmision',
        slots: {
          filterDropdown: 'filterDate',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Estado',
        dataIndex: 'state',
        key: 'state',
        slots: {
          customRender: 'state',
          filterDropdown: 'filterCollectionStates',
          filterIcon: 'filterIconCollectionState'
        }
      },
      {
        title: 'Acción',
        dataIndex: 'action',
        key: 'action',
        slots: {
          customRender: 'actionDetails'
        }
      }
    ];

    // * Estados
    const collection_states = ref([]);
    const billing_states = ref([]);

    const collection_options = ref([
      {
        label: 'Pagado',
        value: 1
      },
      {
        label: 'Pendiente de pago',
        value: 2
      }
      // {
      //   label: 'Aprobada',
      //   value: 4
      // },
    ]);

    const billing_options = ref([
      {
        label: 'Sin emitir',
        value: 1
      },
      {
        label: 'Emitida',
        value: 2
      },
      {
        label: 'Pagada',
        value: 3
      }
    ]);

    // * Redireccion al momento de hacer un filtro
    const filter_values = ref({}); // Objeto que guarda los valores de los filtros
    const search_value = ref({}); // Objeto que guarda los valores de los cuales se hace la busqueda por filtro

    // Cuando se use un filtro de hace una redireccion de la pagina
    const filterRedirectionNewParam = (key, param) => {
      search_value.value[key] = param;

      if (key != 'page') {
        search_value.value['page'] = 1;
      }

      router.replace({
        name: props.routeName,
        query: search_value.value
      });
    };

    // Redireccion reseteando o sacando un parametro
    const filterRedirectionResetColumn = (key) => {
      delete search_value.value[key];
      delete filter_values.value[key];

      router.replace({
        name: props.routeName,
        query: search_value.value
      });
    };

    const changeCurrentPage = (page) => {
      filterRedirectionNewParam('page', page.current);
    };

    // Busqueda por columna
    const handleSearch = (column, value, confirm) => {
      if (value != '') {
        // veo si es period para formatear la fecha y mostrarla en la url al buscarla y si no los demas
        // busquen normales
        if (column == 'fechaEmision') {
          const d = new Date(value);
          const datestring = `${String(d.getDate()).padStart(2, '0')}-${String(
            d.getMonth() + 1
          ).padStart(2, '0')}-${d.getFullYear()}`;
          filterRedirectionNewParam(column, datestring);
        } else {
          filterRedirectionNewParam(column, value);
        }

        // Hago la redireccion con el nuevo valor

        // Hago que se cierre el mini modal de busqueda
        confirm();
      }
    };

    // Limpiar columna
    const handleColumnReset = (column, resetConfirm) => {
      filterRedirectionResetColumn(column);
      resetConfirm();
    };

    // * Recarga de pagina, obtencion de los valores de la url a filtros ### (4) ###
    onMounted(() => {
      // Al momento de cargar la pagina, voy a ver que parametros vienen, con eso voy a dejar los valores de cada uno de los filtros realizados ahi
      const keys = Object.keys(route.query);

      keys.forEach((element) => {
        filter_values.value[element] = route.query[element]; // Le paso valor al los inputs para que muestre que es lo que busco anteriormente
        search_value.value[element] = route.query[element]; // Le paso valor al objeto de la busqueda en caso de hacer otra consulta
      });
    });

    return {
      formater,
      table_columns,
      filter_values,
      search_value,
      collection_states,
      collection_options,
      billing_states,
      billing_options,
      filterRedirectionNewParam,
      filterRedirectionResetColumn,
      changeCurrentPage,
      handleSearch,
      handleColumnReset,
      getColorState
    };
  }
};
</script>

<style scoped>
.request_icon {
  color: #2521e5;
}
</style>
