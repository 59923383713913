<template>
  <div>
    <a-modal v-model:visible="modal_props.visibility" title="Envio de Cobranza" @cancel="closeModal">
      <a-typography-text class="fs-lg text-gray">
        {{
          dataInfoDetails.type == 'COMPANY'
          ? `La empresa ${dataInfoDetails.company.businessName}`
          : '' || dataInfoDetails.type == 'SUBHOLDING'
            ? `El Subholding ${dataInfoDetails.subHolding.name}`
            : '' || dataInfoDetails.type == 'HOLDING'
              ? `El holding ${dataInfoDetails.holding.businessName}`
              : ''
        }}
        reconoce {{ dataResumeRRHH.length }} contrapartes para el envió de sus
        cobranzas, escribe individualmente los correos para el envío según
        {{
          dataInfoDetails.type == 'COMPANY'
          ? 'empresas'
          : '' || dataInfoDetails.type == 'SUBHOLDING'
            ? 'subholding'
            : '' || dataInfoDetails.type == 'HOLDING'
              ? 'holding'
              : ''
        }}.
      </a-typography-text>
      <a-card class="mt-1" :bodyStyle="'padding:1;'">
        <a-tag color="blue" class="fs-md text-gray mb-2"><b>Contraparte: {{ contraparteId }}</b></a-tag>
        <a-row>
          <a-col>
            <a-typography-text class=""><b>Para:</b> </a-typography-text>
          </a-col>
          <a-col class="ml-1">
            <p style="white-space: normal">
              {{ `${dataSendRRHH.mails.map((e) => e).join(', ')}` }}
            </p>
          </a-col>
        </a-row>

        <a-row>
          <a-col>
            <a-typography-text class="text-gray"><b>Asunto:</b></a-typography-text>
          </a-col>
          <a-col class="ml-1">
            <p style="white-space: normal">{{ dataSendRRHH.subject }}</p>
          </a-col>
        </a-row>
      </a-card>

      <a-typography-title :level="5"> Mensaje a cliente </a-typography-title>
      <a-typography-text>
        Escribe un mensaje a tu cliente para informar sobre la nómina de
        cobranza
      </a-typography-text>
      <a-form class="mt-2" name="form" layout="vertical" ref="formref" :model="formState" :rules="rules">
        <a-form-item name="message">
          <a-textarea v-model:value="formState.message" placeholder="Escribe Aquí..." :rows="10">
          </a-textarea>
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button @click="closeModal">Volver atrás</a-button>
        <a-button :disabled="textAreaEdit" type="primary" @click="onOkNext">Siguiente</a-button>
      </template>
    </a-modal>

    <!-- RESUME -->
    <a-modal v-model:visible="modalResumeCollectionSummary" title="Resumen de envío de cobranzas" @cancel="closeModal">
      <div>
        <p>Revisa que los datos ingresados esten correctos.</p>

        <div v-for="(resume, i) in dataResumeRRHH" :key="i">
          <a-card :bodyStyle="'padding:0;'" :bordered="false" class="ticket_details">
            <template #title>
              <a-typography-title :level="5" style="color: blue">{{
                `Contraparte ${i + 1}`
              }}</a-typography-title>
              <a-typography-text> Para: </a-typography-text>
              <p style="white-space: normal">
                {{ resume.mails.map((e) => e).join(', ') }}
              </p>
            </template>
            <a-descriptions class="" bordered>
              <template v-for="(a, index) in dataResumeDetail" :key="index">
                <a-descriptions-item :label="a.key" :span="3">
                  {{
                    a.key == 'Nº de nóminas'
                    ? resume.quantities.collections
                    : '' || a.key == 'Nº de empresas'
                      ? resume.quantities.companies
                      : '' || a.key == 'Nº de facturas'
                        ? resume.quantities.invoices
                        : ''
                  }}
                </a-descriptions-item>
              </template>
            </a-descriptions>
          </a-card>
        </div>
      </div>

      <template #footer>
        <a-button :loading="loadingSendCollections" @click="cancelModalResume">Cancelar</a-button>
        <a-button type="primary" @click="SendToRRHH" :loading="loadingSendCollections">Enviar Cobranzas</a-button>
      </template>
    </a-modal>
    <a-modal v-model:visible="show_sucess" centered footer="" :maskClosable="false">
      <a-result centered status="success" title="Cobranza Enviada" :sub-title="formState.text" />
    </a-modal>
  </div>
</template>

<script>
import {
  computed,
  reactive,
  ref,
  watch,
  createVNode,
  watchEffect
  // onMounted
} from 'vue';
import {
  // notification,
  Modal
} from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  POST_COLLECTION_DATA_SEND_RRHH,
  FETCH_COLLECTION_GROUP_COLLECTIONS
} from '../../../store/types';
import EventBus from '../../../utils/events';

export default {
  name: 'CollectionSendRRHH',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    dataSend: {
      type: Object,
      default: {}
    },
    dataInfo: {
      type: Object,
      default: {}
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const payloadForm = ref({
      groups: []
    });
    const show_sucess = ref(false);
    const currentStepModal = ref(0);
    const contraparteId = ref(1);
    const modalResumeCollectionSummary = ref(false);
    // * Props
    const insurance_data = computed(() => props?.modelValue);
    const dataSendRRHH = computed(
      () => props?.dataSend?.groups[currentStepModal.value]
    );
    const dataResumeRRHH = computed(() => props?.dataSend?.groups);
    const dataInfoDetails = computed(() => props?.dataInfo);
    const datePeriod = ref(null);
    const formState = reactive({
      message: '',
      text: ''
    });
    const textAreaEdit = ref(false);
    const loadingSendCollections = ref(false);

    const closeModal = () => {
      Modal.confirm({
        title: () => '¿Quieres cerrar esta ventana?',
        content: () =>
          'Al cerrar esta ventana los mensajes no seran enviados y se perderá la información en los correos',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => 'Confirmar',
        cancelText: () => 'Cancelar',
        onOk() {
          modal_props.visibility = false;
          currentStepModal.value = 0;
          contraparteId.value = 1;
          payloadForm.value.groups = [];
        },
        onCancel() {
          modal_props.visibility = true;
        }
      });
      modal_props.visibility = false;
      emitResponse();
    };

    const emitResponse = () => {
      emit('update:modelValue', false);
    };

    // * Funciones
    const onOkNext = (e) => {
      // if (formState.message == '') {
      //   notification.error({
      //     message: 'Mensaje cliente',
      //     description: 'Para avanzar debes escribir un mensaje a tu cliente especificando los detalles de la cobranza',
      //     duration: 10,
      //   });
      // };
      // modal_props.visibility = false;
      if (contraparteId.value < dataResumeRRHH.value.length) {
        contraparteId.value += 1;
        currentStepModal.value += 1;
        modal_props.visibility = true;
        // currentStepModal.value=+1;
        // modalResumeCollectionSummary.value=false;
      } else {
        modal_props.visibility = false;
        emitResponse();
        modalResumeCollectionSummary.value = true;
      }
      if (currentStepModal.value < dataResumeRRHH.value.length) {
        payloadForm.value.groups.push({
          collectionsIds: dataSendRRHH.value.collectionsIds,
          companiesIds: dataSendRRHH.value.companiesIds,
          emails: dataSendRRHH.value.mails,
          message: formState.message
        });
      }

      emit('onOk', e);
    };
    const cancelModalResume = () => {
      Modal.confirm({
        title: () => '¿Quieres cerrar esta ventana?',
        content: () =>
          'Al cerrar esta ventana los mensajes no seran enviados y se perderá la información en los correos',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => 'Confirmar',
        cancelText: () => 'Cancelar',
        onOk() {
          modalResumeCollectionSummary.value = false;
          currentStepModal.value = 0;
          contraparteId.value = 1;
          payloadForm.value.groups = [];
        },
        onCancel() {
          modalResumeCollectionSummary.value = true;
        }
      });
      modalResumeCollectionSummary.value = false;
    };
    const SendToRRHH = async () => {
      loadingSendCollections.value = true;
      EventBus.emit('loadingSendCollections', loadingSendCollections.value);
      try {
        const resp = await store.dispatch(POST_COLLECTION_DATA_SEND_RRHH, {
          id: route.params.id,
          payload: payloadForm.value
        });
        if (resp.status == 201 || resp.status == 200) {
          modalResumeCollectionSummary.value = false;
          loadingSendCollections.value = false;
          show_sucess.value = true;
          formState.text = `La cobranza correspondiente al periodo ${datePeriod.value
          } ${dataInfoDetails.value.type == 'SUBHOLDING'
            ? 'para el subholding'
            : '' || dataInfoDetails.value.type == 'HOLDING'
              ? 'para el holding'
              : '' || dataInfoDetails.value.type == 'COMPANY'
                ? 'para la compañía'
                : ''
          } ${dataInfoDetails.value.type == 'SUBHOLDING'
            ? dataInfoDetails.value.subHolding.name
            : '' || dataInfoDetails.value.type == 'HOLDING'
              ? dataInfoDetails.value.holding.businessName
              : '' || dataInfoDetails.value.type == 'COMPANY'
                ? dataInfoDetails.value.company.businessName
                : ''
          } ha sido enviada con éxito.`;
          currentStepModal.value = 0;
          contraparteId.value = 1;
        }
        await store.dispatch(
          FETCH_COLLECTION_GROUP_COLLECTIONS,
          route.params.id
        );
        EventBus.emit('UpdateData');
        EventBus.emit('loadingSendCollections', loadingSendCollections.value);
      } catch (error) {
        modalResumeCollectionSummary.value = false;
        loadingSendCollections.value = false;
        console.log('error', error);
      }
    };
    watch(
      () => props.modelValue,
      (newVal) => {
        modal_props.visibility = newVal;
      }
    );

    const modal_props = reactive({
      visibility: false,
      title: 'Resumen formulario inicio de cobranza'
    });
    // Agregar ceros antes del numero
    const addLeadingZeros = (num, totalLength) => {
      return String(num).padStart(totalLength, '0');
    };

    const dataResumeDetail = ref();
    watch(formState, (val) => {
      const found = val.message.match('{Texto escrito por broker}');
      if (found) {
        textAreaEdit.value = true;
      } else {
        textAreaEdit.value = false;
      }
    });
    watchEffect(() => {
      dataResumeDetail.value = [
        {
          key: 'Nº de nóminas',
          value: ''
        },
        {
          key: 'Nº de empresas',
          value: ''
        },
        {
          key: 'Nº de facturas',
          value: ''
        }
      ];
      // Datos del ticket
      const date = dataInfoDetails?.value.period?.split('-');
      let period = '';
      if (date != undefined) {
        period = `${date[0]}-${addLeadingZeros(date[1], 2)}`;
      }
      datePeriod.value = period;

      if (modal_props.visibility == true) {
        formState.message = `Estimado cliente:\n\nAdjuntamos cobranzas del periodo ${datePeriod.value
        } ${dataSendRRHH.value.companies.length > 1
          ? 'de las empresas'
          : 'de la empresa'
        } ${dataSendRRHH.value.companies.join(
          ', '
        )}.\n\n{Texto escrito por broker}\n\nUn cordial saludo`;
      } else {
        formState.messageText = '';
      }
    });
    const checkText = async (rule, value) => {
      const found = value.match('{Texto escrito por broker}');
      if (found) {
        textAreaEdit.value = true;
        return Promise.reject('Edite mensaje broker');
      } else if (value == '') {
        return Promise.reject('ingrese mensaje');
      }
      return Promise.resolve();
    };
    const rules = {
      message: [
        {
          required: true,
          validator: checkText,
          type: 'string'
        }
      ]
    };

    return {
      closeModal,
      modal_props,
      insurance_data,
      onOkNext,
      formState,
      contraparteId,
      currentStepModal,
      modalResumeCollectionSummary,
      cancelModalResume,
      show_sucess,
      SendToRRHH,
      dataSendRRHH,
      dataResumeRRHH,
      dataInfoDetails,
      dataResumeDetail,
      payloadForm,
      loadingSendCollections,
      datePeriod,
      textAreaEdit,
      rules
    };
  }
};
</script>
