<template>
  <div>
    <a-upload
      :multiple="false"
      :file-list="file_list"
      :customRequest="uploadRequest"
    >
      <a-button
        type="default"
        style="border: 1px solid #2521e5; color: #2521e5"
      >
        <plus-circle-outline />
        Cargar Nómina
      </a-button>
    </a-upload>
  </div>
</template>

<script>
import { PlusCircleOutlined } from '@ant-design/icons-vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import notification from '@/utils/notifications';
import { POST_COLLECTION_TICKET_PAYROLL } from '@/store/types';

export default {
  props: {
    ticketId: {
      type: [String, Number],
      default: 1
    }
  },
  components: {
    'plus-circle-outline': PlusCircleOutlined
  },
  setup(props, { emit }) {
    // * store
    const store = useStore();

    // * Props
    const type = computed(() => props.buttonType);
    const style = computed(() => props.customStyle);

    // * Variables
    const file_list = ref([]);

    // * Metodos
    const uploadRequest = async ({ file }) => {
      emit('payrollUploading', true);

      // Dejo vacio el file_list solo para que no se muestre hacia abajo la lista de archivos que se estan cargando
      file_list.value = [];

      // form
      const formData = new FormData();
      formData.append('file', file);

      try {
        await store.dispatch(POST_COLLECTION_TICKET_PAYROLL, {
          id: props.ticketId,
          payload: formData
        });
        notification('success', 'Correcto', 'Nómina cargada');
        emit('payrollUploaded', true);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }

      emit('payrollUploading', false);
    };

    return {
      file_list,
      type,
      style,
      uploadRequest
    };
  }
};
</script>
1
