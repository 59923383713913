<template>
  <a-modal v-model:visible="visible" :title="modalTitle" @cancel="closeModal">
    <a-form
      name="form"
      layout="vertical"
      ref="form_ref"
      :model="form_state"
      :rules="rules"
    >
      <a-form-item name="calculation_type">
        <a-radio-group v-model:value="form_state.calculation_type">
          <a-radio class="radio-prime-option" :value="1"
            >Capital variable</a-radio
          >
          <a-radio class="mt-2 radio-prime-option" :value="2"
            >Capital fijo</a-radio
          >
          <a-radio class="mt-2 radio-prime-option" :value="3"
            >Tramo de cargas</a-radio
          >
        </a-radio-group>
      </a-form-item>

      <a-form-item
        v-if="
          form_state.calculation_type !== 3 &&
          form_state.calculation_type !== null
        "
        ref="rate_value"
        name="rate_value"
        label="Valor de la tasa:"
      >
        <a-input-number
          :min="0"
          placeholder="Ingrese valor de la tasa"
          v-model:value="form_state.rate_value"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item
        v-if="form_state.calculation_type === 1"
        ref="times_rent"
        label="Veces renta:"
        name="times_rent"
      >
        <a-input-number
          :min="0"
          placeholder="Ingrese valor de veces renta"
          v-model:value="form_state.times_rent"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item
        v-if="form_state.calculation_type === 2"
        ref="fixed_capital"
        label="Monto de capital fijo:"
        name="fixed_capital"
      >
        <a-input-number
          :min="0"
          placeholder="Ingrese valor de capital fijo"
          v-model:value="form_state.fixed_capital"
          style="width: 100%"
        />
      </a-form-item>

      <div v-if="form_state.calculation_type === 3">
        <a-form-item ref="employee" name="employee" label="Sólo asegurado">
          <a-input-number
            :min="0"
            placeholder="Ingrese valor"
            v-model:value="form_state.employee"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item
          ref="first_dependant"
          name="first_dependant"
          label="Asegurado + 1 carga:"
        >
          <a-input-number
            :min="0"
            placeholder="Ingrese valor"
            v-model:value="form_state.first_dependant"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item
          ref="second_dependant"
          name="second_dependant"
          label="Asegurado + 2 cargas:"
        >
          <a-input-number
            :min="0"
            placeholder="Ingrese valor"
            v-model:value="form_state.second_dependant"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item
          ref="third_dependant"
          name="third_dependant"
          label="Asegurado + 3 cargas:"
        >
          <a-input-number
            :min="0"
            placeholder="Ingrese valor"
            v-model:value="form_state.third_dependant"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item
          ref="fourth_dependant"
          name="fourth_dependant"
          label="Asegurado + 4 cargas:"
        >
          <a-input-number
            :min="0"
            placeholder="Ingrese valor"
            v-model:value="form_state.fourth_dependant"
            style="width: 100%"
          />
        </a-form-item>
      </div>
    </a-form>

    <template #footer>
      <a-button @click="closeModal" :disabled="loading">Cancelar</a-button>
      <a-button type="primary" @click="formSubmit" :loading="loading"
        >Aceptar</a-button
      >
    </template>
  </a-modal>
</template>

<script setup>
import { ref, defineExpose, defineEmits, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import notification from '../../../utils/notifications';
import { PUT_GROUP_COMPANY_PREMIUM_CALC } from '@/store/types';

const emit = defineEmits(['completed']);

const store = useStore();
const visible = ref(false);
const form_ref = ref();
const modalTitle = ref();
const form_state = reactive({
  calculation_type: null,
  rate_value: null,
  times_rent: null,
  fixed_capital: null,
  employee: null,
  first_dependant: null,
  second_dependant: null,
  third_dependant: null,
  fourth_dependant: null
});
const rule_value = [
  {
    required: true,
    message: 'Ingrese un valor',
    type: 'number',
    trigger: 'change'
  }
];
const rules = ref([]);
const group_id = ref();
const coverage_id = ref();

const openModal = (values) => {
  const { id: coverageId, name, method, groupCompanyId, parameters } = values;
  modalTitle.value = `Configuración de prima: ${name}`;
  group_id.value = groupCompanyId;
  coverage_id.value = coverageId;

  switch (method) {
    case 'CAPITAL_FIXED_INCOME':
      form_state.calculation_type = 1;
      form_state.rate_value = parameters.premiumRate;
      form_state.times_rent = parameters.incomeMultiplier;
      break;
    case 'CAPITAL_FIXED_AMOUNT':
      form_state.calculation_type = 2;
      form_state.rate_value = parameters.premiumRate;
      form_state.fixed_capital = parameters.capitalAmount;
      break;
    case 'AMOUNT_BY_DEPENDENT_RANGES':
      form_state.calculation_type = 3;
      form_state.employee = parameters.dependentRanges[0].premiumAmount;
      form_state.first_dependant = parameters.dependentRanges[1].premiumAmount;
      form_state.second_dependant = parameters.dependentRanges[2].premiumAmount;
      form_state.third_dependant = parameters.dependentRanges[3].premiumAmount;
      form_state.fourth_dependant = parameters.dependentRanges[4].premiumAmount;
      break;
    default:
      form_state.calculation_type = null;
      form_state.rate_value = null;
      form_state.times_rent = null;
      form_state.fixed_capital = null;
      form_state.employee = null;
      form_state.first_dependant = null;
      form_state.second_dependant = null;
      form_state.third_dependant = null;
      form_state.fourth_dependant = null;
      break;
  }

  visible.value = true;
};

const closeModal = () => {
  visible.value = false;
  form_ref.value.resetFields();
};

const formSubmit = () => {
  form_ref.value.validate().then((resp) => {
    if (resp.calculation_type === 1) {
      variableCapitalPayload();
    } else if (resp.calculation_type === 2) {
      fixedCapitalPayload();
    } else {
      dependantsSectionPayload();
    }
  });
};

const variableCapitalPayload = async () => {
  const payload = {
    method: 'CAPITAL_FIXED_INCOME',
    parameters: {
      premiumRate: form_state.rate_value,
      incomeMultiplier: form_state.times_rent
    }
  };
  postInformation(payload);
};

const fixedCapitalPayload = async () => {
  const payload = {
    method: 'CAPITAL_FIXED_AMOUNT',
    parameters: {
      premiumRate: form_state.rate_value,
      fixedAmount: form_state.fixed_capital
    }
  };
  postInformation(payload);
};

const dependantsSectionPayload = async () => {
  const payload = {
    method: 'AMOUNT_BY_DEPENDENT_RANGES',
    parameters: {
      dependentRanges: [
        {
          id: 1,
          premiumAmount: form_state.employee
        },
        {
          id: 2,
          premiumAmount: form_state.first_dependant
        },
        {
          id: 3,
          premiumAmount: form_state.second_dependant
        },
        {
          id: 4,
          premiumAmount: form_state.third_dependant
        },
        {
          id: 5,
          premiumAmount: form_state.fourth_dependant
        }
      ]
    }
  };
  postInformation(payload);
};

const loading = ref(false);
const postInformation = async (payload) => {
  loading.value = true;
  try {
    await store.dispatch(PUT_GROUP_COMPANY_PREMIUM_CALC, {
      groupId: group_id.value,
      coverageId: coverage_id.value,
      payload
    });
    notification('success', 'Correcto', 'Cobertura configurada correctamente');
    closeModal();
    completedCoverate();
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
  loading.value = false;
};

const setRuleValues = () => {
  const keys = Object.keys(form_state);
  keys.forEach((key) => {
    rules.value[key] = rule_value;
  });
};

const completedCoverate = () => {
  emit('completed', true);
};

onMounted(() => {
  setRuleValues();
});

defineExpose({
  openModal
});
</script>

<style scoped>
.radio-prime-option {
  display: block;
}
</style>
