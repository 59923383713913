<template>
  <div>
    <a-row type="flex" :gutter="8">
      <a-col flex="auto">
        <a-card style="height: 140px">
          <a-row type="flex" justify="space-between" align="middle">
            <a-col>
              <a-typography-title :level="4" class="text-gray-8 mb-0">
                Resumen total
              </a-typography-title>
            </a-col>
          </a-row>
          <a-row
            class="mb-4"
            type="flex"
            justify="space-between"
            align="bottom"
          >
            <a-col>
              <a-space>
                <a-button
                  :type="values_mode == 0 ? 'primary' : 'default'"
                  @click="values_mode = 0"
                >
                  Porcentajes
                </a-button>
                <a-button
                  :type="values_mode == 1 ? 'primary' : 'default'"
                  @click="values_mode = 1"
                >
                  Números
                </a-button>
              </a-space>
            </a-col>
            <a-col class="d-flex">
              <div>
                <a-typography-text class="text-gray-8 fs-xl d-block">
                  {{ resume_values.solicitudes }}
                </a-typography-text>
                <a-typography-text class="text-gray-8 fs-sm d-block">
                  Solicitudes
                </a-typography-text>
              </div>
              <a-divider type="vertical" style="height: 53.28px" class="mx-4" />
              <div>
                <a-typography-text class="text-gray-8 fs-xl d-block">
                  {{ resume_values.ejecutadas }}
                </a-typography-text>
                <a-typography-text class="text-gray-8 fs-sm d-block">
                  Ejecutadas
                </a-typography-text>
              </div>
              <a-divider type="vertical" style="height: 53.28px" class="mx-4" />
              <div>
                <a-typography-text class="text-gray-8 fs-xl d-block">
                  {{ resume_values.revision }}
                </a-typography-text>
                <a-typography-text class="text-gray-8 fs-sm d-block">
                  En progreso
                </a-typography-text>
              </div>
              <a-divider type="vertical" style="height: 53.28px" class="mx-4" />
              <div>
                <a-typography-text class="text-gray-8 fs-xl d-block">
                  {{ resume_values.canceladas }}
                </a-typography-text>
                <a-typography-text class="text-gray-8 fs-sm d-block">
                  Canceladas
                </a-typography-text>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col flex="auto">
        <a-card style="height: 140px">
          <a-row type="flex" justify="space-between" align="middle">
            <a-col class="mb-2 pb-4">
              <a-typography-text class="text-gray-8 fw-semibold">
                Trabajo terminado
                <a-tooltip>
                  <template #title>
                    Solicitudes ejecutadas y canceladas
                  </template>
                  <InfoCircleOutlined />
                </a-tooltip>
              </a-typography-text>
            </a-col>
          </a-row>
          <a-row
            class="mb-4"
            type="flex"
            justify="space-between"
            align="bottom"
          >
            <a-col :span="24">
              <div>
                <a-progress
                  stroke-linecap="square"
                  :percent="resume_values.terminado"
                />
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { FETCH_TABLE_RESUME, GETTER_TICKET_TABLE_RESUME } from '../store/types';
import { InfoCircleOutlined } from '@ant-design/icons-vue';

export default {
  name: 'TableResumeTest',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    InfoCircleOutlined
  },
  setup(props) {
    // * Vuex
    const store = useStore();

    // * Props
    const resume_type = computed(() => props.type);

    // * Llamada principal de data
    watchEffect(async (onCleanUp) => {
      try {
        const ac = new AbortController();
        const signal = ac.signal;
        onCleanUp(() => ac.abort());
        await store.dispatch(FETCH_TABLE_RESUME, {
          payload: resume_type.value,
          signal
        });
      } catch (error) {
        console.log(error);
      }
    });

    // Calculo de porcentaje
    const calculatePorcentage = (value, total) => {
      return total > 0 ? Math.round((value * 100) / total) : 0;
    };

    // Modo como se muestra la data
    // # Porcentajes
    // # Numeros
    // La primera opcion es numeros
    const values_mode = ref(1);

    const resume_values = computed(() => {
      // Valores obtenidos desde el endpoint
      const values = store.getters[GETTER_TICKET_TABLE_RESUME];

      // Calculo de portencajes de "Trabajo terminado"
      const { ejecutadas, canceladas, tickets, revision } = values;
      // const finished = ((ejecutadas + canceladas) * 100) / tickets;
      const finished = calculatePorcentage(ejecutadas + canceladas, tickets);

      // Transformo todo a un objeto para mostrar los valores
      // Dependiendo del modo en el que esten
      const final = {
        solicitudes: tickets,
        ejecutadas:
          values_mode.value === 0
            ? `${calculatePorcentage(ejecutadas, tickets)}%`
            : ejecutadas,
        canceladas:
          values_mode.value === 0
            ? `${calculatePorcentage(canceladas, tickets)}%`
            : canceladas,
        revision:
          values_mode.value === 0
            ? `${calculatePorcentage(revision, tickets)}%`
            : revision,
        terminado: finished
      };

      return final;
    });

    return {
      resume_type,
      values_mode,
      resume_values
    };
  }
};
</script>
