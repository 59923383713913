<template>
  <a-card
    :class="{ 'redirection-card': disableRedirection === false }"
    style="height: 100%"
    @click="redirectionToSection(redirectionTo)"
  >
    <a-skeleton :loading="loading" :active="loading">
      <a-row type="flex" justify="space-between">
        <a-col>
          <a-typography-text class="fw-semibold text-gray-8 fs-6">
            {{ title }}
          </a-typography-text>
        </a-col>
      </a-row>

      <a-row class="py-4" type="flex" justify="space-between" align="middle">
        <a-col>
          <a-typography-text
            class="fw-semibold display-3 text-gray-9 d-block"
            style="line-height: 28px"
          >
            {{ quantity }}
          </a-typography-text>
          <a-typography-text
            class="text-gray-8 fs-xl d-block text-right"
            style="line-height: 64px"
          >
            {{ quantityDescription }}
          </a-typography-text>
        </a-col>
        <a-col>
          <a-progress
            type="circle"
            :strokeColor="'#2521E5'"
            :success="{ percent: 100, strokeColor: '#00DC92' }"
            :percent="percentage"
          />
        </a-col>
      </a-row>

      <div
        class="p-2"
        :class="{ 'state-redirection': disableRedirection === false }"
        v-for="detail in details"
        :key="detail.id"
        @click.stop="redirectionToSection(redirectionTo, detail.state)"
      >
        <a-row type="flex" justify="space-between" align="middle">
          <a-col>
            <a-typography-text class="text-gray-8">
              {{ detail.description }}
            </a-typography-text>
          </a-col>
          <a-col>
            <a-typography-text class="text-gray-8">
              {{ detail.total }}%
            </a-typography-text>
          </a-col>
        </a-row>
        <hr />
      </div>
    </a-skeleton>
  </a-card>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  props: {
    redirectionTo: {
      type: Object,
      default: {}
    },
    title: {
      type: String,
      default: 'Agregue un titulo'
    },
    icon: {
      type: String,
      default: 'fa-upload'
    },
    quantity: {
      type: [String, Number],
      default: 0
    },
    quantityDescription: {
      type: String,
      default: ''
    },
    percentage: {
      type: Number,
      default: 0
    },
    details: {
      type: Array,
      default: []
    },
    loading: {
      type: Boolean,
      default: false
    },
    disableRedirection: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useRouter();

    const redirectionToSection = (route, state) => {
      if (props.disableRedirection === false) {
        const params_objects = route;
        if (state != undefined) {
          params_objects.query = {
            ...params_objects.query,
            ...state
          };
        }
        router.push(params_objects);
      }
    };

    return {
      redirectionToSection
    };
  }
};
</script>

<style>
.state-redirection {
  border: 2px solid transparent;
}

.state-redirection:hover {
  border: #1310a4 2px solid;
  border-radius: 5px;
  transition: border-color 1s ease;
}
</style>
