/*
Obtencion del color del estado dependiendo del nombre del estado
*/

const getCollectionAndBillingStateColor = (value) => {
  switch (value && value.toUpperCase()) {
    // Formularios
    case 'CREADO':
      return 'blue';
    case 'EN PROCESO':
      return 'orange';
    case 'EN REPROCESO':
      return 'error';
    case 'CARGADO':
      return 'green';
    case 'CANCELAR':
      return 'red';

    // Cobranza
    case 'INICIADA':
      return 'blue';
    case 'BEWELL_VALIDATION':
      return 'warning';
    case 'VALIDACIÓN CORREDORA':
      return 'warning';
    case 'VALIDACION RRHH':
      return 'orange';
    case 'APROBADA':
      return 'green';

    // Reprocesos && solicitudes de stock cobranza y facturacion
    case 'CANCELADO':
      return 'red';
    case 'EN ASEGURADORA':
      return 'warning';
    case 'APROBADO':
      return 'green';
    case 'ERROR':
      return 'error';
    case 'VALIDO':
      return 'success';
    case 'MATCH':
      return 'success';
    case 'CUADRA':
      return 'success';
    case 'NO_MATCH':
      return 'error';
    case 'NO CUADRA':
      return 'error';
    case 'NO_PROCESSED':
      return 'red';
    case 'PROCESSING':
      return 'processing';
    case 'RECHAZADO':
      return 'error';
    case 'HR_VALIDATION':
      return 'warning';

    case 'IN_INSURANCE':
      return 'warning';
    case 'APPROVED':
      return 'success';
    case 'REJECTED':
      return 'error';
    case 'ISSUED':
      return 'processing';
    case 'PAID':
      return 'success';
    case 'NOT_ISSUED':
      return 'warning';
    case 'CANCELLED':
      return 'error';
    case 'CANCELED':
      return 'error';
    case 'HAS_CREDIT_NOTE':
      return 'warning';
    case 'HAS_DEBIT_NOTE':
      return 'warning';

    // Nominas
    case 'SUBIENDO':
      return 'default';
    case 'PROCESANDO':
      return 'processing';
    case 'EN SUBIDA':
      return 'default';
    case 'COMPLETED':
      return 'green';
    case 'CREATED':
      return 'blue';
    case 'FAILED':
      return 'red';
    case 'COMPLETED_OBSERVATIONS':
      return 'warning';

    // Facturacion
    case 'EMITIDA':
      return 'blue';
    case 'SIN EMITIR':
      return 'orange';
    case 'PAGADA':
      return 'green';

    case 'COMPLETO':
      return 'green';
    case 'INCOMPLETO':
      return 'warning';

    case 'PENDIENTE DE PAGO':
      return 'warning';
    default:
      return 'default';
  }
};

export default getCollectionAndBillingStateColor;
