<template>
  <div>
    <collapsable-table :data="requests_list" title="Solicitudes asociadas">
      <template v-if="hideCreation == false" #extra>
        <a-dropdown
          v-if="
            stateRequest == 'BEWELL_VALIDATION' ||
            stateRequest == 'HR_VALIDATION'
          "
        >
          <a-button style="margin-top: -5px">
            Crear solicitud
            <down-outlined />
          </a-button>
          <template #overlay>
            <a-menu @click="handleReworkCreation">
              <a-menu-item key="2">Nota de crédito</a-menu-item>
              <a-menu-item key="3">Nota de débito </a-menu-item>
              <a-menu-item key="1">Ajuste retroactivo</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>

      <template #no-data>
        <a-typography-text>
          No existen solicitudes asociados
        </a-typography-text>
      </template>
    </collapsable-table>
    <!-- Modal de reproceso -->
    <modal-request
      :period="period"
      :ticket-id="ticketId"
      ref="request_modal_ref"
      @requestCreated="requestCreated"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { DownOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import modalRequest from '../RequestsModal.vue';
import notification from '../../../../utils/notifications';
import CollapsableTable from '@/components/CollapsableTable.vue';
import { GET_BILLING_DETAIL_LIST } from '@/store/types';

export default {
  name: 'AssociateRequests',
  components: {
    'down-outlined': DownOutlined,
    'collapsable-table': CollapsableTable,
    'modal-request': modalRequest
  },
  props: {
    period: {
      type: String,
      default: ''
    },
    ticketId: {
      type: [String, Number],
      default: ''
    },
    data: {
      type: Array,
      default: []
    },
    states: {
      type: String,
      default: ''
    },
    hideCreation: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const request_modal_ref = ref();
    const stateRequest = computed(() => props.states);

    const collapse_open = ref();
    const requests_list = computed(() =>
      props.data.map((e) => ({
        ...e,
        name: e.name
      }))
    );
    const store = useStore();
    const billingDetailExist = computed(() =>
      store.getters[GET_BILLING_DETAIL_LIST]
        ? store.getters[GET_BILLING_DETAIL_LIST].rows
        : []
    );

    const handleReworkCreation = ({ key }) => {
      if (billingDetailExist.value.length && key >= 2) {
        request_modal_ref.value.openModal(key);
      } else if (key == 1) {
        request_modal_ref.value.openModal(key);
      } else {
        notification(
          'error',
          'Error',
          'Ocurrio un error, No existe un detalle de facturacion para la creación'
        );
      }
    };
    const requestCreated = () => {
      emit('requestCreated', true);
    };

    return {
      collapse_open,
      requests_list,
      handleReworkCreation,
      request_modal_ref,
      requestCreated,
      billingDetailExist,
      stateRequest
    };
  }
};
</script>
