const getMonthByNumber = (num) => {
  const parsed_num = parseInt(num);

  const month_values = {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre'
  };

  return month_values[parsed_num];
};

export default getMonthByNumber;
