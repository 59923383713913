// * Getters
export const GET_CLIENT_CURRENT_TAB = 'tabs/GET_CLIENT_CURRENT_TAB';
export const GET_COMPANY_CLIENT_TAB = 'tabs/GET_COMPANY_CLIENT_TAB';
export const GET_POLIZA_TAB = 'tabs/GET_POLIZA_TAB';

export const SELECTED_ACTIVE_TABS = 'tabs/SELECTED_ACTIVE_TABS';
export const SET_ACTIVE_KEY_TABS = 'tabs/SET_ACTIVE_KEY_TABS';
export const ACTION_ACTIVE_KEY_TABS = 'tabs/ACTION_ACTIVE_KEY_TABS';

// * Setters
export const SET_CLIENT_CURRENT_TAB = 'tabs/SET_CLIENT_CURRENT_TAB';
export const SET_COMPANY_CLIENT_TAB = 'tabs/SET_COMPANY_CLIENT_TAB';
export const SET_POLIZA_TAB = 'tabs/SET_POLIZA_TAB';
// * Mutations
export const SAVE_CLIENT_CURRENT_TAB = 'tabs/SAVE_CLIENT_CURRENT_TAB';
export const SAVE_COMPANY_CLIENT_TAB = 'tabs/SAVE_COMPANY_CLIENT_TAB';
export const SAVE_POLIZA_TAB = 'tabs/SAVE_POLIZA_TAB';
