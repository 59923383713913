// * GET
export const GET_ALL_COMPANIES = 'companies/GET_ALL_COMPANIES';
export const GET_COMPANY = 'companies/GET_COMPANY';
export const GET_DATA_STOCK = 'companies/GET_DATA_STOCK';
export const GET_USERS = 'companies/GET_USERS';
export const GET_USER = 'companies/GET_USER';
export const GET_INSURANCE_COMPANY = 'companies/GET_INSURANCE_COMPANY';
export const GET_COMPANY_NOTIFICATION = 'companies/GET_COMPANY_NOTIFICATION';
export const GET_INSURANCE_COMPANY_NOTIFICATION =
  'companies/GET_INSURANCE_COMPANY_NOTIFICATION';
export const GETTER_STOCK_TABLE_ITEMS_PER_PAGE =
  'companies/GETTER_STOCK_TABLE_ITEMS_PER_PAGE';
export const GETTER_STOCK_TABLE_CURRENT_PAGE =
  'companies/GETTER_STOCK_TABLE_CURRENT_PAGE';
export const GETTER_STOCK_TABLE_TOTAL_PAGES =
  'companies/GETTER_STOCK_TABLE_TOTAL_PAGES';
export const GET_COMPANY_BY_USER_ID = 'companies/GET_COMPANY_BY_USER_ID';
export const GET_COMPANY_DATA_TABLE = 'companies/GET_COMPANY_DATA_TABLE';
export const GET_USER_BY_COMPANY = 'companies/GET_USER_BY_COMPANY';
export const GET_COMPANY_POLICIES_WITHOUT_CONFIGURATION =
  'companies/GET_COMPANY_POLICIES_WITHOUT_CONFIGURATION';
export const GET_EMPLOYEE_COMPANIES = 'companies/GET_EMPLOYEE_COMPANIES';
export const GET_COMPANY_EMAIL_CONFIGURATIONS =
  'companies/GET_COMPANY_EMAIL_CONFIGURATIONS';

// * SET
export const SET_ALL_COMPANIES = 'companies/SET_ALL_COMPANIES';
export const SET_COMPANY = 'companies/SET_COMPANY';
export const SET_EMPLOYEES_COMPANY = 'companies/SET_EMPLOYEES_COMPANY';
export const SET_TABLE_TOTAL_PAGES = 'companies/SET_TABLE_TOTAL_PAGES';
export const SET_TABLE_ITEMS_PER_PAGE = 'companies/SET_TABLE_ITEMS_PER_PAGE';
export const SET_TABLE_CURRENT_PAGE = 'companies/SET_TABLE_CURRENT_PAGE';
export const GETTER_STOCK_TABLE_FILTERS =
  'companies/GETTER_STOCK_TABLE_FILTERS';
export const SET_COMPANY_BY_USER_ID = 'companies/SET_COMPANY_BY_USER_ID';

export const SET_USERS = 'companies/SET_USERS';
export const SET_USER = 'companies/SET_USER';
export const SET_INSURANCE_COMPANY = 'companies/SET_INSURANCE_COMPANY';
export const SET_COMPANY_NOTIFICATION = 'companies/SET_COMPANY_NOTIFICATION';
export const SET_INSURANCE_COMPANY_NOTIFICATION =
  'companies/SET_INSURANCE_COMPANY_NOTIFICATION';
export const SET_TABLE_FILTERS = 'companies/SET_TABLE_FILTERS';
export const SET_USER_BY_COMPANY = 'companies/SET_USER_BY_COMPANY';
export const SET_COMPANY_POLICIES_CONFIGURATION =
  'companies/SET_COMPANY_POLICIES_CONFIGURATION';
export const SET_EMPLOYEE_COMPANIES = 'companies/SET_EMPLOYEE_COMPANIES';
export const SET_COMPANY_EMAIL_CONFIGURATIONS =
  'companies/SET_COMPANY_EMAIL_CONFIGURATIONS';

// * MUTATE
export const MUTATE_COMPANY_DATA_TABLE = 'companies/MUTATE_COMPANY_DATA_TABLE';
export const UPDATE_COMPANY_EMAIL_CONFIGURATION =
  'companies/UPDATE_COMPANY_EMAIL_CONFIGURATION';

// * FETCH
export const FETCH_ALL_COMPANIES = 'companies/FETCH_ALL_COMPANIES';
export const FETCH_COMPANY = 'companies/FETCH_COMPANY';
export const FETCH_USERS = 'companies/FETCH_USERS';
export const FETCH_USER = 'companies/FETCH_USER';
export const FETCH_INSURANCE_COMPANY = 'companies/FETCH_INSURANCE_COMPANY';
export const FETCH_COMPANY_NOTIFICATION =
  'companies/FETCH_COMPANY_NOTIFICATION';
export const FETCH_INSURANCE_COMPANY_NOTIFICATION =
  'companies/FETCH_INSURANCE_COMPANY_NOTIFICATION';
export const FETCH_COMPANY_BY_USER_ID = 'companies/FETCH_COMPANY_BY_USER_ID';
export const FETCH_COMPANY_DATA_TABLE = 'companies/FETCH_COMPANY_DATA_TABLE';
export const FETCH_USER_BY_COMPANY = 'companies/FETCH_USER_BY_COMPANY';
export const FETCH_COMPANY_POLICIES_CONFIGURATION =
  'companies/FETCH_COMPANY_POLICIES_CONFIGURATION';
export const FETCH_EMPLOYEE_COMPANIES = 'companies/FETCH_EMPLOYEE_COMPANIES';
export const FETCH_COMPANY_LOGO = 'companies/FETCH_COMPANY_LOGO';
export const FETCH_COMPANY_DIGITAL_FORM_CONSENT_TEXT =
  'companies/FETCH_COMPANY_DIGITAL_FORM_CONSENT_TEXT';
export const FETCH_COMPANY_EMAIL_CONFIGURATIONS =
  'companies/FETCH_COMPANY_EMAIL_CONFIGURATIONS';

// * POST
export const POST_COMPANY = 'companies/POST_COMPANY';
export const POST_USER = 'companies/POST_USER';
export const POST_INSURANCE_COMPANY = 'companies/POST_INSURANCE_COMPANY';
export const POST_STOCK_DEREGISTRATION = 'companies/POST_STOCK_DEREGISTRATION';
export const POST_COMPANY_LOGO = 'companies/POST_COMPANY_LOGO';
export const POST_COMPANY_EMAIL_CONFIGURATION =
  'companies/POST_COMPANY_EMAIL_CONFIGURATION';

// * PATCH
export const PATCH_COMPANY = 'companies/PATCH_COMPANY';
export const FETCH_STOCK_EMPLOYEE_COMPANIES =
  'companies/FETCH_STOCK_EMPLOYEE_COMPANIES';
export const PATCH_COMPANY_EMAIL_CONFIGURATION =
  'companies/PATCH_COMPANY_EMAIL_CONFIGURATION';

// * PUT
export const PUT_EXECUTIVE = 'companies/PUT_EXECUTIVE';
export const PUT_NOTIFICATION = 'companies/PUT_NOTIFICATION';
export const PUT_INSURANCE_NOTIFICATION =
  'companies/PUT_INSURANCE_NOTIFICATION';
export const PUT_COMPANY_DIGITAL_FORM_CONSENT_TEXT =
  'companies/PUT_COMPANY_DIGITAL_FORM_CONSENT_TEXT';

// * DELETE
export const DELETE_COMPANY = 'companies/DELETE_COMPANY';
