<template>
  <a-modal
    v-model:visible="visibility"
    title="Ejecutar ticket de traspaso de póliza"
  >
    <a-typography-title :level="4"
      >¿Estás seguro que deseas cancelar la solicitud de traspaso de
      póliza?</a-typography-title
    >

    <div style="display: flex; justify-content: space-between">
      <exclamation-circle-outlined
        :style="{ fontSize: '40px', color: '#FFBF01' }"
      />
      <a-typography class="ml-2"
        >Al cancelar la solicitud el colaborador mantendrá las coberturas de su
        póliza actual.</a-typography
      >
    </div>

    <template #footer>
      <a-button @click="closeModal">Volver atrás</a-button>
      <a-button danger type="primary" @click="cancelTranfer"
        >Sí, cancelar</a-button
      >
    </template>
  </a-modal>
</template>

<script setup>
import { ref, defineExpose, defineEmits } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

const emit = defineEmits(['onCancelTransfer']);

const visibility = ref(false);

const openModal = () => {
  visibility.value = true;
};

const closeModal = () => {
  visibility.value = false;
};

const cancelTranfer = () => {
  emit('onCancelTransfer');
  closeModal();
};

defineExpose({ openModal });
</script>
