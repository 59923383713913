<template>
  <div>
    <a-collapse v-model:activeKey="collapse_open">
      <a-collapse-panel key="1" :header="title">
        <template #extra>
          <slot name="extra"> </slot>
        </template>

        <slot>
          <div v-if="table_props.dataSource.length > 0">
            <slot>
              <a-table v-bind="table_props">
                <template #tags="{ text: tags }">
                  <a-tag :color="getColorState(tags)">{{
                    getTagText(tags)?.toUpperCase()
                  }}</a-tag>
                </template>
                <template #redirection="{ text: link }">
                  <router-link :to="link.route">{{ link.title }}</router-link>
                </template>
              </a-table>
            </slot>
          </div>
          <div v-else>
            <slot name="no-data">
              <a-typography-text> No existe data </a-typography-text>
            </slot>
          </div>
        </slot>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { ref, reactive, watchEffect } from 'vue';
import stateColor from '@/utils/collectionBillingStateColor';
import getTagText from '@/utils/getTagText';

export default {
  props: {
    title: {
      type: String,
      default: 'Titulo collapsable'
    },
    data: {
      type: Array,
      default: []
    },
    activeKey: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    /* 
    Data de referencia:
    {
      key: 1,
      name: 'Inconsistencia',
      tags: 'Cancelado',
      redirection: {
          title:'Ver reproceso',
          route: { 
            name: 'detalleTicketCobranza', params: { id: 1 } 
          }
      }
    }
    */

    const collapse_open = ref([props.activeKey]);
    const table_props = reactive({
      dataSource: [],
      columns: [
        {
          dataIndex: 'name',
          slots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'tags',
          slots: {
            customRender: 'tags'
          }
        },
        {
          dataIndex: 'redirection',
          slots: {
            customRender: 'redirection'
          }
        }
      ],
      showHeader: false,
      pagination: false
    });

    watchEffect(() => {
      const { data } = props;
      table_props.dataSource = data;
      if (data.length > 5) {
        table_props.pagination = {
          pageSize: 5
        };
      } else {
        table_props.pagination = false;
      }
    });

    const getColorState = (value) => {
      return stateColor(value);
    };

    return {
      collapse_open,
      table_props,
      getTagText,
      getColorState
    };
  }
};
</script>
