<template>
  <div>
    <a-typography-title :level="4">Recepción</a-typography-title>

    <div v-if="loading_data">
      <a-skeleton active />
    </div>
    <div v-else>
      <!-- Tabla -->
      <a-table :dataSource="data" :columns="columns" :pagination="false" :scroll="{ x: 1000 }">
        <template #mailsDisplay="{ text }">
          {{ text != null ? text.join(', ') : text }}
        </template>
        <template #mailBoxConf="{ text }">
          <a @click="configureMailbox(text)">Configurar</a>
        </template>
      </a-table>
    </div>

    <!-- Modal -->
    <a-modal v-model:visible="modal_props.visibility" title="Recepción de solicitudes" @ok="handleOk"
      @cancel="handleCancel" :closable="!modal_props.loading_button" :maskClosable="!modal_props.loading_button">
      <p>
        En caso de que sea más de un destinatario por casilla, debes separar por
        comas cada dirección de correo electrónico.
      </p>

      <a-form ref="formRef" :model="formState" :rules="rules" layout="vertical">
        <!-- Altas -->
        <a-form-item ref="emails" :label="modal_props.title_input" name="emails">
          <a-input v-model:value="formState.emails" :placeholder="`Correo para solicitud de`" />
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button key="back" @click="handleCancel" :disabled="modal_props.loading_button">Cancelar</a-button>
        <a-button key="submit" @click="handleOk" :loading="modal_props.loading_button">Guardar</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  FETCH_COMPANY_MAILBOX,
  GET_COMPANY_MAILBOX,
  POST_COMPANY_MAILBOX,
  PUT_COMPANY_MAILBOX
} from '@/store/types';
import notification from '@/utils/notifications';
import delay from '@/utils/delay';

export default {
  name: 'MailReception',
  setup() {
    // * Router
    const route = useRoute();
    const { id } = route.params; // id de la empresa

    // * VUEX
    const store = useStore();

    const loading_data = ref(false);
    onBeforeMount(async () => {
      loading_data.value = true;
      try {
        await store.dispatch(FETCH_COMPANY_MAILBOX, id);
      } catch (error) {
        alert(error);
      }
      loading_data.value = false;
    });

    // * Tabla
    // Columnas
    const columns = [
      {
        title: 'Tipo',
        dataIndex: 'type',
        key: 'type'
      },
      {
        title: 'Clase',
        dataIndex: 'class',
        key: 'class'
      },
      {
        title: 'Correo destinatario',
        dataIndex: 'emails',
        key: 'emails',
        slots: {
          customRender: 'mailsDisplay'
        }
      },
      {
        title: 'Acción',
        dataIndex: 'mailbox_type',
        key: 'mailbox_type',
        slots: {
          customRender: 'mailBoxConf'
        }
      }
    ];

    // Data
    const data = computed(() => {
      return store.getters[GET_COMPANY_MAILBOX].map((obj) => {
        return {
          key: obj.id,
          type: obj.type,
          class: obj.class,
          emails: obj.emails,
          mailbox_type: obj.id
        };
      });
    });

    // * Mailbox
    const confMode = ref(''); // Modo de la configuracion
    const id_conf = ref('');
    const configureMailbox = (id) => {
      id_conf.value = id;

      // Busco el mailbox que quiero configurar
      const mailbox = data.value.find((obj) => obj.key == id);

      modal_props.title_input = mailbox.type;
      formState.emails =
        mailbox.emails != null ? mailbox.emails.join(', ') : '';

      // Si no existen correos en el tipo que hice click, significa que tengo que crearlos
      // Asi que paso el modo a creacion
      // En cambio si ya existen correos significa que tengo que editarlo
      // Asi que paso el modo a edicion
      confMode.value = mailbox.emails != null ? 'edit' : 'create';

      // Abro el modal
      openModal();
    };

    // Crear Mailbox
    const createMailbox = async () => {
      loading_data.value = true;

      modal_props.loading_button = true;

      try {
        await store.dispatch(POST_COMPANY_MAILBOX, {
          id_company: id,
          payload: {
            mailboxTypeId: id_conf.value,
            emails: formState.emails.split(',').map((obj) => obj.trim())
          }
        });

        await delay(1500);
        notification('success', 'Correcto', 'Casilla de correo creada');
      } catch (error) {
        notification(
          'error',
          'Error',
          'Ocurrio un error, vuelve a intentarlo mas tarde'
        );
      }

      handleCancel();
      loading_data.value = false;
    };

    // Editar mailbox
    const editMailbox = async () => {
      loading_data.value = true;

      modal_props.loading_button = true;

      try {
        await store.dispatch(PUT_COMPANY_MAILBOX, {
          id_company: id,
          id_mailbox: id_conf.value,
          payload: {
            emails: formState.emails.split(',').map((obj) => obj.trim())
          }
        });

        await delay(1500);
        notification('success', 'Correcto', 'Casilla de correo creada');
      } catch (error) {
        notification(
          'error',
          'Error',
          'Ocurrio un error, vuelve a intentarlo mas tarde'
        );
      }

      handleCancel();
      loading_data.value = false;
    };

    // * Modal
    const modal_props = reactive({
      title_input: '',
      visibility: false,
      loading_button: false
    });

    const openModal = () => {
      modal_props.visibility = true;
    };

    const closeModal = () => {
      modal_props.visibility = false;
    };

    const handleCancel = () => {
      formRef.value.resetFields();
      modal_props.loading_button = false;
      closeModal();
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(() => {
          if (confMode.value === 'create') {
            createMailbox();
          } else {
            editMailbox();
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    };

    // * Form data
    const formRef = ref();
    const formState = reactive({
      emails: ''
    });

    // Reglas
    const validateEmail = (mail) => {
      return String(mail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const checkEmail = async (rule, value) => {
      if (!value) {
        return Promise.reject('Ingrese correo electrónico');
      }

      const emails = value.split(',');
      for (let index = 0; index < emails.length; index++) {
        const element = emails[index].trim();
        const email_validator = validateEmail(element) != null;

        if (email_validator == false) {
          return Promise.reject('Correo inválido');
        }
      }
      return Promise.resolve();
    };

    const rules = {
      emails: [
        {
          required: true,
          validator: checkEmail,
          trigger: 'change'
        }
      ]
    };

    return {
      columns,
      data,
      configureMailbox,
      modal_props,
      openModal,
      handleCancel,
      handleOk,
      formRef,
      formState,
      rules,
      loading_data
    };
  }
};
</script>
