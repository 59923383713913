import { newConfig } from '../config';
import config from '../config';

const appConfig = config();
const { v2 } = appConfig.apiVersion;

export default class DependantIncorporationService {
  constructor(client) {
    this.client = client;
  }

  async deleteDependantIncorporation(id) {
    return this.client.delete(
      `${newConfig.services.proxy.url}${newConfig.services.proxy.core}/${v2}/dependants-incorporations/${id}`
    );
  }
}
