import companies from './companies/store';
import groups from './groups/groups';
import holdings from './holdings/holding';
import policies from './policies/policies';
import mailReception from './mailReception/mailReception';
import stock from './stock/stock';
import subholdings from './subholdings/subholdings';

const store = {};
store.modules = {
  companies,
  holdings,
  policies,
  mailReception,
  groups,
  stock,
  subholdings
};
export default store;
