export const SET_SELECTED_SUBHOLDINGS = 'holdings/SET_SELECTED_SUBHOLDING';
export const GET_ALL_SUBHOLDING = 'holdings/GET_ALL_SUBHOLDING';
export const SELECT_HOLDING = 'holdings/SELECT_HOLDING';
export const SELECTED_SUBHOLDINGS = 'holdings/SELECTED_SUBHOLDINGS';
export const HOLDING_FILTER = 'holdings/HOLDING_FILTER';
export const SUBHOLDINGS_FILTER = 'holdings/SUBHOLDINGS_FILTER';
export const FETCH_ALL_HOLDINGS = 'holdings/FETCH_ALL_HOLDINGS';
export const SET_SELECT_HOLDING = 'holdings/SET_SELECT_HOLDING';
export const ACTION_SELECTED_ALL_SUBHOLDING =
  'holdings/ACTION_SELECTED_ALL_SUBHOLDING';
export const HOLDINGS_SELECTS = 'holdings/HOLDINGS_SELECTS';
export const POST_CREATE_HOLDING = 'holdings/POST_CREATE_HOLDING';
export const PUT_HOLDING_EDIT = 'holdings/PUT_HOLDING_EDIT';
// * FETCH
export const FETCH_HOLDINGS_DATA_TABLE = 'holdings/FETCH_HOLDINGS_DATA_TABLE';

// * SET
export const SET_HOLDINGS = 'holdings/SET_HOLDINGS';

// * GET
export const GET_HOLDINGS = 'holdings/GET_HOLDINGS';
export const GET_HOLDINGS_DATA_TABLE = 'holdings/GET_HOLDINGS_DATA_TABLE';

// * DELETE
export const DELETE_HOLDING = 'holdings/DELETE_HOLDING';

// * MUTATE
export const MUTATE_HOLDINGS_DATA_TABLE = 'holdings/MUTATE_HOLDINGS_DATA_TABLE';
