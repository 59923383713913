import dotenv from 'dotenv';

dotenv.config();

export const config = () => ({
  appEnv: process.env.VUE_APP_ENV || 'local',
  baseURL: process.env.VUE_APP_SERVICE_URL || 'https://bwell.jumpittlabs.cl',
  timeout: 120000,
  auth_prefix: process.env.VUE_APP_API_PREFIX_AUTH_URL || '/api/auth/api/v1',
  ticket_prefix:
    process.env.VUE_APP_API_PREFIX_TICKET_URL || '/api/ticket/api/v1',
  company_client_prefix:
    process.env.VUE_APP_API_PREFIX_COMPANY_URL || '/api/client/api/v1',
  insurance_prefix:
    process.env.VUE_APP_API_PREFIX_INSURANCE_URL || '/api/insurance/api/v1',
  dsn: process.env.VUE_APP_SENTRY_DSN,
  hotjarId: parseInt(process.env.VUE_APP_HOTJAR_ID),
  apiVersion: {
    v1: 'v1',
    v2: 'v2'
  },
  recaptcha_secret_key:
    process.env.VUE_APP_RECAPTCHA_SITE_KEY ||
    '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  gitTag: process.env.VUE_APP_GIT_TAG_VERSION || '0.0.0'
});

export const newConfig = {
  services: {
    proxy: {
      url: process.env.API_PROXY_URL || 'http://localhost:4800',
      core: process.env.API_CORE_PREFIX || '/core/api',
      auth: process.env.API_AUTH_PREFIX || '/auth/api'
    }
  }
};

export default config;
