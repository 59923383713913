<template>
  <a-modal
    title="Nomina original"
    v-model:visible="modal_props.visible"
    @cancel="closeModal"
    :closable="!modal_props.loading"
    :maskClosable="!modal_props.loading"
  >
    <a-row>
      <a-col class="">
        <p>Selecciona archivo con nomina original.</p>
      </a-col>
      <a-col>
        <a-form
          name="form"
          layout="vertical"
          ref="formRef"
          :model="formState"
          :rules="rules"
        >
          <a-form-item name="file">
            <a-upload
              :disabled="modal_props.loading"
              name="file"
              :file-list="formState.file"
              :remove="handleRemove"
              :before-upload="beforeUpload"
              accept=".xlsx,.xlsb,.xls,.xml"
            >
              <a-button :style="{ width: '470px' }"> Carga Nomina </a-button>
            </a-upload>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col> </a-col>
    </a-row>
    <template #footer>
      <a-button @click="closeModal" :disabled="modal_props.loading"
        >Cancelar</a-button
      >
      <a-button
        type="primary"
        @click="formValidation"
        :loading="modal_props.loading"
        >Enviar</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import notification from '../../../utils/notifications';

import { PATCH_COLLECTION_RESOURCE } from '../../../store/types';

export default {
  setup(_, { emit }) {
    const store = useStore();
    const modal_props = reactive({
      visible: false,
      loading: false
    });
    const collection_ticket_id = ref('');
    const collection_resource_id = ref('');

    const openModal = (col_id, col_res_id) => {
      collection_ticket_id.value = col_id;
      collection_resource_id.value = col_res_id;

      modal_props.visible = true;
    };

    const closeModal = () => {
      modal_props.visible = false;
      formRef.value.resetFields();
    };

    const formRef = ref();
    const formState = reactive({
      file: []
    });

    const handleRemove = (file) => {
      const index = formState.file.indexOf(file);
      const newFileList = formState.file.slice();
      newFileList.splice(index, 1);
      formState.file = newFileList;
    };

    const beforeUpload = (file) => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        message.error('El archivo excede el peso máximo 5MB.');
      } else if (
        file.type == 'image/png' ||
        file.type == 'image/jpg' ||
        file.type == 'application/pdf'
      ) {
        message.error('El archivo debe ser formato excel');
      } else {
        formState.file = [file];
      }
      return false;
    };

    const checkFile = async (rule, value) => {
      if (value >= 0) {
        return Promise.reject('Ingresa nomina original');
      }
      return Promise.resolve();
    };

    const rules = {
      file: [
        {
          required: true,
          validator: checkFile,
          type: 'object'
        }
      ]
    };

    const formValidation = () => {
      formRef.value.validate().then(() => sendOriginalPayroll());
    };

    const sendOriginalPayroll = async () => {
      modal_props.loading = true;
      try {
        const formData = new FormData();
        formData.append('file', formState.file[0]);

        const payload = {
          collection_id: collection_ticket_id.value,
          collection_resource_id: collection_resource_id.value,
          payload: formData
        };

        await store.dispatch(PATCH_COLLECTION_RESOURCE, payload);
        notification(
          'success',
          'Correcto',
          'Nómina original adjunta correcta.'
        );
        emit('uploadedOriginal', true);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      modal_props.loading = false;
      closeModal();
    };

    return {
      modal_props,
      openModal,
      closeModal,
      formRef,
      formState,
      handleRemove,
      beforeUpload,
      rules,
      formValidation
    };
  }
};
</script>
