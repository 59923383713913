<template>
  <div>
    <PageHeader :breadcrumRoutes="routes_pageheader" title="Empleados" />

    <a-card>
      <a-typography-title :level="4" class="text-gray-8 mb-0">
        Lista de empleados
      </a-typography-title>

      <EmployeesTable class="mt-3" />
    </a-card>
  </div>
</template>

<script setup>
import PageHeader from '@/components/PageHeader.vue';
import EmployeesTable from '@/components/rrhh/EmployeesTable.vue';

// * Page heacer
const routes_pageheader = [
  {
    breadcrumbName: 'Empleados'
  }
];
</script>
