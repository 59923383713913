<template>
  <div>
    <a-modal title="Resumen de traspaso de póliza" v-model:visible="visibility">
      <a-typography-paragraph>
        Revisa que los datos ingresados esten correctos.
      </a-typography-paragraph>

      <a-descriptions layout="horizontal" bordered>
        <a-descriptions-item :span="3" label="Inicio de vigencia">
          {{ resume_values.requestStart }}
        </a-descriptions-item>
      </a-descriptions>

      <a-typography-title :level="5" class="mt-3">
        Datos póliza de origen
      </a-typography-title>

      <a-descriptions layout="horizontal" bordered>
        <a-descriptions-item :span="3" label="Nombre">
          {{ resume_values.name }}
        </a-descriptions-item>
        <a-descriptions-item :span="3" label="Rut">
          {{ resume_values.rut }}
        </a-descriptions-item>
        <a-descriptions-item :span="3" label="Grupo">
          {{ resume_values.group }}
        </a-descriptions-item>
        <a-descriptions-item :span="3" label="Aseguradora">
          {{ resume_values.insurance }}
        </a-descriptions-item>
        <a-descriptions-item :span="3" label="Pólizas">
          {{ resume_values.policies }}
        </a-descriptions-item>
      </a-descriptions>

      <a-typography-title :level="5" class="mt-3">
        Datos póliza de destino
      </a-typography-title>

      <a-descriptions layout="horizontal" bordered>
        <a-descriptions-item :span="3" label="Grupo">
          {{ resume_values.groupDestiny }}
        </a-descriptions-item>
        <a-descriptions-item :span="3" label="Aseguradora">
          {{ resume_values.insuranceDestiny }}
        </a-descriptions-item>
        <a-descriptions-item :span="3" label="Pólizas">
          {{ resume_values.policiesDestiny }}
        </a-descriptions-item>
        <a-descriptions-item :span="3" label="Coberturas">
          <ul style="margin: 0; padding: 0 0 0 8px">
            <li v-for="coverage in resume_values.coverages" :key="coverage">
              {{ coverage }}
            </li>
          </ul>
        </a-descriptions-item>
      </a-descriptions>

      <template #footer>
        <a-button @click="closeModal" :disabled="props.loading">
          Cancelar
        </a-button>
        <a-button type="primary" @click="sendTransfer" :loading="props.loading">
          Enviar
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
//TODO: remove this file and make a component
import { ref, defineExpose, defineEmits, defineProps } from 'vue';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['createTransfer']);
const visibility = ref(false);
const resume_values = ref({});

const openModal = (values) => {
  resume_values.value = values;
  visibility.value = true;
};

const closeModal = () => {
  visibility.value = false;
};

const sendTransfer = () => {
  emit('createTransfer');
};

defineExpose({ openModal, closeModal });
</script>
