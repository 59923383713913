// Retorna el color de los tags según estado
const getTagText = (state) => {
  switch (state?.toUpperCase() || state?.toLowerCase()) {
    case 'PENDING':
      return 'Pendiente';
    case 'INACTIVE':
      return 'No vigente';
    case 'ACTIVE':
      return 'Vigente';
    case 'CREATED':
      return 'Iniciada';
    case 'BEWELL_VALIDATION':
      return 'Validación corredora';
    case 'HR_VALIDATION':
      return 'Validación RRHH';
    case 'IN_INSURANCE':
      return 'En aseguradora';
    case 'REJECTED':
      return 'Rechazado';
    case 'APPROVED':
      return 'Aprobada';
    case 'ISSUED':
      return 'Emitida';
    case 'PAID':
      return 'Pagada';
    case 'CANCELLED':
      return 'Cancelado';
    case 'CANCELED':
      return 'Cancelado';
    case 'PROCCESING':
      return 'Procesando';
    case 'NOT_ISSUED':
      return 'Sin emitir';
    case 'COMPLETED':
      return 'Completado';
    case 'LOADED':
      return 'Cargado';
    case 'WAITING':
      return 'En Subida';
    case 'PROCESSING':
      return 'Procesando';
    case 'PROCESSED':
      return 'Procesado';
    case 'NO_PROCESSED':
      return 'No procesada';
    case 'MATCH':
      return 'Cuadra';
    case 'NO_MATCH':
      return 'No cuadra';
    case 'ERROR':
      return 'Error';
    case 'PROCESSED_ISSUES':
      return 'Observaciones';
    case 'COMPLETED_OBSERVATIONS':
      return 'Completado con observaciones';
    default:
      return state;
  }
};

export default getTagText;
