import { getOutOfTimeCases } from '../../../network';
import {
  GET_OUTOFTIME_CASES,
  SET_OUTOFTIME_CASES,
  FETCH_OUTOFTIME_CASES
} from './types';

const store = {};
const getDefaultState = () => ({
  cases: []
});

store.state = getDefaultState();

store.getters = {
  [GET_OUTOFTIME_CASES](state) {
    state.cases.sort((a, b) => {
      return a.id - b.id;
    });
    return state.cases.map((a) => {
      console.log('que tieneeeeee', a);
      return {
        key: a.id,
        id: a.id,
        holding: a.ticket.company?.holding
          ? a.ticket.company?.holding.businessName
          : a.ticket.company.subHolding?.holding?.businessName,
        subholding: a
          ? a.ticket.company
            ? a.ticket.company.subHolding
              ? a.ticket.company.subHolding.name
              : ''
            : ''
          : '',
        companyName: a.ticket.company.businessName,
        companyRut: a.ticket.company.rut,
        employeeName: `${a.ticket.employee.firstName} ${a.ticket.employee.firstSurname}`,
        employeeRut: a.ticket.employee.rut,
        ticketType: a.ticket.type.tag,
        caseState: a?.states[a?.states.length - 1]?.state.id,
        ticketState: a.ticket.states[a.ticket.states.length - 1].state.id,
        createdAt: a.updatedAt
      };
    });
  }
};

store.mutations = {
  [SET_OUTOFTIME_CASES](state, payload) {
    state.cases = payload;
  }
};

store.actions = {
  async [FETCH_OUTOFTIME_CASES]({ commit }) {
    const response = await getOutOfTimeCases().catch((err) => console.log(err));
    if (response && response.status === 200) {
      commit(SET_OUTOFTIME_CASES, response.data);
    } else {
      throw { mensaje: 'Error en la solicitud' };
    }
  }
};

export default store;
