// * FETCH
export const FETCH_GLOBAL_STOCK_QUERY = 'stockGlobal/FETCH_GLOBAL_STOCK_QUERY';

// * ACTIONS
export const ACTION_DELETE_GLOBAL_STOCK_FILTERS =
  'stockGlobal/ACTION_DELETE_GLOBAL_STOCK_FILTERS';

// * GETTERS
export const GET_GLOBAL_STOCK_ROWS_TYPE =
  'stockGlobal/GET_GLOBAL_STOCK_ROWS_TYPE';
export const GET_GLOBAL_STOCK_TOTAL_PAGES =
  'stockGlobal/GET_GLOBAL_STOCK_TOTAL_PAGES';
export const GET_GLOBAL_STOCK_CURRENT_PAGE =
  'stockGlobal/GET_GLOBAL_STOCK_CURRENT_PAGE';
export const GET_GLOBAL_STOCK_ITEM_PER_PAGE =
  'stockGlobal/GET_GLOBAL_STOCK_ITEM_PER_PAGE';
export const GET_GLOBAL_STOCK_FILTERS = 'stockGlobal/GET_GLOBAL_STOCK_FILTERS';
export const GET_GLOBAL_STOCK_DATE_RANGE_FILTERS =
  'stockGlobal/GET_GLOBAL_STOCK_DATE_RANGE_FILTERS';
export const GET_GLOBAL_STOCK_TAB = 'stockGlobal/GET_GLOBAL_STOCK_TAB';

// * MUTATIONS
export const MUTATE_GLOBAL_STOCK_ROWS = 'stockGlobal/MUTATE_GLOBAL_STOCK_ROWS';
export const MUTATE_GLOBAL_STOCK_TOTAL_PAGES =
  'stockGlobal/MUTATE_GLOBAL_STOCK_TOTAL_PAGES';
export const MUTATE_GLOBAL_STOCK_CURRENT_PAGE =
  'stockGlobal/MUTATE_GLOBAL_STOCK_CURRENT_PAGE';
export const MUTATE_GLOBAL_STOCK_ITEM_PER_PAGE =
  'stockGlobal/MUTATE_GLOBAL_STOCK_ITEM_PER_PAGE';
export const MUTATE_GLOBAL_STOCK_FILTERS =
  'stockGlobal/MUTATE_GLOBAL_STOCK_FILTERS';
export const MUTATE_EMPTY_GLOBAL_STOCK_FILTERS =
  'stockGlobal/MUTATE_EMPTY_GLOBAL_STOCK_FILTERS';
export const MUTATE_GLOBAL_STOCK_TAB = 'stockGlobal/MUTATE_GLOBAL_STOCK_TAB';
