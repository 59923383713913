<template>
  <div>
    <a-upload
      v-model:file-list="file_list"
      list-type="picture-card"
      :beforeUpload="uploadFile"
      @preview="handlePreview"
      accept=".jpg,.jpeg,.png,.svg"
    >
      <div v-if="file_list.length <= 0">
        <img :src="image_file" />
        <div class="ant-upload-text fs-6">
          Cédula {{ props.dniType === 'anverso' ? 'Anverso' : 'Reverso' }}
        </div>
      </div>
      <div v-if="loading === true">
        <a-spin />
      </div>
    </a-upload>

    <a-modal
      v-model:visible="preview_visible"
      :footer="null"
      @cancel="handleCancel"
    >
      <img alt="example" style="width: 100%" :src="preview_image" />
    </a-modal>
  </div>
</template>

<script setup>
import { ref, defineProps, defineExpose, onMounted } from 'vue';
import anverso from '../../assets/images/insured_form/anverso.svg';
import reverso from '../../assets/images/insured_form/reverso.svg';
import { useStore } from 'vuex';
import {
  PUT_EMPLOYEE_OBVERSE,
  PUT_EMPLOYEE_REVERSE,
  FETCH_EMPLOYEE_OBVERSE,
  FETCH_EMPLOYEE_REVERSE
} from '../../store/types';

const props = defineProps({
  dniType: {
    type: String,
    default: 'anverso'
  }
});

const loading = ref(false);
const store = useStore();
const file_list = ref([]);
const image_file = ref(props.dniType === 'anverso' ? anverso : reverso);
const preview_image = ref();
const preview_visible = ref(false);

const uploadFile = () => {
  return false;
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const handlePreview = async (file) => {
  if (!file.url && !file.preview) {
    file.preview = await getBase64(file.originFileObj);
  }
  preview_image.value = file.url || file.preview;
  preview_visible.value = true;
};

const saveFile = async () => {
  try {
    if (file_list.value[0].originFileObj !== undefined) {
      const file = file_list.value[0].originFileObj;
      const payload = new FormData();
      payload.append('ciphoto', file);

      await store.dispatch(
        props.dniType === 'anverso'
          ? PUT_EMPLOYEE_OBVERSE
          : PUT_EMPLOYEE_REVERSE,
        payload
      );
    }

    return true;
  } catch (error) {
    console.error(error);
  }
};

const handleCancel = () => {
  preview_visible.value = false;
};

const getDNIFile = () => {
  loading.value = true;
  store
    .dispatch(
      props.dniType === 'anverso'
        ? FETCH_EMPLOYEE_OBVERSE
        : FETCH_EMPLOYEE_REVERSE
    )
    .then((resp) => {
      const { data } = resp;
      if (Object.keys(data).length > 0) {
        file_list.value[0] = {
          key: 1,
          id: 1,
          uid: 1,
          name: data.objectName,
          status: 'done',
          url: data.objectUrl
        };
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const validateExistingFile = () => {
  return file_list.value.length > 0;
};

defineExpose({
  saveFile,
  validateExistingFile
});

onMounted(() => {
  getDNIFile();
});
</script>
