<template>
  <div>
    <a-skeleton active :loading="loading_data">
      <a-card>
        <a-typography-title :level="4">Contratante</a-typography-title>
        <a-form layout="vertical">
          <a-row :gutter="16" type="flex">
            <a-col :span="12">
              <a-form-item label="Nombre empresa contratante">
                <a-input v-model:value="company.name" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="RUT contratante">
                <a-input v-model:value="company.rut" disabled />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-card>

      <a-card class="mt-3">
        <a-typography-title :level="4">Pólizas</a-typography-title>
        <a-typography-title :level="5"
          >Grupo {{ groupCompany }}</a-typography-title
        >

        <div v-for="policy in policies.data" :key="policy.number">
          <a-form layout="vertical">
            <a-row :gutter="32">
              <a-col :span="8">
                <p>Nº Póliza</p>
                <a-input v-model:value="policy.number" disabled />
              </a-col>
              <a-col :span="8">
                <p>Coberturas obligatorias</p>

                <a-row type="flex" justify="space-between">
                  <a-checkbox
                    v-for="coverage in policy.required"
                    v-model:checked="coverage.selected"
                    :key="coverage.label"
                    :disabled="true"
                  >
                    {{ coverage.label }}
                  </a-checkbox>
                </a-row>
              </a-col>
              <a-col :span="8">
                <p>Coberturas opcionales</p>

                <a-row type="flex" justify="space-between">
                  <a-checkbox
                    v-for="coverage in policy.notRequired"
                    v-model:checked="coverage.selected"
                    :key="coverage.label"
                  >
                    {{ coverage.label }}
                  </a-checkbox>
                </a-row>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-card>

      <a-row type="flex" class="justify-content-end mt-3">
        <a-col>
          <a-button
            size="large"
            class="px-4"
            type="primary"
            @click="savePolicies(2)"
            block
            :loading="loading"
            >Siguiente
          </a-button>
        </a-col>
      </a-row>
    </a-skeleton>
  </div>
</template>

<script setup>
import { reactive, onMounted, ref, defineExpose } from 'vue';
import { useStore } from 'vuex';
import {
  ACTION_CHANGE_STEP,
  FETCH_DIGITAL_FORM_COMPANY,
  FETCH_DIGITAL_FORM_POLICIES,
  PUT_DIGITAL_FORM_POLICIES
} from '@/store/types';
import rutHelper from '@/utils/Ruts';
import notification from '@/utils/notifications';

const store = useStore();
const company = reactive({
  rut: '',
  name: ''
});
const groupCompany = ref('');
const policies = reactive({
  data: []
});
const loading_data = ref(false);
const loading = ref();

const getCompany = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_DIGITAL_FORM_COMPANY)
      .then((resp) => {
        const { businessName, rut } = resp.data;
        company.rut = rutHelper.formatRut(rut);
        company.name = businessName;
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPolicies = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_DIGITAL_FORM_POLICIES)
      .then((resp) => {
        const { data } = resp;
        groupCompany.value = data[0]?.groupCompany?.name ?? 'No existente';

        policies.data = data.map(
          ({ numberPolicy, coverages, groupCompany }) => {
            const obj = {
              number: numberPolicy,
              required: [],
              notRequired: [],
              groupCompanyId: groupCompany.id
            };

            coverages.forEach((coverage) => {
              const coverage_obj = {
                value: coverage.id,
                label: coverage.name,
                selected: coverage.checked,
                required: coverage.required
              };

              if (coverage.required) {
                obj.required.push(coverage_obj);
              } else {
                obj.notRequired.push(coverage_obj);
              }
            });

            return obj;
          }
        );

        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const savePolicies = (step) => {
  loading.value = true;
  const payload = [];

  policies.data.forEach((policy) => {
    const required = policy.required
      .filter((p) => p.selected === true)
      .map((p) => p.value);
    const notRequired = policy.notRequired
      .filter((p) => p.selected === true)
      .map((p) => p.value);
    if (notRequired.length == 0 && policy.notRequired.length > 0) {
      notification(
        'warning',
        'Advertencia',
        'No ha seleccionado ninguna cobertura opcional. Continuará únicamente con las coberturas financiadas por la empresa.'
      );
    }
    const coverage_ids = [...required, ...notRequired];

    const obj = {
      numberPolicy: policy.number,
      coverageId: coverage_ids,
      groupCompanyId: policy.groupCompanyId
    };
    payload.push(obj);
  });

  store
    .dispatch(PUT_DIGITAL_FORM_POLICIES, payload)
    .then(() => {
      redirectToOwnerData(step);
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      loading.value = true;
    });
};

const redirectToOwnerData = (step) => {
  store.dispatch(ACTION_CHANGE_STEP, step);
};

const getData = async () => {
  loading_data.value = true;
  try {
    await getPolicies();
    await getCompany();
  } catch (error) {
    console.error(error.response.data.message);
  }
  loading_data.value = false;
};

onMounted(() => {
  getData();
});

defineExpose({ savePolicies });
</script>
