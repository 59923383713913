<template>
  <div class="p-4" style="min-height: 100svh; display: flex; flex-direction: column">
    <div class="mb-3">
      <a-skeleton active :loading="logo_loading" v-if="current_component !== 'SignedDocumentSuccessful'">
        <img :src="logo_src" alt="Logo principal" style="max-width: 300px; max-height: 100px" />
      </a-skeleton>
    </div>

    <div v-if="current_step < 6">
      <a-typography-title class="ant-page-header-heading-title" :level="4">Formulario de solicitud incorporación
        digital</a-typography-title>

      <a-steps :current="current_step" class="my-5">
        <a-step v-for="(step, index) in steps" :key="index" :title="step" @click="() => redirectionToStep(index + 1)"
          :class="{ 'pointer-step': index + 1 <= on_load_step }" />
      </a-steps>
    </div>

    <a-skeleton active :loading="loading_component">
      <ContractingAndPolicies ref="contractingAndPoliciesRef" v-if="current_component === 'ContractingAndPolicies'" />
      <OwnerStep ref="ownerStepRef" v-if="current_component === 'OwnerStep'" />
      <BackgroundLoad ref="backgroundLoadRef" v-if="current_component === 'BackgroundLoad'" />
      <Recipients ref="recipientsRef" v-if="current_component === 'Recipients'" />
      <HealthDeclaration ref="healthDeclarationRef" v-if="current_component === 'HealthDeclaration'" />
      <Consent ref="consentRef" v-if="current_component === 'Consent'" />
      <DocumentPrevisualization v-if="current_component === 'DocumentPrevisualization'" />
      <SignedDocumentSuccessful v-if="current_component === 'SignedDocumentSuccessful'" />
    </a-skeleton>
  </div>
</template>

<script setup>
import { onMounted, computed, watchEffect, ref } from 'vue';
import ContractingAndPolicies from '@/components/digital incorporation form/ContractingPartyAndPoliciesStep';
import OwnerStep from '@/components/digital incorporation form/OwnerDataStep';
import BackgroundLoad from '@/components/digital incorporation form/BackgroundLoad';
import Recipients from '@/components/digital incorporation form/Beneficiaries';
import HealthDeclaration from '@/components/digital incorporation form/HealthDeclaration';
import Consent from '@/components/digital incorporation form/Consent';
import DocumentPrevisualization from '@/components/digital incorporation form/DocumentPrevisualization';
import SignedDocumentSuccessful from '@/components/digital incorporation form/SignedDocumentSuccessful.vue';
import scrollToTopScreen from '@/utils/scrollToTopScreen';

import { useStore } from 'vuex';
import {
  SAVE_DIGITAL_FORM_TOKEN,
  GET_DIGITAL_FORM_STEP,
  FETCH_DIGITAL_FORM,
  FETCH_INCORPORATION_LOGO
} from '@/store/types';
import { useRoute } from 'vue-router';

const steps = [
  'Contratante y Pólizas',
  'Datos titular',
  'Cargas',
  'Beneficiarios',
  'Declaración Salud',
  'Consentimiento'
];

const contractingAndPoliciesRef = ref();
const ownerStepRef = ref();
const backgroundLoadRef = ref();
const recipientsRef = ref();
const healthDeclarationRef = ref();
const consentRef = ref();

const store = useStore();
const route = useRoute();
const current_step = computed(() => store.getters[GET_DIGITAL_FORM_STEP] - 1);
const on_load_step = ref();
const current_component = ref();
const loading_component = ref(false);
const logo_loading = ref(false);
const logo_src = ref();

watchEffect(() => {
  // Cambio el componente dependiendo de el step que cambie
  const new_step = current_step.value;

  const steps = {
    0: 'ContractingAndPolicies',
    1: 'OwnerStep',
    2: 'BackgroundLoad',
    3: 'Recipients',
    4: 'HealthDeclaration',
    5: 'Consent',
    6: 'DocumentPrevisualization',
    7: 'SignedDocumentSuccessful'
  };

  current_component.value = steps[new_step];
  scrollToTopScreen();

  // Guardo el valor maximo de el step en el que estube
  // para prevenir que hagan click en un step que no han pasado antes
  if (current_step.value + 1 > on_load_step.value) {
    on_load_step.value = current_step.value + 1;
  }
});

const getAndSaveToken = () => {
  return new Promise((resolve, reject) => {
    const token = route.params.token;
    store
      .dispatch(SAVE_DIGITAL_FORM_TOKEN, token)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getCurrentStep = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_DIGITAL_FORM)
      .then((resp) => {
        const { data } = resp;
        on_load_step.value = data.currentStep;
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLogo = () => {
  return new Promise((resolve, reject) => {
    logo_loading.value = true;
    store
      .dispatch(FETCH_INCORPORATION_LOGO)
      .then((resp) => {
        const { logoObjectUrl } = resp;
        logo_src.value = logoObjectUrl;
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        logo_loading.value = false;
      });
  });
};

const redirectionToStep = (step) => {
  if (step <= on_load_step.value) {
    switch (current_component.value) {
      case 'ContractingAndPolicies':
        contractingAndPoliciesRef.value.savePolicies(step);
        break;
      case 'OwnerStep':
        ownerStepRef.value.formSubmit(step);
        break;
      case 'BackgroundLoad':
        backgroundLoadRef.value.formSubmit(step);
        break;
      case 'Recipients':
        recipientsRef.value.formSubmit(step);
        break;
      case 'HealthDeclaration':
        healthDeclarationRef.value.formValidation(step);
        break;
      case 'Consent':
        consentRef.value.formSubmit(step);
        break;
      default:
        break;
    }
  }
};

onMounted(async () => {
  try {
    await getAndSaveToken();
    await getCurrentStep();
    await getLogo();
  } catch (error) {
    console.error(error.response.data.message);
  }
});
</script>

<style scoped>
.pointer-step {
  cursor: pointer;
}
</style>
