import {
  GET_TOTAL_PAGES_TABLE,
  GET_ITEMS_PER_PAGE_TABLE,
  GET_CURRENT_PAGE_TABLE,
  GET_ROWS_TABLE,
  GET_PAGINATION_OBJECT,
  MUTATE_TOTAL_PAGES_TABLE,
  MUTATE_ITEMS_PER_PAGE_TABLE,
  MUTATE_CURRENT_PAGE_TABLE,
  MUTATE_ROWS_TABLE,
  SET_TOTAL_PAGES_TABLE,
  SET_ITEMS_PER_PAGE_TABLE,
  SET_CURRENT_PAGE_TABLE,
  SET_ROWS_TABLE
} from './types';

const store = {};

const getDefaultState = () => ({
  rows: [],
  total_pages: 0,
  items_per_page: 0,
  current_page: 0
});

store.state = getDefaultState();

store.getters = {
  [GET_TOTAL_PAGES_TABLE](state) {
    return state.total_pages;
  },
  [GET_ITEMS_PER_PAGE_TABLE](state) {
    return state.items_per_page;
  },
  [GET_CURRENT_PAGE_TABLE](state) {
    return state.current_page;
  },
  [GET_ROWS_TABLE](state) {
    return state.rows;
  },
  [GET_PAGINATION_OBJECT](state) {
    return {
      total: state.total_pages,
      pageSize: state.items_per_page,
      current: state.current_page
    };
  }
};

store.mutations = {
  [MUTATE_TOTAL_PAGES_TABLE](state, payload) {
    state.total_pages = payload;
  },
  [MUTATE_ITEMS_PER_PAGE_TABLE](state, payload) {
    state.items_per_page = payload;
  },
  [MUTATE_CURRENT_PAGE_TABLE](state, payload) {
    state.current_page = payload;
  },
  [MUTATE_ROWS_TABLE](state, payload) {
    state.rows = payload;
  }
};

store.actions = {
  [SET_TOTAL_PAGES_TABLE]({ commit }, payload) {
    commit(MUTATE_TOTAL_PAGES_TABLE, payload);
  },
  [SET_ITEMS_PER_PAGE_TABLE]({ commit }, payload) {
    commit(MUTATE_ITEMS_PER_PAGE_TABLE, payload);
  },
  [SET_CURRENT_PAGE_TABLE]({ commit }, payload) {
    commit(MUTATE_CURRENT_PAGE_TABLE, payload);
  },
  [SET_ROWS_TABLE]({ commit }, payload) {
    commit(MUTATE_ROWS_TABLE, payload);
  }
};

export default store;
