import {
  companyGroupCompany,
  CompanyGroup,
  CompanyDelete,
  CompanyEdit,
  companyGroupPremiumCalc,
  configureCompanyGroupPremiumCalc
} from '../../../network';
import {
  FETCH_CLIENT_COMPANY_GROUPS,
  MUTATE_CLIENT_COMPANY_GROUPS,
  GET_CLIENT_COMPANY_GROUPS,
  POST_CLIENT_COMPANY_GROUP,
  DELETE_CLIENT_COMPANY_GROUP,
  PUT_CLIENT_COMPANY_GROUP,
  FETCH_GROUP_COMPANY_PREMIUM_CALC,
  PUT_GROUP_COMPANY_PREMIUM_CALC
} from './types';

const store = {};

// * Store
store.state = {
  groups: []
};

// * Getters
store.getters = {
  [GET_CLIENT_COMPANY_GROUPS](state) {
    return state.groups;
  }
};

// * Mutations
store.mutations = {
  [MUTATE_CLIENT_COMPANY_GROUPS](state, payload) {
    state.groups = payload;
  }
};

// * Actions
store.actions = {
  [FETCH_CLIENT_COMPANY_GROUPS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyGroupCompany(payload)
        .then((resp) => {
          commit(MUTATE_CLIENT_COMPANY_GROUPS, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          commit(MUTATE_CLIENT_COMPANY_GROUPS, []);
          reject(err);
        });
    });
  },
  [POST_CLIENT_COMPANY_GROUP]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      CompanyGroup(payload)
        .then((resp) => {
          dispatch(FETCH_CLIENT_COMPANY_GROUPS, resp.data.companyId);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_CLIENT_COMPANY_GROUP]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      CompanyDelete(payload.group_id)
        .then((resp) => {
          dispatch(FETCH_CLIENT_COMPANY_GROUPS, payload.company_id);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_CLIENT_COMPANY_GROUP]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      CompanyEdit(payload.data, payload.id)
        .then((resp) => {
          dispatch(FETCH_CLIENT_COMPANY_GROUPS, resp.data.companyId);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_GROUP_COMPANY_PREMIUM_CALC](_, { groupId, policyId }) {
    return new Promise((resolve, reject) => {
      companyGroupPremiumCalc(groupId, policyId)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_GROUP_COMPANY_PREMIUM_CALC](_, { groupId, coverageId, payload }) {
    return new Promise((resolve, reject) => {
      configureCompanyGroupPremiumCalc(groupId, coverageId, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default store;
