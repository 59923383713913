<template>
  <a-typography-title :level="3" class="fw-regular title-underline text-gray-9"
    >Contraseña guardada</a-typography-title
  >
  <a-typography-text class="fs-6 text-gray-8 fs-xl">
    Genial! Tu contraseña ha sido guardada con éxito, vuelve al inicio para
    reingresar a la plataforma.</a-typography-text
  >
  <br />
  <br />

  <router-link class="" to="/">Volver al inicio</router-link>
</template>

<script>
export default {
  name: 'PasswordConfirm'
};
</script>
