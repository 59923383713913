<template>
  <div>
    <a-modal centered v-model:visible="visible" title="Nuevo subholding" :closable="!loading" :maskClosable="!loading"
      @close="closeModal">
      <a-form name="form" ref="formRef" :model="formState" :rules="rules" layout="vertical">
        <a-form-item label="Seleccionar holding" ref="holding_id" name="holding_id">
          <a-select v-model:value="formState.holding_id" placeholder="Selecciona un holding">
            <a-select-option :value="hold.id" v-for="hold in holdings_list" :key="hold.id">{{ hold.label
              }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-row :gutter="16" justify="center">
          <a-input-group>
            <a-row type="flex" :gutter="20" justify="space-between" align="middle" style="padding: 20px">
              <a-form-item ref="subholding_name" name="subholding_name" label="Nombre subholding">
                <a-col flex="1">
                  <a-input :autosize="true" style="width: 300px" size="large" v-model:value="formState.subholding_name"
                    placeholder="Ingresa nombre y presiona agregar" />
                </a-col>
              </a-form-item>
              <a-col flex="1">
                <a-button @click="addSubholdingName(formState.subholding_name)" type="primary" size="large"
                  :block="true">
                  <template #icon><plus-circle-filled /> </template>
                  Agregar
                </a-button>
              </a-col>
            </a-row>
          </a-input-group>
        </a-row>
        <a-row :gutter="16" justify="start" v-for="(subholding, i) in subholding_names" :key="subholding"
          style="padding-top: 2px; padding-bottom: 10px">
          <a-col class="gutter-row" flex="3">
            <a-typography-text class="text-gray-2"><b>Subholding {{ i + 1 }}: {{ subholding }}</b>
            </a-typography-text>
          </a-col>
          <a-col class="gutter-row" flex="1">
            <a-typography-link class="text-9" type="danger" @click="deleteSubholdingName(subholding)">
              <delete-outlined /> Eliminar
            </a-typography-link>
          </a-col>
        </a-row>
      </a-form>

      <template #footer>
        <a-button :disabled="loading" @click="closeModal">Cancelar</a-button>
        <a-button type="primary" @click="validateForm" :loading="loading">Guardar</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { PlusCircleFilled, DeleteOutlined } from '@ant-design/icons-vue';
import { computed, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import {
  GET_HOLDINGS,
  FETCH_ALL_HOLDINGS,
  POST_SUBHOLDING
} from '@/store/types';
import notification from '@/utils/notifications';

export default {
  name: 'SubholdingCreationModal',
  components: {
    'plus-circle-filled': PlusCircleFilled,
    'delete-outlined': DeleteOutlined
  },
  setup(_, { emit }) {
    // * Variables globales
    const store = useStore();
    const visible = ref(false);
    const loading = ref(false);

    // * Funciones
    const openModal = () => {
      visible.value = true;
    };

    const closeModal = () => {
      visible.value = false;
      subholding_names.value = [];
      formRef.value.resetFields();
    };

    const createSubholding = async () => {
      loading.value = true;
      try {
        const post_obj = {
          holdingId: formState.holding_id,
          names: subholding_names.value
        };
        await store.dispatch(POST_SUBHOLDING, post_obj);

        closeModal();
        emit('subholdingCreated', true);
        notification('success', 'Correcto', 'Subholding Creado.');
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    const validateForm = async () => {
      try {
        await formRef.value.validate();
        createSubholding();
      } catch (error) {
        console.error(error);
      }
    };

    // * Forms
    const formRef = ref();
    const formState = reactive({
      holding_id: '',
      subholding_name: ''
    });
    const subholding_names = ref([]);

    const addSubholdingName = (name) => {
      formRef.value.validate().then(() => {
        if (formState.subholding_name != '') {
          subholding_names.value.push(name);
          formState.subholding_name = '';
        }
      });
    };

    const deleteSubholdingName = (subholding) => {
      const index = subholding_names.value.indexOf(subholding);

      if (index > -1) {
        subholding_names.value.splice(index, 1);
      }
    };

    const checkSubHolding = async () => {
      if (
        formState.subholding_name == '' &&
        subholding_names.value.length == 0
      ) {
        return Promise.reject('Ingrese nombre de Subholding');
      }
      const exists = subholding_names.value.includes(formState.subholding_name);
      if (exists) {
        return Promise.reject('Nombre de Subholding ya existente');
      }
      return Promise.resolve();
    };

    const rules = {
      holding_id: [
        {
          type: 'number',
          required: true,
          message: 'Seleccione holding',
          trigger: 'change'
        }
      ],
      subholding_name: [
        {
          trigger: 'change',
          validator: checkSubHolding
        }
      ]
    };

    // * Obtencion de data
    const holdings_list = computed(() =>
      store.getters[GET_HOLDINGS].map((e) => ({
        id: e.id,
        label: e.businessName
      }))
    );

    onMounted(() => {
      store.dispatch(FETCH_ALL_HOLDINGS);
    });

    return {
      formRef,
      rules,
      formState,
      holdings_list,
      visible,
      subholding_names,
      loading,
      closeModal,
      validateForm,
      openModal,
      addSubholdingName,
      deleteSubholdingName
    };
  }
};
</script>
