// * FETCH
export const FETCH_ALL_BROKERS = 'broker/FETCH_ALL_BROKERS';
export const FETCH_BROKERS_BY_COMPANY = 'broker/FETCH_BROKERS_BY_COMPANY';

// * GET
export const GET_ALL_BROKERS = 'broker/GET_ALL_BROKERS';
export const GET_BROKERS_BY_COMPANY = 'broker/GET_BROKERS_BY_COMPANY';

// * SET
export const SET_ALL_BROKERS = 'broker/SET_ALL_BROKERS';
export const SET_BROKERS_BY_COMPANY = 'broker/SET_BROKERS_BY_COMPANY';

// * POST
export const POST_BROKER_TO_COMPANY = 'broker/POST_BROKER_TO_COMPANY';

// * DELETE
export const DELETE_BROKER_TO_COMPANY = 'broker/DELETE_BROKER_TO_COMPANY';
