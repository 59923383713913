import {
  commentId,
  createComment,
  masiveCommentsById,
  createCommentMasiveTicket,
  commentTicketId
} from '../../../network';
import router from '../../../router';
import {
  SET_COMMENTS,
  SET_POST_COMMENT_DATA,
  SET_MASIVE_COMMENTS,
  FETCH_COMMENTS,
  FETCH_MASIVE_COMMENTS,
  POST_COMMENTS,
  POST_MASIVE_COMMENTS,
  GET_MASIVE_COMMENTS,
  GET_COMMENT_TICKET_ID,
  SET_TICKET_COMMENT_ID,
  FETCH_TICKET_COMMENTS_ID
} from './types';

const store = {};
const getDefaultState = () => ({
  comments: [],
  masive_comments: [],
  ticketCommentId: []
});

store.state = getDefaultState();

store.getters = {
  getComments: (state) => state.comments.map((e) => e),
  [GET_MASIVE_COMMENTS]: (state) => state.masive_comments,
  [GET_COMMENT_TICKET_ID](state) {
    return state.ticketCommentId;
  }
};

store.mutations = {
  [SET_COMMENTS](state, payload) {
    state.comments = payload;
  },
  [SET_POST_COMMENT_DATA](state, payload) {
    state.ticket_id = payload.ticket_id;
    state.comment_data = payload.comment_data;
  },
  [SET_MASIVE_COMMENTS](state, payload) {
    state.masive_comments = payload;
  },
  [SET_TICKET_COMMENT_ID](state, payloadd) {
    state.ticketCommentId = payloadd;
  }
};

store.actions = {
  async [FETCH_COMMENTS]({ commit }) {
    const datoid = router.currentRoute.value.params.id;
    const response = await commentId(datoid);
    if (response && response.status === 200) {
      commit(SET_COMMENTS, response.data);
    } else {
      throw { mensaje: 'error en la solicitud' };
    }
  },
  async [POST_COMMENTS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      createComment(payload)
        .then((resp) => {
          commit(SET_POST_COMMENT_DATA, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_MASIVE_COMMENTS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      masiveCommentsById(payload)
        .then((resp) => {
          commit(SET_MASIVE_COMMENTS, resp.data);
          resolve(resp.data);
        })
        .catch((error) => {
          commit(SET_MASIVE_COMMENTS, []);
          reject(error);
        });
    });
  },
  [POST_MASIVE_COMMENTS](_, payload) {
    return new Promise((resolve, reject) => {
      createCommentMasiveTicket(payload)
        .then((resp) => {
          // commit(SET_MASIVE_COMMENTS,resp.data);
          resolve(resp);
        })
        .catch((error) => {
          // commit(SET_MASIVE_COMMENTS,[]);
          reject(error);
        });
    });
  },
  [FETCH_TICKET_COMMENTS_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commentTicketId(payload)
        .then((resp) => {
          commit(SET_TICKET_COMMENT_ID, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          commit(SET_MASIVE_COMMENTS, []);
          reject(error);
        });
    });
  }
};

export default store;
