<template>
  <a-modal
    v-model:visible="visibility"
    title="Eliminar Póliza"
    :closable="!loading"
    :maskClosable="!loading"
  >
    <p>
      Para eliminar la póliza <strong>{{ policy_number }}</strong> escribe en el
      recuadro “eliminar” y presiona continuar.
    </p>

    <a-form ref="form_ref" :model="form_state" :rules="rules" layout="vertical">
      <a-form-item ref="delete" name="delete">
        <a-input v-model:value="form_state.delete" placeholder="eliminar" />
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button :disabled="loading">Cancelar</a-button>
      <a-button type="primary" danger :loading="loading" @click="deletePolicy"
        >Continuar</a-button
      >
    </template>
  </a-modal>
</template>

<script setup>
import { ref, defineExpose, defineEmits, reactive } from 'vue';
import { useStore } from 'vuex';
import { DELETE_COMPANY_POLICY } from '@/store/types';
import notification from '@/utils/notifications';

const emit = defineEmits(['deletedPolicy']);
const store = useStore();
const policy_id = ref();
const policy_number = ref();
const visibility = ref(false);
const loading = ref(false);
const form_ref = ref();
const form_state = reactive({
  delete: ''
});

const deleteWordValidator = async (rule, value) => {
  if (!value || value != 'eliminar') {
    const error_message = 'Ingrese la palabra solicitada';
    return Promise.reject(error_message);
  }
  return Promise.resolve();
};

const rules = {
  delete: [
    {
      required: true,
      validator: deleteWordValidator,
      trigger: 'change'
    }
  ]
};

const openModal = (pol_id, pol_num) => {
  policy_id.value = pol_id;
  policy_number.value = pol_num;
  visibility.value = true;
};

const closeModal = () => {
  visibility.value = false;
  form_ref.value.resetFields();
};

const deletePolicy = () => {
  loading.value = true;
  form_ref.value.validate().then(() => {
    store
      .dispatch(DELETE_COMPANY_POLICY, policy_id.value)
      .then(() => {
        notification('success', 'Correcto', 'Póliza eliminada con éxito');
        emit('deletedPolicy');
        closeModal();
      })
      .catch((error) => {
        notification('error', 'Error', error.response.data.message);
      })
      .finally(() => {
        loading.value = false;
      });
  });
};

defineExpose({
  openModal
});
</script>
