import {
  createAltaIndividualTicketToCompany,
  createAltaIndividualTicketToCollaborator,
  covergarePolicy,
  getCompanyList,
  getGroupCompanyList,
  getinsuranceCompanyList,
  getinsuranceCompanyPolicyList,
  getinsuranceCompanyDocumentsList,
  getEmployeeDataByRut,
  TicketResendFormIncorporation,
  filesByTicket,
  reapplyFile,
  finishAltaIndividual,
  sendInformationToInsuranceCompany,
  cancelTicketAlta,
  editTicketDetails,
  fileTicketHistory,
  getTransferInformation,
  getTransferGroups,
  ticketIncome
} from '../../../../network';
import {
  FETCH_COMPANIES,
  FETCH_GROUP_COMPANIES,
  FETCH_INSURANCE_COMPANIES,
  FETCH_INSURANCE_COMPANY_POLICY,
  FETCH_INSURANCE_COMPANIES_DOCUMENTS,
  FETCH_EMPLOYEE_DATA_BY_RUT,
  FETCH_COVERAGE_POLICY,
  FETCH_FILE_BY_TICKET,
  SET_COVERAGE_POLICY,
  SET_DASHBOARD_OPTION_SELECTION,
  SET_COMPANIES,
  SET_GROUP_COMPANIES,
  SET_INSURANCE_COMPANIES,
  SET_INSURANCE_COMPANY_POLICY,
  SET_INSURANCE_COMPANIES_DOCUMENTS,
  SET_FILE_TICKET,
  GET_COVERAGE_POLICY,
  GET_DASHBOARD_OPTION_SELECTION,
  GET_COMPANIES,
  GET_GROUP_COMPANIES,
  GET_INSURANCE_COMPANIES,
  GET_INSURANCE_COMPANY_POLICY,
  GET_INSURANCE_COMPANIES_DOCUMENTS,
  GET_FILE_TICKET,
  POST_ALTA_INDIVIDUAL_TICKET_TO_COMPANY,
  POST_ALTA_INDIVIDUAL_TICKET_TO_COLLABORATOR,
  TICKET_RESEND_FORM_INCORPORATION,
  PUT_REAPPLY_FILE,
  POST_FINISH_ALTA_INDIVIDUAL,
  POST_SEND_INFO_TO_INSURANCE_COMPANY,
  POST_CANCEL_TICKET_ALTA,
  SAVE_TICKET_DISPLAY_SELECTION,
  EDIT_INDIVIDUAL_ALTA_COLABORATOR_TICKET,
  FETCH_FILES_HISTORY,
  GET_FILES_HISTORY,
  SET_FILES_HISTORY,
  FETCH_TRANSFER_INFORMATION,
  FETCH_TRANSFER_GROUPS,
  GET_EMPLOYEE_DATA,
  SET_EMPLOYEE_DATA,
  FETCH_TICKET_INCOME
} from './types';

const store = {};

const getDefaultState = () => ({
  coveragePolicy: [],
  dashboard_option_selection: {},
  companies: [],
  group_companies: [],
  insurance_companies: [],
  insurace_documents: [],
  company_polycies: [],
  employee: {},
  fileTicket: [],
  file_history: []
});

store.state = getDefaultState();

store.getters = {
  [GET_COVERAGE_POLICY]: (state) => state.coveragePolicy,
  [GET_DASHBOARD_OPTION_SELECTION]: (state) => (type) =>
    state.dashboard_option_selection[type],
  [GET_COMPANIES]: (state) => state.companies,
  [GET_GROUP_COMPANIES]: (state) => state.group_companies,
  [GET_INSURANCE_COMPANIES]: (state) => state.insurance_companies,
  [GET_INSURANCE_COMPANY_POLICY]: (state) => state.company_polycies,
  [GET_INSURANCE_COMPANIES_DOCUMENTS]: (state) => state.insurace_documents,
  [GET_EMPLOYEE_DATA]: (state) => state.employee,
  [GET_FILE_TICKET]: (state) => state.fileTicket,
  [GET_FILES_HISTORY]: (state) => state.file_history
};

store.mutations = {
  [SET_COVERAGE_POLICY](state, payload) {
    state.coveragePolicy = payload;
  },
  [SET_DASHBOARD_OPTION_SELECTION](state, payload) {
    state.dashboard_option_selection[Object.keys(payload)[0]] =
      payload[[Object.keys(payload)[0]]];
  },
  [SET_COMPANIES](state, payload) {
    state.companies = payload;
  },
  [SET_GROUP_COMPANIES](state, payload) {
    state.group_companies = payload;
  },
  [SET_INSURANCE_COMPANIES](state, payload) {
    state.insurance_companies = payload;
  },
  [SET_INSURANCE_COMPANY_POLICY](state, payload) {
    state.company_polycies = payload;
  },
  [SET_INSURANCE_COMPANIES_DOCUMENTS](state, payload) {
    state.insurace_documents = payload;
  },
  [SET_FILE_TICKET](state, payload) {
    state.fileTicket = payload;
  },
  [SET_FILES_HISTORY](state, payload) {
    state.file_history = payload;
  },
  [SET_EMPLOYEE_DATA](state, payload) {
    state.employee = payload;
  }
};

store.actions = {
  [POST_ALTA_INDIVIDUAL_TICKET_TO_COMPANY](_, payload) {
    return new Promise((resolve, reject) => {
      createAltaIndividualTicketToCompany(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_ALTA_INDIVIDUAL_TICKET_TO_COLLABORATOR](_, payload) {
    return new Promise((resolve, reject) => {
      createAltaIndividualTicketToCollaborator(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [FETCH_COVERAGE_POLICY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      covergarePolicy(payload).then(
        (resp) => {
          commit(SET_COVERAGE_POLICY, resp);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [SAVE_TICKET_DISPLAY_SELECTION]({ commit }, payload) {
    commit(SET_DASHBOARD_OPTION_SELECTION, payload);
  },
  [FETCH_COMPANIES]({ commit }) {
    return new Promise((resolve, reject) => {
      getCompanyList()
        .then((resp) => {
          commit(SET_COMPANIES, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          commit(SET_COMPANIES, []);
          reject(error);
        });
    });
  },
  [FETCH_GROUP_COMPANIES]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getGroupCompanyList(payload)
        .then((resp) => {
          commit(SET_GROUP_COMPANIES, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          commit(SET_GROUP_COMPANIES, []);
          reject(error);
        });
    });
  },
  [FETCH_INSURANCE_COMPANIES]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getinsuranceCompanyList(payload.id_company, payload.id_group)
        .then((resp) => {
          commit(SET_INSURANCE_COMPANIES, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          commit(SET_INSURANCE_COMPANIES, []);
          reject(err);
        });
    });
  },
  [FETCH_INSURANCE_COMPANY_POLICY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getinsuranceCompanyPolicyList(
        payload.rut,
        payload.id_company,
        payload.id_insurance,
        payload.id_group
      )
        .then((resp) => {
          commit(SET_INSURANCE_COMPANY_POLICY, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          commit(SET_INSURANCE_COMPANY_POLICY, []);
          reject(err);
        });
    });
  },
  [FETCH_INSURANCE_COMPANIES_DOCUMENTS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getinsuranceCompanyDocumentsList(payload)
        .then((resp) => {
          commit(SET_INSURANCE_COMPANIES_DOCUMENTS, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          commit(SET_INSURANCE_COMPANIES_DOCUMENTS, []);
          reject(err);
        });
    });
  },
  [FETCH_EMPLOYEE_DATA_BY_RUT]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getEmployeeDataByRut(payload)
        .then((resp) => {
          if (resp.data != null) {
            commit(SET_EMPLOYEE_DATA, resp.data);
          } else {
            commit(SET_EMPLOYEE_DATA, {});
          }
          resolve(resp);
        })
        .catch((err) => {
          commit(SET_EMPLOYEE_DATA, {});
          reject(err);
        });
    });
  },
  [TICKET_RESEND_FORM_INCORPORATION](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      TicketResendFormIncorporation(id, payload)
        .then((resp) => {
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_FILE_BY_TICKET]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      filesByTicket(payload)
        .then((resp) => {
          commit(SET_FILE_TICKET, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          commit(SET_FILE_TICKET, []);
          reject(err);
        });
    });
  },
  [PUT_REAPPLY_FILE](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      reapplyFile(id, payload)
        .then((resp) => {
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_FINISH_ALTA_INDIVIDUAL](_, payload) {
    return new Promise((resolve, reject) => {
      finishAltaIndividual(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_SEND_INFO_TO_INSURANCE_COMPANY](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      sendInformationToInsuranceCompany(id, payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_CANCEL_TICKET_ALTA](_, payload) {
    return new Promise((resolve, reject) => {
      cancelTicketAlta(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [EDIT_INDIVIDUAL_ALTA_COLABORATOR_TICKET](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      editTicketDetails(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_FILES_HISTORY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      fileTicketHistory(payload)
        .then((resp) => {
          commit(SET_FILES_HISTORY, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          SET_FILES_HISTORY([]);
          reject(err);
        });
    });
  },
  [FETCH_TRANSFER_INFORMATION](_, payload) {
    return new Promise((resolve, reject) => {
      getTransferInformation(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_TRANSFER_GROUPS](_, payload) {
    return new Promise((resolve, reject) => {
      getTransferGroups(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_TICKET_INCOME](_, payload) {
    return new Promise((resolve, reject) => {
      ticketIncome(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
export default store;
