<template>
  <div>
    <a-table v-bind="table_props" @change="changeCurrentPage">
      <template #custom_id="{ text }">
        <p># {{ text }}</p>
      </template>
      <template #custom_rut="{ text }">
        <p>{{ rutHelper.formatRut(text) }}</p>
      </template>
      <template #filterDropdown="{ confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-form name="form" layout="vertical" ref="form_ref" :model="filter_values" :rules="rules">
            <a-form-item has-feedback :name="column.dataIndex">
              <a-input v-model:value="filter_values[column.key]" :placeholder="`Buscar ${column.title}`"
                @keyup="formatRuts" @pressEnter="
                  handleSearch(
                    column.dataIndex,
                    filter_values[column.key],
                    confirm
                  )
                  " style="width: 188px; margin-bottom: 8px; display: block" />
            </a-form-item>

            <a-button type="primary" size="small" style="width: 90px; margin-right: 8px" @click="
              handleSearch(
                column.dataIndex,
                filter_values[column.key],
                confirm
              )
              ">
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>
            <a-button size="small" style="width: 90px" @click="handleReset(column.dataIndex, clearFilters)">
              Limpiar
            </a-button>
          </a-form>
        </div>
      </template>
      <template #filterIcon="{ column }">
        <search-outlined :style="{
          color:
            query_values[column.dataIndex] === undefined ||
              query_values[column.dataIndex] === ''
              ? undefined
              : '#2521E5'
        }" />
      </template>

      <template #action="{ record }">
        <div style="display: flex">
          <div style="margin-left: auto">
            <a-tooltip class="mr-4" color="#05045D" title="Editar">
              <delete-outlined @click="subHoldingEdition(record)" class="edit-holding-text" />
            </a-tooltip>
            <a-tooltip color="#05045D" title="Eliminar">
              <edit-outlined @click="subHoldingDeletion(record)" class="delete-holding-text" />
            </a-tooltip>
          </div>
        </div>
      </template>
    </a-table>

    <!-- Creacion de subholding -->
    <subholding-creation ref="subholding_creation" @subholdingCreated="subholdingCreated" />

    <!-- Edicion de subholding -->
    <subholding-edition ref="subholding_edition" @subholdingEdited="subholdingEdited" />

    <!-- Edicion de subholding -->
    <subholding-deletion ref="subholding_deletion" @subholdingDeleted="subholdingDeleted" />
  </div>
</template>

<script>
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons-vue';
import { onMounted, reactive, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  GET_SUBHOLDINGS_DATA_TABLE,
  FETCH_SUBHOLDINGS_DATA_TABLE
} from '@/store/types';

import rutHelper from '@/utils/Ruts';
import SubholdingCreationModal from './modals/SubholdingCreationModal.vue';
import SubholdingEditionModal from './modals/SubholdingEditionModal.vue';
import SubholdingDeletionModal from './modals/SubholdingDeletionModal.vue';

export default {
  name: 'Subholdings',
  components: {
    'search-outlined': SearchOutlined,
    'subholding-creation': SubholdingCreationModal,
    'subholding-edition': SubholdingEditionModal,
    'subholding-deletion': SubholdingDeletionModal,
    'edit-outlined': DeleteOutlined,
    'delete-outlined': EditOutlined
  },
  setup() {
    // * Variables globales
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // * Tabla
    const filter_values = ref({});
    const query_values = ref({});
    const table_props = reactive({
      dataSource: [],
      columns: [
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
          slots: {
            customRender: 'custom_id'
          }
        },
        {
          title: 'Holding',
          dataIndex: 'holdingName',
          key: 'holdingName',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon'
          }
        },
        {
          title: 'RUT Holding',
          dataIndex: 'rutHolding',
          key: 'rutHolding',
          slots: {
            customRender: 'custom_rut',
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon'
          }
        },
        {
          title: 'Subholding',
          dataIndex: 'subHoldingName',
          key: 'subHoldingName',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon'
          }
        },
        {
          title: 'Acción',
          dataIndex: 'action',
          key: 'action',
          align: 'center',
          width: 150,
          slots: {
            customRender: 'action'
          }
        }
      ],
      loading: false,
      scroll: { x: 700 },
      pagination: {
        total: 0,
        pageSize: 0,
        current: 0
      }
    });

    const filterRedirectionNewParam = (key, param) => {
      query_values.value[key] = param;

      if (key != 'page') {
        query_values.value['page'] = 1;
      }

      router.replace({
        name: 'Cliente',
        query: query_values.value,
        hash: '#subholdings'
      });
    };

    const filterRedirectionResetColumn = (key) => {
      delete query_values.value[key];

      router.replace({
        name: 'Cliente',
        query: query_values.value,
        hash: '#subholdings'
      });
    };

    const handleSearch = (column, value, confirm) => {
      if (value != '') {
        form_ref.value.validate().then(() => {
          filterRedirectionNewParam(column, value);
          confirm();
        });
      }
    };

    const handleReset = (column, clearFilters) => {
      filterRedirectionResetColumn(column);
      delete filter_values.value[column];
      clearFilters();
      form_ref.value.clearValidate();
    };

    const changeCurrentPage = (page) => {
      filterRedirectionNewParam('page', page.current);
    };

    // * Funciones
    const getSubholdingData = async () => {
      console.log('Subholdings ####!!!!!');

      table_props.loading = true;
      let queryParams = route.fullPath.split('?')[1] || 'page=1';
      queryParams = `?${queryParams}`;

      try {
        const resp = await store.dispatch(
          FETCH_SUBHOLDINGS_DATA_TABLE,
          queryParams
        );
        if (resp.status === 200) {
          const subholding = store.getters[GET_SUBHOLDINGS_DATA_TABLE];

          console.log(subholding);

          table_props.dataSource = subholding.items.map((obj) => {
            return {
              id: obj.id,
              key: obj.id,
              holdingName: obj.holding.businessName,
              rutHolding: obj.holding.rut,
              subHoldingName: obj.name,
              action: obj.id
            };
          });

          table_props.pagination.total = subholding.totalItems;
          table_props.pagination.pageSize = 10;
          table_props.pagination.current = subholding.page;
        }
      } catch (error) {
        console.error(error);
      }
      table_props.loading = false;
    };

    watchEffect(() => {
      getSubholdingData();
    });

    onMounted(() => {
      const key_params = Object.keys(route.query);
      key_params.forEach((key) => {
        filter_values.value[key] = route.query[key];
        query_values.value[key] = route.query[key];
      });
    });

    // * Form
    const form_ref = ref();
    const checkRut = async (rule, value) => {
      if (!value) {
        return;
      }
      if (!rutHelper.validateRut(value)) {
        return Promise.reject('RUT no válido');
      }
      return Promise.resolve();
    };
    const rules = {
      rutHolding: [
        {
          required: false,
          validator: checkRut,
          trigger: 'change'
        }
      ]
    };

    const formatRuts = () => {
      if (filter_values.value['rutHolding']) {
        filter_values.value['rutHolding'] = rutHelper.formatRut(
          filter_values.value['rutHolding']
        );
      }
    };

    // * Creacion subholding
    const subholding_creation = ref();
    const subHoldingCreation = () => {
      subholding_creation.value.openModal();
    };

    const subholdingCreated = () => {
      getSubholdingData();
    };

    // * Edicion de subholding
    const subholding_edition = ref();
    const subHoldingEdition = (subholding) => {
      subholding_edition.value.openModal(subholding);
    };

    const subholdingEdited = () => {
      getSubholdingData();
    };

    // * Eliminacion de subholding
    const subholding_deletion = ref();
    const subHoldingDeletion = (subholding) => {
      subholding_deletion.value.openModal(subholding);
    };

    const subholdingDeleted = () => {
      getSubholdingData();
    };

    return {
      table_props,
      filter_values,
      query_values,
      subholding_creation,
      subholding_edition,
      subholding_deletion,
      form_ref,
      rules,
      rutHelper,
      formatRuts,
      checkRut,
      handleSearch,
      handleReset,
      changeCurrentPage,
      subHoldingCreation,
      subholdingCreated,
      subHoldingEdition,
      subholdingEdited,
      subHoldingDeletion,
      subholdingDeleted
    };
  }
};
</script>

<style>
.edit-holding-text {
  color: #2383f6;
  cursor: pointer;
}

.delete-holding-text {
  color: #ff4d4f;
  cursor: pointer;
}
</style>
