<template>
  <a-modal title="Editar alta individual" v-model:visible="visibility" @cancel="closeModal" :closable="!loading"
    :maskClosable="!loading">
    <a-form name="form" :rules="rules" layout="vertical" ref="form_ref" :model="form_state">
      <a-form-item name="firstName" label="Primer nombre" required>
        <a-input v-model:value="form_state.firstName" maxLength="40" placeholder="Ingrese primer nombre" type="text" />
      </a-form-item>
      <a-form-item name="secondName" label="Segundo nombre">
        <a-input v-model:value="form_state.secondName" maxLength="40" placeholder="Ingrese segundo nombre" type="text" />
      </a-form-item>
      <a-form-item name="firstSurname" label="Primer apellido" required maxLength="40">
        <a-input v-model:value="form_state.firstSurname" maxLength="40" placeholder="Ingrese primer apellido"
          type="text" />
      </a-form-item>
      <a-form-item name="secondSurname" label="Segundo apellido" maxLength="40">
        <a-input v-model:value="form_state.secondSurname" maxLength="40" placeholder="Ingrese segundo apellido"
          type="text" />
      </a-form-item>
      <a-form-item v-if="props.rentRequired" name="incomeAmount" label="Renta" maxLength="40">
        <a-input @keyup="formatIncome" v-model:value="form_state.incomeAmount" maxLength="40" placeholder="Ingrese renta"
          style="width: 100%" />
      </a-form-item>
      <a-form-item label="Fecha de nacimiento" name="birthdayDate" has-feedback>
        <a-date-picker :style="{ width: '100%' }" format="DD/MM/YYYY" v-model:value="form_state.birthdayDate"
          placeholder="Fecha de nacimiento" />
      </a-form-item>
      <a-form-item ref="rut" label="RUT" name="rut" required>
        <a-input placeholder="RUT" maxLength="12" v-model:value="form_state.rut" @keyup="formatRuts" />
      </a-form-item>
      <a-form-item ref="email" label="Correo" name="email" required>
        <a-input placeholder="Correo Electrónico" maxLength="40" v-model:value="form_state.email" />
      </a-form-item>
      <a-form-item name="startDate" label="Fecha de inicio de Vigencia">
        <a-date-picker format="DD/MM/YYYY" placeholder="Seleccione fecha inicio de vigencia" :allowClear="false"
          v-model:value="form_state.startDate" :style="{ width: '100%' }" />
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button @click="closeModal" :disabled="loading">Cancelar</a-button>
      <a-button type="primary" :disabled="enableEditbutton" @click="editIndividualAlta" :loading="loading">Guardar
        cambios</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import {
  ref,
  defineExpose,
  defineProps,
  defineEmits,
  reactive,
  computed
} from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import notifitaction from '@/utils/notifications';
import rutHelper from '@/utils/Ruts';
import emailValidator from '@/utils/emailValidator';
import { EDIT_INDIVIDUAL_ALTA_COLABORATOR_TICKET } from '@/store/types';

const props = defineProps({
  values: {
    type: Object,
    default: {}
  },
  ticketId: {
    type: Number,
    default: 0
  },
  rentRequired: {
    type: Boolean,
    default: false
  },
});
const emit = defineEmits(['editedAltaColaborator']);

const store = useStore();
const visibility = ref(false);
const form_state = reactive({
  startDate: null,
  firstName: null,
  secondName: null,
  firstSurname: null,
  secondSurname: null,
  birthdayDate: null,
  rut: null,
  email: null,
  incomeAmount: null
});
const form_ref = ref();
const loading = ref(false);

const checkRut = async (rule, value) => {
  if (!value) {
    const error_message = 'Ingrese RUT';
    return Promise.reject(error_message);
  }
  if (rutHelper.validateRut(value)) {
    return Promise.resolve();
  }
  return Promise.reject('RUT no válido');
};

const checkEmail = async (rule, value) => {
  if (!value) {
    return Promise.reject('Ingrese correo electrónico');
  }
  if (emailValidator(value)) {
    return Promise.resolve();
  }
  return Promise.reject('Correo inválido');
};

const rules = {
  startDate: [
    {
      required: true,
      message: 'Seleccione una fecha',
      trigger: 'change',
      type: 'object'
    }
  ],
  firstName: [
    {
      required: true,
      message: 'Ingrese primer nombre',
      trigger: 'change'
    }
  ],
  firstSurname: [
    {
      required: true,
      message: 'Ingrese primer apellido',
      trigger: 'change'
    }
  ],
  rut: [
    {
      required: true,
      validator: checkRut,
      trigger: 'change'
    }
  ],
  email: [
    {
      required: true,
      validator: checkEmail,
      trigger: 'change'
    }
  ],
  incomeAmount: [
    {
      required: true,
      message: 'Ingrese primer nombre',
      trigger: 'change'
    }
  ]
};

const enableEditbutton = computed(() => {
  const new_obj = {
    ...form_state,
    rut: form_state.rut,
    startDate: form_state.startDate.format('DD/MM/YYYY')
  };

  return JSON.stringify(props.values) === JSON.stringify(new_obj);
});

const editIndividualAlta = () => {
  loading.value = true;
  form_ref.value
    .validate()
    .then((resp) => {
      const {
        firstName,
        secondName,
        firstSurname,
        secondSurname,
        birthdayDate,
        rut,
        email,
        startDate,
        incomeAmount
      } = resp;

      const payload = {
        firstName,
        firstSurname,
        rut,
        email,
        startDate: startDate.format()
      };

      if (secondName !== null && secondName !== '') {
        payload.secondName = secondName;
      }

      if (secondSurname !== null && secondSurname !== '') {
        payload.secondSurname = secondSurname;
      }

      if (birthdayDate !== null) {
        payload.birthdayDate = birthdayDate.format();
      }

      if (
        incomeAmount !== null &&
        incomeAmount !== '' &&
        incomeAmount !== undefined
      ) {
        payload.incomeAmount = parseIncome(incomeAmount);
      }

      store
        .dispatch(EDIT_INDIVIDUAL_ALTA_COLABORATOR_TICKET, {
          id: props.ticketId,
          payload
        })
        .then(() => {
          notifitaction('success', 'Correcto', 'Información editada con éxito');
          closeModal();
          emit('editedAltaColaborator');
        })
        .catch((err) => {
          notifitaction('error', 'Error', err.response.data.message);
        });
    })
    .finally(() => {
      loading.value = false;
    });
};

const closeModal = () => {
  form_ref.value.resetFields();
  visibility.value = false;
};

const parseIncome = (value) => {
  const parsedValue = value
    .replace(' ', '')
    .replace('$', '')
    .replaceAll('.', '');
  return parseInt(parsedValue);
};

const formatRent = (value) => {
  const numericInput = value.toString().replace(/[^0-9]/g, '');
  return `$ ${numericInput}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const formatIncome = () => {
  form_state.incomeAmount = formatRent(form_state.incomeAmount);
};

const openModal = () => {
  const {
    firstName,
    secondName,
    firstSurname,
    secondSurname,
    rut,
    email,
    startDate,
    birthdayDate,
    incomeAmount
  } = props.values;

  form_state.startDate =
    startDate !== null ? moment(startDate, 'YYYY-MM-DD') : null;
  form_state.firstName = firstName;
  form_state.secondName = secondName;
  form_state.firstSurname = firstSurname;
  form_state.secondSurname = secondSurname;
  form_state.incomeAmount = formatRent(incomeAmount);
  form_state.birthdayDate =
    birthdayDate !== null ? moment(birthdayDate, 'YYYY-MM-DD') : null;
  form_state.rut = rutHelper.formatRut(rut);
  form_state.email = email;
  visibility.value = true;
};

const formatRuts = () => {
  form_state.rut = rutHelper.formatRut(form_state.rut);
};

defineExpose({ openModal });
</script>
