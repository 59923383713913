<template>
  <div>
    <a-modal title="Reprocesar Factura" v-model:visible="visible">
      <a-typography-title :level="5" class="text-black">
        ¿Estas seguro que quieres reprocesar la factura?
      </a-typography-title>

      <template #footer>
        <a-button @click="closeModal">Cancelar</a-button>
        <a-button type="primary" @click="reprocessInvoice">Reprocesar</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { REPROCESS_INVOICE } from '@/store/types';
import notification from '@/utils/notifications';

export default {
  name: 'ObservationsModal',
  setup(_, { emit }) {
    const visible = ref();
    const invoice_id = ref(0);
    const store = useStore();

    const openModal = (inv_id) => {
      visible.value = true;
      invoice_id.value = inv_id;
    };

    const closeModal = () => {
      visible.value = false;
    };

    const reprocessInvoice = () => {
      store.dispatch(REPROCESS_INVOICE, invoice_id.value);
      closeModal();
      notification('success', 'Correcto', 'Reprocesando factura.');
      emit('reprocessedInvoice', true);
    };

    return {
      visible,
      invoice_id,
      openModal,
      closeModal,
      reprocessInvoice
    };
  }
};
</script>
