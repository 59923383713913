<template>
  <a-table
    :dataSource="table_data"
    :columns="table_columns"
    :pagination="pagination"
    @change="changeCurrentPage"
    :loading="loading"
  >
    <template #filterDropdown="{ column, confirm, clearFilters }">
      <div style="padding: 8px">
        <a-form
          name="form"
          layout="vertical"
          ref="form_ref"
          :model="filter_values"
          :rules="rules"
        >
          <a-form-item has-feedback :name="column.dataIndex">
            <a-input
              v-model:value="filter_values[column.key]"
              :placeholder="`Buscar ${column.title}`"
              @keyup="formatRuts"
              @pressEnter="
                handleSearch(
                  column.dataIndex,
                  filter_values[column.key],
                  confirm
                )
              "
              style="width: 188px; margin-bottom: 8px; display: block"
            />
          </a-form-item>

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="
              handleSearch(column.dataIndex, filter_values[column.key], confirm)
            "
          >
            <template #icon><search-outlined /></template>
            Buscar
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleColumnReset(column.dataIndex, clearFilters)"
          >
            Limpiar
          </a-button>
        </a-form>
      </div>
    </template>
    <template #filterIcon="{ column }">
      <search-outlined
        :style="{
          color:
            searched_value[column.dataIndex] === undefined ||
            searched_value[column.dataIndex] === ''
              ? undefined
              : '#2521E5'
        }"
      />
    </template>

    <template #filterStates="{ confirm, column, clearFilters }">
      <div style="padding: 8px; width: 300px">
        <a-checkbox-group v-model:value="states_selection">
          <a-row>
            <a-col
              :span="24"
              v-for="(status, index) in states_options"
              :key="index"
            >
              <a-checkbox :value="status.value">{{ status.label }}</a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
        <div
          class="mt-4 p-2"
          style="
            border-top: 1px solid #f0f0f0;
            display: flex;
            justify-content: space-between;
          "
        >
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="
              handleSearch(
                column.dataIndex,
                states_selection.join(','),
                confirm
              )
            "
          >
            <template #icon>
              <search-outlined />
            </template>
            Buscar
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleColumnReset(column.dataIndex, clearFilters)"
          >
            Limpiar
          </a-button>
        </div>
      </div>
    </template>

    <template #filterCompanies="{ column, confirm, clearFilters }">
      <div style="padding: 8px; width: 300px">
        <a-select
          v-model:value="selected_companies"
          :options="companies"
          mode="multiple"
          show-search
          :filterOption="true"
          optionFilterProp="label"
          placeholder="Buscar por Razon social"
          style="width: 100%"
        >
          <template #dropdownRender="{ menuNode: menu }">
            <v-nodes :vnodes="menu" />
            <div
              style="
                border-top: 1px solid #f0f0f0;
                display: flex;
                justify-content: space-between;
              "
              class="p-2"
              @mousedown="(e) => e.preventDefault()"
            >
              <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="
                  handleSearch(
                    column.dataIndex,
                    selected_companies.join(','),
                    confirm
                  )
                "
              >
                Buscar
              </a-button>

              <a-button
                size="small"
                style="width: 90px"
                @click="handleColumnReset(column.dataIndex, clearFilters)"
              >
                Limpiar
              </a-button>
            </div>
          </template>
        </a-select>
        <div
          class="mt-4 p-2"
          style="
            border-top: 1px solid #f0f0f0;
            display: flex;
            justify-content: space-between;
          "
        >
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="
              handleSearch(
                column.dataIndex,
                selected_companies.join(','),
                confirm
              )
            "
          >
            Buscar
          </a-button>

          <a-button
            size="small"
            style="width: 90px"
            @click="handleColumnReset(column.dataIndex, clearFilters)"
          >
            Limpiar
          </a-button>
        </div>
      </div>
    </template>

    <template #filterDate="{ column, confirm, clearFilters }">
      <div style="padding: 8px">
        <a-date-picker
          v-model:value="filter_values[column.key]"
          format="DD-MM-YYYY"
          :placeholder="column.title"
          @pressEnter="
            handleSearch(
              column.dataIndex,
              parseDate(filter_values[column.key])
                .substring(0, 10)
                .replaceAll('/', '-'),
              confirm
            )
          "
          style="width: 188px; margin-bottom: 8px; display: block"
        />
        <a-button
          type="primary"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="
            handleSearch(
              column.dataIndex,
              parseDate(filter_values[column.key])
                .substring(0, 10)
                .replaceAll('/', '-'),
              confirm
            )
          "
        >
          <template #icon>
            <search-outlined />
          </template>
          Buscar
        </a-button>
        <a-button
          size="small"
          style="width: 90px"
          @click="handleColumnReset(column.dataIndex, clearFilters)"
        >
          Limpiar
        </a-button>
      </div>
    </template>

    <template #tag="{ text: tag }">
      <a-tag :color="getTagColor(tag)">{{ getTagText(tag)?.toUpperCase() }}</a-tag>
    </template>

    <template #filterIconState="{ column }">
      <filter-outlined
        :style="{
          color:
            searched_value[column.dataIndex] === undefined ||
            searched_value[column.dataIndex] === ''
              ? undefined
              : '#2521E5'
        }"
      />
    </template>

    <template #details="{ text }">
      <a-tooltip color="#05045D">
        <template #title>Ver más</template>
        <router-link :to="{ name: text.link, params: { id: text.id } }">
          <eye-outlined />
        </router-link>
      </a-tooltip>
    </template>
  </a-table>
</template>

<script>
import { onMounted, ref, watchEffect, computed } from 'vue';
import {
  SearchOutlined,
  EyeOutlined,
  FilterOutlined
} from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';
import parseDate from '@/utils/dateFormatter';
import getTagColor from '@/utils/getTagColor';
import getTagText from '@/utils/getTagText';
import rutHelper from '@/utils/Ruts';
import {
  GET_ALL_COMPANIES,
  GET_PAGINATION_OBJECT,
  GET_ROWS_TABLE,
  FETCH_ALL_COMPANIES,
  FETCH_EMPLOYEE_COMPANIES
} from '@/store/types';

export default {
  components: {
    SearchOutlined,
    EyeOutlined,
    FilterOutlined,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    }
  },
  setup() {
    const form_ref = ref();
    const table_columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'namwe',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'RUT',
        dataIndex: 'rut',
        key: 'rut',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Fecha creación',
        dataIndex: 'createDate',
        key: 'createDate',
        slots: {
          filterDropdown: 'filterDate',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Fecha actualización',
        dataIndex: 'updateDate',
        key: 'updateDate',
        slots: {
          filterDropdown: 'filterDate',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Razón social',
        dataIndex: 'company',
        key: 'company',
        slots: {
          filterDropdown: 'filterCompanies',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Estado',
        dataIndex: 'status',
        key: 'status',
        slots: {
          customRender: 'tag',
          filterDropdown: 'filterStates',
          filterIcon: 'filterIconState'
        }
      }
      // {
      //   title: 'Opciones',
      //   dataIndex: 'details',
      //   key: 'details',
      //   slots: {
      //     customRender: 'details',
      //   }
      // },
    ];

    const checkRut = async (rule, value) => {
      if (!value) {
        return;
      }
      if (!rutHelper.validateRut(value)) {
        return Promise.reject('RUT no válido');
      }
      return Promise.resolve();
    };
    const rules = {
      rut: [
        {
          required: false,
          validator: checkRut,
          trigger: 'change'
        }
      ]
    };

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const filter_values = ref({});
    const searched_value = ref({});
    const states_selection = ref([]);
    const states_options = ref([
      {
        label: 'Vigente',
        value: 'ACTIVE'
      },
      {
        label: 'No Vigente',
        value: 'INACTIVE'
      },
      {
        label: 'Pendiente',
        value: 'PENDING'
      }
    ]);
    const table_data = computed(() => {
      return store.getters[GET_ROWS_TABLE]?.map((row) => ({
        id: `# ${row.id}`,
        key: row.id,
        name: row.name,
        rut: rutHelper.formatRut(row.rut),
        createDate: parseDate(row.createdAt),
        updateDate: parseDate(row.updatedAt),
        company: row.companyName,
        status: row.status
        // details: row.id,
      }));
    });

    const formatRuts = () => {
      if (filter_values.value['rut']) {
        filter_values.value['rut'] = rutHelper.formatRut(
          filter_values.value['rut']
        );
      }
    };

    const filterRedirectionNewParam = (key, param) => {
      searched_value.value[key] = param;

      if (key != 'page') {
        searched_value.value['page'] = 1;
      }

      router.replace({
        name: 'Employees',
        query: searched_value.value
      });
    };

    const filterRedirectionResetColumn = (key) => {
      delete searched_value.value[key];

      router.replace({
        name: 'Employees',
        query: searched_value.value
      });
    };

    const handleSearch = (column, value, confirm) => {
      console.log('handleSearch');
      if (value != '') {
        filterRedirectionNewParam(column, value);
        confirm();
      }
    };

    const handleColumnReset = (column, resetConfirm) => {
      filterRedirectionResetColumn(column);
      resetConfirm();
      delete filter_values.value[column];

      if (column === 'status') {
        states_selection.value = [];
      }

      if (column === 'company') {
        selected_companies.value = [];
      }
    };

    const changeCurrentPage = (page) => {
      filterRedirectionNewParam('page', page.current);
    };

    const selected_companies = ref([]);
    const companies = computed(() => {
      return store.getters[GET_ALL_COMPANIES].map((obj) => {
        return {
          value: obj.id,
          label: obj.businessName
        };
      });
    });

    const getCompaniesList = async () => {
      try {
        store.dispatch(FETCH_ALL_COMPANIES);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const loading = ref(false);
    watchEffect(async () => {
      loading.value = true;
      try {
        await store.dispatch(
          FETCH_EMPLOYEE_COMPANIES,
          `?${route.fullPath.split('?')[1]}` ?? ''
        );
      } catch (error) {
        notification('error', 'Error', error.repsonse.data.message);
      }
      loading.value = false;
    });

    const pagination = computed(() => {
      return store.getters[GET_PAGINATION_OBJECT];
    });

    onMounted(() => {
      const key_params = Object.keys(route.query);
      key_params.forEach((key) => {
        filter_values.value[key] = route.query[key];
        searched_value.value[key] = route.query[key];

        if (key === 'status') {
          states_selection.value.push(
            ...route.query[key].split(',').map((e) => e)
          );
        }

        if (key === 'company') {
          selected_companies.value.push(
            ...route.query[key].split(',').map((e) => parseInt(e))
          );
        }
      });
      getCompaniesList();
    });

    return {
      table_columns,
      form_ref,
      rules,
      store,
      route,
      router,
      filter_values,
      searched_value,
      states_selection,
      states_options,
      selected_companies,
      companies,
      parseDate,
      pagination,
      table_data,
      loading,
      rutHelper,
      getTagText,
      getTagColor,
      changeCurrentPage,
      formatRuts,
      filterRedirectionNewParam,
      filterRedirectionResetColumn,
      handleSearch,
      handleColumnReset
    };
  }
};
</script>
