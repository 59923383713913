<template>
  <a-row type="flex" class="" justify="center">
    <a-col>
      <img src="@/assets/images/insured_form/ready-img.svg" alt="" width="210" class="img-margin-ready" />
    </a-col>
  </a-row>
  <a-row justify="center">
    <a-col>
      <CheckCircleFilled class="text-green-6 mb-3 display-3" />
    </a-col>
  </a-row>

  <a-row class="mb-2 text-center" type="flex" justify="center">
    <a-col :xs="15" :sm="16" :md="12" :lg="9">
      <a-typography-title :level="4" class="text-gray-8 fw-semibold">Tus documentos han sido recepcionados
        satisfactoriamente.</a-typography-title>

      <br />
      <a-typography-text class="text-gray-7">Recibirás un correo de confirmación con tu kit de bienvenida cuando ya
        estés asegurado en cada compañía.</a-typography-text>
    </a-col>
  </a-row>
  <br />
  <br />
  <a-row class="mb-4 pb-1" type="flex" justify="center">
    <a-col class="text-center" :xs="20" :sm="16" :md="12" :lg="8">
      <a-typography-title class="ff-urbanist" :level="3">
        {{ companyName }}
      </a-typography-title>
      <a-typography-paragraph class="small">¡Muchas gracias!</a-typography-paragraph>
    </a-col>
  </a-row>
</template>

<script>
import { message } from 'ant-design-vue';
import { reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { FETCH_TICKET_ID } from '@/store/types';
import { CheckCircleFilled } from '@ant-design/icons-vue';

export default {
  components: { CheckCircleFilled },
  name: 'Ready',
  setup() {
    const info = reactive({
      id: '',
      ticketId: '',
      token: ''
    });
    const route = useRoute();
    const store = useStore();
    try {
      info.token = route.params.token;
      const decodeToken = JSON.parse(atob(info.token.split('.')[1]));
      info.id = decodeToken.employeeId;
      info.ticketId = decodeToken.ticketId;
    } catch (e) {
      message.error('Hubo un problema con el token');
    }

    const companyName = ref(null);

    const getTicketInfo = async () => {
      await store
        .dispatch(FETCH_TICKET_ID, info.ticketId)
        .then((response) => {
          companyName.value = response.data.company.businessName;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTicketInfo();

    return {
      companyName
    };
  }
};
</script>
