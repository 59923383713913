<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="data_table"
      :pagination="pagination"
      @change="changeCurrentPage"
    >
      <!-- ID -->
      <template #idRender="{ text }"> # {{ text }} </template>

      <!-- Filtro de lupa normal -->
      <template
        #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Buscar por ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon><search-outlined /></template>
            Buscar
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            Limpiar
          </a-button>
        </div>
      </template>

      <!-- Lupa de busqueda -->
      <template #filterIcon="filtered">
        <search-outlined
          :style="{ color: filtered.filtered ? '#108ee9' : undefined }"
        />
      </template>
    </a-table>
  </div>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue';
import { reactive, ref, computed, toRefs } from 'vue';

export default {
  props: {
    dataSource: {
      type: Array,
      default: []
    }
  },
  components: {
    'search-outlined': SearchOutlined
  },
  setup(props) {
    // * Props
    const data_table = computed(() =>
      props.dataSource.map((obj) => {
        return {
          id: obj.id,
          key: obj.id,
          subHolding: obj.subHolding?.name,
          businessName: obj.company.businessName,
          numberPolicy: obj.numberPolicy
        };
      })
    );

    // * Tabla
    const state = reactive({
      searchText: '',
      searchedColumn: ''
    });
    const searchInput = ref();
    const columns = [
      {
        title: 'N°',
        width: 100,
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'idRender',
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        },
        onFilter: (value, record) =>
          record.id.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Subholding',
        width: 100,
        dataIndex: 'subHolding',
        key: 'subHolding',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        },
        onFilter: (value, record) =>
          record.subHolding
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Razón social',
        width: 100,
        dataIndex: 'businessName',
        key: 'businessName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        },
        onFilter: (value, record) =>
          record.businessName
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Póliza',
        width: 100,
        dataIndex: 'numberPolicy',
        key: 'numberPolicy',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        },
        onFilter: (value, record) =>
          record.numberPolicy
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      }
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
    };

    const handleReset = (clearFilters) => {
      clearFilters();
      state.searchText = '';
    };

    // * Paginacion
    // configuracion de la paginacion
    const total_items = computed(() => data_table.value.length);
    const pagination = reactive({
      total: total_items.value,
      pageSize: 10,
      current: 1
    });

    // Cambiar la pagina
    const changeCurrentPage = (page) => {
      pagination.current = page.current;
    };

    return {
      data_table,
      columns,
      ...toRefs(state),
      pagination,
      changeCurrentPage,
      handleSearch,
      handleReset,
      searchInput
    };
  }
};
</script>
