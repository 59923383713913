<template>
  <div>
    <!-- Boton para cargar el manual -->
    <a-button class="new_group" type="primary" @click="openManualModal('create')">Cargar manual</a-button>

    <!-- Lista de manuales de uso -->
    <div v-if="loading_data">
      <a-skeleton active />
    </div>
    <div v-else class="mt-4" v-for="m in manual_list" :key="m.id">
      <a-descriptions bordered>
        <a-descriptions-item label="Nombre" :span="3" style="width: 50%">
          {{ m.name }}
        </a-descriptions-item>
        <a-descriptions-item label="Archivo" :span="3">
          <a :href="m.url" target="_blank">{{ m.file_name }}</a>
        </a-descriptions-item>
      </a-descriptions>

      <span style="justify-content: flex-end; list-style: none; display: flex" class="mt-2 mr-2">
        <p class="edit-holding-text" @click="openManualModal('edit', m.id)">
          Editar
        </p>
        <p class="delete-holding-text ml-5" @click="openManualModal('delete', m.id)">
          Eliminar
        </p>
      </span>
    </div>

    <!-- Modal para cargar el manual -->
    <a-modal v-model:visible="manual_prop.visibility" title="Cargar manual de uso" @ok="onOkManual"
      @cancel="onCancelManual" :closable="!manual_prop.loading_button" :maskClosable="!manual_prop.loading_button">
      <a-form ref="manualFormRef" :model="manualFormState" :rules="rules" layout="vertical">
        <div v-if="manual_prop.mode != 'delete'">
          <a-form-item ref="file_name" label="Nombre de documento" name="file_name">
            <a-input v-model:value="manualFormState.file_name" @keypress="preventDot" placeholder="Escribe un nombre"
              :maxlength="30" />
          </a-form-item>

          <a-form-item ref="files" label="Adjuntar documentos" name="files">
            <a-upload :file-list="manualFormState.files" :remove="handleRemove" :multiple="false"
              :before-upload="beforeUpload" accept="application/pdf">
              <a-button>
                <upload-outlined></upload-outlined>
                Adjuntar archivo
              </a-button>
            </a-upload>
          </a-form-item>
        </div>
        <div v-else>
          <p>
            Para eliminar Manual de uso escribe en el recuadro
            <b>“eliminar”</b> y presiona continuar.
          </p>

          <a-form-item ref="delete_word" name="delete_word">
            <a-input v-model:value="manualFormState.delete_word" placeholder="eliminar" :maxlength="15" />
          </a-form-item>
        </div>
      </a-form>

      <template #footer>
        <a-button @click="onCancelManual" key="back" :disabled="manual_prop.loading_button">Cancelar</a-button>
        <a-button @click="onOkManual" key="submit" :danger="manual_prop.mode == 'delete'" type="primary"
          :loading="manual_prop.loading_button" :disabled="formButtonState">{{
            manual_prop.mode == 'delete' ? 'Continuar' : 'Guardar'
          }}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { UploadOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  FETCH_INSURANCE_MANUAL,
  GET_INSURANCE_MANUAL,
  POST_INSURANCE_MANUAL,
  DELETE_INSURANCE_MANUAL,
  PUT_MANUAL_INSURANCE
} from '@/store/types';
import openNotificationWithIcon from '@/utils/notifications';
import delay from '@/utils/delay';

export default {
  name: 'UserManual',
  components: {
    'upload-outlined': UploadOutlined
  },
  setup() {
    // * Router
    const route = useRoute();
    const insurance_id = route.params.id;

    // * VUEX
    const store = useStore();

    // * Obtencion de data
    const loading_data = ref(false);
    const manual_list = computed(() => {
      loading_data.value = true;
      const data = store.getters[GET_INSURANCE_MANUAL];
      loading_data.value = false;

      return data;
    });

    const getManuals = async (id) => {
      try {
        await store.dispatch(FETCH_INSURANCE_MANUAL, id);
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          'Ocurrio un error al obtener los manuales, intentalo mas tarde'
        );
      }
    };

    getManuals(insurance_id);

    // * Funcion para abrir el modal
    const openManualModal = (state, id) => {
      manual_prop.visibility = true; // Lo hago visible
      manual_prop.mode = state; // Le pongo el modo que quiero, puede ser que lo estoy creando o editando

      // En caso de que habra el modal editandolo hago lo siguiente
      // Le paso el valor del item que hice click
      if (state === 'edit') {
        // Busco el objeto que estoy editando y lo paso para que se muestre en el modal
        const found_obj = manual_list.value.find((obj) => obj.id === id);

        const { name } = found_obj;
        const file = [found_obj].map((obj) => {
          return {
            uid: 1,
            name: obj.file_name,
            status: 'done',
            url: obj.url
          };
        });

        manualFormState.file_name = name;
        manualFormState.files = file;

        // Paso el objeto a uno que tengo de backup para comparar si algun valor cambio
        backup_file.id = id;
        backup_file.name = name;
        backup_file.file = file;
      } else if (state === 'delete') {
        delete_manual_id.value = id;
      }
    };

    // * Modal para cargar y editar el manual
    const manual_prop = reactive({
      visibility: false,
      mode: '', // Este seria el modo, si estoy creando o editando un manual
      loading_button: false
    });

    const onOkManual = () => {
      // Aca hago la validacion de formulario, si esta bien voy a poder hacer en envio
      manualFormRef.value
        .validate()
        .then(() => {
          // Valido en que modo esta
          if (manual_prop.mode === 'create') {
            createManual();
          } else if (manual_prop.mode === 'edit') {
            editManual();
          } else {
            deleteManual();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const onCancelManual = () => {
      // saco el modo en el que esta
      manual_prop.mode = '';

      // Cierro el modal
      manual_prop.visibility = false;

      // Saco el loading
      manual_prop.loading_button = false;

      // Reseteo los fields
      manualFormRef.value.resetFields();

      // hard reset a los valores del form
      manualFormState.file_name = '';
      manualFormState.files = [];
      manualFormState.delete_word = '';
    };

    // * Form para cargar el manual
    const manualFormRef = ref(null);
    const manualFormState = reactive({
      file_name: '',
      files: [],
      delete_word: ''
    });

    const checkFiles = async (rule, value) => {
      if (value.length > 0) {
        return Promise.resolve();
      }
      return Promise.reject('Adjunta archivo');
    };

    const checkDeleteWord = async (rule, value) => {
      if (value === 'eliminar') {
        return Promise.resolve();
      }
      return Promise.reject('Ingrese la palabra solicitada');
    };

    const rules = {
      file_name: [
        {
          required: true,
          type: 'string',
          message: 'Ingresa el nombre del Manual',
          trigger: 'change'
        }
      ],
      files: [
        {
          required: true,
          validator: checkFiles,
          trigger: 'change'
        }
      ],
      delete_word: [
        {
          required: true,
          validator: checkDeleteWord,
          trigger: 'change'
        }
      ]
    };

    const handleRemove = () => {
      // const index = manualFormState.files.indexOf(file);
      manualFormState.files = []; // manualFormState.files.splice(index, 1);
    };

    const beforeUpload = (file) => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        alert('El archivo excede el peso máximo 5MB');
      } else {
        manualFormState.files = [file];
      }
      return false;
    };

    // * Crear manual
    const createManual = async () => {
      loading_data.value = true;

      const formData = new FormData();
      formData.append(
        'file',
        manualFormState.files[0],
        `${manualFormState.file_name}.pdf`
      );

      // Dejo cargando el boton
      manual_prop.loading_button = true;

      try {
        const resp = await store.dispatch(POST_INSURANCE_MANUAL, {
          id: insurance_id,
          file: formData
        });

        console.log(resp);

        await delay(1000);

        if (resp === 201) {
          openNotificationWithIcon(
            'success',
            'Correcto',
            'Manual agregado correctamente'
          );
        }
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          'Ocurrio un error al intentar subir el manual, intentalo mas tarde nuevamente'
        );
      }

      // Cierro el modal
      onCancelManual();

      loading_data.value = false;
    };

    // * Editar manual
    const backup_file = reactive({
      id: '',
      name: '',
      file: ''
    });
    const editManual = async () => {
      loading_data.value = true;

      const formData = new FormData();

      // Dejo cargando el boton
      manual_prop.loading_button = true;

      if (backup_file.file != manualFormState.files) {
        formData.append(
          'file',
          manualFormState.files[0],
          `${manualFormState.file_name}.pdf`
        );
      }

      if (backup_file.name != manualFormState.file_name) {
        formData.append('name', `${manualFormState.file_name}.pdf`);
      }

      try {
        await store.dispatch(PUT_MANUAL_INSURANCE, {
          id_insurance: insurance_id,
          id_manual: backup_file.id,
          payload: formData
        });

        await delay(1000);

        openNotificationWithIcon(
          'success',
          'Correcto',
          'Manual editado correctamente'
        );
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          'Ocurrio un error al intentar editar el manual, intentalo mas tarde nuevamente'
        );
      }

      // Cierro el modal
      onCancelManual();

      loading_data.value = false;
    };

    // * Eliminar manual
    const delete_manual_id = ref('');
    const deleteManual = async () => {
      loading_data.value = true;

      // Dejo cargando el boton
      manual_prop.loading_button = true;

      try {
        await store.dispatch(DELETE_INSURANCE_MANUAL, {
          id_insurance: insurance_id,
          id_manual: delete_manual_id.value
        });

        await delay(1000);

        openNotificationWithIcon(
          'success',
          'Correcto',
          'Manual eliminado correctamente'
        );
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          'Ocurrio un error al intentar eliminar el manual, intentalo mas tarde nuevamente'
        );
      }

      // Cierro el modal
      onCancelManual();

      loading_data.value = false;
    };

    // * Habilitar el boton
    const formButtonState = computed(() => {
      if (manual_prop.mode == 'create') {
        return !(
          manualFormState.file_name.length > 0 &&
          manualFormState.files.length > 0
        );
      }

      if (manual_prop.mode == 'delete') {
        return !(manualFormState.delete_word === 'eliminar');
      }

      if (manual_prop.mode == 'edit') {
        if (manualFormState.files.length === 0) {
          return true;
        }

        return !(
          backup_file.file != manualFormState.files ||
          backup_file.name != manualFormState.file_name
        );
      }

      return true;
    });

    // * input
    const preventDot = (e) => {
      if (e.charCode === 44 || e.charCode === 46) {
        e.preventDefault();
      }
    };

    watch(
      () => manualFormState.files,
      (val) => {
        if (val.length > 0) {
          if (val[0].name.split('.').pop() != 'pdf') {
            openNotificationWithIcon(
              'error',
              'Error',
              'Solo se aceptan archivos pdf'
            );
            manualFormState.files = [];
          }
        }
      }
    );

    return {
      // Variables
      manual_prop,
      manualFormRef,
      manualFormState,
      rules,
      manual_list,
      formButtonState,
      loading_data,
      backup_file,
      // Funciones
      openManualModal,
      onOkManual,
      onCancelManual,
      beforeUpload,
      handleRemove,
      preventDot
    };
  }
};
</script>
