<template>
  <div>
    <a-modal centered v-model:visible="visible" title="Editar subholding" :closable="!loading" :maskClosable="!loading"
      @close="closeModal">
      <a-form name="form" ref="formRef" :model="formState" :rules="rules" layout="vertical">
        <a-form-item label="Seleccionar holding" ref="holding_id" name="holding_id">
          <a-select v-model:value="formState.holding_id" placeholder="Selecciona un holding">
            <a-select-option :value="hold.id" v-for="hold in holdings_list" :key="hold.id">{{ hold.label
              }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Nombre subholding" ref="subholding_name" name="subholding_name">
          <a-input v-model:value="formState.subholding_name" placeholder="Ingrese nombre subholding" />
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button :disabled="loading" @click="closeModal">Cancelar</a-button>
        <a-button type="primary" @click="validateForm" :loading="loading">Guardar</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import {
  GET_HOLDINGS,
  FETCH_ALL_HOLDINGS,
  EDIT_SUBHOLDING
} from '@/store/types';
import notification from '@/utils/notifications';

export default {
  name: 'SubholdingEditionModal',
  components: {
    // 'plus-circle-filled': PlusCircleFilled,
    // 'delete-outlined': DeleteOutlined
  },
  setup(_, { emit }) {
    // * Variables globales
    const store = useStore();
    const visible = ref(false);
    const loading = ref(false);
    const subholding_id = ref('');

    // * Funciones
    const openModal = (subholding) => {
      formState.holding_id = holdings_list.value.find(
        (obj) => obj.label === subholding.holdingName
      ).id;
      formState.subholding_name = subholding.subHoldingName;
      subholding_id.value = subholding.id;
      visible.value = true;
    };

    const closeModal = () => {
      visible.value = false;
      formRef.value.resetFields();
    };

    const editSubholding = async () => {
      loading.value = true;
      try {
        const payload = {
          holdingId: formState.holding_id,
          name: formState.subholding_name
        };

        await store.dispatch(EDIT_SUBHOLDING, {
          id: subholding_id.value,
          payload
        });

        notification(
          'success',
          'Correcto',
          'Subholding editado correctamente!'
        );

        closeModal();
        emit('subholdingEdited', true);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    const validateForm = async () => {
      try {
        await formRef.value.validate();
        editSubholding();
      } catch (error) {
        console.error(error);
      }
    };

    // * Forms
    const formRef = ref();
    const formState = reactive({
      holding_id: '',
      subholding_name: ''
    });

    const rules = {
      holding_id: [
        {
          type: 'number',
          required: true,
          message: 'Seleccione holding',
          trigger: 'change'
        }
      ],
      subholding_name: [
        {
          required: true,
          message: 'Ingrese nombre de subholding',
          trigger: 'change'
        }
      ]
    };

    // * Obtencion de data
    const holdings_list = computed(() =>
      store.getters[GET_HOLDINGS].map((e) => ({
        id: e.id,
        label: e.businessName
      }))
    );

    onMounted(() => {
      store.dispatch(FETCH_ALL_HOLDINGS);
    });

    return {
      formRef,
      rules,
      formState,
      holdings_list,
      visible,
      loading,
      closeModal,
      validateForm,
      openModal
    };
  }
};
</script>
