import {
  FETCHTESTING,
  MUTATETESTING,
  GETTESTING,
  FETCHTESTING2
} from './types';

const store = {};

const getDefaultState = () => ({
  insurance_company_policies: {}
});

store.state = getDefaultState();

store.getters = {
  [GETTESTING]: (state) => (payload) => {
    return state.insurance_company_policies[payload];
  }
};

store.mutations = {
  [MUTATETESTING](state, { data, payload }) {
    state.insurance_company_policies[payload] = data;
  }
};

store.actions = {
  [FETCHTESTING]({ commit }, payload) {
    const data = [
      {
        id: 1,
        numberPolicy: '6345',
        company: {
          id: 1,
          rut: '56458264-K',
          businessName: '56458264-K',
          createdAt: '2023-01-06T19:40:20.944Z'
        },
        subHolding: {
          id: 3,
          name: 'Innova',
          createdAt: '2023-01-06T19:40:20.935Z'
        },
        holding: {
          id: 1,
          rut: '73456245-9',
          businessName: 'Sable',
          createdAt: '2023-01-06T19:40:20.922Z'
        }
      }
    ];

    commit(MUTATETESTING, { data, payload });
  },
  [FETCHTESTING2]({ commit }, payload) {
    const data = [
      {
        id: 15,
        numberPolicy: '888',
        company: {
          id: 1,
          rut: '56458264-K',
          businessName: '56458264-K',
          createdAt: '2023-01-06T19:40:20.944Z'
        },
        subHolding: {
          id: 3,
          name: 'Innova',
          createdAt: '2023-01-06T19:40:20.935Z'
        },
        holding: {
          id: 1,
          rut: '73456245-9',
          businessName: 'Sable',
          createdAt: '2023-01-06T19:40:20.922Z'
        }
      }
    ];

    commit(MUTATETESTING, { data, payload });
  }
};

export default store;
