<template>
  <div>
    <a-modal
      v-model:visible="modal_props.visibility"
      @cancel="onClose"
      @ok="onSearch(modal_props.search_value)"
      title="Búsqueda"
    >
      <a-input-search
        placeholder="Buscar en plataforma"
        :maxlength="30"
        v-model:value="modal_props.search_value"
        @search="onSearch(modal_props.search_value)"
      />

      <a-list
        style="margin-top: 5px"
        item-layout="horizontal"
        :data-source="modal_props.searched_elements"
        v-if="modal_props.searched_elements.length > 0"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <a-list-item-meta>
              <template #title>
                <a @click="onSearch(item)">{{ item }}</a>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  ACTION_DELETE_GLOBAL_STOCK_FILTERS,
  MUTATE_GLOBAL_STOCK_TAB
} from '../store/types';

export default {
  name: 'SearcherTest',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    // * Router
    const router = useRouter();
    const route = useRoute();

    // * Vuex
    const store = useStore();

    // * Modal
    const modal_props = reactive({
      visibility: false,
      search_value: '',
      searched_elements: []
    });

    onMounted(() => {
      // Cargar los valores apenas iniciemos el componente
      modal_props.visibility = props.visible;

      // Obtengo las cookies de busqueda que el usuario hizo recientemente
      const cookie = {};
      document.cookie.split(';').forEach((el) => {
        const [key, value] = el.split('=');
        cookie[key.trim()] = value;
      });
      const searched_words =
        cookie['busqueda'] != undefined ? cookie['busqueda'].split(',') : [];
      modal_props.searched_elements = searched_words;
    });

    // Si es que la propiedad llega a cambiar, cambio las propiedades del modal
    watch(
      () => props.visible,
      (value) => {
        modal_props.visibility = value;
      }
    );

    /*
    Cuando cierre el modal, voy a emitir el valor de la visibilidad
    para que el padre tenga el valor
    */
    const emitResponse = () => {
      emit('update:visible', false);
    };

    const onClose = () => {
      emitResponse();
    };

    // * Busqueda
    const onSearch = (value) => {
      if (value != '' && route.query['keywords'] != value) {
        // Guardo la palabra que busco el usuario
        // El limite siempre son 4 busquedas anteriores
        // el caso de que exista una nueva, saco la ultima y agrego una nueva
        if (modal_props.searched_elements.length <= 4) {
          if (modal_props.searched_elements.length == 4) {
            modal_props.searched_elements.pop();
          }

          if (!modal_props.searched_elements.includes(value)) {
            modal_props.searched_elements.unshift(value);
          }
        }

        // Guardo la cookie con el cambio que hice
        document.cookie = `busqueda=${modal_props.searched_elements.toString()}`;

        // Elimino los filtros
        store.dispatch(ACTION_DELETE_GLOBAL_STOCK_FILTERS);

        // Dejo seleccionado al princio la tab de solicitudes
        store.commit(MUTATE_GLOBAL_STOCK_TAB, '1');

        // Dejo vacio el valor del input
        modal_props.search_value = '';

        // LLevo a la pagina buscador
        // Para ello valido si estoy en la misma pagina de resultados
        // de ser asi, voy hacer un reload, del caso contrario, hare un push
        router.replace({
          name: 'GlobalStock',
          query: {
            keywords: value
          },
          hash: route.hash != undefined || route.hash != '' ? route.hash : ''
        });

        // Cerrar modal
        onClose();
      }
    };

    return {
      modal_props,
      onSearch,
      onClose,
      emitResponse
    };
  }
};
</script>
