<template>
  <a-modal
    title="Editar póliza"
    v-model:visible="modal_prop.visible"
    @cancel="closeModal"
    :closable="!modal_prop.loading"
    :maskClosable="!modal_prop.loading"
  >
    <a-form
      name="form"
      layout="vertical"
      ref="form_ref"
      :model="form_state"
      :rules="rules"
    >
      <a-form-item name="numberPolicy" label="Número de póliza">
        <a-input
          v-model:value="form_state.numberPolicy"
          placeholder="Ingrese número de póliza"
        />
      </a-form-item>
      <a-form-item
        name="insuranceCompanyId"
        label="Seleccionar compañía aseguradora"
      >
        <a-select
          :options="insurance_companies"
          v-model:value="form_state.insuranceCompanyId"
          placeholder="Selecciona una aseguradora"
        />
      </a-form-item>
      <a-form-item name="groupId" label="Nombre grupo">
        <a-select
          :options="company_groups"
          v-model:value="form_state.groupId"
          mode="multiple"
          allowClear
          placeholder="Seleccionar grupo"
        />
      </a-form-item>
      <a-form-item name="startDate" label="Fecha de vigencia">
        <a-date-picker
          format="DD-MM-YYYY"
          placeholder="Seleccione fecha inicio"
          v-model:value="form_state.startDate"
          :style="{ width: '100%' }"
        />
      </a-form-item>
      <a-form-item name="endDate">
        <a-date-picker
          format="DD-MM-YYYY"
          placeholder="Seleccione fecha término"
          v-model:value="form_state.endDate"
          :style="{ width: '100%' }"
        />
      </a-form-item>
      <br />
      <a-form-item has-feedback name="fileList">
        <a-upload
          name="fileList"
          :file-list="form_state.fileList"
          :multiple="true"
          :remove="handleRemove"
          :before-upload="beforeUpload"
        >
          <a-button>
            <upload-outlined></upload-outlined>
            Adjuntar documentos
          </a-button>
        </a-upload>
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button @click="closeModal" :disabled="modal_prop.loading"
        >Cancelar</a-button
      >
      <a-button
        @click="submitForm"
        type="primary"
        :disabled="disableEditButton"
        :loading="modal_prop.loading"
        >Guardar cambios</a-button
      >
    </template>
  </a-modal>
</template>

<script setup>
import {
  reactive,
  defineExpose,
  defineEmits,
  ref,
  computed,
  onMounted
} from 'vue';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { UploadOutlined } from '@ant-design/icons-vue';
import moment from 'moment';
import notification from '@/utils/notifications';
import {
  FETCH_INSURANCE_COMPANY,
  GET_INSURANCE_COMPANY,
  FETCH_COMPANY_GROUPS,
  GET_COMPANY_GROUPS,
  PUT_COMPANY_POLICY
} from '@/store/types';

const emit = defineEmits(['editedPolicy']);
const store = useStore();
const route = useRoute();
const modal_prop = reactive({
  visible: false,
  loading: false
});
const form_ref = ref();
const form_state = reactive({
  numberPolicy: null,
  insuranceCompanyId: null,
  groupId: [],
  startDate: null,
  endDate: null,
  fileList: [],
  removeFiles: []
});
const obj_backup = reactive({});

const checkDate = async (prop) => {
  const { field } = prop;
  if (form_state[field]) {
    const startDate = new Date(form_state.startDate);
    const endDate = new Date(form_state.endDate);

    if (form_state.startDate && form_state.endDate) {
      if (endDate < startDate) {
        return Promise.reject(
          'La fecha de término vigencia debe ser mayor a la de inicio'
        );
      }
    }

    return Promise.resolve();
  }
  return Promise.reject('Seleccione una fecha');
};
const rules = {
  numberPolicy: [
    {
      required: true,
      message: 'Ingrese un valor',
      type: 'string',
      trigger: 'change'
    }
  ],
  insuranceCompanyId: [
    {
      required: true,
      message: 'Seleccione una compañía aseguradora',
      type: 'number',
      trigger: 'change'
    }
  ],
  groupId: [
    {
      required: true,
      message: 'Seleccione un grupo',
      type: 'array',
      trigger: 'change'
    }
  ],
  startDate: [
    {
      required: true,
      type: 'object',
      trigger: 'change',
      validator: checkDate
    }
  ],
  endDate: [
    {
      required: true,
      type: 'object',
      trigger: 'change',
      validator: checkDate
    }
  ]
};

const disableEditButton = computed(() => {
  const form = form_state;
  const keys = Object.keys(form);
  const backup = obj_backup;

  for (const key of keys) {
    if (key !== 'removeFiles') {
      if (typeof form[key] === 'object') {
        if (JSON.stringify(form[key]) !== JSON.stringify(backup[key])) {
          return false;
        }
      } else if (form[key] !== backup[key]) {
        return false;
      }
    }
  }

  return true;
});

const openModal = (obj) => {
  const {
    id,
    numberPolicy,
    startDate,
    endDate,
    insuranceCompany,
    groupCompanies,
    filePolicies
  } = obj;

  form_state.policyId = id;
  form_state.numberPolicy = numberPolicy;
  form_state.startDate = moment(startDate.split(' ')[0]);
  form_state.endDate = moment(endDate.split(' ')[0]);
  form_state.insuranceCompanyId = insuranceCompany.id;
  form_state.groupId = groupCompanies.map((x) => x.id);
  form_state.fileList = filePolicies.map((d) => ({
    key: d.id,
    uid: d.id,
    id: d.id,
    path: d.path,
    policyId: d.policyId,
    fileName: d.fileName,
    name: d.fileName,
    filePolicyURL: d.filePolicyURL
  }));
  form_state.removeFiles = [];

  obj_backup.policyId = id;
  obj_backup.numberPolicy = numberPolicy;
  obj_backup.insuranceCompanyId = insuranceCompany.id;
  obj_backup.groupId = groupCompanies.map((x) => x.id);
  obj_backup.startDate = moment(startDate.split(' ')[0]);
  obj_backup.endDate = moment(endDate.split(' ')[0]);
  obj_backup.fileList = filePolicies.map((d) => ({
    key: d.id,
    uid: d.id,
    id: d.id,
    path: d.path,
    policyId: d.policyId,
    fileName: d.fileName,
    name: d.fileName,
    filePolicyURL: d.filePolicyURL
  }));

  modal_prop.visible = true;
};

const closeModal = () => {
  modal_prop.visible = false;
  form_ref.value.resetFields();
};

const beforeUpload = (file) => {
  const fileSize = file.size / 1024 / 1024;
  if (fileSize > 5) {
    message.error('El archivo excede el peso máximo 5MB');
  } else {
    form_state.fileList = [...form_state.fileList, file];
  }
  return false;
};

const handleRemove = (file) => {
  if (file.id) {
    form_state.removeFiles = [...form_state.removeFiles, file.id];
  }
  const index = form_state.fileList.indexOf(file);
  const newFileList = form_state.fileList.slice();
  newFileList.splice(index, 1);
  form_state.fileList = newFileList;
};

const submitForm = () => {
  form_ref.value.validate().then(() => {
    editPolicy();
  });
};

const editPolicy = () => {
  const payload = {
    id: form_state.policyId,
    companyId: parseInt(route.params.id),
    numberPolicy: form_state.numberPolicy,
    insuranceCompanyId: form_state.insuranceCompanyId,
    groupId: form_state.groupId,
    startDate: form_state.startDate,
    endDate: form_state.endDate,
    fileList: form_state.fileList,
    removeFiles: form_state.removeFiles
  };

  modal_prop.loading = true;
  store
    .dispatch(PUT_COMPANY_POLICY, payload)
    .then(() => {
      notification('success', 'Correcto', 'Póliza editada con éxito');
      closeModal();
      emit('editedPolicy');
    })
    .catch((error) => {
      notification(
        'error',
        'Error',
        `No se ha podido editar la Póliza. ${error.response.data.message}`
      );
    })
    .finally(() => {
      modal_prop.loading = false;
    });
};

// Obtencion de data
const insurance_companies = computed(
  () => store.getters[GET_INSURANCE_COMPANY]
);
const company_groups = computed(() => store.getters[GET_COMPANY_GROUPS]);

onMounted(() => {
  try {
    store.dispatch(FETCH_INSURANCE_COMPANY);
    store.dispatch(FETCH_COMPANY_GROUPS, route.params.id);
  } catch (error) {
    notification(
      'error',
      'Error',
      `Error al cargar compañías aseguradoras. ${error.response.data.message}`
    );
  }
});

defineExpose({
  openModal
});
</script>
