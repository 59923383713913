<template>
  <a-modal title="Nueva configuración" v-model:visible="visibility" @cancel="closeModal" centered>
    <a-row class="mb-4">
      <a-col>
        <a-typography-text class="text-gray-8">
          Selecciona una o varias pólizas vigentes para configurar el proceso de
          cobranza y facturación.
        </a-typography-text>
      </a-col>
    </a-row>
    <a-row class="mt-3 mb-4">
      <a-col :span="3">
        <exclamation-circle-outlined :style="{ fontSize: '40px', color: '#FFBF01' }" />
      </a-col>
      <a-col :span="20">
        <a-typography-text class="text-gray-8">
          Al seleccionar varias pólizas la configuración será la misma para
          todas las pólizas que hayas seleccionado.
        </a-typography-text>
      </a-col>
    </a-row>

    <a-form name="form" layout="vertical" ref="form_ref" :model="form_state" :rules="rules">
      <a-form-item name="policy" ref="policy" label="Números de póliza">
        <a-select mode="multiple" :options="policies" v-model:value="form_state.selected"
          placeholder="Seleccionar pólizas" />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button @click="closeModal" key="back" :disabled="loading">
        Cancelar
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="handleConfig">
        Configurar
      </a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { onMounted, ref, defineExpose, reactive, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import {
  FETCH_COMPANY_POLICIES_CONFIGURATION,
  GET_COMPANY_POLICIES_WITHOUT_CONFIGURATION
} from '@/store/types';

const store = useStore();
const route = useRoute();
const router = useRouter();
const visibility = ref(false);
const loading = ref();
const form_ref = ref();
const form_state = reactive({
  policy: null
});

const checkPoliza = async () => {
  if (form_state.selected.length < 1) {
    return Promise.reject('Ingrese numero de poliza');
  }
  return Promise.resolve();
};

const rules = {
  policy: [
    {
      require: true,
      message: 'Seleccione un numero de póliza',
      validator: checkPoliza,
      trigger: 'change',
      type: 'array'
    }
  ]
};

const policies = computed(() => {
  return store.getters[GET_COMPANY_POLICIES_WITHOUT_CONFIGURATION].map(
    (policy) => {
      return {
        value: policy.id,
        label: policy.numberPolicy
      };
    }
  );
});

const openModal = () => {
  visibility.value = true;
};

const getCompanyPolicies = async () => {
  const company_id = route.params.id;

  await store.dispatch(FETCH_COMPANY_POLICIES_CONFIGURATION, {
    id: company_id,
    params: `?policyConfiguration=false&status=${route.query.status}`
  });
};

watch(() => route.query.status, getCompanyPolicies);

const closeModal = () => {
  form_ref.value.resetFields();
  visibility.value = false;
};

const handleConfig = () => {
  form_ref.value.validate().then(() => {
    router.replace({
      name: 'ConfigPoliza',
      params: {
        selected: form_state.selected,
        status: route.query.status
      }
    });
  });
};

onMounted(() => {
  getCompanyPolicies();
});

defineExpose({
  openModal
});
</script>
