import {
  holdingfetch,
  holdingFetchTableData,
  deleteHolding,
  holding,
  holdingEdit
} from '../../../network';
import {
  SET_HOLDINGS,
  GET_HOLDINGS,
  DELETE_HOLDING,
  SET_SELECTED_SUBHOLDINGS,
  GET_ALL_SUBHOLDING,
  SELECT_HOLDING,
  SELECTED_SUBHOLDINGS,
  HOLDING_FILTER,
  SUBHOLDINGS_FILTER,
  FETCH_ALL_HOLDINGS,
  SET_SELECT_HOLDING,
  ACTION_SELECTED_ALL_SUBHOLDING,
  HOLDINGS_SELECTS,
  POST_CREATE_HOLDING,
  PUT_HOLDING_EDIT,
  FETCH_HOLDINGS_DATA_TABLE,
  MUTATE_HOLDINGS_DATA_TABLE,
  GET_HOLDINGS_DATA_TABLE
} from './types';

const store = {};
const getDefaultState = () => ({
  holdings: [],
  holdings_data_table: [],
  selectedHolding: null,
  selectedSubHolding: null
});

store.state = getDefaultState();

store.getters = {
  [GET_HOLDINGS](state) {
    return state.holdings;
  },
  [SELECT_HOLDING]: (state) => state.selectedHolding,
  [SELECTED_SUBHOLDINGS]: (state) => state.selectedSubHolding,

  [GET_ALL_SUBHOLDING]: (state) =>
    state.holdings
      .map((e) => ({
        ...{
          d: e.subHolding.map((f) => ({
            ...f,
            holding: e.businessName,
            rut_holding: e.rut
          }))
        }
      }))
      .reduce((acc, item) => {
        return [...acc, ...item.d];
      }, [])
      .map((sh) => ({
        key: sh.id,
        id: sh.id,
        holding: sh.holding,
        rut_holding: sh.rut_holding,
        subholding: sh.name
      }))
      .sort((a, b) => a.id - b.id),
  [HOLDING_FILTER]: (state) =>
    state.holdings.reduce((acc, item) => {
      return [
        ...acc,
        {
          value: item.id,
          label: item.businessName,
          subholding: item.subHolding.map((e) => ({
            value: e.id,
            label: e.name,
            idholding: e.holdingId
          }))
        }
      ];
    }, []),
  [SUBHOLDINGS_FILTER]: (_, getters) =>
    getters[HOLDING_FILTER].reduce((acc, item) => {
      return [...acc, ...item.subholding];
    }, []),
  [GET_HOLDINGS_DATA_TABLE](state) {
    return state.holdings_data_table;
  }
};

store.mutations = {
  [SET_HOLDINGS](state, payload) {
    state.holdings = payload;
  },
  [SET_SELECTED_SUBHOLDINGS](state, payload) {
    state.selectedSubHolding = payload;
  },
  [SET_SELECT_HOLDING](state, payload) {
    state.selectedHolding = payload;
  },
  [MUTATE_HOLDINGS_DATA_TABLE](state, payload) {
    state.holdings_data_table = payload;
  }
};

store.actions = {
  [FETCH_ALL_HOLDINGS]({ commit }) {
    return new Promise((resolve, reject) => {
      holdingfetch()
        .then((resp) => {
          commit(SET_HOLDINGS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [HOLDINGS_SELECTS]({ commit, getters }, payload) {
    commit(SET_SELECT_HOLDING, payload);
    if (getters[SELECT_HOLDING] !== null) {
      commit(SET_SELECTED_SUBHOLDINGS, null);
    }
    // commit(SET_SELECTED_SUBHOLDINGS,state.selectedSubHolding.filter(f=>getters[SUBHOLDINGS_FILTER].some(h=>h.value==f)));
  },
  [ACTION_SELECTED_ALL_SUBHOLDING]({ commit }, payload) {
    commit(SET_SELECTED_SUBHOLDINGS, payload);
  },
  async [DELETE_HOLDING](_, payload) {
    let error = '';
    const response = await deleteHolding(payload).catch((err) => (error = err));
    if (response && response.status === 200) {
      return response.data;
    }
    throw error;
  },
  [POST_CREATE_HOLDING](_, payload) {
    return new Promise((resolve, reject) => {
      holding(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_HOLDING_EDIT](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      holdingEdit(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_HOLDINGS_DATA_TABLE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      holdingFetchTableData(payload)
        .then((resp) => {
          commit(MUTATE_HOLDINGS_DATA_TABLE, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default store;
