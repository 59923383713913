<template>
  <div>
    <a-upload
      :multiple="true"
      :file-list="file_list"
      :customRequest="customRequest"
    >
      <a-button style="border: 1px solid #2521e5; color: #2521e5">
        <plus-circle-outlined />
        Subir nóminas
      </a-button>
    </a-upload>
  </div>
</template>

<script>
import { PlusCircleOutlined } from '@ant-design/icons-vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  ADD_COLLECTION_FORM_INSURANCE_COMPANIES_PAYROLL,
  POST_COLLECTION_PAYROLL
} from '../../../store/types';
import notification from '@/utils/notifications';

export default {
  components: {
    'plus-circle-outlined': PlusCircleOutlined
  },
  props: {
    insuranceCompanyId: {
      type: [String, Number],
      default: ''
    },
    collectionFormId: {
      type: [String, Number],
      default: ''
    },
    payrollFormatId: {
      type: [String, Number],
      default: ''
    }
  },
  setup(props) {
    // * Vuex
    const store = useStore();

    // * Props
    const col_form_id = computed(() => props.collectionFormId);
    const ins_comp_id = computed(() => props.insuranceCompanyId);

    // * Metodos
    const file_list = ref([]);
    const customRequest = async ({ file }) => {
      file_list.value = [];

      // Archivo dummy
      store.dispatch(ADD_COLLECTION_FORM_INSURANCE_COMPANIES_PAYROLL, {
        insurance_comp_id: ins_comp_id.value,
        payload: {
          id: 1,
          key: 1,
          objectFilename: file.name,
          state: 'EN SUBIDA',
          observations: 0,
          options: 1
        }
      });

      // Archivo real
      try {
        const form_data = new FormData();
        form_data.append('file', file);
        form_data.append('insuranceCompanyId', ins_comp_id.value);
        form_data.append('payrollFormatId', props.payrollFormatId);

        store.dispatch(POST_COLLECTION_PAYROLL, {
          id: col_form_id.value,
          payload: form_data
        });
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    return {
      customRequest,
      file_list
    };
  }
};
</script>
