<template>
  <a-layout>
    <a-layout-content>
      <a-row type="flex" justify="center">
        <div style="height: 110px;" />
      </a-row>

      <div v-if="is_token_valid">
        <a-row type="flex" justify="center">
          <a-col class="text-center" :xs="20" :sm="16" :md="12" :lg="11">
            <a-typography-title :level="3" class="text-gray-9 mb-2 ff-urbanist">
              Resolicitud de documento
            </a-typography-title>
            <a-typography-paragraph class="text-gray-8 mb-2">
              Descarga el documento que enviaste inicialmente y que se encuentra
              con información faltante, luego editalo, completa la información y
              sube el archivo.
            </a-typography-paragraph>
          </a-col>
        </a-row>

        <br />
        <br />

        <a-row type="flex" justify="center">
          <a-col class="text-center" :xs="20" :sm="16" :md="12" :lg="8">
            <a-typography-paragraph strong class="text-gray-9">Descarga tu documento</a-typography-paragraph>

            <a :href="old_document_url" target="_blank">
              <a-button type="primary" class="btn-client-secondary"><download-outlined />Descarga documento</a-button>
            </a>
          </a-col>
        </a-row>

        <br />

        <a-row type="flex" justify="center">
          <a-col :xs="20" :sm="16" :md="12" :lg="10">
            <a-row type="flex" :gutter="8" justify="center">
              <a-col :span="20">
                <a-typography-paragraph class="text-gray-9 small text-center">
                  <exclamation-circle-outlined class="danger-icon" />
                  <a-typography-text strong> Importante:</a-typography-text> Si
                  el documento que enviaste es una imagen tendrás que
                  modificarlo desde el inicio y volver a subir.
                </a-typography-paragraph>

                <a :href="blank_document_url" target="_blank">
                  <a-button type="link" class="d-flex mx-auto"><download-outlined /> Descarga documento en
                    blanco</a-button>
                </a>
              </a-col>
            </a-row>
            <br />
            <br />
          </a-col>
        </a-row>

        <a-row type="flex" justify="center">
          <a-col class="text-center" :xs="20" :sm="16" :md="12" :lg="8">
            <a-typography-paragraph strong class="text-gray-9">Subir archivo</a-typography-paragraph>
            <a-typography-paragraph class="text-gray-9">Sube el archivo corregido</a-typography-paragraph>
          </a-col>
        </a-row>

        <a-row class="" type="flex" justify="center">
          <a-col :xs="20" :sm="16" :md="12" :lg="10">
            <a-upload-dragger :file-list="fileList" name="avatar" :multiple="true" :remove="handleRemove"
              :before-upload="beforeUpload">
              <p class="ant-upload-drag-icon mb-3">
                <inbox-outlined></inbox-outlined>
              </p>
              <p class="ant-upload-text text-gray-9 mb-1">
                Clickea aquí o arrastra el archivo
              </p>
              <p class="ant-upload-hint text-gray-7">
                JPG, PNG y PDF, peso máximo 5mb
              </p>
            </a-upload-dragger>
          </a-col>
        </a-row>

        <br />
        <br />

        <a-row type="flex" justify="center">
          <a-col class="text-center">
            <a-button type="primary" size="large" block :disabled="fileList.length === 0" :loading="uploading"
              @click="handleUpload">
              {{ uploading ? 'Sube tus documentos' : 'Enviar documentos' }}
            </a-button>
          </a-col>
        </a-row>

        <br /><br />
      </div>
      <div v-else>
        <a-row type="flex" justify="center">
          <a-col class="text-center" :xs="20" :sm="16" :md="12" :lg="11">
            <a-typography-title :level="3" class="text-gray-9 mb-2 ff-urbanist">
              Link invalido
            </a-typography-title>
          </a-col>
        </a-row>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import {
  ExclamationCircleOutlined,
  DownloadOutlined,
  InboxOutlined
} from '@ant-design/icons-vue';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  GET_VALIDATE_REAPPLICATION_DOCUMENT,
  POST_REAPPLICATION_DOCUMENT,
  GET_OLD_DOCUMENT,
  GET_BLANK_DOCUMENT
} from '@/store/types';
import notification from '@/utils/notifications';

export default {
  name: 'ResolicitudDocumento',
  components: {
    'download-outlined': DownloadOutlined,
    'exclamation-circle-outlined': ExclamationCircleOutlined,
    InboxOutlined
  },
  setup() {
    // * Store
    const store = useStore();

    // * Router
    // Obtengo el token de los parametros
    const router = useRouter();
    const route = useRoute();
    const { token } = route.params;
    const { id } = route.params;

    // * Validar el token
    const is_token_valid = ref(true);
    const old_file_ticket_id = ref('');
    const file_insurance_company_id = ref('');

    const old_document_url = ref('');
    const blank_document_url = ref('');

    store
      .dispatch(GET_VALIDATE_REAPPLICATION_DOCUMENT, { token, id })
      .then((resp) => {
        is_token_valid.value = true;
        old_file_ticket_id.value = resp.oldFileTicketId;
        file_insurance_company_id.value = resp.fileInsuranceCompanyId;

        // * Descarga de archivos
        // archivo viejo
        store
          .dispatch(GET_OLD_DOCUMENT, old_file_ticket_id.value)
          .then((resp) => {
            old_document_url.value = resp.data.url;
          })
          .catch(() => {
            old_document_url.value = '#';
          });

        // documento en blanco
        store
          .dispatch(GET_BLANK_DOCUMENT, file_insurance_company_id.value)
          .then((resp) => {
            blank_document_url.value = resp.data.url;
          })
          .catch(() => {
            blank_document_url.value = '#';
          });
      })
      .catch(() => {
        is_token_valid.value = false;
      });

    // * Archivos
    const fileList = ref([]);
    const uploading = ref(false);

    const handleRemove = (file) => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
    };

    const handleUpload = async () => {
      uploading.value = true;
      const formData = new FormData();
      formData.append('oldFileTicketId', old_file_ticket_id.value);
      fileList.value.forEach((file) => {
        formData.append('file', file);
      });

      try {
        await store.dispatch(POST_REAPPLICATION_DOCUMENT, formData);
        router.push({ name: 'ResolicitudDocumentoListo' });
      } catch (error) {
        router.push({ name: 'ResolicitudDocumentoError' });
      }
      uploading.value = false;
    };

    const beforeUpload = (file) => {
      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 5) {
        alert('archivo pesado');
        notification('error', 'Error', 'Archivo pesado, intenta con otro');
      } else {
        fileList.value = [file];
      }

      return false;
    };

    return {
      fileList,
      handleRemove,
      beforeUpload,
      handleUpload,
      uploading,
      is_token_valid,
      old_file_ticket_id,
      file_insurance_company_id,
      old_document_url,
      blank_document_url
    };
  }
};
</script>

<style>
.ant-upload.ant-upload-select.ant-upload-select-text {
  width: 100% !important;
  text-align: center !important;
  /* border: 1px solid #bdd7dd !important;
  border-style: dashed !important; */
  background-color: #f8f9fb !important;
  cursor: pointer !important;
}
</style>
