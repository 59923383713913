const modulo11 = (rut) => {
  if (rut.match(/\b(\d{1,3}(?:(\.?)\d{3}){2}(-?)[\dkK])\b$/)) {
    if (rut.includes('.')) {
      rut = rut.replace('.', '');
      rut = rut.replace('.', '');
    }
    if (rut.includes('-')) {
      rut = rut.replace('-', '');
    }
    const valor = rut;
    const cuerpo = valor.slice(0, -1);
    let dv = valor.slice(-1).toUpperCase();
    rut = `${cuerpo}-${dv}`;

    let suma = 0;
    let multiplo = 2;

    for (let i = 1; i <= cuerpo.length; i++) {
      const index = multiplo * valor.charAt(cuerpo.length - i);
      suma += index;
      if (multiplo < 7) {
        multiplo += 1;
      } else {
        multiplo = 2;
      }
    }
    const dvEsperado = 11 - (suma % 11);

    dv = dv == 'K' ? 10 : dv;
    dv = dv == 0 ? 11 : dv;

    if (dvEsperado != dv) {
      return false;
    }
    return true;
  }
  return false;
};

export default modulo11;
