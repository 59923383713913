<template>
  <a-modal title="Configuración de formulario incorporación" v-model:visible="visibility" @cancel="onClose">
    <a-form name="form" ref="form_ref" :model="form_state" :rules="rules">
      <a-form-item name="type">
        <a-radio-group v-model:value="form_state.type">
          <a-radio :value="1">Formularios tipo</a-radio>
          <a-radio :value="2">Formulario digital</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button @click="closeModal">Cancelar</a-button>
      <a-button type="primary" @click="saveSelection">Guardar</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import {
  ref,
  defineExpose,
  defineProps,
  defineEmits,
  reactive,
  watch
} from 'vue';

const props = defineProps({
  formType: {
    default: null,
    type: Number
  }
});

watch(
  () => props.formType,
  (newVal) => {
    form_state.type = newVal;
  }
);

const emit = defineEmits(['selectedFormType']);

const visibility = ref(false);
const form_ref = ref();
const form_state = reactive({
  type: null
});
const rules = {
  type: [
    {
      required: true,
      message: 'Seleccione una opción',
      type: 'number',
      trigger: 'change'
    }
  ]
};

const openModal = () => {
  visibility.value = true;
};

const closeModal = () => {
  visibility.value = false;
  onClose();
};

const onClose = () => {
  form_ref.value.resetFields();
};

const saveSelection = () => {
  form_ref.value
    .validate()
    .then((resp) => {
      emit('selectedFormType', resp.type);
      closeModal();
    })
    .catch((error) => {
      console.error(error);
    });
};

defineExpose({ openModal });
</script>
