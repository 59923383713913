export const FETCH_DATA_INCONSISTENCIA =
  'inconsistencia/FETCH_DATA_INCONSISTENCIA';
export const GET_DATA_ROWS_INCONSISTENCIA =
  'inconsistencia/GET_DATA_ROWS_INCONSISTENCIA';
export const SET_DATA_ROWS_INCONSISTENCIA =
  'inconsistencia/SET_DATA_ROWS_INCONSISTENCIA';

export const GET_DATA_INCONSISTENCIA_DETAIL =
  'inconsistencia/GET_DATA_INCONSISTENCIA_DETAIL';
export const SET_DATA_INCONSISTENCIA_DETAIL =
  'inconsistencia/SET_DATA_INCONSISTENCIA_DETAIL';
export const GET_RECHAZAR_INCONSISTENCIA =
  'inconsistencia/GET_RECHAZAR_INCONSISTENCIA';
export const SET_RECHAZAR_INCONSISTENCIA =
  'inconsistencia/SET_RECHAZAR_INCONSISTENCIA';
