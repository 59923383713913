<template>
  <a-card bodyStyle="padding: 6px 24px 24px 24px; border: 1px solid #f0f0f0; border-top-width: 0px;" title="Comentarios ticket" :bordered="false">
    <a-list v-if="comments.length > 0" :loading="loading" item-layout="horizontal" :data-source="comments">
      <template #renderItem="{ item }">
        <a-list-item>
          <a-comment :author="item.author" :datetime="item.description">
            <template #content>
              <span v-html="item.title" />
            </template>
          </a-comment>
        </a-list-item>
      </template>
    </a-list>

    <a-row class="mt-2">
      <a-col>
        <a-avatar :size="40">
          <template #icon><user-outlined /></template> </a-avatar></a-col>
      <a-col :span="20" :offset="1">
        <a-textarea :disabled="loading" :autoSize="{ minRows: 3, maxRows: 6 }" v-model:value="valueTextArea"
          placeholder="Escribe aquí un cometario." allow-clear />

        <a-button class="px-4 mt-3" type="primary" :loading="loading"
          @click="createComments(idToTicket)">Comentar</a-button>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { UserOutlined } from '@ant-design/icons-vue';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { GET_BROKER_ID, GET_USER_ROLE } from '../../store/user/types';
import {
  POST_MASIVE_COMMENTS,
  FETCH_USER_BY_ID,
  POST_COMMENTS
} from '../../store/types';
import delay from '../../utils/delay';

export default {
  name: 'Comments',
  components: {
    UserOutlined
  },
  props: {
    comments: {
      type: Array,
      default: []
    },
    idToTicket: {
      default: 0
      // comentado por warning en cobranza y facturacion al no usarlo
      // required:true,
    },
    ticketType: {
      default: 'single'
    }
  },
  setup(props, context) {
    const store = useStore();
    // rol de  usuario
    const user_role = store.getters[GET_USER_ROLE];
    const userInfo = ref('');
    const valueTextArea = ref('');
    const brokerId = store.getters[GET_BROKER_ID];
    const brokerName = ref('');
    const loading = ref(false);

    const getBroker = async () => {
      const response = await store.dispatch(FETCH_USER_BY_ID, brokerId);
      brokerName.value = response.data.body.name;
    };
    getBroker();

    const comments = computed(() => {
      return props.comments;
    });

    const id_modificacion = computed(() => {
      return props.idToTicket;
    });

    const ticket_type = computed(() => {
      return props.ticketType;
    });

    const createComments = async (id) => {
      loading.value = true;

      try {
        if (valueTextArea.value != '' || valueTextArea.value == undefined) {
          let response_comment;

          if (ticket_type.value == 'single') {
            const comment_to_post = {
              ticketId: id,
              message: valueTextArea.value,
              brokerId
            };
            response_comment = await store.dispatch(
              POST_COMMENTS,
              comment_to_post
            );
          } else {
            response_comment = await store.dispatch(POST_MASIVE_COMMENTS, {
              ticketGroupId: id,
              message: valueTextArea.value,
              brokerId
            });
          }

          await delay(1000);

          context.emit('new_comment', {
            title: response_comment.data.message,
            description: response_comment.data.createdAt,
            brokerId
          });

          sucessMessage();
          valueTextArea.value = '';
        }
      } catch (error) {
        console.log(error);
      }

      loading.value = false;
    };

    const sucessMessage = () => {
      message.success('Comentario creado');
    };

    return {
      // TODO: ARREGLAR VARIABLES DUPLICADAS
      // eslint-disable-next-line vue/no-dupe-keys
      comments,
      valueTextArea,
      createComments,
      id_modificacion,
      userInfo,
      brokerId,
      brokerName,
      user_role,
      loading
    };
  }
};
</script>
