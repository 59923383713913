import {
  getCompanyMailbox,
  postCompanyMailbox,
  putCompanyMailbox
} from '../../../network';
import {
  FETCH_COMPANY_MAILBOX,
  MUTATE_COMPANY_MAILBOX,
  GET_COMPANY_MAILBOX,
  POST_COMPANY_MAILBOX,
  PUT_COMPANY_MAILBOX
} from './types';

const store = {};

// * STATE
store.state = {
  mailboxes: []
};

// * GETTERS
store.getters = {
  [GET_COMPANY_MAILBOX](state) {
    return state.mailboxes;
  }
};

// * MUTATIONS
store.mutations = {
  [MUTATE_COMPANY_MAILBOX](state, payload) {
    state.mailboxes = payload.map((obj) => {
      return {
        id: obj.id,
        type: obj.type,
        class: obj.class,
        emails: obj.mailboxes != null ? obj.mailboxes.emails : null
      };
    });
  }
};

// * ACTIONS
store.actions = {
  [FETCH_COMPANY_MAILBOX]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getCompanyMailbox(payload)
        .then((resp) => {
          commit(MUTATE_COMPANY_MAILBOX, resp.data);
          resolve(resp.status);
        })
        .catch((error) => {
          commit(MUTATE_COMPANY_MAILBOX, []);
          reject(error);
        });
    });
  },
  [POST_COMPANY_MAILBOX]({ dispatch }, { id_company, payload }) {
    return new Promise((resolve, reject) => {
      postCompanyMailbox(id_company, payload)
        .then((resp) => {
          resolve(resp.status);
          dispatch(FETCH_COMPANY_MAILBOX, id_company);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_COMPANY_MAILBOX]({ dispatch }, { id_company, id_mailbox, payload }) {
    return new Promise((resolve, reject) => {
      putCompanyMailbox(id_company, id_mailbox, payload)
        .then((resp) => {
          resolve(resp.status);
          dispatch(FETCH_COMPANY_MAILBOX, id_company);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default store;
