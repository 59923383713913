<template>
  <div>
    <a-page-header
      :title="attributes.title"
      style="border: 1px solid #e6ebf0"
      class="mb-2"
      :style="{ background: '#FFFFFF' }"
      @back="() => handleBack()"
      :backIcon="attributes.display_icon"
    >
      <!-- Esta zona es para cargar el estado del ticket y el id del ticket -->
      <template #tags>
        <slot name="tags"> </slot>
      </template>

      <!-- Esta zona se cargaran la logica de los botones -->
      <template #extra>
        <div>
          <!-- En este slots podemos poner los botones que queramos -->
          <slot name="buttons"> </slot>
        </div>
      </template>

      <!-- Breadcrum -->
      <template #breadcrumb>
        <a-breadcrumb :routes="routes">
          <template #itemRender="{ route }">
            <a-breadcrumb-item v-if="routes.indexOf(route) === 0">
              <router-link to="/">
                <home-outlined />
              </router-link>
            </a-breadcrumb-item>

            <span v-if="routes.length === 1"> / </span>

            <span v-if="routes.indexOf(route) === routes.length - 1">
              {{ route.breadcrumbName }}
            </span>
            <router-link v-else :to="`${route.path}`">
              {{ route.breadcrumbName }}
            </router-link>
          </template>
        </a-breadcrumb>
      </template>
    </a-page-header>
  </div>
</template>

<script>
import { HomeOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import {
  computed,
  onUpdated,
  ref
  // defineAsyncComponent,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { ACTION_ACTIVE_KEY_TABS } from '../store/types';

// const Tags = defineAsyncComponent(()=>import('../../components/Tags.vue'));

export default {
  name: 'PageHeader',
  components: {
    // 'tags':Tags,
    'home-outlined': HomeOutlined
  },
  props: {
    breadcrumRoutes: {
      type: Array,
      default: []
    },
    title: {
      type: String,
      default: 'Agrega un titulo'
    },
    displayIcon: {
      type: Boolean,
      default: undefined
    },
    dataTabKey: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const ruta = ref();
    // ARREGÑAR PESTAÑAS DE ACUERDO A LOS TAB CON LA URL AL HACER PARA ATRAS
    const handleBack = () => {
      router.go(-1);
      // if(route.name!=='Poliza'){
      //   router.push({name:'Movimientos',query:{companyId:route.params?.id,status:'Vigente'}});
      // }else{
      //   router.go(-1);
      // }
      // if(route.name=='Movimientos'){
      //   router.go(-1);
      // }
      // if(route.query.status=='Vigente'){
      //   return router.go(-1);
      // }
      // if(route.query.status=='No vigente'){
      //   return router.go(-2);
      // }
    };
    onUpdated(() => {
      store.dispatch(ACTION_ACTIVE_KEY_TABS, route.name);
    });

    // onBeforeRouteUpdate((to,from)=>{
    //   console.log('to',to);
    //   console.log('to',from);
    //   console.log('entraaaa arribaa',`/clientes/empresa/${route.params?.id}`);
    //   if(to.path==`/clientes/empresa/${route.params?.id}`){
    //     console.log('entraaaa',router);
    //   }
    // });
    // * Props
    // Las rutas las creo como variable separada, ya que breadcrum lo necesita asi
    const routes = computed(() => {
      return props.breadcrumRoutes;
    });
    // Aca al contrario, creo todas como un objeto para colocar las propiedades
    const attributes = computed(() => {
      return {
        title: props.title,
        display_icon: props.displayIcon
      };
    });

    return {
      routes,
      ruta,
      handleBack,
      attributes
    };
  }
};
</script>
