export const FETCH_COLLECTION_COMMENTS =
  'collectionTicket/FETCH_COLLECTION_COMMENTS';
export const FETCH_BILLING_DETAIL_LIST =
  'collectionTicket/FETCH_BILLING_DETAIL_LIST';
export const FETCH_COLLECTIONS = 'collectionTicket/FETCH_COLLECTIONS';
export const FETCH_COLLECTION_DATA_SEND_RRHH =
  'collectionTicket/FETCH_COLLECTION_DATA_SEND_RRHH';
export const FETCH_FILES_COLLECTION_TICKETS =
  'collectionTicket/FETCH_FILES_COLLECTION_TICKETS';
export const FETCH_RETROACTIVE_ADJUSTMENT_BY_COLLECTION =
  'collectionTicket/FETCH_RETROACTIVE_ADJUSTMENT_BY_COLLECTION';

export const POST_COLLECTION_COMMENTS =
  'collectionTicket/POST_COLLECTION_COMMENTS';
export const POST_COLLECTION_DATA_SEND_RRHH =
  'collectionTicket/POST_COLLECTION_DATA_SEND_RRHH';
export const PATCH_MARK_INVOICE_PAID =
  'collectionTicket/PATCH_MARK_INVOICE_PAID';
export const POST_COLLECTION_HUMAN_SEND =
  'collectionTicket/POST_COLLECTION_HUMAN_SEND';
// export const POST_REPROCESSES = 'collectionTicket/POST_REPROCESSES';
// export const POST_REQUESTS = 'collectionTicket/POST_REQUESTS';

export const MUTATE_COLLECTION_COMMENTS =
  'collectionTicket/MUTATE_COLLECTION_COMMENTS';
export const MUTATE_BILLING_DETAIL_LIST =
  'collectionTicket/MUTATE_BILLING_DETAIL_LIST';
export const MUTATE_COLLECTIONS = 'collectionTicket/MUTATE_COLLECTIONS';
export const MUTATE_COLLECTION_DATA_RRHH =
  'collectionTicket/MUTATE_COLLECTION_DATA_RRHH';
export const MUTATE_FILES_COLLECTION_TICKET =
  'collectionTicket/MUTATE_FILES_COLLECTION_TICKET';
export const MUTATE_RETROACTIVE_ADJUSTMENT_BY_COLLECTION =
  'collectionTicket/MUTATE_RETROACTIVE_ADJUSTMENT_BY_COLLECTION';

export const GET_COLLECTION_COMMENTS =
  'collectionTicket/GET_COLLECTION_COMMENTS';
export const GET_BILLING_DETAIL_LIST =
  'collectionTicket/GET_BILLING_DETAIL_LIST';
export const GET_COLLECTIONS = 'collectionTicket/GET_COLLECTIONS';
export const GET_COLLECTION_DATA_RRHH =
  'collectionTicket/GET_COLLECTION_DATA_RRHH';
export const GET_FILES_COLLECTION_TICKET =
  'collectionTicket/GET_FILES_COLLECTION_TICKET';
export const GET_RETROACTIVE_ADJUSTMENT_BY_COLLECTION =
  'collectionTicket/GET_RETROACTIVE_ADJUSTMENT_BY_COLLECTION';

export const PATCH_APROVE_COLLECTION_TICKET =
  'collectionTicket/PATCH_APROVE_COLLECTION_TICKET';
