import { newConfig } from '../config';
import config from '../config';

const appConfig = config();
const { v1 } = appConfig.apiVersion;

export default class TicketService {
  constructor(client) {
    this.client = client;
  }

  async getTicket(ticketId) {
    return this.client.get(
      `${newConfig.services.proxy.url}${newConfig.services.proxy.core}/${v1}/ticket/${ticketId}`
    );
  }

  async getDependants(ticketId) {
    return this.client.get(
      `${newConfig.services.proxy.url}${newConfig.services.proxy.core}/${v1}/ticket/${ticketId}/dependants`
    );
  }

  async deleteEmployeeDependant(ticketId, employeeDependantId) {
    return this.client.delete(
      `${newConfig.services.proxy.url}${newConfig.services.proxy.core}/${v1}/ticket/${ticketId}/employee-dependants/${employeeDependantId}`
    );
  }

  async getDependantsIncorporations(ticketId) {
    return this.client.get(
      `${newConfig.services.proxy.url}${newConfig.services.proxy.core}/${v1}/ticket/${ticketId}/dependants-incorporations`
    );
  }
}
