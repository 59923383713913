<template>
  <div>
    <a-modal
      title="Nomina original"
      v-model:visible="modal_props.visible"
      @cancel="closeModal"
    >
      <a-row>
        <a-col class="">
          <p>Selecciona archivo con nomina original.</p>
        </a-col>
        <a-col>
          <a-form
            name="form"
            layout="vertical"
            ref="formRef"
            :model="formState"
            :rules="rules"
          >
            <a-form-item name="file">
              <a-upload
                :disabled="loadingFile"
                name="file"
                :file-list="formState.file"
                :remove="handleRemove"
                :before-upload="beforeUpload"
                accept=".xlsx,.xlsb,.xls,.xml"
              >
                <a-button :style="{ width: '470px' }">
                  <!-- <upload-outlined></upload-outlined> -->
                  Carga Nomina
                </a-button>
              </a-upload>
            </a-form-item>
          </a-form>
        </a-col>
        <a-col> </a-col>
      </a-row>
      <template #footer>
        <a-button
          @click="closeModal"
          :loading="loadingFile"
          :disabled="loadingFile"
          >Cancelar</a-button
        >
        <a-button
          type="primary"
          @click="sendFile"
          :loading="loadingFile"
          :disabled="loadingFile"
          >Enviar</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref, computed, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import {
  PATCH_ORIGINAL_PAYROLL,
  GET_FILLES_ORIGINAL_PAYROLL
} from '../../../store/types';
// import { UploadOutlined } from '@ant-design/icons-vue';
import notificacion from '../../../utils/notifications';

export default {
  name: 'OriginalPayroll',
  components: {
    // 'upload-outlined': UploadOutlined,
  },
  props: {
    collectionId: {
      type: Number
    }
  },
  setup(props) {
    const formRef = ref();
    const loadingFile = ref(false);
    const formState = reactive({
      file: []
    });
    // * Variables
    const store = useStore();
    const idCollection = computed(() => props.collectionId);
    // * Propiedades del modal
    const modal_props = reactive({
      visible: false,
      loading: false,
      values: []
    });

    const pagination_props = reactive({
      total: 0,
      pageSize: 5,
      current: 1
    });

    const changeCurrentPage = (page) => {
      pagination_props.current = page.current;
    };

    // * Metodos del modal
    const openModal = async () => {
      modal_props.visible = true;
      modal_props.loading = true;
      // Primero obtengo la data de las observaciones del collection para mostrarl
    };

    const beforeUpload = (file) => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        message.error('El archivo excede el peso máximo 5MB');
      } else if (
        file.type == 'image/png' ||
        file.type == 'image/jpg' ||
        file.type == 'application/pdf'
      ) {
        // que si son archivos de ese tipo tire ese mensaje y no se suban los archivos
        message.error('El archivo debe ser formato excel');
      } else if (formState.file <= 1) {
        // si el archivo es igual o menor a 1 cargue el archivo si no
        formState.file = [...formState.file, file];
      } else {
        // si no remplazee el archivo y cargue uno nuevo
        handleRemove(file);
        formState.file = [...formState.file, file];
      }
      return false;
    };
    const handleRemove = (file) => {
      if (file.id) {
        formState.removeFiles = [...formState.removeFiles, file.id];
      }
      const index = formState.file.indexOf(file);
      const newFileList = formState.file.slice();
      newFileList.splice(index, 1);
      formState.file = newFileList;
    };
    const checkFile = async (rule, value) => {
      if (value >= 0) {
        return Promise.reject('Ingresa nomina original');
      }
      return Promise.resolve();
    };

    const rules = {
      file: [
        {
          required: true,
          validator: checkFile,
          type: 'object'
        }
      ]
    };

    const closeModal = () => {
      modal_props.visible = false;
    };
    const sendFile = () => {
      loadingFile.value = true;
      formRef.value
        .validate()
        .then(async () => {
          await store.dispatch(PATCH_ORIGINAL_PAYROLL, {
            collectionId: props.collectionId,
            payload: formState
          });
          loadingFile.value = false;
          closeModal();
          notificacion(
            'success',
            'Correcto',
            'Se ha cargado la nomina original correctamente.'
          );
        })
        .catch((err) => {
          loadingFile.value = false;
          notificacion('error', 'error', err.data.message);
          console.log('err', err);
        });
    };

    const fileExist = computed(
      () => store.getters[GET_FILLES_ORIGINAL_PAYROLL]
    );

    watchEffect(() => {
      // store.dispatch(FETCH_FILES_ORIGINAL_PAYROLL,props.collectionId);
      if (fileExist.value) {
        return (formState.file = fileExist.value);
      }
    });

    return {
      modal_props,
      openModal,
      closeModal,
      changeCurrentPage,
      pagination_props,
      formState,
      beforeUpload,
      handleRemove,
      formRef,
      sendFile,
      rules,
      loadingFile,
      idCollection,
      fileExist
    };
  }
};
</script>
