import Inconsistencia from './reprocesses/inconsistencia';
import cambioAdicional from './reprocesses/cambio&Adicional';
import anulacion from './reprocesses/anulacion';
import general from './reprocesses/general';
// import notaCredito from './requests/notaCredito';
// import notaDebito from './requests/notaDebito';
// import ajusteRetroactivo from './requests/ajusteRetroactivo';
import requests from './requests';
import procesos from './procesos';
import collectionTicket from './collectionTicket';

const store = {};
store.modules = {
  Inconsistencia,
  cambioAdicional,
  anulacion,
  requests,
  procesos,
  collectionTicket,
  general
};
store.state = {};
store.getters = {};
store.mutations = {};
store.actions = {};

export default store;
