<template>
  <div>
    <a-collapse v-model:activeKey="collapse_open">
      <a-collapse-panel key="1" header="Ajuste retroactivo">
        <retroactive-table :data="retroactive_adjustment" />
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  GET_RETROACTIVE_ADJUSTMENT_BY_COLLECTION,
  FETCH_RETROACTIVE_ADJUSTMENT_BY_COLLECTION
} from '../../../../store/types';
import RetroactiveAdjustmentTable from './RetroactiveAdjustmentTable.vue';

export default {
  name: 'RetroactiveAdjustment',
  components: {
    'retroactive-table': RetroactiveAdjustmentTable
  },
  props: {
    ticketId: {
      type: [String, Number],
      default: 1
    }
  },
  setup(props) {
    const store = useStore();
    const collapse_open = ref();
    const route = useRoute();

    const retroactive_adjustment = computed(() => {
      const data = store.getters[GET_RETROACTIVE_ADJUSTMENT_BY_COLLECTION];
      return data.map((e) => ({
        key: e.id,
        name:
          e.type == 'RETROACTIVE_ADJUSTMENT_REQUEST'
            ? 'Ajuste Retroactivo'
            : '',
        tags: e.state,
        details: e.id
      }));
    });

    const retroactiveAdjustmentData = (params = '') => {
      try {
        store.dispatch(FETCH_RETROACTIVE_ADJUSTMENT_BY_COLLECTION, {
          collection_id: props.ticketId,
          query_params: params
        });
      } catch (error) {
        console.log('error', error);
      }
    };

    const retroactive_list = ref([
      {
        key: 1,
        name: 'Ajuste Retroactivo',
        tags: 'Aprobado',
        details: 1
      }
    ]);

    onMounted(() => {
      retroactiveAdjustmentData();
    });

    watch(
      () => route.query,
      () => {
        const { query } = route;
        const start_query = Object.keys(route.query).length === 0 ? '' : '?';
        const query_params =
          start_query +
          Object.keys(query)
            .map((q) => `${q}=${query[q]}`)
            .join('&');
        retroactiveAdjustmentData(query_params);
      }
    );

    return {
      collapse_open,
      retroactive_list,
      retroactive_adjustment
    };
  }
};
</script>
