<template>
  <div v-if="$route.name === 'AltasCargas'">
    <page-header :breadcrum-routes="headerRoutes" title="Altas cargas">
      <template #buttons>
        <a-button type="primary" @click="showModal">Crear alta</a-button>
      </template>
    </page-header>

    <table-resume type="Altas de cargas" />

    <a-card class="mt-2">
      <a-row type="flex" justify="space-between" align="middle">
        <a-col>
          <a-row class="mt-2">
            <a-typography-title :level="4" class="text-gray-8 mr-5">
              Stock altas cargas
            </a-typography-title>
          </a-row>
        </a-col>
        <a-col>
          <a-range-picker v-model:value="dateRange" :value-format="dateFormat" :format="[dateFormat, dateFormat]" />
        </a-col>
      </a-row>
      <a-row class="mt-3">
        <a-col :span="24">
          <tickets-table :dates="dateRange" type="Altas de cargas" />
        </a-col>
      </a-row>
    </a-card>

    <a-modal v-model:visible="showNewDependentIncorporationModal" title="Nueva solicitud de alta de cargas" centered
      :maskClosable="false">
      <template #footer>
        <a-row type="flex" justify="end">
          <a-col>
            <a-space>
              <router-link class="justify-content-center d-flex" :to="{ name: 'AltaCargasIndividual' }">
                <a-button key="1" type="primary">Individual</a-button>
              </router-link>
              <router-link class="justify-content-center d-flex" :to="{ name: 'AltaMasiva' }">
                <a-button key="2" type="primary" disabled>Masiva</a-button>
              </router-link>
            </a-space>
          </a-col>
        </a-row>
      </template>
      <a-typography-title class="text-gray-9 fw-regular" :level="3">¿Que tipo de solicitud de alta de cargas deseas
        crear?</a-typography-title>
    </a-modal>
  </div>
  <router-view :key="$route.path"></router-view>
</template>

<script>
import { useRoute } from 'vue-router';
import { ref, defineAsyncComponent, onMounted } from 'vue';

import PageHeader from '@/components/PageHeader.vue';
import TableResume from '@/components/TableResume.vue';

const TicketsTable = defineAsyncComponent(() =>
  import('@/components/Tables/TicketsTableTest.vue')
);

export default {
  components: {
    'tickets-table': TicketsTable,
    'page-header': PageHeader,
    'table-resume': TableResume
  },
  setup() {
    const route = useRoute();
    const headerRoutes = [
      {
        breadcrumbName: 'Altas cargas'
      }
    ];

    const showNewDependentIncorporationModal = ref(false);

    const showModal = () => {
      showNewDependentIncorporationModal.value = true;
    };

    const dateFormat = 'YYYY-MM-DD';
    const dateRange = ref([]);
    onMounted(() => {
      if (
        route.query.startDate != undefined &&
        route.query.endDate != undefined
      ) {
        dateRange.value.push(route.query.startDate);
        dateRange.value.push(route.query.endDate);
      }
    });

    return {
      headerRoutes,
      showNewDependentIncorporationModal,
      showModal,
      dateRange,
      dateFormat
    };
  }
};
</script>
