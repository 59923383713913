<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="invoice_list"
      :loading="loading_table"
      :pagination="pagination"
      @change="changeCurrentPage"
    >
      <!-- Filtro de lupa normal -->
      <template
        #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Buscar por ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon><search-outlined /></template>
            Buscar
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            Limpiar
          </a-button>
        </div>
      </template>

      <template #idRender="{ text }">
        <p># {{ text }}</p>
      </template>

      <template #policyRender="{ text: policies }">
        <p>{{ policies.join(', ') }}</p>
      </template>

      <!-- Lupa de busqueda -->
      <template #filterIcon="filtered">
        <search-outlined
          :style="{ color: filtered.filtered ? '#108ee9' : undefined }"
        />
      </template>

      <template #stateRender="{ text: state }">
        <a-tag :color="getTagColor(state)">
          {{ getTagText(state)?.toUpperCase() }}
        </a-tag>
      </template>

      <template #options="{ text, record }">
        <div style="display: flex; justify-content: space-around">
          <a-tooltip color="#05045D" title="Ver observaciones">
            <a-button
              type="link"
              size="small"
              :disabled="
                record.state === 'WAITING' || record.state === 'PROCESSING'
              "
              @click="openObservationModal(text)"
            >
              <template #icon>
                <eye-outlined class="blue-icon" />
              </template>
            </a-button>
          </a-tooltip>

          <a-tooltip color="#05045D" title="Editar factura">
            <a-button
              type="link"
              size="small"
              :disabled="
                record.state === 'WAITING' || record.state === 'PROCESSING'
              "
              @click="openInvoiceEditionModal(text)"
            >
              <template #icon>
                <edit-outlined class="blue-icon" />
              </template>
            </a-button>
          </a-tooltip>

          <a-tooltip color="#05045D" title="Reprocesar factura">
            <a-button
              type="link"
              size="small"
              :disabled="
                record.state === 'WAITING' || record.state === 'PROCESSING'
              "
              @click="openReprocessModal(text)"
            >
              <template #icon>
                <file-sync-outlined class="blue-icon" />
              </template>
            </a-button>
          </a-tooltip>

          <a-tooltip color="#05045D" title="Asociar póliza">
            <a-button
              type="link"
              size="small"
              :disabled="
                record.state === 'WAITING' || record.state === 'PROCESSING'
              "
              @click="openAssociationModal(text)"
            >
              <template #icon>
                <file-text-outlined class="blue-icon" />
              </template>
            </a-button>
          </a-tooltip>

          <a-tooltip color="#05045D" title="Eliminar">
            <a-button type="link" size="small" @click="openDeleteModal(text)">
              <template #icon>
                <delete-outlined class="red-icon" />
              </template>
            </a-button>
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <!-- Modal de edicion datos de factura -->
    <edit-invoice-data
      ref="edit_invoice_modal"
      @policyEdition="policyEditionOk"
    />

    <!-- Modal para la asociacion de polizas -->
    <policy-association
      ref="policy_association_modal"
      @policyAssociation="policyAssociationOk"
    />

    <!-- Modal de observaciones -->
    <observation-modal ref="observacion_modal" />

    <!-- Modal de reprocesar factura -->
    <reprocess-invoice
      @reprocessedInvoice="reprocessedInvoice"
      ref="reprocess_modal"
    />

    <!-- Modal para eliminar factura -->
    <delete-invoice ref="invoice_delete" @deletedInvoice="deletedInvoice" />
  </div>
</template>

<script>
import {
  computed,
  onMounted,
  onBeforeUnmount,
  reactive,
  ref,
  toRefs,
  watch
} from 'vue';
import { useStore } from 'vuex';
import {
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
  FileSyncOutlined,
  EditOutlined,
  FileTextOutlined
} from '@ant-design/icons-vue';
import notification from '../../../../utils/notifications';
import { FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES } from '../../../../store/types';
import PolicyAssociationModal from '../../detail/policy/PolicyAssociationModal.vue';
import BillEditInvoiceDataModal from '../bill/BillEditInvoiceDataModal.vue';
import BillObservationsModal from '../bill/BillObservationsModal.vue';
import BillReprocessInvoice from '../bill/BillReprocessInvoice.vue';
import BillDeleteInvoiceModal from '../bill/BillDeleteInvoiceModal.vue';
import getTagColor from '../../../../utils/getTagColor';
import getTagText from '../../../../utils/getTagText';
import delay from '@/utils/delay';

export default {
  props: {
    collectionFormId: {
      type: [String, Number],
      default: ''
    },
    collectionType: {
      type: String,
      default: 'COLLECTION'
    },
    insuranceCompanyId: {
      type: [String, Number],
      default: ''
    },
    collectionStartFormState: {
      type: String,
      default: ''
    }
  },
  components: {
    'search-outlined': SearchOutlined,
    'delete-outlined': DeleteOutlined,
    'eye-outlined': EyeOutlined,
    'file-sync-outlined': FileSyncOutlined,
    'edit-outlined': EditOutlined,
    'edit-invoice-data': BillEditInvoiceDataModal,
    'policy-association': PolicyAssociationModal,
    'observation-modal': BillObservationsModal,
    'reprocess-invoice': BillReprocessInvoice,
    'file-text-outlined': FileTextOutlined,
    'delete-invoice': BillDeleteInvoiceModal
  },
  setup(props) {
    // * Props
    const coll_form_id = computed(() => props.collectionFormId);
    const ins_comp_id = computed(() => props.insuranceCompanyId);
    const type = computed(() => props.collectionType);

    // * Data
    const store = useStore();

    // * Obtencion de data
    const invoice_list = ref([]);
    const getInvoiceDataList = (data) => {
      const invoices = data;

      if (invoices != undefined && invoices != []) {
        const refactored_invoices = invoices.map((obj) => {
          return {
            id: obj.id,
            key: obj.id,
            bill: obj.objectName,
            billNumber: obj.folio,
            issueDate: obj.issueDate,
            bussinessName:
              obj.companyRecipient != undefined
                ? obj.companyRecipient.businessName
                : '',
            state: obj?.state,
            observations: obj.issuesCount,
            policyNumber:
              obj.payrollPolicies != undefined ? obj.payrollPolicies : [],
            options: obj.id
          };
        });

        return refactored_invoices.sort((a, b) => a.id - b.id);
      }

      return [];
    };

    const uploadDummyFile = (file_obj) => {
      invoice_list.value = [...invoice_list.value, file_obj];
    };

    const loading_table = ref(false);
    const getInsuranceCompanyInvoices = async () => {
      loading_table.value = true;
      try {
        const resp = await store.dispatch(
          FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES,
          {
            collection_id: coll_form_id.value,
            insurance_id: ins_comp_id.value,
            type: type.value
          }
        );

        console.log('Mis archivos', resp);

        invoice_list.value = getInvoiceDataList(resp.data);

        await delay(1000);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading_table.value = false;
    };

    const interval = ref(0);

    const reloadBillData = () => {
      interval.value = setInterval(async () => {
        getInsuranceCompanyInvoices();
      }, 20000);
    };

    watch(invoice_list, (new_val) => {
      const invalid_state = new_val.some(
        (obj) =>
          obj.state != 'PROCESSED' &&
          obj.state != 'ERROR' &&
          obj.state != 'PROCESSED_ISSUES'
      );

      if (invalid_state === true) {
        if (interval.value === 0) {
          reloadBillData();
        }
      } else {
        clearInterval(interval.value);
        interval.value = 0;
      }
    });

    // * Tabla
    const searchInput = ref();
    const state = reactive({
      searchText: '',
      searchedColumn: ''
    });
    const columns = [
      {
        title: 'N°',
        width: 100,
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'idRender',
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        },
        onFilter: (value, record) =>
          record.id.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Factura',
        width: 100,
        dataIndex: 'bill',
        key: 'bill',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        },
        onFilter: (value, record) =>
          record.bill.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'N° factura',
        width: 100,
        dataIndex: 'billNumber',
        key: 'billNumber',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        },
        onFilter: (value, record) =>
          record.billNumber
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Fecha emisión',
        width: 100,
        dataIndex: 'issueDate',
        key: 'issueDate',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        },
        onFilter: (value, record) =>
          record.issueDate
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Razón social',
        width: 100,
        dataIndex: 'bussinessName',
        key: 'bussinessName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        },
        onFilter: (value, record) =>
          record.bussinessName
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Estado',
        width: 100,
        dataIndex: 'state',
        key: 'state',
        align: 'center',
        slots: {
          customRender: 'stateRender'
        },
        filters: [
          {
            text: 'Completo',
            value: 'completo'
          },
          {
            text: 'Incompleto',
            value: 'incompleto'
          },
          {
            text: 'Procesando',
            value: 'procesando'
          },
          {
            text: 'Error',
            value: 'error'
          },
          {
            text: 'Subiendo',
            value: 'subiendo'
          }
        ],
        onFilter: (value, record) => record.state === value
      },
      {
        title: 'Observaciones',
        width: 100,
        dataIndex: 'observations',
        key: 'observations',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        },
        onFilter: (value, record) =>
          record.observations
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'N° de póliza',
        width: 100,
        dataIndex: 'policyNumber',
        key: 'policyNumber',
        slots: {
          customRender: 'policyRender',
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        },
        onFilter: (value, record) =>
          record.policyNumber
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        }
      },
      {
        title: 'Opciones',
        width: 100,
        align: 'center',
        dataIndex: 'options',
        slots: {
          customRender: 'options'
        },
        key: 'options'
      }
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
    };

    const handleReset = (clearFilters) => {
      clearFilters();
      state.searchText = '';
    };

    // * Paginacion
    const total_items = computed(() => invoice_list?.value?.length);
    const pagination = reactive({
      total: total_items.value,
      pageSize: 10,
      current: 1
    });

    const changeCurrentPage = (page) => {
      pagination.current = page.current;
    };

    // * Observation modal
    const observacion_modal = ref(null);
    const openObservationModal = (id) => {
      observacion_modal.value.openModal(id);
    };

    // * Reprocesar factura
    const reprocess_modal = ref();
    const openReprocessModal = (invoice_id) => {
      reprocess_modal.value.openModal(invoice_id);
    };

    const reprocessedInvoice = () => {
      getInsuranceCompanyInvoices();
    };

    // * Eliminar factura
    const invoice_delete = ref();
    const openDeleteModal = (id) => {
      invoice_delete.value.openModal(id);
    };

    const deletedInvoice = () => {
      getInsuranceCompanyInvoices();
    };

    // * Edicion de facturas modal
    const edit_invoice_modal = ref();
    const openInvoiceEditionModal = (invoice_id) => {
      edit_invoice_modal.value.openModal(invoice_id);
    };

    const policyEditionOk = () => {
      getInsuranceCompanyInvoices();
    };

    // * Asociacion de polizas modal
    const policy_association_modal = ref();
    const openAssociationModal = (id) => {
      policy_association_modal.value.openModal(id);
    };

    const policyAssociationOk = () => {
      getInsuranceCompanyInvoices();
    };

    onMounted(() => {
      getInsuranceCompanyInvoices();
    });

    onBeforeUnmount(() => {
      clearInterval(interval.value);
    });

    return {
      invoice_list,
      ...toRefs(state),
      handleSearch,
      openReprocessModal,
      reprocess_modal,
      columns,
      handleReset,
      searchInput,
      total_items,
      changeCurrentPage,
      pagination,
      policy_association_modal,
      openInvoiceEditionModal,
      openAssociationModal,
      edit_invoice_modal,
      loading_table,
      getInsuranceCompanyInvoices,
      policyAssociationOk,
      policyEditionOk,
      getTagColor,
      getTagText,
      observacion_modal,
      openObservationModal,
      invoice_delete,
      openDeleteModal,
      deletedInvoice,
      reprocessedInvoice,
      uploadDummyFile
    };
  }
};
</script>
