<template>
  <a-collapse class="mb-2" v-model:activeKey="activeKey">
    <a-collapse-panel key="1" header="Tickets asociados">
      <a-row>
        <a-col :span="12">{{ data.ticketType }}</a-col>
        <a-col :span="8">
          <tags :stateId="stateId" />
        </a-col>
        <a-col :span="4" align="end">
          <span>
            <a>
              <a-button type="link" @click="redirectType(data.ticketId)">Ir a ticket</a-button>
            </a>
          </span>
        </a-col>
      </a-row>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Tags from '../Tags.vue';

export default {
  name: 'SolicitudesAsociadas',
  components: {
    tags: Tags
  },
  props: {
    data: '',
    state: '',
    tagColor: '',
    stateId: ''
  },
  setup(props) {
    const activeKey = ref(['0']);
    watch(activeKey, () => { });
    const router = useRouter();
    const redirectType = (c) => {
      switch (props.data.ticketType) {
        case 'Alta':
          router.push({ name: 'AltasDetalle', params: { id: c } });
          break;
        case 'Baja':
          router.push({ name: 'BajaDetail', params: { id: c } });
          break;
        case 'Bajas de cargas':
          router.push({ name: 'BajaCargasDetalle', params: { id: c } });
          break;
        case 'Altas de cargas':
          router.push({ name: 'AltaCargasDetalle', params: { id: c } });
          break;
        case 'Modificacion':
          router.push({ name: 'DetalleModificaciones', params: { id: c } });
          break;
        case 'Traspaso':
          router.push({ name: 'TransferDetail', params: { id: c } });
          break;
      }
    };

    return {
      activeKey,
      redirectType
    };
  }
};
</script>
