<template>
  <div>
    <a-card>
      <a-typography-title :level="5">
        {{ comments_title }}
      </a-typography-title>

      <div
        v-if="comments_data.length > 0"
        style="overflow-y: auto; max-height: 180px;"
      >
        <a-list item-layout="horizontal" :data-source="comments_data">
          <template #renderItem="{ item }">
            <a-list-item>
              <a-comment
                :author="item.author"
                :datetime="item.date"
              >
                <template #content>
                  <span v-html="item.message" />
                </template>
              </a-comment>
            </a-list-item>
          </template>
        </a-list>
      </div>

      <a-row class="mt-3">
        <a-col>
          <a-avatar :size="40">
            <template #icon>
              <user-outlined />
            </template>
          </a-avatar>
        </a-col>
        <a-col :span="20" :offset="1">
          <a-textarea
            :disabled="loading"
            :autoSize="{ minRows: 3, maxRows: 6 }"
            v-model:value="textarea_value"
            placeholder="Escribe aquí un cometario."
            allow-clear
          />

          <a-button
            class="px-4 mt-3"
            type="primary"
            @click="createComment(idToTicket)"
            :loading="loading"
            :disabled="textarea_value.length === 0"
            >Comentar</a-button
          >
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { UserOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  GET_BROKER_ID,
  POST_COLLECTION_GROUP_COMMENTS,
  POST_COLLECTION_COMMENTS
} from '../store/types';

export default {
  name: 'CommentsTest',
  components: {
    UserOutlined
  },
  props: {
    title: {
      type: String,
      default: 'Agrega un titulo'
    },
    data: {
      type: Array,
      default: []
    },
    idToTicket: {
      default: 0
      // comentado por warning en cobranza y facturacion al no usarlo
      // required:true,
    }
  },
  setup(props, context) {
    const comments_title = computed(() => props.title);
    const comments_data = computed(() => props.data);
    const route = useRoute();
    const store = useStore();
    const brokerId = store.getters[GET_BROKER_ID];
    const loading = ref(false);
    const textarea_value = ref('');

    // Al momento de crear un comentario, lo que vamos hacer es
    // mandar al componente padre el mensaje que acabamos de escribir en el textarea
    // asi para quien tiene el control del componente sea quien maneje la data de una manera concreta
    // Asi que el componente padre para que tome eeste metodo, tiene que llamarlo asi
    /*
    <comentario @commentCreation="crearNuevoComentario" />
    ...
    const crearNuevoComentario = (value) =>{
        console.log(value);
    }
    */
    const createComment = async (id) => {
      if (route.name == 'DetalleCobranzaFacturacion') {
        loading.value = true;
        try {
          const comment_to_post = {
            message: textarea_value.value,
            brokerId
          };
          const response_comment = await store.dispatch(
            POST_COLLECTION_GROUP_COMMENTS,
            { id, payload: comment_to_post }
          );
          context.emit('commentCreation', {
            message: response_comment.data.message,
            date: response_comment.data.createdAt,
            brokerId
          });
          loading.value = false;
        } catch (error) {
          loading.value = false;
          console.log('error', error);
        }
      }
      if (route.name == 'detalleTicketCobranza') {
        loading.value = true;
        try {
          const comment_to_post = {
            message: textarea_value.value,
            brokerId
          };
          const response_comment = await store.dispatch(
            POST_COLLECTION_COMMENTS,
            { id, payload: comment_to_post }
          );
          context.emit('commentCreation', {
            message: response_comment.data.message,
            date: response_comment.data.createdAt,
            brokerId
          });
          loading.value = false;
        } catch (error) {
          loading.value = false;
          console.log('error', error);
        }
      }

      textarea_value.value = '';
    };

    return {
      comments_title,
      comments_data,
      loading,
      textarea_value,
      createComment
    };
  }
};
</script>
