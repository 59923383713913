import { createStore } from 'vuex';
import token from '../store/token';
import user from '../store/user';
import broker from '../store/broker';
import ticket from '../store/ticket';
import clients from '../store/clients';
import aseguradoras from '../store/aseguradoras';
import employee from '../store/employee';
import reset from '../store/reset';
import ejecutivos from '../store/ejecutivos';
import tabs from '../store/tabs';
import stockGlobal from '../store/stockGlobal';
import dashboard from '../store/dashboard';
import tables from '../store/tables';
import prueba from '../store/prueba';
import stockCobranza from '../store/cobranza y facturacion';
import digitalform from '../store/digitalForm';
import dependents from '../store/dependents';

export default createStore({
  state: {
    // Data para el buscador del navbar
    search_modal: false,
    // almacenando data de formulario
    incorporationForm: {},
    incorporationToken: { id: '', token: '', ticketId: '' },
    uploadedDocs: false,
    uploadedCI: { anverso: false, reverso: false }
  },
  getters: {},
  // Metodos que se le hacen a las variables
  mutations: {
    changeName(state, value) {
      state.nombre = value;
    },
    changeEmail(state, value) {
      state.email = value;
    },
    setJwtExpiration(state, value) {
      state.expiration_token = value;
    },
    searcherInteraction(state) {
      state.search_modal = !state.search_modal;
    },
    setIncorporationToken(state, value) {
      state.incorporationToken = value;
    },
    updateIncCharge(state, value) {
      state.incorporationForm = Object.assign(state.incorporationForm, {
        carga: value
      });
    },
    updateDocs(state, value) {
      state.uploadedDocs = value;
    },
    uploadAnverso(state, value) {
      state.uploadedCI.anverso = value;
    },
    uploadReverso(state, value) {
      state.uploadedCI.reverso = value;
    }
  },
  // Aca se llama a las mutaciones
  actions: {
    // Incorporation Form Methods
    setIncorporationToken(state, value) {
      state.incorporationToken = value;
    },
    // Manage cargas
    updateIncCharge(state, value) {
      state.incorporationForm = Object.assign(state.incorporationForm, {
        carga: value
      });
    },
    updateDocs(state, value) {
      state.uploadedDocs = value;
    },
    uploadCI(state, value) {
      state.uploadedCI = value;
    },
    actionSearcher({ commit }) {
      commit('searcherInteraction');
    }
  },

  // Para obtener data
  // actions: {
  //   [RESET_STATE]({ commit }) {
  //     commit(RESET_USER_STATE);
  //   }
  // },
  modules: {
    token,
    user,
    ticket,
    clients,
    aseguradoras,
    employee,
    reset,
    ejecutivos,
    tabs,
    stockGlobal,
    dashboard,
    tables,
    prueba,
    stockCobranza,
    broker,
    digitalform,
    dependents
  },
  plugins: []
});
