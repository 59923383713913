<template>
  <a-row type="flex" justify="center" align="middle" style="min-height: 100vh">
    <a-spin v-if="state.loading" size="large" />
    <a-col v-if="!state.loading" :sm="20" :md="14" :lg="10">
      <a-row type="flex" justify="center" align="middle">
        <img src="@/assets/images/insured_form/ready-img.svg" width="210" class="img-margin-ready" />
      </a-row>
      <a-row type="flex" justify="center" align="middle">
        <CheckCircleFilled class="text-green-6 display-3 mb-3" />
      </a-row>
      <a-row type="flex" justify="center" align="middle">
        <a-typography-title :level="4" class="text-gray-8 fw-semibold">Tus documentos han sido recepcionados
          satisfactoriamente.</a-typography-title>
        <br />
      </a-row>
      <a-row type="flex" justify="center" align="middle">
        <a-typography-text class="text-gray-7">
          Recibirás un correo de confirmación cuando tus cargas se encuentren
          aseguradas.
        </a-typography-text>
      </a-row>
      <br />
      <br />
      <a-row type="flex" justify="center" align="middle">
        <a-typography-title class="ff-urbanist" :level="3">
          {{ state?.form?.ticket?.company?.businessName }}
        </a-typography-title>
      </a-row>
      <a-row type="flex" justify="center" align="middle">
        <a-typography-paragraph class="small">¡Muchas gracias!</a-typography-paragraph>
      </a-row>
    </a-col>
  </a-row>
</template>

<script setup>
import { reactive, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { FETCH_DEPENDENTS_INCORPORATION_FORM } from '@/store/types';
import { CheckCircleFilled } from '@ant-design/icons-vue';

const route = useRoute();
const router = useRouter();
const store = useStore();
const state = reactive({ loading: true });

onMounted(async () => {
  try {
    const form = await store.dispatch(
      FETCH_DEPENDENTS_INCORPORATION_FORM,
      route.params.token
    );
    state.form = form;
    if (form.state !== 'En revision Bewell') {
      router.push({ name: 'DependentsIncorporationFormError' });
    } else if (form.isTokenExpired) {
      router.push({ name: 'DependentsIncorporationFormExpired' });
    }
    state.loading = false;
  } catch (error) {
    router.push({ name: 'DependentsIncorporationFormError' });
  }
});
</script>
