<template>
  <div>
    <collapsable-table :data="data_list" title="Reprocesos asociados">
      <template v-if="hideCreation == false" #extra>
        <a-dropdown
          v-if="
            stateReprocesses == 'BEWELL_VALIDATION' ||
            stateReprocesses == 'HR_VALIDATION'
          "
        >
          <a-button style="margin-top: -5px">
            Crear reproceso
            <down-outlined />
          </a-button>
          <template #overlay>
            <a-menu @click="handleReworkCreation">
              <a-menu-item key="1">Inconsistencia</a-menu-item>
              <a-menu-item key="2">Anulación factura </a-menu-item>
              <a-menu-item key="3">Cambio / Adicional </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>

      <template #no-data>
        <a-typography-text> No existen reprocesos asociados </a-typography-text>
      </template>
    </collapsable-table>

    <!-- Modal de reproceso -->
    <modal-reprocess
      :period="period"
      :ticket-id="ticketId"
      :policies="policies"
      ref="reprocess_modal_ref"
      @reprocessCreated="reprocessCreated"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { DownOutlined } from '@ant-design/icons-vue';
import modalReprocesos from '../ReprocessModal.vue';
import CollapsableTable from '@/components/CollapsableTable.vue';

export default {
  name: 'AssociateReprocesses',
  components: {
    'modal-reprocess': modalReprocesos,
    'down-outlined': DownOutlined,
    'collapsable-table': CollapsableTable
  },
  props: {
    period: {
      type: String,
      default: ''
    },
    ticketId: {
      type: [String, Number],
      default: 1
    },
    policies: {
      type: Array,
      default: []
    },
    data: {
      type: Array,
      default: []
    },
    states: {
      type: String,
      default: ''
    },
    hideCreation: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const reprocess_modal_ref = ref();
    const collapse_open = ref([]);
    const data_list = computed(() => props.data);

    const handleReworkCreation = ({ key }) => {
      reprocess_modal_ref.value.openModal(key);
    };
    const stateReprocesses = computed(() => props.states);

    const reprocessCreated = () => {
      emit('reprocessCreated', true);
    };

    return {
      collapse_open,
      reprocess_modal_ref,
      data_list,
      reprocessCreated,
      handleReworkCreation,
      stateReprocesses
    };
  }
};
</script>
