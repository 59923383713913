<template>
  <a-row type="flex" justify="center">
    <div style="height: 32px" />
  </a-row>

  <a-row type="flex" justify="center">
    <a-col>
      <img src="@/assets/images/new-identity/carga-ready.svg" alt="" width="187" class="img-margin-ready" />
    </a-col>
  </a-row>
  <a-row justify="center">
    <a-col>
      <CheckCircleFilled class="text-green-6 display-3 mb-3" />
    </a-col>
  </a-row>

  <a-row class="mb-2 text-center" type="flex" justify="center">
    <a-col :xs="15" :sm="16" :md="12" :lg="9">
      <a-typography-title :level="4" class="text-gray-8 ff-urbanist">¡Listo!</a-typography-title>

      <br />
      <a-typography-text class="text-gray-8">Hemos recibido tus documentos, durante los próximos días recibirás un
        correo con más información.
      </a-typography-text>
    </a-col>
  </a-row>
  <br />
  <br />
  <a-row class="my-4 pb-1" type="flex" justify="center">
    <a-col class="text-center" :xs="20" :sm="16" :md="12" :lg="8">
      <a-typography-title class="ff-urbanist text-gray-9" :level="3">
        <!-- {{data.companyName}} -->
      </a-typography-title>
      <a-typography-paragraph class="small text-blue-10">¡Muchas gracias!</a-typography-paragraph>
    </a-col>
  </a-row>
</template>

<script>
import { reactive } from 'vue';
import { CheckCircleFilled } from '@ant-design/icons-vue';

export default {
  components: { CheckCircleFilled },
  name: 'CargaAntecedentesListo',
  setup() {
    const data = reactive({
      companyName: ''
    });

    return {
      data // name of the company
    };
  }
};
</script>
