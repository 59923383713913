import {
  SET_TOTAL_PAGES_TABLE,
  SET_ITEMS_PER_PAGE_TABLE,
  SET_CURRENT_PAGE_TABLE
} from '../../../tables/types';

import {
  FETCH_DATA_NOTA_DEBITO,
  GET_DATA_ROWS_NOTA_DEBITO,
  SET_DATA_ROWS_NOTA_DEBITO
} from './types';

const store = {};

const getDefaultState = () => ({
  rows_data: []
});

store.state = getDefaultState();

store.getters = {
  [GET_DATA_ROWS_NOTA_DEBITO](state) {
    return state.rows_data;
  }
};

store.mutations = {
  [SET_DATA_ROWS_NOTA_DEBITO](state, payload) {
    state.rows_data = payload;
  }
};

store.actions = {
  [FETCH_DATA_NOTA_DEBITO]({ dispatch, commit }) {
    // hice esto para cuando haga el fetch de la tabla, aca voy a obtener la cantidad de rows
    // la pagina actual, cuantos quedan y todo con respecto a la tabla
    // con esto se los voy a pasar al vuex que contiene la data de las tablas
    dispatch(SET_TOTAL_PAGES_TABLE, 30);
    dispatch(SET_ITEMS_PER_PAGE_TABLE, 15);
    dispatch(SET_CURRENT_PAGE_TABLE, 1);
    commit(SET_DATA_ROWS_NOTA_DEBITO, [
      {
        id: 1,
        key: 1,
        stateReproceso: 'Cancelado',
        holding: 'Sable',
        subHolding: 'Grupo sable',
        businessName: 'Jumpitt',
        insuranceCompany: 'consorcio',
        period: '2022-08',
        action: 1
      },
      {
        id: 2,
        key: 2,
        stateReproceso: 'Aprobado',
        holding: '',
        subHolding: 'Grupo Mandomedio',
        businessName: 'icar +10',
        insuranceCompany: 'bci seguros',
        period: '2022-08',
        action: 1
      },
      {
        id: 3,
        key: 3,
        stateReproceso: 'En aseguradora',
        holding: 'Sable',
        subHolding: 'Grupo sable',
        businessName: 'Jumpitt',
        insuranceCompany: 'bci seguros',
        period: '2022-08',
        action: 1
      },
      {
        id: 4,
        key: 4,
        stateReproceso: 'Cancelado',
        holding: '',
        subHolding: 'Grupo sable',
        businessName: 'Jumpitt',
        insuranceCompany: 'consorcio',
        period: '2022-08',
        action: 1
      },
      {
        id: 5,
        key: 5,
        stateReproceso: 'Cancelado',
        holding: '',
        subHolding: 'Grupo jumpitt',
        businessName: 'Jumpitt',
        insuranceCompany: 'consorcio',
        period: '2022-08',
        action: 1
      },
      {
        id: 6,
        key: 6,
        stateReproceso: 'Cancelado',
        holding: 'Sable',
        subHolding: 'Grupo Mandomedio',
        businessName: 'Jumpitt',
        insuranceCompany: 'consorcio',
        period: '2022-08',
        action: 1
      }
    ]);
  }
};

export default store;
