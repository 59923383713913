<template>
  <div>
    <a-skeleton active :loading="loading_data">
      <a-form ref="formRef" :model="form_state" :rules="rules" layout="vertical">
        <a-card>
          <a-typography-title :level="4">Antecedentes asegurado titular</a-typography-title>
          <a-typography-title :level="5">Información personal</a-typography-title>

          <a-row :gutter="16" type="flex">
            <a-col :span="12">
              <a-form-item ref="rut" label="RUT" name="rut" required>
                <a-input placeholder="Ingrese rut" v-model:value="form_state.rut" @keyup="formatRuts"
                  style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item ref="employmentDate" label="Fecha de ingreso a la empresa" name="employmentDate" required>
                <a-date-picker :style="{ width: '100%' }" format="DD/MM/YYYY" v-model:value="form_state.employmentDate"
                  placeholder="Ingrese fecha de ingreso a la empresa" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="firstName" label="Primer nombre" name="firstName" required>
                <a-input placeholder="Ingrese primer nombre" v-model:value="form_state.firstName" style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="secondName" label="Segundo nombre" name="secondName">
                <a-input placeholder="Ingrese segundo nombre" v-model:value="form_state.secondName"
                  style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="firstSurname" label="Apellido paterno" name="firstSurname" required>
                <a-input placeholder="Ingrese apellido paterno" v-model:value="form_state.firstSurname"
                  style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="secondSurname" label="Apellido materno" name="secondSurname">
                <a-input placeholder="Ingrese apellido materno" v-model:value="form_state.secondSurname"
                  style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="dateOfBirth" label="Fecha de nacimiento" name="dateOfBirth" required>
                <a-date-picker :style="{ width: '100%' }" format="DD/MM/YYYY" v-model:value="form_state.dateOfBirth"
                  placeholder="Fecha de nacimiento" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="maritalStatusId" label="Estado civil" name="maritalStatusId" required>
                <a-select v-model:value="form_state.maritalStatusId" :options="marital_status_options"
                  placeholder="Seleccione una opción">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="gender" label="Sexo" name="gender" required>
                <a-select v-model:value="form_state.gender" :options="genderOptions"
                  placeholder="Seleccione una opción">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="healthcareSystemId" label="Sistema de salud previsional" name="healthcareSystemId"
                required>
                <a-select v-model:value="form_state.healthcareSystemId" :options="previsional_system_options"
                  placeholder="Seleccione una opción">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="occupation" label="Actividad / Profesión" name="occupation" required>
                <a-input placeholder="Ingrese Actividad / Profesión" v-model:value="form_state.occupation"
                  style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item ref="weight" label="Peso (kg)" name="weight" required>
                <a-input-number :min="0" v-model:value="form_state.weight" placeholder="Ingrese el peso"
                  style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item ref="height" label="Estatura (mt)" name="height" required>
                <a-input-number :min="0" v-model:value="form_state.height" placeholder="Ingrese la altura"
                  style="width: 100%" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-divider />

          <a-typography-title :level="5">Sube tu cédula de identidad</a-typography-title>
          <p>
            Sube tu cédula de cada lado, presionando sobre Cédula Anverso y
            Cédula Reverso.
          </p>

          <a-row class="mb-4" type="flex">
            <a-col :xs="20" :sm="16" :md="12" :lg="8">
              <a-card class="bordered-client" body-style="padding: 1.125rem 1rem!important;">
                <a-row type="flex" justify="space-between" :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                  <a-col :span="12">
                    <DNIfileUploader dniType="anverso" ref="obverse_ref" />
                  </a-col>
                  <a-col :span="12">
                    <DNIfileUploader dniType="reverso" ref="reverse_ref" />
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>

          <a-divider />

          <a-typography-title :level="5">Información de contacto</a-typography-title>

          <a-row :gutter="16" type="flex">
            <a-col :span="16">
              <a-form-item ref="address" label="Dirección particular" name="address" required>
                <a-input placeholder="Dirección" v-model:value="form_state.address" style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="houseOrApartmentNumber" label="Nº Casa / Depto" name="houseOrApartmentNumber">
                <a-input placeholder="Nº" v-model:value="form_state.houseOrApartmentNumber" style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item ref="municipalityId" label="Comuna" name="municipalityId">
                <a-select v-model:value="form_state.municipalityId" :options="municipality_options"
                  placeholder="Seleccione una opción" optionFilterProp="label" show-search />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item ref="city" label="Ciudad" name="city">
                <a-input placeholder="Ingrese ciudad" v-model:value="form_state.city" style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="phoneNumber" label="Teléfono celular" name="phoneNumber" required>
                <a-input placeholder="Teléfono celular" v-model:value="form_state.phoneNumber" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="particularPhone" label="Teléfono particular" name="particularPhone">
                <a-input placeholder="Teléfono particular" v-model:value="form_state.particularPhone" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="email" label="Correo electrónico" name="email">
                <a-input placeholder="Correo electrónico" v-model:value="form_state.email" style="width: 100%"
                  disabled />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-form-item ref="emailOptOut" name="emailOptOut">
              <a-checkbox v-model:checked="form_state.emailOptOut">
                Si Ud. no desea recibir sus comunicaciones, notificaciones y demás antecedentes a través de su correo
                electrónico marcar está opción
              </a-checkbox>
            </a-form-item>
          </a-row>

          <a-divider v-if="required_rent" />

          <a-typography-title :level="5" v-if="required_rent">Renta</a-typography-title>

          <a-row v-if="required_rent">
            <a-col :span="8">
              <a-form-item ref="incomeAmount" label="Renta bruta" name="incomeAmount">
                <a-input placeholder="$" v-model:value="form_state.incomeAmount" @keyup="formatIncome"
                  style="width: 100%" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>

        <a-card class="mt-3">
          <a-typography-title :level="4">Forma de pago reembolso salud</a-typography-title>
          <a-form-item ref="payment" name="payment">
            <a-radio-group v-model:value="form_state.payment" name="payment">
              <a-radio :value="1">Vale vista</a-radio>
              <a-radio :value="2">Depósito</a-radio>
              <a-radio :value="3">Servipag</a-radio>
            </a-radio-group>
          </a-form-item>

          <div v-if="form_state.payment === 2">
            <a-row :gutter="16">
              <a-col :span="8">
                <a-form-item ref="bank" name="bank" label="Banco">
                  <a-select v-model:value="form_state.bank" :options="bank_list" placeholder="Seleccione una opción" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item ref="accountType" name="accountType" label="Tipo de cuenta">
                  <a-select v-model:value="form_state.accountType" :options="account_type_list"
                    placeholder="Seleccione una opción" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item ref="accountNumber" name="accountNumber" label="N° de cuenta">
                  <a-input v-model:value="form_state.accountNumber" placeholder="Ingrese número de cuenta"
                    :maxlength="250" @change="testChange" />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </a-card>
      </a-form>

      <a-row type="flex" justify="space-between" class="mt-3">
        <a-button size="large" @click="formSubmit(1)" :loading="loading">
          <template #icon>
            <ArrowLeftOutlined />
          </template>
          Atrás
        </a-button>
        <a-button size="large" type="primary" @click="formSubmit(3)" :loading="loading">Siguiente</a-button>
      </a-row>
    </a-skeleton>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, defineExpose } from 'vue';
import { ArrowLeftOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import moment from 'moment';
import {
  ACTION_CHANGE_STEP,
  FETCH_BANKS,
  FETCH_DIGITAL_FORM_MARITAL_STATUS,
  FETCH_PREVISIONAL_HEALTH,
  FETCH_MUNICIPALITIES,
  FETCH_DIGITAL_FORM_EMPLOYEE,
  PUT_DIGITAL_FORM_EMPLOYEE,
  PUT_PAYMENT_METHOD,
  FETCH_PAYMENT_METHOD
} from '../../store/types';
import DNIfileUploader from './DNIfileUploader';
import notifications from '@/utils/notifications';
import emailValidator from '@/utils/emailValidator';
import rutHelper from '@/utils/Ruts';

const pesosFormatter = (value) => {
  const numericInput = Number(value?.toString().replace(/[^0-9]/g, '')) || 0;
  return `$ ${numericInput}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const loading = ref(false);
const obverse_ref = ref();
const reverse_ref = ref();
const loading_data = ref(false);
const store = useStore();
const formRef = ref();
const form_state = reactive({
  rut: null,
  employmentDate: null,
  firstName: null,
  secondName: null,
  firstSurname: null,
  secondSurname: null,
  dateOfBirth: null,
  maritalStatusId: null,
  gender: null,
  healthcareSystemId: null,
  occupation: null,
  weight: null,
  height: null,
  address: null,
  houseOrApartmentNumber: null,
  municipalityId: null,
  city: null,
  phoneNumber: null,
  particularPhone: null,
  email: null,
  emailOptOut: false,
  incomeAmount: null,
  payment: 2,
  bank: null,
  accountType: null
});

const phoneNumberValidation = async (rule, value) => {
  const regex = /^\+[1-9]{1}[0-9]{0,3}[0-9]{5,15}$/;

  if (regex.test(value) === false) {
    return Promise.reject(
      'Ingrese un número válido con "+", código de país y 6 a 19 dígitos en total. Ejemplo: +123456789012'
    );
  }

  return Promise.resolve();
};

const required_rent = ref(true);

const rules = {
  rut: [
    {
      required: true,
      validator: (rule, value) => {
        if (!value) {
          const error_message = 'Ingrese RUT';
          return Promise.reject(error_message);
        }
        if (!rutHelper.validateRut(value)) {
          return Promise.reject('RUT no válido');
        }
        return Promise.resolve();
      },
      trigger: 'change'
    }
  ],
  employmentDate: [
    {
      required: true,
      message: 'Ingrese fecha de ingreso a la empresa',
      trigger: 'change',
      type: 'object'
    }
  ],
  firstName: [
    {
      required: true,
      message: 'Ingrese primer nombre',
      trigger: 'change',
      type: 'string'
    }
  ],
  firstSurname: [
    {
      required: true,
      message: 'Ingrese primer apellido',
      trigger: 'change',
      type: 'string'
    }
  ],
  dateOfBirth: [
    {
      required: true,
      message: 'Ingrese fecha de nacimiento',
      trigger: 'change',
      type: 'object'
    }
  ],
  maritalStatusId: [
    {
      required: true,
      message: 'Seleccione estado civil',
      trigger: 'change',
      type: 'number'
    }
  ],
  gender: [
    {
      required: true,
      message: 'Seleccione Sexo',
      trigger: 'change',
      type: 'string'
    }
  ],
  healthcareSystemId: [
    {
      required: true,
      message: 'Seleccione un sistema de salud',
      trigger: 'change',
      type: 'number'
    }
  ],
  occupation: [
    {
      required: true,
      message: 'Seleccione una actividad',
      trigger: 'change',
      type: 'string'
    }
  ],
  height: [
    {
      required: true,
      message: 'Ingrese la altura',
      trigger: 'change',
      type: 'number'
    }
  ],
  weight: [
    {
      required: true,
      message: 'Ingrese el peso',
      trigger: 'change',
      type: 'number'
    }
  ],
  address: [
    {
      required: true,
      message: 'Ingrese la dirección',
      trigger: 'change',
      type: 'string'
    }
  ],
  phoneNumber: [
    {
      required: true,
      validator: phoneNumberValidation,
      trigger: 'change'
    }
  ],
  incomeAmount: [
    {
      required: required_rent.value,
      message: 'Ingrese renta',
      trigger: 'change',
      type: 'string'
    }
  ],
  email: [
    {
      required: true,
      validator: (rule, value) => {
        if (!value) {
          return Promise.reject('Ingrese correo electrónico');
        }
        if (emailValidator(value)) {
          return Promise.resolve();
        }
        return Promise.reject('Correo inválido');
      },
      trigger: 'change'
    }
  ],
  emailOptOut: [
    {
      required: false,
      trigger: 'change',
      type: 'boolean'
    }
  ],
  payment: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  municipalityId: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  city: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'string'
    }
  ],
  bank: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  accountType: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'string'
    }
  ],
  accountNumber: [
    {
      required: true,
      message: 'Ingrese Numero de cuenta',
      trigger: 'change',
      type: 'string'
    }
  ]
};
const genderOptions = [
  {
    label: 'Masculino',
    value: 'M'
  },
  {
    label: 'Femenino',
    value: 'F'
  },
  {
    label: 'Prefiero no decirlo',
    value: '-'
  }
];
const account_type_list = [
  {
    label: 'Cuenta corriente',
    value: 'CURRENT_ACCOUNT'
  },
  {
    label: 'Cuenta vista',
    value: 'SIGHT_ACCOUNT'
  },
  {
    label: 'Cuenta de ahorro',
    value: 'SAVINGS_ACCOUNT'
  }
];
const bank_list = ref([]);
const marital_status_options = ref([]);
const previsional_system_options = ref([]);
const municipality_options = ref([]);

const formatRuts = () => {
  form_state.rut = rutHelper.formatRut(form_state.rut);
};

const formatIncome = () => {
  form_state.incomeAmount = pesosFormatter(form_state.incomeAmount);
};

const formSubmit = async (step) => {
  loading.value = true;
  try {
    const resp = await formRef.value.validate();
    const obverse_valid = obverse_ref.value.validateExistingFile();
    const reverse_valid = reverse_ref.value.validateExistingFile();

    if (obverse_valid && reverse_valid) {
      await putEmployee(resp);
      await putPaymentMethod();
      await obverse_ref.value.saveFile();
      await reverse_ref.value.saveFile();
      store.dispatch(ACTION_CHANGE_STEP, step);
    } else {
      notifications('error', 'Error', 'Valide archivos de cédula de identidad');
    }
  } catch (error) {
    console.error(error);
    formRef.value.scrollToField(error.errorFields[0].name);
    loading.value = false;
  }
  loading.value = false;
};

const getBankList = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_BANKS)
      .then((resp) => {
        bank_list.value = resp.map((bank) => ({
          label: bank.label,
          value: bank.id
        }));
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMaritalStatus = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_DIGITAL_FORM_MARITAL_STATUS)
      .then((resp) => {
        marital_status_options.value = resp.data.map((status) => ({
          value: status.id,
          label: status.label
        }));
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPrevisionalHealthSystem = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_PREVISIONAL_HEALTH)
      .then((resp) => {
        previsional_system_options.value = resp.data.map((previtional) => ({
          value: previtional.id,
          label: previtional.label
        }));
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMunicipalities = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_MUNICIPALITIES)
      .then((resp) => {
        municipality_options.value = resp.data.map((muni) => ({
          value: muni.id,
          label: muni.label
        }));
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const formattedValueToNumber = (value) => {
  const cleanedString = value.replace(/[^\d]/g, '');
  return Number(cleanedString);
};

const testChange = () => {
  form_state.accountNumber = form_state.accountNumber.replace(/[^0-9-]/g, '');
};

const putEmployee = async (payload) => {
  try {
    const obj = {
      ...payload,
      rut: rutHelper.normalizeRut(payload.rut),
      dateOfBirth: moment(payload.dateOfBirth).format('YYYY-MM-DD'),
      employmentDate: moment(payload.employmentDate).format('YYYY-MM-DD'),
      emailOptOut: payload.emailOptOut ?? false,
    };

    if (payload.incomeAmount) {
      obj.incomeAmount = formattedValueToNumber(payload.incomeAmount);
    }

    delete obj.payment;
    delete obj.email;
    delete obj.bank;
    delete obj.accountType;
    delete obj.accountNumber;

    await store.dispatch(PUT_DIGITAL_FORM_EMPLOYEE, obj);
  } catch (error) {
    notifications('error', 'Error', error.response.data.message);
  }
};

const putPaymentMethod = async () => {
  try {
    let payload = {};
    if (form_state.payment === 1) {
      payload = {
        method: 'BANK_DRAFT'
      };
    } else if (form_state.payment === 3) {
      payload = {
        method: 'SERVIPAG'
      };
    } else {
      payload = {
        method: 'DEPOSIT',
        bankId: form_state.bank,
        accountType: 'CURRENT_ACCOUNT',
        accountNumber: form_state.accountNumber
      };
    }
    await store.dispatch(PUT_PAYMENT_METHOD, payload);
  } catch (error) {
    notifications('error', 'Error', error.response.data.message);
    loading.value = false;
  }
};

const getEmployeeData = async () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_DIGITAL_FORM_EMPLOYEE)
      .then((resp) => {
        const { data } = resp;
        form_state.email = data?.email;
        form_state.emailOptOut = data?.emailOptOut;
        form_state.rut = data?.rut;
        form_state.firstName = data?.firstName ?? null;
        form_state.secondName = data?.secondName ?? null;
        form_state.firstSurname = data?.firstSurname ?? null;
        form_state.secondSurname = data?.secondSurname ?? null;
        form_state.dateOfBirth =
          data?.dateOfBirth ? moment(data?.dateOfBirth) : null;
        form_state.employmentDate =
          data?.employmentDate ? moment(data?.employmentDate) : null;
        form_state.maritalStatusId = data?.maritalStatus?.id ?? null;
        form_state.gender = data?.gender ?? null;
        form_state.healthcareSystemId = data?.healthcareSystem?.id ?? null;
        form_state.height = data?.height ?? null;
        form_state.weight = data?.weight ?? null;
        form_state.address = data?.address ?? null;
        form_state.municipalityId = data?.municipality?.id ?? null;
        form_state.city = data?.city ?? null;
        form_state.phoneNumber = data?.phoneNumber ?? null;
        form_state.particularPhone = data?.particularPhone ?? null;
        form_state.occupation = data?.occupation ?? null;
        form_state.houseOrApartmentNumber =
          data?.houseOrApartmentNumber ?? null;
        form_state.incomeAmount =
          data?.incomeAmount !== undefined
            ? pesosFormatter(data?.incomeAmount)
            : null;
        required_rent.value = data?.isRequiredIncome;
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPaymentMethod = async () => {
  const paymentMethods = {
    'BANK_DRAFT': 1,
    'DEPOSIT': 2,
    'SERVIPAG': 3,
  };
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_PAYMENT_METHOD)
      .then((resp) => {
        if (resp.data !== null) {
          const { method, accountNumber, accountType, bank } = resp.data;
          form_state.payment = paymentMethods[method];
          form_state.accountNumber = accountNumber ?? null;
          form_state.accountType = accountType ?? null;
          form_state.bank = bank?.id ?? null;
        }
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getData = async () => {
  loading_data.value = true;
  try {
    await getBankList();
    await getMaritalStatus();
    await getPrevisionalHealthSystem();
    await getMunicipalities();
    await getEmployeeData();
    await getPaymentMethod();
  } catch (error) {
    console.error(error.response.data.message);
  }
  loading_data.value = false;
};

defineExpose({ formSubmit });

onMounted(() => {
  getData();
});
</script>
