<template>
  <a-collapse class="mb-2" v-model:activeKey="activeKey1">
    <a-collapse-panel key="1" :header="title">
      <a-table v-if="data.length <= 0" :loading="loadingHistory" :showHeader="false" :pagination="false" :data="data">
      </a-table>
      <div v-else style="overflow-y: auto; max-height: 500px">
        <div>
          <a-steps direction="vertical" :current="data.length - 1">
            <a-step :class="{ 'redirecion-step': d.redirectionRoute }" v-for="(d, index) in data" :key="index"
              :title="d.title" :description="d.description" :status="d.status" @click="redirection(d.redirectionRoute)" />
          </a-steps>
        </div>
      </div>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'Records',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: []
    },
    loadingHistory: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useRouter();

    const title = computed(() => {
      return props.title;
    });

    const data = computed(() => {
      return props.data.map((i) => {
        if (i.title === 'En revision Bewell') {
          return { ...i, title: 'En revisión corredora' };
        }
        if (i.title === 'En revision Aseguradora') {
          return { ...i, title: 'En revisión aseguradora' };
        }
        return i;
      });
    });

    const activeKey1 = ref(['1']);
    watch(activeKey1, () => { });

    const redirection = (route) => {
      if (route != undefined) {
        router.push(route);
      }
    };

    return {
      activeKey1,
      // TODO: ARREGLAR VARIABLE DUPLICADAS
      // eslint-disable-next-line vue/no-dupe-keys
      title,

      // TODO: ARREGLAR VARIABLE DUPLICADAS
      // eslint-disable-next-line vue/no-dupe-keys
      data,
      redirection
    };
  }
};
</script>

<style>
.redirecion-step {
  cursor: pointer;
}

.redirecion-step .ant-steps-item-title {
  color: #2521e5 !important;
}
</style>
