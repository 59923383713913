import {
  getReprocessById,
  getProcessByReprocessId,
  changeReprocessState,
  getReprocessesComments,
  createReprocessesComment,
  createReprocess,
  getReprocessByCollectionId,
  getReprocessSummary,
  getAllReprocess
} from '../../../../network';

import {
  GET_REPROCESS,
  GET_PROCESS_BY_REPROCESS,
  MUTATE_REPROCESS,
  MUTATE_PROCESS_BY_REPROCESS,
  FETCH_REPROCESS,
  FETCH_PROCESS_BY_REPROCESS,
  POST_CHANGE_REPROCESS_STATE,
  FETCH_REPROCESS_COMMENTS,
  MUTATE_REPROCESS_COMMENTS,
  GET_REPROCESS_COMMENTS,
  POST_REPROCESS_COMMENTS,
  POST_REPROCESS,
  FETCH_REPROCESS_BY_COLLECTION_ID,
  MUTATE_REPROCESS_BY_COLLECTION_ID,
  GET_REPROCESS_BY_COLLECTION_ID,
  FETCH_REPROCESS_SUMMARY,
  MUTATE_REPROCESS_SUMMARY,
  GET_REPROCESS_SUMMARY,
  FETCH_REPROCESS_TABLE_DATA_BY_TYPE
} from '../types';

// Tabla
import {
  SET_ROWS_TABLE,
  SET_TOTAL_PAGES_TABLE,
  SET_CURRENT_PAGE_TABLE,
  SET_ITEMS_PER_PAGE_TABLE
} from '../../../tables/types';

const store = {};

store.state = {
  reprocess_details: {},
  process: [],
  comments: [],
  reprocesses: [],
  reprocesses_summary: {}
};

store.getters = {
  [GET_REPROCESS]: (state) => state.reprocess_details,
  [GET_PROCESS_BY_REPROCESS]: (state) => state.process,
  [GET_REPROCESS_COMMENTS]: (state) => state.comments,
  [GET_REPROCESS_BY_COLLECTION_ID]: (state) => state.reprocesses,
  [GET_REPROCESS_SUMMARY]: (state) => (type) => state.reprocesses_summary[type]
};

store.mutations = {
  [MUTATE_REPROCESS](state, payload) {
    state.reprocess_details = payload;
  },
  [MUTATE_PROCESS_BY_REPROCESS](state, payload) {
    state.process = payload;
  },
  [MUTATE_REPROCESS_COMMENTS](state, payload) {
    state.comments = payload;
  },
  [MUTATE_REPROCESS_BY_COLLECTION_ID](state, payload) {
    state.reprocesses = payload;
  },
  [MUTATE_REPROCESS_SUMMARY](state, { type, data }) {
    state.reprocesses_summary[type] = data;
  }
};

store.actions = {
  [FETCH_REPROCESS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getReprocessById(payload)
        .then((resp) => {
          commit(MUTATE_REPROCESS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_PROCESS_BY_REPROCESS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getProcessByReprocessId(payload)
        .then((resp) => {
          commit(MUTATE_PROCESS_BY_REPROCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_CHANGE_REPROCESS_STATE](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      changeReprocessState(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_REPROCESS_COMMENTS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getReprocessesComments(payload)
        .then((resp) => {
          commit(MUTATE_REPROCESS_COMMENTS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_REPROCESS](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      createReprocess(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_REPROCESS_COMMENTS](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      createReprocessesComment(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_REPROCESS_BY_COLLECTION_ID](
    { commit },
    { collection_id, query_params }
  ) {
    return new Promise((resolve, reject) => {
      getReprocessByCollectionId(collection_id, query_params)
        .then((resp) => {
          commit(MUTATE_REPROCESS_BY_COLLECTION_ID, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_REPROCESS_SUMMARY]({ commit }, { type, payload }) {
    return new Promise((resolve, reject) => {
      getReprocessSummary(type, payload)
        .then((resp) => {
          commit(MUTATE_REPROCESS_SUMMARY, { type, data: resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_REPROCESS_TABLE_DATA_BY_TYPE]({ dispatch }, { payload, signal }) {
    return new Promise((resolve, reject) => {
      getAllReprocess(payload, signal)
        .then((resp) => {
          const { rows, total_pages, page, items_per_page } = resp.data;
          console.log('roooows', rows);
          dispatch(SET_ROWS_TABLE, rows);
          dispatch(SET_TOTAL_PAGES_TABLE, total_pages);
          dispatch(SET_CURRENT_PAGE_TABLE, page);
          dispatch(SET_ITEMS_PER_PAGE_TABLE, items_per_page);

          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default store;
