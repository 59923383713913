<template>
  <div>
    <a-modal
      title="Resumen de carga de cobranza"
      v-model:visible="modal_props.visible"
      :closable="!modal_props.loading_buttons"
      :maskClosable="!modal_props.loading_buttons"
    >
      <a-skeleton :loading="modal_props.loading_data">
        <p>Revisa que los datos ingresados esten correctos.</p>

        <a-descriptions
          class="mb-3"
          v-for="(data, index) in modal_props.data"
          :key="index"
          :title="data.category"
          layout="horizontal"
          bordered
        >
          <a-descriptions-item
            v-for="(d, i) in data.values"
            :key="i"
            :span="3"
            :label="d.name"
          >
            {{ d.quantity }}
          </a-descriptions-item>
        </a-descriptions>
      </a-skeleton>

      <template #footer>
        <a-button :disabled="modal_props.loading_buttons" @click="closeModal"
          >Cancelar</a-button
        >
        <a-button
          type="primary"
          @click="collectionFormStateToLoaded"
          :loading="modal_props.loading_buttons"
          >Ok</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  PATCH_COLLECTION_FORM_STATE,
  FETCH_COLLECTION_FORM_SUMMARY,
  GET_COLLECTION_FORM_SUMMARY
} from '../../../store/types';
import notification from '../../../utils/notifications';
import scrollToTop from '../../../utils/scrollToTopScreen';

export default {
  setup() {
    // * Store
    const route = useRoute();
    const collection_form_id = route.params.id || '';

    // * Vuex
    const store = useStore();

    // * Variables globales
    const modal_props = reactive({
      visible: false,
      data: undefined,
      loading_data: false,
      loading_buttons: false
    });

    // * Funciones
    const closeModal = () => {
      modal_props.visible = false;
    };

    const getCollectionFormSummary = async (collection_form_id) => {
      modal_props.loading_data = true;

      try {
        const resp = await store.dispatch(
          FETCH_COLLECTION_FORM_SUMMARY,
          collection_form_id
        );

        if (resp.status === 200) {
          modal_props.data = store.getters[GET_COLLECTION_FORM_SUMMARY];
        }
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }

      modal_props.loading_data = false;
    };

    const openModal = (collection_form_id) => {
      getCollectionFormSummary(collection_form_id);
      modal_props.visible = true;
    };

    const collectionFormStateToLoaded = async () => {
      modal_props.loading_buttons = true;
      try {
        await store.dispatch(PATCH_COLLECTION_FORM_STATE, {
          id: collection_form_id,
          payload: {
            state: 'LOADED'
          }
        });

        notification('success', 'Correcto', 'Formulario de cobranza Cargado');

        closeModal();
        scrollToTop();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }

      modal_props.loading_buttons = false;
    };

    return {
      modal_props,
      openModal,
      closeModal,
      collectionFormStateToLoaded
    };
  }
};
</script>
