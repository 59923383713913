<template>
  <a-row type="flex" class="mt-4" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8"> </a-col>
  </a-row>

  <a-row class="mb-2" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <a-typography-title class="text-gray-8" :level="5">Resumen</a-typography-title>
    </a-col>
  </a-row>

  <a-row class="" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <a-card
        body-style="padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; padding-left: 1rem !important; padding-right: 1rem !important;">
        <a-row>
          <a-col>
            <a-typography-text class="text-gray-8">
              <b>Nombre</b>:
              {{
                formState.name && formState.surname
                  ? `${formState.name} ${formState.surname}`
                  : ''
              }}
            </a-typography-text>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-typography-text class="text-gray-8">
              <b>RUT</b>:
              {{ formState.rut ? rutHelper.formatRut(formState.rut) : '' }}
            </a-typography-text>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-typography-text class="text-gray-8">
              <b>Correo electrónico</b>:
              {{ formState.email ? formState.email : '' }}
            </a-typography-text>
          </a-col>
        </a-row>
      </a-card>
      <br />
      <div v-if="dataLoads.length > 0">
        <a-card
          body-style="padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; padding-left: 1rem !important; padding-right: 1rem !important;">
          <a-row type="flex" justify="space-between" align="middle">
            <a-col>
              <a-typography-text class="text-gray-8" strong>Cargas</a-typography-text>
            </a-col>
            <a-col> </a-col>
          </a-row>
          <div v-for="(a, index) of dataLoads" :key="index">
            <a-row>
              <a-col>
                <a-typography-text class="text-gray-8">
                  Rut: {{ a ? rutHelper.formatRut(a.rut) : '' }}
                </a-typography-text>
              </a-col>
            </a-row>
          </div>
        </a-card>
        <br />
      </div>

      <a-card :loading="loadingDataDocs"
        body-style="padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; padding-left: 1rem !important; padding-right: 1rem !important;">
        <a-row type="flex" justify="space-between" align="middle">
          <a-col>
            <a-typography-text class="text-gray-8" strong>Formularios</a-typography-text>
          </a-col>
        </a-row>
        <div v-for="(a, index) of dataDocs" :key="index">
          <a-row class="mb-2">
            <a-col :xs="24">
              <a-card body-style="padding: 12px 16px " class="mt-2">
                <CheckCircleFilled class="mr-2 text-green-6" />
                <a-typography-text class="text-gray-8">
                  {{ a.tag }}
                </a-typography-text>
              </a-card>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-col>
  </a-row>

  <a-row :gutter="16" style="width: 100%" type="flex" justify="center">
    <a-col :xs="10" :sm="6" :lg="4">
      <a-button class="mt-4" size="large" block @click="onPrevStep()">Atrás</a-button>
    </a-col>
    <a-col :xs="10" :sm="6" :lg="4">
      <a-button class="mt-4 btn-client" type="primary" size="large" block @click="onFinish"
        :disabled="dataDocs.length <= 0">
        Siguiente
      </a-button>
    </a-col>
  </a-row>
</template>

<script>
import { ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import rutHelper from '@/utils/Ruts';
import { CheckCircleFilled } from '@ant-design/icons-vue';
import {
  FETCH_USER_LOADS,
  FETCH_EMPLOYEE,
  PATCH_INSURED_EMPLOYEE,
  FETCH_TICKET_ID,
  FETCH_INSURANCE_DOCUMENTS,
  UPDATE_INSURANCE_STATE
} from '@/store/types';

export default {
  name: 'Resume',
  components: {
    CheckCircleFilled
  },
  setup() {
    const formRef = ref();
    const formState = reactive({
      name: '',
      surname: '',
      rut: '',
      email: '',
      cargas: ['']
    });
    const dataLoads = ref({});
    const dataDocs = ref([]);
    const ticketInfo = ref({});
    const customUrl = ref('');
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const loadingDataDocs = ref(false);

    const info = reactive({
      id: '',
      ticketId: '',
      token: ''
    });

    info.token = route.params.token;
    const decodeToken = JSON.parse(atob(info.token.split('.')[1]));
    info.id = decodeToken.employeeId;
    info.ticketId = decodeToken.ticketId;

    const getUserLoads = async () => {
      const response = await store.dispatch(FETCH_USER_LOADS, info.id);
      dataLoads.value = response;
    };
    getUserLoads();

    const getData = async () => {
      store
        .dispatch(FETCH_EMPLOYEE, info.id)
        .then((response) => {
          formState.name = response.data.firstName;
          formState.surname = response.data.firstSurname;
          formState.rut = response.data.rut;
          formState.email = response.data.email;
        })
        .catch((err) => {
          console.log(err.response);
        });
    };
    getData();

    const getTicketInfo = async () => {
      await store
        .dispatch(FETCH_TICKET_ID, info.ticketId)
        .then((response) => {
          ticketInfo.value = response.data;
          const documents = ticketInfo.value.filesInsurance;
          let vueltas = 0;
          let url = '?selectedIds=';
          documents.forEach((e) => {
            vueltas++;
            if (vueltas !== documents.length) {
              url += `${e},`;
            } else {
              url += e;
            }
          });
          customUrl.value = url;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTicketInfo();

    const getSelectedDocs = async () => {
      loadingDataDocs.value = true;
      try {
        await store
          .dispatch(FETCH_INSURANCE_DOCUMENTS, info.ticketId)
          .then((response) => {
            dataDocs.value = response.data.map((a) => {
              return {
                id: a.id,
                businessName: a.insuranceCompany.businessName,
                tag: a.name,
                insuranceCompanyId: a.insuranceCompanyId
              };
            });
          })
          .catch((err) => console.log(err));
        loadingDataDocs.value = false;
      } catch (error) {
        loadingDataDocs.value = false;
        console.log('error', error);
      }
    };
    setTimeout(() => {
      getSelectedDocs();
    }, 300);

    // VALIDATIONS

    const checkName = async (rule, value) => {
      if (!value) {
        const error_message = 'Ingrese nombre';
        return Promise.reject(error_message);
      }
      return Promise.resolve();
    };

    const checkSurname = async (rule, value) => {
      if (!value) {
        const error_message = 'Ingrese apellido';
        return Promise.reject(error_message);
      }
      return Promise.resolve();
    };

    const checkRut = async (rule, value) => {
      if (!value) {
        const error_message = 'Ingrese RUT';
        return Promise.reject(error_message);
      }
      if (rutHelper.validateRut(value)) {
        return Promise.resolve();
      }
      return Promise.reject('RUT no válido');
    };

    const validateEmail = (mail) => {
      return String(mail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const checkEmail = async (rule, value) => {
      if (!value) {
        return Promise.reject('Ingrese correo electrónico');
      }
      if (validateEmail(value)) {
        return Promise.resolve();
      }
      return Promise.reject('Correo inválido');
    };

    // rules
    const rules = {
      name: [
        {
          validator: checkName,
          trigger: 'change'
        }
      ],
      surname: [
        {
          validator: checkSurname,
          trigger: 'change'
        }
      ],
      rut: [
        {
          validator: checkRut,
          trigger: 'change'
        }
      ],
      email: [
        {
          validator: checkEmail,
          trigger: 'change'
        }
      ]
    };

    // const rut = data.value.carga;

    const visible = ref(false);
    const visible2 = ref(false);

    const showModal = () => {
      visible.value = true;
    };

    const showModal2 = () => {
      visible2.value = true;
    };

    const handleOk = () => {
      visible.value = false;
    };

    const handleOk2 = () => {
      visible2.value = false;
    };

    const addCarga = async () => {
      formState.cargas.push('');
    };

    const removeCarga = async (i) => {
      formState.cargas.splice(i, 1);
    };
    const onPrevStep = () => {
      router.go(-1);
    };
    const onFinish = async () => {
      await store
        .dispatch(PATCH_INSURED_EMPLOYEE, {
          id: info.id,
          payload: {
            firstName: formState.name,
            firstSurname: formState.surname,
            rut: formState.rut,
            email: formState.email
          }
        })
        .then(() =>
          store.dispatch(
            UPDATE_INSURANCE_STATE,
            { id: info.ticketId, payload: { stateId: 7 } },
            router.push({
              name: 'ready',
              params: { token: route.params.token }
            })
          )
        )
        .catch((err) => {
          console.log(err.response);
        });
    };

    return {
      // Variables
      formRef,
      dataLoads,
      formState,
      visible,
      visible2,
      // rut,
      rules,
      dataDocs,
      onPrevStep,
      // Methods
      showModal,
      showModal2,
      handleOk,
      handleOk2,
      onFinish,
      addCarga,
      removeCarga,
      loadingDataDocs,
      rutHelper
    };
  }
};
</script>
