<template>
  <div v-if="$route.name == 'Stock'">
    <div class="space-between">
      <div>
        <a-typography-title :level="4">Stock colaboradores</a-typography-title>
      </div>
      <div style="display: flex" v-if="user_role === 'superadmin'">
        <a-button @click="() => baja_stock_ref.openModal()">Baja al stock</a-button>
        <a-button type="primary" @click="() => alta_stock_ref.openModal()" class="ml-3">Alta al stock</a-button>
      </div>
    </div>

    <alta-stock-modal :insurance-companies="insurance_companies" ref="alta_stock_ref"
      @uploaded="getStockEmployeeCompanies" />

    <baja-stock-modal :insurance-companies="insurance_companies" ref="baja_stock_ref"
      @uploaded="getStockEmployeeCompanies" />

    <a-row justify="space-between" class="mt-3">
      <a-typography-title :level="5">
        <a-typography-link target="_blank" :href="getlink">
          Descargar stock
        </a-typography-link>
      </a-typography-title>
    </a-row>

    <a-table :data-source="stock_data" :columns="columns" :pagination="pagination_props" :loading="loading_table"
      @change="changeCurrentPage" :scroll="{ x: 1000 }">
      <!-- Filtro por busqueda normal -->
      <template #filterDropdown="{ column, confirm, clearFilters }">
        <div style="padding: 8px">
          <a-input v-model:value="filter_values[column.key]" :placeholder="`Buscar por ${column.title}`" @pressEnter="
            handleSearch(column.dataIndex, filter_values[column.key], confirm)
            " style="width: 188px; margin-bottom: 8px; display: block" />
          <a-button type="primary" size="small" style="width: 90px; margin-right: 8px" @click="
            handleSearch(column.dataIndex, filter_values[column.key], confirm)
            ">
            <template #icon><search-outlined /></template>
            Buscar
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleColumnReset(column.dataIndex, clearFilters)">
            Limpiar
          </a-button>
        </div>
      </template>

      <!-- Icono de filtrado de lupa -->
      <template #filterIcon="{ column }">
        <search-outlined :style="{
          color:
            search_value[column.dataIndex] === undefined ||
              search_value[column.dataIndex] === ''
              ? undefined
              : '#2521E5'
        }" />
      </template>
      <!-- Renderizado para el click de ver mas -->
      <template #renderDetails="{ text }">
        <a-tooltip color="#05045D" :title="`Ver más`">
          <router-link :to="{ name: 'Colaborador', params: { colaborador: text } }">
            <eye-outlined class="blue-icon" />
          </router-link>
        </a-tooltip>
        <!-- <router-link :to="{name:'Colaborador', params: {colaborador:text}}">
          Ver más
        </router-link> -->
      </template>
    </a-table>
  </div>
  <router-view :key="$route.path"></router-view>
</template>

<script>
import {
  SearchOutlined,
  EyeOutlined
} from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import openNotificacion from '@/utils/notifications';
import rutHelper from '@/utils/Ruts';
import AltaStockModal from '@/components/stock/AltaStockModal.vue';
import BajaStockModal from '@/components/stock/BajaStockModal.vue';
import notification from '@/utils/notifications';
import {
  FETCH_STOCK_EMPLOYEE_COMPANIES,
  FETCH_INSURANCE_COMPANY,
  GET_USER_ROLE,
  GET_DATA_STOCK,
  GETTER_STOCK_TABLE_TOTAL_PAGES,
  GETTER_STOCK_TABLE_ITEMS_PER_PAGE,
  GETTER_STOCK_TABLE_CURRENT_PAGE,
  GET_INSURANCE_COMPANY,
  DOWNLOAD_EXCEL_STOCK,
  GET_LINK
} from '@/store/types';
import parseDate from '@/utils/dateFormatter';
import getTagColor from '@/utils/getTagColor';
import getTagText from '@/utils/getTagText';

export default {
  name: 'Stock',
  components: {
    'search-outlined': SearchOutlined,
    'eye-outlined': EyeOutlined,
    AltaStockModal,
    BajaStockModal
  },
  setup() {
    // * Iniciacion de variables
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const user_role = store.getters[GET_USER_ROLE];

    const company_id = route.params.id;

    // * Obtencion de data
    watch(
      () => route.fullPath,
      () => {
        if (route.name == 'Stock') {
          getStockEmployeeCompanies();
        }
      }
    );

    const loading_table = ref(false);
    const getStockEmployeeCompanies = () => {
      loading_table.value = true;
      store
        .dispatch(FETCH_STOCK_EMPLOYEE_COMPANIES, {
          companyId: company_id,
          query: {
            ...route.query,
            ...(route?.query?.rut ? { rut: rutHelper.normalizeRut(route?.query?.rut) } : {})
          },
        })
        .catch((error) => {
          openNotificacion('error', 'Error', error.response.data.message);
        })
        .finally(() => {
          loading_table.value = false;
        });
    };

    // * Tabla
    const filter_values = ref({}); // Objeto que guarda los valores que voy filtrando por v-model
    const search_value = ref({}); // Objeto que guarda los valores de los cuales se hace la busqueda por filtro

    const stock_data = computed(() => {
      return store.getters[GET_DATA_STOCK].map((e) => ({
        id: e.id,
        key: e.id,
        name: e.fullname,
        rut: rutHelper.formatRut(e.rut),
        groupName: e?.groupCompanyNames,
        type: 'stockColaborador',
      }));
    });

    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'RUT',
        dataIndex: 'rut',
        key: 'rut',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Grupos',
        dataIndex: 'groupName',
        key: 'groupName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Acción',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'renderDetails'
        }
      }
    ];

    // PAGINACION
    // Total de paginas
    const total = computed(() => {
      return store.getters[GETTER_STOCK_TABLE_TOTAL_PAGES];
    });
    // Items por pagina
    const items_per_page = computed(() => {
      return store.getters[GETTER_STOCK_TABLE_ITEMS_PER_PAGE];
    });

    // Pagina en la que nos encontramos
    const current_page = computed(() => {
      return store.getters[GETTER_STOCK_TABLE_CURRENT_PAGE];
    });

    const pagination_props = reactive({
      total,
      pageSize: items_per_page,
      current: current_page
    });

    // color del tag dependiendo del estado del ticket
    const getTagColorByState = (state) => {
      return getTagColor(state);
    };

    // Agregar el nuevo valor del filtro
    const filterRedirectionNewParam = (key, param) => {
      search_value.value[key] = param;

      if (key != 'page') {
        search_value.value['page'] = 1;
      }

      router.replace({
        name: 'Stock',
        query: search_value.value
      });
    };

    // Busqueda por columna
    const handleSearch = (column, value, confirm) => {
      if (value != '') {
        if (column == 'rut') {
          filterRedirectionNewParam(column, rutHelper.formatRut(value));
        } else {
          filterRedirectionNewParam(column, value);
        }
        confirm();
      }
    };

    // Limpiar columna
    const handleColumnReset = (column, resetConfirm) => {
      delete filter_values.value[column];
      delete search_value.value[column];

      router.replace({
        name: 'Stock',
        query: search_value.value
      });

      resetConfirm();
    };

    const changeCurrentPage = (page) => {
      filterRedirectionNewParam('page', page.current);
    };

    // * Obtengo la lista de compañias aseguradoras
    const insurance_companies = computed(
      () => store.getters[GET_INSURANCE_COMPANY]
    );

    // * Alta stock
    const alta_stock_ref = ref();

    // * Baja stock
    const baja_stock_ref = ref();

    // * Descarga de stock
    // le paso el id de compañia
    const download_excel_url = store.dispatch(DOWNLOAD_EXCEL_STOCK, company_id);
    // obtengo el link a descargar stock
    const getlink = computed(() => store.getters[GET_LINK]);

    const getInsuranceCompany = () => {
      try {
        store.dispatch(FETCH_INSURANCE_COMPANY);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    // * Al momento de cargar la pagina y existan filtros anteriormente hechos, los agrego a la logica
    onMounted(() => {
      const keys = Object.keys(route.query);

      keys.forEach((element) => {
        switch (element) {
          default: // Le paso valor al los inputs para que muestre que es lo que busco anteriormente
            filter_values.value[element] = route.query[element];
            break;
        }

        search_value.value[element] = route.query[element]; // Le paso valor al objeto de la busqueda en caso de hacer otra consulta
      });

      // Luego de pasar por esta logica, vamos obtener la lista de insurance company
      getInsuranceCompany();
      getStockEmployeeCompanies();
    });

    return {
      stock_data,
      columns,
      filter_values,
      search_value,
      handleSearch,
      handleColumnReset,
      parseDate,
      getTagColorByState,
      pagination_props,
      loading_table,
      changeCurrentPage,
      user_role,
      insurance_companies,
      download_excel_url,
      getlink,
      getTagText,
      alta_stock_ref,
      baja_stock_ref,
      getStockEmployeeCompanies
    };
  }
};
</script>

<style>
.space-between {
  display: flex;
  justify-content: space-between;
}
</style>
