<template>
  <div>
    <div v-if="mailSent">
      <mail-sent />
    </div>
    <div v-else>
      <a-typography-title
        :level="2"
        class="ff-urbanist title-underline text-blue-8"
        >Recuperar contraseña
      </a-typography-title>
      <a-typography-text class="text-gray-8">
        Te enviaremos un correo electrónico con instrucciones, sobre cómo
        recuperar tu contraseña.</a-typography-text
      >

      <a-form ref="formRef" :model="formState" :rules="rules">
        <a-form-item
          name="email"
          :validate-status="input_validation.status_icon"
          :help="input_validation.help_message"
        >
          <a-input
            v-model:value="formState.email"
            class="ant_input"
            placeholder="tucorreo@mail.com"
            size="large"
            :maxlength="50"
          >
            <template #prefix>
              <mail-outlined
                class="mr-1"
                type="mail"
                style="color: rgba(139, 147, 155, 1)"
              />
            </template>
          </a-input>
        </a-form-item>

        <a-button
          class="btn-client my-4"
          size="large"
          block
          @click="formFinish"
          :loading="loading"
        >
          Enviar correo
        </a-button>
      </a-form>

      <router-link
        class="justify-content-center d-flex text-color-secondary"
        to="/login"
      >
        Volver al inicio
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import { MailOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { RESET_PASS } from '../../store/types';
import MailSent from '@/components/MailSent.vue';

export default {
  name: 'ResetPassword',
  components: {
    'mail-sent': MailSent,
    'mail-outlined': MailOutlined
  },
  setup() {
    // * Validacion de que se envio el correo
    const mailSent = ref(false);

    // * Propiedades del boton
    const loading = ref(false);

    // * Formulario
    const formRef = ref();
    const formState = reactive({
      email: ''
    });

    const validateEmail = (mail) => {
      return String(mail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    // Rules validation
    const input_validation = reactive({
      help_message: '',
      status_icon: ''
    });

    const checkEmail = async (rule, value) => {
      input_validation.help_message = '';
      input_validation.help_message = '';
      if (!value) {
        input_validation.help_message = 'Ingrese correo electrónico';
        input_validation.status_icon = 'error';
        return Promise.reject();
      }
      if (validateEmail(value)) {
        input_validation.help_message = '';
        input_validation.status_icon = '';
        return Promise.resolve();
      }
      input_validation.help_message = 'Correo inválido';
      input_validation.status_icon = '';
      return Promise.reject();
    };

    const rules = {
      email: [
        {
          validator: checkEmail,
          trigger: 'change'
        }
      ]
    };

    const formFinish = () => {
      formRef.value
        .validate()
        .then(() => {
          sendResetEmail();
        })
        .catch((error) => {
          console.log('Error', error);
        });
    };

    // * Envio de correo de recuperacion
    const store = useStore();

    const sendResetEmail = async () => {
      loading.value = true;

      try {
        const resp = await store.dispatch(RESET_PASS, {
          email: formState.email
        });

        await new Promise((resolve) => setTimeout(resolve, 1000)); // TODO: Eliminar, es solo para darle el efecto de que esta cargando

        if (resp.status === 200) {
          mailSent.value = true;
        }
      } catch (error) {
        const errMessage = error.response.data.message;
        if (errMessage === 'User not found') {
          await new Promise((resolve) => setTimeout(resolve, 1000)); // TODO: Eliminar, es solo para darle el efecto de que esta cargando
          input_validation.help_message =
            'Tu correo no esta en nuestros registros, por favor comunicate con tu ejecutivo.';
          input_validation.status_icon = 'warning';
        }
      }

      loading.value = false;
    };

    return {
      mailSent,
      formRef,
      formState,
      rules,
      formFinish,
      input_validation,
      loading
    };
  }
};
</script>
