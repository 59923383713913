<template>
  <a-card :title="title" :bodyStyle="'padding:0;'">
    <a-table :dataSource="table_data" :columns="columns" :pagination="false">
      <template #action="{ record }">
        <a-button @click="primeConfiguration(record.key)" type="link">
          <template #icon>
            <SettingOutlined />
          </template>
        </a-button>
      </template>

      <template #type="{ record }">
        <a-tooltip color="#fafafa" v-if="record.type !== 'NONE'">
          <template #title>
            <a-descriptions
              bordered
              v-if="record.type === 'CAPITAL_FIXED_INCOME'"
            >
              <a-descriptions-item label="Valor de la tasa" :span="3">
                {{ record.parameters.premiumRate }}
              </a-descriptions-item>
              <a-descriptions-item label="Veces renta" :span="3">
                {{ record.parameters.incomeMultiplier }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              bordered
              v-else-if="record.type === 'CAPITAL_FIXED_AMOUNT'"
            >
              <a-descriptions-item label="Valor de la tasa" :span="3">
                {{ record.parameters.premiumRate }}
              </a-descriptions-item>
              <a-descriptions-item label="Monto de capital fijo" :span="3">
                {{ record.parameters.capitalAmount }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions bordered v-else>
              <a-descriptions-item label="Sólo asegurado" :span="3">
                {{ record.parameters.dependentRanges[0].premiumAmount }}
              </a-descriptions-item>
              <a-descriptions-item label="Asegurado + 1 carga" :span="3">
                {{ record.parameters.dependentRanges[1].premiumAmount }}
              </a-descriptions-item>
              <a-descriptions-item label="Asegurado + 2 carga" :span="3">
                {{ record.parameters.dependentRanges[2].premiumAmount }}
              </a-descriptions-item>
              <a-descriptions-item label="Asegurado + 3 carga" :span="3">
                {{ record.parameters.dependentRanges[3].premiumAmount }}
              </a-descriptions-item>
              <a-descriptions-item label="Asegurado + 4 carga" :span="3">
                {{ record.parameters.dependentRanges[4].premiumAmount }}
              </a-descriptions-item>
            </a-descriptions>
          </template>

          <a-tag
            :color="
              record.type === 'CAPITAL_FIXED_INCOME'
                ? 'green'
                : record.type === 'CAPITAL_FIXED_AMOUNT'
                ? 'cyan'
                : 'blue'
            "
          >
            {{ getPrimeConfigurationType(record.type) }}
          </a-tag>
        </a-tooltip>
        <a-tag v-else>
          {{ getPrimeConfigurationType(record.type) }}
        </a-tag>
      </template>
    </a-table>
  </a-card>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import { SettingOutlined } from '@ant-design/icons-vue';

const props = defineProps({
  data: {
    type: Object,
    default: {}
  },
  groupName: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['primeConfiguration']);

const title = computed(() => `Grupo ${props.groupName}`);

const table_data = computed(() => {
  console.log(props.data.coverages.length);

  if (props.data.coverages.length > 0) {
    return props.data.coverages.map((coverage) => ({
      ...coverage,
      type: coverage.method ?? 'NONE',
      key: coverage.id
    }));
  }
  return [];
});

const columns = [
  {
    title: 'Cobertura',
    width: 100,
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Tipo',
    width: 100,
    dataIndex: 'type',
    key: 'type',
    slots: {
      customRender: 'type'
    }
  },
  {
    title: 'Acciones',
    width: 100,
    dataIndex: 'action',
    key: 'action',
    slots: {
      customRender: 'action'
    }
  }
];

const getPrimeConfigurationType = (type) => {
  const obj = {
    NONE: 'Sin configurar',
    CAPITAL_FIXED_INCOME: 'Capital variable',
    CAPITAL_FIXED_AMOUNT: 'Capital fijo',
    AMOUNT_BY_DEPENDENT_RANGES: 'Tramo de cargas'
  };
  return obj[type];
};

const primeConfiguration = (coverageId) => {
  const { coverages, groupCompany } = props.data;
  const found_coverage = coverages.find(
    (coverage) => coverage.id === coverageId
  );
  emit('primeConfiguration', {
    ...found_coverage,
    groupCompanyId: groupCompany
  });
};
</script>
