<template>
  <div>
    <a-modal
      v-model:visible="visible"
      :title="title"
      :closable="!loading"
      :maskClosable="!loading"
      centered
    >
      <a-typography-title :level="5" style="margin-top: -15px">
        ¿Estás seguro que deseas eliminar
        {{
          dataType.maintainerType == 'cobertura'
            ? `la cobertura ${dataType.tipo}`
            : '' || dataType.maintainerType == 'carga'
            ? `el rut ${dataType.rut}`
            : ''
        }}?
      </a-typography-title>
      <a-typography-text>
        Las
        {{
          dataType.maintainerType == 'cobertura'
            ? 'coberturas'
            : '' || dataType.maintainerType == 'carga'
            ? 'cargas'
            : ''
        }}
        que ya han sido parte de un proceso de validación y cuadratura de nómina
        dentro del periodo no podrán ser eliminadas.
      </a-typography-text>
      <template #footer>
        <a-button :loading="loading" key="back" @click="closeModal"
          >Cancelar</a-button
        >
        <a-button
          :loading="loading"
          key="submit"
          danger
          type="primary"
          @click="submitModal"
        >
          Sí,eliminar
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref, watchEffect, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import notification from '../../../utils/notifications';
import {
  DELETE_EMPLOYEE_COVERAGE,
  FETCH_COVERAGE_EMPLOYEE,
  DELETE_EMPLOYEE_LOAD,
  FETCH_DEPENDENTS
} from '@/store/types';

export default {
  name: 'ModalDeleteCyC',
  props: {
    typeSelection: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    const dataType = ref();
    const loading = ref(false);
    const store = useStore();
    const route = useRoute();
    const { id, colaborador } = route.params;
    const type = computed(() => props.typeSelection);
    const title = ref('');

    const visible = ref(false);

    const closeModal = () => {
      visible.value = false;
    };

    const openModal = (type) => {
      dataType.value = type;
      visible.value = true;
    };
    watchEffect(() => {
      title.value = `Eliminar ${dataType.value.maintainerType}`;
    });

    const submitModal = async () => {
      try {
        loading.value = true;
        // VER SI es de tipo cobertuas o cargas para mandar el dato
        if (dataType.value.maintainerType == 'cobertura') {
          const resp = await store.dispatch(
            DELETE_EMPLOYEE_COVERAGE,
            dataType.value.employeeCoverageId
          );
          if (resp.status == 200) {
            loading.value = false;
            await store.dispatch(FETCH_COVERAGE_EMPLOYEE, {
              companyId: id,
              employeeId: colaborador,
              queryParam: `?status=${
                type.value == 'Vigente'
                  ? 'ACTIVE'
                  : '' || type.value == 'No vigente'
                    ? 'INACTIVE'
                    : ''
              }`
            });
            closeModal();
            notification(
              'success',
              'Correcto',
              'Cobertura eliminada correctamente.'
            );
          }
        }
        if (dataType.value.maintainerType == 'carga') {
          const resp = await store.dispatch(DELETE_EMPLOYEE_LOAD, {
            employeeId: route.params.colaborador,
            dependentId: dataType.value.id
          });
          if (resp.status == 200) {
            loading.value = false;
            await store.dispatch(FETCH_DEPENDENTS, route.params.colaborador);
            closeModal();
            notification(
              'success',
              'Correcto',
              'Cobertura eliminada correctamente.'
            );
          }
        }
      } catch (error) {
        loading.value = false;
        notification('error', 'Error', error.response.data.message);
        closeModal();
        console.log('error', error);
      }
    };

    return {
      dataType,
      visible,
      closeModal,
      submitModal,
      openModal,
      loading,
      title,
      type
    };
  }
};
</script>
