<template>
  <div style="display: flex; flex-direction: column; justify-content: center">
    <a-row type="flex">
      <a-col style="flex-grow: 1">
        <a-form
          ref="form_ref"
          style="width: 100%"
          :model="form_state"
          :rules="form_rules"
        >
          <a-form-item
            :label="props.title"
            name="value"
            ref="value"
            style="width: 100%"
          >
            <a-select
              :loading="props.brokerOptionsLoading"
              :disabled="props.brokerOptionsLoading"
              v-model:value="form_state.value"
              placeholder="Seleccione un ejecutivo"
              :options="props.brokerOptions"
              optionFilterProp="label"
              show-search
              style="width: 100%"
            />
          </a-form-item>
        </a-form>
      </a-col>
      <a-col style="flex-grow: 0">
        <a-button type="primary" @click="addBroker" class="ml-3"
          >Añadir ejecutivo</a-button
        >
      </a-col>
    </a-row>

    <a-list
      style="width: 100%"
      class="scrollable-broker-div"
      :loading="props.brokerListLoading"
      size="small"
      item-layout="horizontal"
      :data-source="props.brokerList"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <a-list-item-meta>
            <template #title>
              <a-typography>{{ item.title }}</a-typography>
            </template>
          </a-list-item-meta>
          <template #actions>
            <a-popconfirm
              style="display: flex; align-items: center"
              title="¿Estas seguro de eliminar este ejecutivo?"
              @confirm="() => deleteBroker(item.companyBrokerId)"
              ok-text="Sí"
              cancel-text="No"
            >
              <template #icon
                ><QuestionCircleFilled style="color: red"
              /></template>
              <p class="red-icon" style="margin: 0">Eliminar</p>
            </a-popconfirm>
          </template>
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>

<script setup>
import { QuestionCircleFilled } from '@ant-design/icons-vue';
import { defineProps, defineEmits, reactive, ref } from 'vue';

/* 
@broker_type: es el tipo de broker (BrokerType) al cual vamos a crear
*/
const props = defineProps({
  title: {
    type: String,
    default: 'Titulo'
  },
  brokerType: {
    type: [String, Number],
    default: 1
  },
  brokerOptions: {
    type: Array,
    default: []
  },
  brokerOptionsLoading: {
    type: Boolean,
    default: false
  },
  brokerList: {
    type: Array,
    default: []
  },
  brokerListLoading: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['addBroker', 'deleteBroker']);

const form_ref = ref();
const form_state = reactive({
  value: null
});
const form_rules = {
  value: [
    {
      required: true,
      message: 'Seleccione un Ejecutivo',
      trigger: 'change',
      type: 'number'
    }
  ]
};

const addBroker = () => {
  form_ref.value.validate().then(() => {
    emit('addBroker', {
      broker: form_state.value,
      brokerType: props.brokerType
    });
    form_ref.value.resetFields();
  });
};

const deleteBroker = (companyBrokerId) => {
  emit('deleteBroker', companyBrokerId);
};
</script>

<style scoped>
.scrollable-broker-div {
  height: 250px;
  width: 570px;
  overflow: auto;
}
</style>
