import {
  SET_CHANGE_NAME,
  SET_CHANGE_EMAIL,
  SET_JWT_EXPIRATION
} from '../types';

const store = {};
const getDefaultState = () => ({
  token: {
    name: '',
    email: '',
    expiration_token: undefined
  }
});

store.state = getDefaultState();

store.getters = {};

store.mutations = {
  [SET_CHANGE_NAME](state, payload) {
    state.token.name = payload;
  },
  [SET_CHANGE_EMAIL](state, payload) {
    state.token.email = payload;
  },
  [SET_JWT_EXPIRATION](state, payload) {
    state.token.expiration_token = payload;
  }
};

store.actions = {};

export default store;
