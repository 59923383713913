// * GET
export const GET_INSURANCES = 'index/GET_INSURANCES';
export const GET_COMPANY_ID = 'index/GET_COMPANY';
export const GET_MAIL_RECEPTION_REQUEST = 'index/GET_MAIL_RECEPTION_REQUEST';
export const GET_FILEINSURANCE_COMPANY = 'index/GET_FILEINSURANCE_COMPANY';
export const GET_INSURANCE_MANUAL = 'index/GET_INSURANCE_MANUAL';
export const GET_INSURANCES_COMPANY_PAYROLL_BY_ID =
  'index/GET_INSURANCES_COMPANY_PAYROLL_BY_ID';
export const GET_INSURANCES_COMPANY_BY_HOLDING_ID =
  'index/GET_INSURANCES_COMPANY_BY_HOLDING_ID';
export const GET_INSURANCES_COMPANY_BY_SUBHOLDING_ID =
  'index/GET_INSURANCES_COMPANY_BY_SUBHOLDING_ID';
export const GET_INSURANCES_COMPANY_BY_COMPANY_ID =
  'index/GET_INSURANCES_COMPANY_BY_COMPANY_ID';

// * SET
export const SET_INSURANCES = 'index/SET_INSURANCES';
export const SET_INSURANCE_COMPANY_ID = 'index/SET_INSURANCE_COMPANY_ID';
export const SET_MAIL_RECEPTION_REQUEST = 'index/SET_MAIL_RECEPTION_REQUEST';
export const SET_MANUAL_INSURANCE = 'index/SET_MANUAL_INSURANCE';
export const SET_INSURANCES_COMPANY_PAYROLL_BY_ID =
  'index/SET_INSURANCES_COMPANY_PAYROLL_BY_ID';

// * POST
export const POST_MAIL_RECEPTION_REQUEST = 'index/POST_MAIL_RECEPTION_REQUEST';
export const SET_FILEINSURANCE_COMPANY = 'index/SET_FILEINSURANCE_COMPANY';
export const POST_INSURANCE_MANUAL = 'index/POST_INSURANCE_MANUAL';

// * FETCH
export const FETCH_INSURANCES = 'index/FETCH_INSURANCES';
export const FETCH_INSURANCES_COMPANY_ID = 'index/FETCH_INSURANCES_COMPANY_ID';
export const FETCH_INSURANCES_COMPANY_PAYROLL_BY_ID =
  'index/FETCH_INSURANCES_COMPANY_PAYROLL_BY_ID';
export const FETCH_FILEINSURANCE_COMPANY = 'index/FETCH_FILEINSURANCE_COMPANY';
export const FETCH_MAIL_RECEPTION_REQUEST =
  'index/FETCH_MAIL_RECEPTION_REQUEST';
export const FETCH_INSURANCE_MANUAL = 'index/FETCH_INSURANCE_MANUAL';
export const FETCH_INSURANCES_COMPANY_BY_HOLDING_ID =
  'index/FETCH_INSURANCES_COMPANY_BY_HOLDING_ID';
export const FETCH_INSURANCES_COMPANY_BY_SUBHOLDING_ID =
  'index/FETCH_INSURANCES_COMPANY_BY_SUBHOLDING_ID';
export const FETCH_INSURANCES_COMPANY_BY_COMPANY_ID =
  'index/FETCH_INSURANCES_COMPANY_BY_COMPANY_ID';
export const FETCH_INSURANCE_COMPANY_INCORPORATION_TYPE =
  'index/FETCH_INSURANCE_COMPANY_INCORPORATION_TYPE';

// * POST
export const POST_FORM_TYPE = 'index/POST_FORM_TYPE';
export const POST_INSURANCECOMPANY = 'index/POST_INSURANCECOMPANY';

// * PUT
export const PUT_FORM_TYPE = 'index/PUT_FORM_TYPE';
export const PUT_MAIL_RECEPTION_REQUEST = 'index/PUT_MAIL_RECEPTION_REQUEST';
export const PUT_INSURANCECOMPANY = 'index/PUT_INSURANCE_COMPANY';
export const PUT_MANUAL_INSURANCE = 'index/PUT_MANUAL_INSURANCE';
export const PUT_INSURANCE_COMPANY_INCORPORATION_TYPE =
  'index/PUT_INSURANCE_COMPANY_INCORPORATION_TYPE';

// * DELETE
export const DELETE_FORM_TYPE = 'index/DELETE_FORM_TYPE';
export const DELETE_INSURANCECOMPANY = 'index/DELETE_INSURANCE_COMPANY';
export const DELETE_INSURANCE_MANUAL = 'index/DELETE_INSURANCE_MANUAL';

// * OTROS
export const ACTION_SET_INSURANCES = 'index/ACTION_SET_INSURANCES';
export const ACTION_SET_INSURANCES_COMPANY_PAYROLL_BY_ID =
  'index/ACTION_SET_INSURANCES_COMPANY_PAYROLL_BY_ID';
