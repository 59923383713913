<template>
  <a-modal
    v-model:visible="visibility"
    title="Eliminar cobertura"
    :closable="!loading"
    :maskClosable="!loading"
    @cancel="closeModal"
  >
    <a-skeleton active :loading="get_coverage_loading">
      <p>
        ¿Estás seguro que deseas eliminar la cobertura
        <strong>{{ coverage_name }}</strong
        >?
      </p>

      <a-form
        ref="form_ref"
        :model="form_state"
        :rules="rules"
        layout="vertical"
      >
        <a-form-item ref="delete" name="delete">
          <a-input v-model:value="form_state.delete" placeholder="eliminar" />
        </a-form-item>
      </a-form>
    </a-skeleton>

    <template #footer>
      <a-button :disabled="loading" @click="closeModal">Cancelar</a-button>
      <a-button type="primary" danger :loading="loading" @click="deletePolicy"
        >Continuar</a-button
      >
    </template>
  </a-modal>
</template>

<script setup>
import { ref, defineExpose, reactive } from 'vue';
import { useStore } from 'vuex';
import {
  FETCH_COVERAGE,
  DELETE_COMPANY_POLICY_COVERAGE
} from '../../store/types';
import notification from '../../utils/notifications';
import EventBus from '@/utils/events';

const store = useStore();
const coverage_name = ref();
const coverage_id = ref();
const visibility = ref(false);
const loading = ref(false);
const form_ref = ref();
const form_state = reactive({
  delete: ''
});
const get_coverage_loading = ref(false);

const deleteWordValidator = async (rule, value) => {
  if (!value || value != 'eliminar') {
    const error_message = 'Ingrese la palabra solicitada';
    return Promise.reject(error_message);
  }
  return Promise.resolve();
};

const rules = {
  delete: [
    {
      required: true,
      validator: deleteWordValidator,
      trigger: 'change'
    }
  ]
};

const openModal = (coverageId) => {
  getCoverage(coverageId);
  visibility.value = true;
};

const getCoverage = async (coverageId) => {
  get_coverage_loading.value = true;
  try {
    const { id, name } = await store.dispatch(FETCH_COVERAGE, coverageId);
    coverage_id.value = id;
    coverage_name.value = name;
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
  get_coverage_loading.value = false;
};

const closeModal = () => {
  visibility.value = false;
  form_ref.value.resetFields();
};

const deletePolicy = async () => {
  form_ref.value.validate().then(() => {
    loading.value = true;
    store
      .dispatch(DELETE_COMPANY_POLICY_COVERAGE, coverage_id.value)
      .then(() => {
        EventBus.emit('coverageDeleted', true);
        notification(
          'success',
          'Correcto',
          'Cobertura eliminada correctamente'
        );
        closeModal();
      })
      .catch((error) => {
        notification('error', 'Error', error.response.data.message);
      })
      .finally(() => {
        loading.value = false;
      });
  });
};

defineExpose({
  openModal
});
</script>
