<template>
  <div>
    <a-collapse v-model:activeKey="collapse_open">
      <a-collapse-panel key="1" header="Documentos adjuntos">
        <a-table
          :dataSource="table_data"
          :columns="columns"
          :showHeader="false"
          :pagination="false"
        >
          <template #name="{ text: name }">
            <span>{{ name.split('.')[0].replace('xls') }}</span>
          </template>
          <template #file_name="{ text: name }">
            <a>{{ name }}</a>
          </template>
          <template #url="{ text: url }">
            <a-tooltip placement="topLeft" title="Descargar Archivo">
              <a type="link" :href="url">
                <eye-outlined />
              </a>
            </a-tooltip>
          </template>
        </a-table>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { EyeOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    'eye-outlined': EyeOutlined
  },
  props: {
    data: {
      type: Array,
      default: []
    }
  },
  setup(props) {
    const collapse_open = ref([]);
    const table_data = computed(() => props.data);
    const columns = [
      {
        dataIndex: 'name',
        slots: {
          customRender: 'name'
        }
      },
      {
        dataIndex: 'file_name',
        slots: {
          customRender: 'file_name'
        }
      },
      {
        dataIndex: 'url',
        slots: {
          customRender: 'url'
        }
      }
    ];

    return {
      collapse_open,
      table_data,
      columns
    };
  }
};
</script>
