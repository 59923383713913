<template>
  <a-row type="flex" justify="center">
    <div style="height: 32px" />
  </a-row>
  <br /><br /><br /><br />
  <a-row justify="center" class="mb-2">
    <a-col>
      <CloseCircleFilled class="text-red-6 display-3 mb-3" />
    </a-col>
  </a-row>

  <a-row class="text-center" type="flex" justify="center">
    <a-col :xs="15" :sm="16" :md="12" :lg="9">
      <a-typography-title :level="4" class="text-gray-8 ff-urbanist mb-1">Ups hubo un error</a-typography-title>
      <a-typography-text class="text-gray-8">El documento no fue enviado, por favor inténtalo
        nuevamente.</a-typography-text>
    </a-col>
  </a-row>

  <a-row class="my-4 pb-1" type="flex" justify="center">
    <a-col class="text-center" :xs="20" :sm="16" :md="12" :lg="8">
      <a-button type="primary"> Intentar nuevamente</a-button>
    </a-col>
  </a-row>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { GET_REAPPLICATION_DOCUMENT_STATE } from '@/store/types';
import { CloseCircleFilled } from '@ant-design/icons-vue';

export default {
  components: { CloseCircleFilled },
  name: 'ResolicitudDocumentoError',
  setup() {
    const store = useStore();
    const router = useRouter();

    const resp = store.getters[GET_REAPPLICATION_DOCUMENT_STATE];

    if (resp == false) {
      router.push({ name: 'Missing' });
    }
  }
};
</script>
