<template>
  <div>
    <a-modal
      v-model:visible="modal_visible"
      :title="`Aprobar solicitud por ${title}`"
      :closable="!loading"
      :maskClosable="!loading"
      centered
      :width="requestType >= 2 ? '50%' : '28%'"
      :min-width="requestType >= 2 ? '850px' : '0'"
    >
      <a-typography-title v-if="requestType >= 2" :level="5"
        >¿Estás seguro que deseas aprobar la solicitud por {{ title }} para la
        factura {{ numberInvoice.join(', ') }}?
      </a-typography-title>
      <a-typography-title v-else :level="5"
        >¿Estás seguro que deseas aprobar la solicitud por {{ title }}?
      </a-typography-title>
      <a-row>
        <a-col :span="requestType >= 2 ? 1 : 3">
          <exclamation-circle-outlined
            :style="{ fontSize: '30px', color: '#FFBF01' }"
          />
        </a-col>
        <a-col :span="requestType >= 2 ? 23 : 20">
          <a-typography-text class="text-gray-8">
            {{ main_text }}
          </a-typography-text>
        </a-col>
        <a-col class="mt-2" v-if="requestType == 1">
          <a-typography-title :level="5" strong
            >Resolución compañía aseguradora</a-typography-title
          >
          <a-typography-text class="text-gray-8 fw-regular">
            Escribe aquí los detalles de la resolución de la compañia
            aseguradora
          </a-typography-text>
          <a-form
            ref="form_ref"
            :model="form_state"
            name="form_state"
            :rules="rules"
            class="mt-2"
          >
            <a-form-item name="message_text">
              <a-textarea
                placeholder="Ingrese comentario"
                v-model:value="form_state.message_text"
                :autoSize="{ minRows: 8, maxRows: 8 }"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
      <a-row v-if="requestType >= 2" style="margin-bottom: -18px">
        <a-form
          ref="form_ref"
          :model="form_state"
          name="form_state"
          :rules="rules"
          class="mt-2"
        >
          <a-form-item has-feedback name="fileList">
            <a-upload
              name="file"
              :file-list="form_state.fileList"
              :multiple="true"
              :customRequest="customUpload"
            >
              <a-button>
                <upload-outlined></upload-outlined>
                Agregar documento
              </a-button>
            </a-upload>
          </a-form-item>
        </a-form>
      </a-row>

      <a-table
        v-if="requestType >= 2"
        :loading="
          loadingTableFileInvoice ||
          loadingSaveInvoices ||
          loadingDeleteInvoices
        "
        :showHeader="false"
        :columns="columns"
        :data-source="fileInvoice"
      >
        <template #custom_invoices="{ record }">
          <a-select
            v-model:value="selectedInvoices[record.key]"
            :options="invoicesSelectOption"
            placeholder="Selecciona numero de factura"
            mode="multiple"
            :style="requestType >= 2 ? 'min-width: 100%' : ''"
            :maxTagCount="2"
            :maxTagTextLength="5"
            size="default"
            optionFilterProp="label"
            :labelInValue="true"
            :deselect="deSelect"
          >
          </a-select>
        </template>
        <template #details="{ record }">
          <a-row>
            <a-col>
              <a-tooltip
                color="#05045D"
                :title="`Agregar numero de factura`"
                @click="
                  patchFileInvoices(selectedInvoices[record.key], record.id)
                "
              >
                <saved-outlined
                  :style="{ fontSize: '18px' }"
                  class="blue-icon"
                ></saved-outlined>
              </a-tooltip>
            </a-col>
            <a-col>
              <a-tooltip
                color="#05045D"
                :title="`Eliminar`"
                @click="deleteFileInvoice(record.id)"
              >
                <delete-outlined
                  :style="{ fontSize: '18px' }"
                  class="delete-holding-text"
                />
              </a-tooltip>
            </a-col>
          </a-row>
        </template>
      </a-table>

      <template #footer>
        <a-button :disabled="loading" @click="closeModal">Cancelar</a-button>
        <a-button
          :disabled="requestType == 1 ? validTextMessage : false"
          type="primary"
          :loading="loading"
          @click="aproveRequest"
          >Sí, aprobar</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, ref, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import {
  ExclamationCircleOutlined,
  UploadOutlined,
  SaveOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue';
import notification from '@/utils/notifications';
import {
  GET_BROKER_ID,
  POST_CHANGE_REQUEST_STATE,
  POST_FILES_INVOICES,
  FETCH_FILES_INVOICES,
  GET_FILES_INVOICES,
  GET_INVOICES,
  GET_SELECT_FILTER,
  SELECT_INVOICES_DATA,
  DELETE_FILE_INVOICE,
  PATCH_FILE_INVOICE
} from '@/store/types';

export default {
  components: {
    'exclamation-circle-outlined': ExclamationCircleOutlined,
    'upload-outlined': UploadOutlined,
    'saved-outlined': SaveOutlined,
    'delete-outlined': DeleteOutlined
  },
  props: {
    requestType: {
      type: Number,
      default: 1
    },
    collectionData: {
      type: Object,
      default: {}
    },
    requestId: {
      type: [String, Number],
      default: ''
    },
    requestData: {
      type: Object,
      default: {}
    }
  },
  setup(props, { emit }) {
    const modal_visible = ref(false);
    const loading = ref(false);
    const store = useStore();
    const validTextMessage = ref(false);
    const form_ref = ref();
    const loadingTableFileInvoice = ref(false);
    const loadingSaveInvoices = ref(false);
    const loadingDeleteInvoices = ref(false);
    const selectedInvoices = computed({
      get() {
        return store.getters[GET_SELECT_FILTER];
      },
      set(value) {
        store.dispatch(SELECT_INVOICES_DATA, value);
      }
    });
    const numberInvoice = computed(() =>
      props.requestData?.requestInvoices?.map((e) => `N°${e.invoice?.folio}`)
    );

    const invoicesSelectOption = computed(() => store.getters[GET_INVOICES]); // filtro por dataaaa));
    const fileInvoice = computed(() => store.getters[GET_FILES_INVOICES]);
    const columns = [
      {
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        slots: {
          customRender: 'custom_name'
        }
      },
      {
        dataIndex: 'invoices',
        key: 'invoices',
        slots: {
          customRender: 'custom_invoices'
        }
      },
      {
        title: 'Opciones',
        dataIndex: 'id',
        key: 'id',
        width: '90px',
        slots: {
          customRender: 'details'
        }
      }
    ];
    // fileInvoice.value.map(e=>({
    //   title: 'Name',
    //   dataIndex: 'name',
    //   key: e.name,
    //   slots: {
    //     customRender: 'custom_id'
    //   }
    // }));
    const form_state = reactive({
      message_text: '',
      fileList: [],
      selectInvoices: []
    });
    const rules = {
      message_text: [
        {
          required: true,
          message: 'Ingrese un mensaje',
          trigger: 'change',
          type: 'string'
        }
      ]
    };
    const title = computed(() => {
      const types = {
        1: 'Ajuste retroactivo',
        2: 'Nota de crédito',
        3: 'Nota de débito'
      };

      return types[props.requestType];
    });

    // const typeRequestFound=computed(()=>{
    // });
    const collection = computed(() => props.collectionData);

    const main_text = computed(() => {
      let text;
      if (props.requestType >= 2) {
        text = `Para aprobar una solicitud por ${title.value} la compañia aseguradora debe haber aceptado la solicitud y haber emitido el documento`;
      } else {
        text = `Para aprobar una solicitud por ajuste retroactivo la compañia aseguradora debe haber válidado las inconsistencias en la nómina de cobranza. El ajuste retroactivo quedará registrado para el próximo periodo de cobranza asociado al número de póliza ${collection.value.policies.join()}.`;
      }

      return text;
    });
    const getFileInvoice = async () => {
      await store.dispatch(FETCH_FILES_INVOICES, props.requestId);
    };
    const openModal = async () => {
      modal_visible.value = true;
      validTextMessage.value = true;
      loadingTableFileInvoice.value = true;

      try {
        await getFileInvoice();
        loadingTableFileInvoice.value = false;
      } catch (error) {
        loadingTableFileInvoice.value = false;
      }
    };

    const closeModal = () => {
      modal_visible.value = false;
    };

    const aproveRequest = async () => {
      loading.value = true;
      try {
        let payload;
        if (props.requestType >= 2) {
          payload = {
            state: 2, // 2 -> Aprobado
            brokerId: store.getters[GET_BROKER_ID]
          };
        } else {
          payload = {
            insurance_resolution: form_state.message_text,
            state: 2, // 2 -> Aprobado
            brokerId: store.getters[GET_BROKER_ID]
          };
        }

        await store.dispatch(POST_CHANGE_REQUEST_STATE, {
          id: props.requestId,
          payload
        });
        notification('success', 'Correcto', 'Solicitud Aprobada.');
        emit('aprovedRequest', true);
        closeModal();
        form_state.message_text = '';
        form_ref.value.resetFields();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };
    watch(form_state, (val) => {
      if (val.message_text == '') {
        validTextMessage.value = true;
      } else {
        validTextMessage.value = false;
      }
    });
    const customUpload = async (file) => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        notification('error', 'Error', 'El archivo excede el peso máximo 5MB');
      } else {
        loadingTableFileInvoice.value = true;
        try {
          await store.dispatch(POST_FILES_INVOICES, {
            requestId: props.requestId,
            payload: { file: file.file }
          });
          await getFileInvoice();
          loadingTableFileInvoice.value = false;
        } catch (error) {
          loadingTableFileInvoice.value = false;
          loadingSaveInvoices.value = false;
          loadingTableFileInvoice.value = false;
        }
      }
      return false;
    };
    const deleteFileInvoice = async (id) => {
      loadingDeleteInvoices.value = true;
      await store.dispatch(DELETE_FILE_INVOICE, {
        requestId: props.requestId,
        requestInvoiceFileId: id
      });
      await getFileInvoice();
      loadingDeleteInvoices.value = false;
    };
    const patchFileInvoices = async (selectedInvoices, requestFileId) => {
      loadingSaveInvoices.value = true;
      try {
        await store.dispatch(PATCH_FILE_INVOICE, {
          requestId: props.requestId,
          requestInvoiceFileId: requestFileId,
          payload: { invoiceIds: selectedInvoices.map((si) => si.value) }
        });
        await getFileInvoice();
        loadingSaveInvoices.value = false;
      } catch (error) {
        loadingSaveInvoices.value = false;
        loadingTableFileInvoice.value = false;
      }
    };
    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    return {
      modal_visible,
      title,
      main_text,
      loading,
      aproveRequest,
      openModal,
      closeModal,
      form_state,
      rules,
      validTextMessage,
      form_ref,
      numberInvoice,
      customUpload,
      fileInvoice,
      invoicesSelectOption,
      columns,
      selectedInvoices,
      loadingTableFileInvoice,
      deleteFileInvoice,
      getFileInvoice,
      patchFileInvoices,
      filterOption,
      loadingSaveInvoices,
      loadingDeleteInvoices
    };
  }
};
</script>
