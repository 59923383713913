<template>
  <div class="centered">
    <img src="@/assets/images/token-expired.svg" class="img-margin-ready centered-image" />
    <a-typography-title style="bottom: 0px; font-family: 'Urbanist'; text-align: center">
      Oh no! el enlace ha expirado
    </a-typography-title>
    <p>
      Haz clic en el botón reenviar para recibir un nuevo enlace en tu correo
      electrónico
    </p>
    <a-button type="primary" @click="reseendToken" :loading="loading" :disabled="disabled">Reenviar</a-button>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { FETCH_DIGITAL_FORM_RESETTED_TOKEN } from '@/store/types';
import notification from '@/utils/notifications';
import { useRoute } from 'vue-router';

const loading = ref(false);
const store = useStore();
const disabled = ref(false);
const route = useRoute();
const token = route.params.token;

const reseendToken = () => {
  loading.value = true;
  store
    .dispatch(FETCH_DIGITAL_FORM_RESETTED_TOKEN, token)
    .then(() => {
      notification(
        'success',
        'Correcto',
        'Se a enviado un correo con un nuevo enlace'
      );
      disabled.value = true;
    })
    .catch((error) => {
      notification('error', 'Error', error.response.data.message);
    })
    .finally(() => {
      loading.value = false;
    });
};
</script>

<style scoped>
.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centered-image {
  height: 400px;
}
</style>
