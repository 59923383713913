<template>
  <div v-if="$route.name === 'CobranzaFacturacion'">
    <div>
      <page-header
        :breadcrumRoutes="[
          {
            breadcrumbName: 'Cobranza y facturación'
          }
        ]"
        title="Cobranza y facturación"
      >
        <template #buttons>
          <a-button type="primary" @click="openModal"
            >Iniciar cobranza</a-button
          >
        </template>
      </page-header>

      <resume-header
        title="Resumen total"
        :category-values="resume_data"
        :total-progress="total_percentage"
      />

      <a-card class="mt-2">
        <div class="mt-2">
          <a-typography-title :level="4" class="text-gray-8 mb-0 left-aligned">
            Stock cobranza y facturación
          </a-typography-title>

          <a-tooltip color="#05045D">
            <template #title
              >Un ticket puede contener más de una factura por póliza</template
            >
            <exclamation-circle-outlined style="font-size: 20px" class="ml-2" />
          </a-tooltip>

          <a-radio-group
            :value="type_selection"
            @change="onChangeTabs"
            class="ml-4"
          >
            <a-radio-button value="ticket">Todas</a-radio-button>
            <a-radio-button value="form">Formularios</a-radio-button>
          </a-radio-group>

          <div class="right-aligned">
            <a-button
              size="large"
              class="button_with_icon"
              @click="stockDownload"
            >
              <template #icon>
                <download-outlined />
              </template>
              Descargar stock
            </a-button>
          </div>

          <div style="clear: both"></div>
        </div>

        <div class="mt-3">
          <tickets v-if="type_selection === 'ticket'" />
          <forms v-else />
        </div>
      </a-card>

      <a-modal
        v-model:visible="stock_modal_prop.visible"
        title="Inicio de cobranza"
        @cancel="stockModalOnClose"
      >
        <a-typography-title :level="5">
          Selecciona un periodo para iniciar el proceso de cobranza
        </a-typography-title>
        <br />
        <div>
          <a-form
            name="form"
            ref="stock_ref"
            :model="stock_form"
            :rules="stock_form_rules"
            layout="vertical"
          >
            <a-form-item required name="date" label="Selecciona periodo">
              <a-month-picker
                v-model:value="stock_form.date"
                placeholder="AAAA-MM"
                style="width: 100% !important"
              />
            </a-form-item>
          </a-form>
        </div>
        <template #footer>
          <a-button key="submit" type="primary" @click="formValidate"
            >Iniciar</a-button
          >
        </template>
      </a-modal>
    </div>
  </div>

  <router-view :key="$route.path"></router-view>
</template>

<script>
import {
  DownloadOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { reactive, ref, defineAsyncComponent, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PageHeader from '@/components/PageHeader.vue';

const CollectionBillingForms = defineAsyncComponent(() =>
  import('@/components/Tables/CollectionBillingForms.vue')
);
const CollectionBillingTickets = defineAsyncComponent(() =>
  import('@/components/Tables/CollectionBillingTickets.vue')
);

import parseDate from '@/utils/dateFormatter';

import {
  FETCH_ALL_COLLECTION_FORM_SUMMARY,
  GET_ALL_COLLECTION_FORM_SUMMARY,
  FETCH_ALL_COLLECTION_SUMMARY,
  GET_ALL_COLLECTION_SUMMARY,
  FETCH_COLLECTION_STOCK_DOWNLOAD,
  FETCH_COLLECTION_FORM_DOWNLOAD
} from '@/store/types';
import notification from '@/utils/notifications';
import ResumeHeader from '@/components/resume header/ResumeHeader.vue';

export default {
  name: 'CollectionBilling',
  components: {
    'page-header': PageHeader,
    tickets: CollectionBillingTickets,
    forms: CollectionBillingForms,
    'download-outlined': DownloadOutlined,
    'exclamation-circle-outlined': ExclamationCircleOutlined,
    'resume-header': ResumeHeader
  },
  setup() {
    // * Vuex
    const store = useStore();

    // * Route
    const route = useRoute();
    const router = useRouter();

    // * Seleccion de tipo
    const type_selection = ref('');
    type_selection.value = route.hash === '' ? 'ticket' : 'form';

    const ticket_routes = ref({});
    const form_routes = ref({});

    const onChangeTabs = async (value) => {
      // Obtengo el valor de la tab que hice click
      const tab_value = value.target.value;

      // obtengo especificamente estos valores de la ruta oara reemplazarselos a la otra tab ya que tienen que compartirse
      const { holdingName, subHoldingName, companyId, numberPolicy, period } =
        route.query;
      ticket_routes.value = {
        ...ticket_routes.value,
        holdingName,
        subHoldingName,
        companyId,
        numberPolicy,
        period
      };
      form_routes.value = {
        ...form_routes.value,
        holdingName,
        subHoldingName,
        companyId,
        numberPolicy,
        period
      };

      // Muestro el resumen
      resumeTypeValidation(tab_value);

      if (tab_value === 'ticket') {
        // Guardo los filtros que hice en individual
        form_routes.value = route.query;
        // Redireccion
        await router.replace({
          name: 'CobranzaFacturacion',
          query: ticket_routes.value
        });
      } else {
        // Guardo los filtros que hice en individual
        ticket_routes.value = route.query;
        // Redireccion
        await router.replace({
          name: 'CobranzaFacturacion',
          query: form_routes.value,
          hash: '#billing'
        });
        // Carga de resumen
        getCollectionFormResume();
      }

      type_selection.value = value.target.value;
    };

    // * Resumen
    const resume_data = ref({});
    const total_percentage = ref(0);

    const resumeTypeValidation = (type) => {
      if (type === 'ticket') {
        getCollectionResume();
      } else {
        getCollectionFormResume();
      }
    };

    const getPercentage = (total, number) => {
      return total > 0 ? Math.round((number * 100) / total) : 0;
    };

    const getCollectionFormResume = async () => {
      try {
        await store.dispatch(FETCH_ALL_COLLECTION_FORM_SUMMARY);
        const { total, proccesing, loaded, cancelled, created, progress } =
          store.getters[GET_ALL_COLLECTION_FORM_SUMMARY];

        total_percentage.value = progress;

        const obj = [
          {
            name: 'Solicitudes',
            first_display: total,
            second_display: total
          },
          {
            name: 'En proceso',
            first_display: proccesing,
            second_display: `${getPercentage(total, proccesing)}%`
          },
          {
            name: 'Creadas',
            first_display: created,
            second_display: `${getPercentage(total, created)}%`
          },
          {
            name: 'Cargadas',
            first_display: loaded,
            second_display: `${getPercentage(total, loaded)}%`
          },
          {
            name: 'Canceladas',
            first_display: cancelled,
            second_display: `${getPercentage(total, cancelled)}%`
          }
        ];

        resume_data.value = obj;
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const getCollectionResume = async () => {
      try {
        await store.dispatch(FETCH_ALL_COLLECTION_SUMMARY);
        const {
          total,
          created,
          approved,
          bewellValidation,
          hrValidation,
          progress
        } = store.getters[GET_ALL_COLLECTION_SUMMARY];
        total_percentage.value = progress;

        const obj = [
          {
            name: 'Total',
            first_display: total,
            second_display: total
          },
          {
            name: 'Creadas',
            first_display: created,
            second_display: `${getPercentage(total, created)}%`
          },
          {
            name: 'Aprobadas',
            first_display: approved,
            second_display: `${getPercentage(total, approved)}%`
          },
          {
            name: 'Validación corredora',
            first_display: bewellValidation,
            second_display: `${getPercentage(total, bewellValidation)}%`
          },
          {
            name: 'Validación RRHH',
            first_display: hrValidation,
            second_display: `${getPercentage(total, hrValidation)}%`
          }
        ];

        resume_data.value = obj;
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    // * Modal para descargar el stock
    const stock_ref = ref();
    const stock_modal_prop = reactive({
      visible: false,
      title: '',
      description: '',
      button_text: '',
      loading: false,
      mode: ''
    });

    const stock_form = reactive({
      date: ''
    });

    const stock_form_rules = {
      date: [
        {
          required: true,
          message: 'Seleccione periodo',
          trigger: 'change',
          type: 'object'
        }
      ]
    };

    const openModal = () => {
      stock_modal_prop.visible = true;
    };

    const formValidate = async () => {
      try {
        await stock_ref.value.validate();
        startBilling();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const stockDownload = async () => {
      const route_query = route.query;
      delete route_query.page;
      let final_query = '';
      Object.keys(route_query).forEach((key, index) => {
        if (index === 0) {
          final_query = `${key}=${route_query[key]}`;
        } else {
          final_query += `&${key}=${route_query[key]}`;
        }
      });

      try {
        const download_link_type =
          type_selection.value === 'ticket'
            ? FETCH_COLLECTION_STOCK_DOWNLOAD
            : FETCH_COLLECTION_FORM_DOWNLOAD;
        const url = await store.dispatch(download_link_type, `?${final_query}`);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'stock.xlsx';
        link.click();
        link.remove();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const startBilling = () => {
      router.push({
        name: 'BillingFormCration',
        query: { fecha: stock_form.date.format('MM-YYYY') }
      });
      console.log(parseDate(stock_form.date).substring(0, 10));
    };

    const stockModalOnClose = () => {
      stock_ref.value.resetFields();
    };

    onMounted(() => {
      const type =
        route.hash === '' || route.hash === undefined ? 'ticket' : 'form';
      resumeTypeValidation(type);
    });

    return {
      type_selection,
      stock_modal_prop,
      stock_form_rules,
      stock_form,
      resume_data,
      stock_ref,
      total_percentage,
      onChangeTabs,
      stockDownload,
      stockModalOnClose,
      openModal,
      formValidate,
      startBilling,
      getCollectionFormResume
    };
  }
};
</script>
