<template>
  <div class="centered">
    <img
      src="../../assets/images/insured_form/ready-img.svg"
      alt=""
      width="210"
      class="img-margin-ready"
    />
    <a-result
      status="success"
      title="¡Tu documento ha sido firmado satisfactoriamente!"
      sub-title="Recibirás un correo de confirmación con una copia de tu documento firmado. Una vez que la aseguradora procese tu información, te informaremos la fecha de activación de tus beneficios."
    />
  </div>
</template>

<style scoped>
.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
