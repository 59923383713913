<template>
  <a-card class="mb-3">
    <a-row>
      <a-col :span="24">
        <a-steps :current="step" :status="status">
          <template v-for="(state, index) in states" :key="index">
            <a-step :title="state.name" :description="state.date" />
          </template>
        </a-steps>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { ref, toRefs, watch } from 'vue';

export default {
  name: 'Stepper',
  props: {
    ticketType: {
      type: String,
      default: 'alta'
    },
    state: {
      type: String,
      default: 'creado'
    },
    stateDates: {
      type: Object,
      default: {},
      required: false
    }
  },
  setup(props) {
    const type = toRefs(props, 'ticketType');
    const state = toRefs(props, 'state');

    const step = ref(0);
    const status = ref('process');

    // Aca valido que tipo de ticket es para poner los estados en el stepper
    // Por ejemplo estos serian los estados por ticket
    //         ALTA : Creado, revision bewell, revision aseguradora, ejecutado, cancelado
    //         BAJA : Creado, revision aseguradora, ejecutado, cancelado
    // MODIFICACION : Creado, revision aseguradora, ejecutado, cancelado

    const states = ref([]);

    const createSteps = (value) => {
      const states_data = [
        {
          state: 'creado',
          name: 'Creado',
          date: props.stateDates.creado != '' ? props.stateDates.creado : ''
        },
        {
          state: 'revision_aseguradora',
          name: 'En revisión aseguradora',
          date:
            props.stateDates.revision_aseguradora != ''
              ? props.stateDates.revision_aseguradora
              : ''
        },
        {
          state: 'ejecutado',
          name: 'Ejecutado',
          date:
            props.stateDates.ejecutado != '' ? props.stateDates.ejecutado : ''
        }
      ];

      if (value === 'alta') {
        states_data.splice(1, 0, {
          state: 'revision_bewell',
          name: 'En revisión corredora',
          date:
            props.stateDates.revision_bewell != ''
              ? props.stateDates.revision_bewell
              : ''
        });
      }

      states.value = states_data;
    };

    const createState = (value) => {
      for (let i = 0; i < states.value.length; i++) {
        const element = states.value[i];
        if (value === element.state) {
          step.value = i;
        }
      }
    };

    const cancelStep = () => {
      status.value = 'error';
    };
    const finishStep = () => {
      status.value = 'process';
      status.value = 'finish';
    };

    const uncalcenStep = () => {
      status.value = 'process';
    };

    watch(
      () => props.ticketType,
      (val) => {
        createSteps(val);
      }
    );

    watch(
      () => props.state,
      (val) => {
        createState(val);
      }
    );

    watch(
      () => props.stateDates,
      (oldVal, newVal) => {
        // Este usar para crear la parte de cambias las fechas cuando se cancela o ejecuta
        for (let i = 0; i < states.value.length; i++) {
          const element = states.value[i];

          if (element.state in newVal) {
            states.value[i].date = newVal[element.state];
          }
        }

        createSteps(props.ticketType);
      },
      { deep: true }
    );

    createSteps(props.ticketType);
    createState(props.state);

    return {
      type,
      states,
      // TODO: ARREGLAR VARIABLES DUPLICADAS
      // eslint-disable-next-line vue/no-dupe-keys
      state,
      step,
      status,
      cancelStep,
      finishStep,
      uncalcenStep
    };
  }
};
</script>
