<template>
  <div>
    <div class="buttons_section mb-3" v-if="onPolicyDetail">
      <a-radio-group :value="type_selection" @change="handleChange">
        <a-radio-button style="margin: 1px; z-index: 0" value="Movimientos">Movimientos</a-radio-button>
        <a-radio-button value="cobranzaFacturacionTabs">Cobranza y facturación</a-radio-button>
      </a-radio-group>
      <a-button v-if="type_selection === 'Movimientos'" type="primary" @click="openCreateNewPolicy">Nueva
        póliza</a-button>
      <a-button type="primary" @click="openNewConfiguration" v-else>
        Nueva configuración
      </a-button>
    </div>

    <!-- Crear y editar poliza -->
    <PolicyConfModal ref="policy_conf_modal_ref" />

    <!-- Nueva configuracion -->
    <NewConfiguration ref="new_configuration_ref" />
  </div>
  <router-view :key="$route.path"></router-view>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PolicyConfModal from '@/components/clients/PolicyConfModal.vue';
import NewConfiguration from '@/components/clients/policy/ModalNewConfiguration.vue';

const router = useRouter();
const route = useRoute();

const onPolicyDetail = computed(() => {
  return !(route.name === 'PolicyDetail');
});

// * Boton de crear nueva poliza
const policy_conf_modal_ref = ref();
const openCreateNewPolicy = () => {
  policy_conf_modal_ref.value.openModalOnCreate();
};

// * Boton de crear nueva configuracion
const new_configuration_ref = ref();
const openNewConfiguration = () => {
  new_configuration_ref.value.openModal();
};

// * Radios para cambiar de ventana
const type_selection = ref('');
const handleChange = async (value) => {
  type_selection.value = value.target.value;
  if (type_selection.value == 'Movimientos') {
    await router.push({
      name: 'Movimientos',
      params: { tab: type_selection.value },
      query: { companyId: route.params.id, status: 'Vigente' }
    });
  } else if (type_selection.value == 'cobranzaFacturacionTabs') {
    await router.push({
      name: 'cobranzaFacturacionTabs',
      params: { tab: type_selection.value },
      query: { companyId: route.params.id, status: 'Vigente' }
    });
  }
};

// * Carga principal
onMounted(() => {
  type_selection.value = route.name;
  if (route.name == 'ConfigPoliza') {
    type_selection.value = 'cobranzaFacturacionTabs';
  }
});

onBeforeMount(() => {
  if (route.name == 'Poliza') {
    router.replace({
      name: 'Movimientos',
      params: { tab: 'Movimientos' },
      query: { companyId: route.params.id, status: 'Vigente' }
    });
  } else if (route.name == 'cobranzaFacturacionTabs') {
    router.replace({
      name: 'cobranzaFacturacionTabs',
      params: { tab: 'Movimientos' },
      query: { companyId: route.params.id, status: 'Vigente' }
    });
  }
  type_selection.value = route.params.tab;
});
</script>

<style scoped>
.buttons_section {
  display: flex;
  justify-content: space-between;
}
</style>
