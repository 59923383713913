// * ACTIONS
export const GET_VALIDATE_REAPPLICATION_DOCUMENT =
  'reapplication/GET_VALIDATE_REAPPLICATION_DOCUMENT';
export const GET_OLD_DOCUMENT = 'reapplication/GET_OLD_DOCUMENT';
export const GET_BLANK_DOCUMENT = 'reapplication/GET_BLANK_DOCUMENT';
export const POST_REAPPLICATION_DOCUMENT =
  'reapplication/POST_REAPPLICATION_DOCUMENT';

// * mutations
export const MUTATE_REAPPLICATION_DOCUMENT =
  'reapplication/MUTATE_REAPPLICATION_DOCUMENT';

// * GETTERS
export const GET_REAPPLICATION_DOCUMENT_STATE =
  'reapplication/GET_REAPPLICATION_DOCUMENT_STATE';
