// * GET
export const GET_COLLECTION_FORM_ROWS = 'procesos/GET_COLLECTION_FORM_ROWS';
export const GET_COLLECTION_TICKETS = 'procesos/GET_COLLECTION_TICKETS';
export const GET_COLLECTION_FORMS = 'procesos/GET_COLLECTION_FORMS';
export const GET_COLLECTION_FORM = 'procesos/GET_COLLECTION_FORM';
export const GET_COLLECTION_FORM_INSURANCE_COMPANIES =
  'procesos/GET_COLLECTION_FORM_INSURANCE_COMPANIES';
export const GET_PAYROLL_OBSERVATIONS = 'procesos/GET_PAYROLL_OBSERVATIONS';
export const GET_INVOICE = 'procesos/GET_INVOICE';
export const GET_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES =
  'procesos/GET_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES';
export const GET_COLLECTION_FORM_INSURANCE_COMPANIES_POLICIES =
  'procesos/GET_COLLECTION_FORM_INSURANCE_COMPANIES_POLICIES';
export const GET_INVOICE_POLICIES = 'procesos/GET_INVOICE_POLICIES';
export const GET_COLLECTION_GROUP_COMMENTS =
  'procesos/GET_COLLECTION_GROUP_COMMENTS';
export const GET_COLLECTION_FORM_INSURANCE_COMPANY_PAYROLL =
  'procesos/GET_COLLECTION_FORM_INSURANCE_COMPANY_PAYROLL';
export const GET_COMPANIES_INVOICE = 'procesos/GET_COMPANIES_INVOICE';
export const GET_REPROCESS_BY_COLLECTION_ID =
  'procesos/GET_REPROCESS_BY_COLLECTION_ID';
export const GET_COLLECTION_GROUP_COLLECTIONS =
  'procesos/GET_COLLECTION_GROUP_COLLECTIONS';
export const GET_REQUESTS_BY_COLLECTION_ID =
  'procesos/GET_REQUESTS_BY_COLLECTION_ID';
export const GET_COLLECTION_ATTACHMENTS = 'procesos/GET_COLLECTION_ATTACHMENTS';
export const GET_COLLECTION_FORM_SUMMARY =
  'procesos/GET_COLLECTION_FORM_SUMMARY';
export const GET_COLLECTION_DATA_RRHH = 'procesos/GET_COLLECTION_DATA_RRHH';
export const GET_FILES_COLLECTION = 'procesos/GET_FILES_COLLECTION';
export const GET_COLLECTION_TICKET_PAYROLL =
  'procesos/GET_COLLECTION_TICKET_PAYROLL';
export const GET_INVOICE_OBSERVATIONS = 'procesos/GET_INVOICE_OBSERVATIONS';
export const GET_COLLECTION_REPROCESS_RECORDS =
  'procesos/GET_COLLECTION_REPROCESS_RECORDS';
export const GET_REQUEST_BY_ID = 'procesos/GET_REQUEST_BY_ID';
export const GET_ALL_COLLECTION_FORM_SUMMARY =
  'procesos/GET_ALL_COLLECTION_FORM_SUMMARY';
export const GET_ALL_COLLECTION_SUMMARY = 'procesos/GET_ALL_COLLECTION_SUMMARY';
export const GET_COLLECTION_COMPANY_SUMMARY =
  'procesos/GET_COLLECTION_COMPANY_SUMMARY';
export const GET_INVOICE_SUMMARY = 'procesos/GET_INVOICE_SUMMARY';
export const GET_INVOICE_DEFAULTERS_SUMMARY =
  'procesos/GET_INVOICE_DEFAULTERS_SUMMARY';
export const GET_INVOICE_DEFAULTERS_SUMMARY_LIST =
  'procesos/GET_INVOICE_DEFAULTERS_SUMMARY_LIST';
export const GET_INSURANCE_COMPANY_INVOICE_UPLOAD_VALIDATION =
  'procesos/GET_INSURANCE_COMPANY_INVOICE_UPLOAD_VALIDATION';

// * POST
export const POST_COLLECTION_FORM = 'procesos/POST_COLLECTION_FORM';
export const POST_COLLECTION_PAYROLL = 'procesos/POST_COLLECTION_PAYROLL';
export const POST_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES =
  'procesos/POST_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES';
export const POST_INVOICE_POLICIES = 'procesos/POST_INVOICE_POLICIES';
export const POST_PAYROLL_REPROCESSING = 'procesos/POST_PAYROLL_REPROCESSING';
export const POST_REPROCESS = 'procesos/POST_REPROCESS';
export const POST_COLLECTION_GROUP_COMMENTS =
  'procesos/POST_COLLECTION_GROUP_COMMENTS';
export const POST_COLLECTION_TICKET_PAYROLL =
  'procesos/POST_COLLECTION_TICKET_PAYROLL';
export const POST_COLLECTION_CONFIRM_REOPEN =
  'procesos/POST_COLLECTION_CONFIRM_REOPEN';
export const POST_COLLECTION_DATA_SEND_RRHH =
  'procesos/POST_COLLECTION_DATA_SEND_RRHH';
export const POST_COLLECTION_VALDATE_PAYROLL_FORM =
  'procesos/POST_COLLECTION_VALDATE_PAYROLL_FORM';
export const POST_COLLECTION_RESOURCE_REPROCESS =
  'procesos/POST_COLLECTION_RESOURCE_REPROCESS';
export const POST_SQUARING_COLLECTION_VALIDATE =
  'procesos/POST_SQUARING_COLLECTION_VALIDATE';

// * PATCH
export const PATCH_COLLECTION_FORM_STATE =
  'procesos/PATCH_COLLECTION_FORM_STATE';
export const PATCH_INVOICE = 'procesos/PATCH_INVOICE';

// * MUTATION
export const MUTATE_COLLECTION_TICKETS = 'procesos/MUTATE_COLLECTION_TICKETS';
export const MUTATE_COLLECTION_FORMS = 'procesos/MUTATE_COLLECTION_FORMS';
export const MUTATE_COLLECTION_FORMS_INSURANCE_COMPANIES =
  'procesos/MUTATE_COLLECTION_FORMS_INSURANCE_COMPANIES';
export const MUTATE_PAYROLL_OBSERVATIONS =
  'procesos/MUTATE_PAYROLL_OBSERVATIONS';
export const MUTATE_INVOICE = 'procesos/MUTATE_INVOICE';
export const MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES =
  'procesos/MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES';
export const MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ONE_INVOICE =
  'procesos/MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ONE_INVOICE';
export const MUTATE_COLLECTION_FORM = 'procesos/MUTATE_COLLECTION_FORM';
export const MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ADD_INVOICE =
  'procesos/MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ADD_INVOICE';
export const MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ADD_PAYROLL =
  'procesos/MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_ADD_PAYROLL';
export const MUTATE_INVOICE_POLICIES = 'procesos/MUTATE_INVOICE_POLICIES';
export const MUTATE_REPROCESS_BY_COLLECTION_ID =
  'procesos/MUTATE_REPROCESS_BY_COLLECTION_ID';
export const MUTATE_COLLECTION_GROUP_COLLECTIONS =
  'procesos/MUTATE_COLLECTION_GROUP_COLLECTIONS';
export const MUTATE_REQUESTS_BY_COLLECTION_ID =
  'procesos/MUTATE_REQUESTS_BY_COLLECTION_ID';
export const MUTATE_COLLECTION_ATTACHMENTS =
  'procesos/MUTATE_COLLECTION_ATTACHMENTS';
export const MUTATE_COLLECTION_GROUP_COMMENTS =
  'procesos/MUTATE_COLLECTION_GROUP_COMMENTS';
export const MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_PAYROLLS =
  'procesos/MUTATE_COLLECTION_FORM_INSURANCE_COMPANIES_PAYROLLS';
export const MUTATE_COMPANIES_INVOICE = 'procesos/MUTATE_COMPANIES_INVOICE';
export const MUTATE_COLLECTION_FORM_SUMMARY =
  'procesos/MUTATE_COLLECTION_FORM_SUMMARY';
export const MUTATE_COLLECTION_DATA_RRHH =
  'procesos/MUTATE_COLLECTION_DATA_RRHH';
export const MUTATE_FILE_COLLECTION = 'procesos/MUTATE_FILE_COLLECTION';
export const MUTATE_COLLECTION_TICKET_PAYROLL =
  'procesos/MUTATE_COLLECTION_TICKET_PAYROLL';
export const MUTATE_COLLECTION_REPROCESS_RECORDS =
  'procesos/MUTATE_COLLECTION_REPROCESS_RECORDS';
export const MUTATE_REQUESTS_BY_ID = 'procesos/MUTATE_REQUESTS_BY_ID';
export const MUTATE_INVOICE_OBSERVATIONS =
  'procesos/MUTATE_INVOICE_OBSERVATIONS';
export const MUTATE_ALL_COLLECTION_FORM_SUMMARY =
  'procesos/MUTATE_ALL_COLLECTION_FORM_SUMMARY';
export const MUTATE_ALL_COLLECTION_SUMMARY =
  'procesos/MUTATE_ALL_COLLECTION_SUMMARY';
export const MUTATE_COLLECTION_COMPANY_SUMMARY =
  'procesos/MUTATE_COLLECTION_COMPANY_SUMMARY';
export const MUTATE_INVOICE_SUMMARY = 'procesos/MUTATE_INVOICE_SUMMARY';
export const MUTATE_INVOICE_DEFAULTERS_SUMMARY =
  'procesos/MUTATE_INVOICE_DEFAULTERS_SUMMARY';
export const MUTATE_INVOICE_DEFAULTERS_SUMMARY_LIST =
  'procesos/MUTATE_INVOICE_DEFAULTERS_SUMMARY_LIST';

// * FETCH
export const FETCH_COLLECTION_FORMS = 'procesos/FETCH_COLLECTION_FORMS';
export const FETCH_COLLECTION_TICKETS = 'procesos/FETCH_COLLECTION_TICKETS';
export const FETCH_COLLECTION_FORM_BY_ID =
  'procesos/FETCH_COLLECTION_FORM_BY_ID';
export const FETCH_COLLECTION_FORM_PAYROLL_BY_INSURANCE_COMPANY =
  'procesos/FETCH_COLLECTION_FORM_PAYROLL_BY_INSURANCE_COMPANY';
export const FETCH_COLLECTION_FORM_INSURANCE_COMPANIES =
  'procesos/FETCH_COLLECTION_FORM_INSURANCE_COMPANIES';
export const FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_POLICIES =
  'procesos/FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_POLICIES';
export const FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES =
  'procesos/FETCH_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES';
export const FETCH_PAYROLL_OBSERVATIONS = 'procesos/FETCH_PAYROLL_OBSERVATIONS';
export const FETCH_INVOICE = 'procesos/FETCH_INVOICE';
export const FETCH_INVOICE_POLICIES_BY_ID =
  'procesos/FETCH_INVOICE_POLICIES_BY_ID';
export const FETCH_REPROCESS_BY_COLLECTION_ID =
  'procesos/FETCH_REPROCESS_BY_COLLECTION_ID';
export const FETCH_REQUESTS_BY_COLLECTION_ID =
  'procesos/FETCH_REQUESTS_BY_COLLECTION_ID';
export const FETCH_COLLECTION_GROUP_COLLECTIONS =
  'procesos/FETCH_COLLECTION_GROUP_COLLECTIONS';
export const FETCH_COLLECTION_ATTACHMENTS =
  'procesos/FETCH_COLLECTION_ATTACHMENTS';
export const FETCH_COLLECTION_GROUP_COMMENTS =
  'procesos/FETCH_COLLECTION_GROUP_COMMENTS';
export const FETCH_COMPANIES_INVOICE = 'procesos/FETCH_COMPANIES_INVOICE';
export const FETCH_COLLECTION_FORM_SUMMARY =
  'procesos/FETCH_COLLECTION_FORM_SUMMARY';
export const FETCH_COLLECTION_TICKET_PAYROLL =
  'procesos/FETCH_COLLECTION_TICKET_PAYROLL';
export const FETCH_INVOICE_OBSERVATIONS = 'procesos/FETCH_INVOICE_OBSERVATIONS';
export const FETCH_COLLECTION_REPROCESS_RECORDS =
  'procesos/FETCH_COLLECTION_REPROCESS_RECORDS';
export const FETCH_COLLECTION_DATA_SEND_RRHH =
  'procesos/FETCH_COLLECTION_DATA_SEND_RRHH';
export const FETCH_FILES_COLLECTION_TICKETS =
  'procesos/FETCH_FILES_COLLECTION_TICKETS';
export const FETCH_REQUESTS_BY_ID = 'procesos/FETCH_REQUESTS_BY_ID';
export const FETCH_ALL_COLLECTION_FORM_SUMMARY =
  'procesos/FETCH_ALL_COLLECTION_FORM_SUMMARY';
export const FETCH_ALL_COLLECTION_SUMMARY =
  'procesos/FETCH_ALL_COLLECTION_SUMMARY';
export const FETCH_COLLECTION_COMPANY_SUMMARY =
  'procesos/FETCH_COLLECTION_COMPANY_SUMMARY';
export const FETCH_INVOICE_SUMMARY = 'procesos/FETCH_INVOICE_SUMMARY';
export const FETCH_INVOICE_DEFAULTERS_SUMMARY =
  'procesos/FETCH_INVOICE_DEFAULTERS_SUMMARY';
export const FETCH_INVOICE_DEFAULTERS_SUMMARY_LIST =
  'procesos/FETCH_INVOICE_DEFAULTERS_SUMMARY_LIST';
export const FETCH_COLLECTION_RESOURCE_OBSERVATIONS =
  'procesos/FETCH_COLLECTION_RESOURCE_OBSERVATIONS';
export const FETCH_COLLECTION_STOCK_DOWNLOAD =
  'procesos/FETCH_COLLECTION_STOCK_DOWNLOAD';
export const FETCH_COLLECTION_FORM_DOWNLOAD =
  'procesos/FETCH_COLLECTION_FORM_DOWNLOAD';

// * OTHERS
export const ADD_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICE =
  'procesos/ADD_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICE';
export const ADD_COLLECTION_FORM_INSURANCE_COMPANIES_PAYROLL =
  'procesos/ADD_COLLECTION_FORM_INSURANCE_COMPANIES_PAYROLL';
export const CLEAR_COLLECTION_FORM = 'procesos/CLEAR_COLLECTION_FORM';
export const DELETE_PAYROLL_FROM_COLLECTION_FORM =
  'procesos/DELETE_PAYROLL_FROM_COLLECTION_FORM';
export const CHANGE_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES_CHANGE_STATE =
  'procesos/CHANGE_COLLECTION_FORM_INSURANCE_COMPANIES_INVOICES_CHANGE_STATE';
export const DELETE_INVOICE = 'procesos/DELETE_INVOICE';
export const DELETE_COLLECTION_RESOURCE = 'procesos/DELETE_COLLECTION_RESOURCE';
export const REPROCESS_INVOICE = 'procesos/REPROCESS_INVOICE';

// Cuadratura

export const FETCH_COLLECTION_INCONSISTENCIES =
  'procesos/FETCH_COLLECTION_INCONSISTENCIES';
export const GET_COLLECTION_INCONSISTENCIES =
  'procesos/GET_COLLECTION_INCONSISTENCIES';
export const MUTATE_COLLECTION_INCONSISTENCIES =
  'procesos/MUTATE_COLLECTION_INCONSISTENCIES';

export const DOWNLOAD_SQUARING_INCONSISTENCY =
  'procesos/DOWNLOAD_SQUARING_INCONSISTENCY';
export const MUTATION_SQUARING_LINK = 'procesos/MUTATION_SQUARING_LINK';
export const GET_DOWNLOAD_SQUARING_INCOSISTENCY_LINK =
  'procesos/GET_DOWNLOAD_SQUARING_INCOSISTENCY_LINK';

// original payroll
export const PATCH_ORIGINAL_PAYROLL = 'procesos/PATCH_ORIGINAL_PAYROLL';
export const PATCH_COLLECTION_RESOURCE = 'procesos/PATCH_COLLECTION_RESOURCE';
export const GET_FILLES_ORIGINAL_PAYROLL =
  'procesos/GET_FILLES_ORIGINAL_PAYROLL';
export const MUTATE_FILES_ORIGINAL_PAYROLL_EXIST =
  'procesos/MUTATE_FILES_ORIGINAL_PAYROLL_EXIST';
export const FETCH_FILES_ORIGINAL_PAYROLL =
  'procesos/FETCH_FILES_ORIGINAL_PAYROLL';

// send human invoices
export const FETCH_INVOICE_HUMAN_SEND = 'procesos/FETCH_INVOICE_HUMAN_SEND';
export const GET_HUMAN_SEND_INVOICES = 'procesos/GET_HUMAN_SEND_INVOICES';
export const MUTATION_HUMAN_SEND_INVOICES =
  'procesos/MUTATION_HUMAN_SEND_INVOICES';
export const POST_INVOICE_HUMAN_SEND = 'procesos/POST_INVOICE_HUMAN_SEND';
