<template>
  <a-collapse class="mb-2" v-model:activeKey="activeKey">
    <a-collapse-panel key="1" header="Casos asociados">
      <div v-for="(d, index) in data" :key="index">
        <a-row>
          <a-col :span="12">{{ d.caseType }}</a-col>
          <a-col :span="8">
            <tags :color="d.tagColor">
              {{ d.currentState?.toUpperCase() }}
            </tags>
          </a-col>
          <a-col :span="4" align="end">
            <span>
              <a>
                <router-link
                  v-if="
                    d.caseType == 'Fuera de tiempo: Formulario de incorporación'
                  "
                  class="justify-content-center d-flex"
                  :to="{ name: 'DetalleFueraTiempo', params: { id: d.caseId } }"
                >
                  Ir al caso
                </router-link>
                <router-link
                  v-if="d.caseType == 'Antecedentes'"
                  class="justify-content-center d-flex"
                  :to="{ name: 'DetalleCaso', params: { id: d.caseId } }"
                >
                  Ir al caso
                </router-link>
                <router-link
                  v-if="d.caseType == 'Rechazo'"
                  class="justify-content-center d-flex"
                  :to="{ name: 'DetalleRechazo', params: { id: d.caseId } }"
                >
                  Ir al caso
                </router-link>
              </a>
            </span>
          </a-col>

          <a-divider class="my-2" v-if="d.length > 1 || d.case == null" />
        </a-row>
      </div>
      <div v-if="!data.length"><p>No se encontraron casos asociados.</p></div>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { ref, watch } from 'vue';
import Tags from '../Tags.vue';

export default {
  name: 'CasosAsociados',
  components: {
    tags: Tags
  },
  props: {
    data: {
      type: Array
    }
  },
  setup() {
    const activeKey = ref(['0']);
    watch(activeKey, () => {});

    return {
      activeKey
    };
  }
};
</script>
