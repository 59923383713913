// * GET
export const GET_TOTAL_PAGES_TABLE = 'tables/GET_TOTAL_PAGES_TABLE';
export const GET_ITEMS_PER_PAGE_TABLE = 'tables/GET_ITEMS_PER_PAGE_TABLE';
export const GET_CURRENT_PAGE_TABLE = 'tables/GET_CURRENT_PAGE_TABLE';
export const GET_ROWS_TABLE = 'tables/GET_ROWS_TABLE';
export const GET_PAGINATION_OBJECT = 'tables/GET_PAGINATION_OBJECT';

// * MUTATIONS
export const MUTATE_TOTAL_PAGES_TABLE = 'tables/MUTATE_TABLE_TOTAL_PAGES';
export const MUTATE_ITEMS_PER_PAGE_TABLE = 'tables/MUTATE_TABLE_ITEMS_PER_PAGE';
export const MUTATE_CURRENT_PAGE_TABLE = 'tables/MUTATE_TABLE_TOTAL_ROWS';
export const MUTATE_ROWS_TABLE = 'tables/MUTATE_TABLE_ROWS';

// * ACTIONS
export const SET_TOTAL_PAGES_TABLE = 'tables/SET_TOTAL_PAGES_TABLE';
export const SET_ITEMS_PER_PAGE_TABLE = 'tables/SET_ITEMS_PER_PAGE_TABLE';
export const SET_CURRENT_PAGE_TABLE = 'tables/SET_CURRENT_PAGE_TABLE';
export const SET_ROWS_TABLE = 'tables/SET_ROWS_TABLE';
