<template>
  <a-row class="mb-4 mt-4 pb-1" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <div>
        <a-steps :current="1" class="stepper-client">
          <a-step title="Datos" />
          <a-step title="Cargas" />
          <a-step title="Formularios" />
        </a-steps>
      </div>
    </a-col>
  </a-row>
  <a-row class="mb-4 pb-1" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <a-typography-title class="text-gray-8" :level="5">Ingresa tus cargas</a-typography-title>
      <a-typography-text class="text-gray-8">Ingresa el rut de cada una de tus cargas. Sí no tienes cargas presiona
        el botón “No tengo cargas”.</a-typography-text>
    </a-col>
  </a-row>

  <a-row class="mb-4 pb-1" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <a-form ref="formRef" name="form" layout="vertical" :model="newCarga" :rules="rules">
        <a-typography-paragraph strong class="text-gray-9">
          Mis cargas
        </a-typography-paragraph>
        <div v-for="(a, index) of dataLoads" :key="index">
          <a-form-item name="a" has-feedback class="my-1">
            <a-card class="mb-3">
              <div style="display: flex; justify-content: space-between">
                <div>
                  <a-typography-text class="text-blue-6 fs-xl">
                    {{ rutHelper.formatRut(dataLoads[index].rut) }}
                  </a-typography-text>
                  <a-col :xs="24" :md="24">
                    <a-typography><b>Nombre: </b>{{ dataLoads[index].fullName }}</a-typography>
                  </a-col>
                  <a-col :xs="24" :md="24">
                    <a-typography><b>Fecha de nacimiento:</b>
                      {{
                        moment(
                          dataLoads[index].birthdayDate,
                          'YYYY-MM-DD'
                        ).format('DD-MM-YYYY')
                      }}</a-typography>
                  </a-col>
                </div>
                <div>
                  <a-typography-link class="text-red-5" type="danger" @click="removeCarga(a.id)">
                    <delete-outlined /> Eliminar
                  </a-typography-link>
                </div>
              </div>
            </a-card>
            <a-divider class="my-3" v-if="dataLoads[index + 1]"></a-divider>
          </a-form-item>
        </div>

        <a-row justify="space-between" :gutter="[16]">
          <a-col :xs="24" :md="12">
            <a-form-item has-feedback name="rut" label="Rut">
              <a-input size="large" placeholder="15.342.423-6" :maxlength="12" v-model:value="newCarga.rut"
                @keyup="formatRuts" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item has-feedback name="birthdayDate" label="Fecha de nacimiento">
              <a-date-picker placeholder="01-01-2000" size="large" format="DD-MM-YYYY"
                v-model:value="newCarga.birthdayDate" :style="{ width: '100%', height: '100%' }" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item has-feedback name="firstName" label="Primer nombre">
              <a-input size="large" placeholder="Juan" :maxlength="40" v-model:value="newCarga.firstName" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item has-feedback name="secondName" label="Segundo nombre">
              <a-input size="large" placeholder="Carlos" :maxlength="40" v-model:value="newCarga.secondName" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item has-feedback name="firstSurname" label="Primer apellido">
              <a-input size="large" placeholder="González" :maxlength="40" v-model:value="newCarga.firstSurname" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item has-feedback name="secondSurname" label="Segundo apellido">
              <a-input size="large" placeholder="Rojas" :maxlength="40" v-model:value="newCarga.secondSurname" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item name="relationship" :rules="{
              required: true,
              message: 'Ingrese relación',
              trigger: 'change',
              type: 'string'
            }" label="Relación" required>
              <a-select size="large" v-model:value="newCarga.relationship" :options="relationOptions"
                placeholder="Ingrese relación">
              </a-select>
            </a-form-item>
          </a-col>

          <a-button class="btn-client-secondary" :type="newCarga.rut ? 'primary' : 'primary'" size="large" :block="true"
            :disabled="validUserLoads" @click="addCarga()">
            <template #icon>
              <plus-circle-filled />
            </template>
            Agregar carga
          </a-button>
        </a-row>
      </a-form>
    </a-col>
  </a-row>

  <a-row justify="center">
    <a-col>
      <a-typography-link strong v-if="dataLoads == ''" @click="() =>
          $router.push({
            name: 'insuredform3',
            params: { token: $route.params.token }
          })
        " class="text-center fs-6 text-color-primary">
        No tengo cargas
      </a-typography-link>
    </a-col>
  </a-row>
  <a-row :gutter="16" type="flex" style="width: 100%" justify="center">
    <a-col :xs="10" :sm="6" :lg="4">
      <a-button class="mt-4" size="large" block @click="onPrevStep()">Volver atrás</a-button>
    </a-col>
    <a-col :xs="10" :sm="6" :lg="4">
      <a-button class="mt-4 btn-client" type="primary" size="large" block @click="onNextStep()"
        :disabled="dataLoads.length == 0">
        Siguiente
      </a-button>
    </a-col>
  </a-row>
</template>

<script>
import { PlusCircleFilled, DeleteOutlined } from '@ant-design/icons-vue';
import { ref, reactive } from 'vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import moment from 'moment';
import rutHelper from '@/utils/Ruts';
import {
  POST_USER_LOADS,
  DELETE_EMPLOYEE_LOAD,
  FETCH_USER_LOADS
} from '@/store/types';
import Ruts from '@/utils/Ruts';
import notification from '@/utils/notifications';

export default {
  name: 'InsuredForm2',
  components: {
    PlusCircleFilled,
    DeleteOutlined
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const dataLoads = ref([]);
    const check = ref(false);
    const newCarga = ref({
      rut: '',
      firstName: '',
      secondName: '',
      firstSurname: '',
      secondSurname: '',
      birthdayDate: '',
      relationship: null
    });
    const info = reactive({
      id: '',
      ticketId: '',
      token: ''
    });
    const isValidRut = ref(false);
    info.token = route.params.token;
    const decodeToken = JSON.parse(atob(info.token.split('.')[1]));
    info.id = decodeToken.employeeId;
    info.ticketId = decodeToken.ticketId;

    const formRef = ref();
    const formState = reactive({ cargas: [] });
    const formatRuts = () => {
      newCarga.value.rut = rutHelper.formatRut(newCarga.value.rut);
    };

    const checkRut = async (rule, value) => {
      const repeat =
        Object.values(dataLoads.value).some(
          (e) =>
            rutHelper.normalizeRut(e.rut) ===
            rutHelper.normalizeRut(newCarga.value.rut)
        ) ||
        Object.values(dataLoads.value).some(
          (e) =>
            rutHelper.normalizeRut(e.rut) ===
            rutHelper.normalizeRut(newCarga.value.rut)
        );
      if (!value) {
        const error_message = 'Ingrese RUT';
        isValidRut.value = false;
        return Promise.reject(error_message);
      }
      if (repeat) {
        isValidRut.value = false;
        return Promise.reject('El RUT ya existe');
      }

      if (rutHelper.validateRut(newCarga.value.rut)) {
        isValidRut.value = true;
        return Promise.resolve();
      }
      isValidRut.value = false;
      return Promise.reject('RUT no válido');
    };

    // REFACT: Manual validation (30 mins)
    const validUserLoads = computed(() => {
      return !isValidRut.value;
    });

    const getUserLoads = async () => {
      try {
        const response = await store.dispatch(FETCH_USER_LOADS, info.id);
        if (response) {
          dataLoads.value = response;
        }
      } catch (error) {
        console.log('error', error);
      }
    };
    getUserLoads();

    const onNextStep = async () => {
      try {
        store.commit('updateIncCharge', formState);
        router.push({
          name: 'insuredform3',
          params: { token: route.params.token }
        });
      } catch (error) {
        console.log(error);
      }
    };
    const onPrevStep = () => {
      router.go(-1);
    };

    const disabledbutton = (index) => {
      let d = true;
      if (formState.cargas[index].length > 0) {
        d = false;
      }
      return d;
    };

    const addCarga = async () => {
      try {
        const resp = await formRef.value.validate();
        const {
          rut,
          firstName,
          secondName,
          firstSurname,
          secondSurname,
          birthdayDate,
          relationship
        } = resp;
        const formatted_date = birthdayDate.format('YYYY-MM-DD');

        const payload = {
          employeeId: info.id,
          ticketId: info.ticketId,
          rut: Ruts.normalizeRut(rut),
          firstName,
          secondName,
          firstSurname,
          secondSurname,
          birthdayDate: formatted_date,
          relationship
        };

        if (secondName === '' || secondName === null) {
          delete payload.secondName;
        }

        if (secondSurname === '' || secondSurname === null) {
          delete payload.secondSurname;
        }

        await store.dispatch(POST_USER_LOADS, payload);
        formState.cargas.push({ ...newCarga.value });
        await getUserLoads();
        newCarga.value.rut = '';
        isValidRut.value = false;
        formRef.value.resetFields();
        notification('success', 'Correcto', 'Carga agregada correctamente');
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const removeCarga = async (dependentId) => {
      try {
        await store.dispatch(DELETE_EMPLOYEE_LOAD, {
          employeeId: info.id,
          dependentId
        });
        await getUserLoads();
        formState.cargas.splice(-1);
        notification('success', 'Correcto', 'Carga eliminada correctamente');
      } catch (error) {
        notification(
          'error',
          'Error',
          'ha ocurrido un error en eliminar la carga'
        );
      }
    };
    const rules = {
      rut: [
        {
          required: true,
          validator: checkRut,
          trigger: 'change'
        }
      ],
      firstName: [
        {
          required: true,
          message: 'Ingrese Primer nombre',
          trigger: 'change'
        }
      ],
      firstSurname: [
        {
          required: true,
          message: 'Ingrese Primer apellido',
          trigger: 'change'
        }
      ],
      birthdayDate: [
        {
          required: true,
          message: 'Ingrese fecha de nacimiento',
          trigger: 'change',
          type: 'object'
        }
      ]
    };

    const relationOptions = [
      {
        label: 'Cónyuge',
        value: 'SPOUSE'
      },
      {
        label: 'Conviviente',
        value: 'COHABITANT'
      },
      {
        label: 'Hijo(a)',
        value: 'CHILD'
      },
      {
        label: 'Padre/Madre',
        value: 'PARENT'
      },
      {
        label: 'Nieto(a)',
        value: 'GRANDCHILD'
      },
      {
        label: 'Hermano(a)',
        value: 'SIBLING'
      }
    ];

    return {
      // Variables
      checked: ref(false),
      disabledbutton,
      newCarga,
      rules,
      check,
      formRef,
      formState,
      dataLoads,
      onPrevStep,
      // Methods
      onNextStep,
      addCarga,
      removeCarga,
      validUserLoads,
      formatRuts,
      isValidRut,
      rutHelper,
      moment,
      relationOptions
    };
  }
};
</script>
