<template>
  <div>
    <a-typography-title
      :level="2"
      class="fw-regular title-underline text-gray-9"
      >Email enviado</a-typography-title
    >
    <a-typography-text class="fs-xl text-gray-8">
      Te hemos enviado un email con las instrucciones para que puedas recuperar
      tu contraseña.</a-typography-text
    >
    <br />
    <br />

    <router-link class="" to="/">Volver al inicio</router-link>
  </div>
</template>

<script>
export default {
  name: 'MailSent'
};
</script>
