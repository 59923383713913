<template>
  <a-row type="flex" justify="center">
    <div style="height: 32px" />
  </a-row>
  <br /><br /><br /><br />
  <a-row justify="center">
    <a-col>
      <CheckCircleFilled class="text-green-6 mb-3 display-3" />
    </a-col>
  </a-row>

  <a-row class="mb-2 text-center" type="flex" justify="center">
    <a-col :xs="15" :sm="16" :md="12" :lg="9">
      <a-typography-title :level="4" class="text-gray-8 ff-urbanist">Documento enviado con éxito</a-typography-title>
      <br />
      <a-typography-text class="text-gray-8">El documento fue enviado con éxito y se le dará aviso a tu ejecutivo
        para que lo revise.
      </a-typography-text>
    </a-col>
  </a-row>
  <br />
  <br />
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { GET_REAPPLICATION_DOCUMENT_STATE } from '@/store/types';
import { CheckCircleFilled } from '@ant-design/icons-vue';

export default {
  name: 'ResolicitudDocumentoListo',
  components: { CheckCircleFilled },
  setup() {
    const store = useStore();
    const router = useRouter();

    const resp = store.getters[GET_REAPPLICATION_DOCUMENT_STATE];

    if (resp == false) {
      router.push({ name: 'Missing' });
    }
  }
};
</script>
