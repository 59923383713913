import {
  getInsurances,
  insuranceCompanyById,
  insuranceGetMailbox,
  insurancePostMailbox,
  insurancePutMailbox,
  typeFormsInsuranceCompany,
  insuranceCompanyUploadFormType,
  updateInsuranceCompanyUploadFormType,
  deleteInsuranceCompanyUploadFormType,
  postInsurance,
  putInsurance,
  deleteInsurance,
  insuranceGetManual,
  insurancePostManual,
  insuranceDeleteManual,
  insurancePutManual,
  insuranceCompanyPayrollsById,
  insuranceCompanyByHoldingId,
  insuranceCompanyBySubholdingId,
  insuranceCompanyByCompanyId,
  getInsuranceCompanyFormType,
  saveInsuranceCompanyFormType
} from '../../network';

import {
  PUT_MAIL_RECEPTION_REQUEST,
  POST_MAIL_RECEPTION_REQUEST,
  SET_MAIL_RECEPTION_REQUEST,
  SET_MANUAL_INSURANCE,
  GET_MAIL_RECEPTION_REQUEST,
  FETCH_MAIL_RECEPTION_REQUEST,
  GET_INSURANCES,
  GET_COMPANY_ID,
  GET_FILEINSURANCE_COMPANY,
  GET_INSURANCE_MANUAL,
  FETCH_INSURANCES,
  FETCH_INSURANCES_COMPANY_ID,
  FETCH_FILEINSURANCE_COMPANY,
  SET_INSURANCES,
  ACTION_SET_INSURANCES,
  SET_INSURANCE_COMPANY_ID,
  SET_FILEINSURANCE_COMPANY,
  POST_FORM_TYPE,
  POST_INSURANCE_MANUAL,
  PUT_FORM_TYPE,
  DELETE_FORM_TYPE,
  POST_INSURANCECOMPANY,
  PUT_INSURANCECOMPANY,
  DELETE_INSURANCECOMPANY,
  DELETE_INSURANCE_MANUAL,
  FETCH_INSURANCE_MANUAL,
  PUT_MANUAL_INSURANCE,
  FETCH_INSURANCES_COMPANY_PAYROLL_BY_ID,
  SET_INSURANCES_COMPANY_PAYROLL_BY_ID,
  ACTION_SET_INSURANCES_COMPANY_PAYROLL_BY_ID,
  GET_INSURANCES_COMPANY_PAYROLL_BY_ID,
  FETCH_INSURANCES_COMPANY_BY_HOLDING_ID,
  FETCH_INSURANCES_COMPANY_BY_SUBHOLDING_ID,
  FETCH_INSURANCES_COMPANY_BY_COMPANY_ID,
  FETCH_INSURANCE_COMPANY_INCORPORATION_TYPE,
  PUT_INSURANCE_COMPANY_INCORPORATION_TYPE
  // -> SET_DATA_ROWS
} from './types';

const store = {};
const getDefaultState = () => ({
  insurances: [],
  dataCompany: {
    businessName: 'Consorcio',
    email: '',
    id: '',
    insuranceExecutive: {
      email: '',
      name: '',
      phone: ''
    },
    rut: ''
  },
  mailBoxInsurance: [],
  manualInsurance: [],
  formats: []
});

store.state = getDefaultState();

store.getters = {
  [GET_INSURANCES](state) {
    return state.insurances.map((a) => {
      return {
        key: a.id,
        id: a.id,
        rut: a.rut,
        name: a.businessName,
        typeNotification: '',
        ejecutivo: a.principalInsuranceExecutive
      };
    });
  },
  [GET_COMPANY_ID](state) {
    return state.dataCompany;
  },
  [GET_MAIL_RECEPTION_REQUEST](state) {
    return state.mailBoxInsurance.map((obj) => {
      return {
        key: obj.id,
        type: obj.type,
        class: obj.class,
        emails:
          obj.mailboxInsurances != null ? obj.mailboxInsurances.emails : [],
        mailbox_type:
          obj.mailboxInsurances != null
            ? obj.mailboxInsurances.mailboxInsuranceTypeId
            : obj.id
      };
    });
  },
  [GET_FILEINSURANCE_COMPANY](state) {
    return state.files_insurances;
  },
  [GET_INSURANCE_MANUAL](state) {
    return state.manualInsurance;
  },
  [GET_INSURANCES_COMPANY_PAYROLL_BY_ID](state) {
    return state.formats;
  }
};

store.mutations = {
  [SET_INSURANCES](state, payload) {
    state.insurances = payload;
  },
  [SET_INSURANCE_COMPANY_ID](state, payload) {
    const principal_executive = payload.principalInsuranceExecutive;

    state.dataCompany = {
      businessName: payload.businessName,
      email: payload.email,
      id: payload.id,
      insuranceExecutive: {
        email: principal_executive != null ? principal_executive.email : '',
        name: principal_executive != null ? principal_executive.name : '',
        phone: principal_executive != null ? principal_executive.phone : ''
      },
      rut: payload.rut
    };
  },
  [SET_MAIL_RECEPTION_REQUEST](state, payload) {
    state.mailBoxInsurance = payload;
  },
  [SET_FILEINSURANCE_COMPANY](state, payload) {
    state.files_insurances = payload;
  },
  [SET_MANUAL_INSURANCE](state, payload) {
    state.manualInsurance = payload.fileInsuranceCompanyHandbooks.map((obj) => {
      return {
        id: obj.id,
        name: obj.name.split('.')[0],
        file_name: obj.name,
        url: obj.url
      };
    });
  },
  [SET_INSURANCES_COMPANY_PAYROLL_BY_ID](state, payload) {
    state.formats = payload;
  }
};

store.actions = {
  [ACTION_SET_INSURANCES]({ commit }, payload) {
    commit(SET_INSURANCES, payload);
  },
  [FETCH_INSURANCES]({ commit }) {
    return new Promise((resolve, reject) => {
      getInsurances()
        .then((resp) => {
          commit(SET_INSURANCES, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          commit(SET_INSURANCES, []);
          reject(error);
        });
    });
  },
  [FETCH_INSURANCES_COMPANY_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      insuranceCompanyById(payload)
        .then((resp) => {
          commit(SET_INSURANCE_COMPANY_ID, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          commit(SET_INSURANCE_COMPANY_ID, []);
          reject(error);
        });
    });
  },
  [FETCH_MAIL_RECEPTION_REQUEST]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      insuranceGetMailbox(payload).then(
        (resp) => {
          commit(SET_MAIL_RECEPTION_REQUEST, resp.data);
          resolve(resp);
        },
        (err) => {
          commit(SET_MAIL_RECEPTION_REQUEST, []);
          reject(err);
        }
      );
    });
  },
  [FETCH_FILEINSURANCE_COMPANY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      typeFormsInsuranceCompany(payload).then(
        (resp) => {
          commit(SET_FILEINSURANCE_COMPANY, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [POST_MAIL_RECEPTION_REQUEST](_, { payload, id }) {
    return new Promise((resolve, reject) => {
      insurancePostMailbox(payload, id).then(
        (resp) => {
          resolve(resp);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  [PUT_MAIL_RECEPTION_REQUEST](
    { dispatch },
    { payload, id_company, id_mailbox }
  ) {
    return new Promise((resolve, reject) => {
      insurancePutMailbox(payload, id_company, id_mailbox).then(
        (resp) => {
          dispatch(FETCH_MAIL_RECEPTION_REQUEST, id_company);
          resolve(resp);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  [POST_FORM_TYPE](_, payload) {
    return new Promise((resolve, reject) => {
      insuranceCompanyUploadFormType(payload).then(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [PUT_FORM_TYPE](_, { payload, id }) {
    return new Promise((resolve, reject) => {
      updateInsuranceCompanyUploadFormType(payload, id).then(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [DELETE_FORM_TYPE](_, payload) {
    return new Promise((resolve, reject) => {
      deleteInsuranceCompanyUploadFormType(payload).then(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [POST_INSURANCECOMPANY](_, { payload }) {
    let error = '';
    const response = await postInsurance(payload).catch((err) => (error = err));
    if (response && (response.status === 200 || response.status === 201)) {
      return response;
    }
    throw error;
  },
  async [PUT_INSURANCECOMPANY](_, { insuranceCompanyId, payload }) {
    let error = '';
    const response = await putInsurance(insuranceCompanyId, payload).catch(
      (err) => (error = err)
    );
    if (response && (response.status === 200 || response.status === 201)) {
      return response;
    }
    throw error;
  },
  [DELETE_INSURANCECOMPANY](_, payload) {
    return new Promise((resolve, reject) => {
      deleteInsurance(payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_INSURANCE_MANUAL]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      insuranceGetManual(payload)
        .then((resp) => {
          commit(SET_MANUAL_INSURANCE, resp.data);
          resolve(resp.status);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [POST_INSURANCE_MANUAL]({ dispatch }, { id, file }) {
    return new Promise((resolve, reject) => {
      insurancePostManual(id, file)
        .then((resp) => {
          dispatch(FETCH_INSURANCE_MANUAL, id);
          resolve(resp.status);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_INSURANCE_MANUAL]({ dispatch }, { id_insurance, id_manual }) {
    return new Promise((resolve, reject) => {
      insuranceDeleteManual(id_insurance, id_manual)
        .then((resp) => {
          dispatch(FETCH_INSURANCE_MANUAL, id_insurance);
          resolve(resp.status);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_MANUAL_INSURANCE]({ dispatch }, { id_insurance, id_manual, payload }) {
    return new Promise((resolve, reject) => {
      insurancePutManual(id_insurance, id_manual, payload)
        .then((resp) => {
          dispatch(FETCH_INSURANCE_MANUAL, id_insurance);
          resolve(resp.status);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_INSURANCES_COMPANY_PAYROLL_BY_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      insuranceCompanyPayrollsById(payload)
        .then((resp) => {
          commit(SET_INSURANCES_COMPANY_PAYROLL_BY_ID, resp.data);
          resolve(resp.status);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_INSURANCES_COMPANY_BY_HOLDING_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      insuranceCompanyByHoldingId(payload)
        .then((resp) => {
          commit(SET_INSURANCES, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_INSURANCES_COMPANY_BY_SUBHOLDING_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      insuranceCompanyBySubholdingId(payload)
        .then((resp) => {
          commit(SET_INSURANCES, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_INSURANCES_COMPANY_BY_COMPANY_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      insuranceCompanyByCompanyId(payload)
        .then((resp) => {
          commit(SET_INSURANCES, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ACTION_SET_INSURANCES_COMPANY_PAYROLL_BY_ID]({ commit }, payload) {
    commit(SET_INSURANCES_COMPANY_PAYROLL_BY_ID, payload);
  },
  [FETCH_INSURANCE_COMPANY_INCORPORATION_TYPE](_, payload) {
    return new Promise((resolve, reject) => {
      getInsuranceCompanyFormType(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_INSURANCE_COMPANY_INCORPORATION_TYPE](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      saveInsuranceCompanyFormType(id, payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default store;
