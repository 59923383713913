import { notification } from 'ant-design-vue';

const openNotificationWithIcon = (type, title, message, duration = 5) => {
  let final_duration = duration;
  if (type === 'error') {
    final_duration = 120;
  }

  notification[type]({
    message: title,
    description: message,
    duration: final_duration
  });
};

export default openNotificationWithIcon;
