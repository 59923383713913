import { ticketService } from '@/network';

import {
  FETCH_TICKET,
  SET_TICKET_LOADING,
  SET_TICKET,
  GET_TICKET
} from '@/store/types';

const store = {};

const getDefaultState = () => ({
  ticket: {
    data: {},
    loading: false
  }
});

store.state = getDefaultState();

store.getters = {
  [GET_TICKET]: (state) => {
    return state.ticket;
  }
};

store.mutations = {
  [SET_TICKET_LOADING](state, loading) {
    state.ticket.loading = loading;
  },
  [SET_TICKET](state, ticket) {
    state.ticket.data = ticket;
  }
};

store.actions = {
  async [FETCH_TICKET]({ commit }, id) {
    commit(SET_TICKET_LOADING, true);
    commit(SET_TICKET, {});
    try {
      const response = await ticketService.getTicket(id);
      commit(SET_TICKET, response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching ticket', error);
      console.error('Params:', id);
      throw error;
    } finally {
      commit(SET_TICKET_LOADING, false);
    }
  }
};

export default store;
