export const GET_ALL_DATA_DASHBOARD = 'dashboardAdmin/GET_ALL_DATA_DASHBOARD';
export const GET_PERIOD_DATE_FILTER_DASHBOARD =
  'dashboardAdmin/GET_PERIOD_DATE_FILTER_DASHBOARD';
export const GET_DATE_FILTER_DASHBOARD =
  'dashboardAdmin/GET_DATE_FILTER_DASHBOARD';

export const SET_ALL_DATA_DASHBOARD = 'dashboardAdmin/SET_ALL_DATA_DASHBOARD';
export const SET_PERIOD_DATE_FILTER_DASHBOARD =
  'dashboardAdmin/SET_PERIOD_DATE_FILTER_DASHBOARD';
export const SET_DATE_FILTER_DASHBOARD =
  'dashboardAdmin/SET_DATE_FILTER_DASHBOARD';

export const FETCH_ALL_DATA_DASHBOARD =
  'dashboardAdmin/FETCH_ALL_DATA_DASHBOARD';
export const SAVE_PERIOD_DATE_FILTER_DASHBOARD =
  'dashboardAdmin/SAVE_PERIOD_DATE_FILTER_DASHBOARD';
export const SAVE_DATE_FILTER_DASHBOARD =
  'dashboardAdmin/SAVE_DATE_FILTER_DASHBOARD';
