<template>
  <div v-if="$route.name == 'cobranzaFacturacionTabs'">
    <div class="example" v-if="loadingContainerPoliza">
      <a-skeleton active />
    </div>
    <div v-else class="mt-3">
      <a-radio-group class="mb-3" :value="policy_type_selection" @change="onChangeTabs" :options="policy_type">
      </a-radio-group>

      <div v-for="(a, index) in dataAllPolicy" :key="index">
        <a-descriptions bordered>
          <a-descriptions-item label="Número póliza" :span="3">
            {{ a.policy?.numberPolicy }}
          </a-descriptions-item>
          <a-descriptions-item label="Tipo de cobranza" :span="3">
            {{ a.collectionType }}
          </a-descriptions-item>
          <a-descriptions-item label="Modalidad de cobranza" :span="3">
            {{ a.configurationType }}
          </a-descriptions-item>
          <a-descriptions-item label="Fecha de corte" :span="3">
            Día {{ a.cutoffDay }}
          </a-descriptions-item>
          <a-descriptions-item label="Pago de facturas" :span="3">
            {{ a.invoicePayment.days }} Días
          </a-descriptions-item>
          <a-descriptions-item label="Corte de beneficios" :span="3">
            {{ a.invoiceBenefictCutoff.days }} Días
          </a-descriptions-item>
        </a-descriptions>
        <span style="justify-content: flex-end; list-style: none; display: flex" class="mt-2 mr-2 mb-2">
          <p class="edit-policy-text" @click="openUpdate(a)">Editar</p>
        </span>
      </div>
    </div>

    <a-modal v-model:visible="visible" :centered="true" title="Editar configuración">
      <a-form name="form" layout="vertical" ref="formRef" :model="formState">
        <div>
          <a-form-item name="SelectTypeCobranza" ref="SelectTypeCobranza" label="Tipo de cobranza">
            <a-select v-model:value="formState.SelectTypeCobranza" placeholder="Selecciona tipo de cobranza"
              :options="Cobranza"></a-select>
          </a-form-item>
          <a-form-item name="typeSelected" ref="typeSelected" label="Modalidad de cobranza">
            <a-select ref="select" :options="optionSelected" v-model:value="formState.typeSelected" />
          </a-form-item>
          <a-form-item name="cutOfDate" label="Fecha de corte">
            <a-input v-model:value="formState.cutDate" placeholder="0" type="number" @change="handleFechaCorte" />
          </a-form-item>
          <a-form-item name="invoicePayment" label="Pago de factura">
            <a-select v-model:value="formState.invoicePayment" placeholder="Selecciona pago factura"
              :options="dataInvoicePayment"></a-select>
          </a-form-item>
          <a-form-item name="benefitCut" ref="benefitCut" label="Corte de beneficio">
            <a-select v-model:value="formState.benefitCut" placeholder="Selecciona pago factura"
              :options="dataCutOffsPolicy"></a-select>
          </a-form-item>
        </div>
      </a-form>
      <template #footer>
        <a-button key="back" @click="backModal" :disabled="loading">Cancelar</a-button>

        <a-button key="submit" type="primary" @click="handleOk" :loading="loading">
          Guardar
        </a-button>
      </template>
    </a-modal>
  </div>
  <router-view :key="$route.path"></router-view>
</template>

<script>
import { computed, ref, reactive, watchEffect, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  FETCH_ALL_CONFIG_POLICY,
  GET_ALL_CONFIG,
  GET_INVOICE_PAYMENT_POLICY,
  GET_CUT_OFFS_POLICY,
  PUT_CONFIG_POLIZA,
  FETCH_INVOICE_PAYMENT_POLICY,
  FETCH_CUT_OFFS_POLICY
  // FETCH_COMPANY_POLICIES
} from '@/store/types';
import delay from '@/utils/delay';
import openNotificationWithIcon from '@/utils/notifications';

export default {
  name: 'ListaCobranza',
  components: {},
  setup() {
    const loadingContainerPoliza = ref(false);
    const store = useStore();
    const load_data = ref(false);
    const route = useRoute();
    const dataAllPolicy = computed(() => store.getters[GET_ALL_CONFIG]);
    const visible = ref(false);
    const loading = ref(false);
    const router = useRouter();
    const backModal = () => {
      visible.value = false;
    };
    const formState = reactive({
      numberPolicy: '',
      startDate: null,
      finishDate: null,
      typeSelected: '',
      SelectTypeCobranza: '',
      cutDate: '',
      invoicePayment: 'seleccione plazo',
      benefitCut: 'Seleccione atraso pago'
    });
    const dataInvoicePayment = computed(
      () => store.getters[GET_INVOICE_PAYMENT_POLICY]
    );
    const dataCutOffsPolicy = computed(
      () => store.getters[GET_CUT_OFFS_POLICY]
    );
    // selecion de cobranza y factura
    const optionSelected = [
      {
        label: 'Vencida',
        value: 'expired'
      },
      {
        label: 'Anticipada',
        value: 'anticipated'
      }
    ];
    const Cobranza = [
      {
        label: 'Cobranza',
        value: 'collection'
      },
      {
        label: 'Pre-cobranza',
        value: 'precollection'
      }
    ];

    const getPolizasConfig = async () => {
      loadingContainerPoliza.value = true;
      try {
        await store.dispatch(FETCH_ALL_CONFIG_POLICY, {
          company_id: route.params.id,
          params: `?status=${policy_type_selection.value}`
        });
      } catch (error) {
        console.log('error', error);
      }
      loadingContainerPoliza.value = false;
    };

    watchEffect(async () => {
      load_data.value = true;
      try {
        await store.dispatch(FETCH_INVOICE_PAYMENT_POLICY);
        await store.dispatch(FETCH_CUT_OFFS_POLICY);
      } catch (error) {
        console.log('error', error);
      }
      // store.dispatch(FETCH_COMPANY_POLICIES,`?companyId=${route.params.id}&status=Vigente`);

      load_data.value = false;
    });
    const configId = ref('');
    const dataPayload = ref({});
    const openUpdate = (value) => {
      visible.value = true;

      // rellenar el modal con los datos
      formState.numberPolicy = value.policy?.numberPolicy;
      formState.typeSelected = value.configurationType;
      formState.SelectTypeCobranza = value.collectionType;
      // formState.startDate=value.collectionInsuranceStart;
      // formState.finishDate=value.collectionInsuranceEnd;
      formState.cutDate = value.cutoffDay;
      formState.invoicePayment = `${value.invoicePayment.days} Días`;
      formState.benefitCut = `${value.invoiceBenefictCutoff.days} Días`;
      // formState.collectionType = value.collectionType;

      configId.value = value.id;
    };
    const handleFechaCorte = () => {
      if (formState.cutDate > 0 && formState.cutDate <= 31) {
        return formState.cutDate;
      }
      return (formState.cutDate = '');
    };
    const handleDateStart = () => {
      if (formState.startDate > 0 && formState.startDate <= 31) {
        return formState.startDate;
      }
      return (formState.startDate = '');
    };
    const handleDateFinish = () => {
      if (formState.finishDate > 0 && formState.finishDate <= 31) {
        return formState.finishDate;
      }
      return (formState.finishDate = '');
    };
    const handleOk = async () => {
      const data = {
        policies: formState?.numberPolicy,
        configurationType: formState.typeSelected,
        collectionInsuranceStart: formState.startDate,
        collectionInsuranceEnd: formState.finishDate,
        collectionType: formState.SelectTypeCobranza,
        cutoffDay: formState.cutDate,
        invoicePaymentId: formState.invoicePayment,
        invoiceBenefictCutoffId: formState.benefitCut
      };
      if (formState.SelectTypeCobranza == 'Cobranza') {
        const valueDatatypeCobranza = Cobranza.filter(
          (f) => f.label == data.collectionType
        );
        data.collectionType = valueDatatypeCobranza
          .map((e) => e.value)
          .toString();
      }

      if (formState.SelectTypeCobranza == 'Pre-cobranza') {
        const valueDatatypeCobranza = Cobranza.filter(
          (f) => f.label == data.collectionType
        );
        data.collectionType = valueDatatypeCobranza
          .map((e) => e.value)
          .toString();
      }

      if (formState.typeSelected == 'Vencida') {
        const valueDataTypeConfiguration = optionSelected.filter(
          (f) => f.label == data.configurationType
        );
        data.configurationType = valueDataTypeConfiguration
          .map((e) => e.value)
          .toString();
      }
      if (formState.typeSelected == 'Anticipada') {
        const valueDataTypeConfiguration = optionSelected.filter(
          (f) => f.label == data.configurationType
        );
        data.configurationType = valueDataTypeConfiguration
          .map((e) => e.value)
          .toString();
      }
      const d = dataInvoicePayment.value.filter(
        (f) => f.label == formState.invoicePayment
      );
      switch (formState.invoicePayment) {
        case d.map((e) => e.label).toString():
          data.invoicePaymentId = d.map((e) => e.value).toString();
          break;
      }
      const b = dataCutOffsPolicy.value.filter(
        (f) => f.label == formState.benefitCut
      );
      switch (formState.benefitCut) {
        case b.map((e) => e.label).toString():
          data.invoiceBenefictCutoffId = b.map((e) => e.value).toString();
          break;
      }

      try {
        loading.value = true;
        await store.dispatch(PUT_CONFIG_POLIZA, {
          companyId: route.params.id,
          configId: configId.value,
          ...data
        });
        getPolizasConfig();
        await delay(1500);
        // await getPolicies();
        loading.value = false;
        openNotificationWithIcon(
          'success',
          'Correcto',
          'Configuración modificada con éxito'
        );
        getPolizasConfig();
        visible.value = false;
      } catch (error) {
        loading.value = false;
        openNotificationWithIcon(
          'error',
          'Error',
          `No se ha podido modificar la Configuración. ${error.response.data.message}`
        );
      }
    };

    // Tabs de Vigentes y no Vigentes
    const policy_type_selection = ref(route.query.status);
    const policy_type = [
      {
        label: 'Vigentes',
        value: 'Vigente'
      },
      {
        label: 'No vigentes',
        value: 'No vigente'
      }
    ];
    const onChangeTabs = (value) => {
      console.log('CobranzaFacturacion.vue onChangeTabs');

      const tab_value = value.target.value;
      policy_type_selection.value = tab_value;
      router.push({
        name: 'cobranzaFacturacionTabs',
        query: { companyId: route.params.id, status: tab_value }
      });
      getPolizasConfig();
    };

    onMounted(() => {
      getPolizasConfig();
    });

    return {
      loading,
      loadingContainerPoliza,
      handleOk,
      dataPayload,
      configId,
      Cobranza,
      openUpdate,
      handleDateStart,
      handleDateFinish,
      handleFechaCorte,
      dataCutOffsPolicy,
      dataInvoicePayment,
      optionSelected,
      formState,
      visible,
      backModal,
      load_data,
      dataAllPolicy,
      policy_type_selection,
      policy_type,
      onChangeTabs
    };
  }
};
</script>

<style scoped>
.edit-policy-text {
  color: blue;
  cursor: pointer;
}

.delete-policy-text {
  color: red;
  cursor: pointer;
}
</style>
