<template>
  <div>
    <request-detail
      :breadcrumRoutes="breadcrumRoutes"
      header-title="Nota de débito"
      :request-id="request_id"
    />
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import RequestDetailTemplate from '@/components/collection and billing/reprocesses and requests/requests/RequestDetailTemplate.vue';

export default {
  name: 'CreditNoteDetail',
  components: {
    'request-detail': RequestDetailTemplate
  },
  setup() {
    const breadcrumRoutes = [
      {
        breadcrumbName: 'Solicitud / Nota de Débito'
      }
    ];
    const route = useRoute();
    const request_id = route.params.id;

    return {
      breadcrumRoutes,
      request_id
    };
  }
};
</script>

<style></style>
