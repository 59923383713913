import {
  holdingfetch,
  createSubHolding,
  editSubHolding,
  Company,
  deleteSubholding,
  subholdingFetch,
  subholdingFetchTableData
} from '../../../network';
import {
  FETCH_SUBHOLDING,
  FETCH_SUBHOLDINGS,
  SET_SUBHOLDING,
  GET_SUBHOLDING,
  GET_SUBHOLDINGS,
  SELECT_SUBHOLDING,
  SET_SELECTED_SUBHOLDING,
  ACTION_SELECTED_SUBHOLDING,
  GET_SELECTEDSUBHOLDING,
  GET_RUT_HOLDING,
  POST_SUBHOLDING,
  SELECT_FILTER,
  SET_POST_SUBHOLDING,
  EDIT_SUBHOLDING,
  SET_EDIT_SUBHOLDING,
  FILTER_ALL,
  SUBHOLDINGS,
  SELECTS,
  SET_SELECT,
  SELECTED_SUBHOLDING,
  SET_SELECTEDSUBHOLDING,
  GET_COMP,
  SET_COMP,
  FETCH_COMP,
  ALL_COMPANIES,
  SET_SELECTED_COMPANY,
  ACTION_SELECTED_COMPANY,
  SELECTED_COMPANY,
  DELETE_SUBHOLDING,
  SET_SUBHOLDINGS,
  FETCH_SUBHOLDINGS_DATA_TABLE,
  MUTATE_SUBHOLDINGS_DATA_TABLE,
  GET_SUBHOLDINGS_DATA_TABLE
} from '../../types';

const store = {};
const getDefaultState = () => ({
  subholding: [],
  subholding_general_list: [],
  holding: [],
  selectSubHolding: {
    id: 'Seleccionar holding',
    name: ''
  },
  selected: [],
  selectedSubHolding: [],
  company: [],
  selectedCompany: [],
  subholding_data_table: []
});

store.state = getDefaultState();

store.getters = {
  [GET_SUBHOLDINGS]: (state) => state.subholding_general_list,
  // es al momento de seleccionar me trae el id
  [GET_SELECTEDSUBHOLDING]: (state) => state.selectSubHolding,
  selects: (state) => state.selected,
  // obtengo la data del subholding y la modifico para obtener la data que quiero y
  // la ordeno
  [GET_SUBHOLDING]: (state) =>
    state.holding
      .map((e) => ({
        ...{
          d: e.subHolding.map((f) => ({
            ...f,
            holding: e.businessName,
            rut_holding: e.rut
          }))
        }
      }))
      .reduce((acc, item) => {
        return [...acc, ...item.d];
      }, [])
      .map((sh) => ({
        key: sh.id,
        id: sh.id,
        holding: sh.holding,
        rut_holding: sh.rut_holding,
        subholding: sh.name
      }))
      .sort((a, b) => {
        return (
          a.holding.localeCompare(b.holding) ||
          a.subholding.localeCompare(b.subholding)
        );
        // if (a.subholding < b.subholding) {
        //   return -1;
        // }

        // return 0;
      }),

  [SELECT_FILTER]: (state) => state.selected,
  [SELECTED_COMPANY]: (state) => state.selectedCompany,
  [GET_COMP]: (state) =>
    state.company.map((e) => ({
      value: e.id,
      label: e.businessName,
      subholdingid: e?.subHolding ? e?.subHolding?.id : null,
      idholding: e?.subHolding ? e.subHolding?.holding?.id : e?.holding?.id
    })),
  // click select subholding
  [SELECTED_SUBHOLDING]: (state) => state.selectedSubHolding,

  // filtro total de holding
  [FILTER_ALL]: (state) =>
    state.holding.reduce((acc, item) => {
      return [
        ...acc,
        {
          value: item.id,
          label: item.businessName,
          subholding: item.subHolding.map((e) => ({
            value: e.id,
            label: e.name,
            idholding: e.holdingId
          }))
        }
      ];
    }, []),
  [SUBHOLDINGS]: (state, getters) =>
    getters[FILTER_ALL].reduce((acc, item) => {
      return [...acc, ...item.subholding];
    }, []).filter((f) => getters[SELECT_FILTER].some((e) => f.idholding == e)),
  [ALL_COMPANIES]: (state, getters) =>
    getters[GET_COMP].reduce((acc, item) => {
      return [...acc, item];
    }, []).filter((f) =>
      getters[SELECT_FILTER].length == 0 &&
      getters[SELECTED_SUBHOLDING].length == 0
        ? f
        : 's' && getters[SELECTED_SUBHOLDING].length > 0
          ? getters[SELECTED_SUBHOLDING].some((g) => f.subholdingid == g)
          : '' || getters[SELECT_FILTER].length > 0
            ? getters[SELECT_FILTER].some((h) => f.idholding == h)
            : 's'
    ),

  // llamo a todos los holding para el seleect del front
  [SELECT_SUBHOLDING]: (state) =>
    state.subholding.map((e) => ({
      rut: e,
      value: e.id,
      label: e.businessName
    })),

  // obtengo el id del holding y saco la data de acuerdo a cual seleccione
  [GET_RUT_HOLDING]: (state, getters) =>
    state.subholding
      .map((e) => ({
        holdingId: e.id,
        name: e.holding
      }))
      .filter((f) => f.holdingId === getters[GET_SELECTEDSUBHOLDING].holdingId),
  [GET_SUBHOLDINGS_DATA_TABLE]: (state) => state.subholding_data_table
};

store.mutations = {
  [SET_SUBHOLDING](state, payload) {
    state.holding = payload;
  },
  [SET_SUBHOLDINGS](state, payload) {
    state.subholding_general_list = payload;
  },
  [SET_SELECTED_SUBHOLDING](state, payload) {
    state.selectSubHolding = payload;
  },
  [SET_POST_SUBHOLDING](state, payload) {
    state.holdingId = payload.holdingId;
    state.names = payload.names;
  },
  [SET_EDIT_SUBHOLDING](state, payload) {
    state.subholding = payload.holdingId;
    state.name = payload.name;
  },
  [SET_SELECT](state, payload) {
    state.selected = payload;
  },
  [SET_SELECTEDSUBHOLDING](state, payload) {
    state.selectedSubHolding = payload;
  },
  [SET_COMP](state, payload) {
    state.company = payload;
  },
  [SET_SELECTED_COMPANY](state, payload) {
    state.selectedCompany = payload;
  },
  [MUTATE_SUBHOLDINGS_DATA_TABLE](state, payload) {
    state.subholding_data_table = payload;
  }
};

store.actions = {
  async [FETCH_SUBHOLDING]({ commit }) {
    return new Promise((resolve, reject) => {
      holdingfetch()
        .then((resp) => {
          commit(SET_SUBHOLDING, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_SUBHOLDINGS]({ commit }) {
    return new Promise((resolve, reject) => {
      subholdingFetch()
        .then((resp) => {
          commit(SET_SUBHOLDINGS, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_SUBHOLDING]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      createSubHolding(payload)
        .then((resp) => {
          resolve(resp);
          commit(SET_POST_SUBHOLDING, resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [EDIT_SUBHOLDING]({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      editSubHolding(id, payload)
        .then((resp) => {
          commit(SET_EDIT_SUBHOLDING, resp.data);
          resolve(resp.status);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [FETCH_COMP]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      Company().then(
        (resp) => {
          commit(SET_COMP, resp.data);
          if (getters[SELECTED_COMPANY].length > 0) {
            commit(SET_SELECTED_COMPANY, getters[SELECTED_COMPANY]);
          } else {
            commit(
              SET_SELECTED_COMPANY,
              resp.data.map((e) => e.id)
            );
          }
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },

  [SELECTS]({ commit, state, getters }, payload) {
    commit(SET_SELECT, payload);
    commit(
      SET_SELECTEDSUBHOLDING,
      state.selectedSubHolding.filter((f) =>
        getters[SUBHOLDINGS].some((h) => h.value == f)
      )
    );
    if (getters[SELECT_FILTER].length > 0) {
      commit(
        SET_SELECTED_COMPANY,
        state.selectedCompany.filter((f) =>
          getters[ALL_COMPANIES].some((h) => h.value == f)
        )
      );
    } else {
      commit(SET_SELECTED_COMPANY, []);
    }
  },
  [ACTION_SELECTED_SUBHOLDING]({ commit, state, getters }, payload) {
    commit(SET_SELECTEDSUBHOLDING, payload);
    if (getters[SELECTED_SUBHOLDING].length > 0) {
      commit(
        SET_SELECTED_COMPANY,
        state.selectedCompany.filter((f) =>
          getters[ALL_COMPANIES].some((h) => h.value == f)
        )
      );
    } else {
      commit(SET_SELECTED_COMPANY, []);
    }
  },
  [ACTION_SELECTED_COMPANY]({ commit }, payload) {
    // commit(SET_COMP,getters[GET_COMP].splice(1));
    commit(SET_SELECTED_COMPANY, payload);
  },
  async [DELETE_SUBHOLDING](_, payload) {
    let error = '';
    const response = await deleteSubholding(payload).catch(
      (err) => (error = err)
    );
    if (response && response.status === 200) {
      return response.data;
    }
    throw error;
  },
  [FETCH_SUBHOLDINGS_DATA_TABLE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      subholdingFetchTableData(payload)
        .then((resp) => {
          commit(MUTATE_SUBHOLDINGS_DATA_TABLE, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default store;
