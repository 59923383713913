<template>
  <div>
    <page-header :breadcrumRoutes="[
      {
        breadcrumbName: 'Cobranza y facturación',
        path: '/cobranza-facturacion'
      }
    ]" title="Formulario de inicio de cobranza">
      <template #tags>
        <a-tag color="purple">
          {{ getMonthNameByValue(dataFetchInfo?.period?.split('-')[1])?.toUpperCase() }}
        </a-tag>
        <a-tag color="processing">ID: {{ form_id }} </a-tag>
      </template>

      <template #buttons>
        <a-row :gutter="10">
          <a-col>
            <a-button :loading="validateProcessing || loadingHumanSend" @click="cancelation_modal_visible = true" v-if="
              (form_current_state.state != 'CANCELLED' &&
                form_current_state.state != 'LOADED') ||
              (form_current_state.state == 'LOADED' &&
                validateStateCollectionHumanSend)
            ">Cancelar</a-button>
          </a-col>
          <a-col>
            <a-button :loading="validateProcessing" v-if="form_current_state.state == 'LOADED' && showValidateButton"
              @click="openModalValid" type="primary">{{ !validateProcessing ? 'Validar' : 'Procesando...' }}</a-button>
          </a-col>
          <a-col>
            <a-button :loading="loadingHumanSend" v-if="
              form_current_state.state == 'LOADED' &&
              showSendHR &&
              validateStateCollectionHumanSend
            " type="primary" @click="OpenModalRRHH">Enviar a RRHH</a-button>
          </a-col>
        </a-row>
      </template>
    </page-header>

    <stepper-cobranza :stepper-data="stepper_props" />

    <collection-list class="mt-3" v-if="form_current_state.state === 'LOADED'" :collection-form-id="form_id" />

    <a-card>
      <a-typography-title :level="3"> Datos formulario </a-typography-title>

      <a-row class="mt-3" :gutter="16">
        <a-col :span="12">
          <data-info :data="ticket_data" />
        </a-col>
        <a-col :span="12">
          <comments title="Comentarios formulario" :data="comments" @commentCreation="getNewCommment"
            :idToTicket="$route.params.id" />
        </a-col>
      </a-row>
    </a-card>

    <payroll-procesing v-if="form_current_state.state === 'CREATED'" :collection-form-id="form_id" class="mt-3" />

    <detail-collection-billing v-if="
      form_current_state.state === 'PROCCESING' ||
      form_current_state.state === 'LOADED'
    " :current-collection-form-state="form_current_state.state" :validateApproved="validateApproved"
      :collection-start-form-state="form_current_state.state" />

    <collection-cancel-modal v-model="cancelation_modal_visible" :collection-form-id="form_id" />

    <collection-valid-modal :dataInfo="dataFetchInfo" v-model="modalValid" />
    <collection-cancel-modal v-model="modalCancel" />
    <collection-modal-send-rrhh v-model="modalSendRRHH" :dataSend="dataCollection" :dataInfo="dataFetchInfo" />
  </div>
</template>

<script>
import { onMounted, reactive, ref, watchEffect, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import PayrollProcesing from '@/components/collection and billing/payroll/PayrollProcesing.vue';
import DetailCollectionBilling from '@/components/collection and billing/detail/DetailCollectionAndBilling.vue';
import parseDate from '@/utils/dateFormatter';
import notification from '@/utils/notifications';
import EmitBus from '@/utils/events';
import {
  FETCH_COLLECTION_FORM_BY_ID,
  FETCH_COLLECTION_GROUP_COMMENTS,
  FETCH_USER_BY_ID,
  FETCH_COLLECTION_DATA_SEND_RRHH,
  GET_COLLECTION_FORM,
  GET_COLLECTION_GROUP_COLLECTIONS,
  GET_COLLECTION_DATA_RRHH
} from '@/store/types';
import getMonthNameByValue from '@/utils/monthByNumber';
import PageHeader from '@/components/PageHeader.vue';
import DatosTicket from '@/components/Tickets/TicketData.vue';
import CommentsTest from '@/components/CommentsTest.vue';
import CollectionCancelModal from '@/components/collection and billing/collection form/CollectionCancelModal.vue';
import CollectionListTable from '@/components/collection and billing/collection form/CollectionListTable.vue';
import CollectionModalSendRRHH from '@/components/collection and billing/collection form/CollectionModalSendRRHH.vue';
import stepperCobranza from '@/components/CollectionBillingStepper.vue';

// import CollectionChargeSummary from '@/components/collection and billing/collection form/CollectionChargeSummary.vue';
import CollectionValidModal from '@/components/collection and billing/collection form/CollectionValidModal.vue';
// import CollectionModalRRHH from '@/components/collection and billing/collection form/CollectionModalSendRRHH.vue';

export default {
  name: 'BillingDetails',
  components: {
    'data-info': DatosTicket,
    'page-header': PageHeader,
    comments: CommentsTest,
    'payroll-procesing': PayrollProcesing,
    'detail-collection-billing': DetailCollectionBilling,
    'collection-cancel-modal': CollectionCancelModal,
    'collection-list': CollectionListTable,
    'collection-modal-send-rrhh': CollectionModalSendRRHH,
    // 'collection-charge-summary':CollectionChargeSummary,
    'collection-valid-modal': CollectionValidModal,
    'stepper-cobranza': stepperCobranza
    // 'collection-modal-send-rrhh':CollectionModalRRHH
  },
  setup() {
    // * Iniciacion de variables
    const store = useStore();
    const loadSendToRRHH = ref(false);
    const route = useRoute();
    const router = useRouter();
    const form_id = parseInt(route.params.id);
    const loadingHumanSend = ref(false);

    // const brokerId=computed(()=>store.getters[GET_BROKER_ID]);
    // * Traer la data del formulario de cobranza desde vuex
    const dataFetchInfo = ref([]);
    const loadingValidate = ref(false);

    // * Estados del ticket
    const form_current_state = ref('');
    const stepper_props = reactive({
      steps: [],
      state: 'process',
      current: 0
    });

    // * Modal de validacion
    const keyModal = ref(1);
    const modalType = ref({ id: 1, name: 'Validar' });
    const buttonModalType = reactive([
      {
        id: 1,
        name: 'Validar'
      },
      {
        id: 2,
        name: 'Enviar a RRHH'
      }
    ]);
    const changeKeyModal = (val) => {
      modalType.value = buttonModalType
        .filter((f) => f.id == val.keyModal)
        .reduce((acc, item) => {
          return [...acc, item];
        });
    };

    // * Modal de cancelacion
    const modalCancel = ref(false);
    const openModalCancel = () => {
      modalCancel.value = true;
    };

    const modalValid = ref(false);
    const openModalValid = () => {
      modalValid.value = true;
    };

    // * Modal de cobranza
    const OpenModalSummary = ref(false);
    const openSummary = () => {
      OpenModalSummary.value = true;
    };

    // * Modal general
    // abrir modales 1 = a validar  2 = a enviar rrhh

    // * Datos ticket
    const ticket_data = ref([]);

    // Agregar ceros antes del numero
    const addLeadingZeros = (num, totalLength) => {
      return String(num).padStart(totalLength, '0');
    };

    // * Comentarios

    // * Comentarios
    const comments = ref([]);
    const getBroker = async (id) => {
      const response = await store.dispatch(FETCH_USER_BY_ID, id);
      return response.data.body.name;
    };
    const getNewCommment = async (value) => {
      const { date } = value;
      const { brokerId } = value;
      const brokerName = await getBroker(brokerId);

      comments.value.push({
        message: value.message,
        date: parseDate(date),
        author: brokerName
      });
    };
    const getCommentsById = async () => {
      const response_comments = await store
        .dispatch(FETCH_COLLECTION_GROUP_COMMENTS, route.params.id)
        .catch((e) => e.response);

      for (let i = 0; i < response_comments.data?.length; i++) {
        const element = response_comments.data[i];
        const brokerName = await getBroker(response_comments.data[i].brokerId);

        comments.value.push({
          date: parseDate(element.createdAt),
          message: element.message,
          author: brokerName
        });
      }
    };
    const states_list = computed(() => store.getters[GET_COLLECTION_FORM]);
    const errorHumandSend = ref('');

    const getCollectionDataSend = async () => {
      try {
        const dataFetchCollection = await store.dispatch(
          FETCH_COLLECTION_DATA_SEND_RRHH,
          form_id
        );
        if (dataFetchCollection.status == 200) {
          loadSendToRRHH.value = true;
        } else {
          loadSendToRRHH.value = false;
        }
      } catch (error) {
        errorHumandSend.value = error.response.data.message;
      }
    };

    const status = computed(() => store.getters[GET_COLLECTION_DATA_RRHH]);
    console.log('statuuus', status);
    // * Modal RRHH
    const modalSendRRHH = ref(false);

    const dataCollection = computed(
      () => store.getters[GET_COLLECTION_DATA_RRHH]
    );

    const OpenModalRRHH = () => {
      if (errorHumandSend.value !== '' && !dataCollection.value.groups) {
        notification('error', 'Error', errorHumandSend.value);
      } else {
        modalSendRRHH.value = true;
      }
    };

    const validateProcessing = computed(() =>
      store.getters[GET_COLLECTION_GROUP_COLLECTIONS].some(
        (obj) => obj.squaringStatus == 'PROCESSING'
      )
    );
    const showValidateButton = computed(() =>
      store.getters[GET_COLLECTION_GROUP_COLLECTIONS].some(
        (s) =>
          s.squaringStatus == 'NO_PROCESSED' || s.squaringStatus == 'PROCESSING'
      )
    );
    const showSendHR = computed(() =>
      store.getters[GET_COLLECTION_GROUP_COLLECTIONS].every(
        (e) => e.squaringStatus == 'MATCH' || e.squaringStatus == 'NO_MATCH'
      )
    );
    const validateStateCollectionHumanSend = computed(() =>
      store.getters[GET_COLLECTION_GROUP_COLLECTIONS].some(
        (s) => s.status == 'BEWELL_VALIDATION'
      )
    );
    const validateApproved = computed(() =>
      store.getters[GET_COLLECTION_GROUP_COLLECTIONS].some(
        (s) => s.status == 'APPROVED'
      )
    );

    watchEffect(async () => {
      EmitBus.on('loadingSendCollections', (val) => {
        console.log('vaaal', val);
        loadingHumanSend.value = val;
      });
      // obtengo todos los estados del ticket
      const states = states_list.value.state;
      // ultimo estado del ticket
      form_current_state.value = states[states?.length - 1];

      // separo los estados por sus nombres para validar que existan y asi sacar su fecha a mostrar
      const created = states.find((obj) => obj.state === 'CREATED');
      const processing = states.find((obj) => obj.state === 'PROCCESING');
      const loaded = states.find((obj) => obj.state === 'LOADED');
      stepper_props.steps = [
        {
          title: 'Creado',
          date:
            created != undefined
              ? created.createdAt.replace('T', ' ').split('.')[0]
              : ''
        },
        {
          title: 'Procesando',
          date:
            processing != undefined
              ? processing.createdAt.replace('T', ' ').split('.')[0]
              : ''
        },
        {
          title: 'Cargado',
          date:
            loaded != undefined
              ? loaded.createdAt.replace('T', ' ').split('.')[0]
              : ''
        }
      ];
      // Selecciono el ultimo estado que se encuentra el ticket
      stepper_props.current = states.length - 1;
      console.log('stepppp prosp stateee', stepper_props.current);
      const canceled = states.find((obj) => obj.state === 'CANCELLED');
      const approved = states.find((obj) => obj.state == 'LOADED');

      if (canceled != undefined) {
        stepper_props.current = states.length - 2;
        stepper_props.state = 'error';
      } else {
        stepper_props.state = 'process';
      }

      if (approved) {
        stepper_props.state = 'finish';
      }
      // Datos del ticket
      const date = dataFetchInfo?.value.period?.split('-');
      let period = '';
      if (date != undefined) {
        period = `${date[0]}-${addLeadingZeros(date[1], 2)}`;
      }

      // veo que tipo de formulario se creo, si es holding, subholding y company
      let billing_type = '';
      let billing_type_name = '';

      if (dataFetchInfo.value.type == 'HOLDING') {
        billing_type = 'Holding';
        billing_type_name = dataFetchInfo?.value.holding.businessName;
      } else if (dataFetchInfo.value.type == 'SUBHOLDING') {
        billing_type = 'SubHolding';
        billing_type_name = dataFetchInfo?.value.subHolding.name;
      } else {
        billing_type = 'Empresa';
        billing_type_name = dataFetchInfo?.value.company.businessName;
      }

      // Correos
      const insurance_emails = [];
      const insurance_companies_names = [];
      dataFetchInfo?.value.insuranceCompanies.forEach((ins) => {
        const emails = ins.collectionEmails;
        insurance_emails.push(...emails);

        // Nombres de compañias aseguradoras
        insurance_companies_names.push(ins.businessName);
      });

      const clients_emails = [];
      dataFetchInfo?.value.payrollCompanies.forEach((comp) => {
        const emails = comp.collectionEmails;
        clients_emails.push(...emails);
      });

      ticket_data.value = [
        {
          key: 'Periodo cobranza',
          value: period
        },
        {
          key: billing_type,
          value: billing_type_name
        },
        {
          key: 'Aseguradoras',
          value: insurance_companies_names?.join(' - ')
        },
        {
          key: 'Correo aseguradoras',
          value:
            insurance_emails?.length != 0 ? insurance_emails.join(', ') : '-'
        },
        {
          key: 'Correo clientes',
          value: clients_emails?.length != 0 ? clients_emails.join(', ') : '-'
        }
      ];
    });

    // * Cancelation modal
    const cancelation_modal_visible = ref(false);

    // * Obtener formulario de cobranza en id al momento de cargar el componente
    const getTicketGroup = async () => {
      console.log('getTicketGroup');
      try {
        const { data } = await store.dispatch(
          FETCH_COLLECTION_FORM_BY_ID,
          form_id
        );
        dataFetchInfo.value = data;
      } catch {
        router.push({ name: 'Missing' });
      }
    };

    onMounted(async () => {
      await getTicketGroup();
      await getCollectionDataSend();
      await getCommentsById();
    });

    return {
      getMonthNameByValue,
      dataFetchInfo,
      form_id,
      keyModal,
      modalValid,
      modalCancel,
      modalSendRRHH,
      form_current_state,
      stepper_props,
      ticket_data,
      comments,
      openSummary,
      OpenModalSummary,
      OpenModalRRHH,
      changeKeyModal,
      openModalCancel,
      getBroker,
      getNewCommment,
      cancelation_modal_visible,
      loadSendToRRHH,
      states_list,
      openModalValid,
      showValidateButton,
      showSendHR,
      loadingValidate,
      validateProcessing,
      status,
      validateStateCollectionHumanSend,
      dataCollection,
      loadingHumanSend,
      validateApproved
    };
  }
};
</script>
