<template>
  <div>
    <a-modal v-model:visible="visible" title="Eliminar holding" :closable="!loading" :maskClosable="!loading"
      @close="onClose">
      <p>
        Para eliminar el holding <b>{{ holding_name }}</b> escribe en el
        recuadro "eliminar" y presiona continuar.
      </p>
      <a-form ref="formRef" :model="formState" :rules="rules" layout="vertical">
        <a-form-item ref="delete" name="delete" has-feedback>
          <a-input v-model:value="formState.delete" placeholder="eliminar" />
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button :disabled="loading" @click="onClose">Cancelar</a-button>
        <a-button type="primary" danger @click="onOk" :loading="loading">Continuar</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { DELETE_HOLDING } from '@/store/types';
import notification from '@/utils/notifications';

export default {
  name: 'HoldingDeletionModal',
  setup(_, { emit }) {
    // * Variables
    const visible = ref(false);
    const loading = ref(false);
    const holding_id = ref(0);
    const holding_name = ref('');
    const store = useStore();

    // * Funciones Modal
    const openModal = (hold) => {
      const { id, holdingName } = hold;
      holding_id.value = id;
      holding_name.value = holdingName;
      visible.value = true;
    };

    const onOk = () => {
      validateHoldingForm();
    };

    const onClose = () => {
      visible.value = false;
      formRef.value.resetFields();
    };

    // * Form
    const formRef = ref();
    const formState = reactive({
      delete: ''
    });

    const checkEliminarWord = async (rule, value) => {
      if (!value) {
        const error_message = 'Ingresa palabra solicitada';
        return Promise.reject(error_message);
      }
      if (value == 'eliminar') {
        return Promise.resolve();
      }
      return Promise.reject('Ingresa palabra solicitada');
    };

    const rules = {
      delete: [
        {
          required: true,
          validator: checkEliminarWord,
          trigger: 'change'
        }
      ]
    };

    const deleteHolding = async () => {
      loading.value = true;
      try {
        await store.dispatch(DELETE_HOLDING, holding_id.value);
        notification('success', 'Correcto', 'Holding Eliminado.');
        onClose();
        emit('holdingDeleted', true);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    const validateHoldingForm = async () => {
      try {
        await formRef.value.validate();
        deleteHolding();
      } catch (error) {
        console.error(error);
      }
    };

    return {
      visible,
      formRef,
      formState,
      rules,
      loading,
      holding_name,
      onOk,
      onClose,
      openModal
    };
  }
};
</script>
