<template>
  <div>
    <a-typography-title :level="4">Brokers</a-typography-title>

    <CompanyBrokerAssociation title="Ejecutivo de Servicio" brokerType="1" :brokerOptions="comercialBrokerOptions"
      :brokerOptionsLoading="broker_data_loading" :brokerList="comercialBrokers"
      :brokerListLoading="broker_by_company_loading" @addBroker="addBroker" @deleteBroker="deleteBroker" />

    <CompanyBrokerAssociation class="mt-3" title="Ejecutivo Operativo" brokerType="2"
      :brokerOptions="operativeBrokerOptions" :brokerOptionsLoading="broker_data_loading" :brokerList="operativeBrokers"
      :brokerListLoading="broker_by_company_loading" @addBroker="addBroker" @deleteBroker="deleteBroker" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  FETCH_ALL_BROKERS,
  FETCH_BROKERS_BY_COMPANY,
  GET_ALL_BROKERS,
  GET_BROKERS_BY_COMPANY,
  POST_BROKER_TO_COMPANY,
  DELETE_BROKER_TO_COMPANY
} from '@/store/types';
import notification from '@/utils/notifications';
import CompanyBrokerAssociation from '@/components/clients/CompanyBrokerAssociation.vue';

const store = useStore();
const route = useRoute();
const company_id = route.params.id;
const broker_data_loading = ref(false);
const broker_by_company_loading = ref(false);
const comercial_broker_form_ref = ref();

const brokerOptions = computed(() => {
  return store.getters[GET_ALL_BROKERS].map((broker) => {
    return {
      id: broker.id,
      label: broker.name,
      value: broker.id
    };
  });
});

const isFoundInOptions = (options, value) => {
  return options.some((item) => item.value === value);
};

const comercialBrokerOptions = computed(() => {
  return brokerOptions.value.map((opt) => ({
    ...opt,
    disabled: isFoundInOptions(comercialBrokers.value, opt.value)
  }));
});

const operativeBrokerOptions = computed(() => {
  return brokerOptions.value.map((opt) => ({
    ...opt,
    disabled: isFoundInOptions(operativeBrokers.value, opt.value)
  }));
});

const comercialBrokers = computed(() => {
  const companyBrokers = store.getters[GET_BROKERS_BY_COMPANY]?.find(
    (comp) => comp.id === 1
  )?.companyBrokers;
  if (companyBrokers != undefined) {
    return companyBrokers.map((broker) => {
      const foundBroker = brokerOptions.value.find(
        (obj) => obj.id === broker.userId
      );
      return {
        companyBrokerId: broker.id,
        title: foundBroker?.label,
        value: foundBroker?.value
      };
    });
  }
  return [];
});

const operativeBrokers = computed(() => {
  const companyBrokers = store.getters[GET_BROKERS_BY_COMPANY]?.find(
    (comp) => comp.id === 2
  )?.companyBrokers;
  if (companyBrokers != undefined) {
    return companyBrokers.map((broker) => {
      const foundBroker = brokerOptions.value.find(
        (obj) => obj.id === broker.userId
      );
      return {
        companyBrokerId: broker.id,
        title: foundBroker?.label,
        value: foundBroker?.value
      };
    });
  }
  return [];
});

const addBroker = async ({ broker: brokerId, brokerType: brokerTypeId }) => {
  try {
    const resp = await store.dispatch(POST_BROKER_TO_COMPANY, {
      id: company_id,
      payload: {
        brokerId,
        brokerType: brokerTypeId
      }
    });

    if (resp.status === 201) {
      getBrokerByCompany();
      notification('success', 'Correcto', 'Broker asignado con éxito');
    } else {
      throw new Error('Error al asignar el Broker');
    }

    comercial_broker_form_ref.value.resetFields();
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
};

const deleteBroker = async (companyBrokerId) => {
  try {
    const resp = await store.dispatch(DELETE_BROKER_TO_COMPANY, {
      idCompany: company_id,
      companyBrokerId
    });

    if (resp.status === 200) {
      getBrokerByCompany();
      notification('success', 'Correcto', 'Broker eliminado con éxito');
    } else {
      throw new Error('Error al eliminar el Broker');
    }

    comercial_broker_form_ref.value.resetFields();
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
};

const getAllBrokers = () => {
  broker_data_loading.value = true;
  store
    .dispatch(FETCH_ALL_BROKERS)
    .catch((error) =>
      notification('error', 'Error', error.response.data.message)
    )
    .finally(() => (broker_data_loading.value = false));
};

const getBrokerByCompany = () => {
  broker_by_company_loading.value = true;
  store
    .dispatch(FETCH_BROKERS_BY_COMPANY, company_id)
    .catch((error) =>
      notification('error', 'Error', error.response.data.message)
    )
    .finally(() => (broker_by_company_loading.value = false));
};

onMounted(() => {
  getAllBrokers();
  getBrokerByCompany();
});
</script>
