<template>
  <div v-if="$route.name === 'Transfers'">
    <PageHeader :breadcrumRoutes="routes" title="Traspaso de pólizas">
      <template #buttons>
        <a-button type="primary" @click="openTransferModal">
          Crear traspaso
        </a-button>
      </template>
    </PageHeader>

    <ResumeHeader v-bind="resume_data" />

    <a-card class="mt-2">
      <a-row type="flex" justify="space-between" align="middle">
        <a-col>
          <a-typography-title :level="4" class="text-gray-8 mb-0">
            Stock traspaso de pólizas
          </a-typography-title>
        </a-col>

        <a-range-picker v-model:value="dante_range" :value-format="date_format" :format="[date_format, date_format]" />
      </a-row>
      <a-row class="mt-3">
        <a-col :span="24">
          <TransfersTable :dates="dante_range" />
        </a-col>
      </a-row>
    </a-card>
  </div>
  <router-view :key="$route.path" />
</template>

<script setup>
import { ref, onMounted, computed, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ResumeHeader from '@/components/resume header/ResumeHeader.vue';
import PageHeader from '@/components/PageHeader.vue';
import TransfersTable from '@/components/Tables/TransfersTable.vue';
import { FETCH_TABLE_RESUME, GETTER_TICKET_TABLE_RESUME } from '@/store/types';
import notifications from '@/utils/notifications';

const store = useStore();
const routes = [
  {
    breadcrumbName: 'Transpaso de pólizas'
  }
];
const dante_range = ref([]);
const date_format = 'YYYY-MM-DD';
const route = useRoute();
const router = useRouter();

// * Transfer requests
const openTransferModal = () => {
  router.push({ name: 'TransferCreation' });
};

// Resumen de tablas
const calculatePorcentage = (value, total) => {
  if (value === 0 && total === 0) {
    return 0;
  }
  return Math.round((value * 100) / total);
};

const resume_data = computed(() => {
  const { ejecutadas, canceladas, tickets, revision } =
    store.getters[GETTER_TICKET_TABLE_RESUME];
  const finished = calculatePorcentage(ejecutadas + canceladas, tickets);

  const resume_component_props = {
    title: 'Resumen total',
    totalProgress: finished,
    categoryValues: [
      {
        name: 'Solicitudes',
        first_display: tickets,
        second_display: tickets
      },
      {
        name: 'Ejecutadas',
        first_display: ejecutadas,
        second_display: `${calculatePorcentage(ejecutadas, tickets)}%`
      },
      {
        name: 'En Progreso',
        first_display: revision,
        second_display: `${calculatePorcentage(revision, tickets)}%`
      },
      {
        name: 'Canceladas',
        first_display: canceladas,
        second_display: `${calculatePorcentage(canceladas, tickets)}%`
      }
    ]
  };

  return resume_component_props;
});

watchEffect(async (onCleanUp) => {
  try {
    const ac = new AbortController();
    const signal = ac.signal;
    onCleanUp(() => ac.abort());
    await store.dispatch(FETCH_TABLE_RESUME, { payload: 'Traspaso', signal });
  } catch (error) {
    notifications('error', 'Error', error.response.data.message);
  }
});

// Obtencion de data
onMounted(() => {
  if (route.query.startDate != undefined && route.query.endDate != undefined) {
    dante_range.value.push(route.query.startDate);
    dante_range.value.push(route.query.endDate);
  }
});
</script>
