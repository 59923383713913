<template>
  <div>
    <a-skeleton active :loading="loading_data">
      <a-card v-for="param in paragraphs" :key="param.order" class="mb-3">
        <a-typography-title :level="4">{{ param.title }}</a-typography-title>
        <p style="white-space: pre-line">{{ param.content }}</p>
      </a-card>
    </a-skeleton>

    <a-card class="mt-3">
      <a-form>
        <a-form-item
          name="checked"
          :validateStatus="status"
          :help="message"
          @change="change"
        >
          <a-checkbox v-model:checked="checked"
            >Sí, estoy de acuerdo</a-checkbox
          >
        </a-form-item>
      </a-form>
    </a-card>

    <a-row type="flex" justify="space-between" class="mt-3">
      <a-button size="large" @click="saveConsent(false, 5)" :loading="loading">
        <template #icon>
          <ArrowLeftOutlined />
        </template>
        Atrás
      </a-button>
      <a-button
        size="large"
        type="primary"
        @click="formSubmit(7)"
        :loading="loading"
        >Siguiente</a-button
      >
    </a-row>
  </div>
</template>

<script setup>
import { ArrowLeftOutlined } from '@ant-design/icons-vue';
import { onMounted, ref, defineExpose } from 'vue';
import { useStore } from 'vuex';
import { ACTION_CHANGE_STEP, FETCH_CONSENT, PUT_CONSENT } from '@/store/types';
import notification from '@/utils/notifications';

const store = useStore();
const checked = ref(false);
const status = ref('');
const message = ref('');
const paragraphs = ref([]);
const loading_data = ref(false);
const loading = ref(false);

const change = () => {
  status.value = '';
  message.value = '';
};

const formSubmit = (step) => {
  if (checked.value === false) {
    status.value = 'error';
    message.value = 'Favor confirmar que esta de acuerdo.';
    notification('error', 'Error', 'Debe confirmar que esta de acuerdo.');
  } else {
    saveConsent(true, step);
  }
};

const getConsent = () => {
  loading_data.value = true;
  store
    .dispatch(FETCH_CONSENT)
    .then((resp) => {
      paragraphs.value = resp.paragraphs.sort(
        (param) => param.order - param.order
      );
      checked.value = resp.isConsent;
    })
    .catch((errror) => {
      console.error(errror);
    })
    .finally(() => {
      loading_data.value = false;
    });
};

const saveConsent = (value, step) => {
  loading.value = true;

  const payload = {
    isConsent: value
  };

  store
    .dispatch(PUT_CONSENT, payload)
    .catch((error) => {
      notification('error', 'Error', error.response.data.message);
    })
    .finally(() => {
      loading.value = false;
      store.dispatch(ACTION_CHANGE_STEP, step);
    });
};

defineExpose({ formSubmit });

onMounted(() => {
  getConsent();
});
</script>
