<template style="display: flex; height: 100%">
  <a-typography-title class="ant-page-header-heading-title" :level="4">Revisa el documento antes de
    firmar</a-typography-title>
  <p>Este es el documento que generamos con los datos que rellenaste</p>
  <a-skeleton :loading="loading" active>
    <iframe style="flex-grow: 1" width="100%" height="100%" :src="url"></iframe>
  </a-skeleton>
  <a-row type="flex" justify="end" class="mt-3" v-if="loading === false">
    <a-button :disabled="code_loading" @click="goBack">
      <template #icon>
        <EditOutlined />
      </template>
      Editar documento
    </a-button>
    <a-button class="ml-3" type="primary" @click="firmCodeStateValidation" :loading="code_loading">
      Firmar documento
    </a-button>
  </a-row>

  <a-modal title="Firma documento" v-model:visible="visible">
    <a-typography-text>Hemos enviado un código a tu correo <b>{{ email }}</b> desde Trust para
      que puedas firmar el documento.</a-typography-text>
    <br /><br />
    <a-typography-text>Ingrésa el código en el siguiente campo.</a-typography-text>

    <a-form ref="form_ref" :model="form_state" :rules="rules" layout="vertical">
      <a-form-item name="code" label="Código" class="mt-3">
        <a-input placeholder="Ingresa el código" v-model:value="form_state.code" />
      </a-form-item>
    </a-form>

    <a-row type="flex" justify="end" class="mt-3">
      <a-button type="link" @click="requestCode" :disabled="resend_disabled" :loading="code_loading">
        {{
          resend_disabled
            ? `Reenviar código disponible en ${minutes}:${formatTimerValue(
              seconds
            )}`
            : 'Reenviar código'
        }}
      </a-button>
    </a-row>

    <template #footer>
      <a-button :disabled="sign_loading" @click="closeModal">Cancelar</a-button>
      <a-button type="primary" :disabled="sign_disabled" @click="sendCode" :loading="sign_loading">Firmar</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { FETCH_VISUALIZATION_FILE } from '../../store/types';
import { EditOutlined } from '@ant-design/icons-vue';
import {
  FETCH_DIGITAL_FORM_EMPLOYEE,
  ACTION_CHANGE_STEP,
  FETCH_SIGN_CODE,
  PUT_SIGN_CODE,
  FETCH_FIRM_STATE
} from '../../store/types';
import notification from '../../utils/notifications';

const url = ref();
const loading = ref(false);
const store = useStore();
const visible = ref(false);
const form_ref = ref();
const form_state = reactive({
  code: null
});
const rules = {
  code: [
    {
      required: true,
      message: 'Ingrese código',
      trigger: 'change',
      type: 'string'
    }
  ]
};
const code_loading = ref(false);
const sign_loading = ref(false);
const minutes = ref(0);
const seconds = ref(0);
const resend_disabled = ref(false);
const resend_code_state = ref(false);
const email = ref('');
const requireReloadForm = ref();

const loadPdf = async () => {
  loading.value = true;
  try {
    const resp = await store.dispatch(FETCH_VISUALIZATION_FILE);
    const blob = new Blob([resp.data], { type: 'application/pdf' });
    const blobURL = URL.createObjectURL(blob);
    url.value = blobURL;
  } catch (error) {
    console.error(error);
  }
  loading.value = false;
};

const openModal = () => {
  visible.value = true;
};

const closeModal = () => {
  visible.value = false;

  console.log(requireReloadForm.value);

  if (requireReloadForm.value === true) {
    loadPdf();
    requireReloadForm.value = false;
  }
};

const sign_disabled = computed(() => {
  return form_state.code === null || form_state.code.length === 0;
});

const firmCodeStateValidation = () => {
  if (resend_disabled.value === true) {
    openModal();
  } else {
    requestCode();
  }
};

const requestCode = () => {
  code_loading.value = true;
  store
    .dispatch(FETCH_SIGN_CODE)
    .then(() => {
      openModal();
      createTimer(5, 0);

      if (resend_code_state.value) {
        notification('success', 'Correcto', 'Código reenviado correctamente');
      }

      resend_code_state.value = true;
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      code_loading.value = false;
    });
};

const sendCode = () => {
  sign_loading.value = true;
  store
    .dispatch(PUT_SIGN_CODE, {
      code: form_state.code
    })
    .then((resp) => {
      if (resp.status === true) {
        store.dispatch(ACTION_CHANGE_STEP, 8);
      } else {
        notification('error', 'Error', resp.message);

        console.log(resp.requireReloadForm);

        if (resp.requireReloadForm === true) {
          requireReloadForm.value = true;
        }

        console.log(requireReloadForm.value);
      }
    })
    .catch((error) => {
      notification('error', 'Error', error.response.data.message);
    })
    .finally(() => {
      sign_loading.value = false;
    });
};

const goBack = () => {
  store.dispatch(ACTION_CHANGE_STEP, 6);
};

const createTimer = (minutos, segundos) => {
  resend_disabled.value = true;

  var total = (minutos * 60 + segundos) * 1000;
  var remainingTime = total;

  function showTime() {
    minutes.value = Math.floor(remainingTime / 60000);
    seconds.value = Math.floor((remainingTime % 60000) / 1000);
  }

  var intervalId = setInterval(function () {
    remainingTime -= 1000;
    showTime();

    if (remainingTime <= 0) {
      clearInterval(intervalId);
      resend_disabled.value = false;
    }
  }, 1000);

  showTime();
  return intervalId;
};

const formatTimerValue = (value) => {
  if (value >= 0 && value < 10) {
    return `0${value}`;
  }
  return value;
};

const getEmployee = () => {
  store
    .dispatch(FETCH_DIGITAL_FORM_EMPLOYEE)
    .then((resp) => {
      email.value = resp.data.email;
    })
    .catch((error) => {
      console.error(error);
    });
};

const getFirmState = () => {
  store
    .dispatch(FETCH_FIRM_STATE)
    .then((resp) => {
      const { lastCodeRequestAt } = resp;
      if (lastCodeRequestAt !== null) {
        resend_code_state.value = true;

        const requested_date = new Date(lastCodeRequestAt);
        const current_date = new Date();
        const result_date = current_date - requested_date;
        const minutes = Math.floor(result_date / 60000);
        const seconds = Math.floor((result_date % 60000) / 1000);
        if (minutes < 5) {
          const totalTimeInSeconds = minutes * 60 + seconds;
          const totalSeconds = 300 - totalTimeInSeconds;
          const missingMinutes = Math.floor(totalSeconds / 60);
          const missingSeconds = totalSeconds % 60;
          createTimer(missingMinutes, missingSeconds);
        }
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

onMounted(() => {
  loadPdf();
  getFirmState();
  getEmployee();
});
</script>
