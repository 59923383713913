import {
  getDigitalForm,
  getBeneficiares,
  getDependants,
  putDependants,
  putBeneficiares,
  getInsureds,
  putInsureds,
  getDiseases,
  getInsuredsWithDiseases,
  getBanks,
  getSports,
  putSports,
  getInsuredWithSports,
  getPrevisualizationFile,
  getDigitalFormCompany,
  getDigitalFormPolicies,
  saveDigitalFormPolicies,
  getDigitalFormMaritalStatus,
  getPrevisionalHealthSystem,
  getMunicipalities,
  putDigitalFormEmployee,
  getDigitalFormEmployee,
  getPaymentMethod,
  putPaymentMethod,
  putObverse,
  getObverse,
  putReverse,
  getReverse,
  getPets,
  getDeclaredPets,
  putDeclaredPets,
  putConsent,
  getConsent,
  getDigitalFormResetedToken,
  getCodeToSign,
  signDocument,
  firmState,
  digitalFormLogo
} from '../../network';

import {
  FETCH_DIGITAL_FORM,
  FETCH_BENEFICIARIES,
  FETCH_DEPENDANTS,
  FETCH_INSUREDS,
  FETCH_DISEASES,
  FETCH_DIGITAL_FORM_EMPLOYEE,
  GET_DIGITAL_FORM_TOKEN,
  GET_DIGITAL_FORM_STEP,
  SET_DIGITAL_FORM_TOKEN,
  SET_DIGITAL_FORM_STEP,
  ACTION_CHANGE_STEP,
  PUT_CHARGES,
  PUT_BENEFICIARIES,
  PUT_INSUREDS,
  FETCH_INDUREDS_WITH_DESEASES,
  FETCH_BANKS,
  FETCH_SPORTS,
  PUT_SPORTS,
  FETCH_INSURED_WITH_SPORTS,
  FETCH_VISUALIZATION_FILE,
  FETCH_DIGITAL_FORM_COMPANY,
  FETCH_DIGITAL_FORM_POLICIES,
  FETCH_DIGITAL_FORM_MARITAL_STATUS,
  FETCH_PREVISIONAL_HEALTH,
  FETCH_MUNICIPALITIES,
  PUT_DIGITAL_FORM_EMPLOYEE,
  FETCH_PAYMENT_METHOD,
  PUT_PAYMENT_METHOD,
  PUT_EMPLOYEE_OBVERSE,
  PUT_EMPLOYEE_REVERSE,
  FETCH_EMPLOYEE_OBVERSE,
  FETCH_EMPLOYEE_REVERSE,
  FETCH_PETS,
  FETCH_PETS_DECLARATION,
  SAVE_DIGITAL_FORM_TOKEN,
  PUT_PETS,
  FETCH_CONSENT,
  PUT_CONSENT,
  FETCH_DIGITAL_FORM_RESETTED_TOKEN,
  PUT_DIGITAL_FORM_POLICIES,
  FETCH_SIGN_CODE,
  PUT_SIGN_CODE,
  FETCH_FIRM_STATE,
  FETCH_INCORPORATION_LOGO
} from './types';

const store = {};

store.state = {
  token: '',
  current_step: null
};

store.getters = {
  [GET_DIGITAL_FORM_TOKEN](state) {
    return state.token;
  },
  [GET_DIGITAL_FORM_STEP](state) {
    return state.current_step;
  }
};

store.mutations = {
  [SET_DIGITAL_FORM_TOKEN](state, payload) {
    state.token = payload;
  },
  [SET_DIGITAL_FORM_STEP](state, payload) {
    state.current_step = payload;
  }
};

store.actions = {
  [SAVE_DIGITAL_FORM_TOKEN]({ commit }, payload) {
    commit(SET_DIGITAL_FORM_TOKEN, payload);
  },
  [FETCH_DIGITAL_FORM]({ commit, getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getDigitalForm(token)
        .then((resp) => {
          const { currentStep } = resp.data;
          commit(SET_DIGITAL_FORM_STEP, currentStep);
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ACTION_CHANGE_STEP]({ commit }, payload) {
    commit(SET_DIGITAL_FORM_STEP, payload);
  },
  [FETCH_BENEFICIARIES]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getBeneficiares(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_DEPENDANTS]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getDependants(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_CHARGES]({ getters }, payload) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      putDependants(payload, token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_BENEFICIARIES]({ getters }, payload) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      putBeneficiares(payload, token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_INSUREDS]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getInsureds(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_INSUREDS]({ getters }, payload) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      putInsureds(payload, token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_DISEASES]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getDiseases(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_INDUREDS_WITH_DESEASES]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getInsuredsWithDiseases(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_BANKS]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getBanks(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_SPORTS]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getSports(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_SPORTS]({ getters }, payload) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      putSports(payload, token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_INSURED_WITH_SPORTS]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getInsuredWithSports(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_VISUALIZATION_FILE]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getPrevisualizationFile(token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_DIGITAL_FORM_COMPANY]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getDigitalFormCompany(token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_DIGITAL_FORM_POLICIES]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getDigitalFormPolicies(token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_DIGITAL_FORM_MARITAL_STATUS]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getDigitalFormMaritalStatus(token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_PREVISIONAL_HEALTH]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getPrevisionalHealthSystem(token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_MUNICIPALITIES]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getMunicipalities(token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_DIGITAL_FORM_EMPLOYEE]({ getters }, payload) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      putDigitalFormEmployee(payload, token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_DIGITAL_FORM_EMPLOYEE]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getDigitalFormEmployee(token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_PAYMENT_METHOD]({ getters }, payload) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      putPaymentMethod(payload, token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_PAYMENT_METHOD]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getPaymentMethod(token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_EMPLOYEE_OBVERSE]({ getters }, payload) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      putObverse(payload, token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_EMPLOYEE_REVERSE]({ getters }, payload) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      putReverse(payload, token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_EMPLOYEE_OBVERSE]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getObverse(token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_EMPLOYEE_REVERSE]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getReverse(token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_PETS]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getPets(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_PETS_DECLARATION]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getDeclaredPets(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_PETS]({ getters }, payload) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      putDeclaredPets(payload, token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_CONSENT]({ getters }, payload) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      putConsent(payload, token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_CONSENT]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getConsent(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_DIGITAL_FORM_RESETTED_TOKEN](_, token) {
    return new Promise((resolve, reject) => {
      getDigitalFormResetedToken(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_DIGITAL_FORM_POLICIES]({ getters }, payload) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      saveDigitalFormPolicies(payload, token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_SIGN_CODE]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      getCodeToSign(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PUT_SIGN_CODE]({ getters }, payload) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      signDocument(token, payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_FIRM_STATE]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      firmState(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FETCH_INCORPORATION_LOGO]({ getters }) {
    const token = getters[GET_DIGITAL_FORM_TOKEN];
    return new Promise((resolve, reject) => {
      digitalFormLogo(token)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default store;
