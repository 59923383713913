import {
  getAllBrokers,
  getBrokerByCompany,
  createBrokerToCompany,
  deleteBrokerToCompany
} from '../../network';
import {
  FETCH_ALL_BROKERS,
  FETCH_BROKERS_BY_COMPANY,
  SET_ALL_BROKERS,
  SET_BROKERS_BY_COMPANY,
  GET_ALL_BROKERS,
  GET_BROKERS_BY_COMPANY,
  POST_BROKER_TO_COMPANY,
  DELETE_BROKER_TO_COMPANY
} from '../types';

const store = {};
const getDefaultState = () => ({
  brokers: [],
  brokerByCompany: []
});

store.state = getDefaultState();

store.getters = {
  [GET_ALL_BROKERS](state) {
    return state.brokers;
  },
  [GET_BROKERS_BY_COMPANY](state) {
    return state.brokerByCompany;
  }
};

store.mutations = {
  [SET_ALL_BROKERS](state, payload) {
    state.brokers = payload;
  },
  [SET_BROKERS_BY_COMPANY](state, payload) {
    state.brokerByCompany = payload;
  }
};

store.actions = {
  [FETCH_ALL_BROKERS]({ commit }) {
    return new Promise((resolve, reject) => {
      getAllBrokers()
        .then((resp) => {
          commit(SET_ALL_BROKERS, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_BROKERS_BY_COMPANY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getBrokerByCompany(payload)
        .then((resp) => {
          commit(SET_BROKERS_BY_COMPANY, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_BROKER_TO_COMPANY](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      createBrokerToCompany(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_BROKER_TO_COMPANY](_, { idCompany, companyBrokerId }) {
    return new Promise((resolve, reject) => {
      deleteBrokerToCompany(idCompany, companyBrokerId)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default store;
