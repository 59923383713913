export const FETCH_SUBHOLDING = 'subholdings/FETCH_SUBHOLDING';
export const FETCH_SUBHOLDINGS = 'subholdings/FETCH_SUBHOLDINGS';
export const SET_SUBHOLDING = 'subholdings/SET_SUBHOLDING';
export const GET_SUBHOLDING = 'subholdings/GET_SUBHOLDING';
export const GET_SUBHOLDINGS = 'subholdings/GET_SUBHOLDINGS';
export const SELECT_SUBHOLDING = 'subholdings/SELECT_SUBHOLDING';
export const SET_SELECTED_SUBHOLDING = 'subholdings/SET_SELECTED_SUBHOLDING';
export const ACTION_SELECTED_SUBHOLDING =
  'subholdings/ACTION_SELECTED_SUBHOLDING';
export const GET_SELECTEDSUBHOLDING = 'subholdings/GET_SELECTEDSUBHOLDING';
export const GET_RUT_HOLDING = 'subholdings/GET_RUT_HOLDING';
export const POST_SUBHOLDING = 'subholdings/POST_SUBHOLDING';
export const SELECT_FILTER = 'subholdings/SELECT_FILTER';
export const SET_POST_SUBHOLDING = 'subholdings/SET_POST_SUBHOLDING';
export const EDIT_SUBHOLDING = 'subholdings/EDIT_SUBHOLDING';
export const SET_EDIT_SUBHOLDING = 'subholdings/SET_EDIT_SUBHOLDING';
export const FILTER_ALL = 'subholdings/FILTER_ALL';
export const SUBHOLDINGS = 'subholdings/SUBHOLDINGS';
export const SELECTS = 'subholdings/SELECTS';
export const SET_SELECT = 'subholdings/SET_SELECT';
export const SELECTED_SUBHOLDING = 'subholdings/SELECTED_SUBHOLDING';
export const SET_SELECTEDSUBHOLDING = 'subholdings/SET_SELECTEDSUBHOLDING';
export const GET_COMP = 'subholdings/GET_COMP';
export const SET_COMP = 'subholdings/SET_COMP';
export const FETCH_COMP = 'subholdings/FETCH_COMP';
export const ALL_COMPANIES = 'subholdings/ALL_COMPANIES';
export const SET_SELECTED_COMPANY = 'subholdings/SET_SELECTED_COMPANY';
export const ACTION_SELECTED_COMPANY = 'subholdings/ACTION_SELECTED_COMPANY';
export const SELECTED_COMPANY = 'subholdings/SELECTED_COMPANY';
export const DELETE_SUBHOLDING = 'subholding/DELETE_SUBHOLDING';
export const SET_SUBHOLDINGS = 'subholdings/SET_SUBHOLDINGS';

export const FETCH_SUBHOLDINGS_DATA_TABLE =
  'subholdings/FETCH_SUBHOLDINGS_DATA_TABLE';
export const MUTATE_SUBHOLDINGS_DATA_TABLE =
  'subholdings/MUTATE_SUBHOLDINGS_DATA_TABLE';
export const GET_SUBHOLDINGS_DATA_TABLE =
  'subholdings/GET_SUBHOLDINGS_DATA_TABLE';
