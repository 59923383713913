<template>
  <div>
    <a-card>
      <a-typography-title :level="5">
        {{ title }}
      </a-typography-title>

      <div
        style="overflow-y: auto; height: 200px"
        v-if="commentsList.length > 0"
      >
        <a-list item-layout="horizontal" :data-source="commentsList">
          <template #renderItem="{ item }">
            <a-list-item>
              <a-comment
                :author="item.author"
                :datetime="item.description"
              >
                <template #content>
                  <span v-html="item.content" />
                </template>
              </a-comment>
            </a-list-item>
          </template>
        </a-list>
      </div>

      <a-row class="mt-3" v-if="!hideCommentSection">
        <a-col>
          <a-avatar :size="40">
            <template #icon><user-outlined /></template> </a-avatar
        ></a-col>
        <a-col :span="20" :offset="1">
          <a-textarea
            :autoSize="{ minRows: 3, maxRows: 6 }"
            v-model:value="comment_value"
            :disabled="loading"
            :maxlength="500"
            placeholder="Escribe aquí un cometario."
            allow-clear
          />

          <a-button
            :loading="loading"
            class="px-4 mt-3"
            type="primary"
            @click="createComments"
            >Comentar</a-button
          >
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { UserOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';

export default {
  name: 'CommentsLists',
  components: {
    'user-outlined': UserOutlined
  },
  props: {
    title: {
      type: String,
      default: 'Escribe un titulo'
    },
    commentsList: {
      type: Array,
      default: []
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideCommentSection: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const comment_value = ref('');

    const createComments = () => {
      if (comment_value.value !== '') {
        emit('createComment', comment_value.value);
        comment_value.value = '';
      }
    };

    return {
      comment_value,
      createComments
    };
  }
};
</script>
