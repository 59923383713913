import {
  getRechazoCases,
  getAppealEmails,
  postRejectionAppeal,
  aprroveOrDenyAppeal,
  caseRechazo
} from '../../../network';
import {
  GET_RECHAZO_CASES,
  SET_RECHAZO_CASES,
  FETCH_RECHAZO_CASES,
  GET_APPEAL_EMAILS,
  SET_APPEAL_EMAIL,
  FETCH_APPEAL_EMAILS,
  POST_REJECTION_APPEAL,
  POST_APROVE_OR_DENY_APPEAL,
  POST_CASE_RECHAZO
} from './types';

const store = {};
const getDefaultState = () => ({
  rechazo: [],
  appealEmail: []
});

store.state = getDefaultState();

store.getters = {
  [GET_RECHAZO_CASES](state) {
    state.rechazo.sort((a, b) => {
      return a.id - b.id;
    });

    return state.rechazo.map((obj) => {
      return {
        key: obj.id,
        id: obj.id,
        holding: obj.ticket.company?.holding
          ? obj.ticket.company?.holding.businessName
          : obj.ticket.company.subHolding?.holding?.businessName,
        subholding: obj.ticket.company
          ? obj.ticket.company.subHolding
            ? obj.ticket.company.subHolding.name
            : ''
          : '',
        companyName: obj?.ticket?.company?.businessName,
        companyRut: obj?.ticket?.company?.rut,
        employeeName: `${obj?.ticket?.employee?.firstName} ${obj?.ticket?.employee?.firstSurname}`,
        employeeRut: obj?.ticket?.employee?.rut,
        ticketState: obj?.states.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )[obj.states.length - 1].state.id,
        createdAt: obj?.updatedAt
      };
    });
  },
  [GET_APPEAL_EMAILS](state) {
    return state.appealEmail;
  }
};

store.mutations = {
  [SET_RECHAZO_CASES](state, payload) {
    state.rechazo = payload;
  },
  [SET_APPEAL_EMAIL](state, payload) {
    state.appealEmail = payload;
  }
};

store.actions = {
  async [FETCH_RECHAZO_CASES]({ commit }) {
    return new Promise((resolve, reject) => {
      getRechazoCases()
        .then((resp) => {
          commit(SET_RECHAZO_CASES, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async [FETCH_APPEAL_EMAILS]({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      getAppealEmails(id, payload)
        .then((resp) => {
          commit(SET_APPEAL_EMAIL, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async [POST_REJECTION_APPEAL](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      postRejectionAppeal(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async [POST_APROVE_OR_DENY_APPEAL](_, { id, action, payload }) {
    return new Promise((resolve, reject) => {
      aprroveOrDenyAppeal(id, action, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async [POST_CASE_RECHAZO](_, payload) {
    return new Promise((resolve, reject) => {
      caseRechazo(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default store;
