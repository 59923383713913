import dashboardAdmin from './dashboardAdmin';
import dashboardBroker from './dashboardRRHH';

const store = {};
store.modules = {
  dashboardAdmin,
  dashboardBroker
};

export default store;
