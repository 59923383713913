<template>
  <div>
    <a-row type="flex" :gutter="8">
      <a-col flex="auto">
        <a-card style="height: 140px">
          <a-row type="flex" justify="space-between" align="middle">
            <a-col>
              <a-typography-title :level="4" class="text-gray-8 mb-0">
                {{ title }}
              </a-typography-title>
            </a-col>
          </a-row>

          <a-row
            class="mb-4"
            type="flex"
            justify="space-between"
            align="bottom"
          >
            <a-col>
              <a-space>
                <a-button
                  :type="showing_type === 'percentage' ? 'primary' : 'default'"
                  @click="changeShowingType('percentage')"
                  >Porcentajes</a-button
                >
                <a-button
                  :type="showing_type === 'number' ? 'primary' : 'default'"
                  @click="changeShowingType('number')"
                  >Números</a-button
                >
              </a-space>
            </a-col>
            <a-col class="d-flex">
              <card
                v-for="(cat, index) in categoryValues"
                :key="cat.name"
                :title="cat.name"
                :total="
                  showing_type === 'number'
                    ? cat.first_display
                    : cat.second_display
                "
                :showSeparator="index != categoryValues.length - 1"
              />
            </a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col flex="auto">
        <progress-bar :value="totalProgress" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { ref } from 'vue';
import ResumeHeaderCard from './ResumeHeaderCard.vue';
import ResumeHeaderProgressBar from './ResumeHeaderProgressBar.vue';

export default {
  props: {
    title: {
      type: String,
      default: 'Resumen total'
    },
    categoryValues: {
      type: Object,
      default: []
    },
    totalProgress: {
      type: Number,
      default: 0
    }
  },
  components: {
    card: ResumeHeaderCard,
    'progress-bar': ResumeHeaderProgressBar
  },
  setup() {
    const showing_type = ref('number');

    const changeShowingType = (type) => {
      showing_type.value = type;
    };

    return {
      showing_type,
      changeShowingType
    };
  }
};
</script>
