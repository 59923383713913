import { resetPassword, CreateResetPassword } from '../../network';
import { FETCH_USER_RESET, POST_USER_RESET } from './types';

const store = {};

// * State
const getDefaultState = () => ({});

store.state = getDefaultState();

// * Getters
store.getters = {};

// * MUTATIONS

store.mutations = {};

// * ACTIONS

store.actions = {
  [FETCH_USER_RESET](_, { id, token }) {
    return new Promise((resolve, reject) => {
      resetPassword(id, token).then(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [POST_USER_RESET](_, { id, token, payload }) {
    return new Promise((resolve, reject) => {
      CreateResetPassword(payload, id, token).then(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
};

export default store;
