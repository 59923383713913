<template>
  <div>
    <a-modal
      title="Observaciones"
      v-model:visible="modal_props.visible"
      @cancel="closeModal"
    >
      <div>
        <a-typography-title :level="5" class="text-black">
          <b>Datos cobranza</b>
        </a-typography-title>
        <p>Revisa que los datos ingresados esten correctos.</p>

        <a-table
          :loading="modal_props.loading"
          :columns="columns"
          :data-source="modal_props.values"
          :pagination="pagination_props"
          @change="changeCurrentPage"
        >
          <template #idRender="{ text: id }"> #{{ id }} </template>
        </a-table>
      </div>

      <template #footer>
        <a-button type="primary" @click="closeModal">Ok</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { reactive } from 'vue';
import { useStore } from 'vuex';
import {
  FETCH_PAYROLL_OBSERVATIONS,
  GET_PAYROLL_OBSERVATIONS
} from '../../../store/types';

export default {
  name: 'ObservationsModal',
  setup() {
    // * Variables
    const store = useStore();

    // * Propiedades del modal
    const modal_props = reactive({
      visible: false,
      loading: false,
      values: []
    });

    const pagination_props = reactive({
      total: 0,
      pageSize: 5,
      current: 1
    });

    const changeCurrentPage = (page) => {
      pagination_props.current = page.current;
    };

    // * Metodos del modal
    const openModal = async (id) => {
      modal_props.visible = true;
      modal_props.loading = true;

      // Primero obtengo la data de las observaciones del collection para mostrarlo
      try {
        await store.dispatch(FETCH_PAYROLL_OBSERVATIONS, id);

        const resp = store.getters[GET_PAYROLL_OBSERVATIONS];

        modal_props.values = resp.map((obj, index) => {
          return {
            key: obj.id,
            id: index + 1,
            detail: obj.description
          };
        });

        pagination_props.total = resp.length;
      } catch (error) {
        console.error(error);
        console.error(error.response);
        modal_props.values = [];
      }

      modal_props.loading = false;
    };

    const closeModal = () => {
      modal_props.visible = false;
    };

    // * Tabla de dentro
    const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'idRender'
        }
      },
      {
        title: 'Detalle',
        dataIndex: 'detail',
        key: 'detail'
      }
    ];

    return {
      modal_props,
      columns,
      openModal,
      closeModal,
      changeCurrentPage,
      pagination_props
    };
  }
};
</script>
