<template>
  <div>
    <a-modal v-model:visible="visible" title="Validación cobranza" centered>
      <a-typography-text :level="5">
        La plataforma validará los ultimos movimientos del mes registrados para
        el periodo {{ data[0].value }} con los movimientos adjuntos en la nomina
        de cobranza.
      </a-typography-text>
      <a-row class="mt-2 mb-4">
        <a-col :span="2">
          <exclamation-circle-outlined
            :style="{ fontSize: '28px', color: '#FFBF01' }"
          />
        </a-col>
        <a-col :span="20">
          <a-typography-text class="text-gray-8">
            No se esta considerando ningun movimiento registrado antes o después
            de dicho periodo.
          </a-typography-text>
        </a-col>
      </a-row>
      <template #footer>
        <a-button :loading="loading" key="back" @click="closeModal"
          >Volver atrás</a-button
        >
        <a-button
          :loading="loading"
          key="submit"
          type="primary"
          @click="createSquaringValidate"
        >
          Validar
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    'exclamation-circle-outlined': ExclamationCircleOutlined
  },
  props: {
    data: {
      type: Object,
      default: {}
    },
    ticketId: {
      type: Number,
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const visible = ref(false);

    const closeModal = () => {
      visible.value = false;
    };
    const squaring_value = ref();

    const openModal = () => {
      visible.value = true;
    };

    const createSquaringValidate = async () => {
      emit('createSquaringValidate', { ...squaring_value.value });
    };

    return {
      visible,
      closeModal,
      createSquaringValidate,
      openModal,
      squaring_value
    };
  }
};
</script>
